// Customizable Area Start
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";

import React, { ReactNode } from "react";
import { getStorageData } from "framework/src/Utilities";
import { i18n } from "i18next";

interface CandidateAssessmentResponse {
    candidate_list: {
        candidate_id: number;
        first_name: string;
        assessment_id: number;
        assessment_title: string;
        percentage_score: number;
        job_title: string;
        assessment_complete_date: string;
        total_questions: number;
        objective: string;
        competency_name: string[];
    }[];
    download_url: string
    overall_average_percentage: number;
    total_assessments_count: number;
    pending_assessments_count: number;
    meta: {
        pagination: {
            current_page: number;
            total_pages: number;
            total_count: number;
            per_page: string;
        };
    };
}

// Customizable Area End

export const configJSON = require("./config");
export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    children?: ReactNode;
    selectedMenu?: string;
    i18n?: i18n;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    token: string;
    selectedLanguage: string;
    isLoading: boolean;
    calendarOpen: boolean;
    selectedDate: null | [Date, Date];
    tempDate: null | [Date, Date];
    ViewScoreCardListData: Array<{
        AssessmentCompleteDate: string;
        AssessmentId: string;
        AssessmentTitle: string;
        CandidateId: string;
        CompetencyName: string[];
        FirstName: string;
        JobTitle: string;
        Objective: string;
        PercentageScore: string;
        TotalQuestions: string;
    }>;
    status: string;
    page: number,
    perPage: number,
    TotalRecord: number,
    PendingAssessments: number,
    OverallAveragePercentage: number
    // Customizable Area End

}

interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class ViewScoreCardController extends BlockComponent<
    Props,
    S,
    SS
> {

    // Customizable Area Start
    ListOfScoreCardDetailsApiCallId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.CountryCodeMessage)
        ];

        this.state = {
            token: '',
            selectedLanguage: '',
            isLoading: false,
            calendarOpen: false,
            selectedDate: null,
            tempDate: null,
            ViewScoreCardListData: [],
            status: "",
            page: 1,
            perPage: 10,
            TotalRecord: 0,
            PendingAssessments: 0,
            OverallAveragePercentage: 0
        };

        // Customizable Area End
        runEngine.attachBuildingBlock(this, this.subScribedMessages);
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        runEngine.debugLog("Message Recived", message);
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            this.GetListOfScoreCardDetailsApiResponse(apiRequestCallId, responseJson);
            // Get the data for the response

        }
        // Customizable Area End
    }

    // Customizable Area Start



    async componentDidMount() {
        const tokenMeta = await getStorageData('signInResponse', true);
        this.setState({
            token: tokenMeta?.meta?.token
        })
        const language = await getStorageData("language") || "English";
        this.setState({ selectedLanguage: language });
        this.listAllUsersApiCall()
    }
    TraslationFuncation = (key: string) => {
        if (this.props.i18n) {
            return this.props.i18n.t(key);
        }
    }



    handleCondition = (condition: boolean, truePart: React.ReactNode, falsePart: React.ReactNode) => {
        return condition ? truePart : falsePart
    }

    formatDateRange = (dateRange: [Date, Date] | null): string => {
        if (Array.isArray(dateRange) && dateRange.length === 2) {
            const [startDate, endDate] = dateRange;
            return `${startDate.toLocaleDateString()} - ${endDate.toLocaleDateString()}`;
        }
        return this.TraslationFuncation("CompetencyDashboard.SelectStartDateEndDate") as string;
    };

    formatToYYYYMMDD = (date: Date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    listAllUsersApiCall = (exportAll?: boolean) => {
        this.setState({ isLoading: true });
        this.setState({ isLoading: true })
        const headers = {
            "token": this.state.token
        };

        let queryParam = "";

        if (this.state.selectedDate) {
            queryParam += `start_date=${this.formatToYYYYMMDD(this.state.selectedDate[0])}&`;
            queryParam += `end_date=${this.formatToYYYYMMDD(this.state.selectedDate[1])}&`;
        }
        if (exportAll) {
            queryParam += `export=${exportAll}&`
        }
        if (this.state.status) {
            queryParam += `status=${this.state.status}&`
        }

        const competencyMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.ListOfScoreCardDetailsApiCallId = competencyMsg.messageId;
        competencyMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        competencyMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.ViewScoreBoadApiEndPoint}?${queryParam}&page=${this.state.page}&per_page=${this.state.perPage}`
        );
        competencyMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "GET"
        );
        runEngine.sendMessage(competencyMsg.id, competencyMsg);
    }

    GetListOfScoreCardDetailsApiResponse = (ApiCallId: string, response: CandidateAssessmentResponse) => {
        if (ApiCallId === this.ListOfScoreCardDetailsApiCallId) {
            if (response.download_url) {
                this.setState({
                    isLoading: false
                })
                this.DonwloadPdf(response.download_url)
            } else {
                this.SetListData(response)
            }
        }
    }

    DonwloadPdf = (download_url: string) => {
        const link = document.createElement('a');
        link.href = download_url;
        link.download = 'export.csv';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    SetListData = (response: CandidateAssessmentResponse) => {
        if (response.candidate_list.length > 0) {
            const Manipulated = response.candidate_list.map((item) => ({
                AssessmentCompleteDate: item.assessment_complete_date,
                AssessmentId: `${item.assessment_id}`,
                AssessmentTitle: item.assessment_title,
                CandidateId: `${item.candidate_id}`,
                CompetencyName: item.competency_name,
                FirstName: item.first_name,
                JobTitle: item.job_title,
                Objective: item.objective,
                PercentageScore: `${item.percentage_score}`,
                TotalQuestions: `${item.total_questions}`,
            }));

            this.setState({
                ViewScoreCardListData: Manipulated,
                isLoading: false,
                TotalRecord: response.total_assessments_count,
                PendingAssessments: response.pending_assessments_count,
                OverallAveragePercentage: response.overall_average_percentage,
            })
        } else {
            this.setState({
                ViewScoreCardListData: [],
                isLoading: false,
                TotalRecord: 0,
                OverallAveragePercentage: 0,
                PendingAssessments: 0,
            })
        }

    }

    NaviGateToAnyPage = (PageRoute: string) => {
        const navigateMsg = new Message(getName(MessageEnum.NavigationMessage));
        navigateMsg.addData(getName(MessageEnum.NavigationTargetMessage), PageRoute);
        navigateMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(navigateMsg);
    }

    BoxCondition = (PercentageScore: string) => {
        let Color: string = "";
        let Box: number = -1;
        if (Number(PercentageScore) >= 75) {
            Color = "#059669";
            Box = 3;
            return { Color, Box };
        } else if (Number(PercentageScore) >= 50) {
            Color = "#D97706";
            Box = 1;
            return { Color, Box };
        } else {
            Color = "#DC2626";
            Box = 0;
            return { Color, Box };
        }
    }

    openCalendar = () => {
        this.setState((prevState) => ({ calendarOpen: !prevState.calendarOpen }));
    };

    handleDateChange = (newDate: [Date, Date]) => {
        if (newDate) {
            this.setState({ tempDate: newDate });
        }
    };

    handleCalendarCancelBtn = () => {
        this.setState({ tempDate: null, selectedDate: null, calendarOpen: false, page: 1 },
            () => { this.listAllUsersApiCall() }
        );
    };

    handleCalendarSaveBtn = () => {
        if (this.state.tempDate) {
            this.setState({
                selectedDate: this.state.tempDate,
                tempDate: null,
                calendarOpen: false,
                page: 1
            }, () => { this.listAllUsersApiCall() });
        }
    };

    // Customizable Area End

}