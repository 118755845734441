import React from "react";
// Customizable Area Start
import {
    Box,
    Grid,
    styled,
    Typography,
    Button,
    Divider,
    Snackbar
} from "@mui/material";
import { emailImage, copyImage, rightArrow, companyImage, linkedinImage, twitterImage, boxImage, userDefaultImage } from "./assets";
import {DashboardHeaderWeb as DashboardHeader} from "../../dashboard/src/DashboardHeader.web";
// Customizable Area End

import UserProfileBasicController, {
    Props
} from "./UserProfileBasicController";
import { withTranslation } from "react-i18next";

export default class UserProfileBasicBlock extends UserProfileBasicController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    profileDetail = () => {
        return (
            <Wrapper>
                <Grid container className="profileDetailContainer">
                    <Grid item xs={12} className={" profilePhotoBox "+this.TrueOrFalseCondition(this.state.IsItEnglish," BoderRadiusForEnglish "," BoderRadiusForArabic ")}>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container>

                            <Grid item xs={3} md={2.5} xl={2}>
                                <Box className="profileImageMainBox">
                                    <Box className="profileImage">
                                        <img
                                            alt="Profile photo"
                                            src={this.state.userProfileData.data?.attributes.photo ? this.state.userProfileData.data?.attributes.photo : userDefaultImage.default}
                                            height={this.state.userProfileData.data?.attributes.photo ? "100%" : "40%"}
                                            width={this.state.userProfileData.data?.attributes.photo ? "100%" : "40%"}
                                            className="profileImagewidth"
                                        />
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xs={8} md={9}>
                                <Box className="userDetailBox">
                                    <Typography className="userName">{this.state.userProfileData.data?.attributes.full_name}</Typography>
                                    <Typography className="userCompanyDetail">{this.state.userProfileData.data?.attributes.company_name}</Typography>
                                    <Typography className="userBirthTitle">{this.TraslationFuncation("UserProfile.DateOfBirth")}</Typography>
                                    <Typography className="userBirthDetail">{this.state.userProfileData.data?.attributes.date_of_birth}</Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Wrapper>
        )
    }

    userEmailLink = () => {
        return (
            <Wrapper>
                <Grid container className="socialGridContainer radius1OnBox2">
                    <Grid item xs={3} sm={2} md={1} className="socialImageGrid">
                        <img src={emailImage.default} height="48px" width="48px" />
                    </Grid>
                    <Grid item xs={7} sm={8} md={10}>
                        <Typography className="socialTitle">{this.TraslationFuncation("UserProfile.Email")}</Typography>
                        <Typography className="socialDesc">{this.state.userProfileData.data?.attributes.email}</Typography>
                    </Grid>
                    <Grid item xs={2} md={1} className="socialImageGridMargin imagePosition">
                        <img src={copyImage.default}
                            height="24px"
                            width="24px"
                            className="imagePosition"
                            data-test-id="copy_text"
                            onClick={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => this.handleCopyToClipboard(this.state.userProfileData.data?.attributes.email || "", event)} />
                            <Snackbar
                            open={this.state.snackbarOpen}
                            autoHideDuration={1000}
                            message={this.state.snackbarMessage}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            sx={{
                                position: 'absolute',
                                top: this.state.snackbarPosition.top,
                                left: this.state.snackbarPosition.left,
                                width: "auto"
                            }}
                        />
                    </Grid>
                </Grid>
                <Divider />
            </Wrapper>
        )
    }
    userCompanyLink = () => {
        return (
            <Wrapper>
                <Grid container className="socialGridContainer">
                    <Grid item xs={3} sm={2} md={1} className="socialImageGrid">
                        <img src={companyImage.default} height="48px" width="48px" />
                    </Grid>
                    <Grid item xs={7} sm={8} md={10}>
                        <Typography className="socialTitle">{this.TraslationFuncation("UserProfile.Company")}</Typography>
                        <Typography className="socialDesc">{this.state.userProfileData.data?.attributes.website}</Typography>

                    </Grid>
                    <Grid item xs={2} md={1} className="socialImageGridMargin">
                        <img src={rightArrow.default} height="32px" width="32px" data-test-id="navigate_companylink" onClick={this.navigateToWebsite} />
                    </Grid>
                </Grid>
                <Divider />
            </Wrapper>
        )
    }
    userLinkedinLink = () => {
        return (
            <Wrapper>
                <Grid container className="socialGridContainer">
                    <Grid item xs={3} sm={2} md={1} className="socialImageGrid">
                        <img src={linkedinImage.default} height="48px" width="48px" />
                    </Grid>
                    <Grid item xs={7} sm={8} md={10}>
                        <Typography className="socialTitle">{this.TraslationFuncation("UserProfile.Linkedin")}</Typography>
                        <Typography className="socialDesc">{this.state.userProfileData.data?.attributes.linkedin}</Typography>

                    </Grid>
                    <Grid item xs={2} md={1} className="socialImageGridMargin">
                        <img src={rightArrow.default} height="32px" width="32px" data-test-id="navigate_linkedInlink" onClick={this.navigateToLinkedIn} />
                    </Grid>
                </Grid>
                <Divider />
            </Wrapper>
        )
    }
    userTwitterLink = () => {
        return (
            <Wrapper>
                <Grid container className="socialGridContainer radius2OnBox2">
                    <Grid item xs={3} sm={2} md={1} className="socialImageGrid">
                        <img src={twitterImage.default} height="48px" width="48px" />
                    </Grid>
                    <Grid item xs={7} sm={8} md={10}>
                        <Typography className="socialTitle">
                        {this.TraslationFuncation("UserProfile.Twitter")}</Typography>
                        <Typography className="socialDesc">{this.state.userProfileData.data?.attributes.twitter}</Typography>

                    </Grid>
                    <Grid item xs={2} md={1} className="socialImageGridMargin">
                        <img src={rightArrow.default} height="32px" width="32px" data-test-id="navigate_twitterlink" onClick={this.navigateToTwitter} />
                    </Grid>
                </Grid>
            </Wrapper>
        )
    }
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <DashboardHeader navigation={this.props.navigation} id={this.props.id}>
                <MainWrapper style={{ direction: this.props.i18n?.language === "ar" ? "rtl" : "ltr" }}>
                    <Grid container justifyContent={"center"}>
                        <Grid item xs={11}>
                            <Box className="editProfileMainBox">
                                <Button className="editProfileButton" data-test-id="edit_profile_page" onClick={this.navigateToEditProfile}>
                                    <Typography className="editProfileText">
                                    {this.TraslationFuncation("UserProfile.EditProfile")}
                                    </Typography>
                                </Button>
                            </Box>
                        </Grid>
                        <Grid item xs={10} className="profileDetailmargin">
                            {this.profileDetail()}
                        </Grid>
                        <Grid item xs={10} className="socialDetailmargin">
                            {this.userEmailLink()}
                        </Grid>
                        <Grid item xs={10}>
                            {this.userCompanyLink()}
                        </Grid>
                        <Grid item xs={10}>
                            {this.userLinkedinLink()}
                        </Grid>
                        <Grid item xs={10}>
                            {this.userTwitterLink()}
                        </Grid>
                    </Grid>
                </MainWrapper>
            </DashboardHeader>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
export const UserProfileBasicBlockWeb = withTranslation()(UserProfileBasicBlock);
export const Wrapper = styled(Box)({
    width: "100%",
    "& .profilePhotoBox": {
        height: "90px",
        position: "relative",
        backgroundImage: `url(${boxImage.default})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        width: '100%',
        "@media(max-width:750px)": {
            height: "70px",
        },
        "@media(max-width:500px)": {
            height: "55px",
        }
    },
    "& .BoderRadiusForEnglish": {
        borderRadius: "4px 4px 32px 4px",
    },
    "& .BoderRadiusForArabic": {
        borderRadius: "4px 4px 4px 32px",
    },
    "& .profileImageMainBox": {
        display: "flex",
        justifyContent: "center",
        paddingLeft: "20px",
        "@media(max-width:900px)": {
            justifyContent: "flex-end",
            paddingRight: "3vw",
        },
    },
    "& .profileImage": {
        width: "10vw",
        height: "10vw",
        maxWidth: "150px",
        maxHeight: "150px",
        minWidth: "50px",
        minHeight: "50px",
        borderRadius: "50%",
        border: "4px solid rgba(241, 245, 249, 1)",
        backgroundColor: "rgb(226, 232, 240)",
        opacity: "0px",
        zIndex: 10,
        position: "relative",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "-50px",
        "@media(max-width:750px)": {
            marginTop: "-40px",
        },
        "@media(max-width:500px)": {
            marginTop: "-30px",
        }
    },
    "& .userDetailBox": {
        paddingBottom: "60px",
        paddingLeft: "12px"
    },
    "& .userCompanyDetail": {
        fontFamily: "Urbanist",
        fontSize: "16px",
        fontWeight: 700,
        lineHeight: "24px",
        color: "#64748B",
        marginTop: "6px",
        "@media(max-width:1200px)": {
            fontSize: "14px",
            marginTop: "0px",
        },
        "@media(max-width:600px)": {
            fontSize: "14px",
            marginTop: "0px",
        },
    },
    "& .userName": {
        fontFamily: "Urbanist",
        fontSize: "30px",
        fontWeight: 700,
        lineHeight: "40px",
        color: "#000000",
        marginTop: "8px",
        "@media(max-width:1200px)": {
            fontSize: "26px",
        },
        "@media(max-width:600px)": {
            fontSize: "20px",
        },
    },
    "& .userBirthTitle": {
        fontFamily: "Urbanist",
        fontSize: "14px",
        fontWeight: 700,
        lineHeight: "22px",
        color: "#64748B",
        marginTop: "32px",
        "@media(max-width:1200px)": {
            fontSize: "16px",
            marginTop: "20px",
        },
        "@media(max-width:600px)": {
            fontSize: "14px",
            marginTop: "20px",
        },
    },

    "& .userBirthDetail": {
        fontFamily: "Urbanist",
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "24px",
        color: "#64748B",
        marginTop: "6px",
        "@media(max-width:1200px)": {
            marginTop: "0px",
        },
        "@media(max-width:600px)": {
            fontSize: "14px",
            marginTop: "0px",
        },
    },
    "& .socialTitle": {
        fontFamily: "Urbanist",
        fontSize: "14px",
        fontWeight: 400,
        lineWeight: "22px",
        color: "#64748B"
    },
    "& .socialDesc": {
        fontFamily: "Urbanist",
        fontSize: "18px",
        fontWeight: 700,
        lineWeight: "26px",
        color: "#334155",
        wordBreak: "break-word"
    },
    "& .radius1OnBox2": {
        borderRadius: "8px 8px 0px 0px"
    },
    "& .radius2OnBox2": {
        borderRadius: "0px 0px 8px 8px"
    },
    "& .socialGridContainer": {
        backgroundColor: "E2E8F0",
        padding: "16px 16px 16px 0px"
    },
    "& .socialImageGrid": {
        display: "flex",
        justifyContent: "center"
    },
    "& .socialImageGridMargin": {
        display: "flex",
        justifyContent: "center",
        marginTop: "10px"
    },
    "& .profileDetailContainer": {
        backgroundColor: "#E2E8F0", borderRadius: "8px"
    },
    "& .profileImagewidth": {
        maxWidth: "150px",
        borderRadius: "50%"
    },
    "& .imagePosition": {
        position: "relative"
    }
})
export const MainWrapper = styled(Box)({
    width: "100%",
    marginTop: "31px",
    display: "flex",
    justifyContent: "center",
    paddingBottom: "30px",
    "& .profileDetailmargin": {
        marginTop: "40px"
    },
    "& .socialDetailmargin": {
        marginTop: "20px"
    },
    "& .editProfileButton": {
        width: "40%",
        maxWidth: "218px",
        height: "58px",
        borderRadius: "4px",
        opacity: "0px",
        background: "var(--Brand, #044352)",
        "&:hover": {
            backgroundColor: "#044352",
        },
    },
    "& .editProfileText": {
        fontFamily: "Urbanist",
        fontSize: "16px",
        fontWeight: 700,
        lineHeight: "24px",
        textAlign: "center",
        color: "#FFFFFF",
        textTransform: "none"
    },
    "& .editProfileMainBox": {
        display: "flex",
        justifyContent: "flex-end"
    },
})
// Customizable Area End