import React from "react";


// Customizable Area Start
import {
  Box,
  Grid, styled,
  Card, Typography,
  Button, CardContent,
  FormControlLabel, Switch,
  InputAdornment, SwitchProps,
  Divider, Modal,
  Select, MenuItem,
  InputBase, TableContainer,
  Table, TableHead,
  TableRow, TableCell,
  TableBody, Checkbox,
  alpha,
  Pagination,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  TextField,
} from "@mui/material";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import MoreVertIcon from '@mui/icons-material/MoreVert'
import {
  arrow_Left,
  chnageRole,
  defaultIcon,
  DeleteIcon,
  deleteIcon,
  help,
  language,
  logout,
  notification,
  profile,
  progress,
  right, roles,
  talentProfile,
  userDefaultImage
} from "./assets";
import { DashboardHeaderWeb as DashboardHeader} from "../../dashboard/src/DashboardHeader.web";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { MainWrapper as EmptyDataScreenWrapper } from "../../cfchatgptintegration9/src/Cfchatgptintegration9.web"
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import CustomPopup from "../../../components/src/CustomSuccessModel.web";
import ChangePasswordModal from "../../../components/src/ChangePasswordModal.web";
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';

import { View, StyleSheet } from "react-native";


// Customizable Area End

import Settings2Controller, {
  Props,
  configJSON,
} from "./Settings2Controller";
import { Form, Formik } from "formik";
import { withTranslation } from "react-i18next";

export default class Settings2 extends Settings2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  profileTab = () => {
    return (
      <>
        <img
          src={profile.default}
          alt="ProfileSetting"
          style={{ marginLeft: this.ConditionValuePass(this.state.IsItEnglish, "", "10px") }}
          className="menuImg" />
        <Grid item >
          <Typography className='menuProfileTitle'> {this.TraslationFuncation("SettingsPage.ProfileSetting")} </Typography>
          <Typography marginTop={"10px"} className="menuSubTitle">{this.TraslationFuncation("SettingsPage.SettingsDescription")} </Typography>
        </Grid>
      </>
    ) 
}
notificationTab = () => {
    return (
      <>
        <img
          src={notification.default}
          alt="notificationSetting"
          style={{ marginLeft: this.ConditionValuePass(this.state.IsItEnglish, "", "10px") }}
          className="menuImg" />
        <Grid item >
          <Typography className='menuTitle'> {this.TraslationFuncation("SettingsPage.NotificationSettings")} </Typography>
          <Typography marginTop={"10px"} className="menuSubTitle"> {this.TraslationFuncation("SettingsPage.NotificationDescription")}
          </Typography>
        </Grid>
      </>
    )
}
languageTab = () => {
    return (
      <>
        <img
          src={language.default}
          alt="languageSettings"
          className="menuImg"
          style={{ marginLeft: this.ConditionValuePass(this.state.IsItEnglish, "", "10px") }}
        />
        <Grid item >
          <Typography className='menuTitle'> {this.TraslationFuncation("SettingsPage.LanguageSettings")}
          </Typography>
          <Typography marginTop={"10px"} className="menuSubTitle"> {this.TraslationFuncation("SettingsPage.ManageYourlanguages")}
          </Typography>
        </Grid>
      </>
    )
}
rolesPermissionTab = () => {
    return (
      <>
        <img
          src={roles.default}
          alt="rolesPermissionSetting"
          style={{ marginLeft: this.ConditionValuePass(this.state.IsItEnglish, "", "10px") }}
          className="menuImg" />
        <Grid item >
          <Typography className='menuTitle'> {this.TraslationFuncation("SettingsPage.RolesAndPermissions")}
          </Typography>
          <Typography marginTop={"10px"} className="menuSubTitle"> {this.TraslationFuncation("SettingsPage.ManageYourRolesAndPermissions")}
          </Typography>
        </Grid>
      </>
    )
  }
  talentProfileTab = () => {
    return (
      <>
        <img
          src={talentProfile.default}
          alt="talentProfileSetting"
          style={{ 
            marginLeft: this.ConditionValuePass(this.state.IsItEnglish, "", "10px")
          }}
          className="menuImg" 
          />
        <Grid item >
          <Typography className='menuTitle'>
            {this.TraslationFuncation("SettingsPage.TalentProfile")}
          </Typography>
          <Typography marginTop={"10px"} className="menuSubTitle">
            {this.TraslationFuncation("SettingsPage.ManageYourRolesAndPermissions")}
          </Typography>
        </Grid>
      </>
    )
  }
  helpTab = () => {
    return (
      <>
        <img
          src={help.default}
          alt="helpSetting"
          style={{
            marginLeft: this.ConditionValuePass(this.state.IsItEnglish, "", "10px")
          }}
          className="menuImg" />
        <Grid item >
          <Typography 
            className='menuTitle'>
            {this.TraslationFuncation("SettingsPage.HelpAndSupport")}
          </Typography>
          <Typography marginTop={"10px"} className="menuSubTitle">
            {this.TraslationFuncation("SettingsPage.AccessFAQsAndDifferentPolicies")}
          </Typography>
        </Grid>
      </>
    )
}

profileSettingScreen = () => {
  return (
    <Grid item xs={10} sm={8} md={9} lg={8}
    xl={7.5} className={"profilesettings "+" "+ this.ConditionValuePass(this.state.IsItEnglish," IsItEnglishTrue "," IsItEnglishFalse ") }>
    <Grid container justifyContent={"space-between"} className="jobMainHeaderGrid">
      <Grid item flexWrap={"wrap"}>
        <Box>
            <Typography className="profileText"> {this.TraslationFuncation("SettingsPage.YourPhoto")} </Typography>
        </Box>
      </Grid>
      <Grid item marginRight={"80px"} xs={3} md={2.5} xl={2}>
        <Box className="profileImageMain">
          <Box className="profileImage">
            {this.state.imageUpload ? <img src={this.state.profileImage[0]}
            style={{borderRadius:"50%"}}
            height={this.state.userProfileData.data?.attributes.photo ? "100%" : "40%"}
            width={this.state.userProfileData.data?.attributes.photo ? "100%" : "40%"} /> : <img src={this.state.userProfileData.data?.attributes.photo ? 
            this.state.userProfileData.data?.attributes.photo : 
            userDefaultImage.default}
            style={{borderRadius:"50%"}}
            height={this.state.userProfileData.data?.attributes.photo ? "100%" : "40%"}
            width={this.state.userProfileData.data?.attributes.photo ? "100%" : "40%"} />}
            <input data-test-id="upload_referenceImage" type="file" accept=".jpeg, .png" style={{display: "none"}} onChange={(event) => {this.handleImageChange(event.target.files)}} ref={this.state.imageRef} />
          </Box>
        </Box>
      </Grid>
      <Grid item className="buttonsprofile" marginRight={"26px"}>
        <Button className="profileButton" data-test-id="removeProfilePhoto" onClick={this.removeProfilePhoto}>
          {this.TraslationFuncation("Delete")}
        </Button>
        <Button data-test-id="handleImage" className="profileButton" onClick={() => this.handleImage()}>
          {this.TraslationFuncation("Update")}
        </Button>
      </Grid>
    </Grid>
    <Grid item xs={12} marginTop={"25px"}>
      <Divider
        sx={{
          color: "#CBD5E1",
          marginLeft: '26px', marginRight: '26px',
        }}
        />
    </Grid>
    <Grid className="PersonalDetailsContainer" xs={12} display={"flex"} justifyContent={"space-between"} marginTop={"25px"} flexWrap={"wrap"}>
        <Grid item >
          <Typography className="profileText">
        {this.TraslationFuncation("SettingsPage.PersonalDetails")}
          </Typography>
        </Grid>
        <Grid item xs={7.4}  className={"formField"+" " + this.ConditionValuePass(this.state.IsItEnglish," EnglishMargin ","ArabicMargin ")}>
          <Typography className="formText">
            {this.TraslationFuncation("SettingsPage.FullName")}
          </Typography>
          <input
            className="textfieldStyle"
            data-test-id="fullName"
            required
            name="fullName"
            value={this.state.userProfileData.data?.attributes.full_name}
          />
          <Typography marginTop={"20px"} className="formText">
            {this.TraslationFuncation("SettingsPage.Email")}
          </Typography>
          <input
            className="textfieldStyle"
            data-test-id="email"
            required
            name="email"
            value={this.state.userProfileData.data?.attributes.email}
          />
          <Typography marginTop={"20px"} className="formText">
            {this.TraslationFuncation("SettingsPage.CompanyName")}
          </Typography>
          <input
            className="textfieldStyle"
            data-test-id="companyName"
            required
            name="companyName"
            value={this.state.userProfileData.data?.attributes.company_name}
          />
          <Grid item display={"flex"} marginTop={"20px"} alignItems={"start"} justifyContent={"space-between"} flexWrap={"wrap"}>
            <Grid item>
              <Typography className="formText">
                {this.TraslationFuncation("SettingsPage.Password")}
              </Typography>
          <Typography>*********</Typography>
            </Grid>
          <Button className="profileButton"  
            style={{textDecoration:"underline" , textDecorationColor:"#044352"}}  data-test-id="changePassword" onClick={this.handleChangePassword}>
            {this.TraslationFuncation("SettingsPage.ChangePassword")}
          </Button>
          </Grid>
        </Grid>
    </Grid>
    <Grid item xs={12} marginTop={"35px"}>
        <Divider
          sx={{
            marginLeft: '26px',
            color: "#CBD5E1",
            marginRight: '26px',
          }}
        />
    </Grid>
    <Grid xs={12} marginTop={"25px"} display={"flex"} justifyContent={"space-between"} flexWrap={"wrap"}>
        <Grid item >
          <Typography className="profileText">
            {this.TraslationFuncation("SettingsPage.AccountActions")}
          </Typography>
        </Grid>
        <Grid item xs={7.4} className="formField" marginRight={"20px"}>
          <Grid item display={"flex"} alignItems={"center"} justifyContent={"start"}>
          <img src={logout.default}/>
          <Button className="profileButton" onClick={this.handleLogoutProfile}>
            {this.TraslationFuncation("SettingsPage.Logout")}
          </Button>
          </Grid>
          <Grid item marginTop={"30px"} marginBottom={"47px"} display={"flex"} alignItems={"center"} justifyContent={"start"}>
          <img src={deleteIcon.default}/>
            <Button className="deleteButton" onClick={this.handleDeleteAccount}>
              {this.TraslationFuncation("SettingsPage.DeleteAccount")}
            </Button>
            </Grid>
        </Grid>
    </Grid>
    <Modal
        open={this.state.logoutProfile}
        onClose={this.closeLogoutProfilePopupModal}>
        <CustomPopup
          data-test-id="logoutProfilePopupModal"
          subTitlePopup={`${this.TraslationFuncation("SettingsPage.LogoutSubTitle")}`}
          titlePopup={this.TraslationFuncation("SettingsPage.AreYouSureYouWantToLogout")}
          cancelButtonText={this.TraslationFuncation("Cancel")}
          onClose={this.closeLogoutProfilePopupModal} 
          headingPopup={`${this.TraslationFuncation("SettingsPage.LogoutConfirmation")}`}
          successIcon={defaultIcon.default}
          buttonText={`${this.TraslationFuncation("SettingsPage.Logout")}`}
          showCancelButton={true}
          onOpen={this.openLogoutProfilePopupModal}
          />
      </Modal>
      <Modal
        open={this.state.deleteAccount}
        onClose={this.closeDeleteAccountPopupModal}>
        <CustomPopup
          data-test-id="deleteAccountPopupModal"
          headingPopup={`${this.TraslationFuncation("SettingsPage.DeleteAccount")}`}
          titlePopup={this.TraslationFuncation("SettingsPage.AreYouSureYouWantToDeleteAccount")}
          checkBoxTitlePopup={this.TraslationFuncation("SettingsPage.IUnderstandTheConsequencesAndWishToProceed")}
          requiredCheckBoxError={this.TraslationFuncation("SettingsPage.ActionRequired")}
          cancelButtonText={this.TraslationFuncation("Cancel")}
          buttonText={`${this.TraslationFuncation("Delete")}`}
          subTitlePopup={`${this.TraslationFuncation("SettingsPage.DeleteAccountSubTitle")}`}
          checkBox={true}
          successIcon={defaultIcon.default}
          showCancelButton={true}
          onOpen={this.openDeleteAccountPopupModal}
          onClose={this.closeDeleteAccountPopupModal} 
          />
      </Modal>
      <Modal
        open={this.state.deleteSuccess}
        onClose={this.closeDeleteSuccessPopupModal}>
        <CustomPopup
          headingPopup={`${this.TraslationFuncation("SettingsPage.AccountDeletedSuccessfully")}`}
          subTitlePopup={`${this.TraslationFuncation("SettingsPage.AccountDeletedSuccessfullySubTitle")}`}
          deleteSuccess={true}
          buttonText={`${this.TraslationFuncation("SettingsPage.Done")}`}
          data-test-id="deleteSuccessPopupModal"
          successIcon={right.default}
          showCancelButton={false}
          onOpen={this.openDeleteSuccessPopupModal}
          onClose={this.closeDeleteSuccessPopupModal} />
      </Modal>
      <Modal
        open={this.state.changePassword}
        onClose={this.closeChangePasswordPopupModal}>
        <ChangePasswordModal
          data-test-id="changePasswordPopupModal"
          confirmPasswordTitle={`${this.TraslationFuncation("SettingsPage.ConfirmNewPassword")}`}
          confirmPasswordError = {this.TraslationFuncation("SettingsPage.RequiredField")}
          headingPopup={`${this.TraslationFuncation("SettingsPage.ChangePassword")}`}
          currentPasswordTitle={`${this.TraslationFuncation("SettingsPage.CurrentPassword")}`}
          currentPasswordError={this.TraslationFuncation("SettingsPage.RequiredField")}
          newPasswordTitle={`${this.TraslationFuncation("SettingsPage.NewPassword")}`}
          newPasswordError = {this.TraslationFuncation("SettingsPage.RequiredField")}
          cancelButtonText={`${this.TraslationFuncation("Cancel")}`}
          buttonText={`${this.TraslationFuncation("SettingsPage.SaveChanges")}`}
          onOpen={this.openChangePasswordPopupModal}
          onClose={this.closeChangePasswordPopupModal} 
          handlCurrentApi={this.apiCurrentPasswordGetCall}
          handleCurrentResponse = {this.state.currentPasswordResponse}
          />
      </Modal>
      <Modal      
        open={this.state.ChangedOpen}
        onClose={this.closeChangedModal}>
        <CustomPopup 
        data-test-id="custom-popup-changed"
        buttonText={`${this.TraslationFuncation("SettingsPage.Login")}`}
        headingPopup={`${this.TraslationFuncation("SettingsPage.PasswordChanged")}`} 
        titlePopup={this.TraslationFuncation("SettingsPage.PasswordChangedSuccessfully")} 
        subTitlePopup = {`${this.TraslationFuncation("SettingsPage.LoggingInAgain")}`}
        onOpen={this.openChangedModal}
        onClose={this.closeChangedModal}/>
      </Modal>
  </Grid>
  )
}

notificationSettingScreen = () => {
  return (
    <Grid item xs={10}
      sm={8}
      md={9}
      lg={8}
      xl={7.5} className={"notificationsettings" + " " + this.ConditionValuePass(this.state.IsItEnglish, "IsItEnglishTrue", "IsItEnglishFalse")} display={"flex"} flexDirection={"column"} justifyContent={"space-between"}>
      <Grid style={{ padding: this.ConditionValuePass(this.state.IsItEnglish, "0px", "0px 20px") }}>
        <Grid container justifyContent={"space-between"} className="notificationGrid">
          <Grid item>
            <Box marginLeft={"24px"}>
              <Typography className="notificationTitle">
                {this.TraslationFuncation("SettingsPage.NewJobAlertNotification")}
              </Typography>
              <Typography className="notificationSubTitle">
                {this.TraslationFuncation("SettingsPage.InformedAboutTheLatest")}
              </Typography>
            </Box></Grid>
          <Grid item><Box marginRight={"24px"} className="switchButton">
            <FormControlLabel control={<IOSSwitch data-test-id="switchButton1" sx={{ m: 1 }} defaultChecked />}
              label="" />
          </Box></Grid>
          <Grid item xs={12} marginTop={"36px"}><Divider sx={{ color: "#CBD5E1", marginLeft: '26px', marginRight: '26px'}} />
          </Grid>
        </Grid>
        <Grid container justifyContent={"space-between"} className="notificationGrid">
          <Grid item><Box marginLeft={"24px"}>
            <Typography className="notificationTitle">
              {this.TraslationFuncation("SettingsPage.AssessmentReminders")}
            </Typography>
            <Typography className="notificationSubTitle">
              {this.TraslationFuncation("SettingsPage.ReceiveTimelyRemindersForAllAssessments")}
            </Typography>
          </Box>
          </Grid>
          <Grid item> <Box marginRight={"24px"} className="switchButton">
            <FormControlLabel
              control={<IOSSwitch data-test-id="switchButton2" sx={{ m: 1 }} />} label="" />
          </Box></Grid>
          <Grid item xs={12} marginTop={"36px"}>
            <Divider sx={{ color: "#CBD5E1", marginLeft: '26px', marginRight: '26px',}} />
          </Grid>
        </Grid>
        <Grid container justifyContent={"space-between"} 
                  className="notificationGrid">
          <Grid item>
            <Box marginLeft={"24px"}>
              <Typography className="notificationTitle">
                {this.TraslationFuncation("SettingsPage.UpdatesFromTheOrganisation")}
              </Typography>
              <Typography className="notificationSubTitle">
                {this.TraslationFuncation("SettingsPage.KeepUpWithTheLatestNewsPoliciesAndAnnouncementsFromTheOrganisation")}
              </Typography>
            </Box></Grid>
          <Grid item>
            <Box marginRight={"24px"} className="switchButton">
              <FormControlLabel control={<IOSSwitch data-test-id="switchButton3" sx={{ m: 1 }} defaultChecked />}
                label=""
              /></Box>
          </Grid>
          <Grid item xs={12} marginTop={"36px"}> <Divider sx={{ color: "#CBD5E1", marginLeft: '26px', marginRight: '26px', }}
          />
          </Grid> </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} marginLeft={"26px"} marginRight={"26px"} marginBottom={"38px"}>
          <Button onClick={this.handleUpdateNotification}
            className="notificationButton" style={{ width: "100%", paddingTop: "20px", paddingBottom: "20px" }}
          >{this.TraslationFuncation("SettingsPage.SaveSettings")}
          </Button> </Grid></Grid>
      <Modal
        open={this.state.updateNotification}
        onClose={this.closeNotificationPopupModal}>
        <CustomPopup
          data-test-id="NotificationPopupModal"
          headingPopup={`${this.TraslationFuncation("SettingsPage.ChangesSaved")}`}
          titlePopup={this.TraslationFuncation("SettingsPage.NotificationPreferencesUpdated")}
          subTitlePopup={`${this.TraslationFuncation("SettingsPage.YourNotificationSuccessfullySaved")}`}
          buttonText={`${this.TraslationFuncation("SettingsPage.Done")}`}
          successIcon={right.default}
          showCancelButton={false}
          onOpen={this.openNotificationPopupModal}
          onClose={this.closeNotificationPopupModal} />
      </Modal>
    </Grid>
  )
}

languageSettingScreen = () => {
  return (
    <Grid item xs={10}
    sm={8}
    md={9}
    lg={8}
    xl={7.5}  className={"notificationsettings"+" "+ this.ConditionValuePass(this.state.IsItEnglish,"IsItEnglishTrue","IsItEnglishFalse")} display={"flex"} flexDirection={"column"} justifyContent={"space-between"}>
    <Grid>
  <Grid container className="notificationGrid">
    <Grid item>
      <Box marginLeft={"24px"}>
        <Typography className="notificationTitle">
        {this.TraslationFuncation("SettingsPage.LanguageSettings")}
        </Typography>
        <Typography className="notificationSubTitle" marginTop={"10px"}>
        {this.TraslationFuncation("SettingsPage.SelectYourPreferredLanguage")}
        </Typography>
      </Box>
    </Grid>
          <Grid item xs={12} marginTop={"36px"}>
            <Divider sx={{ color: "#CBD5E1", marginLeft: '26px', marginRight: '26px',}} />
        </Grid>
  </Grid>
  <Grid container  className="notificationGrid" >
    <Grid item  xs={7.5}>
      <Box marginLeft={"24px"} >
        <Typography className="languageTitle">
        {this.TraslationFuncation("SettingsPage.ChooseYourLanguage")}</Typography>
        <Select
            sx={{
              "& .MuiSelect-icon": {
                transform: 'none !important',
              },
            }}
            data-test-id="selectedLanguage"
            className={`dropDownStyle`}
            variant="outlined"
            name="selectedLanguage"
            value={this.state.selectedLanguage}
            displayEmpty
            onChange={this.handleLanguageChange}
            IconComponent={KeyboardArrowRightIcon}
            input={<InputBase />}
        >
            {this.state.languageChange?.map((option : any , index:number) => (
                <MenuItem key={index} value={option}>
                    {option}
                </MenuItem>
            ))}
        </Select>
      </Box>
    </Grid>  
  </Grid>
</Grid>
<Grid container>
  <Grid item xs={12} marginLeft={"26px"} marginRight={"26px"} marginBottom={"38px"} className="savebutton">
    <Button onClick={this.handlelanguageChangeSuccess} data-test-id="languagebutton"
      className="notificationButton"  
      style={{ width: "100%", paddingTop:"20px", paddingBottom:"20px"}} 
    >{this.TraslationFuncation("SettingsPage.SaveSettings")}
    </Button> </Grid></Grid>
    <Modal      
        open={this.state.languageChangeSuccess}
        onClose={this.closelanguageChangeModal}>
        <CustomPopup 
        data-test-id="languageChangeSuccess"
        headingPopup={`${this.TraslationFuncation("SettingsPage.LanguageSetSuccessfully")}`} 
        titlePopup={this.TraslationFuncation("SettingsPage.LanguageChanged")} 
        subTitlePopup = {`${this.TraslationFuncation("SettingsPage.YouHaveSuccessfullyChangedTheLanguageTo")} ${this.state.selectedLanguage}.`}
        buttonText={`${this.TraslationFuncation("SettingsPage.Done")}`}
        onOpen={this.openlanguageChangeModal}
        onClose={this.closelanguageChangeModal}/>
      </Modal>
  </Grid>
  )
}

rolespermissionSettingScreen = () => {

  return (
    <Grid item xs={10}
      sm={8}
      md={9}
      lg={8}
      xl={7.5} className={"notificationsettings" + " " + this.ConditionValuePass(this.state.IsItEnglish, "IsItEnglishTrue", "IsItEnglishFalse")} 
        display={"flex"} flexDirection={"column"} justifyContent={"space-between"}>
      <Grid>
        <Grid container 
          justifyContent={"space-between"} className="notificationGrid">
          <Grid container 
            display={"flex"} 
              gap={'1rem'} 
                className="headerTab" 
                alignItems={"center"} flexWrap={"wrap"} sx={{ width: "100%", paddingX: "26px", textAlign: "center" }}>
            <Box
              data-test-id="HRSubadmin"
              onClick={() => this.handleTabClick("HRSubadmin", "SubAdmin")}
              className={this.ConditionValuePass(this.state.activeTab === "HRSubadmin", "defineRatingTabs", "")}
            >
              <Typography className={this.ConditionValuePass(this.state.activeTab === "HRSubadmin", "rolesTitle", "disableText")}>{this.TraslationFuncation("SettingsPage.HRSubAdmin")} </Typography>
            </Box>

            <Box
              data-test-id="ManageEmployeeData"
              onClick={() => this.handleTabClick("ManageEmployeeData", "employee")}
              className={this.state.activeTab === "ManageEmployeeData" ? "defineRatingTabs" : ""}
            >
              <Typography className={
                this.ConditionValuePass(this.state.activeTab === "ManageEmployeeData", "rolesTitle", "disableText")}>
                {this.TraslationFuncation("SettingsPage.ManageEmployeeData")}
              </Typography>
            </Box>

            <Box
              data-test-id="ManageCandidates"
              onClick={() => this.handleTabClick("ManageCandidates", "candidate")}
              className={this.ConditionValuePass(this.state.activeTab === "ManageCandidates", "defineRatingTabs", "")}
            >
              <Typography
                className={this.ConditionValuePass(this.state.activeTab === "ManageCandidates", "rolesTitle", "disableText")}
              >{this.TraslationFuncation("SettingsPage.ManageCandidate")} </Typography>
            </Box>
          </Grid>

          <Grid item xs={12} marginTop={"36px"}><Divider
            sx={{ color: "#CBD5E1", marginLeft: '26px',marginRight: '26px',}} />
          </Grid>
        </Grid>
        {
          this.state.activeTab === "HRSubadmin" &&
          <>
            <Grid container justifyContent={"space-between"} className="notificationGrid">
              <Grid item>
                <Box marginLeft={"24px"}>
                  <Typography className="notificationTitle">
                    {this.TraslationFuncation("SettingsPage.PermissionsForTheHRSubAdminSettings")}
                  </Typography>
                  <Typography className="notificationSubTitle">
                    {this.TraslationFuncation("SettingsPage.SelectYourPreferredPermissionsToCustomiseThePlatformExperienceToYourLiking")}
                  </Typography>
                </Box>
              </Grid>

              <Grid item xs={12} marginTop={"36px"}><Divider
                sx={{ color: "#CBD5E1", marginLeft: '26px', marginRight: '26px', }} />
              </Grid>
            </Grid>
            <Grid container
              justifyContent={"space-between"}
              className="notificationGrid">
              <Grid item><Box marginLeft={"24px"}>
                <Typography className="notificationTitle">
                  {this.TraslationFuncation("SettingsPage.CompanyDetails")}
                </Typography>
                <Typography className="notificationSubTitle">
                  {this.TraslationFuncation("SettingsPage.AllowsAccessOfTheOrganisationsCoreInformationIncludingCompanyProfileAndContactDetails")}
                </Typography>
              </Box></Grid>
              <Grid item>
                <Box marginRight={"24px"} className="switchButton">
                  <IOSSwitch
                    sx={{ m: 1 }}
                    checked={this.state.companyDetails}
                    name="checkedC"
                    data-test-id="switch2"
                    onChange={this.handleCompanyDetailsChange}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} marginTop={"36px"}><Divider sx={{ color: "#CBD5E1", marginLeft: '26px', marginRight: '26px',}}
              />
              </Grid> </Grid>
            <Grid container 
              justifyContent={"space-between"}

              className="notificationGrid">   <Grid item> <Box marginLeft={"24px"}>
                <Typography className="notificationTitle">
                  {this.TraslationFuncation("SettingsPage.JobDescription")}
                </Typography>
                <Typography className="notificationSubTitle">
                  {this.TraslationFuncation("SettingsPage.EnablesTheCreationEditingAndManagementOfJobDescriptionsForVariousRolesWithinTheCompany")}
                </Typography>
              </Box> </Grid>
              <Grid item>
                <Box marginRight={"24px"} className="switchButton">
                  <IOSSwitch
                    sx={{ m: 1 }}
                    name="checkedC"
                    data-test-id="switch3"
                    checked={this.state.JobDescription}
                    onChange={this.handleJobDescriptionChange}
                  />
                </Box></Grid>
              <Grid item xs={12} marginTop={"36px"}><Divider sx={{ color: "#CBD5E1", marginLeft: '26px', marginRight: '26px',
              }}
              />
              </Grid>
            </Grid>
            <Grid container justifyContent={"space-between"} className="notificationGrid">
              <Grid item>
                <Box marginLeft={"24px"}>
                  <Typography className="notificationTitle">
                    {this.TraslationFuncation("SettingsPage.Competencies")}
                  </Typography>
                  <Typography className="notificationSubTitle">
                    {this.TraslationFuncation("SettingsPage.GrantsTheAbilityToDefineAssignAndManageCompetenciesRequiredForDifferentJobRoles")}
                  </Typography>
                </Box>
              </Grid>
              <Grid item>
                <Box marginRight={"24px"} className="switchButton">
                  <IOSSwitch
                    data-test-id="switch4"
                    sx={{ m: 1 }} name="checkedC"
                    onChange={this.handleCompetenciesChange}
                    checked={this.state.competencies}
                  />
                </Box></Grid>
              <Grid item xs={12} marginTop={"36px"}><Divider
                sx={{
                  color: "#CBD5E1", marginLeft: '26px',
                  marginRight: '26px',
                }}
              />
              </Grid></Grid>
            <Grid container justifyContent={"space-between"} className="notificationGrid">
              <Grid item>
                <Box marginLeft={"24px"}>
                  <Typography className="notificationTitle">
                    {this.TraslationFuncation("SettingsPage.CriticalPositions")}
                  </Typography>
                  <Typography className="notificationSubTitle">
                    {this.TraslationFuncation("SettingsPage.ManagementOfCriticalPositionsBasedOnStrategicImpactFinancialImpactAndOtherKeyFactors")}
                  </Typography>
                </Box>
              </Grid>
              <Grid item>
                <Box marginRight={"24px"} 
                className="switchButton">
                  <IOSSwitch
                    data-test-id="switch5" 
                    sx={{ m: 1 }} name="checkedC"
                    checked={this.state.CriticalPosition}
                    onChange={this.handleCriticalPositionChange}
                  />
                </Box></Grid>
              <Grid item xs={12} marginTop={"36px"}><Divider
                sx={{
                  color: "#CBD5E1", marginLeft: '26px', marginRight: '26px',
                }}
              />
              </Grid>
            </Grid>
            <Grid container 
            justifyContent={"space-between"} className="notificationGrid">
              <Grid item>
                <Box marginLeft={"24px"}>
                  <Typography 
                  className="notificationTitle">
                    {this.TraslationFuncation("SettingsPage.AllUsers")}
                  </Typography>
                  <Typography 
                  className="notificationSubTitle">
                    {this.TraslationFuncation("SettingsPage.ProvidesAccessToViewManageAndUpdateUserInformation")}
                  </Typography>
                </Box>
              </Grid>
              <Grid item>
                <Box marginRight={"24px"} 
                className="switchButton">

                  <IOSSwitch
                    data-test-id="switch6" sx={{ m: 1 }} name="checkedC"
                    checked={this.state.manageAllUsers} onChange={this.handleManageAllUsersChange}
                  />

                </Box></Grid>
              <Grid item xs={12} marginTop={"36px"}><Divider
                sx={{
                  color: "#CBD5E1",
                   marginLeft: '26px',
                  marginRight: '26px',
                }}
              />
              </Grid></Grid>
            <Grid container justifyContent={"space-between"} className="notificationGrid">
              <Grid item><Box marginLeft={"24px"}>
                <Typography className="notificationTitle"> {this.TraslationFuncation("SettingsPage.QuestionBank")}</Typography>
                <Typography className="notificationSubTitle">
                  {this.TraslationFuncation("SettingsPage.AllowsTheCreationEditingAndOrganizationOfQuestionsForAssessmentsIncludingTheAbilityTo")}
                </Typography>
                <Typography className="notificationSubTitle">
                  {this.TraslationFuncation("SettingsPage.SetDifficultyLevelsAndCompetencies")}</Typography>
              </Box>
              </Grid>
              <Grid item> <Box marginRight={"24px"} className="switchButton">
                <IOSSwitch
                  data-test-id="switch7"
                  sx={{ m: 1 }} name="checkedC"
                  onChange={this.handleQuestionBankChange}
                  checked={this.state.questionBank}
                />
              </Box></Grid>
              <Grid item
                xs={12} marginTop={"36px"}>
                <Divider sx={{
                  color: "#CBD5E1",
                  marginLeft: '26px',
                       marginRight: '26px',
                }} />
              </Grid>
            </Grid>
            <Grid container justifyContent={"space-between"}
              className="notificationGrid">
              <Grid item>
                <Box marginLeft={"24px"}>
                  <Typography className="notificationTitle">
                    {this.TraslationFuncation("SettingsPage.Assessments")}
                  </Typography>
                  <Typography className="notificationSubTitle">
                    {this.TraslationFuncation("SettingsPage.EnablesTheGenerationAssignmentAndReviewOfAssessmentsIncludingScoringAndResultAnalysis")}
                  </Typography>
                </Box></Grid>
              <Grid item> <Box marginRight={"24px"} className="switchButton">
                <IOSSwitch
                  data-test-id="switch8"
                  sx={{ m: 1 }}
                  name="checkedC"
                  checked={this.state.assessments}
                  onChange={this.handleAssessmentsChange}
                />
              </Box>
              </Grid>
              <Grid item xs={12} marginTop={"36px"}> <Divider
                sx={{
                  color: "#CBD5E1", marginLeft: '26px', marginRight: '26px',
                }}
              />
              </Grid>
            </Grid>
            {this.SuccessSettingDialogChange()}
            <Grid container>
              <Grid item xs={12} marginTop={"50px"} 
              marginLeft={"26px"} marginRight={"26px"}
               marginBottom={"38px"}>
                <Button data-test-id="handleHRGubAdminSaveSettings"
                 onClick={this.handleHRGubAdminSaveSettings}
                  className="notificationButton" style={{ width: "100%", paddingTop: "20px", paddingBottom: "20px" }}
                >
                  {this.TraslationFuncation("SettingsPage.SaveSettings")}
                </Button> </Grid>
            </Grid>
          </>
        }
        {
          this.state.activeTab === "ManageEmployeeData" &&
          <>
            <Grid container className="notificationGrid">
              <Search style={{
                flex: 1, border: "1px solid #94A3B8", 
                display: "flex", flexDirection: "row-reverse",
                marginLeft: "26px", marginRight: "26px"
              }}>
                <StyledInputBase onKeyDown={this.handleKeyDown} data-test-id="ManageEmployeSearch"
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton data-test-id="SearchEmploye" onClick={this.SearchEmployeeApiCall} edge="end">
                        <SearchIcon style={{ color: "black" }} />
                      </IconButton>
                    </InputAdornment>
                  }
                  onChange={this.SearchEmployee} value={this.state.SearchTextEmployee} placeholder={this.TraslationFuncation("SettingsPage.SearchEmployee")} fullWidth inputProps={{ 'aria-label': 'search' }}
                />
              </Search>
              <TableContainer
                sx={{ marginLeft: "26px", marginRight: "26px", marginTop: "26px" }}>
                <Table> <TableHead>
                  <TableRow>
                    <TableCell className={"tableDataText" + this.ConditionValuePass(this.state.IsItEnglish, "", " IsItArabicTrue ")}>
                      {this.TraslationFuncation("SettingsPage.EmployeeName")}
                    </TableCell>
                    <TableCell align="center" className={"tableDataText" + this.ConditionValuePass(this.state.IsItEnglish, "", " IsItArabicTrue ")}>
                      {this.TraslationFuncation("SettingsPage.Designation")}
                    </TableCell>
                    <TableCell align="right" className={"tableDataText" + this.ConditionValuePass(this.state.IsItEnglish, "", " IsItArabicTrue ")}>
                      {this.TraslationFuncation("SettingsPage.Department")}
                    </TableCell>
                    <TableCell align="center" sx={{ paddingLeft: "8rem" }} className={"tableDataText" + this.ConditionValuePass(this.state.IsItEnglish, "", " IsItArabicTrue ")}>
                      {this.TraslationFuncation("SettingsPage.Action")}
                    </TableCell></TableRow>
                </TableHead>
                  <TableBody className={this.ConditionValuePass(this.state.IsItEnglish,"IsItEnglishTrue","IsItEnglishFalse")}>
                    {this.state.employeeRole?.map((data: any) => (
                      <>
                        <TableRow>
                          <TableCell style={{ whiteSpace: "nowrap" }} className={"tableDataText" + this.ConditionValuePass(this.state.IsItEnglish, "", " IsItArabicTrue ")}>
                            <Checkbox
                              data-test-id="checkbox"
                              checked={this.state.selectedEmployees.includes(data.id)}
                              onChange={() => this.handleCheckboxChange(data)}
                              sx={{ height: "20px", marginInline: "5px", padding: "4px" }} />
                            {data.firstName + " " + data.lastName}
                          </TableCell>
                          <TableCell align="center"
                            className={"tableDataText" + this.ConditionValuePass(this.state.IsItEnglish, "", " IsItArabicTrue ")}>
                            {data.designation}
                          </TableCell>
                          <TableCell
                            align="right" className={"tableDataText" + this.ConditionValuePass(this.state.IsItEnglish, "", " IsItArabicTrue ")}>
                            {data.department}</TableCell>
                          <TableCell align="right"><Button
                            data-test-id="rolechangeButton"
                            disabled={!this.state.selectedEmployees.includes(data.id) || this.state.selectedEmployees?.length > 1}
                            onClick={() => this.handleChangeRole(data)}
                            sx={{
                              border: "1px solid #94A3B8",
                              padding: "10px 16px", textTransform: "none",
                              color: "#475569", 
                              
                              
                              fontFamily: "Urbanist",
                              fontSize: "14px", fontWeight: "700",
                              lineHeight: "22px",
                            }}
                          >
                            {this.TraslationFuncation("SettingsPage.ChangeToHRSubAdmin")}
                          </Button>
                          </TableCell>
                        </TableRow>
                      </>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            {
              this.state.employeeRole?.length !== 0 &&
              <Grid container justifyContent="center" sx={{ mt: 3 }}>
                <Box
                  sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                  <Pagination
                    count={Math.ceil(this.state.TotalEmployeeRecord / this.state.PerPageDataRender)}
                    color="primary"
                    style={{ direction: "ltr" }}
                    data-test-id="EmployeeRolePagination"
                    onChange={this.EmployeeRolePaginationFuncation}
                    siblingCount={0}
                    boundaryCount={1}
                  />
                </Box>
              </Grid>
            }
            {this.state.employeeRole.length === 0 && this.NoDataScreen()}
            <Grid container>
              <Grid item xs={12} marginTop={"50px"} marginLeft={"26px"} marginRight={"26px"} >
                <Button
                  data-test-id="handleManageEmployeeSaveSettings"
                  onClick={this.handleManageEmployeeSaveSettings}
                  disabled={this.state.selectedEmployees?.length === 0}
                  className="notificationButton"
                  style={{ width: "100%", paddingTop: "20px", paddingBottom: "20px" }}
                >
                  {this.TraslationFuncation("SettingsPage.ChangeToHRSubAdmin")}
                </Button>
              </Grid>
            </Grid>
          </>}
        {this.state.activeTab === "ManageCandidates" &&
          <>
            <Grid container
              className="notificationGrid">
              <Search style={{
                flex: 1, border: "1px solid #94A3B8", display: "flex", flexDirection: "row-reverse",
                marginLeft: "26px",
                marginRight: "26px"
              }}>
                <StyledInputBase
                  onKeyDown={this.handleKeyDown}
                  data-test-id="ManageCandidatesSearch"
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton data-test-id="SearchCandidate" onClick={this.SearchCandidateApiCall} edge="end">
                        <SearchIcon style={{ color: "black" }} />
                      </IconButton>
                    </InputAdornment>
                  }
                  placeholder={this.TraslationFuncation("SettingsPage.SearchCandidate")} value={this.state.SearchTextCandidate} onChange={this.SearchCandidate} fullWidth inputProps={{ 'aria-label': 'search' }}
                /></Search>
              <TableContainer sx={{ marginLeft: "26px", marginRight: "26px", marginTop: "26px" }}>
                <Table>
                  <TableHead><TableRow>
                    <TableCell className={"tableDataText" + this.ConditionValuePass(this.state.IsItEnglish, "", " IsItArabicTrue ")}>
                      {
                        this.TraslationFuncation("SettingsPage.EmployeeName")
                      }
                    </TableCell>
                    <TableCell align="center" className={"tableDataText" + this.ConditionValuePass(this.state.IsItEnglish, "", " IsItArabicTrue ")}>
                      {this.TraslationFuncation("SettingsPage.Designation")}
                    </TableCell>
                    <TableCell align="right" className={"tableDataText" + this.ConditionValuePass(this.state.IsItEnglish, "", " IsItArabicTrue ")}>
                      {this.TraslationFuncation("SettingsPage.Department")}</TableCell>
                    <TableCell align="center" sx={{ paddingLeft: "8rem" }}
                      className={"tableDataText" + this.ConditionValuePass(this.state.IsItEnglish, "", " IsItArabicTrue ")}>
                      {this.TraslationFuncation("SettingsPage.Action")}
                    </TableCell>
                  </TableRow></TableHead>
                  <TableBody className={this.ConditionValuePass(this.state.IsItEnglish,"IsItEnglishTrue","IsItEnglishFalse")}>
                    {this.state.candidateRole.map((data: any) => (
                      <TableRow>
                        <TableCell style={{ whiteSpace: "nowrap" }} className={"tableDataText" + this.ConditionValuePass(this.state.IsItEnglish, "", " IsItArabicTrue ")}>
                          <Checkbox
                            data-test-id="checkCandidate"
                            checked={this.state.selectedCandidate.includes(data.id)}
                            onChange={() => this.handleCheckboxCandidateChange(data)}
                            sx={{ height: "20px", marginInline: "5px", padding: "4px" }}
                          />
                          {data.firstName + " " + data.lastName}
                        </TableCell>
                        <TableCell align="center" className={"tableDataText" + this.ConditionValuePass(this.state.IsItEnglish, "", " IsItArabicTrue ")}>{data.designation}
                        </TableCell><TableCell
                          align="right"
                          className={"tableDataText" + this.ConditionValuePass(this.state.IsItEnglish, "", " IsItArabicTrue ")}>
                          {data.department}
                        </TableCell>
                        <TableCell align="right">
                          <Button
                            data-test-id="rolesChangesCandidateButton"
                            disabled={!this.state.selectedCandidate.includes(data.id) || this.state.selectedCandidate?.length > 1}
                            onClick={() => this.handleChangeCandidateRole(data)}
                            sx={{
                              border: "1px solid #94A3B8",
                              padding: "10px 16px", textTransform: "none",
                              color: "#475569", fontFamily: "Urbanist",
                              fontSize: "14px", fontWeight: "700",
                              lineHeight: "22px",
                            }}>
                            {this.TraslationFuncation("SettingsPage.ChangeToEmployee")}
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            {
              this.state.candidateRole?.length !== 0 &&
              <Grid container justifyContent="center" sx={{ mt: 3 }}>
                <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                  <Pagination
                    data-test-id="CandidateRolePagination"
                    onChange={this.CandidateRolePaginationFuncation}
                    count={Math.ceil(this.state.TotalCandidateRecord / this.state.PerPageDataRender)}
                    color="primary"
                    siblingCount={0}
                    style={{ direction: "ltr" }}
                    boundaryCount={1}
                  />
                </Box>
              </Grid>
            }
            {this.state.candidateRole?.length === 0 && this.NoDataScreen()}
            <Grid container>
              <Grid item xs={12} marginTop={"20px"} marginLeft={"26px"} marginRight={"26px"}>
                <Button disabled={this.state.ModelShowEmployeeData.length === 0} data-test-id="handleManageCandidateSaveSettings" onClick={this.handleManageCandidateSaveSettings}
                  className="notificationButton"
                  style={{
                    width: "100%", paddingTop: "20px",
                    paddingBottom: "20px"
                  }}
                >{this.TraslationFuncation("SettingsPage.ChangeToEmployee")}
                </Button> </Grid>
            </Grid>
          </>
        }
      </Grid>
    </Grid>
  )
}

helpSupportSettingScreen = () => {
  return (
    <Grid item xs={10}
    sm={8}
    md={9}
    lg={8}
    xl={7.5}   className={"notificationsettings"+" "+ this.ConditionValuePass(this.state.IsItEnglish,"IsItEnglishTrue","IsItEnglishFalse")} display={"flex"} flexDirection={"column"} justifyContent={"space-between"}>
    <Grid>
  <Grid container className="helpSupportGrid">
    <Grid item>
      <Box marginLeft={"24px"}>
        <Typography className="helpSupportTitle">{this.TraslationFuncation("SettingsPage.HelpAndSupport")}</Typography>
        <Typography className="notificationSubTitle" marginTop={"10px"}>
          {this.TraslationFuncation("SettingsPage.PleaseFillOutTheFormBelowToContactOurSupportTeamWellGetBackToYouAsSoonAsPossible")}
        </Typography>
      </Box>
    </Grid>
    <Grid item xs={12} marginTop={"36px"}><Divider
      sx={{color: "#CBD5E1",marginLeft: '26px',marginRight: '26px',
      }} 
     />
  </Grid></Grid>
  <Grid  container  xs={12}  display="flex"  justifyContent="space-between"  flexWrap="wrap" gap={2}
  marginTop={"24px"}>
  <Grid  item  xs={12} sm={5.7}
    className="textfield1"
  >
    <Box marginLeft="24px">
      <Box className="textFields">
      <Typography className="languageTitle"> {this.TraslationFuncation("SettingsPage.FirstName")}*</Typography>
      {this.state.touched.firstName && this.state.errors.firstName !== "" && (
        <Typography className="errorText">{this.state.errors.firstName}</Typography>
      )}
      </Box>
      <input
        className="textfieldStyle"
        data-test-id="first_name"
        required
        name="firstName"
        placeholder={this.TraslationFuncation("SettingsPage.Enter")+" "+this.TraslationFuncation("SettingsPage.FirstName")}
        value={this.state.firstName}
        onChange={this.handleFirstNameChange}
        onBlur={this.handleFirstNameBlur}
      />
    </Box>
  </Grid>  

  <Grid 
    item 
    xs={12} 
    sm={5.7} 
    className="textfield"
  >
    <Box marginRight="24px">
    <Box className="textFields">
      <Typography className="languageTitle">
      {this.TraslationFuncation("SettingsPage.LastName")}</Typography>
      {this.state.touched.lastName && this.state.errors.lastName !== "" && (
        <Typography className="errorText">{this.state.errors.lastName}</Typography>
      )}
      </Box>
      <input
        className="textfieldStyle"
        data-test-id="lastName"
        required
        name="lastName"
        placeholder={this.TraslationFuncation("SettingsPage.Enter")+" "+this.TraslationFuncation("SettingsPage.LastName")}
        value={this.state.lastName}
        onChange={this.handleLastNameChange}
        onBlur={this.handleLastNameBlur}
      />
    </Box>
  </Grid>  
</Grid>
<Grid  container  xs={12}  marginTop="25px"  display="flex"  justifyContent="space-between"  flexWrap="wrap"
  gap={2}
>
  <Grid 
    item 
    xs={12} 
    sm={5.7}
    className="textfield1"
  >
    <Box marginLeft="24px">
    <Box className="textFields">
      <Typography className="languageTitle">{this.TraslationFuncation("SettingsPage.PhoneNumberOptional")}</Typography>
      {this.state.touched.phoneNumber && this.state.errors.phoneNumber !== "" && (
        <Typography className="errorText">{this.state.errors.phoneNumber}</Typography>
      )}
      </Box>
      <TextField
                    fullWidth
                    data-test-id="contact_number"
                    variant="outlined"
                    placeholder="(555) 000-0000"
                    value={this.state.phoneNumber}
                    onChange={this.handlePhoneNumberChange}
                    sx={{
                        lineHeight: "20px",
                        '& .MuiOutlinedInput-root': {
                          minHeight:"60px",
                            "borderRadius": "8px",
                            '&:focus-visible': {
                                border: "none"
                            },
                            '&:hover': {
                                border: "none",
                                outline: "none"
                            },
                        },
                        "& .MuiSelect-icon": {
                            transform: 'none !important',
                        },
                        "& .MuiOutlinedInput-input": {
                            padding: "14px 16px"
                        },
                        "& .MuiSelect-select-MuiInputBase-input-MuiInput-input:focus": {
                            backgroundColor: "none",
                            border: "1px solid lightgray"
                        },
                        "& .MuiInputBase-root-MuiOutlinedInput-root": {
                            borderRadius: "8px"
                        },
                    }}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Select
                                    data-test-id="country_code"
                                    className="countryCodeText"
                                    value={this.state.selectedCountryCode}
                                    onChange={this.handleCountryCodeChange}
                                    variant="standard"
                                    IconComponent={(props) => <IconButton {...props} sx={{ padding: 0 }}><ExpandMoreIcon /></IconButton>}
                                    disableUnderline
                                    sx={{ minWidth: 70 }}
                                    MenuProps={{
                                        PaperProps: {
                                            sx: {
                                                maxHeight: 48 * 4,
                                                width: 'auto',
                                                overflowY: 'auto',
                                                marginLeft: "-10px"
                                            },
                                        },
                                    }}
                                >
                                    {this.state.countryCodeData.data?.map((code) => (
                                        <MenuItem key={code.country_name} value={`${code.country_code}`}>
                                            {code.country_code}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </InputAdornment>
                        ),
                    }}
                />
    </Box>
  </Grid>  

  <Grid 
    item 
    xs={12} 
    sm={5.7} 
    className="textfield"
  >
    <Box marginRight="24px">
    <Box className="textFields">
      <Typography className="languageTitle">{this.TraslationFuncation("SettingsPage.EmailAddress")}</Typography>
      {this.state.touched.emailForHelp && this.state.errors.emailForHelp !== "" && (
        <Typography className="errorText">{this.state.errors.emailForHelp}</Typography>
      )}
      </Box>
      <input
        className="textfieldStyle"
        data-test-id="emailAddress"
        required
        name="emailAddress"
        value={this.state.emailForHelp}
        placeholder={this.TraslationFuncation("SettingsPage.Enter")+" "+this.TraslationFuncation("SettingsPage.EmailAddress")}
        onChange={this.handleEmailChange}
        onBlur={this.handleEmailBlur}
      />
    </Box>
  </Grid>  
</Grid>
<Grid  container  xs={12}  marginTop="25px" 
  display="flex" 
  justifyContent="space-between"  flexWrap="wrap" gap={2}
>
  <Grid 
    item 
    xs={12} 
  >
    <Box marginLeft="24px" marginRight="24px">
    <Box className="textFields">
      <Typography className="languageTitle">
      {this.TraslationFuncation("SettingsPage.WhatDoYouNeedHelpWith")}
      </Typography>
      {this.state.touched.helpRequest && this.state.errors.helpRequest !== "" && (
        <Typography className="errorText">{this.state.errors.helpRequest}</Typography>
      )}
      </Box>
      <textarea className="textfieldStyle1"
        data-test-id="helpRequest"
        required
        name="helpRequest"
        value={this.state.helpRequest}
        placeholder={this.TraslationFuncation("SettingsPage.DescribeYourIssueOrQuestionInDetail")}
        style={{resize:"none"}}
        onChange={this.handleHelpRequestChange}
        onBlur={this.handleHelpRequestBlur}/>
    </Box>
  </Grid>   
</Grid>
</Grid>
<Grid container>
  <Grid item xs={12} marginLeft={"26px"} marginRight={"26px"} marginBottom={"38px"} className="savebutton">
    <Button onClick={this.handleHelpSupportChangeSuccess} data-test-id="helpSupportbutton"
      className="notificationButton"  style={{ width: "100%", paddingTop:"20px", paddingBottom:"20px"}} 
    >
    {this.TraslationFuncation("SettingsPage.Submit")}
    </Button> </Grid></Grid>
    <Modal      
        open={this.state.helpSupportChangeSuccess}
        onClose={this.closeHelpSupportModal}>
        <CustomPopup 
        data-test-id="helpSupportSuccess"
        buttonText={`${this.TraslationFuncation("SettingsPage.Done")}`}
        headingPopup={`${this.TraslationFuncation("SettingsPage.SupportRequestReceived")}`} 
        titlePopup={this.TraslationFuncation("SettingsPage.ThankYouForContactingUs")} 
        subTitlePopup = {`${this.TraslationFuncation("SettingsPage.WellRespondToYourInquiryWithin24/48hours")}`}
        onOpen={this.openHelpSupportModal}
        onClose={this.closeHelpSupportModal}/>
      </Modal>
  </Grid>
  )
}

handleActionTab=(item:{
  id: number;
    name_ar: string;
    name_en: string;
    account_id: string;
    created_at: string;
    updated_at: string;
    description: string;
})=>{
  return( <>
    <Grid className={this.ConditionValuePass(this.state.IsItEnglish,"EditDeletePopUp","EditDeletePopUpArabic")}>
    <Grid 
    sx={{backgroundColor:"#FFFFFF",
    zIndex:9999,borderRadius:"0.8rem",
    padding:"0.3rem",
    boxShadow:"0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);"}}>
      <Box
      data-test-id={"EditJobFamily"} 
      onClick={() => {this.HandelEditJobFamily(item)}}
      sx={{backgroundColor:"#E2E8F0",
      borderRadius:"0.4rem",borderBottom:"1px solid #f1f5f9",width:"150px",height:"40px",
      display:"flex",alignItems:"center",justifyContent:"center", cursor:"pointer"}}>
        <Typography>
        {this.TraslationFuncation("SettingsPage.EditDetails")}
          </Typography></Box>
      <Box 
      data-test-id={"DeleteJobFamily"}
      onClick={()=>{this.DeleteModelOpen(`${item.id}`)}}
      sx={{height:"40px",display:"flex",
      width:"100%",alignItems:"center",
      justifyContent:"center", cursor:"pointer" }}>
        <Typography sx={{color:"#F87171"}}>
        {this.TraslationFuncation("SettingsPage.DeleteDetails")}
        </Typography> </Box>
      </Grid> </Grid></>
  )}
handleDefineLevelActionTab=(item:{
  id: string;
    name_ar: string;
    name_en: string;
    account_id: string;
    created_at: string;
    updated_at: string;
    description: string;
})=>{
  return(
     <>
    <Grid 
    className={this.ConditionValuePass(this.state.IsItEnglish,"EditDeletePopUp","EditDeletePopUpArabic")}
    >
    <Grid  sx={{backgroundColor:"#FFFFFF",
    
    borderRadius:"0.8rem",
    padding:"0.3rem", boxShadow:"0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);"}}>
      <Box 
      data-test-id="EditJobLevel"
      onClick={() => {this.HandelEditLevel(item)}}
       sx={{backgroundColor:"#E2E8F0", borderRadius:"0.4rem",borderBottom:"1px solid #f1f5f9",width:"150px",height:"40px",
      display:"flex",
      alignItems:"center",justifyContent:"center", cursor:"pointer"}}>
        <Typography> {this.TraslationFuncation("SettingsPage.EditDetails")}</Typography>
          </Box>
      <Box data-test-id="DeleteJobLevel" onClick={()=>{this.DeleteModelOpen(item.id)}} sx={{height:"40px",display:"flex",
      width:"100%",
      alignItems:"center",justifyContent:"center", cursor:"pointer"}}>
        <Typography 
        sx={{color:"#F87171"}}>
          {this.TraslationFuncation("SettingsPage.DeleteDetails")}
          </Typography> 
          </Box>
      </Grid> 
      </Grid>
      </>
  )
}
  handleDefineRatingPop = (item: {
    id: string;
    level_name: string;
    description: string;
    rating_scale_type: string;
    account_id: string;
    created_at: string;
    updated_at: string;
    type: string;
    NameEn: string;
    NameAr: string;
  })=>{
  return(
    <>
    <Grid className={this.ConditionValuePass(this.state.IsItEnglish,"EditDeletePopUp","EditDeletePopUpArabic")}>
    <Grid sx={{backgroundColor:"#FFFFFF",zIndex:9999,borderRadius:"0.8rem",padding:"0.3rem",boxShadow:"0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);"}}>
      <Box data-test-id={`EditRateScale`} sx={{backgroundColor:"#E2E8F0",borderRadius:"0.4rem",borderBottom:"1px solid #f1f5f9",width:"150px",height:"40px",display:"flex",alignItems:"center",justifyContent:"center", cursor:"pointer"}}
      onClick={()=>this.HandelEditdefineRateing(item)}
      >
        <Typography>{this.TraslationFuncation("SettingsPage.EditDetails")}</Typography>
      </Box>
      <Box data-test-id="DeleteButtonProfile" onClick={()=>{this.DeleteModelOpen(item.id)}} sx={{height:"40px",display:"flex",width:"100%",alignItems:"center",justifyContent:"center", cursor:"pointer" }}>
        <Typography sx={{color:"#F87171"}}>{this.TraslationFuncation("SettingsPage.DeleteDetails")}</Typography>
      </Box>
      </Grid>
    </Grid>
    </>
  )
}

errorCounter = (errors:string | undefined, touched:string | boolean | undefined) =>{
  return errors && touched && (
      <RequiredTxt>{errors}</RequiredTxt>
    )
}

DeleteModel = () =>{
  return(
    <Modal
      open={this.state.IsDeleteModelOpen}
      onClose={()=>{}}>
      <CustomPopup
        data-test-id="JobDeletePopupModal"
        headingPopup={`${this.TraslationFuncation("SettingsPage.DeleteDetails")}`}
        titlePopup={`${this.TraslationFuncation("SettingsPage.AreYouSureYouWantToDeleteThis")} ${this.state.ActiveTabTextRender}`}
        subTitlePopup={`${this.TraslationFuncation("SettingsPage.YouWontBeAbleToAccessItAgain")}`}
        cancelButtonText={this.TraslationFuncation("Cancel")}
        buttonText={`${this.TraslationFuncation("Delete")}`}
        successIcon={DeleteIcon.default}
        showCancelButton={true}
        onOpen={()=>{
          this.DeleteApiCalls()
        }}
        onClose={this.DeleteModelClose} />
    </Modal>
    )
}

  NoDataScreen = () => {
    return (
      <EmptyDataScreenWrapper>
        <Grid container justifyContent={"center"}>
          <Grid item xs={11}>
            <Grid container spacing={1} alignItems={"center"}>
              <Grid item xs={12} sm={12} md={11.2} lg={11.5}>
                <Wrapper style={{ height: "100%", minHeight: "unset" }}>
                  <Box className="progressImage" style={{ marginTop: "0" }}>
                    <img src={progress.default} alt="Progress" className="ProgressClass" />
                  </Box>
                  <Typography className="emptyText"> 
                    {this.TraslationFuncation("NoData")} 
                  </Typography>
                  <Typography className="emptyScreenMessage">
                    {this.TraslationFuncation("SettingsPage.ItLooksLikeTheresNothingHereYet")}
                  </Typography>
                </Wrapper>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </EmptyDataScreenWrapper>
    )
  }

SuccessModel = () =>{
  const { SuccessLabelText, SuccessUpdateOrCreate, SuccessModelForProfile } = this.state
  const MainLebel = `${SuccessLabelText} ${SuccessUpdateOrCreate}`
  return(
    <Modal      
          open={SuccessModelForProfile}
          onClose={this.CloseSuccessModelForProfile}>
          <CustomPopup 
          data-test-id="ProfileSuccessPopup"
          headingPopup={MainLebel} 
          titlePopup={MainLebel}
          subTitlePopup ={`${this.TraslationFuncation("SettingsPage.Your")} ${SuccessLabelText} ${this.TraslationFuncation("SettingsPage.HasBeenSuccessfully")} ${SuccessUpdateOrCreate}`}
          buttonText={`${this.TraslationFuncation("SettingsPage.Done")}`}
          showCancelButton={false}
          successIcon = {right.default}
          onOpen={this.CloseSuccessModelForProfile}
          onClose={this.CloseSuccessModelForProfile}/>
      </Modal>
  )
}

  DefineRatingScaleAllTabs = () => {
    return this.state.talentActiveTab === "DefineRating" &&
      <>
        <Grid item xs={12} marginTop={"25px"} sx={{ 
          backgroundColor: "#f1f5f9", marginX: "26px",
           borderTopRightRadius: "15px", borderTopLeftRadius: "15px" }}>
          <Box sx={{ marginX: "26px", paddingY: "22px" }}>
            <Typography className="rolesTitle" >
              {this.TraslationFuncation("SettingsPage.DefineRatingScale")}
              </Typography>
            <Typography className="subText">
              {this.TraslationFuncation("SettingsPage.PleaseFillOutTheGenericRatingScale")}
            </Typography>
          </Box>
          <Divider
            sx={{
              color: "#CBD5E1", marginLeft: '26px', marginRight: '26px', }}
          />
        </Grid>
        <Grid sx={{ backgroundColor: "#f1f4f9", marginX: "26px" }}>
          <Box
            sx={{ display: "flex",
               justifyContent: "space-between", 
               alignItems: "center", paddingY: "20px", 
               paddingX: "26px", gap: "1rem", flexWrap: "wrap" }}
          >
            <Box
              onClick={() => this.handleDefineTab("genRating", "generic","SettingsPage.GenericRatingScale")}
              data-test-id="genRating"
              className={this.handleCondition(this.state.defineTab === "genRating", "defineRatingTabs", "rolesHeading3")}
            >
              <Typography className={this.handleCondition(this.state.defineTab === "genRating", "rolesSubTitle", "disableRolesSubTitle")}>
                {this.TraslationFuncation("SettingsPage.GenericRatingScale")}
              </Typography>
            </Box>
            <Box
              data-test-id="comRating"

              onClick={() => this.handleDefineTab("comRating", "competency","SettingsPage.competency")}
              className={this.handleCondition(this.state.defineTab === "comRating", "defineRatingTabs", "rolesHeading3")}
            >
              <Typography className={this.handleCondition(this.state.defineTab === "comRating", "rolesSubTitle", "disableRolesSubTitle")}>
              {this.TraslationFuncation("SettingsPage.CompetencyRatingScale")}
              </Typography>
            </Box>
            <Box
              data-test-id="profRating"

              onClick={() => this.handleDefineTab("profRating", "proficiency","SettingsPage.proficiency")}
              className={this.handleCondition(this.state.defineTab === "profRating", "defineRatingTabs", "rolesHeading3")}
            >
              <Typography className={this.handleCondition(this.state.defineTab === "profRating", "rolesSubTitle", "disableRolesSubTitle")}>
              {this.TraslationFuncation("SettingsPage.ProficiencyRatingScale")}
              </Typography>
            </Box>
            <Box
              data-test-id="perfoRating"
              onClick={() => this.handleDefineTab("perfoRating", "performance","SettingsPage.performance")}
              className={this.handleCondition(this.state.defineTab === "perfoRating", "defineRatingTabs", "rolesHeading3")}
            >
              <Typography className={this.handleCondition(this.state.defineTab === "perfoRating", "rolesSubTitle", "disableRolesSubTitle")}>  
              {this.TraslationFuncation("SettingsPage.PerformanceRatingScale")}
              </Typography>
            </Box>
          </Box>
          <Divider
            sx={{
              color: "#CBD5E1",
              marginX: '26px',
            }}
          />
        </Grid>
      </>

  }

  ActiveDefineRatingTab = () => {
    return this.state.talentActiveTab === "DefineRating" && (
      <>
        <TableContainer className={this.ConditionValuePass(this.state.IsItEnglish,"IsItEnglishTrue","IsItEnglishFalse") + " DefineRatingTableOverflow "} sx={{ marginX: "26px", pr: "38px", pl: "15px", backgroundColor: "#f1f4f9", width: "auto" }}>
          <Table sx={{ marginX: "10px" }}>
            <TableHead>
              <TableRow>
                <TableCell className="tableTilteText">{this.TraslationFuncation("SettingsPage.LevelName")}</TableCell>
                <TableCell className="tableTilteText">
                {this.TraslationFuncation("SettingsPage.Description")}
                </TableCell>
                <TableCell className="tableDataText" sx={{ textAlign: "center" }}>
                {this.TraslationFuncation("SettingsPage.Action")}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody className={this.ConditionValuePass(this.state.IsItEnglish,"IsItEnglishTrue","IsItEnglishFalse")}>
              {this.state.RatingScaleData.filter((FilterData) => FilterData.rating_scale_type === this.state.DefineTabType).length !== 0 ? this.state.RatingScaleData.filter((FilterData) => FilterData.rating_scale_type === this.state.DefineTabType).map((item, index) => {
                return (
                  <TableRow>
                    <TableCell className="tableTilteText" >
                    {this.ConditionValuePass(this.state.IsItEnglish,item.NameEn,item.NameEn)}</TableCell>
                    <TableCell className="tableContentText">
                      {item.description}
                    </TableCell>
                    <TableCell sx={{ textAlign: "center", position: "relative" }}>
                      <Grid item xs="auto" tabIndex={0} data-test-id={"close_profile_dropdown"} onBlur={this.closedefineRatPop}>
                        <MoreVertIcon
                          data-test-id="ThreeDots"
                          style={{ cursor: "pointer", }}
                          onClick={() => this.handleDefineRatingAction(Number(item.id) + index)}
                        />
                        {this.state.defineRatingAction === Number(item.id) + index && (
                          this.handleDefineRatingPop(item)
                        )}
                      </Grid>
                    </TableCell>
                  </TableRow>
                )
              }) :

                <TableRow sx={{ overflowY: "visible" }}>
                  <TableCell data-test-id="ActiveDefineRatingTab" sx={{ textAlign: "end", position: "relative" }} colSpan={4} >
                    {this.NoDataScreen()}
                  </TableCell>
                </TableRow>

              }
            </TableBody>
          </Table>
        </TableContainer>
        <Divider sx={{ color: "#E2E8F0", backgroundColor: "#f1f5f9", mx: "50px" }} />
        {!this.state.plusButton && (
          <Grid className="RemovePadingAndCenterButtonJobLevel">
            <Box>
              <Button data-test-id="handleAddLevel" className="tableContentText" sx={{ textTransform: "none", backgroundColor: "#E2E8F0", paddingX: "40px", display: "flex", gap: "0.5rem", alignItems: "center", color: "black", cursor: "pointer", paddingY: "15px", ":hover": { backgroundColor: "#E2E8F0" } }}
                onClick={this.handleAddLevel}
              >
                <AddCircleOutlineIcon />
                {this.TraslationFuncation("SettingsPage.AddLevels")}
              </Button>
            </Box>
          </Grid>
        )}
        {this.state.plusButton && (
          <Box>
            <Formik
              data-test-id="ratingScaleForm"
              validationSchema={this.RatingScaleSchema}
              enableReinitialize={this.state.RatingScaleFormReinitialize}
              initialValues={{
                LevelName: this.state.LevelName,
                LevelDescription: this.state.LevelDescription,
                LevelOrder: this.state.LevelOrder,
              }}
              onSubmit={(values: { LevelName: string, LevelDescription: string; LevelOrder: string; }) => {
                this.RatingScaleFormApiCall(values)
              }}
            >
              {({
                handleSubmit,
                values,
                handleBlur,
                setFieldValue,
                errors,
                touched,
              }) => (
                <Form
                  onSubmit={handleSubmit}
                  noValidate
                  autoComplete="off"
                >

                  <Grid className="ActiveDefineRatingClass">
                    <Grid container spacing={2} sx={{ marginTop: "5px" }}>
                      <Grid item lg={12} sm={12} sx={{ width: "100%" }}>
                        <Box className="lebelStyleContainer">
                          <Typography className="disableRolesSubTitle">{this.TraslationFuncation("SettingsPage.LevelName")} *</Typography>
                          {this.errorCounter(errors.LevelName, touched.LevelName)}
                        </Box>
                        <TextField
                          sx={{
                            marginTop: "10px",
                            backgroundColor: "#ffffff",
                            "& .MuiInputBase-input": {
                              fontFamily: "Urbanist",
                              fontSize: "16px",
                              fontWeight: "700",
                              lineHeight: "24px",
                              color: "#0F172A",
                            },
                          }}
                          autoFocus
                          value={values.LevelName}
                          type="text"
                          name="LevelName"
                          placeholder={this.TraslationFuncation("SettingsPage.Enter") +" " +this.TraslationFuncation("SettingsPage.LevelName")}
                          onBlur={handleBlur}
                          fullWidth
                          data-test-id="levelNameTextField"
                          onChange={(e) => {
                            this.HandelChangeDefineRatingScale(e, setFieldValue, "LevelName")
                          }}
                        />
                      </Grid>
                    </Grid>
                    <Box className="lebelStyleContainer" sx={{ marginTop: "15px" }}>
                      <Typography className="disableRolesSubTitle">{this.TraslationFuncation("SettingsPage.Description")} *</Typography>
                      {this.errorCounter(errors.LevelDescription, touched.LevelDescription)}
                    </Box>
                    <textarea
                      className={`cultureInputField`}
                      placeholder={this.TraslationFuncation("SettingsPage.WriteDescription")}
                      style={{ marginTop: "10px" }}
                      value={values.LevelDescription}
                      name="LevelDescription"
                      data-test-id="LevelDescription"
                      onBlur={handleBlur}
                      onChange={(e) => {
                        this.HandelChangeDefineRatingScale(e, setFieldValue, "LevelDescription")
                      }}
                    />
                    <Box sx={{ width: "100%" }}>
                      <Button data-test-id="ScaleSubmit" type="submit" sx={{
                        textTransform: "capitalize", fontSize: "16px", fontWeight: 700, backgroundColor: "#044352"
                        , color: "#ffffff", width: "100%", py: "1rem", mt: "1rem", ":hover": { backgroundColor: "#044352" }
                      }}>
                        {this.ConditionValuePass(this.state.IsRatingFormEdit,`${this.TraslationFuncation("Update")}`,`${this.TraslationFuncation("Save")}`)}{' '}
                         {this.state.DefineTabTypeTraslationText} {' '}
                        {this.TraslationFuncation("SettingsPage.RatingScale")}
                        </Button>
                    </Box>
                  </Grid>
                </Form>
              )}
            </Formik>
          </Box>
        )}
      </>
    )
  }

  ActiveJobfamilyTab = () => {
    return this.state.talentActiveTab === "DefineJobfamily" && (
      <>
        <Grid item xs={12} marginTop={"25px"} sx={{
          backgroundColor: "#f1f5f9", marginX: "26px",
          borderTopRightRadius: "15px", borderTopLeftRadius: "15px"
        }}>
            <Box sx={{ marginX:"26px",
              paddingY:"22px" }}>
          <Typography className="rolesTitle" >
          {this.TraslationFuncation("SettingsPage.DefineJobFamily")}
          </Typography>
          <Typography className="subText">
          {this.TraslationFuncation("SettingsPage.PleaseFillOutTheJobFamilyDetails")}
          </Typography>
            </Box>
            <Divider sx={{color: "#CBD5E1",marginLeft: '26px',
            marginRight: '26px',
            }} 
           />
        </Grid>
        <TableContainer className="ActiveJobFaimlyClass">
      <Table sx={{marginX:"10px",overflowY:"visible"}}>
          <TableHead>
              <TableRow >
                  <TableCell className="tableTilteText">
                  {this.TraslationFuncation("SettingsPage.JobFamily")}
                  </TableCell>
                  <TableCell className="tableTilteText">
                    {this.TraslationFuncation("SettingsPage.Description")}
                  </TableCell>
                  <TableCell className="tableDataText"
                   sx={{textAlign:"end"}}>
                    {this.TraslationFuncation("SettingsPage.Action")}
                  </TableCell>
              </TableRow>
          </TableHead>
                      <TableBody sx={{ position: "relative" }} className={this.ConditionValuePass(this.state.IsItEnglish,"IsItEnglishTrue","IsItEnglishFalse")}>
                        {this.state.JobFamilyData.length > 0 ?
                          this.state.JobFamilyData.map((item, index) => {
                            return (
                              <TableRow sx={{ overflowY: "visible" }} key={`${item.id + index}`}>
                                <TableCell className="tableTilteText" sx={{ width: "14rem" }}> {this.state.CurrentLanguage === "en" ? item.name_en : item.name_ar}</TableCell>
                                <TableCell className="tableContentText" sx={{ width: "12rem" }}>{item.description}</TableCell>
                                <TableCell sx={{ textAlign: "end", position: "relative" }}
                                >
                                  <Grid item xs="auto" tabIndex={0} data-test-id="closeListIconPopup" onBlur={this.closeListIconPopup}>
                                    <MoreVertIcon
                                      data-test-id="JobFamilyDataThreeDots"
                                      style={{
                                        cursor: "pointer",
                                      }}
                                      onClick={() => { this.handleAction(`${item.id}`) }}
                                    />
                                    {this.state.jobFamilyAction === item.id && (
                                      this.handleActionTab(item)
                                    )}
                                  </Grid>
                                </TableCell>
                              </TableRow>
                            )
                          }): 
                          <TableRow data-test-id="ActiveJobfamilyTab" sx={{ overflowY: "visible" }}>
                            <TableCell sx={{ textAlign: "end", position: "relative" }} colSpan={3} >
                              {this.NoDataScreen()}
                            </TableCell>
                          </TableRow>
                        }
                      </TableBody>
      </Table>
    </TableContainer>
    <Divider sx={{ color: "#E2E8F0",backgroundColor:"#f1f5f9",mx:"50px" }} />
    {!this.state.jobFamilyPlusButton && (
    <Grid className="RemovePadingAndCenterButtonJobLevel">
      <Box>
        <Button data-test-id="handleAddFamily" className="tableContentText" sx={{textTransform:"none",backgroundColor:"#E2E8F0",paddingX:"40px",display:"flex",gap:"0.5rem",alignItems:"center",color:"black",cursor:"pointer",paddingY:"15px",":hover":{backgroundColor:"#E2E8F0"}}}
        onClick={this.handleAddJobFamily}
        >
          <AddCircleOutlineIcon />
           {this.TraslationFuncation("SettingsPage.AddJobFamily")}
        </Button>
      </Box>
    </Grid>
    )}
    {this.state.jobFamilyPlusButton && (
      <Box>
                      <Formik
                        validationSchema={this.JobFaimilySchema}
                        data-test-id="JobFamilyScaleForm"
                        enableReinitialize={this.state.JobFamilyFormReinitialize}
                        initialValues={{
                          JobFamilyName: this.state.JobFamilyName,
                          JobFamilyDescription: this.state.JobFamilyDescription
                        }}
                        onSubmit={(values) => {
                          this.JobFamiyFormApiCall(values)
                        }}
                      >
                        {({
                          handleSubmit,
                          values,
                          handleBlur,
                          setFieldValue,
                          errors,
                          touched,
                        }) => (
                          <Form
                            onSubmit={handleSubmit}
                            noValidate
                            autoComplete="off"
                          >
                            <Grid sx={{ backgroundColor: "#f1f5f9", marginX: "26px", paddingX: "20px", paddingY: "20px", borderBottomRightRadius: "15px", borderBottomLeftRadius: "15px" }}>
                              <Grid sx={{ marginTop: "5px" }}>
                                <Grid sx={{ width: "100%" }}>
                                  <Box className="lebelStyleContainer" >
                                    <Typography className="disableRolesSubTitle">{this.TraslationFuncation("SettingsPage.JobFamily")} *</Typography>
                                    {this.errorCounter(errors.JobFamilyName, touched.JobFamilyName)}
                                  </Box>
                                  <TextField
                                    sx={{
                                      marginTop: "10px",
                                      backgroundColor: "#ffffff",
                                      "& .MuiInputBase-input": {
                                        fontFamily: "Urbanist",
                                        fontSize: "16px",
                                        fontWeight: "700",
                                        lineHeight: "24px",
                                        color: "#0F172A",
                                        width: "100%"
                                      },
                                    }}
                                    autoFocus
                                    placeholder={this.TraslationFuncation("SettingsPage.Enter") +" " +this.TraslationFuncation("SettingsPage.JobFamily")}
                                    value={values.JobFamilyName}
                                    fullWidth
                                    name="JobFamilyName"
                                    onBlur={handleBlur}
                                    type="text"
                                    data-test-id="JobFamilyName"
                                    onChange={(e) => {
                                      this.HandelChangeJobFamilyName(e, setFieldValue, "JobFamilyName")
                                    }}
                                  />
                                </Grid>
                              </Grid>
                              <Box sx={{ marginTop: "15px" }} className="lebelStyleContainer" >
                                <Typography className="disableRolesSubTitle" >{this.TraslationFuncation("SettingsPage.Description")} *</Typography>
                                {this.errorCounter(errors.JobFamilyDescription, touched.JobFamilyDescription)}
                              </Box>
                              <textarea
                                className={`cultureInputField`}
                                placeholder={this.TraslationFuncation("SettingsPage.WriteDescription")}
                                value={values.JobFamilyDescription}
                                name="JobFamilyDescription"
                                onBlur={handleBlur}
                                data-test-id="JobFamilyDescription"
                                onChange={(e) => {
                                  this.HandelChangeJobFamilyName(e, setFieldValue, "JobFamilyDescription")
                                }}
                                style={{ marginTop: "10px" }}
                              />
                              <Box sx={{ width: "100%" }}>
                                <Button 
                                 type="submit"
                                sx={{
                                  textTransform: "none", fontSize: "16px", fontWeight: 700, backgroundColor: "#044352"
                                  , color: "#ffffff", width: "100%", py: "1rem", mt: "1rem", ":hover": { backgroundColor: "#044352" }
                                }} >
                                  {this.ConditionValuePass(this.state.IsJobFamilyFormEdit,`${this.TraslationFuncation("Update")}`,`${this.TraslationFuncation("Save")}`)}
                                  {" "}
                                  {this.TraslationFuncation("SettingsPage.JobFamily")}
                                   </Button>
                              </Box>
                            </Grid>
                          </Form>
                        )}
                      </Formik>
      </Box>
    )}
      </>
    )
  }

  ActiveDefineJobLevel = () =>{
    return this.state.talentActiveTab === "DefineJobLevel" && (
      <>
        <Grid item xs={12} marginTop={"25px"} 
          sx={{ backgroundColor: "#f1f5f9",
            marginX: "26px",
            borderTopRightRadius: "15px", 
            borderTopLeftRadius: "15px" 
            }}>
          <Box sx={{ marginX: "26px", paddingY: "22px" }}>
            <Typography className="rolesTitle">
              {this.TraslationFuncation("SettingsPage.DefineJobLevel")}
            </Typography>
            <Typography className="subText">
            {this.TraslationFuncation("SettingsPage.PleaseFillOutTheJobLevelDetails")}
            </Typography>
          </Box>
          <Divider sx={{ color: "#CBD5E1", marginLeft: '26px', marginRight: '26px', }} />
        </Grid>
        <TableContainer className="ActiveDefineJobLevelClass">
          <Table sx={{ marginX: "10px", overflowY: "visible" }}>
            <TableHead>
              <TableRow >
                <TableCell
                 className="tableTilteText">
                {this.TraslationFuncation("SettingsPage.JobLevel")}
                </TableCell>
                <TableCell className="tableTilteText">{this.TraslationFuncation("SettingsPage.Description")}</TableCell>
                <TableCell className="tableDataText" sx={{ 
                  textAlign: "end"
                 }}>{this.TraslationFuncation("SettingsPage.Action")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody sx={{ position: "relative" }} className={this.ConditionValuePass(this.state.IsItEnglish,"IsItEnglishTrue","IsItEnglishFalse")}>
              {this.state.JobLevelData.length > 0 ?
                this.state.JobLevelData.map((item, index) => {
                  return (
                    <TableRow sx={{ overflowY: "visible" }} key={`${item.id + index}`}>

                      <TableCell className="tableTilteText" sx={{ width: "14rem" }}>{this.state.CurrentLanguage === "en" ? item.name_en : item.name_ar}</TableCell>
                      <TableCell className="tableContentText" sx={{ width: "12rem" }}>{item.description}</TableCell>
                      <TableCell sx={{ textAlign: "end", position: "relative" }}
                      >
                        <Grid item xs="auto" tabIndex={0} data-test-id="closeLevelPop" onBlur={this.closeLevelPop}>
                          <MoreVertIcon
                            data-test-id="JobLevelThreeeDot"
                            style={{
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              this.handleDefineLevel(Number(item.id))
                            }}
                          />
                          {this.state.jobLevelAction === Number(item.id) && (
                            this.handleDefineLevelActionTab(item)
                          )}
                        </Grid>
                      </TableCell>

                    </TableRow>
                  )
                }) :
                <TableRow data-test-id="ActiveDefineJobLevel" sx={{ overflowY: "visible" }}>
                  <TableCell sx={{ textAlign: "end", position: "relative" }} colSpan={3} >
                    {this.NoDataScreen()}
                  </TableCell>
                </TableRow>
              }
            </TableBody>
          </Table>
        </TableContainer>
        <Divider sx={{ color: "#E2E8F0", backgroundColor: "#f1f5f9", mx: "50px" }} />
        {!this.state.jobLevelPlusButton && (
          <Grid className="RemovePadingAndCenterButtonJobLevel">
            <Box>
              <Button data-test-id="handleJobLevel" className="tableContentText" sx={{ textTransform: "none", backgroundColor: "#E2E8F0", paddingX: "40px", display: "flex", gap: "0.5rem", alignItems: "center", color: "black", cursor: "pointer", paddingY: "15px", ":hover": { backgroundColor: "#E2E8F0" } }}
                onClick={this.handleAddJobLevel}
              >
                <AddCircleOutlineIcon />
                {this.TraslationFuncation("SettingsPage.AddJobLevel")}
              </Button>
            </Box>
          </Grid>
        )}
        {this.state.jobLevelPlusButton && (
          <Box>
            <Formik
              validationSchema={this.JobLevelSchema}
              enableReinitialize={this.state.JobLevelFormReinitialize}
              initialValues={{
                JobLevelName: this.state.JobLevelName,
                JobLevelDescription: this.state.JobLevelDescription,
              }}
              data-test-id="JobLevelForm"
              onSubmit={(values) => {
                this.JobLevelFormApiCall(values)
              }}
            >
              {({
                handleSubmit,
                values,
                handleBlur,
                setFieldValue,
                errors,
                touched,
              }) => (
                <Form
                  onSubmit={handleSubmit}
                  noValidate
                  autoComplete="off"
                >
                  <Grid sx={{ backgroundColor: "#f1f5f9", marginX: "26px", paddingX: "20px", paddingY: "20px", borderBottomRightRadius: "15px", borderBottomLeftRadius: "15px" }}>
                    <Grid sx={{ marginTop: "5px" }}>
                      <Grid sx={{ width: "100%" }}>
                        <Box className="lebelStyleContainer">
                          <Typography
                            className="disableRolesSubTitle">
                            {this.TraslationFuncation("SettingsPage.JobLevel")} *
                          </Typography>
                          {this.errorCounter(errors.JobLevelName, touched.JobLevelName)}
                        </Box>
                        <TextField
                          sx={{
                            marginTop: "10px",
                            backgroundColor: "#ffffff",
                            "& .MuiInputBase-input": {
                              fontFamily: "Urbanist", fontSize: "16px",
                              fontWeight: "700",
                              lineHeight: "24px",
                              color: "#0F172A", width: "100%"
                            },
                          }}
                          fullWidth
                          autoFocus
                          placeholder={this.TraslationFuncation("SettingsPage.Enter") +" " +this.TraslationFuncation("SettingsPage.JobLevel")}
                          value={values.JobLevelName}
                          type="text"
                          name="JobLevelName"
                          onBlur={handleBlur}
                          data-test-id="JobLevelName"
                          onChange={(e) => {
                            this.HandelChangeJobLevel(e, setFieldValue, "JobLevelName")
                          }}
                        />
                      </Grid>
                    </Grid>
                    <Box className="lebelStyleContainer" sx={{ marginTop: "15px" }}>
                      <Typography className="disableRolesSubTitle">
                      {this.TraslationFuncation("SettingsPage.Description")} *
                      </Typography>
                      {this.errorCounter(errors.JobLevelDescription, touched.JobLevelDescription)}
                    </Box>
                    <textarea className={`cultureInputField`}
                      placeholder={this.TraslationFuncation("SettingsPage.WriteDescription")}
                      name="JobLevelDescription"
                      value={values.JobLevelDescription}
                      onBlur={handleBlur}
                      data-test-id="JobLevelDescription"
                      onChange={(e) => {
                        this.HandelChangeJobLevel(e, setFieldValue, "JobLevelDescription")
                      }}
                      style={{ marginTop: "10px" }} />
                    <Box
                      sx={{ width: "100%" }}>
                      <Button
                        type="submit"
                        sx={{
                          textTransform: "none", fontSize: "16px", fontWeight: 700, backgroundColor: "#044352", color: "#ffffff",
                          width: "100%", py: "1rem", mt: "1rem", ":hover": { backgroundColor: "#044352" }
                        }} >
                          {this.ConditionValuePass(this.state.IsJobFamilyFormEdit,`${this.TraslationFuncation("Update")}`,`${this.TraslationFuncation("Save")}`)} {' '}
                        {this.TraslationFuncation("SettingsPage.JobLevel")}
                      </Button>
                    </Box>
                  </Grid>
                </Form>
              )}

            </Formik>
          </Box>
        )}
      </>
    )
  }

talentProfileSettingScreen = () => {
  return (
 <>
 {this.SuccessModel()}
 {this.DeleteModel()}
     <Grid item xs={10}
    sm={8}
    md={9}
    lg={8}
    xl={7.5} className={"notificationsettings"+" "+ this.ConditionValuePass(this.state.IsItEnglish,"IsItEnglishTrue","IsItEnglishFalse")}  sx={{backgroundColor:"#ffffff",height:"100%",pb:"30px"}} display={"flex"} flexDirection={"column"} justifyContent={"space-between"}>
      <Grid>
      <Grid container  justifyContent={"space-between"} className="TalentProfileTabs" sx={{marginTop:"25px"}}>
      <Grid container sx={{display:"flex",alignItems:"center",gap:"1rem",flexWrap:"wrap",paddingX:"26px"}} className="headerTab TalentProfileTabsDirection" >
      <Box
              data-test-id="DefineRating"
              onClick={() => this.handleTalentClick("DefineRating", "Rating Scale","SettingsPage.DefineRatingScale")}
              className={this.handleCondition(this.state.talentActiveTab === "DefineRating" ,  "defineRatingTabs" , "rolesHeading4")}
            >
              <Typography className={this.handleCondition(this.state.talentActiveTab === "DefineRating" , "rolesTitle" , "disableText")}>{this.TraslationFuncation("SettingsPage.DefineRatingScale")}</Typography>
            </Box>
            <Box
            data-test-id="DefineJobfamily"
              onClick={() => this.handleTalentClick("DefineJobfamily","Job Family","SettingsPage.DefineJobFamily")}
              className={this.handleCondition(this.state.talentActiveTab === "DefineJobfamily" ,  "defineRatingTabs" , "rolesHeading4")}
            >
              <Typography className={this.handleCondition(this.state.talentActiveTab === "DefineJobfamily" ,  "rolesTitle" , "disableText")}>
                {this.TraslationFuncation("SettingsPage.DefineJobFamily")}
                </Typography>
            </Box>

            <Box
              data-test-id="DefineJobLevel"
              onClick={() => this.handleTalentClick("DefineJobLevel","Job Level","SettingsPage.DefineJobLevel")}
              className={this.handleCondition(this.state.talentActiveTab === "DefineJobLevel" ,  "defineRatingTabs" , "rolesHeading4")}
            >
              <Typography className={this.handleCondition(this.state.talentActiveTab === "DefineJobLevel" , "rolesTitle" , "disableText")}
             >{this.TraslationFuncation("SettingsPage.DefineJobLevel")}</Typography>
            </Box>
      </Grid> 
    </Grid>
          {this.DefineRatingScaleAllTabs()}
          {this.ActiveDefineRatingTab()}
          {this.ActiveJobfamilyTab()}
          {this.ActiveDefineJobLevel()}
    </Grid>
    </Grid>
 </>
  )
}

successUserCreationDialog = () => {
  return (
    <Dialog
      open={this.state.openSuccessComp}
      onClose={this.handleCloseSuccessComp}
      style={{
        direction: this.state.IsItEnglish ? "ltr" : "rtl",
      }}
      PaperProps={{
        sx: {
          borderRadius: "8px 8px 32px 8px",
          overflow: 'hidden',
        }
      }}
    >
      <DialogTitle>
        <DilogWrapper>
          <div className={this.ConditionValuePass(this.state.IsItEnglish,"DircationChangeOfClose","DircationChangeOfCloseArabic")}>
          <Typography className="successCompDialogTitle">
          {this.TraslationFuncation("SettingsPage.RoleConversionToHRSubAdmin")}
          </Typography>
          <IconButton
            aria-label="close"
            data-test-id="close_competency_success"
            onClick={this.handleCloseSuccessComp}
            sx={{ right:  30, left: "auto"}}
            className={this.ConditionValuePass(this.state.IsItEnglish,"successCompDialogClose","successCompDialogCloseArabic")}
          >
            <CloseIcon />
          </IconButton>
          </div>
        </DilogWrapper>
      </DialogTitle>
      <Divider sx={{ color: "#E2E8F0" }} />
      <DialogContent>
        <DilogWrapper>
          <Box className="successCompImageBox">
            <img alt="Success Image" src={right.default} height={98} width={98} />
          </Box>
        </DilogWrapper>
        <Box style={{borderRadius:"12px" , backgroundColor:"#F1F5F9"}}>
          {(this.state.selectedEmployees.length === 1) 
          &&  <Typography
          sx={{ fontSize: "20px",fontWeight: 700, fontFamily: "Urbanist",padding: "10px 34px", lineHeight: "28px",color: "#0F172A",textAlign: "center" }}>
          {`${this.state.ModelShowMangeEmployeData[0]?.firstName} ${this.state.ModelShowMangeEmployeData[0]?.lastName}`}</Typography> }
        <Typography sx={{fontSize: "20px",fontWeight: 700, fontFamily: "Urbanist",padding: "10px 34px", lineHeight: "28px",color: "#0F172A",textAlign: "center"
          }}>
          {this.TraslationFuncation("SettingsPage.Employee")}
        </Typography>
        <DilogWrapper>
          <Box className="successCompImageBox1">
            <img src={chnageRole.default} alt="Success Image" height={15.86} width={20.52} />
          </Box>
        </DilogWrapper>
        {(this.state.selectedEmployees.length === 1) && 
        <Typography
          sx={{
            fontSize: "20px",fontWeight: 700, fontFamily: "Urbanist",padding: "10px 34px", lineHeight: "28px",color: "#0F172A",textAlign: "center"
          }}>
          {`${this.state.ModelShowMangeEmployeData[0]?.firstName} ${this.state.ModelShowMangeEmployeData[0]?.lastName}`}
        </Typography>
        }
        <Typography
          sx={{
            fontSize: "20px",fontWeight: 700, fontFamily: "Urbanist",padding: "10px 34px", lineHeight: "28px",color: "#0F172A",textAlign: "center"
          }}>
          {this.TraslationFuncation("SettingsPage.HRSubAdmin")}
        </Typography>
        </Box>
        <Typography
          sx={{
            fontWeight: 400,
            lineHeight: "24px",
            fontFamily: "Urbanist", padding: "0px 40px", marginTop:"22px", fontSize: "16px",
            color: "#0F172A",
            textAlign: "center",
          }}>
            
            {this.TraslationFuncation("SettingsPage.TheUserWillReceiveAnEmailNotificationAboutThisChange")}
        </Typography>
        <Typography
          sx={{
            fontWeight: 400,
            lineHeight: "24px",
            fontFamily: "Urbanist",
            padding: "0px 68px",
            fontSize: "16px", color: "#0F172A", textAlign: "center",
          }}>
          {this.TraslationFuncation("SettingsPage.ThisUserWillLoseAccessToCertainFeatures")}
        </Typography>
      </DialogContent>
      <DialogActions>
        <DilogWrapper>
          <Box className={`compButtonsBox`}>
          <Button
              color="primary"
              variant="contained"
              onClick={this.handleCloseSuccessComp}
              className={`viewCompButton1`}
            >
              {this.TraslationFuncation("Cancel")}
            </Button>
            <Button
            data-test-id="handleOpenSuccessComp"
              color="primary"
              variant="contained"
              onClick={this.handleOpenSuccessComp}
              className={`viewCompButton`}
            >
              {this.TraslationFuncation("SettingsPage.Proceed")}
            </Button>
          </Box>
        </DilogWrapper>
      </DialogActions>
    </Dialog>
  )
}

SuccessSettingDialogChange = () =>{
  return(
    <Dialog
      open={this.state.HrSubAdminSuccessModelState}
      onClose={this.HrSubAdminSuccessModelStateClose}
      style={{
        direction: this.state.IsItEnglish ? "ltr" : "rtl",
      }}
      PaperProps={{
        sx: {
          borderRadius: "8px 8px 32px 8px",
          overflow: 'hidden',
        }
      }}
    >
      <DialogTitle>
        <DilogWrapper>
          <div className={this.ConditionValuePass(this.state.IsItEnglish,"DircationChangeOfClose","DircationChangeOfCloseArabic")}>
          <Typography className="successCompDialogTitle">{this.TraslationFuncation("SettingsPage.HRSubAdminSettingsSaved")}</Typography>
          <IconButton
            aria-label="close"
            data-test-id="close_competency_success"
            sx={{
              right:  30,
              left: "auto"
            }}
            onClick={this.HrSubAdminSuccessModelStateClose}
            className={this.ConditionValuePass(this.state.IsItEnglish,"successCompDialogClose","successCompDialogCloseArabic")}
          >
            <CloseIcon />
          </IconButton>
          </div>
        </DilogWrapper>
      </DialogTitle>
      <Divider sx={{ color: "#E2E8F0" }} />
      <DialogContent>
        <DilogWrapper>
          <Box className="successCompImageBox">
            <img src={right.default}
              alt="Success Image" height={98} width={98} />
          </Box>
        </DilogWrapper>
        <Box style={{borderRadius:"12px" ,backgroundColor:"#F1F5F9"}}>
        </Box>
        <Typography
          sx={{
            fontWeight: 400, lineHeight: "24px",
            fontFamily: "Urbanist", padding: "0px 40px",
            marginTop:"22px", fontSize: "16px",
            color: "#0F172A", textAlign: "center",
          }}>
            {this.TraslationFuncation("SettingsPage.HRSubAdminSettingsHaveBeenUpdatedSuccessfully")}
        </Typography>
      </DialogContent>
      <DialogActions>
        <DilogWrapper>
          <Box className={`compButtonsBox`}>
            <Button data-test-id="handleOpenSuccessComp"
              color="primary" variant="contained"
              onClick={this.HrSubAdminSuccessModelStateClose} className={`viewCompButton`}
            >
              {this.TraslationFuncation("SettingsPage.Done")}
            </Button>
          </Box>
        </DilogWrapper>
      </DialogActions>
    </Dialog>
  )
} 

successUserCandidateDialog = () => {
  return (
    <Dialog
      open={this.state.openSuccessCandidateComp}
      style={{
        direction: this.state.IsItEnglish ? "ltr" : "rtl",
      }}
      onClose={this.handleCloseCandidateSuccessComp}
      PaperProps={{
        sx: {
          borderRadius: "8px 8px 32px 8px", overflow: 'hidden',
        }}}>
      <DialogTitle>
        <DilogWrapper>
          <div className={this.ConditionValuePass(this.state.IsItEnglish,"DircationChangeOfClose","DircationChangeOfCloseArabic")}>
          <Typography className="successCompDialogTitle">
            {this.TraslationFuncation("SettingsPage.RoleConversionToEmployee")}</Typography>
          <IconButton
            aria-label="close" data-test-id="handleCloseSuccessComp"
            onClick={this.handleCloseCandidateSuccessComp} className={this.ConditionValuePass(this.state.IsItEnglish,"successCompDialogClose","successCompDialogCloseArabic")}
            sx={{ right:  30,left: "auto"}}
          > 
            <CloseIcon />
          </IconButton>
          </div>
        </DilogWrapper>
      </DialogTitle>
      <Divider sx={{ color: "#E2E8F0" }} />
      <DialogContent>
        <DilogWrapper>
          <Box className="successCompImageBox"><img src={right.default} alt="Success Image" height={98} width={98} /></Box>
        </DilogWrapper>
        <Box style={{ borderRadius:"12px" , backgroundColor:"#F1F5F9" }}>
          {this.state.selectedCandidate.length === 1 && 
        <Typography
          sx={{
            fontSize: "20px",
            fontFamily: "Urbanist", padding: "10px 34px",
            lineHeight: "28px",color: "#0F172A",
            fontWeight: 700,
            textAlign: "center"
          }}>
          {`${this.state.ModelShowEmployeeData[0].firstName} ${this.state.ModelShowEmployeeData[0].lastName}`}
        </Typography>
          }
        <Typography
          sx={{
            fontSize: "20px",
            fontWeight: 700,
            fontFamily: "Urbanist",
            lineHeight: "28px",
            color: "#0F172A",
            padding: "10px 34px",
            textAlign: "center"
          }}>
          {this.state.candidateDataPopup.designation}
        </Typography>
        <Typography
          sx={{
            fontSize: "20px",
            fontWeight: 700,
            fontFamily: "Urbanist",
            padding: "10px 34px",
            lineHeight: "28px",
            color: "#0F172A",
            textAlign: "center"
          }}>
          {this.TraslationFuncation("SettingsPage.Candidate")}
        </Typography>
        <DilogWrapper>
          <Box className="successCompImageBox1">
            <img src={chnageRole.default} alt="Success Image" height={15.86} width={20.52} />
          </Box>
        </DilogWrapper>
        {this.state.selectedCandidate.length === 1 && 
        <Typography
        sx={{
          fontWeight: 700,
          fontFamily: "Urbanist", padding: "10px 34px",
          fontSize: "20px", color: "#0F172A",
          lineHeight: "28px",
          textAlign: "center"
        }}>
          {`${this.state.ModelShowEmployeeData[0].firstName} ${this.state.ModelShowEmployeeData[0].lastName}`}
        </Typography>
        }
        <Typography sx={{ fontSize: "20px",
            fontFamily: "Urbanist",
            padding: "10px 34px",
            lineHeight: "28px",
            fontWeight: 700, color: "#0F172A",
            textAlign: "center"
          }}>
          {this.TraslationFuncation("SettingsPage.Employee")}
        </Typography>
        </Box>
        <Typography
          sx={{fontWeight: 400,
            lineHeight: "24px",
            fontSize: "16px",
            color: "#0F172A",
            fontFamily: "Urbanist",
            padding: "0px 40px",
            marginTop:"22px",
            textAlign: "center",
          }}>
            {this.TraslationFuncation("SettingsPage.TheUserWillReceiveAnEmailNotificationAboutThisChange")}
        </Typography>
        <Typography
          sx={{
            fontWeight: 400, 
            lineHeight: "24px",
            fontFamily: "Urbanist",
            padding: "0px 68px",
            fontSize: "16px",
            color: "#0F172A",
            textAlign: "center",
          }}>
          {this.TraslationFuncation("SettingsPage.ThisUserWillLoseAccessToCertainFeatures")}
        </Typography>
      </DialogContent>
      <DialogActions>
        <DilogWrapper>
          <Box className={`compButtonsBox`}>
          <Button data-test-id="handleCloseCandidateSuccessComp" 
            color="primary"
            variant="contained"
            className={`viewCompButton1`}
            onClick={this.handleCloseCandidateSuccessComp} 
            >
              {this.TraslationFuncation("Cancel")}
            </Button>
            <Button data-test-id="handleOpenCandidateSuccessComp"
              color="primary"
              variant="contained"
              onClick={this.handleOpenCandidateSuccessComp} 
              className={`viewCompButton`}
            >
              {this.TraslationFuncation("SettingsPage.Proceed")}
            </Button>
          </Box>
        </DilogWrapper>
      </DialogActions>
    </Dialog>
  )
}

  AllTabRender = () => {
    return (
      <>
        {this.state.profileSettingScreen && this.profileSettingScreen()}
        {this.state.notificationSettingScreen && this.notificationSettingScreen()}
        {this.state.languageSettingScreen && this.languageSettingScreen()}
        {this.state.rolespermissionSettingScreen && this.rolespermissionSettingScreen()}
        {this.state.helpSupportSettingScreen && this.helpSupportSettingScreen()}
        {this.state.openSuccessComp && this.successUserCreationDialog()}
        {this.state.openSuccessCandidateComp && this.successUserCandidateDialog()}
        {this.state.talentProfileSettingScreen && this.talentProfileSettingScreen()}</>
    )
  }

  // Customizable Area End

  render() {
    console.log("language")
    return (
      // Customizable Area Start
      <>
      <View style={{ display: "none", alignItems: "center" }}>
        <View style={styles.mainWrapper}>
          <Typography >{configJSON.labelTitleText}</Typography>
          <Typography >{configJSON.labelBodyText}</Typography>
          <View >
            
          </View>
          <View style={styles.buttonStyle}>
            <Button
              data-test-id="btnAddExample"
              onClick={() => this.doButtonPressed()}
              >
              CLICK ME
              </Button>
          </View>
        </View>
      </View>
      <DashboardHeader navigation={this.props.navigation} id={this.props.id}>
            <Wrapper>
                <Grid container display={"flex"} justifyContent={"center"}>
                  <HeaderWrapper>
                    <Grid item xs={11}>
                      <Grid container spacing={1} alignItems={"center"}>
                        <Grid item xs={1.5} sm={1} md={0.8} lg={0.5}>
                          <Box data-test-id="Setting" onClick={this.navigateToDashboard}
                            style={{ 
                              width: "47px",
                              height: "47px", border: "1px solid #ECECEC",
                              borderRadius: "50%", display: "flex",
                              alignItems: "center", justifyContent: "center"
                            }}
                          >
                            <img src={arrow_Left.default} alt="Back Arrow" />
                          </Box>
                        </Grid>
                        <Grid item xs={10.5} sm={11} md={11.2} lg={11.5} alignItems={"center"}>
                          <Typography className="settingTitle"> {this.TraslationFuncation("SettingsPage.Settings")}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </HeaderWrapper>
                  </Grid>
                  <MainWrapper>
                  <Grid item xs={9} sm={7} md={4} lg={4} xl={3.6}
                      sx={{ maxWidth: { xs: "85%", sm: "66%", md: "40%", lg: "40%",  xl: "35%", }, }}>
                      <Card className="companyCardDescBox" style={{boxShadow: "0px 14px 144px 0px #0000001A",marginBottom:"20px", direction : this.state.IsItEnglish ? "ltr" : "rtl"}}>
                        <CardContent>
                          <Grid item>
                          <Grid item xs={11}
                          onClick={this.profileScreen}
                          marginRight={"30px"}
                          marginLeft={"30px"}
                          marginTop={"10px"}
                            data-test-id="navigate_to_ProfileSetting"
                            className={`menu ${this.state.profileSettingScreen === true ? 'activeMenuTitle' : ''}`}>
                            {this.profileTab()}
                          </Grid>
                          <Grid item xs={11}
                            onClick={this.notificationScreen}
                            marginRight={"30px"}
                            marginLeft={"30px"}
                            marginTop={"10px"}
                            data-test-id="navigate_to_NotificationSetting"
                            className={`menu ${this.state.notificationSettingScreen === true ? 'activeMenuTitle' : ''}`}>
                            {this.notificationTab()}
                          </Grid>
                          <Grid item xs={11} data-test-id="navigate_to_LangaugeSetting"
                          onClick={this.languageScreen}
                           marginRight={"30px"}
                           marginLeft={"30px"}
                           marginTop={"10px"}
                            className={`menu ${this.state.languageSettingScreen === true ? 'activeMenuTitle' : ''}`}>
                            {this.languageTab()}
                          </Grid>
                          <Grid item xs={11}
                          onClick={this.rolesPermissionScreen}
                           marginRight={"30px"}
                           marginLeft={"30px"}
                           marginTop={"10px"}
                           data-test-id="navigate_to_RolesPermissionSetting"
                            className={`menu ${this.state.rolespermissionSettingScreen === true ? 'activeMenuTitle' : ''}`}>
                            {this.rolesPermissionTab()}
                          </Grid>
                          <Grid item xs={11}
                           onClick={this.talentProfileScreen}
                           marginRight={"30px"}
                           marginLeft={"30px"}
                           marginTop={"10px"}
                           data-test-id="navigate_to_TalentProfileSettings"
                            className={`menu ${this.state.talentProfileSettingScreen === true ? 'activeMenuTitle' : ''}`}
                            >
                            {this.talentProfileTab()}
                          </Grid>
                          <Grid item xs={11}
                          onClick={this.helpSupportScreen}
                           marginRight={"30px"}
                           marginLeft={"30px"}
                           marginTop={"10px"}
                            data-test-id="navigate_to_HelpSettings"
                            className={`menu ${this.state.helpSupportSettingScreen === true ? 'activeMenuTitle' : ''}`}>
                            {this.helpTab()}
                          </Grid>
                          </Grid>
                        </CardContent>
                      </Card>
                    </Grid>
                    {this.AllTabRender()}
                  </MainWrapper>
            </Wrapper>
            </DashboardHeader>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

export const Settings2Web = withTranslation()(Settings2)

export const AntSwitch = styled(Switch)({
  width: 48,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 1,
    "&.Mui-checked": {
      color: "#fff",
      transform: "translateX(25px)",
      "& + .MuiSwitch-track": {
        backgroundColor: "#375280",
        opacity: 1,
        border: "none",
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#375280",
      border: "none",
    },
  },
  "& .MuiSwitch-thumb": {
    width: 18,
    height: 18,
    color:"#FFFFFF",
    margin: 2
  },
  "& .MuiSwitch-track": {
    backgroundColor: "#E2E8F0",
    opacity: 1,
    borderRadius: 40,
    height: 24,
    width: 48
  },
});

const IOSSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& .MuiSwitch-thumb': {
        backgroundColor: '#fff',
      },
      '& + .MuiSwitch-track': {
        backgroundColor: '#044352',
        opacity: 1,
        border: 0,
        ...theme.applyStyles('dark', {
          backgroundColor: '#044352',
        }),
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color: theme.palette.grey[100],
      ...theme.applyStyles('dark', {
        color: theme.palette.grey[600],
      }),
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: 0.7,
      ...theme.applyStyles('dark', {
        opacity: 0.3,
      }),
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
    backgroundColor: '#94A3B8'
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: '#E2E8F0',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
    ...theme.applyStyles('dark', {
      backgroundColor: '#E2E8F0',
    }),
  },
}));

export const HeaderWrapper = styled(Box)({
  display: "flex",
  alignItems:"center",
  justifyContent: "center",
  width: "100%",
  marginTop: "42px",
  paddingBottom: "30px",
  "& .settingTitle": {
    fontFamily: "Urbanist",
    fontSize: "25px",
    fontWeight: 600,
    lineHeight: "30px",
    color: "#000104",
    marginLeft: "12px"
},
})

export const DilogWrapper = styled(Box)({
  width: "100%",
  "& .DircationChangeOfClose":{
    display:"flex",
    padding:"0 10px",
    whiteSpace:"nowrap",
    justifyContent: "space-between",
    alignItems: "center",
    "@media(max-width:600px)": {
      whiteSpace:"unset"
  },
  },
  "& .DircationChangeOfCloseArabic":{
    display:"flex",
    padding:"0 10px",
    whiteSpace:"nowrap",
    justifyContent: "space-between",
    alignItems: "center",
    "@media(max-width:600px)": {
      whiteSpace:"unset"
  },
  },
  "& .successCompDialogClose": {
    color: "#334155",
    "@media(max-width:600px)": {
      marginRight:"-25px",
      right: "0px"
  },
  },
  "& .successCompDialogCloseArabic": {
    color: "#334155",
    "@media(max-width:600px)": {
      marginRight:"-25px",
      left: "0px"
  },
  },
  "& .successCompImageBox": {
    display: "flex",
    padding: "10px 0px",
    justifyContent: "center",
    marginBottom:"22px"
  },
  "& .successCompImageBox1": {
    display: "flex",
    padding: "10px 0px",
    justifyContent: "center",
  },
  "& .successCompDialogTitle": {
    fontFamily: "Urbanist",
    color: "#1E1E1E",
    fontSize: "24px",
    lineHeight: "32px",
    fontWeight: 700,
  },
  "& .compButtonsBox": {
    margin: "16px 0px",
    paddingRight: "16px",
    display: "flex",
    justifyContent: "flex-end"
  },
  "& .viewCompButton1":{
    padding: "16px 24px",
    fontWeight: 700,
    width: "30%",
    height: "56px",
    fontFamily: "Urbanist",
    fontSize: "16px",
    gap: "8px",
    borderRadius: "8px",
    opacity: "0px",
    marginLeft: "16px",
    background: "#F1F5F9",
    textTransform: "none",
    color:"#044352",
    "&:hover": {
      backgroundColor: "#F1F5F9"
    },
    "@media(max-width:600px)": {
      width: "100%",
    },
  },
  "& .viewCompButton": {
    padding: "16px 24px",
    fontWeight: 700,
    width: "30%",
    height: "56px",
    fontFamily: "Urbanist",
    fontSize: "16px",
    gap: "8px",
    borderRadius: "8px",
    opacity: "0px",
    marginLeft: "16px",
    background: "#044352",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#044352"
    },
    "@media(max-width:600px)": {
      width: "100%",
    },
  },
 
})

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: '28px',
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  width: '100%',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  padding: "10px 16px",
  color: 'inherit',
  justifyContent: "space-between",
  '& .MuiInputBase-input': {
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
}));

export const Wrapper = styled(Box)({
  borderRight: "1px solid rgba(236, 236, 236, 1)",
  height: "100%",
  minHeight: "100vh",
  "& .IsItEnglishTrue":{
    direction:"ltr !important",
    "& .MuiTableCell-root":{
      textAlign:""
    }
  },
  "& .DefineRatingTableOverflow":{
    overflow: "visible",
    "@media(max-width:425px)": {
      overflow: "auto",
    }
  },
  "& .EditDeletePopUp":{
    position:"absolute",
    top:"2.7rem",
    zIndex:9999,
    right:"7%",
  },
  "& .EditDeletePopUpArabic":{
    position:"absolute",
    top:"2.7rem",
    zIndex:9999,
    left:"7%"
  },
  "& .PersonalDetailsContainer":{
    "@media(max-width:425px)": {
      paddingLeft:"10px !important",
      justifyContent: "center",
      "& .profileText":{
        marginLeft:"0px"
      }
  },
  "& .EnglishMargin":{
    marginRight:"20px",
    marginLeft:"0px"
  },
  "& .ArabicMargin":{
    marginRight:"0px",
    marginLeft:"20px"
  },
  },
  "& .IsItEnglishFalse":{
    direction:"rtl !important",
    padding:"10px !important",
    "& .MuiTableCell-root":{
      textAlign:"right"
    }
  },
  "& .logoWrapper": {
      height: "150px"
  },
  "& .menu": {
      padding: "20px 10px 25px 20px",
      display: "flex",
      flexDirection: "row",
      cursor: "pointer",
      alignItems: "start",
  },
  "& .menuProfileTitle": {
    paddingLeft: "15px",
    fontFamily: "Urbanist",
    fontSize: "18px",
    fontWeight: "700",
    lineHeight: "26px",
    color: "#044352",
},
"& .subText":{
  fontStyle:"Urbanist",
  fontWeight:"400",
  fontSize:"14px",
  lineHeight:"22px",
  color:"#334155"
},
  "& .menuTitle": {
      paddingLeft: "15px",
      fontFamily: "Urbanist",
      fontSize: "18px",
      fontWeight: "700",
      lineHeight: "26px",
      color: "#0F172A",
  },
  "& .menuSubTitle": {
    paddingLeft: "15px",
    fontFamily: "Urbanist",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "22px",
    color: "#334155",
},
  "& .activeMenuTitle": {
      backgroundColor : "#F1F5F9"
  },
  "& .profilesettings": {
    marginLeft:"30px",
    boxShadow: "0px 14px 144px 0px #0000001A"
  },
  "& .notificationsettings": {
    marginLeft:"30px",
    boxShadow: "0px 14px 144px 0px #0000001A"
  },
  "& .cultureInputField": {
    boxShadow: "0px 1px 2px 0px #1018280D",
    fontSie: "16px",
    fontFamily: 'Urbanist',
    border: '1px solid #CBD5E1',
    fontWeight: 400,
    lineHight: "24px",
    borderRadius: "8px",
    height: "98px",
    padding: "12px 16px",
    width: '100%',
    color: "#475569",
    "&:focus": {
        borderColor: "#CBD5E1",
        outline: "none",
    }
},
  "& .dropDownStyle": {
    backgroundColor: 'white',
    height: '66px', marginTop:"10px",
    borderRadius: '8px',
    border: '1px solid #CBD5E1',
    fontSie: '14px',
    fontWeight: 400,
    lineHight: "22px",
    fontFamily: 'Urbanist',
    padding: '10px 12px',
    width: '100%',
    color: "#334155"
},
"& .savebutton":{
  "@media(max-width:1050px)": {
    marginTop:"150px"
}
},
})
export const MainWrapper = styled(Box)({
  display: "flex",
  justifyContent: "start",
  width: "100%",
  marginTop: "42px",
  paddingBottom: "30px",
  "@media(max-width:768px)": {
    flexWrap:"wrap",
  },
  "& .ProgressClass":{
    "@media(max-width:425px)": {
      width:"100%"
  },
  },
  "& .ActiveDefineRatingClass":{
    backgroundColor: "#f1f5f9", marginTop:"-1px", marginLeft: "26px", marginRight: "26px", paddingLeft: "20px", paddingRight: "20px", borderBottomRightRadius: "15px", borderBottomLeftRadius: "15px",
    "@media(max-width:425px)": {
      margin: "-5px 26px",
  },
  },
  "& .RemovePadingAndCenterButtonJobLevel":{
    backgroundColor: "#f1f5f9", marginLeft: "26px", marginRight:"26px", padding: "25px 0px 60px 25px",
     borderBottomRightRadius: "15px", 
     borderBottomLeftRadius: "15px",
    "@media(max-width:425px)": {
      padding: "20px 0px",
      margin: "0px 26px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center"  
  },
  },
  "& .ActiveDefineJobLevelClass":{
    marginRight: "26px", marginLeft:"26px", paddingRight: "38px",
    paddingLeft: "15px",
    backgroundColor: "#f1f4f9",
    width: "auto", overflow: "visible",
    "@media(max-width:425px)": {
      overflow: "scroll",
      margin:"0px 26px"
  },
  },
  "& .ActiveJobFaimlyClass":{
    marginLeft: "26px",
    marginRight: "26px",
    paddingRight: "38px", paddingLeft: "15px",
    backgroundColor: "#f1f4f9",
    width: "auto",
    overflow: "visible",
    "@media(max-width:425px)": {
      overflow: "scroll",
      margin:"0px 26px"
  },
  },
  "& .TalentProfileTabs":{
    "@media(max-width:425px)": {
      marginTop:"20px"
  },
  },
  "@media(max-width:425px)": {
    justifyContent: "center",
  },
  "& .jobMainHeaderGrid": {
    marginTop: "30px"
},
"& .notificationGrid": {
  marginTop: "50px"
},
"& .helpSupportGrid": {
  marginTop: "30px"
},
"& .IsItArabicTrue":{
  direction:"right !important",
  textAlign:"right !important",
},
"& .tableDataText": {
  color:"#475569",
  fontFamily: "Urbanist",
  fontSize: "14px",
  fontWeight: "700",
  lineHeight: "22px",
  paddingTop: "30px",
  paddingBottom: "30px"
},
"& .tableDataText1": {
  paddingTop: "30px",
  paddingBottom: "30px",
},
"& .notificationButton":{
  backgroundColor:"#044352",
  color:"#FFFFFF",
  fontFamily: "Urbanist",
  fontSize: "16px",
  fontWeight: "700",
  lineHeight: "24px",
  textTransform: "none"
},
"& .profileImageMain": {
  display: "flex",
  justifyContent: "center",
},
"& .profileImage": {
  width: "100px",
  height: "100px",
  maxWidth: "150px",
  maxHeight: "150px",
  minWidth: "50px",
  minHeight: "50px",
  borderRadius: "150px",
  border: "4px solid rgba(241, 245, 249, 1)",
  backgroundColor: "rgb(226, 232, 240)",
  opacity: "0px",
  zIndex: 10,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
},
"& .profileText": {
  marginLeft:"26px",
  fontFamily: "Urbanist",
  fontSize: "16px", fontWeight: "700", lineHeight: "24px",
  color:"#64748B",
  "@media(max-width:1050px)": {
    marginLeft:"26px"
},
},
"& .notificationTitle": {
  fontFamily: "Urbanist",
  fontSize: "16px",
  fontWeight: "700",
  lineHeight: "24px",
  color:"#044352",
  "@media(max-width:1050px)": {
    marginLeft:"0px"
},
},
"& .helpSupportTitle": {
  fontFamily: "Urbanist",
  fontSize: "18px",
  fontWeight: "700",
  lineHeight: "26px",
  color:"#0F172A",
  "@media(max-width:1050px)": {
    marginLeft:"0px"
},
},
"& .languageTitle": {
  fontFamily: "Urbanist",
  fontSize: "14px",
  fontWeight: "700",
  lineHeight: "22px",
  color:"#64748B",
  marginBottom:"10px",
  "@media(max-width:1050px)": {
    marginLeft:"0px"
}
},
"& .countryCodeText": {
  paddingTop: "3px",
  textAlign: "center",
  paddingRight: "12px"
},
"& .textFields":{
  display: "flex",
  justifyContent: "space-between"
},
"& .errorText":{
  fontFamily: "Urbanist",
  fontSize: "16px",
  fontWeight: "400",
  lineHeight: "24px",
  color:"#F87171",
},
"& .textfield":{
  "@media(max-width:600px)": {
    marginLeft:"24px",
}
},
"& .textfield1":{
  "@media(max-width:600px)": {
    marginRight:"24px",
}
},
"& .rolesHeadingBox":{
  border:"1px solid #044352",
  backgroundColor:"#F1F5F9" , 
  padding:"15px 60px" , 
  borderRadius:"4px",
  marginLeft:"56px",
"@media(max-width:460px)": {
  padding:"15px 40px"
},
"@media(max-width:380px)": {
  padding:"15px 30px"
}
},
"& .talenrHeadingBox":{
  border:"1px solid #044352",
  backgroundColor:"#F1F5F9" , 
  padding:"15px 45px" , 
  borderRadius:"4px",
  color:"#044352",
  fontWeight: 700,
  // marginLeft:"56px",
"@media(max-width:460px)": {
  padding:"15px 40px"
},
"@media(max-width:380px)": {
  padding:"15px 30px"
}
},
"& .defineRatingTabs":{
  border:"1px solid #044352",
  backgroundColor:"#F1F5F9" , 
  padding:"10px 20px" , 
  borderRadius:"4px",
  color:"#044352",
  fontWeight: 700,
"@media(max-width:460px)": {
  padding:"15px 40px"
},
"@media(max-width:380px)": {
  padding:"15px 30px"
}
},
"& .rolesHeading1":{
  "@media(max-width:1285px)": {
    marginLeft:"56px",
    marginRight:"56px",
},
},
"& .rolesHeadingBox1":{
  border:"1px solid #044352",
  backgroundColor:"#F1F5F9" , 
  padding:"15px 60px" , 
  borderRadius:"4px",
  "@media(max-width:1285px)": {
    marginLeft:"56px",
    marginRight:"56px",
},
"@media(max-width:460px)": {
  padding:"15px 40px"
},
"@media(max-width:380px)": {
  padding:"15px 30px"
}
},
"& .rolesHeading2":{
  "@media(max-width:1285px)": {
    marginRight:"56px",
    marginLeft:"56px"
},
},
"& .rolesHeadingBox2":{
  border:"1px solid #044352",
  backgroundColor:"#F1F5F9" , 
  padding:"15px 60px" , 
  borderRadius:"4px",
  // marginRight:"24px",
  "@media(max-width:1285px)": {
    marginRight:"56px",
    marginLeft:"56px"
},
"@media(max-width:460px)": {
  padding:"15px 40px"
},
"@media(max-width:380px)": {
  padding:"15px 30px"
}
},
"& .rolesHeading3":{
marginLeft:"20px",
},
"& .rolesTitle":{
  fontFamily: "Urbanist",
  fontSize: "16px",
  fontWeight: "700",
  lineHeight: "24px",
  color:"#044352",
  cursor:"pointer",
  "@media(max-width:1285px)": {
    marginLeft:"0px"
},
},
"& .tableTilteText":{
  fontFamily: "Urbanist",
  fontSize: "14px",
  fontWeight: "700",
  lineHeight: "22px",
  color:"#475569",
},
"& .tableContentText":{
  fontFamily: "Urbanist",
  fontSize: "16px",
  fontWeight: "400",
  lineHeight: "24px",
  color:"#0F172A",
},
"& .disableText":{
  fontFamily: "Urbanist",
  fontSize: "17.64px",
  fontWeight: "700",
  lineHeight: "26.46px",
  color:"#64748B",
  cursor:"pointer",
},
"& .headerTab":{
  "@media(max-width:700px)": {
    justifyContent:"center",
    backgroudColor:"blue"
  },
  "@media(min-width:700px)": {
    justifyContent:"space-between",
    backgroudColor:"black"
},
},
"& .TalentProfileTabsDirection":{
  "@media(max-width:425px)": {
    flexDirection:"column",
},
},
"& .rolesSubTitle":{
  fontFamily: "Urbanist",
  fontSize: "15px",
  fontWeight: "700",
  color:"#044352",
  cursor:"pointer",
},
"& .lebelStyleContainer":{
  display: "flex",
  justifyContent: "space-between",
},
"& .disableRolesSubTitle":{
  fontFamily: "Urbanist",
  fontSize: "15px",
  fontWeight: "700",
  color:"#64748B",
  cursor:"pointer",
},
"& .textContentWords":{
  fontFamily: "Urbanist",
  fontSize: "16px",
  fontWeight: "700",
  lineHeight: "24px",
  color:"#0F172A",
},
"& .notificationSubTitle": {
  fontFamily: "Urbanist",
  fontSize: "14px",
  fontWeight: "400",
  lineHeight: "22px",
  color:"#334155",
  "@media(max-width:1050px)": {
    marginLeft:"0px"
},
},
"& .switchButton": {
  marginLeft : "0px",
  "@media(max-width:1280px)": {
    marginLeft:"26px"
},
},
"& .formText": {
  fontFamily: "Urbanist",
  fontSize: "16px",
  fontWeight: "700",
  lineHeight: "24px",
  color:"#64748B",
  marginBottom:"10px"
},
"& .formField":{
  "@media(max-width:1050px)": {
    marginLeft:"26px"
},
},
"& .profileButton": {
  fontFamily: "Urbanist",
    fontSize: "14px",
    fontWeight: "700",
    lineHeight: "22px",
    color:"#044352",
    textTransform: "none"
},
"& .buttonsprofile":{
  "@media(max-width:1050px)": {
    marginLeft:"20px"
},
},
"& .deleteButton": {
  fontFamily: "Urbanist",
    fontSize: "14px",
    fontWeight: "700",
    lineHeight: "22px",
    color:"#DC2626",
    textTransform: "none"
},
"& .textfieldStyle": {
  backgroundColor: 'white',
  height: '60px',
  borderRadius: '8px',
  border: '1px solid #CBD5E1',
  fontSie: '16px',
  fontWeight: 700,
  lineHight: "24px",
  fontFamily: 'Urbanist',
  padding: '10px 12px',
  width: '100%',
  color: "#475569"
},
"& .textfieldStyle1": {
  backgroundColor: 'white',
  height: '135px',
  borderRadius: '8px',
  border: '1px solid #CBD5E1',
  fontSie: '16px',
  fontWeight: 700,
  lineHight: "24px",
  fontFamily: 'Urbanist',
  padding: '10px 12px',
  width: '100%',
  color: "#475569"
},
"& .NotUsClass":{
  fontFamily: "Urbanist",
  fontSize: "16px",
  fontWeight: "700",
  lineHeight: "24px",
  color:"#0F172A",
  cursor:"pointer",
},
"& .NotUsClass1":{
  fontFamily: "Urbanist",
  fontSize: "16px",
  border: '1px solid #CBD5E1',
  fontSie: '16px',
  fontWeight: 700,
  lineHight: "24px",
  marginLeft:"10px"
  },
  "& .NotUsetextfieldStyle1": {
  backgroundColor: 'white',
  height: '135px',
  borderRadius: '8px',
  border: '1px solid #CBD5E1',
  fontSie: '16px',
  fontWeight: 700,
  lineHight: "24px",
  fontFamily: 'Urbanist',
  padding: '10px 12px',
  width: '100%',
  color: "#475569"
},
});

const RequiredTxt = styled("span")({
  color: 'rgb(248, 113, 113)',
  fontFamily: 'Urbanist',
  fontSize: '16px',
  fontWeight: 400,
  fontStyle: 'italic',
});

const styles = StyleSheet.create({
  mainWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    backgroundColor: "#ffff",
    width: "50%",
    fontFamily: "Roboto, sans-serif"
  },
  titleText: {
    fontSize: 30,
    paddingVertical: 10,
    fontWeight: "600",


  },
  bodyText: {
    fontSize: 20,
    paddingVertical: 15,
    fontWeight: "400",
  },
  inputStyle: {
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    backgroundColor: "rgb(98, 0, 238)",
  },
});


// Customizable Area End
