import React from "react";

// Customizable Area Start
import {
    Box,
    Grid,
    Typography,
    Button,
    CircularProgress,
    InputAdornment,
    IconButton,
    TextField,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Pagination,
    Checkbox,
    Avatar,
    MenuItem,
    Modal
} from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { arrow_Left, emptyDashboardImg, successImg } from "./assets";
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { DashboardHeaderWeb as DashboardHeader } from "../../dashboard/src/DashboardHeader.web";

import { withTranslation } from "react-i18next";
import AssignAssessmentController, {
    Props,
} from "./AssignAssessmentController";
import { styled } from "@mui/system";
import { HeaderContainer, AssessmentEmptyWrapper } from "./AssessmentTestDashbord.web";
import CustomPopup from "../../../../packages/components/src/CustomSuccessModel.web";
// Customizable Area End

export default class AssignAssessment extends AssignAssessmentController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start

    TableDataScreen = () => {
        const AllIds = this.state.AssignAssessmentTable.map((item) => item.Id)
        return (
            <Grid container justifyContent={"center"}>
                <Grid style={{ marginTop: "20px" }} item xs={12} sm={12} md={12} lg={12}>
                    <HeaderContainer>
                        <Grid container spacing={2} alignItems={"center"}>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12} sm={12} md={4} lg={4}>
                                        <Button
                                            data-test-id="candidate"
                                            fullWidth
                                            className={this.handleCondition(this.state.ActiveTableTab === 'candidate', "SelectedButton", "UnSelectedButton") as string}
                                            onClick={() => {
                                                this.ChangeHandleTab('candidate')
                                            }}
                                        >
                                            {this.TraslationFuncation("allUsers.candidate")} {(this.state.CandidateCount)}
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={4} lg={4}>
                                        <Box>
                                            <Button
                                                data-test-id="employee"
                                                fullWidth
                                                className={this.handleCondition(this.state.ActiveTableTab === 'employee', "SelectedButton", "UnSelectedButton") as string}
                                                onClick={() => {
                                                    this.ChangeHandleTab('employee')
                                                }}
                                            >
                                                Employees {(this.state.EmployeeCount)}
                                            </Button>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={4} lg={4}>
                                        <Box>
                                            <Button
                                                data-test-id="hr_sub_admin"
                                                fullWidth
                                                className={this.handleCondition(this.state.ActiveTableTab === 'hr_sub_admin', "SelectedButton", "UnSelectedButton") as string}
                                                onClick={() => {
                                                    this.ChangeHandleTab('hr_sub_admin')
                                                }}
                                            >
                                                HR SubAdmin {(this.state.HrSubAdminCount)}
                                            </Button>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <Grid container spacing={1}>
                                    <Grid item
                                        xs={12} sm={12}
                                        md={4} lg={4}
                                    >
                                        <TextField
                                            placeholder={this.TraslationFuncation("CompetencyDashboard.Search")}
                                            className="searchBox"
                                            fullWidth
                                            data-test-id="search_box"
                                            onChange={this.handleSearch}
                                            onKeyUp={this.handleKeyPress}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton data-test-id="search_icon">
                                                            <SearchIcon style={{ color: "#0F172A" }} />
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </Grid>
                                    <Grid item md={4} lg={4} xs={12} sm={12}>
                                        <Box>
                                            <Button
                                                data-test-id="FilterButton" className="OutlinedButton" variant="outlined"
                                                fullWidth
                                                onClick={() => {
                                                    this.setState({
                                                        FilterAssessmentDropDown: !this.state.FilterAssessmentDropDown
                                                    })
                                                }}
                                                endIcon={<KeyboardArrowDownIcon style={{ color: "#0F172A" }} />}
                                            >
                                                <Typography className="ButtonText">
                                                    Filter
                                                </Typography>
                                            </Button>
                                            {
                                                this.state.FilterAssessmentDropDown &&
                                                <div style={{ position: "relative", }}>
                                                    <div className="PopUpMenu" style={{
                                                        top: "4px",
                                                        width: "100%",
                                                    }}>
                                                        {Object.keys(this.state.userFilter).map((option, index) => {
                                                            return (
                                                                <MenuItem data-test-id={"FilterOptionButton" + index} onClick={() => {
                                                                    this.handleFilter(option)
                                                                }} key={`${option} + ${this.state.selectedLanguage}`} value={option}
                                                                    sx={{
                                                                        background: `${option === "All Active Users" ? "#f1f5f9" : "none"}`,
                                                                        fontFamily: "Urbanist",
                                                                        fontSize: "14px",
                                                                        fontWeight: 400,
                                                                        margin: "4px"
                                                                    }}>
                                                                    {this.state.userFilter[option]}
                                                                </MenuItem>
                                                            )
                                                        })}
                                                    </div>
                                                </div>
                                            }
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={4} lg={4}>
                                        <Box>
                                            <Button
                                                data-test-id="AssignUsers"
                                                className="ContainedButton"
                                                variant="outlined"
                                                fullWidth
                                                disabled={this.state.SelectedIds.length === 0}
                                                onClick={this.AssignAssessmentApiCall}
                                            >
                                                <Typography className="ButtonText">
                                                    Assign Users
                                                </Typography>
                                            </Button>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                {
                                    this.state.AssignAssessmentTable.length > 0 &&
                                    <TableContainer sx={{ mt: 2 }}>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell padding="checkbox">
                                                        <Checkbox
                                                            indeterminate={AllIds.length > 0 && this.state.SelectedIds.length === AllIds.length}
                                                            checked={AllIds.length > 0 && this.state.SelectedIds.length === AllIds.length}
                                                            inputProps={{ 'aria-label': 'select all desserts' }}
                                                            indeterminateIcon={<CheckBoxIcon />}
                                                            data-test-id="select_all_checkbox"
                                                            onChange={this.AllCheckAndRemove}
                                                            sx={{
                                                                color: "#044352",
                                                                '&.Mui-checked': {
                                                                    color: "#044352",
                                                                },
                                                            }}
                                                        />
                                                    </TableCell>
                                                    <TableCell className="TableTilteText">
                                                        <Box className="tableCellBox">
                                                            Profile
                                                        </Box>
                                                    </TableCell>
                                                    <TableCell className="TableTilteText">
                                                        <Box className="tableCellBox">
                                                            First Name
                                                        </Box>
                                                    </TableCell>
                                                    <TableCell className="TableTilteText">
                                                        <Box className="tableCellBox">
                                                            Last Name
                                                        </Box>
                                                    </TableCell>
                                                    <TableCell className="TableTilteText">
                                                        <Box className="tableCellBox">
                                                            Email
                                                        </Box>
                                                    </TableCell>
                                                    <TableCell className="TableTilteText">
                                                        Contact Number
                                                    </TableCell>
                                                    <TableCell className="TableTilteText">
                                                        Department
                                                    </TableCell>

                                                    <TableCell
                                                        data-test-id="delete_selected"
                                                        className="TableTilteText actionStyle">
                                                        Action
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {this.state.AssignAssessmentTable.length > 0 ? this.state.AssignAssessmentTable.map((item, index) => {
                                                    return (<TableRow>
                                                        <TableCell padding="checkbox">
                                                            <Checkbox
                                                                data-test-id={`select_checkbox${index}`}
                                                                indeterminate={this.state.SelectedIds.includes(item.Id)}
                                                                checked={this.state.SelectedIds.includes(item.Id)}
                                                                indeterminateIcon={<CheckBoxIcon />}
                                                                inputProps={{ 'aria-label': 'select all desserts' }}
                                                                sx={{
                                                                    color: "#044352",
                                                                    '&.Mui-checked': {
                                                                        color: "#044352",
                                                                    },
                                                                }}
                                                                onChange={() => {
                                                                    this.SelectAndRemoveId(item.Id)
                                                                }}
                                                            />
                                                        </TableCell>
                                                        <TableCell className="TableTilteText">
                                                            <Box className="tableCellBox">
                                                                <Avatar
                                                                    alt={item.FirstName}
                                                                    src={item.ProfilePicture}
                                                                    sx={{ width: 42, height: 42 }}
                                                                />
                                                            </Box>
                                                        </TableCell>
                                                        <TableCell className="TableTilteText">
                                                            <Box className="tableCellBox">
                                                                {item.FirstName}
                                                            </Box>
                                                        </TableCell>
                                                        <TableCell className="TableTilteText">
                                                            <Box className="tableCellBox">
                                                                {item.LastName}
                                                            </Box>
                                                        </TableCell>
                                                        <TableCell className="TableTilteText">
                                                            <Box className="tableCellBox">
                                                                {item.Email}
                                                            </Box>
                                                        </TableCell>
                                                        <TableCell className="TableTilteText">
                                                            <Box className="tableCellBox" style={{ textAlign: "center" }}>
                                                                {item.FullPhoneNumber}
                                                            </Box>
                                                        </TableCell>
                                                        <TableCell className="TableTilteText">
                                                            <Box className="tableCellBox">
                                                                {item.Department.Name}
                                                            </Box>
                                                        </TableCell>
                                                        <TableCell className="TableTilteText">
                                                            <Box className="tableCellBox" style={{ textAlign: "center" }}>
                                                                <IconButton data-test-id="dotsData">
                                                                    <MoreVertIcon />
                                                                </IconButton>
                                                            </Box>
                                                        </TableCell>
                                                    </TableRow>)
                                                }) : this.NoDataScreen()}
                                            </TableBody>
                                        </Table>
                                        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2, width: "100%" }}>
                                            <Pagination
                                                color="primary"
                                                page={this.state.page}
                                                style={{
                                                    direction: "ltr"
                                                }}
                                                data-test-id="pagination"
                                                onChange={this.PaginationFuncation}
                                                count={Math.ceil(this.state.TotalRecord / this.state.perPage)}
                                            />
                                        </Box>
                                        {this.SuccessModel()}
                                    </TableContainer>
                                }
                                {this.state.AssignAssessmentTable.length === 0 && this.NoDataScreen()}
                            </Grid>
                        </Grid>
                    </HeaderContainer>
                </Grid>
            </Grid>
        )
    }

    SuccessModel = () => {
        return (
            <Modal
                open={this.state.SuccessModel}
                data-test-id="successModel"
                onClose={this.CloseModelSuccessModel}>
                <CustomPopup
                    buttonText={"Done"}
                    data-test-id="successModelCustomPopup"
                    headingPopup={"Assessment Assigned"}
                    titlePopup={"Assessment Assigned Successful!"}
                    subTitlePopup={`The assessment has been successfully assigned to the selected candidates. Notifications have been sent.`}
                    showCancelButton={false}
                    successIcon={successImg.default}
                    onOpen={this.CloseModelSuccessModel}
                    onClose={this.CloseModelSuccessModel}
                />
            </Modal>
        )
    }

    NoDataScreen = () => {
        return (
            <AssessmentEmptyWrapper>
                <Grid container className="gridContainer">
                    <Grid item xs={12}>
                        <Box className="noDataImageBox">
                            <img src={emptyDashboardImg.default} alt="No Data" />
                            <Typography className="noDataMainText">
                                {this.TraslationFuncation("NoData")}
                            </Typography>
                            <Typography className="noDataNextText">
                                It looks like there’s nothing here yet. Add your <b>Assessments</b> for greater efficiency and growth.
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </AssessmentEmptyWrapper>
        )
    }

    RenderAllScreens = () => {
        if (this.state.isLoading) {
            return <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80vh' }}>
                <CircularProgress style={{ color: "#044352" }} />
            </Box>
        } else {
            return this.RenderTable()

        }

    }

    RenderTable = () => {
        return (
            <Grid container>
                <Grid item xs={12} sm={12} md={12} lg={12}  >
                    <Box data-test-id="BackToJobDescription"
                        style={{
                            display: "flex", alignItems: "center", justifyContent: "flex-start",
                            marginBottom: "20px", cursor: "pointer"
                        }}
                        onClick={() => {
                            this.NaviGateToAnyPage("ViewAssessment")
                        }}
                    >
                        <Box className="BackArrowBox">
                            <img src={arrow_Left.default}
                                alt="Back Arrow"
                            />
                        </Box>
                        <Typography className="HeadingTitle">Assign Assessment</Typography>
                    </Box>
                </Grid>
                {this.TableDataScreen()}
            </Grid>
        )
    }

    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <DashboardHeader selectedMenu="Assessments" navigation={this.props.navigation} id={this.props.id}>
                <AssignAssessmentStyle>
                    {this.RenderAllScreens()}
                </AssignAssessmentStyle>
            </DashboardHeader>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
export const AssignAssessmentWeb = withTranslation()(AssignAssessment)

export const AssignAssessmentStyle = styled(Box)({
    display: "flex",
    justifyContent: "center",
    marginTop: "42px",
    padding: "0px 30px",

    "& .AssignUsersBtn": {
        padding: "8px 60px",
        height: "58px",
        borderRadius: "4px",
        background: "#044352",
    },
    "& .BackArrowBox": {
        display: "flex", alignItems: "center", justifyContent: "center",
        width: "47px", height: "47px", marginRight: "10px", border: "1px solid #ECECEC", borderRadius: "50%",
        cursor: "pointer"
    },
    "& .HeadingTitle": {
        fontFamily: 'Urbanist',
        fontSize: '25px',
        fontWeight: 600,
        lineHeight: '30px'
    },
    "& .SelectedButton": {
        paddingLeft: 0,
        color: "#64748B",
        textTransform: "none",
        fontSize: "18px",
        fontWeight: 700,
        lineHeight: "26.46px",
        padding: "8px",
        borderRadius: "4px",
        border: "1px solid #64748B",
        background: "#F1F5F9",
    },
    "& .UnSelectedButton": {
        color: "#64748B",
        fontSize: "18px",
        fontWeight: 700,
        lineHeight: "26.46px",
        textTransform: "none",
        padding: "8px",
        paddingLeft: 0,
        "&:hover": {
            background: "none"
        }
    },
})


// Customizable Area End