import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import { runEngine } from "framework/src/RunEngine";
import MessageEnum, {
    getName
} from "framework/src/Messages/MessageEnum";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";


export interface Questions {
    data?: {
        id: string;
        type: string;
        attributes: {
            id: string;
            text: string;
            answer_type: string;
            question_bank_id: string;
            answer: {
                id: string;
                answer_type: string;
                choices: {
                    id: string;
                    option_text: string;
                    correct: boolean;
                }[];
                correct_choices: {
                    id: string;
                    option_text: string;
                    correct: boolean;
                }[];
            };
        };
    };
}

interface Choices {
    id?: string;
    text?: string;
    answer_type?: string;
    question_bank_id?: string;
    answer?: {
        id: string;
        answer_type: string;
        choices: {
            id: string;
            option_text: string;
            correct: boolean;
        }[];
        correct_choices?: {
            id: string;
            option_text: string;
            correct: boolean;
        }[];
    };
}

interface AssessmentQuestionResponse {
    message: string;
    assessment: {
        data: {
            id: string;
            type: string;
            attributes: {
                id: number;
                title: string;
                time_allocation: number;
                objective: string;
                number_of_questions: number;
                competencies: {
                    id: number;
                    job_title: string;
                    description: string;
                    competency_name: string;
                    job_description_id: string; // Can be more specific if the type is known
                    created_at: string;
                    updated_at: string;
                    competency_type_id: number;
                    status: boolean;
                    competency_dictionary_id: string; // Can be more specific if the type is known
                }[];
                status: string; // Can be more specific if the type is known
                creator: {
                    id: number;
                    name: string;
                };
                difficulty_levels: {
                    id: number;
                    name: string;
                }[];
                focus_areas: {
                    id: number;
                    name: string;
                }[];
                question_formats: {
                    id: number;
                    name: string;
                }[];
                job_description: {
                    id: number;
                    job_title: string;
                };
                questions: {
                    data: {
                        id: string;
                        type: string;
                        attributes: {
                            id: number;
                            text: string;
                            question_format_name: string;
                            question_bank_id: string; // Can be more specific if the type is known
                            assessment_id: number;
                            created_at: string;
                            job_description: {
                                id: string; // Can be more specific if the type is known
                                name: string; // Can be more specific if the type is known
                            };
                            competencies: []; // Can be more specific if the structure is known
                            difficulty_level: {
                                id: number;
                                name: string;
                            };
                            answer: {
                                id: number;
                                answer_type: string;
                                text_response: string;
                                choices: {
                                    id: number;
                                    option_text: string;
                                    correct: boolean;
                                }[];
                                correct_choices: {
                                    id: number;
                                    option_text: string;
                                    correct: boolean;
                                }[];
                            };
                        };
                    };
                }[];
            };
        };
    };
}

// Customizable Area End

export const configJSON = require("./config");
export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    token: string;
    selectedLanguage: string;
    questionBankId: string;
    errorMsg: string;
    questionId: string;
    editQuestion: string;
    question_format_id: number,
    difficulty_level_id: number,
    job_description_id: number,
    competency_ids: Array<number>,
    editOptions: {
        id: string;
        option_text: string;
        correct: boolean;
    }[];
    ErrorId: Array<{
        id: string;
        option_text: string;
        correct: boolean;
        ErrorText?: string
    }>,
    AnswerType: string;
    questionBankData: Choices[];
    updateDialog: boolean;
    IsLoading: boolean;
    // Customizable Area End

}

interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class EditAseessmentQuestionsController extends BlockComponent<
    Props,
    S,
    SS
> {

    // Customizable Area Start
    listQuestionApiCallId: string = "";
    editQuestionApiCallId: string = "";
    GetAssessmentData: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.CountryCodeMessage)
        ];

        this.state = {
            token: "",
            selectedLanguage: "",
            errorMsg: "",
            questionBankId: "",
            questionId: "",
            AnswerType: "",
            editQuestion: "",
            question_format_id: -1,
            difficulty_level_id: -1,
            job_description_id: -1,
            competency_ids: [],
            questionBankData: [],
            editOptions: [],
            ErrorId: [],
            updateDialog: false,
            IsLoading: false
        };

        // Customizable Area End
        runEngine.attachBuildingBlock(this, this.subScribedMessages);
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (apiRequestCallId === this.editQuestionApiCallId) {
                this.editQuestionApiResponse(responseJson)
            }
            this.GetResponseForOneQuetion(apiRequestCallId, responseJson)
        }
        // Customizable Area End
    }

    // Customizable Area Start
    async componentDidMount() {
        super.componentDidMount();
        const signInResponse = await getStorageData("signInResponse");
        const parsedSignInResponse = await JSON.parse(signInResponse)

        this.setState({ token: parsedSignInResponse.meta?.token })



        const questionId = await getStorageData("editQuestionId");  
        const parsedQuestionId = await JSON.parse(questionId)

        this.setState({ questionId: parsedQuestionId }, () => {
            this.GetAssessmentDataFuncation()
        });

        const language = await getStorageData("language") || "English";
        this.setState({ selectedLanguage: language });
    }



    editQuestionApiResponse = (responseJson: { message: string }) => {
        if (responseJson.message === "Question updated successfully") {
            this.navigateToListing();
        } else {
            alert("Something went wrong");
            this.setState({ updateDialog: false, IsLoading:false })
        }
    }



    editQuestionApiCall = async () => {
        this.setState({ IsLoading: true })
        const headers = {
            "token": this.state.token,
            "Content-Type": "application/json"
        };
        const AssessmentId = await getStorageData("assessmentId") as string

        const MultipleChoice = JSON.stringify({
            questions: [
                {
                    question: {
                        text: this.state.editQuestion,
                        question_format_id: this.state.question_format_id,
                        difficulty_level_id: this.state.difficulty_level_id,
                        job_description_id: this.state.job_description_id,
                        competency_ids: this.state.competency_ids
                    },
                    choices: this.state.editOptions.map(option => ({
                        option_text: option.option_text,
                        correct: option.correct
                    }))
                }
            ]
        })

        const ScenarioBasedBody = JSON.stringify({
            custom_question: {
                questions: [
                    {
                        question: {
                            text: this.state.editQuestion
                        },
                        text_response: this.state.editOptions[0]?.option_text
                    }
                ]
            }
        })

        const Body = this.state.AnswerType === "Multiple Choice" ? MultipleChoice : ScenarioBasedBody

        const questionMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.editQuestionApiCallId = questionMsg.messageId;
        questionMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        questionMsg.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            Body
        );
        questionMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.UpdateAssessmentQuestionUrl(AssessmentId, this.state.questionId)
        );

        questionMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.putApiMethodType
        );
        runEngine.sendMessage(questionMsg.id, questionMsg);
    }

    questionChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({ editQuestion: event.target.value });
    }

    handleOptionChange = (event: React.ChangeEvent<HTMLInputElement>, id: string): void => {
        const updatedOptions = this.state.editOptions.map(option => {
            if (option.id === id) {
                return {
                    ...option,
                    option_text: event.target.value
                };
            }
            return option;
        });

        this.setState({
            editOptions: updatedOptions
        });
    };

    handleUpdateClose = () => {
        this.setState({ updateDialog: false })
    }

    handleEditQuestion = () => {
        const index = this.state.editOptions.map((option) => {
            if (option.option_text.trim() === "") {
                return { ...option, ErrorText: "Please fill this options" };
            } else {
                return { ...option, ErrorText: "" };
            }
        })
        const IsAllFiled = index.filter((option) => option.option_text.trim() === "");

        if (IsAllFiled.length === 0) {
            this.setState({ updateDialog: true })
        } else {
            this.setState({ ErrorId: index })
        }
    }

    navigateToListing = async () => {
        const Path = await getStorageData("NaviGateFrom")
        const navigateMsg = new Message(getName(MessageEnum.NavigationMessage));
        navigateMsg.addData(getName(MessageEnum.NavigationTargetMessage), Path);
        navigateMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(navigateMsg);
    }

    GetResponseForOneQuetion = async (ApiCallId: string, responseJson: AssessmentQuestionResponse) => {
        if (ApiCallId === this.GetAssessmentData) {
            const questionResponse = responseJson.assessment.data.attributes.questions.map((item) => {
                return { ...item.data.attributes }
            });
            const questionId = await getStorageData("editQuestionId");  
            const parsedQuestionId = await JSON.parse(questionId)
            const questionDataId = parsedQuestionId;
            const matchingQuestions = questionResponse?.filter((options) => {
                if (`${options.id}` === `${Number(questionDataId)}`) {
                    return options
                }
            });

            this.setState({
                AnswerType: matchingQuestions[0]?.answer?.answer_type,
                question_format_id: 1,
                difficulty_level_id: matchingQuestions[0]?.difficulty_level.id,
                job_description_id: Number(matchingQuestions[0]?.job_description.id),
                competency_ids: matchingQuestions[0]?.competencies,
            })

            let ScenarioBased = [{
                id: `${matchingQuestions[0]?.id}`,
                option_text: `${matchingQuestions[0]?.answer?.text_response}`,
                correct: true
            }]

            let MultipleanswerTypes = matchingQuestions[0]?.answer?.answer_type === "Multiple Choice" ? matchingQuestions[0].answer?.choices.map((item) => {
                return {
                    id: `${item?.id}`,
                    option_text: item?.option_text,
                    correct: item?.correct
                }
            }) : ScenarioBased

            this.setState({
                editOptions: MultipleanswerTypes,
                IsLoading: false
            })
            if (matchingQuestions.length) {
                const currentQuestion = matchingQuestions[0].text;
                this.setState({
                    editQuestion: currentQuestion,
                    IsLoading: false
                }, () => {
                    const plainText = currentQuestion.replace(/^Question:\s*/, "") || "";
                    this.setState({ editQuestion: plainText })
                })
            }
        }
    }

    GetAssessmentDataFuncation = async () => {
        this.setState({ IsLoading: true })
        const AssessmentId = await getStorageData("assessmentId") as string

        const headers = {
            "token": this.state.token
        };
        const GetAssessment = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.GetAssessmentData = GetAssessment.messageId;
        GetAssessment.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        GetAssessment.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.GetAllAssessment}/${AssessmentId}`
        );
        GetAssessment.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getAssessementMethod
        );
        runEngine.sendMessage(GetAssessment.id, GetAssessment);
    }

    // Customizable Area End
}