export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const imgVisbility = require("../assets/visibility.svg");
export const imgVisbilityOff = require("../assets/visibilityOff.svg");
export const backArrow = require("../assets/backArrow.svg");
export const checkbox = require("../assets/checkbox.png");
export const selectedCheckbox = require("../assets/selectedCheckbox.png");
export const blankCheckbox = require("../assets/blankCheckbox.png");
export const progress = require("../assets/progress.svg");
export const ProgressSlider = require("../assets/ProgressSlider.png");
export const addQuestionImg = require("../assets/addQuestionImg.png");
export const editQuestion = require("../assets/editQuestion.png");
export const deleteQuestion = require("../assets/deleteQuestion.png");
export const deleteQuestionBank = require("../assets/deleteQuestionBank.png");
export const arrow_Left = require("../assets/arrow_Left.svg");
export const ListIcon = require("../assets/ListIcon.png");
export const UserSmallIcon = require("../assets/UserSmallIcon.png");
export const right = require("../assets/rightIcon.png");
export const Start = require("../assets/Start.png");