import React from "react";
// Customizable Area Start    
import {
    Box,
    Grid,
    styled,
    Typography,
    Card,
    CardContent,
    Divider,
    Input,
    Select,
    InputBase,
    MenuItem,
    Button,
    Dialog,
    DialogTitle,
    IconButton,
    DialogContent,
    DialogActions
} from "@mui/material";
import { DashboardHeaderWeb as DashboardHeader } from "../../dashboard/src/DashboardHeader.web";
import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { backArrow, successImg } from "./assets";
export const configJSON = require("./config");
// Customizable Area End

import DictionaryCreationController, {
    Props,
} from "./DictionaryCreationController";
import { withTranslation } from "react-i18next";

export default class DictionaryCreation extends DictionaryCreationController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    dictionaryCreationDialog = () => {
        return (
            <Dialog
                open={this.state.openDicDialog}
                onClose={this.closeDicDialog}
                PaperProps={{
                    sx: {
                        direction: this.state.selectedLanguage.toLowerCase() === "english" ? "ltr" : "rtl",
                        borderRadius: "8px 8px 32px 8px",
                        overflow: 'hidden',
                    }
                }}
            >
                <DialogTitle>
                    <Wrapper>
                        <Typography className="successDicDialogTitle">
                            {this.TrueOrFalseCondition(this.state.isEdit,this.TraslationFuncation("CompetencyDashboard.CompetencyDictUpdated"),this.TraslationFuncation("CompetencyDashboard.DictionaryCreated"))}
                        </Typography>
                        <IconButton
                            aria-label="close"
                            data-test-id="close_dic_success"
                            onClick={this.closeDicDialog}
                            className="successDicDialogClose"
                            sx={{
                                right: this.state.selectedLanguage.toLowerCase() === "english" ? 30 : "auto",
                                left: this.state.selectedLanguage.toLowerCase() === "english" ? "auto" : 30
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Wrapper>
                </DialogTitle>
                <Divider sx={{ color: "#E2E8F0" }} />
                <DialogContent>
                    <Wrapper>
                        <Box className="successDicImageBox">
                            <img src={successImg.default} alt="Success Image" height={144} width={144} />
                        </Box>
                    </Wrapper>
                    <Typography
                        sx={{
                            fontSize: "20px",
                            fontWeight: 700,
                            fontFamily: "Urbanist",
                            padding: "14px 34px",
                            lineHeight: "28px",
                            color: "#0F172A",
                            textAlign: "center"
                        }}>
                        {this.state.isEdit ?
                            this.TraslationFuncation("CompetencyDashboard.CompetencyDictUpdatedSuccessfully")
                            :
                            this.TraslationFuncation("CompetencyDashboard.CompetencyDictionaryCreatedSuccessfully")
                        }
                    </Typography>
                    <Typography
                        sx={{
                            fontWeight: 400,
                            lineHeight: "26px",
                            fontFamily: "Urbanist",
                            padding: "0px 68px",
                            fontSize: "18px",
                            color: "#0F172A",
                            textAlign: "center"
                        }}>
                        {this.state.isEdit ?
                            <>
                                {this.TraslationFuncation("CompetencyDashboard.TheCompetencyFor")} <span style={{ fontWeight: 700 }}>{this.state.competencyName}</span> {this.TraslationFuncation("CompetencyDashboard.HasBeenUpdatedSuccessfully")}
                            </> :
                            <>
                                {this.TraslationFuncation("CompetencyDashboard.TheCompetencyFor")} <span style={{ fontWeight: 700 }}>{this.state.competencyName}</span> {this.TraslationFuncation("CompetencyDashboard.HasBeenCreatedSuccessfully")}
                            </>
                        }
                    </Typography>

                </DialogContent>
                <Divider />
                <DialogActions>
                    <Wrapper>
                        <Box className={`${this.state.selectedLanguage.toLowerCase() === "english" ? "dicButtonsBox" : "dicButtonsBoxArabic"}`}>
                            <Button
                                color="primary"
                                variant="contained"
                                data-test-id="navigate_to_view"
                                onClick={this.navigateToViewDictionary}
                                className={`${this.state.selectedLanguage.toLowerCase() === "english" ? "viewDicButton" : "viewDicButtonArabic"}`}
                            >
                                {this.TraslationFuncation("CompetencyDashboard.Done")}
                            </Button>
                        </Box>
                    </Wrapper>
                </DialogActions>
            </Dialog>
        )
    }


    competencyName = () => {
        const error = this.state.saveDicClicked && !this.state.competencyName && this.TraslationFuncation("CompetencyDashboard.ThisFieldIsRequired");
        return (
            <Wrapper>
                <Box className="errorStyleMainbox">
                    <Typography className="dicCardInputLabel">
                        {this.TraslationFuncation("CompetencyDashboard.CompetencyName")} <span>*</span>
                    </Typography>
                    <Typography className="errorStyle">
                        {error}
                    </Typography>
                </Box>
                <Input
                    data-test-id="competency_name"
                    className={`${error ? "errorClass" : ""} dicEnterInputField`}
                    disableUnderline
                    placeholder={this.TraslationFuncation("CompetencyDashboard.EnterCompetencyName")}
                    value={this.state.competencyName}
                    onChange={this.handleCompetencyNameChange}
                    autoComplete="off"
                />
            </Wrapper>
        )
    }

    competencyType = () => {
        const error = this.state.saveDicClicked && !this.state.selectedCompetencyType && this.TraslationFuncation("CompetencyDashboard.ThisFieldIsRequired");
        return (
            <Wrapper>
                <Box className="errorStyleMainbox">
                    <Typography className="compdInputLabel">
                        {this.TraslationFuncation("CompetencyDashboard.CompetencyType")} <span>*</span>
                    </Typography>
                    <Typography className="errorStyle">
                        {error}
                    </Typography>
                </Box>
                <Select
                    className={`${error ? "errorClass" : ""} dropDownStyle`}
                    data-test-id="competency_type"
                    inputProps={{
                        icon: {
                            left: 0,
                        },
                    }}
                    displayEmpty
                    variant="outlined"
                    value={this.state.selectedCompetencyType}
                    renderValue={
                        this.state.selectedCompetencyType
                            ? undefined
                            : () => (
                                <div className="dropDownplaceHolder">
                                    {this.TraslationFuncation("CompetencyDashboard.ChooseTheCompetencyType")}
                                </div>
                            )
                    }
                    IconComponent={KeyboardArrowRightIcon}
                    onChange={this.handleCompetencyTypeChange}
                    input={<InputBase />}
                >
                    {this.state.competencyType?.map((comp) => (
                        <MenuItem key={comp.id} value={comp.id}
                            sx={{
                                fontSize: "16px",
                                fontFamily: "Urbanist",
                                fontWeight: 400,
                                margin: "4px"
                            }}>
                            {comp.name}
                        </MenuItem>
                    ))}
                </Select>
            </Wrapper>
        )
    }

    targetLevel = () => {
        const error = this.state.saveDicClicked && !this.state.selectedTargetLevel && this.TraslationFuncation("CompetencyDashboard.ThisFieldIsRequired");
        return (
            <Wrapper>
                <Box className="errorStyleMainbox">
                    <Typography className="compdInputLabel">
                        {this.TraslationFuncation("CompetencyDashboard.TargetLevel")} <span>*</span>
                        <span>*</span>
                    </Typography>
                    <Typography className="errorStyle">
                        {error}
                    </Typography>
                </Box>
                <Select
                    className={`${error ? "errorClass" : ""} dropDownStyle`}
                    data-test-id="target_level"
                    inputProps={{
                        icon: {
                            left: 0,
                        },
                    }}
                    value={this.state.selectedTargetLevel}
                    displayEmpty
                    variant="outlined"
                    renderValue={
                        this.state.selectedTargetLevel
                            ? undefined
                            : () => (
                                <div className="dropDownplaceHolder">
                                    {this.TraslationFuncation("CompetencyDashboard.ChooseTargetLevel")}
                                </div>
                            )
                    }
                    IconComponent={KeyboardArrowRightIcon}
                    onChange={this.handleTargetLevelChange}
                    input={<InputBase />}
                >
                    {this.state.targetLevel?.map((targetLevel) => (
                        <MenuItem key={targetLevel.id} value={targetLevel.id}
                            sx={{
                                fontSize: "16px",
                                fontFamily: "Urbanist",
                                fontWeight: 400,
                                margin: "4px"
                            }}>
                            {targetLevel.name}
                        </MenuItem>
                    ))}
                </Select>
            </Wrapper>
        )
    }

    dictionaryDescription = () => {
        const error = this.state.saveDicClicked && !this.state.dictionaryDescription && this.TraslationFuncation("CompetencyDashboard.ThisFieldIsRequired")
        return (
            <Wrapper>
                <Box className="errorStyleMainbox">
                    <Typography className="compdInputLabel">
                        {this.TraslationFuncation("CompetencyDashboard.Description")} <span>*</span>
                    </Typography>
                    <Typography className="errorStyle">
                        {error}
                    </Typography>
                </Box>
                <textarea
                    data-test-id="dic_description"
                    className={`${error ? "errorClass" : ""} dicDescInputField`}
                    placeholder={this.TraslationFuncation("CompetencyDashboard.WriteAboutYourJob")}
                    value={this.state.dictionaryDescription}
                    onChange={(event) => this.handleDicDescription(event)}
                />
                <Typography className="companyCultureLimit">{this.state.dictionaryDescription?.length || 0}/1000</Typography>
            </Wrapper>
        )
    }

    companyFormHeader = () => {
        return (
            <Wrapper>
                <Box className="dicItemMainBox">
                    <Grid container className={"dicCardContainer"+this.TrueOrFalseCondition(this.state.selectedLanguage.toLowerCase() === "english"," dicCardContainerEnglish ", " dicCardContainerArabic ")} justifyContent={"flex-start"}>
                        <Grid item xs={12} className="dicCardItem">
                            <Card className="dicCardDescBox">
                                <CardContent className="dicCardContentPadding">
                                    <Grid container>
                                        <Grid item xs={12} className="dicDetailPadding">
                                            <Typography className="dicCardTitle">
                                                {this.TraslationFuncation("CompetencyDashboard.CompetencyDictionary")}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Divider />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={3.5} className="dicContentPadding">
                                        <Grid item xs={12}>
                                            {this.competencyName()}
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            {this.competencyType()}
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            {this.targetLevel()}
                                        </Grid>
                                        <Grid item xs={12}>
                                            {this.dictionaryDescription()}
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            {this.cancelCompDictionaryBtn()}
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            {this.saveCompDictionaryBtn()}
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </Box>
            </Wrapper>
        )
    }

    cancelCompDictionaryBtn = () => {
        return (
            <Wrapper>
                <Button
                    className="dictionaryCancelBtn"
                    data-test-id="cancel_dictionary"
                    onClick={this.navigateToDashboard}
                >
                    <Typography className="dicCancelText">
                        {this.TraslationFuncation("CompetencyDashboard.Cancel")}
                    </Typography>
                </Button>
            </Wrapper>
        )
    }

    saveCompDictionaryBtn = () => {
        return (
            <Wrapper>
                <Button
                    className="saveDictionaryBtn"
                    data-test-id="save_dictionary"
                    onClick={this.handleSaveCompDictionary}
                >
                    <Typography className="saveCompText">
                        {this.state.isEdit ?
                            this.TraslationFuncation("CompetencyDashboard.UpdateCompetencyDict")
                            :
                            this.TraslationFuncation("CompetencyDashboard.SaveCompetencyDictionary")
                        }
                    </Typography>
                </Button>
            </Wrapper>
        )
    }
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <DashboardHeader selectedMenu="Competencies" navigation={this.props.navigation} id={this.props.id}>
                <MainWrapper style={{ direction: this.state.selectedLanguage.toLowerCase() === "english" ? "ltr" : "rtl" }}>
                    <Grid container justifyContent={"center"}>
                        <Grid item xs={11}>

                            <Grid container
                                spacing={1}
                                justifyContent={"center"}
                                alignItems={"center"}
                                className={this.TrueOrFalseCondition(this.state.selectedLanguage.toLowerCase() === "english", "dashboardGrid", "dashboardGridArabic")}
                            >
                                <Grid item xs={1.5} sm={1} md={0.8} lg={0.5}>
                                    <Box data-test-id="BackToJobDescription">
                                        <img src={backArrow.default}
                                            alt="Back Arrow"
                                            className={this.TrueOrFalseCondition(this.state.selectedLanguage.toLowerCase() === "english","backArrowImg","backArrowImgArabic")}
                                            data-test-id="back_to_dashboard"
                                            onClick={this.navigateToDashboard}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={10.5} sm={11} md={11.2} lg={6}>
                                    <Typography className={"createDicTitle" + this.TrueOrFalseCondition(this.state.selectedLanguage.toLowerCase() === "english",""," createDicTitleArabic ")}>
                                        {this.state.isEdit ?
                                            this.TraslationFuncation("CompetencyDashboard.EditCompetencyDictionary") :
                                            this.TraslationFuncation("CompetencyDashboard.CreateCompetencyDictionary")
                                        }
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container spacing={1} className={this.TrueOrFalseCondition(this.state.selectedLanguage.toLowerCase() === "english"," compMainHeaderGrid "," compMainHeaderGridArabic ")}>
                                <Grid item xs={12} sm={12} md={11.2} lg={11.5}>
                                    {this.companyFormHeader()}
                                </Grid>
                            </Grid>
                        </Grid>
                        {this.dictionaryCreationDialog()}
                    </Grid>
                </MainWrapper>
            </DashboardHeader>
            // Customizable Area End
        );
    }
}

// Customizable Area Start

export const DictionaryCreationWeb = withTranslation()(DictionaryCreation);

export const MainWrapper = styled(Box)({
    display: "flex",
    justifyContent: "center",
    width: "100%",
    marginTop: "42px",
    paddingBottom: "30px",
    "& .dashboardGrid":{
        direction:"",
        justifyContent: "flex-start !important",
    },
    "& dashboardGridArabic":{
        direction:"rtl",
    },
    "& .compMainHeaderGridArabic":{
        justifyContent: "flex-end"
    },
    "& .backArrowImg": {
        paddingRight: "12px"
    },
    "& .backArrowImgArabic": {
        paddingRight: "12px",
        transform: "rotate(180deg)"
    },
    "& .createDicTitle": {
        fontSize: "25px",
        fontWeight: 600,
        fontFamily: "Urbanist",
        lineHeight: "30px",
        color: "#000104",
        marginLeft: "12px"
    },
    "& .createDicTitleArabic":{
        marginRight: "12px"
    }

})
export const Wrapper = styled(Box)({
    width: "100%",
    "& .dicCardDescBox": {
        boxShadow: "rgba(0, 0, 0, 0.1) 0px 14px 144px 0px",
        width: "55vw",
        margin: "4vh 3vw",
        "@media(max-width:900px)": {
            width: "80vw"
        },
    },
    "& .successDicDialogTitle": {
        fontFamily: "Urbanist",
        color: "#1E1E1E",
        fontSize: "24px",
        fontWeight: 700,
        margin: "8px 16px",
        lineHeight: "32px",
    },
    "& .successDicDialogClose": {
        top: 16,
        position: 'absolute',
        color: "#334155"
    },
    "& .successDicImageBox": {
        display: "flex",
        justifyContent: "center",
        padding: "10px 0px",
    },
    "& .dicCardInputLabel": {
        linHheight: "22px",
        fontFamily: "Urbanist",
        fontSize: "14px",
        fontWeight: 700,
        color: "#344054",
        marginBottom: "10px"
    },
    "& .dicCardItem": {
        scrollSnapAlign: "center",
        flex: "0 0 auto",
    },
    "& .dictionaryCancelBtn": {
        padding: "10px 16px 10px 16px",
        width: "100%",
        height: "58px",
        borderRadius: "4px",
        border: "1px solid #94A3B8",
        marginTop: "16px",
        "&:hover": {
            background: "none"
        }
    },
    "& .dicButtonsBox": {
        paddingRight: "16px",
        display: "flex",
        margin: "16px 0px",
        justifyContent: "flex-end"
    },
    "& .dicButtonsBoxArabic": {
        display: "flex",
        paddingLeft: "16px",
        justifyContent: "flex-end",
        margin: "16px 0px"
    },
    "& .dicItemMainBox": {
        width: "100%",
        overflow: "hidden",
        display: "flex",
        justifyContent: "center",
    },
    "& .dropDownStyle": {
        fontSie: '16px',
        backgroundColor: 'white',
        height: '66px',
        borderRadius: '8px',
        lineHight: "24px",
        border: '1px solid #CBD5E1',
        fontWeight: 700,
        fontFamily: 'Urbanist',
        padding: '10px 12px',
        width: '100%',
        color: "#044352"
    },
    "& .dicEnterInputField": {
        borderRadius: '8px',
        padding: '12px 16px',
        fontFamily: 'Urbanist',
        color: "#044352",
        border: '1px solid #CBD5E1',
        fontSie: '16px',
        fontWeight: 700,
        lineHight: "24px",
        height: '66px',
        width: '100%'
    },
    "& .MuiSelect-icon": {
        transform: 'none !important',
    },
    "& .dicDetailPadding": {
        padding: "30px 56px 30px 35px",
        "@media(max-width:425px)": {
            padding: "20px 10px !important",
        },
    },
    "& .dicDescInputField": {
        fontWeight: 400,
        lineHight: "24px",
        minHeight: "98px",
        height: "auto",
        borderRadius: "8px",
        boxShadow: "0px 1px 2px 0px #1018280D",
        fontSie: "16px",
        fontFamily: 'Urbanist',
        border: '1px solid #CBD5E1',
        padding: "12px 16px",
        width: '100%',
        color: "#475569",
        "&:focus": {
            borderColor: "#CBD5E1",
            outline: "none",
        }
    },
    "& .dicCardContentPadding": {
        padding: "0px 0px 42px 0px"
    },
    "& .dicContentPadding": {
        padding: "30px 56px 0px 35px",
        "@media(max-width:425px)": {
            padding: "0px 10px !important",
        },
    },
    "& .viewDicButton": {
        fontFamily: "Urbanist",
        fontSize: "16px",
        padding: "16px 24px",
        fontWeight: 700,
        width: "30%",
        height: "56px",
        gap: "8px",
        borderRadius: "8px",
        opacity: "0px",
        marginLeft: "16px",
        background: "#044352",
        textTransform: "none",
        "&:hover": {
            backgroundColor: "#044352"
        },
    },
    "& .viewDicButtonArabic": {
        fontFamily: "Urbanist",
        padding: "16px 39.5px 16px 36.5px",
        height: "56px",
        gap: "8px",
        borderRadius: "8px",
        opacity: "0px",
        fontSize: "16px",
        fontWeight: 700,
        width: "120px",
        marginRight: "16px",
        background: "#044352",
        textTransform: "none",
        "&:hover": {
            backgroundColor: "#044352"
        },
    },
    "& .dicCardTitle": {
        fontWeight: 600,
        fontFamily: "Urbanist",
        fontSize: "20px",
        lineHeight: "24px",
        color: "#0444352"
    },
    "& .errorStyleMainbox": {
        display: "flex",
        justifyContent: "space-between"
    },
    "& .saveCompText": {
        fontWeight: 700,
        fontSize: "16px",
        color: "#FFFFFF",
        fontFamily: "Urbanist",
        lineHeight: "24px",
        textTransform: "none"
    },
    "& .saveDictionaryBtn": {
        border: "1px solid #94A3B8",
        width: "100%",
        borderRadius: "4px",
        height: "58px",
        padding: "10px 16px 10px 16px",
        background: "#044352",
        marginTop: "16px",
    },
    "& .dicCardContainerEnglish":{
        justifyContent: "flex-start",
    },
    "& .dicCardContainerArabic":{
        justifyContent: "flex-end",
    },
    justifyContent: "flex-start",
    "& .dicCardContainer": {
        display: "flex",
        overflowX: "auto",
        scrollSnapType: "x mandatory",
        scrollBehavior: "smooth",
        padding: "0px 8px",
        width: "100%",
        scrollbarWidth: "none",
        "&::-webkit-scrollbar": {
            display: "none",
        },
    },
    "& .errorStyle": {
        fontSize: "16px",
        fontWeight: 400,
        fontFamily: "Urbanist",
        color: "#F87171",
        fontStyle: "italic",
        display: "flex",
        justifyContent: "flex-end"
    },
    "& .dicCancelText": {
        fontSize: "16px",
        fontWeight: 700,
        fontFamily: "Urbanist",
        lineHeight: "24px",
        color: "#000000",
        textTransform: "none"
    },
    "& .compdInputLabel": {
        marginBottom: "10px",
        fontFamily: "Urbanist",
        fontSize: "14px",
        fontWeight: 700,
        linHheight: "22px",
        color: "#344054"
    },
    "& .errorClass": {
        border: '1px solid #F87171 !important',
    },
    "& .companyCultureLimit": {
        display: "flex",
        justifyContent: "flex-end",
        fontSize: "12px",
        fontWeight: 400,
        fontFamily: "Urbanist",
    },
    "& input::placeholder, textarea::placeholder": {
        color: "grey",
        fontSize: "16px",
        fontWeight: 400
    },
    "& .dropDownplaceHolder": {
        color: "lightgrey",
        fontSize: "16px",
        fontWeight: 400
    }
})
// Customizable Area End