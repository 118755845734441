import React from "react";

// Customizable Area Start
import {
    Box,
    Grid,
    Typography,
    Card,
    CardContent,
    Divider,
    Select,
    InputBase,
    MenuItem,
    Button,
    CircularProgress
} from "@mui/material";
import { DashboardHeaderWeb as DashboardHeader } from "../../dashboard/src/DashboardHeader.web";
import { arrow_Left } from "./assets";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { withTranslation } from "react-i18next";
import { Wrapper, MainWrapper } from "./Cfchatgptintegration9.web"
import ViewJobDescriptionController, {
    Props,
    configJSON,
} from "./ViewJobDescriptionController.web";
// Customizable Area End

export default class ViewJobDescription extends ViewJobDescriptionController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start

    ViewJobDetailsRender = () => {
        const keySkillData = this.state.ShowJobDescriptChatGpt.key_skills.map((skill: string) => skill.replace(/^- /, '').trim())
            .filter(Boolean);

        const keyRespoData = this.state.ShowJobDescriptChatGpt.key_responsibilities
            ?.map((responsibility: string) => responsibility.replace(/^- /, '').trim())
            .filter(Boolean);

        const keyWordsData = this.state.ShowJobDescriptChatGpt.key_words.join(", ").split(",").map((keyword: string) => keyword.trim())
            .filter(Boolean);
        const GeneratedRequirements = this.state.ShowJobDescriptChatGpt.generated_requirements.map((requirement: string) => requirement.replace(/^- /, '').trim())
        return (
            <Grid container justifyContent={"center"}>
                <Grid xs={11} >
                    <Grid container spacing={1} alignItems={"center"}>
                        <Grid item xs={12} sm={12} md={0.8} lg={0.5}  >
                            <Box style={{ display: "flex", alignItems: "center" }}>
                                <Box data-test-id="backToViewDetails"
                                    onClick={() => this.CloseSuccessModel()}
                                    style={{
                                        cursor: "pointer",
                                        width: "47px", height: "47px",
                                        border: "1px solid #ECECEC", borderRadius: "50%",
                                        display: "flex", alignItems: "center",
                                        justifyContent: "center",
                                        transform: this.ConditionForLanguage("", "rotate(180deg)")
                                    }}
                                >
                                    <img src={arrow_Left.default} alt="Back Arrow" />
                                </Box>
                                <Typography className="jobTitle MobileText" style={{ marginRight: this.ConditionForLanguage("", "12px") }} >{this.TraslationFuncation("JobDescriptionPage.JobDescription")}</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={12} md={11.2} lg={11.5} display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
                            <Typography className="jobTitle DexstopText" style={{ marginRight: this.ConditionForLanguage("", "12px") }} >{this.TraslationFuncation("JobDescriptionPage.JobDescription")}</Typography>
                            <Grid
                                item
                                xs={12} sm={12} md={5}
                                display={"flex"}
                                gap={3}
                                justifyContent={{ xs: "center", md: "flex-end" }}
                                sx={{
                                    flexDirection: { xs: 'column', sm: 'row' },
                                    alignItems: { xs: 'stretch', sm: 'center' },
                                }}
                            >
                                <Select
                                    sx={{
                                        backgroundColor: "#044352",
                                        width: { xs: '100%', sm: '100%' },
                                        height: "58px",
                                        padding: "10px 16px",
                                        borderRadius: "4px",
                                        "& .MuiSelect-icon": {
                                            color: "#FFFFFF",
                                            transform: 'none !important',
                                        },
                                    }}
                                    data-test-id="setDownload"
                                    className="downloadButton"
                                    variant="outlined"
                                    name="download"
                                    displayEmpty
                                    // value={this.state.selectedOption}
                                    // onChange={this.handleChange}
                                    IconComponent={KeyboardArrowRightIcon}
                                    input={<InputBase />}
                                    renderValue={() => (
                                        <span style={{
                                            color: '#FFFFFF',
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            fontFamily: "Urbanist",
                                            fontSize: "16px",
                                            fontWeight: 700,
                                            lineHeight: "28px",
                                        }}>
                                            {this.TraslationFuncation("JobDescriptionPage.Download")}
                                        </span>
                                    )}
                                >
                                    {configJSON.downloadDropDownData?.map((option: string, index: number) => (
                                        <MenuItem key={index} value={option}>
                                            {option}
                                        </MenuItem>
                                    ))}
                                </Select>
                                <Button
                                    data-test-id="JobDetail"
                                    className="editButton"
                                    variant="outlined"
                                    sx={{
                                        backgroundColor: "#044352",
                                        width: { xs: '100%', sm: '100%' },
                                        height: "58px",
                                        padding: "10px 16px",
                                        borderRadius: "4px",
                                        textTransform: "none",
                                        "&:hover": {
                                            backgroundColor: "#044352",
                                        },
                                        "& .MuiTypography-root": {
                                            fontFamily: "Urbanist",
                                            fontSize: "16px",
                                            fontWeight: 500,
                                            lineHeight: "28px",
                                            color: "#FFFFFF",
                                        }
                                    }}
                                    onClick={() => {
                                        this.NavigateToAnyPage("EditJobDescription")
                                    }}
                                >
                                    <Typography className="editButtonText" style={{ fontWeight: 700 }}>{this.TraslationFuncation("JobDescriptionPage.EditJobDetails")}</Typography>
                                </Button>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={12} md={11.2} lg={11.5} className="jobDetailHeaderGrid">
                            <Wrapper>
                                <Box className="companyItemMainBox">
                                    <Grid container className={"companyCardContainer"} justifyContent={"flex-start"}>
                                        <Grid item xs={12} className="companyCardItem">
                                            <Card className="jobdetailBox">
                                                <CardContent
                                                    className="cardContentPadding"><Grid
                                                        container> <Grid item xs={12} className="orgDetailPadding">
                                                            <Typography className="JobDetailTitle">{this.state.ShowJobDescriptChatGpt.position}
                                                            </Typography></Grid>
                                                        <Grid item xs={12}><Divider
                                                            sx={{ color: "#E2E8F0", width: 'calc(100% - 35px)', marginRight: '35px', }} /></Grid></Grid>
                                                    <Grid container spacing={3.5} className="contentPadding"><Grid item xs={12} md={6}>
                                                        <Wrapper>
                                                            <Box className="errorStyleMainbox">
                                                                <Typography className="JobDetailLabel">
                                                                    {this.TraslationFuncation("JobDescriptionPage.JobID")}: {this.state.viewedId}
                                                                </Typography>
                                                            </Box>
                                                            <Typography className="JobDetailContent">
                                                                {this.TraslationFuncation("JobDescriptionPage.JobTitle")} {this.state.ShowJobDescriptChatGpt.position}
                                                            </Typography>
                                                            <Typography className="JobDetailContent">
                                                                {this.TraslationFuncation("JobDescriptionPage.DepartmentTeam")}  {this.state.ShowJobDescriptChatGpt.department}
                                                            </Typography>
                                                            <Typography className="JobDetailContent">
                                                                {this.TraslationFuncation("JobDescriptionPage.JobFamily")}:  {this.state.ShowJobDescriptChatGpt.JobFamily}
                                                            </Typography>
                                                        </Wrapper>
                                                    </Grid>
                                                    </Grid>
                                                    <Grid item xs={11.65} marginTop={"25px"}>
                                                        <Divider className="DividerClass" />
                                                    </Grid>
                                                    <Grid container spacing={3.5} className="contentPadding">
                                                        <Grid item xs={10.9}>
                                                            <Wrapper>
                                                                <Box className="errorStyleMainbox">
                                                                    <Typography className="JobDetailLabel">
                                                                        {this.TraslationFuncation("JobDescriptionPage.JobSummary")}
                                                                    </Typography>
                                                                </Box>
                                                                <Typography className="JobDetailContent"> {this.state.ShowJobDescriptChatGpt.job_summary} </Typography>
                                                            </Wrapper>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={11.65}
                                                        marginTop={"25px"}>
                                                        <Divider
                                                            className="DividerClass" />
                                                    </Grid>
                                                    <Grid container spacing={3.5} className="contentPadding">
                                                        <Grid item xs={11}>
                                                            <Wrapper>
                                                                <Box className="errorStyleMainbox">
                                                                    <Typography className="JobDetailLabel">
                                                                        {this.TraslationFuncation("JobDescriptionPage.JobExperience")}
                                                                    </Typography>
                                                                </Box>
                                                                <Typography className="JobDetailContent">
                                                                    {this.state.ShowJobDescriptChatGpt.job_experience}
                                                                </Typography>
                                                            </Wrapper>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={11.65} marginTop={"25px"}>
                                                        <Divider
                                                            sx={{
                                                                color: "#E2E8F0", width: 'calc(100% - 35px)', marginLeft: '35px',
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid className="contentPadding" container spacing={3.5}>
                                                        <Grid item xs={11}>
                                                            <Wrapper>
                                                                <Box className="errorStyleMainbox">
                                                                    <Typography className="JobDetailLabel"> {this.TraslationFuncation("JobDescriptionPage.JobResponsibilities")} </Typography>
                                                                </Box>
                                                                {keyRespoData?.length > 0 && keyRespoData?.map((respo: string, index: number) => (
                                                                    <Typography className="JobDetailContent" key={index}>
                                                                        {index + 1}. {respo}
                                                                    </Typography>
                                                                ))}
                                                            </Wrapper>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={11.65} marginTop={"25px"}>
                                                        <Divider
                                                            sx={{
                                                                color: "#E2E8F0", width: 'calc(100% - 35px)', marginLeft: '35px',
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid container spacing={3.5} className="contentPadding">
                                                        <Grid item xs={11}>
                                                            <Wrapper>
                                                                <Box className="errorStyleMainbox">
                                                                    <Typography className="JobDetailLabel"> {this.TraslationFuncation("JobDescriptionPage.KeySkills")}</Typography>
                                                                </Box>
                                                                {keySkillData?.length > 0 && keySkillData?.map((skills: string, index: number) => (
                                                                    <Typography className="JobDetailContent" key={index}>{index + 1}. {skills} </Typography>
                                                                ))}
                                                            </Wrapper>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={11.65} marginTop={"25px"}>
                                                        <Divider
                                                            className="DividerClass"
                                                        />
                                                    </Grid>
                                                    <Grid container spacing={3.5} className="contentPadding">
                                                        <Grid item xs={11}>
                                                            <Wrapper>
                                                                <Box className="errorStyleMainbox">
                                                                    <Typography className="JobDetailLabel"> {this.TraslationFuncation("JobDescriptionPage.JobRequirements")}</Typography>
                                                                </Box>
                                                                {GeneratedRequirements?.length > 0 && GeneratedRequirements?.map((skills: string, index: number) => (
                                                                    <Typography className="JobDetailContent" key={index}>{index + 1}. {skills} </Typography>
                                                                ))}
                                                            </Wrapper>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={11.65} marginTop={"25px"}>
                                                        <Divider
                                                            className="DividerClass"
                                                        />
                                                    </Grid>
                                                    <Grid container spacing={3.5} className="contentPadding">
                                                        <Grid item xs={11}>
                                                            <Wrapper>
                                                                <Box className="errorStyleMainbox">
                                                                    <Typography className="JobDetailLabel">
                                                                        {this.TraslationFuncation("JobDescriptionPage.KeyWords")}
                                                                    </Typography>
                                                                </Box>
                                                                {keyWordsData?.length > 0 && keyWordsData?.map((word: string, index: number) => (
                                                                    <Typography className="JobDetailContent" key={index}>
                                                                        {index + 1}. {word}
                                                                    </Typography>
                                                                ))}
                                                            </Wrapper>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={11.65} marginTop={"25px"}>
                                                        <Divider
                                                            className="DividerClass"
                                                        />
                                                    </Grid>
                                                </CardContent>
                                            </Card>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Wrapper>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        )
    }

    loaderScreen = () => {
        return (
            <>
                <Box className="loader-outer"
                    sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "80vh" }}>
                    <Box className="loader-inner">
                        <CircularProgress />
                    </Box>
                </Box>
            </>
        )
    }

    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <DashboardHeader selectedMenu="Job descriptions" navigation={this.props.navigation} id={this.props.id}>
                <MainWrapper style={{ direction: this.state.selectedLanguage.toLowerCase() === "english" ? "ltr" : "rtl" }}>
                    {
                        this.state.loader ? this.loaderScreen() :
                            this.ViewJobDetailsRender()
                    }
                </MainWrapper>
            </DashboardHeader>
            // Customizable Area End
        );
    }
}

export const ViewJobDescriptionWeb = withTranslation()(ViewJobDescription)


// Customizable Area Start