import React from "react";
// Customizable Area Start
import {
    Box,
    Grid,
    styled,
    Typography,
    Button,
    CardContent,
    Card,
    Divider,
    CircularProgress,
    Dialog,
    DialogTitle,
    IconButton,
    DialogContent,
    DialogActions,
    Checkbox
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { DashboardHeaderWeb as DashboardHeader } from "../../dashboard/src/DashboardHeader.web";
import moment from "moment";
import { backArrow, deActivateImg, emptyDashboardImg, competenciesImg, companyImg, genderImg, birthDateImg, emailImg, contactImg, userNameImg, defaultUserImg } from "./assets";
import { withTranslation } from "react-i18next";
// Customizable Area End

import ViewUserProfileController, {
    Props
} from "./ViewUserProfileController";

export default class ViewUserProfile extends ViewUserProfileController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    deActivateUserDialog = () => {
        return (
            <Dialog
                open={this.state.openDeActivateUser}
                onClose={this.closeDeActivateUser}
                PaperProps={{
                    sx: {
                        direction: this.state.selectedLanguage.toLowerCase() === "english" ? "ltr" : "rtl",
                        borderRadius: "8px 8px 32px 8px",
                        overflow: 'hidden',
                    }
                }}
            >
                <DialogTitle>
                    <Wrapper>
                        <Typography className="deactivateUserDialogTitle">
                            {this.state.userProfileData.data?.attributes.activated ?
                                this.props.i18n?.t("allUsers.deactivateUser")?.toString() :
                                this.props.i18n?.t("allUsers.activateUser")?.toString()
                            }
                        </Typography>
                        <IconButton
                            aria-label="close"
                            data-test-id="close_user_deactivate"
                            onClick={this.closeDeActivateUser}
                            className="deactivateUserDialogClose"
                            sx={{
                                right: this.state.selectedLanguage.toLowerCase() === "english" ? 30 : "auto",
                                left: this.state.selectedLanguage.toLowerCase() === "english" ? "auto" : 30
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Wrapper>
                </DialogTitle>
                <Divider sx={{ color: "#E2E8F0" }} />
                <DialogContent>
                    <Wrapper>
                        <Box className="deActivateUserImgBox">
                            <img src={deActivateImg.default} alt="Success Image" height={144} width={144} />
                        </Box>
                    </Wrapper>
                    <Typography
                        sx={{
                            fontSize: "20px",
                            fontWeight: 700,
                            fontFamily: "Urbanist",
                            padding: "14px 34px",
                            lineHeight: "28px",
                            color: "#0F172A",
                            textAlign: "center"
                        }}>
                        {this.state.userProfileData.data?.attributes.activated ?
                            this.props.i18n?.t("allUsers.sureDeactivate")?.toString() :
                            this.props.i18n?.t("allUsers.sureActivate")?.toString()
                        }
                    </Typography>
                    <Typography
                        sx={{
                            fontWeight: 400,
                            lineHeight: "26px",
                            fontFamily: "Urbanist",
                            padding: "0px 40px",
                            fontSize: "18px",
                            color: "#0F172A",
                            textAlign: "center"
                        }}>
                        {this.state.userProfileData.data?.attributes.activated ?
                            this.props.i18n?.t("allUsers.deactivateSubText")?.toString() :
                            this.props.i18n?.t("allUsers.activateSubText")?.toString()
                        }
                        <Box className="deActivateCheckbox">
                            <Checkbox
                                checked={this.state.checkedDeactivate}
                                onChange={this.handleCheckboxChange}
                                color="primary"
                                data-test-id="deActivateCheckbox"
                            />
                            <span>{this.props.i18n?.t("allUsers.checkBoxText")?.toString()}</span>
                            <Typography
                                sx={{
                                    marginLeft: "5px",
                                    fontSize: "0.85rem",
                                    color: "#F30202",
                                    display: "flex",
                                    justifyContent: "flex-end"
                                }}
                            >
                                {this.state.deActivateBtnClicked && !this.state.checkedDeactivate && this.props.i18n?.t("allUsers.acionRequired")?.toString()}
                            </Typography>
                        </Box>
                    </Typography>

                </DialogContent>
                <Divider />
                <DialogActions>
                    <Wrapper>
                        <Box className={`${this.state.selectedLanguage.toLowerCase() === "english" ? "deActivateButtonsBox" : "deActivateButtonsBoxArabic"}`}>
                            <Button
                                onClick={this.closeDeActivateUser}
                                variant="outlined"
                                data-test-id="termsCancel"
                                className="deActivateCancelButton"
                            >
                                {this.props.i18n?.t("Cancel")?.toString()}
                            </Button>
                            <Button
                                onClick={this.handleUserStatus}
                                variant="contained"
                                color="primary"
                                data-test-id="deactivate_user"
                                className={`${this.state.selectedLanguage.toLowerCase() === "english" ? "deactivateButton" : "deactivateButtonArabic"}`}
                            >
                                {this.state.userProfileData.data?.attributes.activated ?
                                    this.props.i18n?.t("allUsers.deactivateUser")?.toString() :
                                    this.props.i18n?.t("allUsers.activateUser")?.toString()
                                }
                            </Button>
                        </Box>
                    </Wrapper>
                </DialogActions>
            </Dialog>
        )
    }

    firstName = () => {
        return (
            <Wrapper>
                <Box className="userRightContainerBox">
                    <Box>
                        <img
                            src={userNameImg.default}
                            alt="User Profile"
                            className="userImageStyle"
                        />
                    </Box>
                    <Box className="nameMargin">
                        <Typography className="userDetailLabel">
                            {this.props.i18n?.t("contactUs.fName").toString()}
                        </Typography>
                        <Typography className="userDetailSubText">
                            {this.state.userProfileData.data?.attributes.first_name}
                        </Typography>
                    </Box>
                </Box>
            </Wrapper >
        )
    }

    lastName = () => {
        return (
            <Wrapper>
                <Box className="userRightContainerBox">
                    <Box>
                        <img
                            src={userNameImg.default}
                            alt="User Profile"
                            className="userImageStyle"
                        />
                    </Box>
                    <Box className="nameMargin">
                        <Typography className="userDetailLabel">
                            {this.props.i18n?.t("contactUs.lName").toString()}
                        </Typography>
                        <Typography className="userDetailSubText">
                            {this.state.userProfileData.data?.attributes.last_name}
                        </Typography>
                    </Box>
                </Box>
            </Wrapper>
        )
    }

    userEmail = () => {
        return (
            <Wrapper>
                <Box className="userRightContainerBox">
                    <Box>
                        <img
                            src={emailImg.default}
                            alt="User Profile"
                            className="userImageStyle"
                        />
                    </Box>
                    <Box className="nameMargin">
                        <Typography className="userDetailLabel">
                            {this.props.i18n?.t("contactUs.email").toString()}
                        </Typography>
                        <Typography className="userDetailSubText">
                            {this.state.userProfileData.data?.attributes.email}
                        </Typography>
                    </Box>
                </Box>
            </Wrapper>
        )
    }

    userContactNumber = () => {
        return (
            <Wrapper>
                <Box className="userRightContainerBox">
                    <Box>
                        <img
                            src={contactImg.default}
                            alt="User Profile"
                            className="userImageStyle"
                        />
                    </Box>
                    <Box className="nameMargin">
                        <Typography className="userDetailLabel">
                            {this.props.i18n?.t("allUsers.contact").toString()}
                        </Typography>
                        <Typography className="userDetailSubText">
                            +{this.state.userProfileData.data?.attributes.full_phone_number}
                        </Typography>
                    </Box>
                </Box>
            </Wrapper>
        )
    }

    userBirthDate = () => {
        const dateOfBirth = this.state.userProfileData?.data?.attributes?.date_of_birth;
        const formattedDate = dateOfBirth ? moment(dateOfBirth).format('DD-MM-YYYY') : '';
        return (
            <Wrapper>
                <Box className="userRightContainerBox">
                    <Box>
                        <img
                            src={birthDateImg.default}
                            alt="User Profile"
                            className="userImageStyle"
                        />
                    </Box>
                    <Box className="nameMargin">
                        <Typography className="userDetailLabel">
                            {this.props.i18n?.t("allUsers.dob").toString()}
                        </Typography>
                        <Typography className="userDetailSubText">
                            {formattedDate}
                        </Typography>
                    </Box>
                </Box>
            </Wrapper>
        )
    }

    userGender = () => {
        return (
            <Wrapper>
                <Box className="userRightContainerBox">
                    <Box>
                        <img
                            src={genderImg.default}
                            alt="User Profile"
                            className="userImageStyle"
                        />
                    </Box>
                    <Box className="nameMargin">
                        <Typography className="userDetailLabel">
                            {this.props.i18n?.t("allUsers.gender").toString()}
                        </Typography>
                        <Typography className="userDetailSubText">
                            {this.state.userProfileData.data?.attributes.gender}
                        </Typography>
                    </Box>
                </Box>
            </Wrapper>
        )
    }

    userDesignation = () => {
        return (
            <Wrapper>
                <Box className="userRightContainerBox">
                    <Box>
                        <img
                            src={userNameImg.default}
                            alt="User Profile"
                            className="userImageStyle"
                        />
                    </Box>
                    <Box className="nameMargin">
                        <Typography className="userDetailLabel">
                            {this.props.i18n?.t("allUsers.designation").toString()}
                        </Typography>
                        <Typography className="userDetailSubText">
                            {this.state.userProfileData.data?.attributes.designation}
                        </Typography>
                    </Box>
                </Box>
            </Wrapper >
        )
    }

    userDepartment = () => {
        return (
            <Wrapper>
                <Box className="userRightContainerBox">
                    <Box>
                        <img
                            src={genderImg.default}
                            alt="User Profile"
                            className="userImageStyle"
                        />
                    </Box>
                    <Box className="nameMargin">
                        <Typography className="userDetailLabel">
                            {this.props.i18n?.t("allUsers.department").toString()}
                        </Typography>
                        <Typography className="userDetailSubText">
                            {this.state.userProfileData.data?.attributes.department.attributes.name}
                        </Typography>
                    </Box>
                </Box>
            </Wrapper>
        )
    }

    userCompany = () => {
        return (
            <Wrapper>
                <Box className="userRightContainerBox">
                    <Box>
                        <img
                            src={companyImg.default}
                            alt="User Profile"
                            className="userImageStyle"
                        />
                    </Box>
                    <Box className="nameMargin">
                        <Typography className="userDetailLabel">
                            {this.props.i18n?.t("allUsers.organisation").toString()}
                        </Typography>
                        <Typography className="userDetailSubText">
                            {this.state.userProfileData.data?.attributes.company_name}
                        </Typography>
                    </Box>
                </Box>
            </Wrapper>
        )
    }

    userCompetencies = () => {
        return (
            <Wrapper>
                <Box className="userRightContainerBox">
                    <Box>
                        <img
                            src={competenciesImg.default}
                            alt="User Profile"
                            className="userImageStyle"
                        />
                    </Box>
                    <Box className="nameMargin">
                        <Typography className="userDetailLabel">
                            {this.props.i18n?.t("allUsers.competencies").toString()}
                        </Typography>
                        {this.state.userProfileData.data?.attributes.competencies.map((competency) => (
                            <Typography key={competency.id} className="userDetailSubText extraMargin">
                                {competency.name}
                            </Typography>
                        ))}
                    </Box>
                </Box>
            </Wrapper>
        )
    }

    userImageAndName = () => {
        return (
            <Wrapper>
                <Grid container spacing={2}
                    sx={{
                        justifyContent: {
                            md: "space-between",
                            xs: "center"
                        }
                    }}>
                    <Grid item xs={12} sm={3.5} style={{ textAlign: "center" }}>
                        <img
                            src={this.state.userProfileData.data?.attributes.profile_picture || defaultUserImg.default}
                            alt="Candidate Pic"
                            className="userPhoto" />
                    </Grid>
                    <Grid item xs={12} sm={7.5} className="userNameBox"
                        sx={{
                            alignItems: {
                                sm: "baseline",
                                xs: "center"
                            }
                        }}>
                        <Box>
                            <Typography className="candidateName">
                                {this.state.userProfileData.data?.attributes.first_name} {this.state.userProfileData.data?.attributes.last_name}
                            </Typography>
                            <Typography className="staffID">
                                {this.props.i18n?.t("allUsers.staffId").toString()} : {this.state.userProfileData.data?.attributes.staff_id}
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Wrapper>
        )
    }

    noAssessment = () => {
        return (
            <EmptyWrapper>
                <Grid container>
                    <Grid item xs={12}>
                        <Box className="noDataImageBox">
                            <img src={emptyDashboardImg.default} />
                            <Typography className="noDataMainText">
                                {this.props.i18n?.t("allUsers.noAssessment").toString()}
                            </Typography>
                            <Typography className="noDataNextText">
                                {this.props.i18n?.t("allUsers.noAssSubText").toString()}
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </EmptyWrapper>
        )
    }

    viewUserProfilesHeader = () => {
        return (
            <Wrapper>
                <Box className="orgItemMainBox">
                    <Grid container justifyContent="center" className="orgCardContainer" >
                        <Grid item xs={12} className="companyCardItem">
                            <Card className="userProfileDescBox">
                                <CardContent
                                    className="cardContentPadding"
                                    sx={{
                                        direction: this.state.selectedLanguage.toLowerCase() === "english" ? "ltr" : "rtl",
                                    }}
                                >
                                    <Grid container>
                                        <Grid item xs={12} className="orgDetailPadding">
                                            <Typography className="cardTitle">
                                                {this.props.i18n?.t("allUsers.candidate").toString()}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} className="dividerPadding">
                                            <Divider />
                                        </Grid>
                                        <Grid item xs={12} className="orgDetailPadding">
                                            <Grid container justifyContent={"space-between"}>
                                                <Grid item xs={12} lg={4.5} className="userDetailGrid">
                                                    {this.userImageAndName()}
                                                </Grid>

                                                <Grid item xs={12} lg={7.2} className="userDetailGrid">
                                                    <Grid container spacing={3.5}>
                                                        <Grid item xs={12}>
                                                            <Typography className="aboutUSerText">
                                                                {this.props.i18n?.t("allUsers.aboutCandidate").toString()}
                                                            </Typography>
                                                            <Divider />
                                                        </Grid>

                                                        <Grid item xs={12} md={6}>
                                                            {this.firstName()}
                                                        </Grid>
                                                        <Grid item xs={12} md={6}>
                                                            {this.lastName()}
                                                        </Grid>
                                                        <Grid item xs={12} md={6}>
                                                            {this.userEmail()}
                                                        </Grid>
                                                        <Grid item xs={12} md={6}>
                                                            {this.userContactNumber()}
                                                        </Grid>
                                                        <Grid item xs={12} md={6}>
                                                            {this.userBirthDate()}
                                                        </Grid>
                                                        <Grid item xs={12} md={6}>
                                                            {this.userGender()}
                                                        </Grid>
                                                        <Grid item xs={12} md={6}>
                                                            {this.userDesignation()}
                                                        </Grid>
                                                        <Grid item xs={12} md={6}>
                                                            {this.userDepartment()}
                                                        </Grid>
                                                        <Grid item xs={12} md={6}>
                                                            {this.userCompany()}
                                                        </Grid>
                                                        <Grid item xs={12} md={6}>
                                                            {this.userCompetencies()}
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    {this.noAssessment()}
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </Box>
            </Wrapper >
        )
    }

    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <DashboardHeader selectedMenu="All users" id={this.props.id} navigation={this.props.navigation}>
                <MainWrapper>
                    <Grid container justifyContent={"center"}>
                        <Grid item xs={11}>
                            <Grid container
                                alignItems={"center"}
                                spacing={1}
                                sx={{
                                    direction: this.state.selectedLanguage.toLowerCase() === "english" ? "" : "rtl"
                                }}
                            >
                                <Grid item xs={1.5} sm={1} md={0.8} lg={0.5}>
                                    <img src={backArrow.default}
                                        alt="Back Arrow"
                                        className={`${this.state.selectedLanguage.toLowerCase() === "english" ? "backArrowImg" : "backArrowImgAr"}`}
                                        data-test-id="navigate_to_create_user"
                                        onClick={this.navigateToCreateUser}
                                    />
                                </Grid>
                                <Grid item xs={10.5} sm={5} md={5.2} lg={5.5} alignItems={"center"}>
                                    <Typography
                                        className={`${this.state.selectedLanguage.toLowerCase() === "english" ? "userTitle" : "userTitleAr"}`}
                                    >
                                        {this.state.userProfileData.data?.attributes.first_name} {this.state.userProfileData.data?.attributes.last_name}{this.props.i18n?.t("allUsers.sProfile")?.toString()}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={6} md={6} lg={6}>
                                    <Grid container
                                        spacing={2}
                                        justifyContent={"ceneter"}
                                        sx={{
                                            justifyContent: this.state.selectedLanguage.toLowerCase() === "english" ? "flex-start" : "flex-end"
                                        }}
                                    >
                                        <Grid item xs={12} sm={6} md={6} lg={4.5} className="editMainBox">
                                            <Button className="deactivateButton" data-test-id="deactivate_user_dialog"
                                                onClick={this.deactivateUserDialogOpen}>
                                                <Typography className="deactivateText">
                                                    {this.state.userProfileData.data?.attributes.activated ?
                                                        this.props.i18n?.t("allUsers.deactivateUser")?.toString() :
                                                        this.props.i18n?.t("allUsers.activateUser")?.toString()
                                                    }
                                                </Typography>
                                            </Button>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={6} lg={4.5} className="editMainBox">
                                            <Button className="editProfileButton" data-test-id="edit_profile" onClick={(id) => this.navigateToEditUser(this.state.userProfileData.data?.id || "")}>
                                                <Typography className="editProfileText">
                                                    {this.props.i18n?.t("allUsers.editProfile")?.toString()}
                                                </Typography>
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>

                            </Grid>
                        </Grid>
                        {!this.state.loader &&
                            <Grid item xs={12} sm={12} md={11.2} lg={11.5}>
                                {this.viewUserProfilesHeader()}
                            </Grid>
                        }
                    </Grid>
                    {this.deActivateUserDialog()}
                </MainWrapper >
                {this.state.loader && (
                    <Box
                        sx={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "30px" }}>
                        <Box>
                            <CircularProgress />
                        </Box>
                    </Box>
                )}
            </DashboardHeader >
            // Customizable Area End
        );
    }
}

// Customizable Area Start
export const ViewUserProfileWeb = withTranslation()(ViewUserProfile)
export const Wrapper = styled(Box)({
    width: "100%",
    "& .dividerPadding": {
        padding: "0px 56px 0px 35px"
    },
    "& .userRightContainerBox": {
        display: "flex"
    },
    "& .nameMargin": {
        marginLeft: "5px"
    },
    "& .deactivateUserDialogTitle": {
        fontFamily: "Urbanist",
        color: "#1E1E1E",
        fontSize: "24px",
        lineHeight: "32px",
        fontWeight: 700,
        margin: "8px 16px"
    },
    "& .userDetailLabel": {
        fontFamily: "Urbanist",
        fontSize: "15px",
        fontWeight: 600,
        lineHeight: "18px",
        color: "#0C2755",
        marginBottom: "8px"
    },
    "& .deActivateButtonsBox": {
        margin: "24px 0px",
        paddingRight: "16px",
        display: "flex",
        justifyContent: "flex-end"
    },
    "& .deactivateButton": {
        padding: "16px 19px 16px 19px",
        fontSize: "16px",
        fontWeight: 700,
        height: "56px",
        fontFamily: "Urbanist",
        gap: "8px",
        borderRadius: "8px",
        opacity: "0px",
        marginLeft: "16px",
        background: "#044352",
        textTransform: "none",
        "&:hover": {
            backgroundColor: "#044352",
        },
    },
    "& .deactivateButtonArabic": {
        height: "56px",
        fontSize: "16px",
        fontWeight: 700,
        fontFamily: "Urbanist",
        padding: "16px 39.5px 16px 36.5px",
        gap: "8px",
        borderRadius: "8px",
        opacity: "0px",
        marginRight: "16px",
        background: "#044352",
        textTransform: "none",
        "&:hover": {
            backgroundColor: "#044352",
        },
    },
    "& .deActivateButtonsBoxArabic": {
        display: "flex",
        justifyContent: "flex-end",
        paddingLeft: "16px",
        margin: "24px 0px",
    },
    "& .deActivateUserImgBox": {
        justifyContent: "center",
        display: "flex",
        padding: "10px 0px",
    },
    "& .deActivateCheckbox": {
        color: "#0F172A",
        fontFamily: "Urbanist",
        paddingTop: "12px",
        fontSize: "18px",
        fontWeight: 400,
        lineHeight: "26px",
        marginTop: "8px"
    },
    "& .deActivateCancelButton": {
        borderColor: "#F1F5F9",
        padding: "16px 37.5px 16px 32.5px",
        width: "120px",
        height: "56px",
        color: "#044352",
        fontFamily: "Urbanist",
        fontSize: "16px",
        fontWeight: 700,
        borderRadius: "8px",
        gap: "8px",
        opacity: "0px",
        background: "#F1F5F9",
        boxShadow: "0px 4px 4px 0px #00000040",
        textTransform: "none",
        "&:hover": {
            backgroundColor: "#F1F5F9",
            border: "1px solid #F1F5F9"
        },
    },
    "& .extraMargin": {
        marginBottom: "8px"
    },
    "& .deactivateUserDialogClose": {
        position: 'absolute',
        top: 16,
        color: "#334155"
    },
    "& .userDetailSubText": {
        fontFamily: "Urbanist",
        fontSize: "13px",
        fontWeight: 400,
        lineHeight: "18px",
        color: "#0C2755B2"
    },
    "& .userNameBox": {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
    },
    "& .userImageStyle": {
        height: "20px",
        width: "20px",
        marginTop: "2px"
    },
    "& .userProfileDescBox": {
        margin: "60px 5vw",
        boxShadow: "rgba(0, 0, 0, 0.1) 0px 14px 144px 0px",
        width: "65vw",
        "@media(max-width:900px)": {
            width: "80vw"
        },
    },
    "& .userPhoto": {
        height: "7vw",
        width: "7vw",
        border: "4px solid lightgray",
        borderRadius: "50%",
        maxWidth: "130px",
        maxHeight: "130px",
        minWidth: "80px",
        minHeight: "80px"
    },
    "& .candidateName": {
        fontFamily: "Urbanist",
        fontSize: "20px",
        fontWeight: 600,
        lineHeight: "20px",
        color: "#2C3E50",
        marginLeft: "10px"
    },
    "& .staffID": {
        fontFamily: "Urbanist",
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "20px",
        color: "#5E5873",
        marginLeft: "10px",
        marginTop: "6px"
    },
    "& .orgDetailPadding": {
        padding: "30px 56px 30px 35px"
    },
    "& .orgItemMainBox": {
        width: "100%",
        overflow: "hidden",
        display: "flex",
        justifyContent: "center",
    },
    "& .companyCardItem": {
        flex: "0 0 auto",
        scrollSnapAlign: "center",
    },
    "& .cardContentPadding": {
        padding: "0px 0px 42px 0px"
    },
    "& .contentPadding": {
        padding: "30px 56px 0px 35px"
    },
    "& .cardTitle": {
        fontFamily: "Urbanist",
        fontSize: "20px",
        fontWeight: 600,
        lineHeight: "24px",
        color: "#0444352"
    },
    "& .userDetailGrid": {
        border: "1px solid #E2E2E5",
        padding: "20px",
        margin: "10px 0px",
        borderRadius: "5px",
        height: "100%"
    },
    "& .aboutUSerText": {
        fontFamily: "Urbanist",
        fontSize: "18px",
        fontWeight: 600,
        lineHeight: "22px",
        paddingBottom: "20px",
        color: "#000104"
    },
    "& .orgCardContainer": {
        width: "100%",
        overflowX: "auto",
        scrollSnapType: "x mandatory",
        scrollBehavior: "smooth",
        padding: "0px 8px",
        scrollbarWidth: "none",
        "&::-webkit-scrollbar": {
            display: "none",
        },
    }
})
export const MainWrapper = styled(Box)({
    width: "100%",
    marginTop: "42px",
    paddingBottom: "30px",
    display: "flex",
    justifyContent: "center",
    "& .editMainBox": {
        display: "flex",
        justifyContent: "flex-end",
        "@media(max-width:600px)": {
            justifyContent: "flex-start",
            marginTop: "10px"
        }
    },
    "& .editProfileButton": {
        padding: "10px 25px",
        borderRadius: "4px",
        height: "58px",
        width: "100%",
        border: "1px solid #94A3B8",
        background: "#044352",
        "@media(max-width:599px)": {
            width: "60%"
        }
    },
    "& .deactivateButton": {
        padding: "10px 25px",
        borderRadius: "4px",
        height: "58px",
        width: "100%",
        border: "2px solid #94A3B8",
        "&:hover": {
            background: "none"
        },
        "@media(max-width:599px)": {
            width: "60%"
        }
    },
    "& .deactivateText": {
        fontWeight: 700,
        lineHeight: "24px",
        fontFamily: "Urbanist",
        fontSize: "16px",
        color: "#044352",
        textTransform: "none"
    },
    "& .backArrowImg": {
        paddingRight: "12px"
    },
    "& .backArrowImgAr": {
        transform: "rotate(180deg)"
    },
    "& .editProfileText": {
        fontWeight: 700,
        lineHeight: "24px",
        fontFamily: "Urbanist",
        fontSize: "16px",
        color: "#FFFFFF",
        textTransform: "none"
    },
    "& .userTitle": {
        fontSize: "25px",
        lineHeight: "30px",
        fontWeight: 600,
        fontFamily: "Urbanist",
        color: "#000104",
        marginLeft: "12px"
    },
    "& .userTitleAr": {
        fontSize: "25px",
        lineHeight: "30px",
        fontWeight: 600,
        fontFamily: "Urbanist",
        color: "#000104",
        marginRight: "14px"
    },
})
export const EmptyWrapper = styled(Box)({
    width: "100%",
    "& .noDataNextText": {
        width: "40%",
        fontWeight: 400,
        color: "#8A96A8",
        fontFamily: "Urbanist",
        fontSize: "18px",
        lineHeight: "26px",
        textAlign: "center"
    },
    "& .addNewUserText": {
        fontWeight: 700,
        fontFamily: "Urbanist",
        fontSize: "16px",
        lineHeight: "24px",
        color: "#FFFFFF",
        textTransform: "none"
    },
    "& .noDataMainText": {
        color: "#000104",
        fontFamily: "Urbanist",
        fontWeight: 700,
        fontSize: "30px",
        lineHeight: "40px",
        padding: "0px 20px",
        textAlign: "center"
    },
    "& .noDataImageBox": {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
    },
})

// Customizable Area End