import React from "react";

// Customizable Area Start
import {
    Box,
    Grid,
    Typography,
    Button,
    CircularProgress,
    InputAdornment,
    IconButton,
    TextField,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Pagination,
    Modal
} from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { emptyDashboardImg, calendar, CheckIcon, UserIcon, DeleteIcon } from "./assets";
import { DashboardHeaderWeb as DashboardHeader } from "../../dashboard/src/DashboardHeader.web";

import Calendar from "react-calendar";
import { withTranslation } from "react-i18next";
import AssessmentTestDashbordController, {
    Props,
} from "./AssessmentTestDashbordController.web";
import { styled } from "@mui/system";
import CustomPopup from "../../../../packages/components/src/CustomSuccessModel.web";
import { setStorageData } from "../../../../packages/framework/src/Utilities";
// Customizable Area End

export default class AssessmentTestDashbord extends AssessmentTestDashbordController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start

    noDataScreen = (ButtonVisible: boolean) => {
        return (
            <AssessmentEmptyWrapper>
                <Grid container className="gridContainer">
                    <Grid item xs={12}>
                        <Box className="noDataImageBox">
                            <img src={emptyDashboardImg.default} />
                            <Typography className="noDataMainText">
                                {this.TraslationFuncation("NoData")}
                            </Typography>
                            <Typography className="noDataNextText">
                                It looks like there’s nothing here yet. Add your <b>Assessments</b> for greater efficiency and growth.
                            </Typography>
                            {ButtonVisible &&
                                <Button className="addNewUserButton"
                                    data-test-id="AddAssessmentsWithOutData"
                                    onClick={() => {
                                        setStorageData("GenerateAssessmentBack", "AssessmentTestDashbord")
                                        this.NaviGateToAnyPage("GenerateAssessment")
                                    }}
                                >
                                    <Typography className="addNewUserText">
                                        Add Assessments
                                    </Typography>
                                </Button>
                            }
                        </Box>
                    </Grid>
                </Grid>
            </AssessmentEmptyWrapper>
        )
    }

    DeleteModel = () => {
        return (
            <Modal
                open={this.state.DeleteQuestionModel}
                data-test-id="DeleteAssessmentMainModel"
                onClose={() => { this.CloseDeleteModel() }}>
                <CustomPopup
                    data-test-id="DeleteAssessmentModel"
                    headingPopup={`Delete Assessment`}
                    titlePopup={`Are you sure you want to delete this Assessment?`}
                    subTitlePopup={`${this.TraslationFuncation("SettingsPage.YouWontBeAbleToAccessItAgain")}`}
                    cancelButtonText={this.TraslationFuncation("Cancel")}
                    buttonText={`${this.TraslationFuncation("Delete")}`}
                    successIcon={DeleteIcon.default}
                    showCancelButton={true}
                    onOpen={() => {
                        this.DeleteQuestionApiCall()
                    }}
                    onClose={() => {
                        this.CloseDeleteModel()
                    }}
                />
            </Modal>
        )
    }

    TableDataScreen = () => {
        return (
            <Grid container justifyContent={"center"}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <HeaderContainer>
                        {this.DeleteModel()}
                        <Grid container spacing={2} alignItems={"center"}>
                            <Grid item xs={12} sm={12} md={4} lg={4}>
                                <Typography variant="h5" className="HeadingOne">
                                    Key Metrics for Acements
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} md={8} lg={8}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12} sm={12} md={4} lg={4}>
                                        <CustomDatePicker sx={{ flex: 1 }}>
                                            <TextField
                                                fullWidth
                                                value={this.formatDateRange(this.state.selectedDate) || this.TraslationFuncation("CompetencyDashboard.SelectStartDateEndDate")}
                                                onClick={this.openCalendar}
                                                data-test-id="calendar_field"
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                onClick={this.openCalendar}
                                                            >
                                                                <img src={calendar.default}
                                                                    onClick={this.openCalendar}
                                                                />
                                                            </IconButton>
                                                        </InputAdornment>
                                                    ),
                                                    inputProps: {
                                                        sx: {
                                                            color: this.handleCondition(this.state.selectedDate === null, '#334155', '#6C7B89'),
                                                            fontFamily: "Urbanist",
                                                            fontWeight: this.handleCondition(this.state.selectedDate === null, 700, 400),
                                                            fontSize: "16px",
                                                            paddingRight: '0px',
                                                            paddingLeft: "8px"
                                                        },
                                                    },
                                                    sx: { justifyContent: 'center', },
                                                }}
                                                InputLabelProps={{ sx: { textAlign: 'center', width: '100%' }, }}
                                            />
                                            {this.state.calendarOpen && (
                                                <div
                                                    className={`${this.handleCondition(this.state.selectedLanguage.toLowerCase() === "english", " reactCalendarBox ", " reactCalendarBoxArabic ")}`}
                                                >
                                                    <Calendar
                                                        selectRange
                                                        onChange={(e) => {
                                                            this.handleDateChange(e as [Date, Date]);
                                                        }}
                                                        data-test-id="calendar"
                                                        value={this.state.tempDate || this.state.selectedDate}
                                                    />
                                                    <div className="action-button-div">
                                                        <Button
                                                            className="action-btn cancel-btn"
                                                            onClick={this.handleCalendarCancelBtn}
                                                            data-test-id="cancel_calendar_btn"
                                                        >
                                                            {this.TraslationFuncation("CompetencyDashboard.Cancel")}
                                                        </Button>
                                                        <Button
                                                            data-test-id="calender_save_btn"
                                                            className={this.handleCondition(this.state.tempDate === null, `action-btn save-btn active`, `action-btn save-btn`) as string}
                                                            onClick={this.handleCalendarSaveBtn}
                                                        >
                                                            {this.TraslationFuncation("CompetencyDashboard.Apply")}
                                                        </Button>
                                                    </div>
                                                </div>
                                            )}
                                        </CustomDatePicker>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={4} lg={4}>
                                        <Box>
                                            <Button
                                                data-test-id="ViewScoreCard"
                                                className="OutlinedButton"
                                                variant="outlined"
                                                fullWidth
                                                onClick={() => { this.NaviGateToAnyPage("ViewScoreCard") }}
                                            >
                                                <Typography className="ButtonText">
                                                    View Score Card
                                                </Typography>
                                            </Button>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={4} lg={4}>
                                        <Box>
                                            <Button
                                                className="OutlinedButton"
                                                variant="outlined"
                                                fullWidth
                                                data-test-id="AddAssessmentsWithData"
                                                onClick={() => {
                                                    setStorageData("GenerateAssessmentBack", "AssessmentTestDashbord")

                                                    this.NaviGateToAnyPage("GenerateAssessment")
                                                }}
                                            >
                                                <Typography className="ButtonText">
                                                    Add Assessments
                                                </Typography>
                                            </Button>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <Box className="GreyContainer">
                                    <Box>
                                        <img src={UserIcon.default} className="GreyMainIcon" alt="" />
                                    </Box>
                                    <Box className="GreyTextContainer">
                                        <Box>
                                            <Typography className="GreyContainerheading">
                                                Total Assessments
                                            </Typography>
                                        </Box>
                                        <Box>
                                            <Typography className="GreyContainerheading">
                                                {this.state.TotalAssessments}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <Box className="GreyContainer">
                                    <Box>
                                        <img src={CheckIcon.default} className="GreyMainIcon" alt="" />
                                    </Box>
                                    <Box className="GreyTextContainer">
                                        <Box>
                                            <Typography className="GreyContainerheading">
                                                Pending Assessments
                                            </Typography>
                                        </Box>
                                        <Box>
                                            <Typography className="GreyContainerheading">
                                                {this.state.PendingAssessments}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </HeaderContainer>
                </Grid>
                <Grid style={{ marginTop: "20px" }} item xs={12} sm={12} md={12} lg={12}>
                    <HeaderContainer>
                        <Grid container spacing={2} alignItems={"center"}>
                            <Grid item xs={12} sm={12} md={4} lg={4}>
                                <Typography variant="h5" className="HeadingOne">
                                    Assessments
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} md={8} lg={8}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12} sm={12} md={4} lg={4}>
                                        <TextField
                                            className="searchBox"
                                            data-test-id="searchTextBox"
                                            fullWidth
                                            value={this.state.searchText}
                                            placeholder={this.TraslationFuncation("CompetencyDashboard.Search")}
                                            onChange={(e) => {
                                                this.setState({
                                                    searchText: e.target.value,
                                                })
                                            }}
                                            onKeyUp={this.handleKeyPress}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton data-test-id="search_icon"
                                                            onClick={() => this.AllSearchAndFilterApiCall()}
                                                        >
                                                            <SearchIcon style={{ color: "#0F172A" }} />
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={4} lg={4}>
                                        <Box>
                                            <Button
                                                data-test-id="ExportAllButton"
                                                className="OutlinedButton"
                                                variant="outlined"
                                                fullWidth
                                                onClick={() => this.AllSearchAndFilterApiCall(true)}
                                            >
                                                <Typography className="ButtonText">
                                                    Export All
                                                </Typography>
                                            </Button>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={4} lg={4}>
                                        <Box>
                                            <Button
                                                data-test-id="FilterAssessmentDropDown"
                                                className="OutlinedButton"
                                                variant="outlined"
                                                fullWidth
                                                onClick={() => {
                                                    this.setState({
                                                        FilterAssessmentDropDown: !this.state.FilterAssessmentDropDown
                                                    })
                                                }}
                                                endIcon={<KeyboardArrowDownIcon style={{ color: "#0F172A" }} />}
                                            >
                                                <Typography className="ButtonText">
                                                    {this.state.CurrentFilterStatus}
                                                </Typography>
                                            </Button>
                                            {
                                                this.state.FilterAssessmentDropDown &&
                                                <div style={{ position: "relative", }}>
                                                    <div className="PopUpMenu" style={{
                                                        top: "4px",
                                                        width: "100%",
                                                    }}>
                                                        {
                                                            this.RenderAllTabs().map((item, index) => {
                                                                return (
                                                                    <Button data-test-id={"FilterAssessmentDropDownOption" + index} className="MoreVertIconButton" onClick={() => {
                                                                        this.SetFilterApiCall(item)
                                                                    }}>
                                                                        <Typography>
                                                                            {item.name}
                                                                        </Typography>
                                                                    </Button>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            }
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <TableContainer sx={{ mt: 2 }}>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell className="TableTilteText">
                                                    <Box className="tableCellBox">
                                                        Assessment Name
                                                    </Box>
                                                </TableCell>
                                                <TableCell className="TableTilteText">
                                                    <Box className="tableCellBox">
                                                        Job Role
                                                    </Box>
                                                </TableCell>
                                                <TableCell className="TableTilteText">
                                                    <Box className="tableCellBox">
                                                        Competency
                                                    </Box>
                                                </TableCell>
                                                <TableCell className="TableTilteText">
                                                    <Box className="tableCellBox">
                                                        Difficulty Level
                                                    </Box>
                                                </TableCell>
                                                <TableCell className="TableTilteText">
                                                    Questions Count
                                                </TableCell>
                                                <TableCell className="TableTilteText">
                                                    Focus Areas
                                                </TableCell>
                                                <TableCell className="TableTilteText">
                                                    Status
                                                </TableCell>
                                                <TableCell
                                                    data-test-id="delete_selected"
                                                    className="TableTilteText actionStyle">
                                                    Action
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {this.state.AssessmentsTableData.length !== 0 ? this.state.AssessmentsTableData.map((item, index) => {
                                                return (<TableRow>
                                                    <TableCell className="TableTilteText">
                                                        <Box className="tableCellBox">
                                                            {item.AssessmentTitle}
                                                        </Box>
                                                    </TableCell>
                                                    <TableCell className="TableTilteText">
                                                        <Box className="tableCellBox">
                                                            {item.JobRole}
                                                        </Box>
                                                    </TableCell>
                                                    <TableCell className="TableTilteText">
                                                        <Box className="tableCellBox">
                                                            {item.Competency}
                                                        </Box>
                                                    </TableCell>
                                                    <TableCell className="TableTilteText">
                                                        <Box className="tableCellBox">
                                                            {item.DifficultyLevels}
                                                        </Box>
                                                    </TableCell>
                                                    <TableCell className="TableTilteText">
                                                        <Box className="tableCellBox">
                                                            {item.QuestionsCount}
                                                        </Box>
                                                    </TableCell>
                                                    <TableCell className="TableTilteText">
                                                        <Box className="tableCellBox">
                                                            {item.FocusAreas}
                                                        </Box>
                                                    </TableCell>
                                                    <TableCell className="TableTilteText">
                                                        <Box className="tableCellBox" style={{  textTransform: "capitalize" }}>
                                                            {item.Status}
                                                        </Box>
                                                    </TableCell>
                                                    <TableCell className="TableTilteText">
                                                        <Box className="tableCellBox" style={{ textAlign: "center" }}>
                                                            <IconButton onClick={() => {
                                                                this.setState({
                                                                    OpenIndex: this.state.OpenIndex === index ? -1 : index,
                                                                })
                                                            }} data-test-id="DotsData">
                                                                <MoreVertIcon />
                                                            </IconButton>
                                                            {this.state.OpenIndex === index && (
                                                                <div className="PopUpMenu" style={{

                                                                    right: this.handleCondition(this.state.selectedLanguage.toLowerCase() === "english", "20px", "") as string,
                                                                    left: this.handleCondition(this.state.selectedLanguage.toLowerCase() === "english", "", "20px") as string,
                                                                }}>
                                                                    <Button className="MoreVertIconButton" onClick={() => {
                                                                        this.NaviGateToEditAndViewPage("ViewAssessment", item)
                                                                    }}>
                                                                        <Typography>
                                                                            View assessment
                                                                        </Typography>
                                                                    </Button>
                                                                    <Button onClick={() => {
                                                                        this.NaviGateToEditAndViewPage("EditAssessment", item)
                                                                    }} className="MoreVertIconButton">
                                                                        <Typography>
                                                                            Edit details
                                                                        </Typography>
                                                                    </Button>
                                                                    <Button onClick={() => {
                                                                        this.setState({
                                                                            AssessmentId: item.ID,
                                                                            DeleteQuestionModel: true,
                                                                            OpenIndex: -1
                                                                        })
                                                                    }}
                                                                        data-test-id="deleteAssessment"
                                                                        className="MoreVertIconButton">
                                                                        <Typography className="DeleteColor">
                                                                            Delete details
                                                                        </Typography>
                                                                    </Button>
                                                                </div>
                                                            )}
                                                        </Box>
                                                    </TableCell>
                                                </TableRow>)
                                            }) : <TableRow>
                                                <TableCell colSpan={8}>
                                                    {this.noDataScreen(true)}
                                                </TableCell>
                                            </TableRow>}
                                        </TableBody>
                                    </Table>
                                    {
                                        this.state.AssessmentsTableData.length !== 0 &&
                                        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2, width: "100%" }}>
                                            <Pagination
                                                color="primary"
                                                page={this.state.page}
                                                boundaryCount={1}
                                                siblingCount={0}
                                                style={{
                                                    direction: "ltr"
                                                }}
                                                data-test-id="pagination"
                                                onChange={this.PaginationFuncation}
                                                count={Math.ceil(this.state.TotalRecord / this.state.perPage)}
                                            />
                                        </Box>
                                    }
                                </TableContainer>
                            </Grid>
                        </Grid>
                    </HeaderContainer>
                </Grid>
            </Grid>
        )
    }
    RenderAllScreens = () => {
        if (this.state.isLoading) {
            return <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80vh' }}>
                <CircularProgress style={{ color: "#044352" }} />
            </Box>
        } else {
            return this.TableDataScreen()
        }

    }
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <DashboardHeader selectedMenu="Assessments" navigation={this.props.navigation} id={this.props.id}>
                <AssessmentTestDashbordStyle>
                    {this.RenderAllScreens()}
                </AssessmentTestDashbordStyle>
            </DashboardHeader>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
export const AssessmentTestDashbordWeb = withTranslation()(AssessmentTestDashbord)

export const AssessmentTestDashbordStyle = styled(Box)({
    display: "flex",
    justifyContent: "center",
    marginTop: "42px",
    padding: "0px 30px",
})


export const HeaderContainer = styled(Box)({
    boxShadow: "rgba(0, 0, 0, 0.1) 0px 1px 144px 0px",
    padding: "20px 20px",
    borderBottom: '1px solid #3333331A',
    "& .PopUpMenu": {
        position: 'absolute',
        backgroundColor: 'white',
        border: '1px solid #ccc',
        borderRadius: '4px',
        display: 'flex',
        flexDirection: 'column',
        zIndex: 1000,
    },
    "& .TableTilteText": {
        fontFamily: 'Urbanist',
        fontSize: '14px',
        fontWeight: 700
    },
    "& .HeadingOne": {
        fontFamily: 'Urbanist',
        fontSize: '20px',
        fontWeight: 700,
        lineHeight: '28px',
        textAlign: 'left',
    },
    "& .OutlinedButton": {
        backgroundColor: "#FFFFFF",
        borderColor: "#94A3B8",
        height: "58px",
        padding: "10px 16px",
        borderRadius: "4px",
        textTransform: "none",
        fontWeight: 500,
        "&:hover": {
            backgroundColor: "#FFFFFF",
            borderColor: "#94A3B8",
        },
        "& .MuiTypography-root": {
            fontFamily: "Urbanist",
            fontSize: "16px",
            fontWeight: 700,
            lineHeight: "28px",
            color: "#044352",
        }
    },
    "& .ContainedButton": {
        backgroundColor: "#044352",
        borderColor: "#94A3B8",
        height: "58px",
        padding: "10px 16px",
        borderRadius: "4px",
        textTransform: "none",
        fontWeight: 500,
        "&:hover": {
            backgroundColor: "#044352",
            borderColor: "#94A3B8",
        },
        "& .MuiTypography-root": {
            fontFamily: "Urbanist",
            fontSize: "16px",
            fontWeight: 700,
            lineHeight: "28px",
            color: "#FFFFFF",
        }
    },
    "& .GreyContainer": {
        backgroundColor: "#F1F5F9",
        padding: "10px",
        borderRadius: "8px",
    },
    "& .GreyContainerheading": {
        fontFamily: 'Urbanist',
        fontSize: '14px',
        fontWeight: 700,
    },
    "& .GreyTextContainer": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginTop: "10px",
    },
    "& .GreyMainIcon": {
        height: "30px"
    },
    "& .DeleteColor": {
        color: "red !important",
    },
    "& .MoreVertIconButton": {
        fontFamily: "Urbanist",
        fontSize: "14px",
        justifyContent: "flex-start",
        fontWeight: 400,
        padding: '15px 30px 15px 30px',
        cursor: 'pointer',
        "&:hover": {
            backgroundColor: "#E2E8F0",
            borderRadius: "8px",
        },
        "& .MuiTypography-root": {
            fontFamily: 'Urbanist',
            fontWeight: 400,
            textAlign: "left",
            fontSize: '14px',
            lineHeight: '22px',
            color: "#0F172A",
            textTransform: "none",
        }
    }
})

export const CustomDatePicker = styled(Box)({
    borderRadius: '8px',
    position: 'relative' as 'relative',
    boxSizing: "border-box",
    padding: 0,
    border: "1px solid #94A3B8",
    "&:hover": {
        border: "1px solid #94A3B8"
    },
    "& .MuiInput-underline::after, .MuiInput-underline::before": {
        display: 'none'
    },
    "& .reactCalendarBox": {
        marginTop: "5px",
        boxShadow: "0px 8px 32px 0px #0000000F, 0px 4px 8px 0px #00000008",
        position: 'absolute',
        background: "white",
        top: '100%',
        right: 0,
        zIndex: 1000,
        direction: "ltr",
    },
    "& .reactCalendarBoxArabic": {
        marginTop: "5px",
        boxShadow: "0px 8px 32px 0px #0000000F, 0px 4px 8px 0px #00000008",
        position: 'absolute',
        background: "white",
        top: '100%',
        zIndex: 1000,
        right: 0,
        direction: "ltr",
    },
    "& .react-calendar": {
        border: "none",
        fontFamily: "Urbanist",
    },
    "& .react-calendar__navigation button.react-calendar__navigation__label": {
        fontSize: '14px',
        fontFamily: "Urbanist",
        fontWeight: 700,
        color: "#0F172A",
    },
    "& .react-calendar__navigation__prev2-button, .react-calendar__navigation__next2-button": {
        display: "none",
    },
    "& .react-calendar__navigation button": {
        fontSize: '14px',
        fontFamily: "Urbanist",
        fontWeight: 700,
        color: "#94A3B8",
    },
    "& .react-calendar__tile.react-calendar__tile--now": {
        background: 'none',
        color: '#0F172A',
    },
    "& .react-calendar__month-view__weekdays__weekday": {
        textTransform: 'capitalize',
        fontSize: '14px',
        color: '#64748B',
        fontWeight: 400,
        textDecoration: 'none',
    },
    "& .react-calendar__month-view__weekdays__weekday abbr": {
        textDecoration: 'none',
    },
    "& .react-calendar__tile": {
        fontSize: '14px',
        fontWeight: 400,
        color: '#0F172A',
        width: '50px',
        height: '50px',
    },
    "& .react-calendar__tile.react-calendar__tile--range": {
        background: '#F1F5F9',
        color: '#044352',
        borderRadius: '0px',
    },
    "& .react-calendar__tile.react-calendar__tile--rangeStart": {
        borderTopLeftRadius: '50%',
        borderBottomLeftRadius: '50%',
        background: '#F1F5F9',
        color: '#044352',
    },
    "& .react-calendar__tile.react-calendar__tile--rangeEnd": {
        borderTopRightRadius: '50%',
        borderBottomRightRadius: '50%',
        background: '#F1F5F9',
        color: '#044352',
    },
    "& .react-calendar__tile.react-calendar__tile--active, .react-calendar__tile.react-calendar button:enabled": {
        background: '#F1F5F9',
        color: '#044352',
        fontSize: '14px',
        fontWeight: 700,
    },
    "& .react-calendar__tile.react-calendar__tile--active:hover, .react-calendar__tile.react-calendar button:enabled:hover": {
        background: '#F1F5F9',
        color: '#044352',
        pointerEvents: "none",
    },
    "& .react-calendar__tile.react-calendar__month-view__days__day--neighboringMonth": {
        color: '#94A3B8'
    },
    "& .action-button-div": {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '15px 15px',
        gap: '20px'
    },
    "& .action-button-div button": {
        width: '140px',
        height: '50px',
        borderRadius: '7.21px',
        fontFamily: "Urbanist",
        textTransform: 'capitalize',
        fontSize: '14.42px',
    },
    "& .action-button-div .cancel-btn": {
        background: '#F1F5F9',
        color: "#044352",
        boxShadow: "0px 3.6px 3.6px 0px #00000040"
    },
    "& .action-button-div .save-btn.active": {
        background: '#044352',
        color: '#FFFFFF'
    },
    "& .action-button-div .save-btn": {
        background: '#044352',
        color: '#FFFFFF'
    },
});

export const AssessmentEmptyWrapper = styled(Box)({
    width: "100%",
    paddingBottom: "30px",
    height: "calc(100vh - 180px)",
    marginTop: "20px",
    "& .addNewUserButton": {
        padding: "8px 60px",
        marginTop: "24px",
        height: "58px",
        borderRadius: "4px",
        background: "#044352",
    },
    "& .noDataImageBox": {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center"
    },
    "& .noDataNextText": {
        color: "#8A96A8",
        width: "40%",
        fontWeight: 400,
        fontFamily: "Urbanist",
        fontSize: "18px",
        lineHeight: "26px",
        textAlign: "center"
    },
    "& .gridContainer": {
        height: "100%"
    },
    "& .addNewUserText": {
        fontWeight: 700,
        fontFamily: "Urbanist",
        fontSize: "16px",
        lineHeight: "24px",
        color: "#FFFFFF",
        textTransform: "none"
    },
    "& .noDataMainText": {
        color: "#000104",
        fontFamily: "Urbanist",
        fontWeight: 700,
        fontSize: "30px",
        lineHeight: "40px",
    },
})


// Customizable Area End