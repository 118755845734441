import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { getStorageData, setStorageData } from "framework/src/Utilities";
import { i18n } from "i18next";

interface ShowDicResponse {
    message: string;
    error: string;
    competency_dictionary: ShowDictionaryData;
}

interface DeleteDictionary {
    message?: string;
    error?: string;
}

interface ShowDictionaryData {
    data?: {
        id: string;
        type: string;
        attributes: {
            id: string;
            competency_name: string;
            description: string;
            created_at: string;
            job_level: {
                id: string;
                name: string;
            },
            competency_type: {
                id: string;
                name: string;
            }
        }
    }
}
// Customizable Area End

export const configJSON = require("./config");
export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    i18n?: i18n;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    selectedLanguage: string;
    token: string;
    errorMsg: string;
    dictionaryId: string;
    showDictionary: ShowDictionaryData;
    openDeleteDic: boolean;
    deleteDictionary: DeleteDictionary;
    // Customizable Area End

}

interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class ViewDictionaryController extends BlockComponent<
    Props,
    S,
    SS
> {

    // Customizable Area Start
    showDictionaryApiCallId: string = "";
    deleteDictionaryApiCallId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.CountryCodeMessage)
        ];

        this.state = {
            selectedLanguage: "",
            token: "",
            errorMsg: "",
            dictionaryId: "",
            showDictionary: {},
            openDeleteDic: false,
            deleteDictionary: {}
        };

        // Customizable Area End
        runEngine.attachBuildingBlock(this, this.subScribedMessages);
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (apiRequestCallId === this.showDictionaryApiCallId) {
                this.showDictionaryResponse(responseJson)
            }
            if (apiRequestCallId === this.deleteDictionaryApiCallId) {
                this.deleteDictionaryResponse(responseJson)
            }
        }
        // Customizable Area End
    }

    // Customizable Area Start
    async componentDidMount() {
        super.componentDidMount();
        const language = await getStorageData("language") || "English";
        this.setState({ selectedLanguage: language });

        const signInResponse = await getStorageData("signInResponse");
        const parsedSignInResponse = JSON.parse(signInResponse)
        this.setState({
            token: parsedSignInResponse.meta?.token,
        })

        const dictionaryId = await getStorageData("dictionaryId");
        this.setState({ dictionaryId: dictionaryId }, () => {
            this.showDictionaryApiCall();
        })
    }

    showDictionaryResponse = (responseJson: ShowDicResponse) => {
        if (responseJson && !responseJson.error) {
            this.setState({
                showDictionary: responseJson.competency_dictionary
            });
        } else if (responseJson && responseJson.error) {
            this.setState({
                errorMsg: responseJson.error,
            });
        }
    }

    deleteDictionaryResponse = (responseJson: DeleteDictionary) => {
        if (responseJson && !responseJson.error) {
            this.setState({ deleteDictionary: responseJson }, () => {
                this.navigateToDashboard();
            })
        } else if (responseJson && responseJson.error) {
            this.setState({
                errorMsg: responseJson.error,
            });
        }
    }


    showDictionaryApiCall = () => {
        const headers = {
            "token": this.state.token
        };
        const getCompetencyMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.showDictionaryApiCallId = getCompetencyMsg.messageId;
        getCompetencyMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        getCompetencyMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.dictionaryApiEndPoint}/${this.state.dictionaryId}`
        );
        getCompetencyMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getApiMethodType
        );
        runEngine.sendMessage(getCompetencyMsg.id, getCompetencyMsg);
    }

    deleteDictionaryApiCall = () => {
        const headers = {
            "token": this.state.token
        };
        const getCompetencyMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.deleteDictionaryApiCallId = getCompetencyMsg.messageId;
        getCompetencyMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        getCompetencyMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.dictionaryApiEndPoint}/${this.state.dictionaryId}`
        );
        getCompetencyMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.deleteApiMethodType
        );
        runEngine.sendMessage(getCompetencyMsg.id, getCompetencyMsg);
    }

    closeDeleteDicDialog = () => {
        this.setState({ openDeleteDic: false })
    }

    openDeleteDicDialog = () => {
        this.setState({ openDeleteDic: true })
    }

    navigateToDashboard = () => {
        const navigateMsg = new Message(getName(MessageEnum.NavigationMessage));
        navigateMsg.addData(getName(MessageEnum.NavigationTargetMessage), "CompetencyDictionaryDashboard");
        navigateMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(navigateMsg);
    }

    navigateToCreateDictionary = async () => {
        await setStorageData("dictionaryId", this.state.dictionaryId)
        
        const navigateMsg = new Message(getName(MessageEnum.NavigationMessage));

        navigateMsg.addData(getName(MessageEnum.NavigationTargetMessage), "DictionaryCreation");

        navigateMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

        this.send(navigateMsg);
    }

    ConditionPass = (condition:boolean,TrueCondition:string,FalseCondition:string) => {
        if(condition){
            return TrueCondition
        }else{
            return FalseCondition
        }
    }

    TraslationFuncation = (key: string) => {
        if (this.props.i18n) {
            return this.props.i18n.t(key);
        } else{
            return key
        }
    }

    // Customizable Area End
}