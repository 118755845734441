import React from "react";
// Customizable Area Start
import {
    Box,
    Grid,
    styled,
    Typography,
    Button,
    Card,
    CardContent,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Select,
    MenuItem,
    Menu,
    IconButton,
    InputBase,
    Pagination,
    Dialog,
    DialogTitle,
    Divider,
    DialogContent,
    DialogActions,
    CircularProgress
} from "@mui/material";
import { DashboardHeaderWeb as DashboardHeader } from "../../dashboard/src/DashboardHeader.web";
import { backArrow, emptyDashboardImg, dropdownImg, deleteComp } from './assets';
import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import MoreVertIcon from '@mui/icons-material/MoreVert';
// Customizable Area End

import CompetencyDictionaryDashboardController, {
    Props,
} from "./CompetencyDictionaryDashboardController";
import { withTranslation } from "react-i18next";

export default class CompetencyDictionaryDashboard extends CompetencyDictionaryDashboardController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    deleteDictionaryDialog = () => {
        return (
            <Dialog
                onClose={this.handleCloseDeleteDialog}
                open={this.state.openDelete}
                PaperProps={{
                    sx: {
                        borderRadius: "8px 8px 32px 8px",
                        direction: this.state.selectedLanguage.toLowerCase() === "english" ? "ltr" : "rtl",
                        overflow: 'hidden',
                    }
                }}
            >
                <DialogTitle>
                    <Wrapper sx={{ marginTop: 0 }}>
                        <Typography className="deleteDicDialogTitle">
                            {this.TraslationFuncation("CompetencyDashboard.DeleteCompetencyDict")}
                        </Typography>
                        <IconButton
                            data-test-id="close_delete_dictionary"
                            aria-label="close"
                            onClick={this.handleCloseDeleteDialog}
                            className="deleteDicDialogClose"
                            sx={{
                                left: this.state.selectedLanguage.toLowerCase() === "english" ? "auto" : 30,
                                right: this.state.selectedLanguage.toLowerCase() === "english" ? 30 : "auto",
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Wrapper>
                </DialogTitle>
                <Divider sx={{ color: "#E2E8F0" }} />
                <DialogContent>
                    <Wrapper>
                        <Box className="deleteImageBox">
                            <img src={deleteComp.default} alt="Success Image" height={144} width={144} />
                        </Box>
                    </Wrapper>
                    <Typography
                        sx={{
                            fontWeight: 700,
                            fontSize: "20px",
                            fontFamily: "Urbanist",
                            padding: {
                                xs: "14px 14px",
                                sm: "14px 64px"
                            },
                            lineHeight: "28px",
                            color: "#0F172A",
                            textAlign: "center"
                        }}>
                            {this.TraslationFuncation("CompetencyDashboard.AreYouSureYouWantToDeleteThisCompetencyDictionary")}
                    </Typography>
                    <Typography
                        sx={{
                            fontFamily: "Urbanist",
                            fontWeight: 400,
                            lineHeight: "26px",
                            padding: "0px 68px",
                            fontSize: "18px",
                            color: "#0F172A",
                            textAlign: "center"
                        }}>
                            {this.TraslationFuncation("CompetencyDashboard.YouWontBeAbleToAccessItAgain")}
                    </Typography>

                </DialogContent>
                <Divider />
                <DialogActions>
                    <Wrapper style={{ marginTop: "0px"}}>
                        <Box className={`${this.state.selectedLanguage.toLowerCase() === "english" ? "deleteButtonsBox" : "deleteButtonsArabic"}`}>
                            <Button
                                color="primary"
                                variant="contained"
                                onClick={this.handleCloseDeleteDialog}
                                className={`${this.state.selectedLanguage.toLowerCase() === "english" ? "cancelDeleteButton" : "cancelDeleteArabic"}`}
                            >
                                {this.TraslationFuncation("CompetencyDashboard.Cancel")}
                            </Button>
                            <Button
                                color="primary"
                                variant="contained"
                                onClick={this.deleteDicApiCall}
                                data-test-id="delete_dic"
                                className={`${this.state.selectedLanguage.toLowerCase() === "english" ? "deleteDicButton" : "deleteButtonArabic"}`}
                            >
                                {this.TraslationFuncation("CompetencyDashboard.Delete")}
                            </Button>
                        </Box>
                    </Wrapper>
                </DialogActions>
            </Dialog>
        )
    }

    noDataScreen = () => {
        return (
            <EmptyWrapper>
                <Grid container className="gridContainer">
                    <Grid item xs={12}>
                        <Box className="dicNoDataImageBox">
                            <img src={emptyDashboardImg.default} />
                            <Typography className="dicNoDataText">
                                {this.TraslationFuncation("CompetencyDashboard.NoCompetenciesAddedYetDashboard")}
                            </Typography>
                            <Typography className="dicNoDataText">
                                {this.TraslationFuncation("CompetencyDashboard.Click")} <span className="highLightedText">
                                {this.TraslationFuncation("CompetencyDashboard.AddNewCompetency")}
                                    </span> {this.TraslationFuncation("CompetencyDashboard.ToGetStarted")}
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </EmptyWrapper>
        )
    }

    exportAllTab = () => {
        return (
            <Grid item xs={12} sm={5} md={4.5} xl={5}>
                <Button
                    className="dicExportAllButton"
                    data-test-id="export_all"
                    onClick={this.handleExportAll}
                >
                    <Typography className="dicExportAllText">
                    {this.TraslationFuncation("CompetencyDashboard.ExportAll")}
                    </Typography>
                </Button>
            </Grid>
        )
    }

    createCompBtn = () => {
        return (
            <Grid item xs={12} sm={7} md={7.5} xl={6.5} >
                <Button
                    className="createDicButton"
                    data-test-id="create_dictionary"
                    onClick={this.navigateToCreation}
                >
                    <Typography className="competencyDicText">
                    {this.TraslationFuncation("CompetencyDashboard.CreateCompetencyDictionary")}
                    </Typography>
                </Button>
            </Grid>
        )
    }

    dictionaryTable = () => {
        return (
            <Wrapper>
                <Card sx={{ borderRadius: 2, boxShadow: "rgba(0, 0, 0, 0.1) 0px 14px 144px 0px" }}>
                    <CardContent sx={{ paddingLeft: "40px", paddingBottom: "0px !important" }}>
                        <TableContainer sx={{ mt: 2 }}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell className="dictonaryTilteText" sx={{ padding: 0 }}>
                                            <Box className="tableCellBox">
                                                {this.TraslationFuncation("CompetencyDashboard.CompetencyName")}
                                                <img src={dropdownImg.default} />
                                            </Box>
                                        </TableCell>

                                        <TableCell className="dictonaryTilteText">
                                            <Box className="tableCellBox">
                                                {this.TraslationFuncation("CompetencyDashboard.CompetencyType")}
                                                <img src={dropdownImg.default} />
                                            </Box>
                                        </TableCell>
                                        <TableCell className="dictonaryTilteText">
                                            {this.TraslationFuncation("CompetencyDashboard.TargetLevel")}</TableCell>
                                        <TableCell className="dictonaryTilteText">
                                        {this.TraslationFuncation("CompetencyDashboard.Behaviour")}
                                        </TableCell>
                                        <TableCell className="dictonaryTilteText">
                                        {this.TraslationFuncation("CompetencyDashboard.Status")}
                                        </TableCell>
                                        <TableCell className="dictonaryTilteText">
                                        {this.TraslationFuncation("CompetencyDashboard.Action")}
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.state.dictionaryData?.data?.map((row, index) => (
                                        <TableRow key={index}>
                                            <TableCell className="dictonaryRowText" sx={{ width: "241px", paddingLeft: 0 }}>
                                                {row.attributes.competency_name}
                                            </TableCell>
                                            <TableCell className="dictonaryRowText" sx={{ width: "219px" }}>
                                                {row.attributes.competency_type.name}
                                            </TableCell>

                                            <TableCell className="dictonaryRowText" sx={{ width: "219px" }}>
                                                {row.attributes.job_level.name}
                                            </TableCell>

                                            <TableCell className="dictonaryRowText" sx={{ width: "329px" }}>
                                                {row.attributes.description}
                                            </TableCell>

                                            <TableCell className="dictonaryRowText" sx={{ width: "105px" }}>
                                                <Select
                                                    data-test-id="dictionary_status"
                                                    displayEmpty
                                                    value={this.state.selectedStatus}
                                                    variant="outlined"
                                                    className="dictionaryStatus"
                                                    renderValue={
                                                        this.state.selectedStatus
                                                            ? undefined
                                                            : () => (
                                                                <div>
                                                                    {row.attributes.status ? this.TraslationFuncation("CompetencyDashboard.Active") : this.TraslationFuncation("CompetencyDashboard.Inactive")}
                                                                </div>
                                                            )
                                                    }
                                                    IconComponent={KeyboardArrowDownIcon}
                                                    input={<InputBase />}
                                                    onChange={(event) => this.handleCompetencyStatus(event, row.id, row.attributes.status)}
                                                >
                                                    {this.state.competencyStatus.map((status) => (
                                                        <MenuItem
                                                            value={status.value}
                                                            key={status.value}
                                                            sx={{
                                                                fontSize: "14px",
                                                                fontFamily: "Urbanist",
                                                                fontWeight: 400,
                                                                margin: "4px",
                                                                background: `${status.value === "Active" ? "#f1f5f9" : "none"}`
                                                            }}
                                                        >
                                                            {status.name}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </TableCell>

                                            <TableCell sx={{ width: "72px" }}>
                                                <IconButton
                                                    data-test-id="more_icon"
                                                    onClick={(event) => this.handleMenuOpen(event, row.id)}
                                                >
                                                    <MoreVertIcon />
                                                </IconButton>
                                                <Menu
                                                    data-test-id="more_menu_close"
                                                    anchorEl={this.state.anchorEl}
                                                    open={Boolean(this.state.anchorEl) && this.state.selectedRowId === row.id}
                                                    onClose={this.handleMenuClose}
                                                    anchorOrigin={{
                                                        vertical: 'top',
                                                        horizontal: 'right',
                                                    }}
                                                    transformOrigin={{
                                                        vertical: 'top',
                                                        horizontal: 'right',
                                                    }}
                                                    PaperProps={{
                                                        sx: {
                                                            borderRadius: "8px",
                                                            border: "1px solid #E2E8F0",
                                                            boxShadow: "rgba(211, 211, 211, 1) 0px 3px , rgba(211, 211, 200, 1) 0px 3px 2px, rgba(211, 211, 211, 1) 0px 2px"
                                                    
                                                        }
                                                    }}
                                                    sx={{
                                                        mt: 4,
                                                        ml: -3,
                                                    }}
                                                >
                                                    <MenuItem
                                                        sx={{ padding: "8px 40px 8px 16px", fontFamily: "Urbanist", fontSize: "14px", fontWeight: 400, color: "#0F172A" }}
                                                        data-test-id="view_dictionary"
                                                        onClick={() => { this.handleView(row.id) }}
                                                    >
                                                        {this.TraslationFuncation("CompetencyDashboard.ViewDetails")}
                                                    </MenuItem>
                                                    <MenuItem
                                                        sx={{ padding: "8px 40px 8px 16px", fontFamily: "Urbanist", fontSize: "14px", fontWeight: 400, color: "#0F172A" }}
                                                        data-test-id="edit_dictionary"
                                                        onClick={() => { this.handleEdit(row.id) }}
                                                    >
                                                        {this.TraslationFuncation("CompetencyDashboard.Editdetails")}
                                                    </MenuItem>
                                                    <MenuItem
                                                        sx={{ padding: "8px 40px 8px 16px", fontFamily: "Urbanist", fontSize: "14px", fontWeight: 400, color: "#F87171", borderRadius: "8px" }}
                                                        data-test-id="delete_dictionary"
                                                        onClick={() => this.openDeleteDialog(row.id)}
                                                    >
                                                        {this.TraslationFuncation("CompetencyDashboard.DeleteDetails")}
                                                    </MenuItem>
                                                </Menu>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </CardContent>
                </Card>
            </Wrapper>
        )
    }

    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <DashboardHeader selectedMenu="Competencies" navigation={this.props.navigation} id={this.props.id}>
                <MainWrapper style={{ direction:this.state.selectedLanguage.toLowerCase() === "english" ? "ltr" : "rtl", }} >
                    {!this.state.loader &&
                        <Grid container justifyContent={"center"}>
                            <Grid item xs={11}>
                                <Grid container alignItems={"center"} justifyContent={"space-between"} spacing={1}>
                                    <Grid item xs={11} sm={11} md={6}>
                                        <Grid container spacing={1} alignItems={"center"}>
                                            <Grid item xs={12} sx={{
                                                display: "flex",
                                                alignItems: "center"
                                            }}>
                                                <img src={backArrow.default}
                                                    alt="Back Arrow"
                                                    className={this.ConditionPass(this.state.selectedLanguage.toLowerCase() === "english", " backArrow ", " backArrowImgArabic ")}
                                                    onClick={this.navigateToBack}
                                                    data-test-id="back_to_dashboard"
                                                />
                                                <Typography className="dictionaryTitle">
                                                    {this.TraslationFuncation("CompetencyDashboard.CompetencyDictionary")}
                                                </Typography>
                                            </Grid>
                                           
                                            <Grid item xs={12}>
                                                <Typography className="dictionarySubTitle">
                                                {this.TraslationFuncation("CompetencyDashboard.CompetencyDictionaryManagement")}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={11} sm={11} md={5}
                                        sx={{
                                            display: "flex",
                                            justifyContent: {
                                                xs: "flex-start",
                                                md: "flex-end",
                                                lg: "flex-end",
                                                xl: "flex-end"
                                            },
                                            marginRight: "16px"
                                        }}
                                    >
                                        <Grid container spacing={2}>
                                            {this.exportAllTab()}
                                            {this.createCompBtn()}
                                        </Grid>

                                    </Grid>
                                </Grid>
                                {this.state.dictionaryData.data?.length ?
                                    this.dictionaryTable() :
                                    this.noDataScreen()
                                }
                            </Grid>
                            {this.deleteDictionaryDialog()}
                            {this.state.totalPage > 1 &&
                                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 5 }}>
                                    <Pagination
                                        count={this.state.totalPage}
                                        page={this.state.page}
                                        data-test-id="page_change"
                                        color="primary"
                                        style={{
                                             direction:"ltr"
                                        }}
                                        onChange={(event, value) => this.handlePageChange(value)}
                                    />
                                </Box>
                            }
                        </Grid>
                    }
                    {this.state.loader && (
                        <Box className="loader-outer"
                            sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <Box className="loader-inner">
                                <CircularProgress />
                            </Box>
                        </Box>
                    )}
                </MainWrapper>
            </DashboardHeader >
            // Customizable Area End
        );
    }
}

// Customizable Area Start
export const CompetencyDictionaryDashboardWeb = withTranslation()(CompetencyDictionaryDashboard);
export const MainWrapper = styled(Box)({
    display: "flex",
    justifyContent: "center",
    width: "100%",
    marginTop: "42px",
    paddingBottom: "30px",
    "& .dictionaryTitle": {
        fontSize: "25px",
        fontWeight: 600,
        fontFamily: "Urbanist",
        color: "#000104",
        marginLeft: "8px",
        display: "inline",
    },
    "& .dictionarySubTitle": {
        fontSize: "16px",
        fontWeight: 400,
        fontFamily: "Urbanist",
        marginTop: "-12px",
        color: "#8A96A8",
        marginLeft: "68px"
    },
    "& .backArrowImg": {
        paddingRight: "12px"
    },
    "& .backArrowImgArabic": {
        paddingRight: "12px",
        transform: "rotate(180deg)"
    },
    "& .dicExportAllButton": {
        width: "100%",
        height: "58px",
        borderRadius: "4px",
        border: "2px solid #94A3B8",
        "&:hover": {
            background: "none"
        }
    },
    "& .dicExportAllText": {
        fontFamily: "Urbanist",
        color: "#64748B",
        fontWeight: 700,
        fontSize: "16px",
        lineHeight: "24px",
        textTransform: "none",
    },
    "& .createDicButton": {
        background: "#044352",
        height: "58px",
        width: "100%",
        borderRadius: "4px",
        padding: "16px 30px",
        position: "relative",
    },
    "& .competencyDicText": {
        fontSize: "16px",
        lineHeight: "24px",
        fontFamily: "Urbanist",
        fontWeight: 700,
        color: "#FFFFFF",
        textTransform: "none"
    },
})
export const Wrapper = styled(Box)({
    width: "100%",
    marginTop: "54px",
    "& .dictonaryTilteText": {
        fontFamily: "Urbanist",
        fontWeight: 700,
        fontSize: "16px",
        lineHeight: "24px",
        color: "#044352"
    },
    "& .deleteDicDialogClose": {
        position: 'absolute',
        color: "#334155",
        top: 16
    },
    "& .deleteDicDialogTitle": {
        color: "#1E1E1E",
        lineHeight: "32px",
        fontWeight: 700,
        fontFamily: "Urbanist",
        fontSize: "24px",
        margin: "8px 16px"
    },
    "& .deleteImageBox": {
        display: "flex",
        padding: "10px 0px",
        justifyContent: "center"
    },
    "& .cancelDeleteButton": {
        padding: "16px 24px",
        fontWeight: 700,
        width: "30%",
        borderRadius: "8px",
        fontFamily: "Urbanist",
        fontSize: "16px",
        height: "56px",
        marginLeft: "16px",
        gap: "8px",
        opacity: "0px",
        textTransform: "none",
        background: "#F1F5F9",
        color: "#000",
        "&:hover": {
            backgroundColor: "#F1F5F9"
        },
    },
    "& .cancelDeleteArabic": {
        borderRadius: "8px",
        padding: "16px 39.5px 16px 36.5px",
        background: "#F1F5F9",
        textTransform: "none",
        opacity: "0px",
        fontSize: "16px",
        fontWeight: 700,
        height: "56px",
        marginRight: "16px",
        gap: "8px",
        fontFamily: "Urbanist",
        width: "120px",
        color: "#000",
        "&:hover": {
            backgroundColor: "#F1F5F9"
        },
    },
    "& .deleteDicButton": {
        fontWeight: 700,
        width: "30%",
        borderRadius: "8px",
        fontFamily: "Urbanist",
        fontSize: "16px",
        height: "56px",
        padding: "16px 24px",
        gap: "8px",
        opacity: "0px",
        marginLeft: "16px",
        background: "#044352",
        textTransform: "none",
        "&:hover": {
            backgroundColor: "#044352"
        },
    },
    "& .deleteButtonArabic": {
        height: "56px",
        gap: "8px",
        borderRadius: "8px",
        opacity: "0px",
        fontSize: "16px",
        fontWeight: 700,
        fontFamily: "Urbanist",
        padding: "16px 39.5px 16px 36.5px",
        marginRight: "16px",
        background: "#044352",
        textTransform: "none",
        width: "120px",
        "&:hover": {
            backgroundColor: "#044352"
        },
    },
    "& .dictonaryRowText": {
        fontFamily: "Urbanist",
        fontWeight: 700,
        fontSize: "14px",
        lineHeight: "24px",
        color: "#044352"
    },
    "& .MuiSelect-icon": {
        transform: 'none !important',
    },
    "& .deleteButtonsBox": {
        paddingRight: "16px",
        margin: "16px 0px",
        display: "flex",
        justifyContent: "flex-end"
    },
    "& .deleteButtonsArabic": {
        display: "flex",
        paddingLeft: "16px",
        margin: "16px 0px",
        justifyContent: "flex-end"
    },
    "& .tableCellBox": {
        display: "flex",
        alignItems: "center"
    },
    "& .dictionaryStatus": {
        padding: "0px 20px 0px 16px",
        textTransform: "none",
        width: "100px",
        height: "30px",
        borderRadius: "4px",
        border: "1px solid #CBD5E1",
        color: "#64748B",
        fontFamily: "Urbanist",
        fontSize: "12px",
        fontWeight: 700,
        lineHeight: "18px",
        marginTop: "3px"
    },
})
export const EmptyWrapper = styled(Box)({
    width: "100%",
    paddingBottom: "30px",
    height: "calc(100vh - 180px)",
    "& .dicNoDataImageBox": {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100%"
    },
    "& .highLightedText": {
        lineHeight: "26px",
        color: "#044352",
        width: "50%",
        fontWeight: 700,
        fontFamily: "Urbanist",
        fontSize: "18px",
        textAlign: "center"
    },
    "& .dicNoDataText": {
        lineHeight: "26px",
        color: "#8A96A8",
        fontWeight: 700,
        fontFamily: "Urbanist",
        fontSize: "18px",
        textAlign: "center",
        width: "60%"
    },
    "& .gridContainer": {
        height: "100%"
    },
    "& .dicNoDataMainText": {
        fontFamily: "Urbanist",
        fontWeight: 700,
        fontSize: "30px",
        lineHeight: "40px",
        color: "#000104"
    }
})
// Customizable Area End