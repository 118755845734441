import React from "react";
// Customizable Area Start
import {
    Box,
    Grid,
    styled,
    Typography,
    Card,
    CardContent,
    Button,
    Divider,
    Chip,
    CircularProgress
} from "@mui/material";
import { backArrow, uploadedFile, emptyDashboardImg } from "./assets";
import { DashboardHeaderWeb as DashboardHeader } from "../../dashboard/src/DashboardHeader.web";
import { withTranslation } from "react-i18next";
// Customizable Area End

import ViewCompanyDetailsController, {
    Props
} from "./ViewCompanyDetailsController";

export default class ViewCompanyDetails extends ViewCompanyDetailsController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    showCompanyName = () => {
        return (
            <Wrapper>
                <Typography className="orgCardInputLabel">
                    {this.props.i18n?.t("companyDetails.organisationName").toString()} <span>*</span>
                </Typography>
                <Typography className="orgNameInputField">
                    {this.state.showCompanyData?.company_name}
                </Typography>
                <Divider style={{ marginTop: "22px" }} />
            </Wrapper>
        )
    }

    showCountry = () => {
        return (
            <Wrapper>
                <Typography className="orgCardInputLabel">
                    {this.props.i18n?.t("companyDetails.country").toString()} <span>*</span>
                </Typography>
                <Typography className="orgDropDownStyle">
                    {this.state.showCompanyData?.country}
                </Typography>
            </Wrapper>
        )
    }

    showRegion = () => {
        return (
            <Wrapper>
                <Typography className="orgCardInputLabel">
                    {this.props.i18n?.t("companyDetails.region").toString()} <span>*</span>
                </Typography>

                <Typography className="orgDropDownStyle">
                    {this.state.showCompanyData?.region}
                </Typography>
            </Wrapper>
        )
    }

    showCompanyCulture = () => {
        return (
            <Wrapper>
                <Typography className="orgCardInputLabel">
                    {this.props.i18n?.t("companyDetails.orgCulture").toString()} <span>*</span>
                </Typography>
                <Typography className="orgCultureInputField">
                    {this.state.showCompanyData?.company_culture?.split('\n').map((text, index) => (
                        <React.Fragment key={index}>
                            {text}
                            <br />
                        </React.Fragment>
                    ))}
                </Typography>
                <Divider style={{ marginTop: "22px" }} />

            </Wrapper>
        )
    }

    showIndustryType = () => {
        return (
            <Wrapper>
                <Typography className="orgCardInputLabel">
                    {this.props.i18n?.t("companyDetails.industryType").toString()} <span>*</span>
                </Typography>
                <Typography className="orgDropDownStyle">
                    {this.state.showCompanyData?.industry_name}
                </Typography>
            </Wrapper>
        )
    }

    showCompanySize = () => {
        return (
            <Wrapper>
                <Typography className="orgCardInputLabel">
                    {this.props.i18n?.t("companyDetails.orgSize").toString()} <span>*</span>
                </Typography>

                <Typography className="orgDropDownStyle">
                    {this.state.showCompanyData?.company_size_name}
                </Typography>

            </Wrapper>
        )
    }

    showCompanystage = () => {
        return (
            <Wrapper>
                <Typography className="orgCardInputLabel">
                    {this.props.i18n?.t("companyDetails.orgStage").toString()} <span>*</span>
                </Typography>
                <Typography className="orgDropDownStyle" >
                    {this.state.showCompanyData?.stage_name}
                </Typography>
            </Wrapper>
        )
    }

    showJdStyle = () => {
        return (
            <Wrapper>
                <Typography className="orgCardInputLabel">
                    {this.props.i18n?.t("companyDetails.jdStyle").toString()} <span>*</span>
                </Typography>
                <Typography className="orgDropDownStyle" >
                    {this.state.showCompanyData?.job_description_style_name}
                </Typography>
            </Wrapper>
        )
    }

    showOrganizationKeywords = () => {
        const keywords = this.state.showCompanyData?.organizational_keywords;
        const chipData = keywords?.split(", ")
        return (
            <Wrapper>
                <Typography className="orgCardInputLabel">
                    {this.props.i18n?.t("companyDetails.orgKeyword").toString()} <span>*</span>
                </Typography>

                <Box className="showChipContainer">
                    {chipData && chipData.map((data, index) => {
                        return (
                            <Chip
                                className="showChip"
                                key={index}
                                label={data}
                            />
                        );
                    })}
                </Box>
            </Wrapper>
        )

    }

    showJdTemplates = () => {
        return (
            <Wrapper>
                <Typography className="orgCardInputLabel uploadJdMargin">
                    {this.props.i18n?.t("companyDetails.uploadJD").toString()} <span>*</span>
                </Typography>
                <Box className="showJdMainBox">
                    <Grid container alignItems={"center"} spacing={1}>
                        <Grid item xs={12} sm={2} md={1.5} className="showJdInnerGrid">
                            <img
                                src={uploadedFile.default}
                                alt="upload Image"
                                data-test-id="upload_image"
                            />
                        </Grid>
                        <Grid item xs={12} sm={9} md={9.5} sx={{
                            display: "flex",
                            flexDirection: "column",
                            textAlign: {
                                xs: 'center',
                                sm: 'left',
                            },
                            alignItems: {
                                xs: 'center',
                                sm: 'flex-start',
                            },
                        }}>
                            <Typography className="showJdUploadText">
                                {this.state.showCompanyData?.document_filename}
                            </Typography>
                            <Typography className="showJdUploadSubText">
                                {this.state.showCompanyData?.document_size} KB
                            </Typography>
                        </Grid>

                    </Grid>
                </Box>
            </Wrapper>
        )
    }

    viewCompanyDetailsHeader = () => {
        return (
            <Wrapper>
                <Box className="orgItemMainBox">
                    <Grid container className="orgCardContainer" >
                        <Grid item xs={12} className="companyCardItem">
                            <Card className={`${this.state.selectedLanguage.toLowerCase() === "english" ? "orgCardDescBox" : "orgCardDescBoxAR"}`}>                            
                                <CardContent className="cardContentPadding">
                                    <Grid container>
                                        <Grid item xs={12} className="orgDetailPadding">
                                            <Typography className="cardTitle">
                                                {this.props.i18n?.t("companyDetails.organizationDetails").toString()}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Divider />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={3.5} className={`contentPadding`}>
                                        <Grid item xs={12}>
                                            {this.showCompanyName()}
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            {this.showCountry()}
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            {this.showRegion()}
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Divider />
                                        </Grid>
                                        <Grid item xs={12}>
                                            {this.showCompanyCulture()}
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            {this.showIndustryType()}
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            {this.showCompanySize()}
                                        </Grid>
                                        <Grid item xs={12}><Divider /></Grid>

                                        <Grid item xs={12} md={6}>
                                            {this.showCompanystage()}
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            {this.showJdStyle()}
                                        </Grid>
                                        <Grid item xs={12}><Divider /></Grid>
                                        <Grid item xs={12}>
                                            {this.showOrganizationKeywords()}
                                        </Grid>
                                        <Grid item xs={12}><Divider /></Grid>
                                        <Grid item xs={12}>
                                            {this.showJdTemplates()}
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </Box>
            </Wrapper>
        )
    }

    noCompanyDetails = () => {
        return (
            <EmptyWrapper>
                <Grid container className="gridContainer">
                    <Grid item xs={12}>
                        <Box className="noDataImageBox">
                            <img src={emptyDashboardImg.default} />
                            <Typography
                                className={this.state.selectedLanguage.toLowerCase() === "english" ?
                                    "noDataMainText" : "noDataMainTextAR"}>
                                {this.props.i18n?.t("companyDetails.noDataFound").toString()}
                            </Typography>
                            <Typography className="noDataNextText">
                                {this.props.i18n?.t("companyDetails.noDataSubText").toString()}
                            </Typography>
                            <Button className="emptyScreenButton" onClick={this.navigateToCompanyDetail}>
                                <Typography className="emptyButtonText">
                                    {this.props.i18n?.t("companyDetails.createOrgBtn").toString()}
                                </Typography>
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </EmptyWrapper>
        )
    }
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <DashboardHeader selectedMenu="Company details" id={this.props.id} navigation={this.props.navigation}>
                <MainWrapper>
                    <Box sx={{ width: "100%" }}>
                        <Grid container justifyContent={`${this.state.selectedLanguage.toLowerCase() === "english" ? "flex-start" : "flex-end"}`}>
                            <Grid item xs={12} md={11.5}>
                                <Grid container
                                    className={`${this.state.selectedLanguage.toLowerCase() === "english" ? "" : "arabicDirection"}`}
                                >
                                    <Grid item xs={12}>
                                        <Grid container justifyContent={`${this.state.selectedLanguage.toLowerCase() === "english" ? "flex-start" : "flex-end"}`}>
                                            <Grid item
                                                xs={12}
                                                md={this.state.selectedLanguage.toLowerCase() === "english" ? 10.5 : 9.5}
                                            >
                                                <Grid container sx={{ padding: "16px" }} alignItems={"center"} >
                                                    {this.state.companyId && <>
                                                        <Grid item xs={1.5} sm={1} md={0.8} lg={0.6}>
                                                            <img src={backArrow.default}
                                                                alt="Back Arrow"
                                                                className={`${this.state.selectedLanguage.toLowerCase() === "english" ? "" : "backArrowImgArabic"}`}
                                                                data-test-id="navigate_to_dashboard"
                                                                onClick={this.navigateToBack}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={10.5} sm={5} md={5.2} lg={5.5}>
                                                            <Typography
                                                                className={`${this.state.selectedLanguage.toLowerCase() === "english" ? "CompanyTitle" : "companyTitleAR"}`}
                                                            >
                                                                {this.props.i18n?.t("companyDetails.organizationDetails").toString()}
                                                            </Typography>
                                                        </Grid>
                                                    </>}
                                                    {!this.state.loader && <Grid item xs={12} sm={5} md={5} lg={5.3}>
                                                        {this.state.companyId &&
                                                            <Box className="editMainBox">
                                                                <Button className="editButton" data-test-id="edit_company" onClick={this.navigateToCompanyDetail}>
                                                                    <Typography className="editText">
                                                                        {this.props.i18n?.t("companyDetails.editOrgBtn").toString()}
                                                                    </Typography>
                                                                </Button>
                                                            </Box>
                                                        }
                                                    </Grid>}
                                                </Grid>
                                                {this.state.loader && (
                                                    <Box className="loader-outer"
                                                        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                        <Box className="loader-inner">
                                                            <CircularProgress style={{ color: "#044352" }} />
                                                        </Box>
                                                    </Box>
                                                )}
                                            </Grid>
                                            <Grid item xs={12} md={this.state.selectedLanguage.toLowerCase() === "english" ? 10.5 : 10}>

                                                {!this.state.loader && <Grid container spacing={1} className="compMainHeaderGrid">
                                                    <Grid item xs={12} sm={12} md={11.2} lg={11.5}>
                                                        {this.state.companyId ?
                                                            this.viewCompanyDetailsHeader() :
                                                            this.noCompanyDetails()}
                                                    </Grid>
                                                </Grid>}
                                            </Grid>
                                        </Grid>

                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                </MainWrapper>
            </DashboardHeader>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
export const ViewCompanyDetailsWeb = withTranslation()(ViewCompanyDetails)
export const Wrapper = styled(Box)({
    width: "100%",
    "& .orgCardDescBox": {
        boxShadow: "rgba(0, 0, 0, 0.1) 0px 14px 144px 0px",
        width: "55vw",
        margin: "4vh 5vw",
        "@media(max-width:900px)": {
            width: "80vw"
        },
    },
    "& .orgCardDescBoxAR": {
        boxShadow: "rgba(0, 0, 0, 0.1) 0px 14px 144px 0px",
        width: "55vw",
        margin: "4vh 6vw",
        "@media(max-width:900px)": {
            width: "80vw"
        },
    },
    "& .orgDetailPadding": {
        padding: "30px 56px 30px 35px"
    },
    "& .orgItemMainBox": {
        width: "100%",
        overflow: "hidden",
        display: "flex",
        justifyContent: "center",
    },
    "& .companyCardItem": {
        flex: "0 0 auto",
        scrollSnapAlign: "center",
    },
    "& .cardContentPadding": {
        padding: "0px 0px 42px 0px"
    },
    "& .contentPadding": {
        padding: "30px 56px 0px 35px"
    },
    "& .cardTitle": {
        fontFamily: "Urbanist",
        fontSize: "20px",
        fontWeight: 600,
        lineHeight: "24px",
        color: "#0444352"
    },
    "& .orgCardInputLabel": {
        fontWeight: 700,
        fontFamily: "Urbanist",
        fontSize: "14px",
        color: "#344054",
        linHheight: "22px",
    },
    "& .loader-outer": {
        height: "100%",
        minHeight: "100vh",
        position: "absolute",
        left: 0,
        bottom: 0,
        top: 0,
        right: 0,
        zIndex: 1,
        backgroundColor: "rgb(0, 0, 0, .4)",
    },
    "& .loader-inner": {
        transform: "translate(-50%, -50%)",
        position: "absolute",
        top: "40%",
        left: "50%",
    },
    "& .uploadJdMargin": {
        marginBottom: "12px"
    },
    "& .orgCardContainer": {
        display: "flex",
        justifyContent: "flex-start",
        width: "100%",
        overflowX: "auto",
        scrollSnapType: "x mandatory",
        scrollBehavior: "smooth",
        padding: "0px 8px",
        scrollbarWidth: "none",
        "&::-webkit-scrollbar": {
            display: "none",
        },
    },
    "& .showChipContainer": {
        padding: "14px 0px 0px 0px",
    },
    "& .showChip": {
        border: '1px solid #CBD5E1',
        margin: "12px 12px 12px 0px",
        borderRadius: "8px",
        fontFamily: "Urbanist",
        fontSize: "14px",
        fontWeight: 500,
        color: "#475361"
    },
    "& .showJdInnerGrid": {
        display: "flex",
        justifyContent: "center"
    },
    "& .showJdUploadText": {
        fontWeight: 400,
        lineHeight: "24px",
        fontFamily: "Urbanist",
        fontSize: "16px",
        color: "#0F172A"
    },
    "& .showJdMainBox": {
        borderRadius: "8px",
        alignItems: "center",
        display: "flex",
        border: "1px solid #CBD5E1",
        padding: "20px 5px"
    },
    "& .showJdUploadSubText": {
        color: "#64748B",
        fontFamily: "Urbanist",
        fontWeight: 400,
        fontSize: "16px",
        lineHeight: "24px",
        direction: "ltr"
    },
    "& .orgCultureInputField": {
        fontSie: "16px",
        fontFamily: 'Urbanist',
        fontWeight: 400,
        lineHight: "24px",
        padding: "12px 0px",
        width: '100%',
        color: "#475569",
        "&:focus": {
            borderColor: "#CBD5E1",
            outline: "none",
        }
    },
    "& .orgNameInputField": {
        fontSie: "16px",
        fontFamily: 'Urbanist',
        fontWeight: 700,
        lineHight: "24px",
        padding: "12px 0px",
        width: '100%',
        color: "#475569",
        "&:focus": {
            borderColor: "#CBD5E1",
            outline: "none",
        }
    },
    "& .orgDropDownStyle": {
        backgroundColor: 'white',
        fontSie: '16px',
        fontWeight: 700,
        lineHight: "24px",
        fontFamily: 'Urbanist',
        padding: '10px 0px',
        width: '100%',
        color: "#475569"
    },
})
export const EmptyWrapper = styled(Box)({
    width: "100%",
    height: "calc(100vh - 180px)",
    paddingBottom: "30px",
    "& .arabicDirection": {
        direction: "rtl",
    },
    "& .gridContainer": {
        height: "100%"
    },
    "& .emptyScreenButton": {
        marginTop: "24px",
        height: "58px",
        padding: "10px 16px",
        borderRadius: "4px",
        background: "#044352",
    },
    "& .emptyButtonText": {
        fontFamily: "Urbanist",
        fontSize: "16px",
        fontWeight: 700,
        lineHeight: "24px",
        color: "#FFFFFF",
        textTransform: "none"
    },
    "& .noDataImageBox": {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        height: "100%",
        alignItems: "center",
    },
    "& .noDataNextText": {
        color: "#8A96A8",
        fontFamily: "Urbanist",
        fontSize: "18px",
        width: "50%",
        fontWeight: 400,
        lineHeight: "26px",
        textAlign: "center"
    },
    "& .spanStyle": {
        fontWeight: 700
    },
    "& .noDataMainText": {
        fontWeight: 700,
        fontFamily: "Urbanist",
        fontSize: "30px",
        lineHeight: "40px",
        color: "#000104"
    },
    "& .noDataMainTextAR": {
        fontWeight: 700,
        fontFamily: "Urbanist",
        fontSize: "30px",
        lineHeight: "60px",
        color: "#000104"
    },
})
export const MainWrapper = styled(Box)({
    display: "flex",
    justifyContent: "center",
    width: "100%",
    marginTop: "42px",
    paddingBottom: "30px",
    "& .arabicDirection": {
        direction: "rtl",
    },
    "& .companyTitle": {
        fontWeight: 600,
        fontFamily: "Urbanist",
        color: "#000104",
        fontSize: "25px",
        lineHeight: "30px",
        marginLeft: "12px",
    },
    "& .companyTitleAR": {
        fontWeight: 600,
        fontFamily: "Urbanist",
        color: "#000104",
        fontSize: "25px",
        lineHeight: "30px",
        marginRight: "12px"
    },
    "& .editMainBox": {
        display: "flex",
        justifyContent: "flex-end",
        "@media(max-width:600px)": {
            justifyContent: "flex-start",
            marginTop: "10px"
        }
    },
    "& .editButton": {
        height: "58px",
        padding: "10px 32px",
        borderRadius: "4px",
        border: "1px solid #94A3B8",
        background: "#044352",
    },
    "& .compMainHeaderGrid": {
        marginTop: "20px"
    },
    "& .editText": {
        fontFamily: "Urbanist",
        fontSize: "16px",
        fontWeight: 700,
        lineHeight: "24px",
        color: "#FFFFFF",
        textTransform: "none"
    },
    "& .backArrowImgArabic": {
        transform: "rotate(180deg)"
    },
})
// Customizable Area End