import React from "react";

// Customizable Area Start
import {
    Box,
    Grid,
    Typography,
    Button,
    CircularProgress,
    Divider,
    Chip,
    Modal
} from "@mui/material";
import { arrow_Left, blankCheckbox, DeleteIcon, deleteQuestion, editQuestion, selectedCheckbox, successImg } from "./assets";
import { DashboardHeaderWeb as DashboardHeader } from "../../dashboard/src/DashboardHeader.web";

import { withTranslation } from "react-i18next";
import EditAssessmentCotroller, {
    Props,
} from "./EditAssessmentCotroller";
import { HeaderContainer } from "./AssessmentTestDashbord.web";
import { removeStorageData } from "../../../../packages/framework/src/Utilities";
import { ViewAssessmentStyle } from "./ViewAssessment.web";
import { styled } from "@mui/system";
import CustomPopup from "../../../components/src/CustomSuccessModel.web";
// Customizable Area End

export default class EditAssessment extends EditAssessmentCotroller {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start





    RenderAllScreens = () => {
        if (this.state.isLoading) {
            return <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80vh' }}>
                <CircularProgress style={{ color: "#044352" }} />
            </Box>
        } else {
            return this.RenderTable()
        }

    }

    DeleteModel = () => {
        return (
            <Modal
                open={this.state.DeleteQuestionModel}
                data-test-id="QuestionParentDeleteModal"
                onClose={() => { this.CloseDeleteModel() }}>
                <CustomPopup
                    data-test-id="QuestionDeleteModal"
                    headingPopup={`Delete Question`}
                    titlePopup={`Are you sure you want to delete this Question?`}
                    subTitlePopup={`${this.TraslationFuncation("SettingsPage.YouWontBeAbleToAccessItAgain")}`}
                    cancelButtonText={this.TraslationFuncation("Cancel")}
                    buttonText={`${this.TraslationFuncation("Delete")}`}
                    successIcon={DeleteIcon.default}
                    showCancelButton={true}
                    onOpen={() => {
                        this.DeleteQuestionApiCall()
                    }}
                    onClose={() => {
                        this.CloseDeleteModel()
                    }}
                />
            </Modal>
        )
    }

    SuccessModel = () => {
        return (
            <Modal
                open={this.state.SuccessModel}
                data-test-id="successModel"
                onClose={this.CloseModelSuccessModel}>
                <CustomPopup
                    buttonText={"Done"}
                    data-test-id="successModelCustomPopup"
                    headingPopup={"Assessment Updated"}
                    titlePopup={"Assessment Updated"}
                    subTitlePopup={"Assessment has been updated successfully"}
                    showCancelButton={false}
                    successIcon={successImg.default}
                    onOpen={this.CloseModelSuccessModel}
                    onClose={this.CloseModelSuccessModel}
                />
            </Modal>
        )
    }

    RenderTable = () => {
        return (
            <Grid container>
                {this.DeleteModel()}
                {this.SuccessModel()}
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Grid container>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                            <Box data-test-id="BackToJobDescription"
                                onClick={() => {
                                    this.NaviGateToAnyPage("AssessmentTestDashbord");
                                    removeStorageData("assessmentId")
                                }}
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "flex-start",
                                    marginBottom: "20px",
                                    cursor: "pointer"
                                }}
                            >
                                <Box className="BackArrowBox">
                                    <img src={arrow_Left.default}
                                        alt="BackArrowImg" />
                                </Box>
                                <Typography className="HeadingTitle">Assessment Name</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                            <Grid container spacing={2} justifyContent={"flex-end"}>
                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                    <Button
                                        data-test-id="SaveButton"
                                        className="ContainedButton"
                                        variant="outlined"
                                        fullWidth
                                        type="submit"
                                        onClick={() => {
                                            this.SaveChangesApiCall()
                                            
                                        }}
                                    >
                                        <Typography className="ButtonText">
                                            Save Changes
                                        </Typography>
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid
                            item xs={12}
                            sm={12}
                            md={12}
                            lg={12} className="TableContainer"
                            style={{ marginTop: "70px" }}>
                            <HeaderContainer>
                                <Box className="CardContainer">
                                    <Typography className="SmallTitle">Assessment Name</Typography>
                                    <Box>
                                        <Divider style={{ color: "#E2E8F0", marginTop: "10px" }} />
                                    </Box>
                                    <Box>
                                        <Grid container spacing={2} justifyContent={"center"} style={{ marginTop: "10px" }}>
                                            {this.state.QuestionAnswerData.map((item, index) => {
                                                const combinedData = [
                                                    ...this.state.DifficultyLevel,
                                                    ...this.state.QuestionFormats,
                                                    ...this.state.FocusAreas
                                                ];
                                                return (
                                                    <EditQuetionWrapper sx={{ marginTop: 0 }}>

                                                        <Grid container spacing={1.5} alignItems={"center"}>
                                                            <Grid item xs={12} sm={"auto"} className="filterEditRemove">
                                                                <img
                                                                    src={blankCheckbox.default} alt="checkbox" className="checkBoxStyle" />
                                                            </Grid>

                                                            <Grid item xs={12} sm={10.5} className="chipGridItem">
                                                                {combinedData.map((filter, index) => (
                                                                    <Chip
                                                                        variant="outlined" size="small" label={filter}
                                                                        className={`filterChip ${index === 0 ? "filterChipSelected" : null}`}
                                                                    />
                                                                ))}
                                                            </Grid>

                                                            <Grid item xs={12} sm={"auto"} className="filterEditRemove">
                                                                <img
                                                                    src={editQuestion.default}
                                                                    width={"24px"} height={"24px"}
                                                                    data-test-id={"edit_question" + index}
                                                                    onClick={() => this.navigateToEdit(item.question.id)}
                                                                    style={{ marginRight: "5px" }} />
                                                                <img
                                                                    src={deleteQuestion.default}
                                                                    data-test-id={"delete_question" + index}
                                                                    onClick={() => {
                                                                        this.setState({
                                                                            QuestionApiId: item.question.id,
                                                                            DeleteQuestionModel: true,
                                                                        })
                                                                    }}
                                                                    width={"24px"}
                                                                    height={"24px"} />
                                                            </Grid>
                                                        </Grid>

                                                        <Box sx={{
                                                            margin: {
                                                                xs: "24px 0px 24px 8px",
                                                                sm: "24px 0px 24px 16px",
                                                                md: "24px 0px 24px 40px"
                                                            }
                                                        }}>
                                                            <Typography className="questionLabel">
                                                                {index + 1}. {item.question.text}
                                                            </Typography>

                                                            <Box sx={{ fontFamily: "Urbanist" }}>
                                                                {item.question.question_format_id === "Scenario Based" ? (
                                                                    <Box className="optionsStyle scenarioAns">
                                                                        <Typography>
                                                                            {item.choices[0].option_text}
                                                                        </Typography>
                                                                    </Box>
                                                                ) : (
                                                                    item.choices?.map((options) => (
                                                                        <Box
                                                                            key={options.option_text}
                                                                            display="flex"
                                                                            alignItems="center"
                                                                            mb={1}
                                                                            className={
                                                                                options.correct
                                                                                    ? "optionsStyleTrue"
                                                                                    : "optionsStyle"
                                                                            }
                                                                            sx={{ fontFamily: "Urbanist" }}
                                                                            data-test-id="question_options"
                                                                        >
                                                                            <img
                                                                                src={
                                                                                    options.correct
                                                                                        ? selectedCheckbox.default
                                                                                        : blankCheckbox.default
                                                                                }
                                                                                alt="checkbox"
                                                                                className="checkBoxStyle"
                                                                            />
                                                                            <Typography>{options.option_text}</Typography>
                                                                        </Box>
                                                                    ))
                                                                )}
                                                            </Box>
                                                        </Box>

                                                    </EditQuetionWrapper>
                                                )
                                            })}
                                        </Grid>
                                    </Box>
                                </Box>
                            </HeaderContainer>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        )
    }

    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <DashboardHeader selectedMenu="Assessments" navigation={this.props.navigation} id={this.props.id}>
                <ViewAssessmentStyle>
                    {this.RenderAllScreens()}
                </ViewAssessmentStyle>
            </DashboardHeader>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
export const EditAssessmentWeb = withTranslation()(EditAssessment)

export const EditQuetionWrapper = styled(Box)({
    width: "100%",
    marginTop: "54px",
    "& .questionLabel": {
        color: "#0F172A",
        fontFamily: "Urbanist",
        fontSize: "16px",
        fontWeight: 700,
        linHheight: "22px",
        marginBottom: "20px"
    },
    "& .contentPadding": {
        
        padding: "10px 30px 40px 0px"
    },
    "& .checkBoxStyle": {
        marginRight: "10px",
        width: "20px",
        height: "20px",
    },
    "& .chipGridItem": {
        display: "flex",
        alignItems: "center",
        flexWrap: "wrap"
    },
    "& .optionsStyle": {
        cursor: "pointer",
        fontSize: '14px',
        fontWeight: 400,
        fontFamily: 'Urbanist',
        color: "#000000",
        width: "93%",
        lineHight: "24px",
        textAlign: "left",
        "&:hover": { backgroundColor: "transparent" },
        border: '1px solid #E2E8F0',
        minHeight: '50px',
        borderRadius: '8px',
        padding: '0px 16px',
        "& .MuiTypography-root": {
            linHheight: "22px",
            fontWeight: 400,
            fontFamily: 'Urbanist',
            fontSize: "14px",
        },
    },
    "& .scenarioAns": {

        padding: "8px 16px"
    },
    "& .optionsStyleTrue": {
        color: "#000000",
        fontSize: '14px',
        fontWeight: 400,
        fontFamily: 'Urbanist',
        cursor: "pointer",
        width: "93%",
        border: "1px solid #059669",
        backgroundColor: "#D1FAE5",
        minHeight: '50px',
        lineHight: "24px",
        textAlign: "left",
        "&:hover": { backgroundColor: "#D1FAE5" },
        borderRadius: '8px',
        padding: '0px 16px',
        "& .MuiTypography-root": {
            fontWeight: 400,
            linHheight: "22px",
            fontFamily: 'Urbanist',
            fontSize: "14px",
        },
    },
    "& .filterChip": {
        margin: "2px 2px 6px 2px",
        fontWeight: "400",
        lineHeight: "22px",
        padding: "14px 4px",
        color: "#475569",
        fontFamily: "Urbanist",
        fontSize: "14px",
        border: "1px solid #E2E8F0",
        display: "flex"
    },
    "& .filterChipSelected": {
        border: "1px solid #DC2626",
        color: "#DC2626",
    },
    "& .filterEditRemove": {
        display: "flex", justifyContent: "center",
        "@media (max-width: 425px)": {
            justifyContent: "flex-start"
        },
    }
})

// Customizable Area End