import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import React from "react";
import { SelectChangeEvent } from '@mui/material/Select';
import { setStorageData, getStorageData } from "framework/src/Utilities";
import { i18n } from "i18next";
interface CountryCodes {
    country_name: string;
    country_code: string;
    region: string;
}

interface ContryCodeResponse {
    message?: string;
    error?: string;
    data?: CountryCodes[];
}

interface Department {
    id?: string;
    type?: string;
    attributes?: {
        id: number;
        name: string;
    }
}
interface DepartmentResponse {
    message?: string;
    error?: string;
    data?: Department[];
}
interface ShowCompanyData {
    error?: string;
    message?: string;
    organisation_details?: {
        data?: {
            id: string;
            type: string;
            attributes: {
                company_name: string;
            }
        }
    }
}
interface CreateUserResponse {
    message?: string;
    error?: string;
    data?: {
        id: string;
        attribute: {
            activated: boolean;
            country_code: string;
            email: string;
            first_name: string;
            full_phone_number: string;
            last_name: string;
            phone_number: string;
            type: string;
            staff_id: string;
            date_of_birth: string;
            gender: string;
            designation: string;
            company_name: string;
            user_role: string;
            job_description: string;
            profile_picture: string;
        }
    };
    meta?: {
        message: string;
    }
    errors?: Array<{
        email?: string;
        staff_id?: string;
        full_phone_number?: string;
    }>;
}

interface UserProfile {
    message?: string;
    errors?: string;
    data?: {
        id: string;
        attributes: {
            email: string;
            first_name: string;
            activated: boolean;
            country_code: string;
            full_phone_number: string;
            last_name: string;
            phone_number: string;
            staff_id: string;
            date_of_birth: string;
            gender: string;
            designation: string;
            company_name: string;
            user_role: string;
            job_description: string;
            profile_picture: string;
            department: {
                id: string;
                attributes: {
                    id: string;
                    name: string;
                }
            };
            competencies: {
                id: string;
                name: string;
            }[];
        }
    }
}

interface CompetencyResponse {
    message: string;
    error: string;
    competencies: Competencies;
    download_url?: string;
    meta?: {
        total_pages: number;
        current_page: number;
        total_records: number;
        prev_page: number;
    }
}

interface Competencies {
    data?: {
        id: string;
        type: string;
        attributes: {
            id: string;
            job_description_id: number | null;
            job_title: string;
            competency_type_id: number;
            competency_type: string;
            description: string;
            status: boolean;
            file_url: string | null;
            job_family: string;
            target_level: string;
            file_filename: string | null;
            file_size: number | null;
            competency_name: string;
            chatgpt_response: {
                title: string;
                description: string;
            };
        };
    }[];
}
// Customizable Area End

export const configJSON = require("./config");
export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    i18n?: i18n;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    token: string;
    selectedLanguage: string;
    errorMsg: string;
    referenceImage: string | File | null | undefined;
    referenceImgFile: string | File | null | undefined;
    referenceImageError: string;
    countryCodeData: ContryCodeResponse;
    selectedCountryCode: string;
    userBirthDate: string | undefined;
    birthDateError: string;
    gender: string[];
    selectedGender: string;
    department: {
        data?: Department[];
    }
    selectedDepartment: string;
    createUser: CreateUserResponse;
    updateUser: CreateUserResponse;
    competencies: Competencies;
    Page: number;
    TotalRecords: number;
    PerPage: number;
    selectedCompetencies: string[];
    userType: string[];
    selectedUserType: string;
    createUserClicked: boolean;
    firstName: string;
    firstNameError: string;
    lastName: string;
    lastNameError: string;
    userEmail: string;
    emailError: string;
    userContactNumber: string;
    contactError: string;
    staffId: string;
    userDesignation: string;
    companyName: string;
    jobDescription: string;
    openSuccessUser: boolean;
    staffIdError: string;
    createdCompanyname: string;
    userId: string;
    userProfileData: UserProfile;
    isEdit: boolean;
    // Customizable Area End

}

interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class UserCreationController extends BlockComponent<
    Props,
    S,
    SS
> {

    // Customizable Area Start
    dateInputRef: null | React.RefObject<HTMLInputElement> = null;
    getAllCountryCodeApiCallId: string = "";
    createUserApiCallId: string = "";
    getDepartmentApiCallId: string = "";
    getCompanyApiCallId: string = "";
    getUserProfileApiCallId: string = "";
    updateUserApiCallId: string = "";
    getCompetencyApiCallId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.CountryCodeMessage)
        ];

        this.state = {
            errorMsg: "",
            token: "",
            selectedLanguage: "",
            referenceImage: null,
            referenceImgFile: null,
            referenceImageError: "",
            countryCodeData: {},
            selectedCountryCode: "+91",
            userBirthDate: "",
            birthDateError: "",
            gender: ["Male", "Female", "Other"],
            selectedGender: "",
            department: {},
            selectedDepartment: "",
            competencies: {},
            Page: 1,
            PerPage: 20,
            TotalRecords: 0,
            selectedCompetencies: [],
            userType: ["Candidate", "Employee", "HR SubAdmin"],
            selectedUserType: "",
            createUserClicked: false,
            createUser: {},
            updateUser: {},
            firstName: "",
            firstNameError: "",
            lastName: "",
            lastNameError: "",
            userEmail: "",
            emailError: "",
            userContactNumber: "",
            contactError: "",
            staffId: "",
            userDesignation: "",
            companyName: "",
            jobDescription: "",
            openSuccessUser: false,
            staffIdError: "",
            createdCompanyname: "",
            userId: "",
            userProfileData: {},
            isEdit: false
        };
        this.dateInputRef = React.createRef<HTMLInputElement>();
        // Customizable Area End
        runEngine.attachBuildingBlock(this, this.subScribedMessages);
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (apiRequestCallId === this.getAllCountryCodeApiCallId) {
                this.countryCodeApiResponse(responseJson)
            }
            if (apiRequestCallId === this.getDepartmentApiCallId) {
                this.departmentApiResponse(responseJson)
            }
            if (apiRequestCallId === this.createUserApiCallId) {
                this.createUserApiResponse(responseJson)
            }
            if (apiRequestCallId === this.updateUserApiCallId) {
                this.updateUserApiResponse(responseJson)
            }
            if (apiRequestCallId === this.getCompanyApiCallId) {
                this.companyApiResponse(responseJson)
            }
            if (apiRequestCallId === this.getUserProfileApiCallId) {
                this.handleUserApiResponse(responseJson)
            }
            if (apiRequestCallId === this.getCompetencyApiCallId) {
                this.getCompetencyApiResponse(responseJson)
            }
        }
        // Customizable Area End
    }

    // Customizable Area Start
    async componentDidMount() {
        super.componentDidMount();

        const language = await getStorageData("language") || "English";
        this.setState({ selectedLanguage: language });

        const signInResponse = await getStorageData("signInResponse");
        const parsedSignInResponse = await JSON.parse(signInResponse)
        this.setState({ token: parsedSignInResponse.meta?.token })

        const userId = await getStorageData("userId");
        const parsedUserId = await JSON.parse(userId)
        if (parsedUserId) {
            this.setState({ userId: parsedUserId, isEdit: true })
            this.getUserProfileApiCall();
        }
        this.getAllCountryCodeApiCall();
        this.getDepartmentApiCall();
        this.getCompanyApiCall();
        this.getCompetencyApiCall();
    }

    handleUserApiResponse = (responseJson: UserProfile) => {
        if (responseJson && !responseJson.errors) {
            this.setState({ userProfileData: responseJson }, () => {
                const { userProfileData } = this.state;
                let userRole = "";
                if (userProfileData.data?.attributes.user_role === "candidate") {
                    userRole = "Candidate";
                }
                else if (userProfileData.data?.attributes.user_role === "employee") {
                    userRole = "Employee";
                }
                else if (userProfileData.data?.attributes.user_role === "hr_sub_admin") {
                    userRole = "HR SubAdmin";
                }
                this.populateStateOnEdit(responseJson, userRole);

            })
        } else if (responseJson && responseJson.errors) {
            this.setState({
                errorMsg: responseJson.errors,
            });
        }
    }

    populateStateOnEdit = (responseJson: UserProfile, userRole: string) => {
        this.setState({
            referenceImage: responseJson.data?.attributes.profile_picture,
            firstName: responseJson.data?.attributes.first_name || "",
            lastName: responseJson.data?.attributes.last_name || "",
            userEmail: responseJson.data?.attributes.email || "",
            selectedCountryCode: "+" + responseJson.data?.attributes.country_code || "",
            userContactNumber: responseJson.data?.attributes.phone_number || "",
            staffId: responseJson.data?.attributes.staff_id || "",
            userBirthDate: responseJson.data?.attributes.date_of_birth || "",
            selectedGender: responseJson.data?.attributes.gender || "",
            userDesignation: responseJson.data?.attributes.designation || "",
            selectedDepartment: responseJson.data?.attributes.department.attributes.id || "",
            selectedCompetencies: responseJson.data?.attributes.competencies
                ? responseJson.data.attributes.competencies.map((competency) => competency.id)
                : [],
            selectedUserType: userRole,
            jobDescription: responseJson.data?.attributes.job_description || ""
        })
    }

    countryCodeApiResponse = (responseJson: ContryCodeResponse) => {
        if (responseJson && !responseJson.error) {
            this.setState({ countryCodeData: responseJson })
        } else if (responseJson && responseJson.error) {
            this.setState({
                errorMsg: responseJson.error
            });
        }
    }

    departmentApiResponse = (responseJson: DepartmentResponse) => {
        if (responseJson && !responseJson.error) {
            this.setState({ department: responseJson })
        } else if (responseJson && responseJson.error) {
            this.setState({
                errorMsg: responseJson.error
            });
        }
    }

    companyApiResponse = (responseJson: ShowCompanyData) => {
        if (responseJson && !responseJson.error) {
            this.setState({ createdCompanyname: responseJson.organisation_details?.data?.attributes.company_name || "" }, () => {
                this.setState({ companyName: this.state.createdCompanyname })
            })
        } else if (responseJson && responseJson.error) {
            this.setState({
                errorMsg: responseJson.error
            });
        }
    }

    updateUserApiResponse = (responseJson: CreateUserResponse) => {
        if (responseJson && responseJson.data) {
            this.setState({ updateUser: responseJson }, () => {

                if (this.state.updateUser.meta?.message) {
                    this.setState({
                        openSuccessUser: true
                    });
                }
            });
        } else {
            this.createUserErrorResponse(responseJson);
        }
    };

    createUserApiResponse = (responseJson: CreateUserResponse) => {
        if (responseJson && responseJson.data) {
            this.setState({ createUser: responseJson }, async () => {

                if (this.state.createUser.meta?.message) {
                    await setStorageData("userId", JSON.stringify(this.state.createUser.data?.id))
                    this.setState({
                        openSuccessUser: true
                    });
                }
            });
        } else {
            this.createUserErrorResponse(responseJson);
        }
    };

    createUserErrorResponse = (responseJson: CreateUserResponse) => {
        if (responseJson && responseJson.errors) {
            const errors = responseJson.errors;

            let emailError = "";
            let contactError = "";
            let staffIdError = "";

            errors.forEach((errorObj) => {
                if (errorObj.email) {
                    emailError = errorObj.email;
                }
                if (errorObj.full_phone_number) {
                    contactError = errorObj.full_phone_number;
                }
                if (errorObj.staff_id) {
                    staffIdError = errorObj.staff_id;
                }
            });
            this.setState({
                emailError,
                contactError,
                staffIdError,
            });
        }
    }


    handleCloseSuccessUser = () => {
        this.setState({ openSuccessUser: false })
    }

    getCompetencyApiResponse = (responseJson: CompetencyResponse) => {
        if (responseJson && !responseJson.error) {
            if (this.state.Page !== 1) {
                this.setState(prevState => ({
                    competencies: {
                        ...prevState.competencies,
                        data: [
                            ...(prevState.competencies.data || []),
                            ...(responseJson.competencies.data || [])
                        ]
                    },
                    TotalRecords: Number(responseJson.meta?.total_records)
                }))
            } else {
                this.setState({ competencies: responseJson.competencies, TotalRecords: Number(responseJson.meta?.total_records) })
            }
        } else if (responseJson && responseJson.error) {
            this.setState({
                errorMsg: responseJson.error
            });
        }
    }

    getCompetencyApiCall = () => {
        const headers = {
            "token": this.state.token
        };

        const competencyMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getCompetencyApiCallId = competencyMsg.messageId;
        competencyMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        competencyMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.getCompetencyApiEndPoint}?page=${this.state.Page}&per_page=${this.state.PerPage}`
        );
        competencyMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getApiMethodType
        );
        runEngine.sendMessage(competencyMsg.id, competencyMsg);
    }

    getAllCountryCodeApiCall = () => {
        const headers = {
            "Content-Type": "application/json",
        };
        const getMsgForCountryCode = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getAllCountryCodeApiCallId = getMsgForCountryCode.messageId;
        getMsgForCountryCode.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.getCountryCodeApiEndPoint}`
        );
        getMsgForCountryCode.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        getMsgForCountryCode.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getApiMethodType
        );
        runEngine.sendMessage(getMsgForCountryCode.id, getMsgForCountryCode);
    }

    handleCreateUser = () => {
        this.setState({ createUserClicked: true })

        let errorInFirst = !this.state.firstName;
        let errorInLast = !this.state.lastName;
        let errorInEmail = !this.state.userEmail;
        let errorIncontact = !this.state.userContactNumber;
        let errorInStaffId = !this.state.staffId;
        let errorInBirthDate = !this.state.userBirthDate;
        let errorInSelectedGender = !this.state.selectedGender;
        let errorInUserDesignation = !this.state.userDesignation;
        let errorInSelectedDepartment = !this.state.selectedDepartment;
        let errorInCompanyName = !this.state.companyName;
        let errorInUserType = !this.state.selectedUserType;
        let errorInCompetencies = !this.state.selectedCompetencies.length;
        let errorInJobDescription = !this.state.jobDescription;

        this.firstNameValidation();
        this.lastNameValidation();
        this.validationEmail();
        this.phoneNumberValidation();
        this.birthDateValidation();
        this.staffIdValidation();
        if (errorInFirst || this.state.firstNameError ||
            errorInLast || this.state.lastNameError ||
            errorInEmail || this.state.emailError ||
            errorIncontact || this.state.contactError ||
            errorInStaffId ||
            errorInBirthDate ||
            errorInSelectedGender ||
            errorInUserDesignation ||
            errorInSelectedDepartment ||
            errorInCompanyName ||
            errorInUserType ||
            errorInCompetencies ||
            errorInJobDescription
        ) {
            return;
        }

        if (this.state.isEdit) {
            this.updateUserApiCall();
        } else {
            this.createUserApiCall();
        }
    }

    firstNameValidation = () => {
        let firstNameError = "";
        const alphabeticRegex = /^[A-Za-z\u0600-\u06FF\s]+$/;

        if ((this.state.firstName.length < 2) || (this.state.firstName.length > 100)) {
            firstNameError = this.props.i18n?.t("allUsers.namelongError").toString() || "";
        }
        else if (!alphabeticRegex.test(this.state.firstName)) {
            firstNameError = this.props.i18n?.t("allUsers.nameAlphabaticError").toString() || "";
        }
        this.setState({ firstNameError })
    }

    lastNameValidation = () => {
        let lastNameError = "";
        const alphabeticRegex = /^[A-Za-z\u0600-\u06FF\s]+$/;

        if ((this.state.lastName.length < 2) || (this.state.lastName.length > 100)) {
            lastNameError = this.props.i18n?.t("allUsers.namelongError").toString() || "";
        }
        else if (!alphabeticRegex.test(this.state.lastName)) {
            lastNameError = this.props.i18n?.t("allUsers.nameAlphabaticError").toString() || "";
        }
        this.setState({ lastNameError })
    }

    validationEmail = () => {
        let emailError = "";
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

        if (!this.state.userEmail) {
            emailError = this.props.i18n?.t("allUsers.errorText").toString() || "";
        } else if (!emailRegex.test(this.state.userEmail)) {
            emailError = this.props.i18n?.t("allUsers.emailError").toString() || "";
        }

        this.setState({ emailError });
    }

    phoneNumberValidation = () => {
        let contactError = '';
        const contactRegex = /^\d{6,10}$/;

        if (!this.state.userContactNumber) {
            contactError = this.props.i18n?.t("allUsers.errorText").toString() || "";
        } else if (!contactRegex.test(this.state.userContactNumber)) {
            contactError = this.props.i18n?.t("allUsers.contactError").toString() || "";
        }

        this.setState({ contactError });
    }

    staffIdValidation = () => {
        let staffIdError = '';
        if (!this.state.staffId) {
            staffIdError = this.props.i18n?.t("allUsers.errorText").toString() || "";
        } else if (this.state.staffId.length > 8) {
            staffIdError = this.props.i18n?.t("allUsers.staffIdError").toString() || "";
        }

        this.setState({ staffIdError });
    }

    birthDateValidation = () => {
        if (!this.state.userBirthDate) {
            this.setState({ birthDateError: this.props.i18n?.t("allUsers.errorText").toString() || "" });
        } else {
            const inputDate = new Date(this.state.userBirthDate);
            const today = new Date();
            const thresholdDate = new Date();
            thresholdDate.setFullYear(today.getFullYear() - 18);

            if (inputDate.getTime() > thresholdDate.getTime()) {
                this.setState({ birthDateError: this.props.i18n?.t("allUsers.dobError").toString() || "" });
            } else {
                this.setState({ birthDateError: '' });
            }
        }
    }

    getDepartmentApiCall = () => {
        const headers = {
            "Content-Type": "application/json",
            "token": this.state.token
        };
        const getDepartmentMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getDepartmentApiCallId = getDepartmentMsg.messageId;
        getDepartmentMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        getDepartmentMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.getDepartmentApiEndPoint}`
        );
        getDepartmentMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getApiMethodType
        );
        runEngine.sendMessage(getDepartmentMsg.id, getDepartmentMsg);
    }

    getCompanyApiCall = () => {
        const headers = {
            "token": this.state.token
        };
        const getMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getCompanyApiCallId = getMsg.messageId;
        getMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.showCompanyApiEndPoint}`
        );
        getMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        getMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getApiMethodType
        );
        runEngine.sendMessage(getMsg.id, getMsg);
    }

    createUserApiCall = () => {
        const headers = {
            token: this.state.token,
        };
        let userRole = "";
        if (this.state.selectedUserType === "Candidate") {
            userRole = "candidate";
        }
        else if (this.state.selectedUserType === "HR SubAdmin") {
            userRole = "hr_sub_admin";
        }
        else if (this.state.selectedUserType === "Employee") {
            userRole = "employee";
        }

        let formdata = new FormData();
        formdata.append("user[last_name]", this.state.lastName);
        formdata.append("user[first_name]", this.state.firstName);
        formdata.append("user[email]", this.state.userEmail);
        formdata.append("user[country_code]", this.state.selectedCountryCode);
        formdata.append("user[phone_number]", this.state.userContactNumber);
        formdata.append("user[staff_id]", this.state.staffId);
        formdata.append("user[date_of_birth]", this.state.userBirthDate || "");
        formdata.append("user[department_id]", this.state.selectedDepartment);
        formdata.append("user[gender]", this.state.selectedGender);
        formdata.append("user[designation]", this.state.userDesignation);
        formdata.append("user[company_name]", this.state.companyName);
        formdata.append("user[user_role]", userRole);
        this.state.selectedCompetencies.forEach(value => {
            formdata.append("user[competency_ids][]", value);
        })
        formdata.append("user[job_description]", this.state.jobDescription);
        if (this.state.referenceImgFile) {
            formdata.append("user[profile_picture]", this.state.referenceImgFile as File);
        }

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.createUserApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.createUserApiEndPoint}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            formdata
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.postApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    updateUserApiCall = () => {
        const headers = {
            token: this.state.token,
        };
        let userRole = "";
        if (this.state.selectedUserType === "Employee") {
            userRole = "employee";
        }
        else if (this.state.selectedUserType === "Candidate") {
            userRole = "candidate";
        }
        else if (this.state.selectedUserType === "HR SubAdmin") {
            userRole = "hr_sub_admin";
        }

        let formdata = new FormData();
        formdata.append("onbording_user[first_name]", this.state.firstName);
        formdata.append("onbording_user[last_name]", this.state.lastName);
        formdata.append("onbording_user[country_code]", this.state.selectedCountryCode);
        formdata.append("onbording_user[phone_number]", this.state.userContactNumber);
        formdata.append("onbording_user[staff_id]", this.state.staffId);
        formdata.append("onbording_user[date_of_birth]", this.state.userBirthDate || "");
        formdata.append("onbording_user[gender]", this.state.selectedGender);
        formdata.append("onbording_user[email]", this.state.userEmail);
        formdata.append("onbording_user[designation]", this.state.userDesignation);
        formdata.append("onbording_user[department_id]", this.state.selectedDepartment);
        formdata.append("onbording_user[company_name]", this.state.companyName);
        formdata.append("onbording_user[user_role]", userRole);
        formdata.append("onbording_user[job_description]", this.state.jobDescription);
        this.state.selectedCompetencies.forEach(value => {
            formdata.append("onbording_user[competency_ids][]", value);
        })
        if (this.state.referenceImgFile) {
            formdata.append("onbording_user[profile_picture]", this.state.referenceImgFile as File);
        }

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.updateUserApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.updateUserApiEndPoint}/${this.state.userId}/${configJSON.updateUserApiText}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            formdata
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.patchApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    getUserProfileApiCall = () => {
        const headers = {
            "token": this.state.token
        };
        const getViewUserMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getUserProfileApiCallId = getViewUserMsg.messageId;
        getViewUserMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        getViewUserMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.viewUserApiEndPoint}/${this.state.userId}`
        );
        getViewUserMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getApiMethodType
        );
        runEngine.sendMessage(getViewUserMsg.id, getViewUserMsg);
    }

    getMaxDateOfBirthDate = () => {
        const today = new Date();
        const year = today.getFullYear() - 18;
        const month = String(today.getMonth() + 1).padStart(2, '0');
        const day = String(today.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        const acceptedFormats = ['image/jpeg', 'image/png'];
        const maxFileSize = 5 * 1024 * 1024;

        if (file) {
            if (acceptedFormats.includes(file.type) && file.size < maxFileSize) {
                const fileReader = new FileReader();

                fileReader.onload = () => {
                    this.setState({
                        referenceImage: fileReader.result as string,
                        referenceImageError: '',
                        referenceImgFile: file
                    });
                };

                fileReader.readAsDataURL(file);
            } else {
                if (!acceptedFormats.includes(file.type)) {
                    this.setState({
                        referenceImageError: this.props.i18n?.t("allUsers.fileErrorMsg").toString() || ""
                    });
                }
                if (file.size > maxFileSize) {
                    this.setState({
                        referenceImageError: this.props.i18n?.t("allUsers.fileMbError").toString() || ""
                    });
                }
            }
        }
    };

    handleFirstName = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({ firstName: event.target.value }, () => { this.firstNameValidation() })
    }

    handleLastName = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({ lastName: event.target.value }, () => { this.lastNameValidation() })
    }

    handleCountryCodeChange = (event: SelectChangeEvent<string>) => {
        this.setState({ selectedCountryCode: event.target.value });
    };

    handleUserEmailChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({ userEmail: event.target.value },
            () => {
                this.validationEmail()
            })
    }

    handlePhoneNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ userContactNumber: event.target.value },
            () => {
                this.phoneNumberValidation()
            })
    }

    handleStaffIdChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ 
            staffId: event.target.value 
        }, () => { 
            this.staffIdValidation()
        })
    }

    handleBirthDateChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({ 
            userBirthDate: event.target.value
        })
    }

    handleGenderChange = (event: SelectChangeEvent<string>) => {
        this.setState({ 
            selectedGender: event.target.value
        });
    }

    handleDesignationchange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({ userDesignation: event.target.value })
    }

    handleDepartmentChange = (event: SelectChangeEvent<string>) => {
        this.setState({ 
            selectedDepartment: event.target.value
        });
    }

    handleCompanyNameChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({ 
            companyName: event.target.value
        })
    }

    handleUserChange = (event: SelectChangeEvent<string>) => {
        this.setState({ 
            selectedUserType: event.target.value
        });
    }

    handleCompetenciesChange = (event: SelectChangeEvent<string[]>) => {
        const { value } = event.target;
        if (value[0] === "LoadMore") {
            return false
        } else {
            this.setState({
                selectedCompetencies: typeof value === 'string' ? value.split(',') : value,
            });
        }
    };

    LoadMoreApicall = () => {
        this.setState({
            Page: this.state.Page + 1,
        }, () => {
            this.getCompetencyApiCall()
        })
    }

    handleJobDescriptionChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({ jobDescription: event.target.value.length > 1000 ? this.state.jobDescription : event.target.value })
    }

    backToDashBoard = () => {
        const navigateMsg = new Message(getName(MessageEnum.NavigationMessage));
        navigateMsg.addData(getName(MessageEnum.NavigationTargetMessage), "AllUsersDashboard");
        navigateMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(navigateMsg);
    }
    navigateToViewUserProfile = () => {
        const navigateMsg = new Message(getName(MessageEnum.NavigationMessage));
        navigateMsg.addData(getName(MessageEnum.NavigationTargetMessage), "ViewUserProfile");
        navigateMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(navigateMsg);
    }
    // Customizable Area End

}