export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const imgVisbility = require("../assets/visibility.svg");
export const imgVisbilityOff = require("../assets/visibilityOff.svg");
export const backArrow = require("../assets/backArrow.svg");
export const uploadImage = require("../assets/uploadImage.svg");
export const more = require("../assets/more.svg");
export const uploadedFile = require("../assets/uploadedFile.png");
export const emptyDashboardImg = require("../assets/emptyDashboardImg.png");
export const successImg = require("../assets/successImg.png");
export const calendar = require("../assets/calendar.svg");
export const dropdownImg = require("../assets/dropdownImg.png");
export const selectCompArrow = require("../assets/selectCompArrow.png");
export const deleteComp = require("../assets/deleteComp.png");
export const UserIcon = require("../assets/UserIcon.png");
export const CheckIcon = require("../assets/CheckIcon.png");
export const arrow_Left = require("../assets/arrow_Left.svg");
export const UserSmallIcon = require("../assets/UserSmallIcon.png");
export const ListIcon = require("../assets/ListIcon.png");
export const selectedCheckbox = require("../assets/selectedCheckbox.png");
export const blankCheckbox = require("../assets/blankCheckbox.png");
export const editQuestion = require("../assets/editQuestion.png");
export const deleteQuestion = require("../assets/deleteQuestion.png");
export const DeleteIcon = require("../assets/delete.png");
