import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { SelectChangeEvent } from '@mui/material/Select';
import { removeStorageData, getStorageData, setStorageData } from "framework/src/Utilities";
import { i18n } from "i18next";
interface AllUsersData {
    id: string;
    attributes: {
        activated: boolean;
        country_code: string;
        email: string;
        first_name: string;
        full_phone_number: string;
        last_name: string;
        phone_number: string;
        staff_id: string;
        date_of_birth: string;
        gender: string;
        designation: string;
        profile_picture: string;
        department: {
            id: string;
            attributes: {
                id: string;
                name: string;
            }
        }
    }
}
interface AllUsersDataResponse {
    errors: string;
    message: string;
    data: AllUsersData[];
    users_count: UsersCount;
    meta: PageCount;
}

interface ExportAllResponse {
    errors?: string;
    message?: string;
    download_url?: string;
}


interface PageCount {
    total_pages: number;
    current_page: number;
    total_records: number;
    prev_page: null;
    next_page: number;

}
interface UsersCount {
    all_users_count?: number;
    candidate_count?: number;
    employee_count?: number;
    hr_sub_admin_count?: number;
}

interface ShowCompanyData {
    error?: string;
    message?: string;
    organisation_details?: {
        data?: {
            id: string;
            type: string;
            attributes: {
                company_name: string;
            }
        }
    }
}
interface UserStatus {
    errors?: string;
    message?: string;
    account_id?: string;
    activated?: boolean;
}
interface DeleteUser {
    errors?: string;
    message?: string;
}

// Customizable Area End

export const configJSON = require("./config");
export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    i18n?: i18n;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    token: string;
    errorMsg: string;
    loader: boolean;
    selectedLanguage: string;
    selectedTab: string;
    selectedFilter: string;
    userFilter: {[key: string]: string };
    selectedStatus: string;
    userStatus: {[key: string]: string };
    userStatusData: UserStatus;
    userData: AllUsersData[];
    filterAndSearchData: AllUsersData[];
    allUsersCount: UsersCount;
    page: number;
    perPage: number;
    totalpage: number;
    companyName: string;
    opencompanyDialog: boolean;
    anchorEl: HTMLElement | null;
    selectedRowId: string | null;
    deleteUser: DeleteUser;
    searchText: string;
    filterAndSearch: boolean;
    export: boolean;
    exportAllData: ExportAllResponse;
    showExportDialog: boolean;
    exportProgress: number
    // Customizable Area End

}

interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class AllUsersDashboardController extends BlockComponent<
    Props,
    S,
    SS
> {

    // Customizable Area Start
    listAllUsersApiCallId: string = "";
    getCompanyApiCallId: string = "";
    activeDeactiveApiCallId: string = "";
    deleteUserApiCallId: string = "";
    filterAndSearchApiCallId: string = "";
    exportAllApiCallId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.CountryCodeMessage)
        ];

        this.state = {
            token: "",
            errorMsg: "",
            loader: false,
            selectedLanguage: "",
            selectedTab: "candidate",
            selectedFilter: "",
            userFilter: {},
            selectedStatus: "",
            userStatus: {},
            userStatusData: {},
            userData: [],
            filterAndSearchData: [],
            allUsersCount: {},
            page: 1,
            perPage: 10,
            totalpage: 0,
            companyName: "",
            opencompanyDialog: false,
            anchorEl: null,
            selectedRowId: null,
            deleteUser: {},
            searchText: "",
            filterAndSearch: false,
            export: false,
            exportAllData: {},
            showExportDialog: false,
            exportProgress: 0
        };

        // Customizable Area End
        runEngine.attachBuildingBlock(this, this.subScribedMessages);
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (apiRequestCallId === this.listAllUsersApiCallId) {
                this.listAllUsersApiResponse(responseJson)
            }
            if (apiRequestCallId === this.getCompanyApiCallId) {
                this.companyApiResponse(responseJson)
            }
            if (apiRequestCallId === this.activeDeactiveApiCallId) {
                this.activeDeactiveApiResponse(responseJson)
            }
            if (apiRequestCallId === this.deleteUserApiCallId) {
                this.deleteUserApiResponse(responseJson)
            }
            if (apiRequestCallId === this.filterAndSearchApiCallId) {
                this.filterAndSearchApiResponse(responseJson)
            }
            if (apiRequestCallId === this.exportAllApiCallId) {
                this.exportAllApiResponse(responseJson)
            }
        }
        // Customizable Area End
    }

    // Customizable Area Start
    async componentDidMount() {
        super.componentDidMount();

        const language = await getStorageData("language") || "English";
        this.setState({ selectedLanguage: language });

        let filterArray: { [key: string]: string } = {};
        let statusArray: { [key: string]: string } = {};

        setTimeout(() => {
            if (this.props.i18n) {
                filterArray = {
                    "All Active Users": this.props.i18n.t("allUsers.allActiveUsers").toString(),
                    "All Inactive users": this.props.i18n.t("allUsers.allInAciveUsers").toString(),
                    "Sort by Name": this.props.i18n.t("allUsers.sortByName").toString()
                }

                statusArray = {
                    "Active": this.props.i18n.t("allUsers.active").toString(),
                    "InActive": this.props.i18n.t("allUsers.inActive").toString()
                }

                this.setState({
                    userFilter: filterArray,
                    userStatus: statusArray
                })
            }
        }, 0);

        const signInResponse = await getStorageData("signInResponse");
        const parsedSignInResponse = JSON.parse(signInResponse)
        this.setState({
            token: parsedSignInResponse.meta?.token,
            
        })
        this.getCompanyApiCall();
        this.listAllUsersApiCall();
    }

    listAllUsersApiResponse = (responseJson: AllUsersDataResponse) => {
        if (responseJson && !responseJson.errors) {
            this.setState({
                userData: responseJson.data,
                allUsersCount: responseJson.users_count,
                totalpage: responseJson.meta?.total_pages,
                loader: false
            })
        } else if (responseJson && responseJson.errors) {
            this.setState({
                userData: [],
                errorMsg: responseJson.errors,
                loader: false
            });
        }
    }

    filterAndSearchApiResponse = (responseJson: AllUsersDataResponse) => {
        if (responseJson && !responseJson.errors) {
            this.setState({
                userData: responseJson.data,
                totalpage: responseJson.meta?.total_pages,
                loader: false,
            })
        } else if (responseJson && responseJson.errors) {
            this.setState({
                errorMsg: responseJson.errors,
                loader: false,
            });
        }
    }

    exportAllApiResponse = (responseJson: ExportAllResponse) => {
        if (responseJson && !responseJson.errors) {
            if (responseJson.download_url) {
                const link = document.createElement('a');
                link.href = responseJson.download_url;
                link.download = 'export.csv';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }

            this.setState({
                exportAllData: responseJson,
                loader: false,
                exportProgress: 100,
                export: false, showExportDialog: false
            })
        } else if (responseJson && responseJson.errors) {
            this.setState({
                errorMsg: responseJson.errors,
                loader: false,
                showExportDialog: false
            });
        }
    }

    deleteUserApiResponse = (responseJson: DeleteUser) => {
        if (responseJson && !responseJson.errors) {
            this.setState({
                deleteUser: responseJson
            }, () => {
                this.listAllUsersApiCall()
            })
        } else if (responseJson && responseJson.errors) {
            this.setState({
                errorMsg: responseJson.errors
            });
        }
    }

    companyApiResponse = (responseJson: ShowCompanyData) => {
        if (responseJson && !responseJson.error) {
            this.setState({ companyName: responseJson.organisation_details?.data?.attributes.company_name || "" })
        } else if (responseJson && responseJson.error) {
            this.setState({
                errorMsg: responseJson.error
            });
        }
    }

    activeDeactiveApiResponse = (responseJson: UserStatus) => {
        if (responseJson && !responseJson.errors) {
            this.setState({ userStatusData: responseJson }, () => {
                this.listAllUsersApiCall();
            })
        } else if (responseJson && responseJson.errors) {
            this.setState({
                errorMsg: responseJson.errors,
                loader: false
            });
        }
    }

    getCompanyApiCall = () => {
        const headers = {
            "token": this.state.token
        };
        const getMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getCompanyApiCallId = getMsg.messageId;
        getMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.showCompanyApiEndPoint}`
        );
        getMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        getMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getApiMethodType
        );
        runEngine.sendMessage(getMsg.id, getMsg);
    }

    listAllUsersApiCall = () => {
        this.setState({ loader: true })
        const headers = {
            "token": this.state.token
        };
        const getAllUsersMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.listAllUsersApiCallId = getAllUsersMsg.messageId;
        getAllUsersMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        getAllUsersMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.listAllUsersApiEndPoint}?user_role=${this.state.selectedTab}&page=${this.state.page}&per_page=${this.state.perPage}`
        );
        getAllUsersMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getApiMethodType
        );
        runEngine.sendMessage(getAllUsersMsg.id, getAllUsersMsg);
    }

    filterAndSearchApiCall = () => {
        if (this.state.export) {
            this.setState({ filterAndSearch: true, showExportDialog: true })
        } else {
            this.setState({ loader: true, filterAndSearch: true })
        }
        const headers = {
            "token": this.state.token
        };

        let queryParam = "";

        if (this.state.searchText) {
            queryParam += `search=${this.state.searchText}&`
        }
        if (this.state.selectedFilter === "All Active Users") {
            queryParam += `all_active_user=true&`
        }
        if (this.state.selectedFilter === "All Inactive users") {
            queryParam += `all_inactive_user=true&`
        }
        if (this.state.selectedFilter === "Sort by Name") {
            queryParam += `sort_by_name=true&`
        }
        if (this.state.export) {
            queryParam += `export=${this.state.export}&`
        }

        const getAllUsersMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        if (this.state.export) {
            this.exportAllApiCallId = getAllUsersMsg.messageId;
        } else {
            this.filterAndSearchApiCallId = getAllUsersMsg.messageId;
        }
        getAllUsersMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        getAllUsersMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.filterAndSearchApiEndPoint}?user_role=${this.state.selectedTab}&page=${this.state.page}&per_page=${this.state.perPage}&${queryParam}`
        );
        getAllUsersMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getApiMethodType
        );
        runEngine.sendMessage(getAllUsersMsg.id, getAllUsersMsg);
    }

    activeDeactiveUserApiCall = (id: string, status: boolean) => {
        const headers = {
            "token": this.state.token
        };
        const getUserStatusMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.activeDeactiveApiCallId = getUserStatusMsg.messageId;
        getUserStatusMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        getUserStatusMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.userStatusApiEndPoint}/${id}/${configJSON.changeStatus}?status=${status}`
        );
        getUserStatusMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.patchApiMethodType
        );
        runEngine.sendMessage(getUserStatusMsg.id, getUserStatusMsg);
    }

    handleExportAll = () => {
        this.setState({ export: true, showExportDialog: true, exportProgress: 0 }, () => {
            setTimeout(() => this.filterAndSearchApiCall(), 5000);
            this.incrementProgress();
        });
    };

    incrementProgress = () => {
        const progressInterval = setInterval(() => {
            if (this.state.exportProgress >= 90) {
                clearInterval(progressInterval);
            }

            this.setState({ exportProgress: Math.min(this.state.exportProgress + 10, 90) });
        }, 500);
    }

    handleCloseExportDialog = () => {
        this.setState({ showExportDialog: false })
    }

    handleSearch = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const searchValue = event.target.value;
        this.setState({ searchText: searchValue });
    };

    handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>): void => {
        if (event.key === 'Enter') {
            this.filterAndSearchApiCall();
        }
    };

    handleSearchIcon = () => {
        this.filterAndSearchApiCall();
    }

    handleMenuOpen = (event: React.MouseEvent<HTMLElement>, rowId: string) => {
        this.setState({
            anchorEl: event.currentTarget,
            selectedRowId: rowId
        });
    };

    handleMenuClose = () => {
        this.setState({
            anchorEl: null,
            selectedRowId: null
        });
    };

    handleEdit = async (rowId: string) => {
        await setStorageData("userId", JSON.stringify(rowId))

        const navigateMsg = new Message(getName(MessageEnum.NavigationMessage));
        navigateMsg.addData(getName(MessageEnum.NavigationTargetMessage), "UserCreation");
        navigateMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(navigateMsg);
        this.handleMenuClose();
    };

    handleView = async (rowId: string) => {
        await setStorageData("userId", JSON.stringify(rowId))

        const navigateMsg = new Message(getName(MessageEnum.NavigationMessage));
        navigateMsg.addData(getName(MessageEnum.NavigationTargetMessage), "ViewUserProfile");
        navigateMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(navigateMsg);

        this.handleMenuClose();
    };

    deleteUserApiCall = (id: string) => {
        const headers = {
            "token": this.state.token
        };
        const getUserStatusMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.deleteUserApiCallId = getUserStatusMsg.messageId;
        getUserStatusMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        getUserStatusMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.deleteUserApiEndPoint}/${id}`
        );
        getUserStatusMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.deleteApiMethodType
        );
        runEngine.sendMessage(getUserStatusMsg.id, getUserStatusMsg);

    }

    handleDelete = (rowId: string) => {
        this.deleteUserApiCall(rowId);
        this.handleMenuClose();
    };

    handlePageChange = (newPage: number) => {
        this.setState({ page: newPage }, () => {
            if (this.state.filterAndSearch) {
                this.filterAndSearchApiCall();
            } else {
                this.listAllUsersApiCall();
            }
        });
    };

    handleCloseCompanyDialog = () => {
        this.setState({ opencompanyDialog: false })
    }

    handleTabClick = (tabName: string) => {
        if (tabName === "hrSubAdmin") {
            tabName = "hr_sub_admin";
        }
        this.setState({
            selectedTab: tabName,
            page: 1,
            filterAndSearch: false,
            searchText: "",
            selectedFilter: ""
        }, () => {
            this.listAllUsersApiCall();
        })
    }

    handleFilter = (event: SelectChangeEvent<string>) => {
        this.setState({ selectedFilter: event.target.value, page: 1 }, () => {
            this.filterAndSearchApiCall()
        });
    }

    handleStatus = (event: SelectChangeEvent<string>, id: string, status: boolean) => {
        this.activeDeactiveUserApiCall(id, event.target.value.toLowerCase() === "active");
    }

    navigateToCreateUser = async () => {
        await removeStorageData("userId")
        if (!this.state.companyName) {
            this.setState({ opencompanyDialog: true })
        } else {
            const navigateMsg = new Message(getName(MessageEnum.NavigationMessage));
            navigateMsg.addData(getName(MessageEnum.NavigationTargetMessage), "UserCreation");
            navigateMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
            this.send(navigateMsg);
        }
    }

    navigateToCreateCompany = () => {
        const navigateMsg = new Message(getName(MessageEnum.NavigationMessage));
        navigateMsg.addData(getName(MessageEnum.NavigationTargetMessage), "ViewCompanyDetails");
        navigateMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(navigateMsg);
    }

    // Customizable Area End

}