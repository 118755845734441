import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { SelectChangeEvent } from '@mui/material/Select';
import { removeStorageData, getStorageData, setStorageData } from "framework/src/Utilities";
import { i18n } from "i18next";

interface CompetencyResponse {
    message: string;
    error: string;
    competencies: Competencies;
    download_url?: string;
    meta?: {
        total_pages: number;
        current_page: number;
        total_records: number;
        prev_page: number;
    }
}

interface MultiDeleteResponse {
    message: string;
    error: string;
}

interface Competencies {
    data?: {
        id: string;
        type: string;
        attributes: {
            id: number;
            job_description_id: number | null;
            job_title: string;
            description: string;
            status: boolean;
            created_at: string;
            updated_at: string;
            file_url: string | null;
            job_family: string;
            target_level: string;
            competency_type_id: number;
            competency_type: string;
            file_filename: string | null;
            file_size: number | null;
            competency_name: string;
            chatgpt_response: {
                title: string;
                description: string;
            };
        };
    }[];
}
interface DeleteCompetency {
    message?: string;
    error?: string;
}
// Customizable Area End

export const configJSON = require("./config");
export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    i18n?: i18n
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    token: string;
    selectedLanguage: string;
    errorMsg: string;
    selectedDate: null | [Date, Date];
    tempDate: null | [Date, Date];
    calendarOpen: boolean;
    searchText: string;
    selectedStatus: string;
    competencyStatus: Array<{
        name: string,
        value: string,
    }>;
    anchorEl: HTMLElement | null;
    selectedRowId: string | null;
    checkedRow: boolean;
    selectedRowIds: string[];
    createCompDropdown: boolean;
    competencyData: Competencies;
    dataLoaded: boolean;
    loader: boolean;
    deleteCompetency: DeleteCompetency;
    perPage: number;
    page: number;
    totalPage: number;
    exportAll: boolean;
    openMultiDelete: boolean;
    singleDeleteId: string;
    // Customizable Area End

}

interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class CompetencyDashboardController extends BlockComponent<
    Props,
    S,
    SS
> {

    // Customizable Area Start
    listCompetencyApiCallId: string = "";
    deleteCompetencyApiCallId: string = "";
    updateStatusApiCallId: string = "";
    multiDeleteApiCallId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.CountryCodeMessage)
        ];

        this.state = {
            token: "",
            selectedLanguage: "",
            errorMsg: "",
            selectedDate: null,
            tempDate: null,
            calendarOpen: false,
            searchText: "",
            selectedStatus: "",
            competencyStatus: [{
                name:this.TraslationFuncation("CompetencyDashboard.Active"),
                value: "Active",
            }, {
                name:this.TraslationFuncation("CompetencyDashboard.Inactive"),
                value: "Inactive",
            }],
            anchorEl: null,
            selectedRowId: null,
            checkedRow: false,
            selectedRowIds: [],
            createCompDropdown: false,
            competencyData: {},
            dataLoaded: false,
            loader: false,
            deleteCompetency: {},
            perPage: 10,
            page: 1,
            totalPage: 0,
            exportAll: false,
            openMultiDelete: false,
            singleDeleteId: ""
        };

        // Customizable Area End
        runEngine.attachBuildingBlock(this, this.subScribedMessages);
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (apiRequestCallId === this.listCompetencyApiCallId) {
                this.listCompetencyApiResponse(responseJson)
            }
            if (apiRequestCallId === this.deleteCompetencyApiCallId) {
                this.deleteCompetencyApiResponse(responseJson)
            }
            if (apiRequestCallId === this.updateStatusApiCallId) {
                this.updateStatusApiResponse(responseJson)
            }
            if (apiRequestCallId === this.multiDeleteApiCallId) {
                this.multiDeleteApiResponse(responseJson)
            }
        }
        // Customizable Area End
    }

    // Customizable Area Start
    async componentDidMount() {
        super.componentDidMount();

        const language = await getStorageData("language") || "English";
        this.setState({ selectedLanguage: language });

        const signInResponse = await getStorageData("signInResponse");
        const parsedSignInResponse = JSON.parse(signInResponse)
        this.setState({
            token: parsedSignInResponse.meta?.token,
        })


        this.listCompetencyApiCall();
    }

    listCompetencyApiResponse = (responseJson: CompetencyResponse) => {
        if (responseJson && !responseJson.error) {
            if (responseJson.download_url) {
                this.setState({
                    loader: false
                })
                const link = document.createElement('a');
                link.href = responseJson.download_url;
                link.download = 'export.csv';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            } else {
                this.setState({
                    competencyData: responseJson.competencies,
                    dataLoaded: this.state.dataLoaded ||
                        (responseJson.competencies.data && responseJson.competencies.data?.length > 0) ? true : false,
                    totalPage: responseJson.meta?.total_pages || 0,
                    loader: false
                })
            }
        } else if (responseJson && responseJson.error) {
            this.setState({
                errorMsg: responseJson.error,
                loader: false
            });
        }
    }

    updateStatusApiResponse = (responseJson: CompetencyResponse) => {
        if (responseJson && !responseJson.error) {
            this.listCompetencyApiCall();
        } else if (responseJson && responseJson.error) {
            this.setState({
                errorMsg: responseJson.error,
                loader: false
            });
        }
    }
    multiDeleteApiResponse = (responseJson: MultiDeleteResponse) => {
        if (responseJson && !responseJson.error) {
            this.setState({ selectedRowIds: [], openMultiDelete: false })
            this.listCompetencyApiCall();
        } else if (responseJson && responseJson.error) {
            this.setState({
                errorMsg: responseJson.error,
                loader: false
            });
        }
    }

    deleteCompetencyApiResponse = (responseJson: DeleteCompetency) => {
        if (responseJson && !responseJson.error) {
            this.setState({
                deleteCompetency: responseJson,
                openMultiDelete: false
            }, () => {
                this.listCompetencyApiCall();
            })
        } else if (responseJson && responseJson.error) {
            this.setState({
                errorMsg: responseJson.error,
            });
        }
    }

    listCompetencyApiCall = (exportAll?: boolean) => {
        this.setState({ loader: true })
        const headers = {
            "token": this.state.token
        };

        let queryParam = "";

        if (this.state.searchText) {
            queryParam += `search=${this.state.searchText}&`
        }

        if (this.state.selectedDate) {
            queryParam += `start_date=${this.formatToYYYYMMDD(this.state.selectedDate[0])}&`;
            queryParam += `end_date=${this.formatToYYYYMMDD(this.state.selectedDate[1])}&`;
        }
        if (exportAll) {
            queryParam += `export=${exportAll}&`
        }

        const competencyMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.listCompetencyApiCallId = competencyMsg.messageId;
        competencyMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        competencyMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.viewCompetencyApiEndPoint}?${queryParam}&page=${this.state.page}&per_page=${this.state.perPage}`
        );
        competencyMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getApiMethodType
        );
        runEngine.sendMessage(competencyMsg.id, competencyMsg);
    }

    deleteCompetencyApiCall = (id: string) => {
        const headers = {
            "token": this.state.token
        };
        const competencyMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.deleteCompetencyApiCallId = competencyMsg.messageId;
        competencyMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        competencyMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.deleteCompetencyApiEndPoint}/${id}`
        );
        competencyMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.deleteApiMethodType
        );
        runEngine.sendMessage(competencyMsg.id, competencyMsg);
    }

    updateStatusApiCall = (id: string, status: boolean) => {
        const headers = {
            token: this.state.token,
        };

        let formdata = new FormData();
        formdata.append("competency[status]", status.toString());

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.updateStatusApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.updateCompetencyApiEndPoint}/${id}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            formdata
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.patchApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    multiDeleteApiCall = () => {
        const headers = {
            token: this.state.token,
            "Content-Type": "application/json"
        };

        const raw = JSON.stringify({
            "competency_ids": this.state.selectedRowIds
        })


        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.multiDeleteApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.multiDeleteApiEndPoint}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            raw
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.deleteApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    closeCompDropdown = () => {
        this.setState({ createCompDropdown: false });
    };

    handleCloseDeleteComp = () => {
        this.setState({ openMultiDelete: false, selectedRowIds: [] })
    }

    handlePageChange = (newPage: number) => {
        this.setState({ page: newPage }, () => {
            this.listCompetencyApiCall();
        });
    };


    toggleDropdown = () => {
        this.setState((prevState) => ({
            createCompDropdown: !prevState.createCompDropdown,
        }));
    };

    handleCheckboxChange = (
        event: React.ChangeEvent<HTMLInputElement>,
        id: string
    ): void => {
        this.setState((prevState) => {
            const { selectedRowIds } = prevState;
            if (selectedRowIds.includes(id)) {
                return { selectedRowIds: selectedRowIds.filter((rowId) => rowId !== id) };
            } else {
                return { selectedRowIds: [...selectedRowIds, id] };
            }
        });
    };

    handleCompetencyStatus = (event: SelectChangeEvent<string>, id: string, status: boolean) => {
        this.updateStatusApiCall(id, event.target.value.toLowerCase() === "active");
    }

    handleMenuOpen = (event: React.MouseEvent<HTMLElement>, rowId: string) => {
        this.setState({
            anchorEl: event.currentTarget,
            selectedRowId: rowId
        });
    };

    handleMenuClose = () => {
        this.setState({
            anchorEl: null,
            selectedRowId: null
        });
    };

    handleView = async (rowId: string) => {
        await setStorageData("competencyId", JSON.stringify(rowId))

        const navigateMsg = new Message(getName(MessageEnum.NavigationMessage));
        navigateMsg.addData(getName(MessageEnum.NavigationTargetMessage), "ViewCompetency");
        navigateMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(navigateMsg);

        this.handleMenuClose();
    };

    handleEdit = async (rowId: string) => {
        await setStorageData("competencyId", JSON.stringify(rowId))

        const navigateMsg = new Message(getName(MessageEnum.NavigationMessage));
        navigateMsg.addData(getName(MessageEnum.NavigationTargetMessage), "Cfchatgptcompetencymapgeneration");
        navigateMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(navigateMsg);
        this.handleMenuClose();
    };

    handleDelete = (rowId: string) => {
        this.deleteCompetencyApiCall(rowId);
        this.handleMenuClose();
    };

    openMultiDeletedialog = (id: string) => {
        this.setState({ openMultiDelete: true, singleDeleteId: id }, () => {
            this.handleMenuClose();
        })
    }

    handleSearch = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const searchValue = event.target.value;
        this.setState({ searchText: searchValue, page: 1 });
    };

    handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>): void => {
        if (event.key === 'Enter') {
            this.listCompetencyApiCall();
        }
    };

    handleExportAll = () => {
        this.listCompetencyApiCall(true);
    }

    handleCondition = (condition: any, truePart: any, falsePart: any) => {
        return condition ? truePart : falsePart
    }

    openCalendar = () => {
        this.setState((prevState) => ({ calendarOpen: !prevState.calendarOpen }));
    };

    handleCalendarCancelBtn = () => {
        this.setState({ tempDate: null, selectedDate: null, calendarOpen: false, page: 1 },
            () => { this.listCompetencyApiCall() }
        );
    };
    handleCalendarSaveBtn = () => {
        if (this.state.tempDate) {
            this.setState({
                selectedDate: this.state.tempDate,
                tempDate: null,
                calendarOpen: false,
                page: 1
            }, () => { this.listCompetencyApiCall() });
        }
    };

    formatToYYYYMMDD = (date: Date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };


    handleDateChange = (newDate: any) => {
        this.setState({ tempDate: newDate });
    };

    formatDateRange = (dateRange: any) => {
        if (Array.isArray(dateRange) && dateRange.length === 2) {
            const [startDate, endDate] = dateRange;
            return `${startDate.toLocaleDateString()} - ${endDate.toLocaleDateString()}`;
        }
        return this.TraslationFuncation("CompetencyDashboard.SelectStartDateEndDate");
    };

    navigateToCreateComp = async () => {
        await removeStorageData("competencyId");
        const navigateMsg = new Message(getName(MessageEnum.NavigationMessage));
        navigateMsg.addData(getName(MessageEnum.NavigationTargetMessage), "Cfchatgptcompetencymapgeneration");
        navigateMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(navigateMsg);
    }

    navigateToDictionary = () => {
        const navigateMsg = new Message(getName(MessageEnum.NavigationMessage));
        navigateMsg.addData(getName(MessageEnum.NavigationTargetMessage), "CompetencyDictionaryDashboard");
        navigateMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(navigateMsg);
    }

    TraslationFuncation = (key: string) => {
        if (this.props.i18n) {
            return this.props.i18n.t(key);
        }else{
            return key
        }
    }

    // Customizable Area End
}