import React from "react";
// Customizable Area Start
import {
    Box,
    Grid,
    styled,
    Typography,
    Card,
    CardContent,
    Divider,
    Input,
    Select,
    InputBase,
    MenuItem,
    Button,
    CircularProgress,
    LinearProgress,
    Modal
} from "@mui/material";
import { DashboardHeaderWeb as DashboardHeader } from "../../dashboard/src/DashboardHeader.web";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { backArrow, checkbox, selectedCheckbox, blankCheckbox, progress, ProgressSlider, right } from "./assets";

import GenerateAssessmentController, {
    Props,
} from "./GenerateAssessmentController";
import { ErrorMessage, Form, Formik } from "formik";
import { withTranslation } from "react-i18next";
import CustomPopup from "../../../../packages/components/src/CustomSuccessModel.web";
// Customizable Area End

export default class GenerateAssessment extends GenerateAssessmentController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    assessmentTitle = (
        handleBlur: {
            (e: React.FocusEvent<React.FocusEventHandler<HTMLInputElement>>): void;
            <T = React.FocusEventHandler<HTMLInputElement>>(fieldOrEvent: T): T extends string ? (e: React.FocusEventHandler<HTMLInputElement>) => void : void;
        },
        setFieldValue: (field: string, value: string, shouldValidate?: boolean) => void
    ) => {
        return (
            <Wrapper>
                <Box className="errorStyleMainbox">
                    <Typography className="assessmentLabel">
                        1. {this.TraslationFuncation("GenerateAssessment.AssessmentTitle")} <span>*</span>
                    </Typography>
                    <ErrorMessage name="AssessmentTitle" component="div" className="errorStyle" />
                    {
                        this.state.TitleErrorMessage !== "" &&
                        <Typography className="errorStyle">
                            {this.state.TitleErrorMessage}
                        </Typography>
                    }
                </Box>

                <Input
                    data-test-id="assessment_title"
                    className="assessmentInputField"
                    disableUnderline
                    onBlur={handleBlur}
                    placeholder={this.TraslationFuncation("GenerateAssessment.EnterTheAssessmentTitle")}
                    value={this.state.assessmentTitle}
                    onChange={(e) => {
                        this.assessmentTitleChange(e, setFieldValue, "AssessmentTitle")
                    }}
                    autoComplete="off"
                />
            </Wrapper>
        )
    }

    assessmentJobRole = (
        handleBlur: {
            (e: React.FocusEvent<React.FocusEventHandler<HTMLInputElement>>): void;
            <T = React.FocusEventHandler<HTMLInputElement>>(fieldOrEvent: T): T extends string ? (e: React.FocusEventHandler<HTMLInputElement>) => void : void;
        },
        setFieldValue: (field: string, value: string | number | number[] | string[], shouldValidate?: boolean) => void
    ) => {
        return (
            <Wrapper>
                <Box className="errorStyleMainbox">
                    <Typography className="assessmentLabel">
                        2. {this.TraslationFuncation("GenerateAssessment.SelectJobRole")}  <span>*</span>
                    </Typography>
                    <ErrorMessage name="AssessmentJobRole" component="div" className="errorStyle" />
                </Box>
                <Select
                    data-test-id="assessment_job_role"
                    className="assessmentDropDown"
                    inputProps={{
                        icon: {
                            left: 0,
                        },
                    }}
                    variant="outlined"
                    displayEmpty
                    onBlur={handleBlur}
                    value={this.state.selectedJobRole}
                    onChange={(e) => {
                        this.handleJobRoleChange(e, setFieldValue, "AssessmentJobRole")
                        this.GetJobCompetencies(`${e.target.value}`)
                    }}
                    renderValue={
                        this.state.selectedJobRole
                            ? undefined
                            : () => (
                                <div className={" dropDownplaceHolder " + ` ${this.TrueOrFalseConditionPass(this.state.selectedLanguage.toLowerCase() === "english","EnglishTextAlignment","ArabicTextAlignment")} `} >
                                    {this.TraslationFuncation("GenerateAssessment.ChooseJobRole")}
                                </div>
                            )
                    }
                    IconComponent={KeyboardArrowDownIcon}
                    input={<InputBase />}
                >
                    {this.state.jobRole.map((option) => (
                        <MenuItem key={option.id} value={option.id}
                            sx={{
                                fontFamily: "Urbanist",
                                fontSize: "16px",
                                fontWeight: 400,
                                lineHeight: "22px",
                                margin: "4px",
                                borderRadius: "8px"
                            }}>
                            {option.name}
                        </MenuItem>
                    ))}
                </Select>
            </Wrapper>
        )
    }

    assessmentCompetencies = (
        handleBlur: {
            (e: React.FocusEvent<React.FocusEventHandler<HTMLInputElement>>): void;
            <T = React.FocusEventHandler<HTMLInputElement>>(fieldOrEvent: T): T extends string ? (e: React.FocusEventHandler<HTMLInputElement>) => void : void;
        },
        setFieldValue: (field: string, value: string | number | number[] | string[], shouldValidate?: boolean) => void
    ) => {
        return (
            <Wrapper>
                <Box className="errorStyleMainbox">
                    <Typography className="assessmentLabel">
                        3. {this.TraslationFuncation("GenerateAssessment.Competency")} <span>*</span>
                    </Typography>
                    <ErrorMessage name="AssessmentCompetencies" component="div" className="errorStyle" />
                </Box>
                <Select
                    multiple
                    data-test-id="select_competencies"
                    className="assessmentDropDown"
                    inputProps={{
                        icon: {
                            left: 0,
                        },
                    }}
                    displayEmpty
                    variant="outlined"
                    value={this.state.selectedCompetencies}
                    onChange={(e) => {
                        this.handleCompetenciesChange(e, setFieldValue, "AssessmentCompetencies")
                    }}
                    renderValue={(selected) => {
                        const RenderNames = selected.map((item) => {
                            const selectedItem = this.state.competencies.find((competency) => competency.id === item);
                            return selectedItem ? selectedItem.name : '';
                        });
                        return selected.length > 0 ? RenderNames.join(', ') : (
                            <div className={" dropDownplaceHolder " + ` ${this.TrueOrFalseConditionPass(this.state.selectedLanguage.toLowerCase() === "english","EnglishTextAlignment","ArabicTextAlignment")} `}>
                                {this.TraslationFuncation("GenerateAssessment.ChooseCompetencies")}
                            </div>
                        )
                    }}
                    onBlur={handleBlur}
                    IconComponent={KeyboardArrowDownIcon}
                    input={<InputBase />}
                >
                    {this.state.competencies.map((item) => {
                        const selected = this.state.selectedCompetencies.includes(item.id);

                        return (
                            <MenuItem key={item.id} value={item.id}
                                data-test-id={`competency_${item.name}`}
                                sx={{
                                    fontFamily: "Urbanist",
                                    fontSize: "16px",
                                    color: "#0F172A",
                                    borderRadius: "8px",
                                    padding: "10px",
                                    fontWeight: 400,
                                    lineHeight: "22px",
                                    margin: "16px",
                                    '&.Mui-selected': {
                                        backgroundColor: "#E1F5F9",
                                        border: "1px solid #044352",
                                        borderRadius: "8px"
                                    }
                                }}
                                onClick={() => this.handleCompetencyCheckbox(item.id, setFieldValue, "AssessmentCompetencies")}
                            >
                                <img
                                    src={selected ?
                                        selectedCheckbox.default :
                                        checkbox.default
                                    }
                                    style={{ marginRight: "10px" }}
                                />
                                {item.name}
                            </MenuItem>
                        )
                    })}
                </Select>
            </Wrapper>
        )
    }

    assessmentDifficultyLevel = (
        setFieldValue: (field: string, value: string | number | number[] | string[], shouldValidate?: boolean) => void
    ) => {
        return (
            <Wrapper>
                <Box className="errorStyleMainbox">
                    <Typography className="assessmentLabel">
                        4. {this.TraslationFuncation("GenerateAssessment.DifficultyLevel")} <span>*</span>
                    </Typography>
                    <ErrorMessage name="AssessmentDifficultyLevel" component="div" className="errorStyle" />
                </Box>

                <Box sx={{ fontFamily: 'Urbanist' }}>
                    {this.state.difficultyLevel?.map((type) => {
                        const selected = this.state.selectedDifficultyLevel.includes(type.id);
                        return (
                            <Box
                                display="flex"
                                alignItems="center"
                                data-test-id={`difficulty_level_${type.name}`}
                                key={type.id}
                                mb={1}
                                className={selected ? "selectedAssOptionStyle" : "assOptionsStyle"}
                                sx={{ fontFamily: 'Urbanist' }}
                                onClick={() => this.assDifficultyLevelChange(type.id, setFieldValue, "AssessmentDifficultyLevel")}
                            >
                                <img
                                    src={selected ? selectedCheckbox.default : blankCheckbox.default}
                                    alt="checkbox"
                                    className="assCheckBoxStyle"
                                />
                                <Typography className={this.TrueOrFalseConditionPass(this.state.selectedLanguage.toLowerCase() === "english", " ", " TextMarginRight ")} >{type.name}</Typography>
                            </Box>
                        )
                    })}
                </Box>
            </Wrapper>
        )
    }

    assNoOfQuestion = (
        handleBlur: {
            (e: React.FocusEvent<React.FocusEventHandler<HTMLInputElement>>): void;
            <T = React.FocusEventHandler<HTMLInputElement>>(fieldOrEvent: T): T extends string ? (e: React.FocusEventHandler<HTMLInputElement>) => void : void;
        },
        setFieldValue: (field: string, value: string, shouldValidate?: boolean) => void
    ) => {
        return (
            <Wrapper>
                <Box className="errorStyleMainbox">
                    <Typography className="assessmentLabel">
                        5. {this.TraslationFuncation("GenerateAssessment.NumberOfQuestions")} <span>*</span>
                    </Typography>
                    <ErrorMessage name="AssNoOfQuestion" component="div" className="errorStyle" />
                </Box>

                <Input
                    data-test-id="no_of_question"
                    className="assessmentInputField"
                    disableUnderline
                    onBlur={handleBlur}
                    placeholder={this.TraslationFuncation("GenerateAssessment.EnterTheNumberOfQuestions")}
                    value={this.state.assNoOfQuestion}
                    onChange={(e) => {
                        this.assNoOfQuestionChange(e, setFieldValue, "AssNoOfQuestion")
                    }}
                    autoComplete="off"
                    inputProps={{
                        maxLength: 3,
                    }}
                />
            </Wrapper>
        )
    }

    timeAllocation = (
        handleBlur: {
            (e: React.FocusEvent<React.FocusEventHandler<HTMLInputElement>>): void;
            <T = React.FocusEventHandler<HTMLInputElement>>(fieldOrEvent: T): T extends string ? (e: React.FocusEventHandler<HTMLInputElement>) => void : void;
        },
        setFieldValue: (field: string, value: string | number | number[] | string[], shouldValidate?: boolean) => void
    ) => {
        return (
            <Wrapper>
                <Box className="errorStyleMainbox">
                    <Typography className="assessmentLabel">
                        6. {this.TraslationFuncation("GenerateAssessment.TimeAllocation")} <span>*</span>
                    </Typography>
                    <ErrorMessage name="TimeAllocation" component="div" className="errorStyle" />
                </Box>

                <Input
                    className="assessmentInputField"
                    data-test-id="time_allocation"
                    disableUnderline
                    onBlur={handleBlur}
                    placeholder={this.TraslationFuncation("GenerateAssessment.EnterTheTimeAllocation")}
                    value={this.state.timeAllocation}
                    onChange={(e) => {
                        this.timeAllocationChange(e, setFieldValue, "TimeAllocation")
                    }}
                    autoComplete="off"
                />
            </Wrapper>
        )
    }

    assessmentQuestionFormat = (
        setFieldValue: (field: string, value: string | number | number[] | string[], shouldValidate?: boolean) => void
    ) => {
        return (
            <Wrapper>
                <Box className="errorStyleMainbox">
                    <Typography className="assessmentLabel">
                        7. {this.TraslationFuncation("GenerateAssessment.QuestionFormat")}<span>*</span>
                    </Typography>
                    <ErrorMessage name="AssessmentQuestionFormat" component="div" className="errorStyle" />
                </Box>

                <Box sx={{ fontFamily: 'Urbanist' }}>
                    {this.state.assQuestionFormat.filter((Fitem) =>  {
                        return  Number(Fitem.id) !== 4
                    } )?.map((type) => {
                        const selected = this.state.selectedQuestionFormat.includes(type.id);
                        return (
                            <Box
                                mb={1}
                                display="flex"
                                alignItems="center"
                                data-test-id={`question_format_${type.name}`}
                                key={type.id}
                                className={selected ? "selectedAssOptionStyle" : "assOptionsStyle"}
                                sx={{ fontFamily: 'Urbanist' }}
                                onClick={() => this.assQuestionFormatChange(type.id, setFieldValue, "AssessmentQuestionFormat")}
                            >
                                <img
                                    src={selected ? selectedCheckbox.default : blankCheckbox.default}
                                    alt="checkbox"
                                    className="assCheckBoxStyle"
                                />
                                <Typography className={this.TrueOrFalseConditionPass(this.state.selectedLanguage.toLowerCase() === "english", " ", " TextMarginRight ")}>{type.name}</Typography>
                            </Box>
                        )
                    })}
                </Box>
            </Wrapper>
        )
    }

    assessmentFocusArea = (
        setFieldValue: (field: string, value: string | number | number[] | string[], shouldValidate?: boolean) => void
    ) => {
        return (
            <Wrapper>
                <Box className="errorStyleMainbox">
                    <Typography className="assessmentLabel">
                        8. {this.TraslationFuncation("GenerateAssessment.FocusAreas")} <span>*</span>
                    </Typography>
                    <ErrorMessage name="AssessmentFocusArea" component="div" className="errorStyle" />
                </Box>

                <Box sx={{ fontFamily: 'Urbanist' }}>
                    {this.state.assFocusArea?.map((type) => {
                        const selected = this.state.selectedFocusArea.includes(type.id);
                        return (
                            <Box
                                display="flex"
                                mb={1}
                                alignItems="center"
                                data-test-id={`focus_area_${type.name}`}
                                key={type.id}
                                className={selected ? "selectedAssOptionStyle" : "assOptionsStyle"}
                                sx={{ fontFamily: 'Urbanist' }}
                                onClick={() => this.assFocusAreaChange(type.id, setFieldValue, "AssessmentFocusArea")}
                            >
                                <img
                                    src={selected ? selectedCheckbox.default : blankCheckbox.default}
                                    alt="checkbox"
                                    className="assCheckBoxStyle"
                                />
                                <Typography className={this.TrueOrFalseConditionPass(this.state.selectedLanguage.toLowerCase() === "english", " ", " TextMarginRight ")}>{type.name}</Typography>
                            </Box>
                        )
                    })}
                </Box>
            </Wrapper>
        )
    }

    objectivesOfAssessment = (
        handleBlur: {
            (e: React.FocusEvent<React.FocusEventHandler<HTMLInputElement>>): void;
            <T = React.FocusEventHandler<HTMLInputElement>>(fieldOrEvent: T): T extends string ? (e: React.FocusEventHandler<HTMLInputElement>) => void : void;
        },
        setFieldValue: (field: string, value: string | number | number[] | string[], shouldValidate?: boolean) => void
    ) => {
        return (
            <Wrapper>
                <Box className="errorStyleMainbox">
                    <Typography className="assessmentLabel">
                        8. {this.TraslationFuncation("GenerateAssessment.AssessmentObjective")} <span>*</span>
                    </Typography>
                    <ErrorMessage name="ObjectivesOfAssessment" component="div" className="errorStyle" />
                </Box>
                <textarea
                    className="assObjectiveField"
                    data-test-id="assessment_objectives"
                    onBlur={handleBlur}
                    placeholder={this.TraslationFuncation("GenerateAssessment.DescribeTheObjectiveOfTheAssessment")}
                    value={this.state.assessmentObjectives}
                    onChange={(e) => { this.handleAssessmentObjective(e, setFieldValue, "ObjectivesOfAssessment") }}
                />
                <Box className="assObjectiveDesc">
                    <Typography></Typography>
                    <Typography className="assObjectiveLimit">{this.state.assessmentObjectives.length}/5000</Typography>
                </Box>
            </Wrapper>
        )
    }

    cancelAssessmentButton = () => {
        return (
            <Wrapper>
                <Button
                    data-test-id="cancel_assessment"
                    className="cancelAssBtn"
                    onClick={this.navigateToDashboard}
                >
                    <Typography className="cancelAssText">
                        {this.TraslationFuncation("GenerateAssessment.Cancel")}
                    </Typography>
                </Button>
            </Wrapper>
        )
    }

    generateAssessmentButton = () => {
        return (
            <Wrapper>
                <Button
                    className="generateAssBtn"
                    data-test-id="generate_assessment"
                    type="submit"
                >
                    <Typography className="generateAssText">
                        {this.TraslationFuncation("GenerateAssessment.GenerateAssessment")}
                    </Typography>
                </Button>
            </Wrapper>
        )
    }

    progressDataScreen = () => {
        return (
            <Grid container justifyContent={"center"} style={{ direction: "ltr" }} >
                <Grid item xs={11}>
                    <Grid container spacing={1} alignItems={"center"}>
                        <Grid item xs={1.5} sm={1} md={0.8} lg={0.5}  >
                            <Box data-test-id="back_to_dashboard" onClick={this.navigateToDashboard}
                                style={{
                                    width: "47px", height: "47px", border: "1px solid #ECECEC", borderRadius: "50%",
                                    display: "flex", alignItems: "center", justifyContent: "center"
                                }}
                                className={this.TrueOrFalseConditionPass(this.state.selectedLanguage.toLowerCase() === "english"," BackArrowForEnglish "," BackArrowForArabic ")}
                            >
                                <img src={backArrow.default}
                                    alt="Back Arrow"
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={10.5} sm={11} md={11.2} lg={11.5}>
                            <Typography className="jobTitle">
                                {this.TraslationFuncation("GenerateAssessment.GeneratingQuestions")}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={1} className={"jobMainHeaderGrid"}>
                        <Grid item xs={12} sm={12} md={11.2} lg={11.5}>
                            <Wrapper>
                                <Box className="progressMainContainer">
                                    <Box className="progressContainer">
                                        <Box className="progressImage">
                                            <img src={progress.default} />
                                        </Box>
                                        <Box className="linearProgressBar">
                                            <LinearProgress
                                                className="linearProgress"
                                                variant="determinate"
                                                value={this.state.ProgressValue}
                                            />
                                        </Box>
                                        <Box className="dotsProgress">
                                            <img src={ProgressSlider.default} className="progressDot" />
                                        </Box>
                                        <Box className="ProgressTextContain">
                                            <Typography className="progressTextRunning">
                                                {this.TraslationFuncation("GenerateAssessment.AnalyzingCompetencies")}
                                            </Typography>
                                        </Box>
                                        <Box className="ProgressCreationContent">
                                            <Typography className="progressContent1">
                                                {this.TraslationFuncation("GenerateAssessment.HangTightWereGeneratingYourQuestions")}
                                            </Typography>
                                            <Typography className="progressContent2">
                                                {this.TraslationFuncation("GenerateAssessment.ThisMayTakeAFewMomentsDependingOnTheComplexityAndNumberOfQuestions")}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Box>
                            </Wrapper>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        )
    }

    SuccesModel = () => {
        return (
            <Modal
                open={this.state.SuccessModel}
                onClose={this.NaviGateToViewScreen}>
                <CustomPopup
                    data-test-id="ProfileSuccessPopup"
                    // headingPopup={"Assessment Generated"}
                    // titlePopup={"Assessment Generated"}
                    // subTitlePopup={"Assessment Generated Successfully"}
                    headingPopup={this.TraslationFuncation("GenerateAssessment.SuccessfullyGenerated")}
                    titlePopup={this.TraslationFuncation("GenerateAssessment.SuccessfullyGenerated")}
                    subTitlePopup={this.TraslationFuncation("GenerateAssessment.AssessmentGeneratedSuccessfully")}
                    buttonText={this.TraslationFuncation("GenerateAssessment.done")}
                    showCancelButton={false}
                    successIcon={right.default}
                    onOpen={this.NaviGateToViewScreen}
                    onClose={this.NaviGateToViewScreen} />
            </Modal>
        )
    }

    assessmentHeader = () => {
        return (
            <Wrapper>
                <Box className="assessItemMainBox" >
                    <Grid container className="assessCardContainer" justifyContent={this.TrueOrFalseConditionPass(this.state.selectedLanguage.toLowerCase() === "english", "flex-start", "flex-end")}>
                        <Grid item xs={12} className="assessCardItem">
                            <Card className="assessCardDescBox">
                                <CardContent className="cardContentPadding">
                                    <Grid container>
                                        <Grid item xs={12} className="cardDetailPadding">
                                            <Typography className="assessCardTitle">
                                                {this.TraslationFuncation("GenerateAssessment.AssessmentName")}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Divider />
                                        </Grid>
                                    </Grid>
                                    <Formik
                                        data-test-id="EditAssessmentFormik"
                                        initialValues={{
                                            AssessmentTitle: this.state.assessmentTitle,
                                            AssessmentJobRole: this.state.selectedJobRole,
                                            AssessmentCompetencies: this.state.selectedCompetencies,
                                            AssessmentDifficultyLevel: this.state.selectedDifficultyLevel,
                                            AssNoOfQuestion: this.state.assNoOfQuestion,
                                            TimeAllocation: this.state.timeAllocation,
                                            AssessmentQuestionFormat: this.state.selectedQuestionFormat,
                                            AssessmentFocusArea: this.state.selectedFocusArea,
                                            ObjectivesOfAssessment: this.state.assessmentObjectives
                                        }}
                                        validationSchema={this.AssessmentFormSchema}
                                        enableReinitialize={false}
                                        onSubmit={(values) => {
                                            this.GenerateAssessmentApiFuncation(values);
                                        }}
                                    >
                                        {({
                                            handleSubmit,
                                            handleBlur,
                                            setFieldValue,
                                        }) => {
                                            return (
                                                <Form
                                                    onSubmit={handleSubmit}
                                                    noValidate
                                                    name="EditJobDetailForm"
                                                    autoComplete="off"
                                                >
                                                    <Grid container spacing={3} className="contentPadding">
                                                        <Grid item xs={12}>
                                                            {this.assessmentTitle(handleBlur, setFieldValue)}
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            {this.assessmentJobRole(handleBlur, setFieldValue)}
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            {this.assessmentCompetencies(handleBlur, setFieldValue)}
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            {this.assessmentDifficultyLevel(setFieldValue)}
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            {this.assNoOfQuestion(handleBlur, setFieldValue)}
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            {this.timeAllocation(handleBlur, setFieldValue)}
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            {this.assessmentQuestionFormat(setFieldValue)}
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            {this.assessmentFocusArea(setFieldValue)}
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            {this.objectivesOfAssessment(handleBlur, setFieldValue)}
                                                        </Grid>
                                                        <Grid item xs={12} md={6}>
                                                            {this.cancelAssessmentButton()}
                                                        </Grid>
                                                        <Grid item xs={12} md={6}>
                                                            {this.generateAssessmentButton()}
                                                        </Grid>
                                                    </Grid>
                                                </Form>
                                            )
                                        }}
                                    </Formik>

                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </Box>
            </Wrapper>
        )
    }


    AssessmentForm = () => {
        return (
            <Grid container justifyContent={"center"}>
                <Grid item xs={11}>
                    {this.SuccesModel()}
                    <Grid container
                        spacing={1}
                        // justifyContent={"center"}
                        justifyContent={this.TrueOrFalseConditionPass(this.state.selectedLanguage.toLowerCase() === "english","flex-start","center")}
                        alignItems={"center"}
                        sx={{
                            direction: this.state.selectedLanguage.toLowerCase() === "english" ? "" : "rtl",
                        }}
                    >
                        <Grid item xs={1.5} sm={1} md={0.8} lg={0.5}>
                            <Box data-test-id="back_to_dashboard"
                                style={{
                                    width: "47px", height: "47px", border: "1px solid #ECECEC", borderRadius: "50%",
                                    display: "flex", alignItems: "center", justifyContent: "center",
                                    cursor: 'pointer', 
                                }}
                                className={this.TrueOrFalseConditionPass(this.state.selectedLanguage.toLowerCase() === "english"," BackArrowForEnglish "," BackArrowForArabic ")}
                                onClick={this.navigateToDashboard}
                            >
                                <img src={backArrow.default}
                                    alt="Back Arrow"
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={10.5} sm={11} md={11.2} lg={6}>
                            <Typography
                            className={this.TrueOrFalseConditionPass(this.state.selectedLanguage.toLowerCase() === "english", " createUserTitle ", " createUserTitleAr ")}
                            >
                                <Typography style={{ marginRight:this.TrueOrFalseConditionPass(this.state.selectedLanguage.toLowerCase() === "english","  "," 10px ") }} className="assessmentTitle jobTitle">{this.TraslationFuncation("GenerateAssessment.GenerateAssessment")}</Typography>
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={1} className="mainHeaderGrid" justifyContent={this.TrueOrFalseConditionPass(this.state.selectedLanguage.toLowerCase() === "english", "flex-start", "flex-end")}>
                        <Grid item xs={12} sm={12} md={11.2} lg={11.5}>
                            {this.assessmentHeader()}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        )
    }

    RenderAllTabs = () => {
        if (this.state.IsLoading) {
            return (
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: "center", height: '80vh' }}>
                    <CircularProgress color="inherit" className="backdrop" />
                </Box>
            )
        } else {
            if (this.state.ProgressScreen) {
                return this.progressDataScreen()
            } else {
                return this.AssessmentForm()
            }
        }
    }
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <DashboardHeader selectedMenu="Question banks" navigation={this.props.navigation} id={this.props.id}>
                <MainWrapper style={{ direction: this.state.selectedLanguage.toLowerCase() === "english" ? "ltr" : "rtl" }}>
                    {this.RenderAllTabs()}
                </MainWrapper>
            </DashboardHeader>
            // Customizable Area End
        );
    }
}

// Customizable Area Start

export const GenerateAssessmentWeb = withTranslation()(GenerateAssessment)

export const MainWrapper = styled(Box)({
    width: "100%",
    marginTop: "42px",
    display: "flex",
    justifyContent: "center",
    paddingBottom: "30px",
    "& .BackArrowForEnglish": {
        transform: ""
    },
    "& .BackArrowForArabic": {
        transform: "rotate(180deg)",
        marginLeft: "10px"
    },
    "& .progressTextRunning": {
        fontFamily: 'Urbanist',
        fontWeight: 700,
        fontSize: '20px',
        color: "#059669"
    },
    "& .jobTitle": {
        fontFamily: "Urbanist",
        fontSize: "25px",
        fontWeight: 600,
        lineHeight: "30px",
        color: "#000104",
        marginLeft: "12px"
    },
    "& .jobMainHeaderGrid": {
        marginTop: "30px"
    },
    "& .progressMainContainer": {
        paddingLeft: "4rem",
        paddingBottom: "80px",
        "@media (max-width: 768px)": {
            paddingLeft: "6rem",
            paddingBottom: "40px",
        },
        "@media (max-width: 480px)": {
            paddingLeft: "5rem",
            paddingBottom: "20px",
        },
    },
    "& .progressContainer": {
        width: "1188px",
        height: "646px",
        boxShadow: "0px 14px 144px 0px #0000001A",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        "@media(max-width:1650px)": {
            width: "950px"
        },
        "@media(max-width:1350px)": {
            width: "650px"
        },
        "@media(max-width:970px)": {
            width: "550px"
        },
        "@media(max-width:720px)": {
            width: "450px"
        },
        "@media (max-width: 550px)": {
            width: "350px",
        },
        "@media (max-width: 450px)": {
            width: "250px",
        },
        "@media (max-width: 380px)": {
            width: "200px",
        },
    },
    "& .progressImage": {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        maxWidth: "100%",
        height: "auto",
        "@media (max-width: 768px)": {
            paddingBottom: "20px",
        },
    },
    "& .dotsProgress": {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        paddingTop: "30px",
        gap: "7.5px",
        "@media (max-width: 768px)": {
            gap: "5px",
        },
        "@media (max-width: 480px)": {
            gap: "3px",
        },
    },
    "& .linearProgressBar": {
        width: "359px",
        paddingTop: "30px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        "@media (max-width: 768px)": {
            width: "80%",
        },
        "@media (max-width: 480px)": {
            width: "100%",
        },
    },
    "& .linearProgress": {
        height: "10px",
        width: "100%",
        backgroundColor: "#E2E8F0",
        borderRadius: "5px",
        "& .MuiLinearProgress-bar": {
            backgroundColor: "#059669",
        },
    },
    "& .ProgressCreationContent": {
        textAlign: "center",
        paddingTop: "62px",
        "@media (max-width: 768px)": {
            paddingTop: "40px",
        },
        "@media (max-width: 480px)": {
            paddingTop: "20px",
        },
    },
    "& .ProgressContent1": {
        color: "#000104",
        fontFamily: "Urbanist",
        fontSize: "1.125rem",
        lineHeight: "1.5rem",
        fontWeight: 700,
        width: "100%",
        textAlign: "center",
        "@media (max-width: 768px)": {
            fontSize: "1rem",
        },
        "@media (max-width: 620px)": {
            fontSize: "0.875rem",
        },
    },
    "& .ProgressContent2": {
        color: "#000104",
        fontFamily: "Urbanist",
        fontSize: "1rem",
        lineHeight: "1.5rem",
        fontWeight: 400,
        width: "100%",
        textAlign: "center",
        "@media (max-width: 768px)": {
            fontSize: "0.875rem",
        },
        "@media (max-width: 620px)": {
            fontSize: "0.75rem",
        },
    },
    "& .backdrop": {
        color: "#044352",
        backgroundColor: "#fff",
        height: "100vh",
    },
    "& .assessmentTitle": {
        fontFamily: "Urbanist",
        fontWeight: 600,
        lineHeight: "30px",
        fontSize: "25px",
        color: "#000104",
        marginLeft: "12px"
    },
    "& .createUserTitle": {
        lineHeight: "30px",
        color: "#000104",
        fontFamily: "Urbanist",
        fontSize: "25px",
        fontWeight: 600,
        marginLeft: "12px"
    },
    "& .createUserTitleAr": {
        lineHeight: "30px",
        color: "#000104",
        fontFamily: "Urbanist",
        fontSize: "25px",
        fontWeight: 600,
        marginRight: "16px"
    },
    "& .mainHeaderGrid": {
        marginTop: "20px"
    },
    "& .backArrowImg": {
        paddingRight: "12px"
    }
})
export const Wrapper = styled(Box)({
    width: "100%",
    "& .contentPadding": {
        padding: "30px 56px 0px 35px"
    },
    "& .MuiSelect-icon": {
        transform: 'none !important',
    },
    "& .errorStyleMainbox": {
        display: "flex",
        justifyContent: "space-between"
    },
    "& .generateAssText": {
        lineHeight: "24px",
        color: "#FFFFFF",
        fontWeight: 700,
        fontFamily: "Urbanist",
        fontSize: "16px",
        textTransform: "none"
    },
    "& .generateAssBtn": {
        padding: "10px 16px 10px 16px",
        width: "100%",
        borderRadius: "4px",
        height: "58px",
        border: "1px solid #94A3B8",
        background: "#044352"
    },
    "& .cancelAssText": {
        fontFamily: "Urbanist",
        lineHeight: "24px",
        color: "#000000",
        fontWeight: 700,
        textTransform: "none",
        fontSize: "16px"
    },
    "& .assObjectiveField": {
        fontWeight: 400,
        fontFamily: 'Urbanist',
        lineHight: "24px",
        boxShadow: "0px 1px 2px 0px #1018280D",
        fontSie: "16px",
        borderRadius: "8px",
        border: '1px solid #E2E8F0',
        height: "98px",
        padding: "12px 16px",
        width: '100%',
        color: "rgba(0, 0, 0, 0.87)",
        "&:focus": {
            borderColor: "#CBD5E1",
            outline: "none",
        }
    },
    "& .cancelAssBtn": {
        padding: "10px 16px 10px 16px",
        height: "58px",
        width: "100%",
        borderRadius: "4px",
        border: "1px solid #94A3B8",
        "&:hover": {
            background: "none"
        }
    },
    "& .assCheckBoxStyle": {
        width: "20px",
        marginRight: "10px",
        height: "20px",
    },
    "& .TextMarginRight": {
        marginRight: "10px"
    },
    "& .assObjectiveLimit": {
        display: "flex",
        justifyContent: "flex-end",
        fontFamily: "Urbanist",
        fontSize: "12px",
        fontWeight: 400,
    },
    "& .assOptionsStyle": {
        cursor: "pointer",
        "&:hover": { backgroundColor: "#f9f9f9" },
        fontFamily: 'Urbanist',
        border: '1px solid #E2E8F0',
        height: '54px',
        fontSie: '16px',
        fontWeight: 400,
        lineHight: "24px",
        textAlign: "left",
        borderRadius: '8px',
        padding: '2px 16px',
        "& .MuiTypography-root": {
            fontFamily: 'Urbanist',
            fontSize: "14px",
            fontWeight: 400,
            linHheight: "22px",
        },
    },
    "& .selectedAssOptionStyle": {
        fontSie: '14px',
        fontWeight: 700,
        fontFamily: 'Urbanist',
        cursor: "pointer",
        border: '1px solid #044352',
        backgroundColor: "#F1F5F9",
        height: '54px',
        lineHight: "24px",
        textAlign: "left",
        borderRadius: '8px',
        padding: '2px 16px',
        "& .MuiTypography-root": {
            fontFamily: 'Urbanist',
            fontSize: "14px",
            fontWeight: 700,
            linHheight: "22px",
        },
    },
    "& .ArabicTextAlignment": {
        textAlign: "right"
    },
    "& .EnglishTextAlignment": {
        textAlign: "left"
    },

    "& .dropDownplaceHolder": {
        fontSize: 16,
        color: "#DCDCDC",
        fontWeight: 400
    } as React.CSSProperties,
    "& .assessmentDropDown": {
        fontSie: '16px',
        fontWeight: 400,
        border: '1px solid #E2E8F0',
        height: '66px',
        fontFamily: 'Urbanist',
        lineHight: "24px",
        textAlign: "left",
        borderRadius: '8px',
        padding: '12px 16px',
        width: '100%'
    },
    "& .assessmentInputField": {
        border: '1px solid #E2E8F0', fontWeight: 400,
        lineHight: "24px", fontFamily: 'Urbanist',
        fontSie: '16px', textAlign: "left",
        borderRadius: '8px',
        height: '54px',
        padding: '12px 16px',
        cursor: "pointer",
        width: '100%'
    },
    "& .assessmentLabel": {
        fontFamily: "Urbanist", fontSize: "14px",
        fontWeight: 700, color: "#344054",
        linHheight: "22px", marginBottom: "10px"
    },
    "& .errorStyle": {
        marginBottom: "0.5rem",
        fontSize: "0.85rem",
        color: "#F30202"
    },
    "& .assessItemMainBox": {
        width: "100%", overflow: "hidden", display: "flex",
        justifyContent: "center"
    },
    "& .assessCardTitle": {
        color: "#0444352", lineHeight: "24px", fontFamily: "Urbanist", fontSize: "20px",
        fontWeight: 600
    },
    "& .cardContentPadding": {
        padding: "0px 0px 42px 0px"
    },
    "& .assessCardItem": {
        scrollSnapAlign: "center",
        flex: "0 0 auto"
    },
    "& .cardDetailPadding": {
        padding: "30px 56px 30px 35px"
    },
    "& .assessCardContainer": {
        display: "flex",
        overflowX: "auto",
        width: "100%",
        scrollSnapType: "x mandatory",
        scrollBehavior: "smooth",
        padding: "0px 8px",
        scrollbarWidth: "none",
        "&::-webkit-scrollbar": {
            display: "none",
        },
    },
    "& .assessCardDescBox": {
        width: "55vw",
        margin: "4vh 3vw",
        boxShadow: "rgba(0, 0, 0, 0.1) 0px 14px 144px 0px",
        "@media(max-width:900px)": {
            width: "80vw"
        },
    },
    "& textarea::placeholder": {
        color: "#B3B3B3"
    },
    "& input::placeholder": {
        color: "#B3B3B3"
    }
})
// Customizable Area End