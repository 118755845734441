Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "cfchatgptintegrationforknowledgetest";
exports.labelBodyText = "cfchatgptintegrationforknowledgetest Body";

exports.btnExampleTitle = "CLICK ME";
exports.colorLightGrayStatus = "rgb(236, 246, 255)";
exports.colorLightPink = "rgb(245,189,191)";
exports.errorText = "Required field";
exports.getApiMethodType = "GET";
exports.postApiMethodType = "POST";
exports.deleteApiMethodType = "DELETE";
exports.putApiMethodType = "PUT";
exports.getCompetencyApiEndPoint = "bx_block_cfchatgptcompetencymapgeneration/competencies";
exports.createQuestionsApiEndPoint = "bx_block_questionbank2/question_banks";
exports.questionListApiEndPoint = "bx_block_questionbank2/question_banks";
exports.editQuestionApiEndPoint = "bx_block_questionbank2/custom_questions";
exports.deleteQuestionBankApiEndPoint = "bx_block_questionbank2/question_banks";
exports.difficultyLevelApiEndPoint = "bx_block_assessmenttest/assessments/difficulty_levels";
exports.questionFormatApiEndPoint = "bx_block_assessmenttest/assessments/question_formats";
exports.focusAreaApiEndPoint = "bx_block_assessmenttest/assessments/focus_areas";
exports.GetJobTitleEndPoint = "/bx_block_dashboardguests/job_descriptions";
exports.GetJobcompetenciesEndPoint = "/bx_block_questionbank2/question_banks/competencies?job_description_id=";
exports.GenerateAssessmentEndPoint = "/bx_block_assessmenttest/assessments/";
exports.UpdateAssessmentQuestionUrl = (assessmentId, questionId) => {
  return `/bx_block_questionbank2/custom_questions/${questionId}?assesment_id=${assessmentId}`;
};

exports.GetAllAssessment = "bx_block_assessmenttest/assessments/";
exports.getAssessementMethod = "GET";
exports.GetGenratedAssessment = (id)=>{
  return `/bx_block_assessmenttest/assessments/${id}`;
};
exports.getDeleteAssessmentQuestionUrl = (assessmentId, questionId) => {
  return `/bx_block_assessmenttest/assessments/${assessmentId}/delete_question?question_id=${questionId}`;
};

exports.POST="POST";
exports.PUT="PUT";
exports.StatusChangeEndPoint = "/bx_block_assessmenttest/assessments/";

// Customizable Area End