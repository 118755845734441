import React from "react";
// Customizable Area Start
import {
    Box,
    Grid,
    styled,
    Typography,
    Button,
    Card,
    CardContent,
    Input
} from "@mui/material";
import { DashboardHeaderWeb as DashboardHeader } from "../../dashboard/src/DashboardHeader.web";
import { blankCheckbox, selectedCheckbox } from './assets';
import RadioButtonUncheckedOutlinedIcon from '@mui/icons-material/RadioButtonUncheckedOutlined';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';

import CandidateTestController, {
    Props
} from "./CandidateTestController";
import { withTranslation } from "react-i18next";
import { MainWrapper, Wrapper } from "./ViewGenerateAssessment.web";
// Customizable Area End

export default class CandidateTest extends CandidateTestController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start



    RenderScanrioBased = (item: {
        Index: number;
        QuestionsId: number;
        Id: number;
        QuestionFormatName: string;
        QuestionText: string;
        AnswerText: string;
        Answers: {
            Id: number;
            ChoiceText: string;
            IsItCorrect: boolean;
        }[];
    },
        index: number
    ) => {
        return (
            <Box className="optionsStyle scenarioAns">
                <Box style={{ width: '100%' }}>
                    <Input
                        data-test-id={"EditOption"}
                        multiline
                        className={"optionField"}
                        disableUnderline
                        fullWidth
                        maxRows={4}
                        value={item.AnswerText}
                        onBlur={() => {
                            this.ScanrioBasedAnswer(item.Id, index);
                        }}
                        onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                            this.HandelChange(event as React.ChangeEvent<HTMLInputElement>, index);
                        }}
                        autoComplete="off"
                    />
                </Box>
            </Box>
        )
    }

    RenderMutlipleChoice = (item: {
        Index: number;
        QuestionsId: number;
        Id: number;
        QuestionFormatName: string;
        QuestionText: string;
        AnswerText: string;
        Answers: {
            Id: number;
            ChoiceText: string;
            IsItCorrect: boolean;
        }[];
    }, index: number) => {
        return item.Answers?.map((options, Optionindex) => {
            return (
                <Box
                    key={options.ChoiceText + index}
                    display="flex"
                    alignItems="center"
                    mb={1}
                    className={"optionsStyle"}
                    sx={{ fontFamily: "Urbanist" }}
                    data-test-id={"question_options" + item.Index + Optionindex}
                    onClick={() => {
                        if (item.QuestionFormatName === "Multiple Choice") {
                            this.GiveAnswer(options.Id);
                            this.MutipleSelect(item.Id, options.Id, index)
                        } else {
                            this.SingleSelect(item.Id, options.Id)
                            this.RadioButtonChange(item.Answers.map((itemOption) => itemOption.Id), options.Id);
                        }
                    }}
                >
                    {item.QuestionFormatName === "Multiple Choice" ? <img
                        src={this.state.SelectedAnswer.includes(Number(options.Id)) ? selectedCheckbox.default : blankCheckbox.default}
                        alt="checkbox"
                        className="checkBoxStyle"
                    /> :
                        <div style={{ marginRight: "10px" }}>
                            {
                                this.state.SelectedAnswer.includes(Number(options.Id)) ? <RadioButtonCheckedIcon style={{ color: "#044352" }} /> :
                                    <RadioButtonUncheckedOutlinedIcon />
                            }
                        </div>
                    }
                    <Typography style={{
                        marginRight: this.TrueOrFalseConditionPass(this.state.selectedLanguage.toLowerCase() === "english", "  ", " 10px ")
                    }} >{options.ChoiceText}</Typography>
                </Box>
            )
        })
    }


    questionList = (item: {
        Index: number;
        QuestionsId: number;
        Id: number;
        QuestionFormatName: string;
        QuestionText: string;
        AnswerText: string;
        Answers: {
            Id: number;
            ChoiceText: string;
            IsItCorrect: boolean;
        }[];
    }, index: number) => {
        return (
            <Wrapper sx={{ marginTop: 0 }}>
                <Box sx={{
                    margin: {
                        xs: "24px 0px 24px 8px",
                        sm: "24px 0px 24px 16px",
                        md: "24px 0px 24px 40px"
                    }
                }}>
                    <Typography className="questionLabel">
                        {item.Index}. {item.QuestionText}
                    </Typography>

                    <Box sx={{ fontFamily: "Urbanist" }}>
                        {item.Answers.length === 0 ? (
                            this.RenderScanrioBased(item, index)
                        ) : (
                            this.RenderMutlipleChoice(item, index)
                        )}
                    </Box>
                </Box>
            </Wrapper>
        )
    }

    questionListCard = () => {
        return (
            <Wrapper style={{ marginTop: "0px" }}>
                {!this.state.loader &&
                    <Card sx={{ borderRadius: 2, borderLeft: '4px solid #CBD5E1' }}>
                        <CardContent sx={{ paddingLeft: "40px", paddingBottom: "0px !important" }}>
                            <Grid container spacing={1}>
                                {Array.isArray(this.state.QuestionAnswerData) && this.PaginationData().map((item, index) => (
                                    <Grid item xs={12} key={item.Id + index}>
                                        {this.questionList(item, index)}
                                    </Grid>
                                ))}
                            </Grid>
                            <Grid container spacing={1}>
                                <Grid item xs={12} style={{ borderTop: "2px solid #CBD5E1", margin: "16px" }}>
                                    <Box style={{ marginTop: "20px" }}>
                                        <Grid container alignItems={"center"} justifyContent={"space-between"}>
                                            <Grid item xs={6}>
                                                <Typography variant="body1" className="questionLabel" style={{ marginBottom: "0px" }}>
                                                    Q: {String(this.state.visibleCount).padStart(2, "0")}/
                                                    {String(this.state.QuestionAnswerData.length).padStart(2, "0")}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Grid container spacing={2} alignItems={"center"} justifyContent={"space-between"}>
                                                    <Grid item xs={6}>
                                                        <Button fullWidth
                                                            data-test-id="BackBtn"
                                                            className="addQuestionBtn" onClick={() => { this.handleBack() }}>
                                                            <Typography className="addQuestionText">
                                                                Back
                                                            </Typography>
                                                        </Button>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Button
                                                            className="previewBtn"
                                                            data-test-id="NextBtn"
                                                            onClick={() => { this.handleNext() }}
                                                        >
                                                            <Typography className="previewBtnText">
                                                                {this.state.visibleCount === this.state.QuestionAnswerData.length ? "Submit" : "Next"}
                                                            </Typography>
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grid>
                            </Grid>
                        </CardContent>

                    </Card>
                }
            </Wrapper>
        )
    }

    TestStart = () => {
        return (
            <Grid container justifyContent={"center"}>
                <Grid item xs={11}>
                    <Grid container alignItems={"center"} justifyContent={"space-between"} spacing={1}>
                        <Grid item xs={11} sm={11} md={7}>
                            <Grid container spacing={1} alignItems={"center"}>
                                <Grid item xs={12} sx={{ display: "flex", alignItems: "center" }}>
                                    <Typography className="AssessmentName">
                                        Assessment 01
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={11} sm={11} md={5} lg={2.5}
                            sx={{
                                display: "flex",
                                justifyContent: {
                                    xs: "flex-start",
                                    md: "flex-end",
                                    lg: "flex-end",
                                    xl: "flex-end"
                                },
                                marginRight: "16px",
                                marginBottom: "16px"
                            }}
                        >
                            {!this.state.previewClicked &&
                                <Grid container spacing={2} justifyContent={"flex-end"}>
                                    <Button variant="text" className="ExitAssessmentText">
                                        Exit Assessment
                                    </Button>
                                </Grid>
                            }

                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={11}>
                    {this.questionListCard()}
                </Grid>
            </Grid>
        )
    }

    RenderScreens = () => {
        return this.TestStart()
    }

    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <AssessmentWrapper>
                <DashboardHeader IsMenuHidden={true} selectedMenu="Question banks" navigation={this.props.navigation} id={this.props.id}>
                    <MainWrapper ref={this.previewRef} style={{ direction: this.state.selectedLanguage.toLowerCase() === "english" ? "ltr" : "rtl" }}>
                        {this.RenderScreens()}
                    </MainWrapper>
                </DashboardHeader>
            </AssessmentWrapper>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
export const CandidateTestWeb = withTranslation()(CandidateTest);

export const AssessmentWrapper = styled(Box)({
    "& .AssessmentName": {
        fontFamily: 'Urbanist',
        fontWeight: 700,
        fontSize: '14px',
        color: "#044352"
    },
    "& .ExitAssessmentText": {
        fontFamily: 'Urbanist',
        fontWeight: 400,
        fontSize: '14px',
        textTransform: "none", color: "#0F172A"
    }
});

// Customizable Area End