import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
import React, { RefObject } from "react";
import { i18n } from "i18next";

interface AssessmentResponse {
    assessment_id: number;
    attempted_assessment_id: number;
    questions: {
        question_format_name: string;
        id: number;
        text: string;
        options: {
            id: number;
            option_text: string;
        }[];
    }[];
    error: string
}
// Customizable Area End

export const configJSON = require("./config");
export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    i18n: i18n;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    selectedLanguage: string;
    token: string;
    questionBankId: string;
    questionBankData: {};
    errorMsg: string;
    previewClicked: boolean;
    loader: boolean;
    questionFormatName: string;
    QuestionAnswerData: Array<{
        Index: number;
        QuestionsId: number;
        Id: number;
        QuestionFormatName: string;
        QuestionText: string;
        AnswerText: string;
        SelectedAnswer: Array<number>;
        Answers: {
            Id: number;
            ChoiceText: string;
            IsItCorrect: boolean;
        }[];
    }>;
    currentPage: number;
    visibleCount: number,
    itemsPerPage: number,
    IsMenuHide: boolean;
    checked: boolean;
    StartAssessmentPopUp: boolean;
    AssessmentStart: boolean;
    SelectedAnswer: Array<number>;
    TotalQuestions: number;
    SwitchingTabsCount: number;
    // Customizable Area End

}

interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class CandidateTestController extends BlockComponent<
    Props,
    S,
    SS
> {

    // Customizable Area Start
    getQuestionListApiCallId: string = "";
    SaveChangesApiId: string = "";
    GetAllQuetions: string = "";
    GiveAnswerApiCallId: string = "";
    SubmitAssessmentApiCallId: string = "";
    previewRef: RefObject<HTMLElement> = React.createRef<HTMLElement>();
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.CountryCodeMessage)
        ];

        this.state = {
            selectedLanguage: "",
            token: "",
            errorMsg: "",
            questionBankId: "",
            questionBankData: {},
            previewClicked: false,
            loader: false,
            questionFormatName: "",
            QuestionAnswerData: [],
            currentPage: 0,
            visibleCount: 5,
            itemsPerPage: 5,
            IsMenuHide: false,
            checked: false,
            StartAssessmentPopUp: false,
            AssessmentStart: false,
            SelectedAnswer: [],
            TotalQuestions: 0,
            SwitchingTabsCount: 0,
        };

        // Customizable Area End
        runEngine.attachBuildingBlock(this, this.subScribedMessages);
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (apiRequestCallId === this.GetAllQuetions) {
                this.questionListApiResponse(responseJson)
            }
            this.SubmitApiCallResponse(apiRequestCallId, responseJson);
        }
        // Customizable Area End
    }

    // Customizable Area Start
    async componentDidMount() {
        super.componentDidMount();

        const signInResponse = await getStorageData("signInResponse");
        const parsedSignInResponse = await JSON.parse(signInResponse)
        this.setState({ token: parsedSignInResponse.meta?.token })

        const questionBankId = await getStorageData("assessmentId");
        const parsedId = await JSON.parse(questionBankId)
        this.setState({ questionBankId: parsedId }, () => {
            this.GetAllQuetionCall()
        });

        const language = await getStorageData("language") || "English";
        const TestData = await getStorageData("TestData") as string;
        const parsedTestData = await JSON.parse(TestData)

        this.setState({ selectedLanguage: language, TotalQuestions: parsedTestData.TotalQuestions });
        document.addEventListener("keydown", this.handleKeyDown);
        document.addEventListener("contextmenu", this.disableRightClick);
        document.addEventListener("keydown", this.handleKeyDown);
        document.addEventListener("contextmenu", this.disableRightClick);
        this.forceFullScreen();
        this.detectDevTools();
    }

    async componentWillUnmount() {
        document.removeEventListener("keydown", this.handleKeyDown);
        document.removeEventListener("contextmenu", this.disableRightClick);
        document.removeEventListener("visibilitychange", this.handleVisibilityChange);
        document.removeEventListener("keydown", this.blockShortcuts);
        document.removeEventListener("keydown", this.handleKeyDown);
        document.removeEventListener("contextmenu", this.disableRightClick);
        window.removeEventListener("blur", this.handleTabSwitch);
        document.removeEventListener("visibilitychange", this.handleTabSwitch);
    }

    handleKeyDown = (event: KeyboardEvent) => {
        const blockedKeys: ReadonlyArray<string> = [
            "F12", "F5", "Tab", "Escape", "ArrowLeft", "ArrowRight",
            "Control", "Shift", "Meta", "Alt"
        ];

        if (
            blockedKeys.includes(event.key) ||
            (event.ctrlKey && (event.key.toLowerCase() === "r")) ||
            (event.ctrlKey && event.shiftKey) ||
            (event.metaKey && event.shiftKey) // For Mac users
        ) {
            event.preventDefault();
        }
    };

    disableRightClick = (event: MouseEvent) => {
        event.preventDefault();
    };

    questionListApiResponse = (responseJson: AssessmentResponse) => {

        const QuestionAnswerData = responseJson.questions.map((item, index) => {
            return {
                Index: index + 1,
                QuestionsId: item.id,
                Id: item.id,
                QuestionFormatName: item.question_format_name,
                QuestionText: item.text,
                SelectedAnswer: [],
                AnswerText: "",
                Answers: item.options.map((Op) => {
                    return {
                        Id: Op.id,
                        ChoiceText: Op.option_text,
                        IsItCorrect: false,
                    }
                }),
            }
        })

        if (responseJson.error === "You have already started or completed this assessment") {
            this.NaviGateToAnyScreen("CandidateDashboard")
        }

        this.setState({
            loader: false,
            QuestionAnswerData: QuestionAnswerData,
        })
    }

    handleVisibilityChange = () => {
        if (document.hidden) {
            alert("Warning: You switched tabs. This is not allowed during the test!");
        }
    };

    blockShortcuts = (event: KeyboardEvent) => {
        const blockedKeys: string[] = ["F11", "Escape", "Tab"];
        if (event.ctrlKey || event.metaKey || blockedKeys.includes(event.key)) {
            event.preventDefault();
            alert("Keyboard shortcuts are disabled during the test.");
        }
    };



    GetAllQuetionCall = async () => {
        const headers = {
            "token": this.state.token,
            "Content-Type": "application/json",
        };
        const AssessmentTestId = await getStorageData("AssessmentTestId")
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.GetAllQuetions = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            "/bx_block_assessmenttest/attempted_assessments/start_test"
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify({
                "assessment_id": AssessmentTestId
            })
        )
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "POST"
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    GiveAnswerApiCall = async (Body: string) => {
        const headers = {
            "token": this.state.token,
            "Content-Type": "application/json",
        };
        const AssessmentTestId = await getStorageData("AssessmentTestId")
        const HttpBody = {
            assessment_id: AssessmentTestId,
            question_answer_attributes: [JSON.parse(Body)]
        }
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.GiveAnswerApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            "/bx_block_assessmenttest/attempted_assessments/submit_que_answer"
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(HttpBody)
        )
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "POST"
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    SubmitAssessmentApiCall = async () => {
        const headers = {
            "token": this.state.token,
            "Content-Type": "application/json",
        };
        const AssessmentTestId = await getStorageData("AssessmentTestId")
        const HttpBody = {
            assessment_id: AssessmentTestId,
        }
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.SubmitAssessmentApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            "/bx_block_assessmenttest/attempted_assessments/submit_assessment"
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(HttpBody)
        )
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "POST"
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    SubmitApiCallResponse = (ApiCallId: string, APiResponse: { error: string, message: string }) => {
        if (ApiCallId === this.SubmitAssessmentApiCallId) {
            if (APiResponse.message === "Assessment submitted successfully") {
                document.exitFullscreen();
                this.NaviGateToAnyScreen("CandidateDashboard")
            } else {
                alert(APiResponse.error)
            }
        }
    }

    NaviGateToAnyScreen = (PathName: string) => {
        const navigateMsg = new Message(getName(MessageEnum.NavigationMessage));
        navigateMsg.addData(getName(MessageEnum.NavigationTargetMessage), PathName);
        navigateMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(navigateMsg);
    }

    TrueOrFalseConditionPass = (Condition: boolean, TrueCondition: string, FalseCondition: string) => {
        if (Condition) {
            return TrueCondition
        } else {
            return FalseCondition
        }
    }

    PaginationData = () => {
        const { visibleCount } = this.state;

        const currentData = this.state.QuestionAnswerData.slice(0, visibleCount);

        return currentData
    }

    handleNext = () => {
        if (this.state.visibleCount === this.state.QuestionAnswerData.length) {
            this.SubmitAssessmentApiCall()
        } else {
            this.setState((prevState) => ({
                visibleCount:
                    prevState.visibleCount + prevState.itemsPerPage <= prevState.QuestionAnswerData.length
                        ? prevState.visibleCount + prevState.itemsPerPage
                        : prevState.QuestionAnswerData.length,
            }));
        }
    };

    handleBack = () => {
        this.setState((prevState) => ({
            visibleCount:
                prevState.visibleCount - prevState.itemsPerPage >= prevState.itemsPerPage
                    ? prevState.visibleCount - prevState.itemsPerPage
                    : prevState.itemsPerPage,
        }));
    };
    HandelChange = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
        let Data = [...this.state.QuestionAnswerData]
        Data[index].AnswerText = event.target.value
        this.setState({
            QuestionAnswerData: Data
        })
    }

    GiveAnswer = (Id: number) => {
        this.setState({
            SelectedAnswer: this.state.SelectedAnswer.includes(Id) ? this.state.SelectedAnswer.filter(item => item !== Id) : [...this.state.SelectedAnswer, Id]
        })
    }

    SingleSelect = (QuetionId: number, AnswerId: number) => {
        let body = {
            question_id: QuetionId,
            selected_option_id: AnswerId
        }
        this.GiveAnswerApiCall(JSON.stringify(body))
    }

    ScanrioBasedAnswer = (QuetionId: number, index: number) => {
        let data = [...this.state.QuestionAnswerData]
        let body = {
            question_id: QuetionId,
            descriptive_answer: data[index].AnswerText
        }
        this.GiveAnswerApiCall(JSON.stringify(body))
    }

    MutipleSelect = (QuetionId: number, AnswerId: number, index: number) => {
        let data = [...this.state.QuestionAnswerData]

        data[index].SelectedAnswer = data[index].SelectedAnswer.includes(AnswerId) ? data[index].SelectedAnswer.filter(item => item !== AnswerId) : [...data[index].SelectedAnswer, AnswerId]
        this.setState({
            QuestionAnswerData: data
        }, () => {
            let PassData = [...this.state.QuestionAnswerData]
            let body = {
                question_id: QuetionId,
                selected_option_id: PassData[index].SelectedAnswer
            }
            this.GiveAnswerApiCall(JSON.stringify(body))
        })
    }

    RadioButtonChange = (removeIds: number[], AddId: number) => {
        this.setState({
            SelectedAnswer: [...this.state.SelectedAnswer.filter(item => !removeIds.includes(item)), AddId]
        })
    }


    // 🔒 Force Fullscreen Mode
    forceFullScreen = (): void => {
        // Define a type that includes all possible fullscreen request methods
        type FullscreenElement = HTMLElement & {
            requestFullscreen?: () => Promise<void>;
            mozRequestFullScreen?: () => Promise<void>;
            webkitRequestFullscreen?: () => Promise<void>;
            msRequestFullscreen?: () => Promise<void>;
        };
    
        const elem: FullscreenElement = document.documentElement as FullscreenElement;
        
        if (elem.requestFullscreen) {
            elem.requestFullscreen();
        } else if (elem.mozRequestFullScreen) {
            elem.mozRequestFullScreen();
        } else if (elem.webkitRequestFullscreen) {
            elem.webkitRequestFullscreen();
        } else if (elem.msRequestFullscreen) {
            elem.msRequestFullscreen();
        }
    
        // Define a type for the document with fullscreen properties
        type FullscreenDocument = Document & {
            mozFullScreenElement?: Element;
            webkitFullscreenElement?: Element;
            msFullscreenElement?: Element;
        };
    
        const doc: FullscreenDocument = document as FullscreenDocument;
    
        // Continuously check if the user exits fullscreen
        setInterval(() => {
            if (
                !document.fullscreenElement &&
                !doc.mozFullScreenElement &&
                !doc.webkitFullscreenElement &&
                !doc.msFullscreenElement
            ) {
                this.forceFullScreen();
            }
        }, 1000);
    };
    // ❌ Detect and Block Tab Switching
    handleTabSwitch = (): void => {
        this.setState(
            (prevState) => ({
                SwitchingTabsCount: prevState.SwitchingTabsCount + 1, // Increment count
            }),
            () => {
                if (this.state.SwitchingTabsCount < 2) {
                    alert(`Only one time switching allowed.`);
                }
                if (this.state.SwitchingTabsCount === 2) {
                    this.NaviGateToAnyScreen("CandidateDashboard"); // Redirect after 2+ switches
                }
            }
        );
    };

    // 🛡 Detect Developer Tools (Advanced)
    detectDevTools = (): void => {
        setInterval((): void => {
            const widthThreshold: boolean = window.outerWidth - window.innerWidth > 200;
            const heightThreshold: boolean = window.outerHeight - window.innerHeight > 200;

            if (widthThreshold || heightThreshold) {
                alert("Developer tools detected! Please close them to continue.");
            }
        }, 1000);
    };

    // Customizable Area End
}