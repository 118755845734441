import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
import React, { RefObject } from "react";
import { i18n } from "i18next";
interface DeleteQuestionResponse {
    message?: string;
    error?: string;
}

interface QuestionBankResponse {
    message: string;
    question_bank: {
        data: {
            id: string;
            type: string;
            attributes: QuestionBankAttributes;
        };
    };
}

interface QuestionBankAttributes {
    job_description_id: number;
    created_at: string;
    updated_at: string;
    total_generated_questions: number;
    selected_question_types: QuestionType[];
    selected_question_formats: QuestionFormat[];
    selected_difficulty_levels: DifficultyLevel[];
    selected_focus_areas: FocusArea[];
    questions: Question[];
}

interface QuestionType {
    id: number;
    name: string;
    created_at: string;
    updated_at: string;
}

interface QuestionFormat {
    id: number;
    name: string;
    created_at: string;
    updated_at: string;
}

interface DifficultyLevel {
    id: number;
    name: string;
    created_at: string;
    updated_at: string;
}

interface FocusArea {
    id: number;
    name: string;
    created_at: string;
    updated_at: string;
}

interface Question {
    data: {
        id: string;
        type: string;
        attributes: QuestionAttributes;
    };
}

interface QuestionAttributes {
    id: number;
    text: string;
    question_format_name: string;
    question_bank_id: number;
    assessment_id: number | null;
    created_at: string;
    job_description: JobDescription;
    competencies: Competency[];
    difficulty_level: DifficultyLevel;
    answer: Answer;
}

interface JobDescription {
    id: number;
    name: string;
}

interface Competency {
    id: number;
    name: string;
}

interface Answer {
    id: number;
    answer_type: string;
    text_response: string; // Optional for scenario-based questions
    choices: AnswerChoice[]; // Optional for multiple-choice questions
    correct_choices: AnswerChoice[]; // Optional for correct choices
}

interface AnswerChoice {
    id: number;
    option_text: string;
    correct: boolean;
}


// Customizable Area End

export const configJSON = require("./config");
export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    i18n: i18n;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    selectedLanguage: string;
    token: string;
    questionBankId: string;
    questionBankData: {};
    deleteQuestionData: DeleteQuestionResponse;
    errorMsg: string;
    previewClicked: boolean;
    loader: boolean;
    deleteDialog: boolean;
    rowId: string;
    questionFormatName: string;
    selectedQuestionBank: string,
    QuestionAnswerData: Array<{
        Id: string;
        Index: number;
        QuestionFormatName: string,
        QuestionText: string;
        TagText: Array<{
            id: string;
            name: string;
        }>
        Answers: Array<{
            Id: number
            ChoiceText: string;
            isCorrect: boolean;
        }>;
        CorrectChoices: Array<{
            Correctid: number;
            CorrectText: string;
        }>;
    }>;
    SelectedQuestionId: Array<number>;
    QuestionTypes: Array<{
        id: string,
        name: string
    }>;
    DifficultyLevels: Array<{
        id: string,
        name: string
    }>;
    FocusAreas: Array<{
        id: string,
        name: string
    }>;
    SuccessModel: boolean;
    questionList: Array<{
        id: string;
        name: string;
    }>;
    // Customizable Area End

}

interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class QuestionsAddController extends BlockComponent<
    Props,
    S,
    SS
> {

    // Customizable Area Start
    getQuestionListApiCallId: string = "";
    GetQuestionBanksListApiId: string = "";
    SaveChangesApiId: string = "";
    previewRef: RefObject<HTMLElement> = React.createRef<HTMLElement>();
    AddInAssessmentApiCallId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.CountryCodeMessage)
        ];

        this.state = {
            selectedLanguage: "",
            token: "",
            loader: false,
            deleteDialog: false,
            errorMsg: "",
            questionBankId: "",
            questionBankData: {},
            deleteQuestionData: {},
            previewClicked: false,
            rowId: "",
            questionFormatName: "",
            QuestionAnswerData: [],
            QuestionTypes: [],
            SelectedQuestionId: [],
            selectedQuestionBank: "",
            DifficultyLevels: [],
            FocusAreas: [],
            SuccessModel: false,
            questionList: [],

        };

        // Customizable Area End
        runEngine.attachBuildingBlock(this, this.subScribedMessages);
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (apiRequestCallId === this.getQuestionListApiCallId) {
                this.questionListApiResponse(responseJson)
            }

            this.QuestionBanksListResponse(apiRequestCallId, responseJson)
            this.ResponseAddInAssessmentApiCall(apiRequestCallId, responseJson)
        }
        // Customizable Area End
    }

    // Customizable Area Start
    async componentDidMount() {
        super.componentDidMount();

        const signInResponse = await getStorageData("signInResponse");
        const parsedSignInResponse = await JSON.parse(signInResponse)
        this.setState({ token: parsedSignInResponse.meta?.token })

        const questionBankId = await getStorageData("assessmentId");
        const parsedId = await JSON.parse(questionBankId)
        this.setState({ questionBankId: parsedId }, () => {
            this.GetQuestionBanksApiCall()
        });

        const language = await getStorageData("language") || "English";
        this.setState({ selectedLanguage: language });
    }

    questionListApiResponse = (responseJson: QuestionBankResponse) => {
        const QuestionAnswerData = responseJson.question_bank.data.attributes.questions.map((item, index) => {
            return {
                Index: index + 1, Id: item.data.id,
                QuestionFormatName: item.data.attributes.question_format_name, QuestionText: item.data.attributes.text,
                TagText: [{
                    id: `${item.data.attributes.difficulty_level.id}`,
                    name: item.data.attributes.difficulty_level.name.toLowerCase()
                }],
                Answers: item.data.attributes.answer.choices ? item.data.attributes.answer.choices?.map((item) => {
                    return {
                        Id: item.id, ChoiceText: item.option_text, isCorrect: item.correct
                    }
                }) : [{
                    Id: item.data.attributes.answer.id, ChoiceText: item.data.attributes.answer.text_response, isCorrect: true
                }],
                CorrectChoices: item.data.attributes.answer.correct_choices?.map((CorrectItem) => {
                    return {
                        Correctid: CorrectItem.id, CorrectText: CorrectItem.option_text,
                    }
                }) || [],
            }
        })
        this.setState({
            loader: false,
            QuestionAnswerData: QuestionAnswerData,
            SelectedQuestionId: []
        })
    }




    QuestionListApiCall = (Id: string) => {
        this.setState({ loader: true })
        const headers = {
            "token": this.state.token
        };
        const getQuestionListMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getQuestionListApiCallId = getQuestionListMsg.messageId;
        getQuestionListMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        getQuestionListMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `bx_block_questionbank2/question_banks/${Id}`
        );
        getQuestionListMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "GET"
        );
        runEngine.sendMessage(getQuestionListMsg.id, getQuestionListMsg);
    }









    navigateToCreation = () => {
        const navigateMsg = new Message(getName(MessageEnum.NavigationMessage));
        navigateMsg.addData(getName(MessageEnum.NavigationTargetMessage), "ViewGenerateAssessmenti");
        navigateMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(navigateMsg);
    }


    NaviGateToAnyScreen = (PathName: string) => {
        const navigateMsg = new Message(getName(MessageEnum.NavigationMessage));
        navigateMsg.addData(getName(MessageEnum.NavigationTargetMessage), PathName);
        navigateMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(navigateMsg);
    }



    CloseModelSuccessModel = () => {
        this.setState({ SuccessModel: false })
        this.NaviGateToAnyScreen("ViewAssessment")
    }

    TraslationFuncation = (key: string) => {
        if (this.props.i18n) {
            return this.props.i18n.t(key);
        } else {
            return key
        }
    }

    TrueOrFalseConditionPass = (Condition: boolean, TrueCondition: string, FalseCondition: string) => {
        if (Condition) {
            return TrueCondition
        } else {
            return FalseCondition
        }
    }

    QuestionBanksListResponse = (ApiCallId: string, response: {
        message: string;
        question_banks: {
            data: {
                id: string;
                type: string;
                attributes: {
                    question_bank_listing: { job_id: number; job_title: string; competencies: string[]; question_count: number; };
                };
            };
        }[];
        pagination: {
            current_page: number; total_pages: number; total_count: number;
            per_page: number; prev_page: number; next_page: number
        };
    }) => {
        if (ApiCallId === this.GetQuestionBanksListApiId) {
            let data = response.question_banks.map((item) => {
                return {
                    id: item.data.id,
                    name: item.data.attributes.question_bank_listing.job_title
                }
            })
            this.setState({
                questionList: data
            })
        }

    }

    GetQuestionBanksApiCall = () => {
        const headers = {
            "token": this.state.token
        };

        const QuestionBanks = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.GetQuestionBanksListApiId = QuestionBanks.messageId;

        QuestionBanks.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `/bx_block_questionbank2/question_banks/`
        );

        QuestionBanks.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        QuestionBanks.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "GET"
        );

        runEngine.sendMessage(QuestionBanks.id, QuestionBanks);
    }

    AddInAssessnent = async () => {
        this.setState({ loader: true })
        const headers = {
            "token": this.state.token,
            "Content-Type": "application/json"
        };
        const AssessmentId = await getStorageData("assessmentId") as string

        const Body = {
            question_bank_id: this.state.selectedQuestionBank,
            question_ids: this.state.SelectedQuestionId
        }

        const questionMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.AddInAssessmentApiCallId = questionMsg.messageId;
        questionMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        questionMsg.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(Body)
        );
        questionMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `/bx_block_assessmenttest/assessments/${AssessmentId}/create_question_from_bank`
        );

        questionMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.POST
        );
        runEngine.sendMessage(questionMsg.id, questionMsg);
    }

    ResponseAddInAssessmentApiCall = (ApiCallId: string, responseJson: { message: string; }) => {
        if (ApiCallId === this.AddInAssessmentApiCallId) {
            if (responseJson.message === "Selected questions were successfully added to the assessment.") {
                this.setState({ loader: false })
                this.setState({ SuccessModel: true })
                this.setState({ SelectedQuestionId: [] })
            }else{
                alert(responseJson.message)
                this.setState({ SelectedQuestionId: [] })
            }
        }
    }

    // Customizable Area End
}