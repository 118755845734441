import React from "react";
// Customizable Area Start
import {
    Box,
    Grid,
    styled,
    Typography,
    Button,
    Container,
    FormControlLabel,
    Checkbox,
    Modal} from "@mui/material";
import { DashboardHeaderWeb as DashboardHeader } from "../../dashboard/src/DashboardHeader.web";
import { blankCheckbox, selectedCheckbox, Start } from './assets';

import InstructionsController, {
    Props
} from "./InstructionsController";
import { withTranslation } from "react-i18next";
import { MainWrapper } from "./ViewGenerateAssessment.web";
import CustomPopup from "../../../../packages/components/src/CustomSuccessModel.web";
import { AssessmentWrapper } from "./CandidateTest.web";

// Customizable Area End

export default class Instructions extends InstructionsController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    SuccessModel = () => {
        return (
            <Modal
                open={this.state.StartAssessmentPopUp}
                data-test-id="successModel"
                onClose={this.CloseStartPopUp}
            >
                <CustomPopup
                    buttonText={`Start`}
                    data-test-id="AssessmentStartModel"
                    headingPopup={`Start Your Assessment`}
                    titlePopup={`Ready to Start Your Assessment?`}
                    subTitlePopup={`Please review the instructions below before starting.Number of Questions: 20`}
                    showCancelButton={true}
                    successIcon={Start.default}
                    onOpen={() => {
                        this.StartAssessment()
                    }}

                    cancelButtonText={`Cancel`}
                    onClose={() => {
                        this.CloseStartPopUp()
                    }}
                />
            </Modal>
        )
    }

    Instructions = () => {
        return (
            <StyledContainer>
                {this.SuccessModel()}
                <Typography variant="h5" fontWeight="bold" gutterBottom>
                    Instructions
                </Typography>
                <Grid container spacing={2} className="instruction-list">
                    {[
                        {
                            "title": `The test consists of ${this.state.TotalQuestions} multiple-choice questions.`,
                            "sub_text": "Ensure you understand the rules and guidelines to complete the assessment successfully."
                        },
                        {
                            "title": "Select the best answer from the options provided. You can navigate between questions using the \"Next\" and \"Previous\" buttons.",
                            "sub_text": "Ensure you understand the rules and guidelines to complete the assessment successfully."
                        },
                        {
                            "title": "You can review and change your answers before submitting. Mark questions for review if you're unsure, and revisit them later.",
                            "sub_text": "Ensure you understand the rules and guidelines to complete the assessment successfully."
                        },
                        {
                            "title": "Click on the \"Submit\" button once you've completed the test. Ensure all answers are finalized before submission.",
                            "sub_text": "Ensure you understand the rules and guidelines to complete the assessment successfully."
                        }
                    ]
                        .map((text, index) => (
                            <Grid item xs={12}>
                                <Box key={index} className="instruction-item">
                                    <Box className="number-circle">{index + 1}</Box>
                                    <div>
                                        <Typography className="instruction-text">
                                            <strong>{text.title.split(".")[0]}.</strong> {text.title.split(".").slice(1).join(".")}
                                        </Typography>
                                        <Typography className="instruction-sub-text">
                                            {text.sub_text.split(".")[0]}. {text.sub_text.split(".").slice(1).join(".")}
                                        </Typography>
                                    </div>
                                </Box>
                            </Grid>
                        ))}
                </Grid>

                <Grid container justifyContent={"center"} spacing={2} alignItems={"center"}>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <div style={{ marginLeft: "10px" }}>
                            <FormControlLabel
                                data-test-id="TermsAndConditions"
                                control={<Checkbox icon={<img style={{ minWidth: "20px" }} src={blankCheckbox.default} />} checkedIcon={<img style={{ minWidth: "20px" }} src={selectedCheckbox.default} />} checked={this.state.checked} onChange={this.handleCheckboxChange} />}
                                label={
                                    <Typography className="IAgreeText">
                                        I agree with <span className="TermsAndConditions">Terms and Conditions</span>
                                    </Typography>
                                }
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <Grid container justifyContent={"center"} spacing={2}>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <Box className="button-container">
                                    <Button data-test-id="BackButton" className="addQuestionBtn">
                                        <Typography className="addQuestionText">
                                            Back
                                        </Typography>
                                    </Button>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <Box className="button-container">
                                    <Button onClick={() => {
                                        this.OpenAssessment()
                                    }} className="previewBtn"
                                        data-test-id="OpenAssessment" disabled={!this.state.checked}>
                                        <Typography className="previewBtnText">
                                            Start
                                        </Typography>
                                    </Button>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </StyledContainer>
        )
    }

    RenderScreens = () => {
        return this.Instructions()
    }

    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <AssessmentWrapper>
                <DashboardHeader IsMenuHidden={this.state.IsMenuHide} selectedMenu="Question banks" navigation={this.props.navigation} id={this.props.id}>
                    <MainWrapper ref={this.previewRef} style={{ direction: this.state.selectedLanguage.toLowerCase() === "english" ? "ltr" : "rtl" }}>
                        {this.RenderScreens()}
                    </MainWrapper>
                </DashboardHeader>
            </AssessmentWrapper>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
export const InstructionsWeb = withTranslation()(Instructions);

const StyledContainer = styled(Container)({
    padding: "24px",
    borderRadius: "8px",
    background: "#fff",
    marginTop: "40px",
    "& .instruction-list": {
        paddingLeft: 2,
    },
    "& .instruction-item": {
        display: "flex",
        alignItems: "start",
        marginBottom: "16px",
        gap: "10px"
    },
    "& .number-circle": {
        width: "30px",
        minWidth: "30px",
        minHeight:"30px",
        height: "30px",
        borderRadius: "50%",
        backgroundColor: "#E2E8F0",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontWeight: "bold",
        marginRight: "10px",
    },
    "& .instruction-text": {
        fontFamily: 'Urbanist',
        fontWeight: 700,
        fontSize: '14px'
    },
    "& .instruction-sub-text": {
        fontFamily: 'Inter',
        fontWeight: 400,
        fontSize: '14px',
    },
    "& .IAgreeText": {
        fontFamily: 'Inter',
        fontWeight: 400,
        fontSize: '14px',
        marginLeft: "10px"
    },
    "& .TermsAndConditions": {
        fontFamily: 'Inter',
        fontWeight: 400,
        fontSize: '14px',
        textDecoration: 'underline'

    },
    "& .button-container": {
        display: "flex",
        justifyContent: "flex-end",
        marginTop: "16px",
    },
    "& .button": {
        padding: "10px 20px",
        borderRadius: "5px",
        fontWeight: "bold",
        marginLeft: "10px",
        minWidth: "120px",
    },
});

// Customizable Area End