import React from "react";
// Customizable Area Start
import {
    Box,
    Grid,
    styled,
    Typography,
    Button,
    Card,
    CardContent,
    Divider,
    Dialog,
    DialogTitle,
    IconButton,
    DialogContent,
    DialogActions
} from "@mui/material";
import { DashboardHeaderWeb as DashboardHeader } from "../../dashboard/src/DashboardHeader.web";
import { backArrow, deleteComp } from "./assets";
import CloseIcon from '@mui/icons-material/Close';
// Customizable Area End

import ViewDictionaryController, {
    Props,
} from "./ViewDictionaryController";
import { withTranslation } from "react-i18next";

export default class ViewDictionary extends ViewDictionaryController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    deleteCompetencyDialog = () => {
        return (
            <Dialog
                open={this.state.openDeleteDic}
                onClose={this.closeDeleteDicDialog}
                PaperProps={{
                    sx: {
                        direction: this.state.selectedLanguage.toLowerCase() === "english" ? "ltr" : "rtl",
                        borderRadius: "8px 8px 32px 8px",
                        overflow: 'hidden',
                    }
                }}
            >
                <DialogTitle>
                    <Wrapper>
                        <Typography className="successDicDialogTitle">
                            {this.TraslationFuncation("CompetencyDashboard.DeleteDetails")}
                        </Typography>
                        <IconButton
                            aria-label="close"
                            data-test-id="close_delete_dictionary"
                            onClick={this.closeDeleteDicDialog}
                            className="deleteDicDialogClose"
                            sx={{
                                right: this.state.selectedLanguage.toLowerCase() === "english" ? 30 : "auto",
                                left: this.state.selectedLanguage.toLowerCase() === "english" ? "auto" : 30
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Wrapper>
                </DialogTitle>
                <Divider sx={{ color: "#E2E8F0" }} />
                <DialogContent>
                    <Wrapper>
                        <Box className="deleteDicImageBox">
                            <img src={deleteComp.default} alt="Success Image" height={144} width={144} />
                        </Box>
                    </Wrapper>
                    <Typography
                        sx={{
                            fontSize: "20px",
                            fontWeight: 700,
                            fontFamily: "Urbanist",
                            padding: {
                                xs: "14px 14px",
                                sm: "14px 64px"
                            },
                            lineHeight: "28px",
                            color: "#0F172A",
                            textAlign: "center"
                        }}>
                        {this.TraslationFuncation("CompetencyDashboard.AreYouSureYouWantToDeleteThisCompetencyDictionary")}
                    </Typography>
                    <Typography
                        sx={{
                            fontWeight: 400,
                            lineHeight: "26px",
                            fontFamily: "Urbanist",
                            padding: "0px 68px",
                            fontSize: "18px",
                            color: "#0F172A",
                            textAlign: "center"
                        }}>
                            {this.TraslationFuncation("CompetencyDashboard.YouWontBeAbleToAccessItAgain")}
                    </Typography>

                </DialogContent>
                <Divider />
                <DialogActions>
                    <Wrapper>
                        <Box className={`${this.state.selectedLanguage.toLowerCase() === "english" ? "deleteDicButtonsBox" : "deleteDicButtonsBoxArabic"}`}>
                            <Button
                                color="primary"
                                variant="contained"
                                onClick={this.closeDeleteDicDialog}
                                className={`${this.state.selectedLanguage.toLowerCase() === "english" ? "cancelDicButton" : "cancelDicButtonArabic"}`}
                            >
                                {this.TraslationFuncation("CompetencyDashboard.Cancel")}
                            </Button>
                            <Button
                                color="primary"
                                variant="contained"
                                onClick={this.deleteDictionaryApiCall}
                                data-test-id="delete_dictionary_btn"
                                className={`${this.state.selectedLanguage.toLowerCase() === "english" ? "deleteDicButton" : "deleteDicButtonArabic"}`}
                            >
                                {this.TraslationFuncation("CompetencyDashboard.Delete")}
                            </Button>
                        </Box>
                    </Wrapper>
                </DialogActions>
            </Dialog>
        )
    }

    viewCompetencyName = () => {
        return (
            <Wrapper>
                <Typography className="dicViewLabel">
                    {this.TraslationFuncation("CompetencyDashboard.CompetencyName")} <span>*</span>
                </Typography>
                <Typography className="dicDescField">
                    {this.state.showDictionary?.data?.attributes.competency_name}
                </Typography>
                <Divider sx={{ marginTop: "22px" }} />
            </Wrapper>
        )
    }

    viewCompetencyType = () => {
        return (
            <Wrapper>
                <Typography className="dicViewLabel">
                    {this.TraslationFuncation("CompetencyDashboard.CompetencyType")} <span>*</span>
                </Typography>
                <Typography className="dicDescField">
                    {this.state.showDictionary?.data?.attributes.competency_type.name}
                </Typography>
            </Wrapper>
        )
    }

    viewTargetLevel = () => {
        return (
            <Wrapper>
                <Typography className="dicViewLabel">
                    {this.TraslationFuncation("CompetencyDashboard.TargetLevel")} <span>*</span>
                </Typography>

                <Typography className="dicDescField">
                    {this.state.showDictionary?.data?.attributes.job_level.name}
                </Typography>
            </Wrapper>
        )
    }

    dicBehaviour = () => {
        return (
            <Wrapper>
                <Typography className="dicViewLabel">
                    {this.TraslationFuncation("CompetencyDashboard.Behaviour")} <span>*</span>
                </Typography>
                <Typography className="dicDescViewField">
                    {this.state.showDictionary?.data?.attributes.description?.split('\n').map((text, index) => (
                        <React.Fragment key={index}>
                            {text}
                            <br />
                        </React.Fragment>
                    ))}
                </Typography>
            </Wrapper>
        )
    }

    viewDictionaryHeader = () => {
        return (
            <Wrapper>
                <Box className="dicItemMainBox">
                    <Grid container justifyContent={"flex-start"} className="dicCardContainer" >
                        <Grid item xs={12} className="dicCardItem">
                            <Card className="dicCardDescBox">
                                <CardContent className="cardContentPadding">
                                    <Grid container>
                                        <Grid item xs={12} className="dicDetailPadding">
                                            <Typography className="dicCardTitle">
                                            {this.TraslationFuncation("CompetencyDashboard.DictionaryDetails")}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Divider />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={3.5} className="contentPadding">
                                        <Grid item xs={12}>
                                            {this.viewCompetencyName()}
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            {this.viewCompetencyType()}
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            {this.viewTargetLevel()}
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Divider />
                                        </Grid>
                                        <Grid item xs={12}>
                                            {this.dicBehaviour()}
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </Box>
            </Wrapper>
        )
    }
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <DashboardHeader selectedMenu="Competencies" id={this.props.id} navigation={this.props.navigation}>
                <MainWrapper style={{ direction:this.state.selectedLanguage.toLowerCase() === "english" ? "ltr" : "rtl" }}>
                    <Grid container justifyContent={"center"}>
                        <Grid item xs={11}>
                            <Grid container alignItems={"center"} spacing={1}>
                                <Grid item xs={1.5} sm={1} md={0.8} lg={0.5}>
                                    <img src={backArrow.default}
                                        alt="Back Arrow"
                                        className={this.ConditionPass(this.state.selectedLanguage.toLowerCase() === "english", " backArrow ", " backArrowImgArabic ")}
                                        onClick={this.navigateToDashboard}
                                        data-test-id="navigate_to_create_dic"
                                    />
                                </Grid>
                                <Grid item xs={10.5} sm={5} md={5.2} lg={5.5}>
                                    <Typography className="dicTitle">
                                        {this.TraslationFuncation("CompetencyDashboard.CompetencyDictionary")}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={6} md={6} lg={6}>
                                    <Grid container spacing={2} justifyContent={"ceneter"}>
                                        <Grid item xs={12} sm={5} md={5} lg={4} className="editDicMainBox">
                                            <Button
                                                className="deleteButton"
                                                data-test-id="delete_dictionary"
                                                onClick={this.openDeleteDicDialog}
                                            >
                                                <Typography className="deleteDetailText">
                                                {this.TraslationFuncation("CompetencyDashboard.DeleteDetails")}
                                                </Typography>
                                            </Button>
                                        </Grid>
                                        <Grid item xs={12} sm={7} md={6} lg={4.8} className="editDicMainBox">
                                            <Button
                                                className="editDicButton"
                                                data-test-id="edit_dictionary"
                                                onClick={this.navigateToCreateDictionary}
                                            >
                                                <Typography
                                                    className="editDicText">
                                                    {this.TraslationFuncation("CompetencyDashboard.EditCompetencyDictionary")}
                                                </Typography>
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={12} md={11.2} lg={11.5}>
                                    {this.viewDictionaryHeader()}
                                </Grid>

                            </Grid>
                            {this.deleteCompetencyDialog()}
                        </Grid>
                    </Grid>
                </MainWrapper>

            </DashboardHeader>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
export const ViewDictionaryWeb = withTranslation()(ViewDictionary);
export const MainWrapper = styled(Box)({
    paddingBottom: "30px",
    width: "100%",
    marginTop: "42px",
    display: "flex",
    justifyContent: "center",
    "& .backArrowImg": {
        paddingRight: "12px"
    },
    "& .backArrowImgArabic": {
        paddingRight: "12px",
        transform: "rotate(180deg)"
    },
    "& .dicTitle": {
        fontWeight: 600,
        fontSize: "25px",
        lineHeight: "30px",
        fontFamily: "Urbanist",
        color: "#000104",
        marginLeft: "12px"
    },
    "& .editDicMainBox": {
        display: "flex",
        justifyContent: "flex-end",
        "@media(max-width:600px)": {
            justifyContent: "flex-start",
            marginTop: "10px"
        }
    },
    "& .deleteButton": {
        padding: "10px 10px",
        height: "58px",
        borderRadius: "4px",
        cursor: "pointer",
        width: "100%",
        border: "2px solid #94A3B8",
        "&:hover": {
            background: "none"
        },
        "@media(max-width:599px)": {
            width: "60%"
        }
    },
    "& .deleteDetailText": {
        fontWeight: 700,
        fontFamily: "Urbanist",
        lineHeight: "24px",
        fontSize: "16px",
        color: "#044352",
        textTransform: "none"
    },
    "& .editDicButton": {
        padding: "10px 10px",
        borderRadius: "4px",
        width: "100%",
        height: "58px",
        border: "1px solid #94A3B8",
        background: "#044352",
        "@media(max-width:599px)": {
            width: "60%"
        }
    },
    "& .editDicText": {
        fontWeight: 700,
        fontSize: "16px",
        lineHeight: "24px",
        fontFamily: "Urbanist",
        color: "#FFFFFF",
        textTransform: "none"
    },
})
export const Wrapper = styled(Box)({
    width: "100%",
    "& .dicViewLabel": {
        fontWeight: 700,
        fontFamily: "Urbanist",
        fontSize: "14px",
        color: "#344054",
        linHheight: "22px",
    },
    "& .successDicDialogTitle": {
        lineHeight: "32px",
        fontWeight: 700,
        fontFamily: "Urbanist",
        color: "#1E1E1E",
        fontSize: "24px",
        margin: "8px 16px"
    },
    "& .deleteDicButton": {
        height: "56px",
        padding: "16px 24px",
        fontWeight: 700,
        opacity: "0px",
        marginLeft: "16px",
        background: "#044352",
        textTransform: "none",
        width: "30%",
        borderRadius: "8px",
        fontFamily: "Urbanist",
        fontSize: "16px",
        gap: "8px",
        "&:hover": {
            backgroundColor: "#044352"
        },
    },
    "& .deleteDicButtonArabic": {
        height: "56px",
        gap: "8px",
        fontFamily: "Urbanist",
        background: "#044352",
        textTransform: "none",
        borderRadius: "8px",
        padding: "16px 39.5px 16px 36.5px",
        marginRight: "16px",
        opacity: "0px",
        fontSize: "16px",
        fontWeight: 700,
        width: "120px",
        "&:hover": {
            backgroundColor: "#044352"
        },
    },
    "& .dicDescField": {
        backgroundColor: 'white',
        fontSie: '16px',
        fontWeight: 700,
        lineHight: "24px",
        fontFamily: 'Urbanist',
        padding: '10px 0px',
        width: '100%',
        color: "#475569"
    },
    "& .deleteDicImageBox": {
        padding: "10px 0px",
        display: "flex",
        justifyContent: "center"
    },
    "& .deleteDicButtonsBox": {
        display: "flex",
        justifyContent: "flex-end",
        margin: "16px 0px",
        paddingRight: "16px",
    },
    "& .deleteDicButtonsBoxArabic": {
        display: "flex",
        paddingLeft: "16px",
        justifyContent: "flex-end",
        margin: "16px 0px"
    },
    "& .dicDescViewField": {
        fontSie: "16px",
        fontFamily: 'Urbanist',
        fontWeight: 400,
        lineHight: "24px",
        padding: "12px 0px",
        width: '100%',
        color: "#475569"
    },
    "& .cancelDicButton": {
        fontFamily: "Urbanist",
        height: "56px",
        marginLeft: "16px",
        fontSize: "16px",
        gap: "8px",
        opacity: "0px",
        background: "#F1F5F9",
        textTransform: "none",
        padding: "16px 24px",
        fontWeight: 700,
        width: "30%",
        borderRadius: "8px",
        color: "#000",
        "&:hover": {
            background: "#F1F5F9"
        }
    },
    "& .cancelDicButtonArabic": {
        marginRight: "16px",
        background: "#F1F5F9",
        textTransform: "none",
        opacity: "0px",
        fontSize: "16px",
        borderRadius: "8px",
        height: "56px",
        gap: "8px",
        fontFamily: "Urbanist",
        padding: "16px 39.5px 16px 36.5px",
        fontWeight: 700,
        width: "120px",
        color: "#000",
        "&:hover": {
            background: "#F1F5F9"
        }
    },
    "& .deleteDicDialogClose": {
        top: 16,
        position: 'absolute',
        color: "#334155"
    },
    "& .dicCardDescBox": {
        width: "55vw",
        boxShadow: "rgba(0, 0, 0, 0.1) 0px 14px 144px 0px",
        margin: "4vh 3vw",
        "@media(max-width:900px)": {
            width: "80vw"
        },
    },
    "& .dicCardContainer": {
        overflowX: "auto",
        display: "flex",
        justifyContent: "flex-start",
        width: "100%",
        scrollSnapType: "x mandatory",
        scrollBehavior: "smooth",
        padding: "0px 8px",
        scrollbarWidth: "none",
        "&::-webkit-scrollbar": {
            display: "none",
        },
    },
    "& .dicDetailPadding": {
        padding: "30px 56px 30px 35px"
    },
    "& .dicItemMainBox": {
        display: "flex",
        justifyContent: "center",
        width: "100%",
        overflow: "hidden"
    },
    "& .dicCardItem": {
        scrollSnapAlign: "center",
        flex: "0 0 auto"
    },
    "& .cardContentPadding": {
        padding: "0px 0px 42px 0px"
    },
    "& .contentPadding": {
        padding: "30px 56px 0px 35px"
    },
    "& .dicCardTitle": {
        fontWeight: 600,
        fontFamily: "Urbanist",
        fontSize: "20px",
        lineHeight: "24px",
        color: "#0444352"
    },
})
// Customizable Area End