// Customizable Area Start
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";

import React, { ReactNode } from "react";
import { getStorageData } from "framework/src/Utilities";
import { i18n } from "i18next";


interface AccountList {
    data: Account[];
    users_count: UsersCount;
    meta: Meta;
}

interface Account {
    id: string;
    type: string;
    attributes: AccountAttributes;
}

interface AccountAttributes {
    activated: boolean;
    key: string;
    country_code: string;
    email: string;
    first_name: string;
    full_phone_number: string;
    last_name: string;
    phone_number: string;
    type: string;
    created_at: string;
    updated_at: string;
    device_id: string;
    unique_auth_id: string;
    staff_id: string;
    date_of_birth: string;
    gender: string;
    designation: string;
    company_name: string;
    user_role: string;
    job_description: string;
    profile_picture: string;
    organisation: Organisation;
    department: Department;
    competencies: [];
}

interface Organisation {
    id: string;
    type: string;
    attributes: OrganisationAttributes;
}

interface OrganisationAttributes {
    company_name: string;
    company_culture: string;
    key: string;
    country: string;
    region: string;
    organizational_keywords: string;
    industry_id: number;
    industry_name: string;
    company_size_id: number;
    company_size_name: string;
    stage_id: number;
    stage_name: string;
    job_description_style_id: number;
    job_description_style_name: string;
    document_url: string;
    document_filename: string;
    document_size: number;
}

interface Department {
    id: string;
    type: string;
    attributes: DepartmentAttributes;
}

interface DepartmentAttributes {
    id: number;
    name: string;
}

interface UsersCount {
    all_users_count: number;
    candidate_count: number;
    employee_count: number;
    hr_sub_admin_count: number;
}

interface Meta {
    total_pages: number;
    current_page: number;
    total_records: number;
    prev_page: number;
    next_page: number;
}
// Customizable Area End

export const configJSON = require("./config");
export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    children?: ReactNode;
    selectedMenu?: string;
    i18n?: i18n;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    token: string;
    selectedLanguage: string;
    calendarOpen: boolean,
    selectedDate: null | [Date, Date];
    tempDate: null | [Date, Date];
    AssignAssessmentTable: Array<{
        Id: string,
        Key: string,
        activated: boolean,
        CountryCode: string,
        Email: string,
        FirstName: string,
        LastName: string,
        FullPhoneNumber: string,
        PhoneNumber: string,
        Type: string,
        CreatedAt: string,
        UpdatedAt: string,
        DeviceId: string,
        UniqueAuthId: string,
        StaffId: string,
        DateOfBirth: string,
        Gender: string,
        Designation: string,
        CompanyName: string,
        UserRole: string,
        JobDescription: string,
        ProfilePicture: string,
        Organisation: {
            Id: string,
            Type: string,
            CompanyName: string,
            CompanyCulture: string,
            Key: string,
            Country: string,
            Region: string,
            OrganizationalKeywords: string,
            IndustryId: string,
            IndustryName: string,
            CompanySizeId: string,
            CompanySizeName: string,
            StageId: string,
            StageName: string,
            JobDescriptionStyleId: string,
            JobDescriptionStyleName: string,
            DocumentUrl: string,
            DocumentFilename: string,
            DocumentSize: string
        },
        Department: {
            Id: string,
            Type: string,
            Name: string
        },
    }>;
    CandidateCount: number;
    EmployeeCount: number;
    HrSubAdminCount: number;
    TotalAssessments: number;
    isLoading: boolean;
    ActiveTableTab: string,
    page: number,
    perPage: number,
    totalpage: number,
    SelectedIds: Array<string>,
    selectedFilter: string;
    export: boolean,
    filterAndSearch: boolean,
    showExportDialog: boolean,
    searchText: string,
    userFilter: { [key: string]: string };
    FilterAssessmentDropDown: boolean;
    TotalRecord: number;
    SuccessModel: boolean;
    // Customizable Area End

}

interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class AssignAssessmentController extends BlockComponent<
    Props,
    S,
    SS
> {

    // Customizable Area Start
    GetAllAssessementApiCallId: string = "";
    ListOfAllUsersApiCallId: string = "";
    filterAndSearchApiCallId: string = "";
    AssignAssessmentApiId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.CountryCodeMessage)
        ];

        this.state = {
            token: '',
            selectedLanguage: '',
            calendarOpen: false,
            selectedDate: null,
            tempDate: null,
            AssignAssessmentTable: [],
            TotalAssessments: 0,
            isLoading: false,
            ActiveTableTab: "candidate",
            page: 1,
            perPage: 10,
            CandidateCount: 0,
            EmployeeCount: 0,
            HrSubAdminCount: 0,
            totalpage: 0,
            SelectedIds: [],
            selectedFilter: "",
            export: false,
            filterAndSearch: false,
            showExportDialog: false,
            searchText: "",
            userFilter: {
                "All Active Users": this.TraslationFuncation("allUsers.allActiveUsers") as string,
                "All Inactive users": this.TraslationFuncation("allUsers.allInAciveUsers") as string,
                "Sort by Name": this.TraslationFuncation("allUsers.sortByName") as string
            },
            FilterAssessmentDropDown: false,
            TotalRecord: 0,
            SuccessModel: false,
        };

        // Customizable Area End
        runEngine.attachBuildingBlock(this, this.subScribedMessages);
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        runEngine.debugLog("Message Recived", message);
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            this.GetAllAccountListResponse(apiRequestCallId, responseJson);
            this.AssignAssessmentResponse(apiRequestCallId, responseJson);
            // Get the data for the response

        }
        // Customizable Area End
    }

    // Customizable Area Start



    async componentDidMount() {
        const tokenMeta = await getStorageData('signInResponse', true);
        this.setState({
            token: tokenMeta?.meta?.token
        })
        const language = await getStorageData("language") || "English";
        this.setState({ selectedLanguage: language });
        this.listAllUsersApiCall()
    }
    TraslationFuncation = (key: string) => {
        if (this.props.i18n) {
            return this.props.i18n.t(key);
        }
    }



    handleCondition = (condition: boolean, truePart: React.ReactNode, falsePart: React.ReactNode) => {
        return condition ? truePart : falsePart
    }

    ChangeHandleTab = (TabName: string) => {
        this.setState({
            ActiveTableTab: TabName,
            SelectedIds: [],
        })
        if (TabName === "hr_sub_admin") {
            TabName = "hr_sub_admin";
        }
        this.setState({
            ActiveTableTab: TabName,
            page: 1,
            filterAndSearch: false,
            searchText: "",
            selectedFilter: ""
        }, () => {
            this.listAllUsersApiCall();
        })
    }

    listAllUsersApiCall = () => {
        this.setState({ isLoading: true })
        const headers = {
            "token": this.state.token
        };
        const getAllUsersMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.ListOfAllUsersApiCallId = getAllUsersMsg.messageId;
        getAllUsersMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        getAllUsersMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.listAllUsersApiEndPoint}?user_role=${this.state.ActiveTableTab}&page=${this.state.page}&per_page=${this.state.perPage}`
        );
        getAllUsersMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getAssessementMethod
        );
        runEngine.sendMessage(getAllUsersMsg.id, getAllUsersMsg);
    }

    SetAccountListResponse = (response: AccountList) => {
        if (response.data.length !== 0) {
            const ListData = response.data.map((item) => {
                const { activated, company_name, country_code, created_at, date_of_birth, department, designation, device_id, email, first_name, full_phone_number, gender, key, job_description, last_name, organisation, phone_number, profile_picture, staff_id, type, unique_auth_id, updated_at, user_role } = item.attributes;
                return {
                    Id: item.id,
                    Key: key,
                    activated: activated,
                    CountryCode: country_code,
                    Email: email,
                    FirstName: first_name,
                    FullPhoneNumber: full_phone_number,
                    LastName: last_name,
                    PhoneNumber: phone_number,
                    Type: type,
                    CreatedAt: created_at,
                    UpdatedAt: updated_at,
                    DeviceId: device_id,
                    UniqueAuthId: unique_auth_id,
                    StaffId: staff_id,
                    DateOfBirth: date_of_birth,
                    Gender: gender,
                    Designation: designation,
                    CompanyName: company_name,
                    UserRole: user_role,
                    JobDescription: job_description,
                    ProfilePicture: profile_picture,
                    Organisation: {
                        Id: organisation.id,
                        Type: organisation.type,
                        CompanyName: organisation.attributes.company_name,
                        CompanyCulture: organisation.attributes.company_culture,
                        Key: organisation.attributes.key,
                        Country: organisation.attributes.country,
                        Region: organisation.attributes.region,
                        OrganizationalKeywords: organisation.attributes.organizational_keywords,
                        IndustryId: organisation.attributes.industry_id.toString(),
                        IndustryName: organisation.attributes.industry_name,
                        CompanySizeId: organisation.attributes.company_size_id.toString(),
                        CompanySizeName: organisation.attributes.company_size_name,
                        StageId: organisation.attributes.stage_id.toString(),
                        StageName: organisation.attributes.stage_name,
                        JobDescriptionStyleId: organisation.attributes.job_description_style_id.toString(),
                        JobDescriptionStyleName: organisation.attributes.job_description_style_name,
                        DocumentUrl: organisation.attributes.document_url,
                        DocumentFilename: organisation.attributes.document_filename,
                        DocumentSize: organisation.attributes.document_size.toString()
                    },
                    Department: {
                        Id: department.id,
                        Type: department.type,
                        Name: department.attributes.name
                    },
                }
            })
            this.setState({
                AssignAssessmentTable: ListData,
                CandidateCount: response.users_count.candidate_count,
                EmployeeCount: response.users_count.employee_count,
                HrSubAdminCount: response.users_count.hr_sub_admin_count,
                totalpage: response.meta.total_pages,
                isLoading: false,
                TotalRecord: response.users_count.all_users_count
            })
        } else {
            this.setState({
                AssignAssessmentTable: [],
                TotalAssessments: 0,
                isLoading: false
            })
        }
    }

    PaginationFuncation = (_event: React.ChangeEvent<unknown>, page: number) => {
        this.setState({ page: page }, () => {
            if (this.state.filterAndSearch) {
                this.filterAndSearchApiCall();
            } else {
                this.listAllUsersApiCall();
            }
        });
    }

    GetAllAccountListResponse = (ApiCallId: string, response: AccountList) => {
        if (ApiCallId === this.ListOfAllUsersApiCallId) {
            this.SetAccountListResponse(response)
        }
        if (ApiCallId === this.filterAndSearchApiCallId) {
            this.SetAccountListResponse(response)
        }

    }

    AllCheckAndRemove = (e: React.ChangeEvent<HTMLInputElement>) => {
        const AllIds = this.state.AssignAssessmentTable.map((item) => item.Id)
        if (e.target.checked) {
            this.setState({
                SelectedIds: AllIds
            })
        } else {
            this.setState({
                SelectedIds: []
            })
        }
    }

    SelectAndRemoveId = (Id: string) => {
        this.setState({
            SelectedIds: this.state.SelectedIds.includes(Id) ? this.state.SelectedIds.filter(id => id !== Id) : [...this.state.SelectedIds, Id]
        })
    }

    NaviGateToAnyPage = (PageRoute: string) => {
        const navigateMsg = new Message(getName(MessageEnum.NavigationMessage));
        navigateMsg.addData(getName(MessageEnum.NavigationTargetMessage), PageRoute);
        navigateMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(navigateMsg);
    }

    filterAndSearchApiCall = () => {

        this.setState({ isLoading: true, filterAndSearch: true })

        const headers = {
            "token": this.state.token
        };

        let queryParam = "";

        if (this.state.searchText) {
            queryParam += `search=${this.state.searchText}&`
        }
        if (this.state.selectedFilter === "All Active Users") {
            queryParam += `all_active_user=true&`
        }
        if (this.state.selectedFilter === "All Inactive users") {
            queryParam += `all_inactive_user=true&`
        }
        if (this.state.selectedFilter === "Sort by Name") {
            queryParam += `sort_by_name=true&`
        }

        const getAllUsersMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.filterAndSearchApiCallId = getAllUsersMsg.messageId;

        getAllUsersMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        getAllUsersMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.filterAndSearchApiEndPoint}?user_role=${this.state.ActiveTableTab}&page=${this.state.page}&per_page=${this.state.perPage}&${queryParam}`
        );
        getAllUsersMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "GET"
        );
        runEngine.sendMessage(getAllUsersMsg.id, getAllUsersMsg);
    }

    handleSearch = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const searchValue = event.target.value;
        this.setState({ searchText: searchValue });
    };

    handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>): void => {
        if (event.key === 'Enter') {
            this.filterAndSearchApiCall();
        }
    };

    handleFilter = (value: string) => {
        this.setState({ selectedFilter: value, page: 1, FilterAssessmentDropDown: false }, () => {
            this.filterAndSearchApiCall()
        });
    }

    AssignAssessmentApiCall = async () => {
        this.setState({ isLoading: true })
        const assessmentId = await getStorageData("assessmentId");

        const headers = {
            "token": this.state.token,
            "Content-Type": configJSON.validationApiContentType,
        };

        const httpBody = {
            "account_ids": this.state.SelectedIds.map((item) => Number(item)),
        }

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.AssignAssessmentApiId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.AssignAssessmentApiUrl(assessmentId)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        )
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.POST
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    AssignAssessmentResponse = (ApiCallId: string, response: { message: string }) => {
        if (ApiCallId === this.AssignAssessmentApiId) {
            if (response.message === "Assessment successfully assigned to selected users, and notification has been sent.") {
                this.setState({ isLoading: false, SuccessModel: true, SelectedIds: [] })
                if (this.state.filterAndSearch) {
                    this.filterAndSearchApiCall();
                } else {
                    this.listAllUsersApiCall();
                }
            } else {
                alert(response.message)
                if (this.state.filterAndSearch) {
                    this.filterAndSearchApiCall();
                } else {
                    this.listAllUsersApiCall();
                }
                this.setState({ isLoading: false })
            }

        }

    }

    CloseModelSuccessModel = () => {
        this.setState({ SuccessModel: false })
    }



    // Customizable Area End

}