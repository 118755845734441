export const GROUP = require("../assets/Group.png");
export const PDF1 = require("../assets/PDFCopy.png");
export const PDF2 = require("../assets/PDFCopy2.png");
export const LEFTARROW = require("../assets/arrow-left.png");
export const WhiteStar = require("../assets/StarCopy.png")
export const yellowStar = require("../assets/Star.png")
export const DROPDOWN = require("../assets/dropDawnIcone1.png")
export const PurpleView = require("../assets/Path.png")
export const Purplebigview = require("../assets/Path2.png")
export const TIME = require("../assets/Time.png")
export const REDBOX = require("../assets/RedBox.png")
export const BELL = require("../assets/BellIcon.png")
export const DropDownIcon = require("../assets/DropDownimg.png")
export const UPLOADEDIMG = require("../assets/UploadingImg.png")
export const Attachments = require("../assets/Attachments.png")
export const QuestionsCopy = require("../assets/QuestionsCopy.png")
export const uploadedfile = require("../assets/uploadedfile.png")
export const uploadingfile = require("../assets/Uploading.png")
export const Free_Button = require("../assets/Free_Button.png")
export const Pro_Button = require("../assets/Pro_Button.png")
export const createNewQuestion = require("../assets/createNewQuestion.png");
export const generateAssement = require("../assets/generateAssement.png");
export const uploadQuestions = require("../assets/uploadQuestions.png");
export const emptyDashboardImg = require("../assets/emptyDashboardImg.png");
export const allDownArrow = require("../assets/allDownArrow.png");
export const backArrow = require("../assets/backArrow.svg");
export const checkbox = require("../assets/checkbox.png");
export const selectedCheckbox = require("../assets/selectedCheckbox.png");
export const blankCheckbox = require("../assets/blankCheckbox.png");
export const successImg = require("../assets/successImg.png");
export const addQuestionImg = require("../assets/addQuestionImg.png");
export const editQuestion = require("../assets/editQuestion.png");
export const deleteQuestion = require("../assets/deleteQuestion.png");
export const failedQue = require("../assets/failedQue.png");
export const deleteQuestionBank = require("../assets/deleteQuestionBank.png");
export const titleImg = require("../assets/titleImg.png");
export const textImg = require("../assets/textImg.png");
export const shortAnswer = require("../assets/shortAnswer.png");
export const openEnded = require("../assets/openEnded.png");
export const multipleSelection = require("../assets/multipleSelection.png");
export const dropdownSelection = require("../assets/dropdownSelection.png");
export const scenarioBased = require("../assets/scenarioBased.png");
export const yesNo = require("../assets/yesNo.png");
export const dragIconButton = require("../assets/dragIconButton.png");
export const addOtherImg = require("../assets/addOtherImg.png");
export const markRightImg = require("../assets/markRightImg.png");
export const duplicateImg = require("../assets/duplicateImg.png");
export const DummpyQuestionsList = require("../assets/DummpyQuestionsList.png");

