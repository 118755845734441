Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.getAssessementMethod = "GET";
exports.allAssessementTestApiContentType = "application/json";
exports.getAllAssessementTestUrl = `bx_block_assessmenttest/assessments`;
exports.getAssessementTestCategoriesUrl = `bx_block_assessmenttest/assessment_types`;
exports.getAttemptedAssessmentsUrl = `bx_block_assessmenttest/user_all_assessments`;
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "AssessmentTest";
exports.labelBodyText = "AssessmentTest Body";
exports.btnExampleTitle = "CLICK ME";
exports.mockPassword = "password!";
exports.GetAllAssessment = "bx_block_assessmenttest/assessments/";
exports.listAllUsersApiEndPoint = "account_block/accounts";
exports.DeleteAssessmentQuestion = "/bx_block_assessmenttest/assessments/86/delete_question?question_id=471";
exports.DeleteMethod = "DELETE";
exports.getDeleteAssessmentQuestionUrl = (assessmentId, questionId) => {
  return `/bx_block_assessmenttest/assessments/${assessmentId}/delete_question?question_id=${questionId}`;
};
exports.filterAndSearchApiEndPoint = "account_block/accounts/filter_onbording_users";
exports.ViewScoreBoadApiEndPoint = "/bx_block_assessmenttest/assessments/completed_assessments";
exports.AssignAssessmentApiUrl = (AssessmentID)=>{
  return `/bx_block_assessmenttest/assessments/${AssessmentID}/assign_assessments`
};

exports.POST="POST";
exports.PUT="PUT";
exports.StatusChangeEndPoint = "/bx_block_assessmenttest/assessments/";



  // Customizable Area End