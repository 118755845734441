import React, { Component, ReactNode } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { styled } from "@mui/system";
import { emptyDashboardImg } from './assets';
import { withTranslation, WithTranslation } from 'react-i18next';

interface NoDataScreenProps extends WithTranslation {
    CustomJsk?: ReactNode;
}

class NoDataScreen extends Component<NoDataScreenProps> {
    TraslationFuncation = (key: string) => {
        return this.props.t(key);
    };

    render() {
        return (
            <EmptyWrapper>
                <Grid container className="gridContainer">
                    <Grid item xs={12}>
                        <Box className="noDataImageBox">
                            <img src={emptyDashboardImg.default} alt="No Data" />
                            <Typography className="noDataMainText">
                                {this.TraslationFuncation("NoData")}
                            </Typography>
                            {this.props.CustomJsk}
                        </Box>
                    </Grid>
                </Grid>
            </EmptyWrapper>
        );
    }
}



const EmptyWrapper = styled(Box)({
    width: "100%",
    paddingBottom: "30px",
    "& .addNewUserButton": {
        padding: "8px 60px",
        marginTop: "24px",
        height: "58px",
        borderRadius: "4px",
        background: "#044352",
    },
    "& .noDataImageBox": {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
    },
    "& .noDataNextText": {
        color: "#8A96A8",
        width: "40%",
        fontWeight: 400,
        fontFamily: "Urbanist",
        fontSize: "18px",
        lineHeight: "26px",
        textAlign: "center"
    },
    "& .gridContainer": {
        height: "100%"
    },
    "& .addNewUserText": {
        fontWeight: 700,
        fontFamily: "Urbanist",
        fontSize: "16px",
        lineHeight: "24px",
        color: "#FFFFFF",
        textTransform: "none"
    },
    "& .noDataMainText": {
        color: "#000104",
        fontFamily: "Urbanist",
        fontWeight: 700,
        fontSize: "30px",
        lineHeight: "40px",
    },
})

export default withTranslation()(NoDataScreen);