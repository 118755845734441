import React from "react";
// Customizable Area Start
import {
    Box,
    Grid,
    styled,
    Typography,
    Card,
    CardContent,
    Button,
    Divider,
    Input
} from "@mui/material";
import { DashboardHeaderWeb as DashboardHeader } from "../../dashboard/src/DashboardHeader.web";
import { backArrow, paramImg, addOtherImg } from "./assets";
// Customizable Area End

import AdditionalParameterController, {
    Props,
} from "./AdditionalParameterController";

export default class AdditionalParameter extends AdditionalParameterController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    headerCard = () => {
        return (
            <Wrapper sx={{ paddingLeft: 0 }}>
                <Card sx={{
                    borderBottom: "1px solid #3333331A",
                    borderRight: "1px solid #3333331A",
                    borderRadius: 2,
                    boxShadow: "rgba(0, 0, 0, 0.1) 0px 14px 144px 0px",
                    width: "90%",
                    padding: "20px",
                }}>
                    <CardContent >
                        <Grid container justifyContent="space-between" sx={{ paddingLeft: "28px" }}>
                            <Grid item xs={1.5} sm={1} md={0.8} lg={0.7}>
                                <img src={backArrow.default}
                                    alt="Back Arrow"
                                    className={`${this.state.selectedLanguage.toLowerCase() === "english" ? "mainArrowImg" : "mainBackArrowImgAr"}`}
                                    data-test-id="back_arrow"
                                    onClick={this.navigateToDashboard}
                                />
                            </Grid>
                            <Grid item xs={10} sm={11} md={11.2} lg={11.3}>
                                <Grid container sx={{ marginTop: "8px" }}>
                                    <Grid item xs={12}>
                                        <Typography
                                            className={`${this.state.selectedLanguage.toLowerCase() === "english" ? "addTitle" : "addTitleAr"}`}
                                        >
                                            Add Additional parameter
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={12} sx={{ margin: "64px 0px 0px 12px" }}>
                                        <Typography className="parametersTypo">
                                            Parameters:
                                        </Typography>
                                    </Grid>

                                    <Grid item sx={{ margin: "20px 0px 0px 20px" }} >
                                        <Button className="paramButton">
                                            <Typography className="paramBtnText">
                                                Strategic Impact
                                                <span style={{ color: "#059669", marginLeft: "26px" }}>
                                                    <img alt="Img" src={paramImg.default} />
                                                </span>

                                            </Typography>
                                        </Button>
                                    </Grid>
                                    <Grid item sx={{ margin: "20px 0px 0px 20px" }}>
                                        <Button className="paramButton">
                                            <Typography className="paramBtnText">
                                                Financial Impact
                                                <span style={{ color: "#059669", marginLeft: "26px" }}>
                                                    <img alt="Img" src={paramImg.default} />
                                                </span>

                                            </Typography>
                                        </Button>
                                    </Grid>
                                    <Grid item sx={{ margin: "20px 0px 0px 20px" }}>
                                        <Button className="paramButton">
                                            <Typography className="paramBtnText">
                                                Reputation
                                                <span style={{ color: "#059669", marginLeft: "26px" }}>
                                                    <img alt="Img" src={paramImg.default} />
                                                </span>

                                            </Typography>
                                        </Button>
                                    </Grid>
                                    <Grid item sx={{ margin: "20px 0px 0px 20px" }}>
                                        <Button className="paramButton">
                                            <Typography className="paramBtnText">
                                                Scarcity
                                                <span style={{ color: "#059669", marginLeft: "26px" }}>
                                                    <img alt="Img" src={paramImg.default} />
                                                </span>

                                            </Typography>
                                        </Button>
                                    </Grid>
                                    <Grid item sx={{ margin: "20px 0px 0px 20px" }}>
                                        <Button className="paramButton">
                                            <Typography className="newParamBtnText">
                                                New Parameter
                                                <span style={{ color: "#059669", marginLeft: "26px" }}>
                                                    <img alt="Img" src={paramImg.default} />
                                                </span>
                                            </Typography>
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Wrapper>
        )
    }

    addParameterName = () => {
        return (
            <Wrapper>
                <Box className="errorStyleMainbox">
                    <Typography className="inputLabel" >
                        1. Parameter Name*
                    </Typography>
                </Box>
                <Input
                    data-test-id="parameter_name"
                    className="addParamInputField"
                    disableUnderline
                    placeholder="Enter the Parameter Name"
                    value={this.state.parameterName}
                    onChange={this.handleParameterNameChange}
                    autoComplete="off"
                />
            </Wrapper>
        )
    }

    addSubParamName = () => {
        return (
            <Wrapper>
                <Box className="errorStyleMainbox">
                    <Typography className="subParamInputLabel">
                        2. Sub-parameters*
                    </Typography>

                </Box>
                {this.state.subParameterName.map((param, index) => (
                    <Box sx={{ marginTop: "10px" }}>
                        <Input
                            key={index}
                            data-test-id={`sub_parameter_name_${index}`}
                            className="addParamInputField"
                            disableUnderline
                            placeholder={`Add Sub-parameter ${index + 1}`}
                            value={param}
                            onChange={(event) => this.handleSubParameterNameChange(index, event)}
                            autoComplete="off"
                        />
                    </Box>
                ))}
                <Box sx={{ marginTop: "20px", marginBottom: "-5px" }}>
                    <Button
                        data-test-id="add-sub-param-btn"
                        onClick={this.addSubParam}
                    >
                        <img src={addOtherImg.default} />
                        <Typography className="addOtherBtn">
                            Add Sub-parameter
                        </Typography>
                    </Button>
                </Box>

            </Wrapper>
        )
    }

    addScoreOutOf = () => {
       return (
            <Wrapper>
                <Box className="errorStyleMainbox">
                    <Typography className="inputLabel">
                        3. Score Out Of*
                    </Typography>
                </Box>
                <Input
                    data-test-id="score_out_of"
                    className="addParamInputField"
                    disableUnderline
                    placeholder="Enter the Score value"
                    value={this.state.scoreValue}
                    onChange={this.handleScoreChange}
                    autoComplete="off"
                />
            </Wrapper>
        )
    }

    cancelAddParamBtn = () => {
        return (
            <Wrapper>
                <Button
                    className="addParamCancelBtn"
                    data-test-id="cancel_add_param"
                    onClick={this.navigateToDashboard}
                >
                    <Typography className="addParamCancelText">
                        Cancel
                    </Typography>
                </Button>
            </Wrapper>
        )
    }

    addParamBtn = () => {
        return (
            <Wrapper>
                <Button
                    className="addParamBtn"
                    data-test-id="add_param_btn"
                >
                    <Typography className="addParamText">
                        Add Additional parameter
                    </Typography>
                </Button>
            </Wrapper>
        )
    }

    addParamCard = () => {
        return (
            <Wrapper>
                <Box className="adddParamItemMainBox">
                    <Grid container className="addParamCardContainer">
                        <Grid item xs={12} className="addParamCardItem">
                            <Card className="addParamCard">
                                <CardContent className="cardContentPadding">
                                    <Grid container>
                                        <Grid item xs={12} className="addParamcDetailPadding">
                                            <Typography className="cardTitle">
                                                New Parameter Name
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Divider />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={3.5} className="contentPadding">
                                        <Grid item xs={12}>
                                            {this.addParameterName()}
                                        </Grid>
                                        <Grid item xs={12}>
                                            {this.addSubParamName()}
                                        </Grid>
                                        <Grid item xs={12}>
                                            {this.addScoreOutOf()}
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            {this.cancelAddParamBtn()}
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            {this.addParamBtn()}
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </Box>
            </Wrapper>
        )
    }
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <DashboardHeader selectedMenu="Critical position" navigation={this.props.navigation} id={this.props.id}>
                <MainWrapper>
                    <Grid container justifyContent={"center"}>
                        <Grid item xs={12}>
                            {this.headerCard()}
                        </Grid>

                        <Grid item xs={12} sx={{ marginTop: "30px" }}>
                            {this.addParamCard()}
                        </Grid>
                    </Grid>
                </MainWrapper>
            </DashboardHeader>

            // Customizable Area End
        );
    }
}

// Customizable Area Start
export const Wrapper = styled(Box)({
    width: "100%",
    "& .cardContentPadding": {
        padding: "0px 0px 42px 0px"
    },
    "& .addParamBtn": {
        width: "100%",
        borderRadius: "4px",
        height: "58px",
        border: "1px solid #94A3B8",
        padding: "10px 16px 10px 16px",
        background: "#044352",
        marginTop: "16px",
    },
    "& .addOtherBtn": {
        lineHeight: "22px",
        fontSize: "16px",
        fontWeight: 700,
        color: "#044352",
        paddingLeft: "12px",
        textTransform: "none",
        fontFamily: "Urbanist"
    },
    "& .addParamText": {
        fontWeight: 700,
        lineHeight: "24px",
        textTransform: "none",
        fontSize: "16px",
        color: "#FFFFFF",
        fontFamily: "Urbanist"
    },
    "& .addParamCancelText": {
        fontWeight: 700,
        fontFamily: "Urbanist",
        fontSize: "16px",
        lineHeight: "24px",
        color: "#000000",
        textTransform: "none"
    },
    "& .addParamCancelBtn": {
        height: "58px",
        borderRadius: "4px",
        padding: "10px 16px 10px 16px",
        width: "100%",
        border: "1px solid #94A3B8",
        marginTop: "16px",
        "&:hover": {
            background: "none"
        }
    },
    "& .inputLabel": {
        fontFamily: "Urbanist",
        linHheight: "22px",
        fontSize: "14px",
        fontWeight: 700,
        color: "#344054",
        marginBottom: "10px"
    },
    "& .subParamInputLabel": {
        fontFamily: "Urbanist",
        linHheight: "22px",
        fontSize: "14px",
        fontWeight: 700,
        color: "#344054",
    },
    "& .errorStyleMainbox": {
        display: "flex",
        justifyContent: "space-between"
    },
    "& .addParamInputField": {
        fontFamily: 'Urbanist',
        color: "#044352",
        border: '1px solid #CBD5E1',
        borderRadius: '8px',
        padding: '12px 16px',
        fontSie: '16px',
        fontWeight: 700,
        lineHight: "24px",
        height: '66px',
        width: '100%'
    },
    "& .contentPadding": {
        padding: "30px 56px 0px 35px",
        "@media(max-width:425px)": {
            padding: "0px 10px !important",
        },
    },
    "& .addParamcDetailPadding": {
        padding: "30px 56px 30px 35px",
        "@media(max-width:425px)": {
            padding: "20px 10px !important",
        },
    },
    "& .cardTitle": {
        fontWeight: 600,
        fontFamily: "Urbanist",
        fontSize: "20px",
        lineHeight: "24px",
        color: "#0444352"
    },
    "& .addTitleAr": {
        fontSize: "25px",
        fontFamily: "Urbanist",
        lineHeight: "30px",
        color: "#000104",
        fontWeight: 600,
        marginRight: "12px",
        "@media (max-width: 360px)": {
            marginRight: "30px",
        }
    },
    "& .adddParamItemMainBox": {
        overflow: "hidden",
        display: "flex",
        width: "100%",
        justifyContent: "center",
    },
    "& .addParamCardContainer": {
        scrollBehavior: "smooth",
        padding: "0px 8px",
        display: "flex",
        overflowX: "auto",
        scrollSnapType: "x mandatory",
        width: "100%",
        scrollbarWidth: "none",
        "&::-webkit-scrollbar": {
            display: "none",
        },
    },
    "& .addParamCardItem": {
        flex: "0 0 auto",
        scrollSnapAlign: "center"
    },
    "& .addParamCard": {
        width: "52vw",
        boxShadow: "rgba(0, 0, 0, 0.1) 0px 14px 144px 0px",
        margin: "3vh 7vw",
        "@media(max-width:900px)": {
            width: "80vw"
        },
    },
    "& .paramBtnText": {
        lineHeight: "24px",
        fontFamily: "Urbanist",
        fontWeight: 700,
        fontSize: "16px",
        color: "#0F172A",
        padding: "0px 10px",
        textTransform: "none"
    },
    "& .newParamBtnText": {
        lineHeight: "24px",
        fontFamily: "Urbanist",
        fontWeight: 700,
        fontSize: "16px",
        color: "#8A96A8",
        padding: "0px 10px",
        textTransform: "none"
    },
    "& .paramButton": {
        borderRadius: "8px",
        height: "52px",
        padding: "20px 2px",
        backgroundColor: "#F1F5F9",
    },
    "& .addTitle": {
        fontFamily: "Urbanist",
        fontSize: "25px",
        fontWeight: 600,
        lineHeight: "30px",
        marginLeft: "8px",
        color: "#000104",
        "@media (max-width: 360px)": {
            marginLeft: "30px",
        }
    },
    "& .parametersTypo": {
        fontFamily: "Urbanist",
        fontWeight: 600,
        fontSize: "20px",
        lineHeight: "100%"
    },
    "& input::placeholder, textarea::placeholder": {
        color: "grey",
        fontSize: "16px",
        fontWeight: 400
    },
})
export const MainWrapper = styled(Box)({
   padding: 0
})
// Customizable Area End