import React from "react";

// Customizable Area Start
import {
    Box,
    Button,
    Grid,
    IconButton,
    InputAdornment,
    TextField,
    Typography,
} from "@mui/material";
import { DashboardHeaderWeb as DashboardHeader } from "../../dashboard/src/DashboardHeader.web";
import ViewCriticalDashboardController, {
    Props,
} from "./ViewCriticalDashboardController";
import { styled } from "@mui/system";
import Calendar from "react-calendar";
import { calendar, UserIcon, CheckIcon, arrow_Left } from "./assets";
import { HeaderContainer, CustomDatePicker } from "./CriticalPositionDashbord.web";
import { color } from "react-native-reanimated";
// Customizable Area End

export default class ViewCriticalDashboard extends ViewCriticalDashboardController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    render() {
        return (
            // Customizable Area Start

            <DashboardHeader selectedMenu="Critical position" navigation={this.props.navigation} id={this.props.id}>
                <ViewAssessmentStyle>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <HeaderContainer>
                            <Grid container spacing={2} alignItems={"center"}>
                                <Grid item xs={12} sm={12} md={4} lg={4}>
                                    <Box data-test-id="NavigateToHRManager"
                                        style={{
                                            display: "flex", alignItems: "center", justifyContent: "flex-start",
                                            cursor: "pointer"
                                        }}
                                        onClick={() => {
                                            this.NaviGateToAnyPage("CriticalDashboard");
                                        }}
                                    >
                                        <Box className="BackArrowBox">
                                            <img src={arrow_Left.default} alt="Back Arrow" />
                                        </Box>
                                        <Typography variant="h5" className="HeadingOne">{this.state.JobTitle}</Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={12} md={8} lg={8}>
                                    <Grid container spacing={1} justifyContent="flex-end" display="flex">
                                        <Grid item xs={12} sm={6} md={3} lg={3}>
                                            <Box>
                                                <Button
                                                    data-test-id="NewButton"
                                                    className="OutlinedButton"
                                                    variant="outlined"
                                                    fullWidth
                                                    onClick={this.navigateToEditPosition}
                                                >
                                                    <Typography className="ButtonText">
                                                        Edit Position
                                                    </Typography>
                                                </Button>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={3} lg={3}>
                                            <Box>
                                                <Button
                                                    data-test-id="ExportAllButton"
                                                    className="OutlinedButton"
                                                    variant="outlined"
                                                    fullWidth
                                                    style={{ backgroundColor: "#044352", color: "#FFFFFF" }}
                                                >
                                                    <Typography className="ButtonText" style={{ color: "#FFFFFF" }}>
                                                        Export Details
                                                    </Typography>
                                                </Button>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} sm={6} md={3} lg={3}>
                                    <Box className="GreyContainer">
                                        <Box className="GreyTextContainer">
                                            <Box>
                                                <Typography className="GreyContainerheading">
                                                    Critical Rank <span style={{ color: "#43A047" }}>{this.state.rank}</span> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    <span style={{ color: "#43A047" }}>{this.state.totalScore}</span>
                                                </Typography>

                                            </Box>
                                        </Box>
                                    </Box>
                                </Grid>
                            </Grid>
                        </HeaderContainer>
                    </Grid>
                </ViewAssessmentStyle>
                <ViewAssessmentStyle>
                    <Grid
                        container
                        spacing={3}
                        sx={{
                            width: "100%",
                            display: "block",
                            textAlign: "center"
                        }}
                    >
                        {this.state.cpParameters.map((paramData, index) => {
                            const attributes = paramData.data.attributes;
                            return (
                                <>
                                    <div key={index} style={{ width: "100%", marginBottom: "20px" }}>
                                        <HeaderContainer>
                                            <Grid container spacing={2} alignItems="center">
                                                <Grid
                                                    item
                                                    xs={12}
                                                    style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                                                >
                                                    <Typography variant="h6" className="HeadingTwo">
                                                        {attributes.name}
                                                    </Typography>

                                                    <Typography variant="h6" className="HeadingTwo">
                                                        {attributes.score_ratio}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={2} sx={{ marginTop: '12px', marginBottom: '16px' }}>
                                                {attributes.critical_sub_parameters.map((subParam: any, subIndex: number) => (
                                                    <Grid item xs={6} key={subIndex}>
                                                        <Typography variant="subtitle1" sx={{ textAlign: 'left' }}> {/* 👈 Ensure left alignment */}
                                                            {subParam.data.attributes.name}
                                                        </Typography>
                                                    </Grid>
                                                ))}
                                            </Grid>
                                            <Grid container spacing={2} sx={{ backgroundColor: '#f5f5f5', padding: '10px', borderRadius: '5px' }}>
                                                {attributes.critical_sub_parameters.map((subParam: any, subIndex: number) => (
                                                    <Grid item xs={6} key={subIndex}>
                                                        <Typography variant="body1" sx={{ textAlign: 'left' }}> {/* 👈 Ensure left alignment */}
                                                            {subParam.data.attributes.score_generated}/{subParam.data.attributes.score_out_of}
                                                        </Typography>
                                                    </Grid>
                                                ))}
                                            </Grid>
                                        </HeaderContainer>
                                    </div>
                                    <br />
                                </>
                            );
                        })}
                    </Grid>
                </ViewAssessmentStyle>

            </DashboardHeader>
            // Customizable Area End
        );
    }
}

// Customizable Area Start


export const ViewAssessmentStyle = styled(Box)({
    display: "flex",
    flexWrap: "wrap", // ✅ Allow items to wrap to new row
    justifyContent: "center",
    marginTop: "42px",
    padding: "0px 30px",
    "& .CardContainer": {
        backgroundColor: "#FFFFFF",
        borderRadius: "8px",
        padding: "16px",

    },
    "& .GreyColorContainer": {
        backgroundColor: "#FCFCFD",
        margin: "26px 0",
        padding: "26px 0",
        border: '1px solid #ECECEC'
    },
    "& .SmallTitle": {
        fontFamily: 'Urbanist',
        fontWeight: 600,
        fontSize: '20px',
        lineHeight: '24px',
    },
    "& .IconSmallTitle": {
        fontFamily: 'Urbanist',
        fontWeight: 700,
        fontSize: '14px',
        lineHeight: '22px'
    },
    "& .SmallIconTitle": {
        fontFamily: 'Urbanist',
        fontWeight: 400,
        fontSize: '14px',
        color: "#0C2755B2"
    },
    "& .AnswerText": {
        fontFamily: 'Inter',
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '22px',
    },
    "& .IconContainer": {
        display: "flex",
        gap: "10px",
        "@media (max-width: 425px)": {
            marginLeft: "20px"
        },
    },
    "& .HeadingTwo": {
        fontFamily: 'Urbanist',
        fontSize: '18px',
        fontWeight: 600,
        lineHeight: '22px',
        textAlign: 'left',
    },
    "& .CompetenciesContainer": {
        "@media (max-width: 425px)": {
            paddingTop: "28px"
        },
    },
    "& .QuestionTextTitle": {
        fontFamily: 'Inter',
        fontWeight: '700',
        fontSize: '16px',
        lineHeight: '24px'
    },
    "& .QuetionAnswerContainer": {
        borderWidth: '1px',
        padding: '10px',
        borderRadius: '14px',
        border: '1px solid #E2E8F0',
        backgroundColor: "#F8FAFC",
        margin: '5px 0px',
        display: 'flex',
        alignItems: 'flex-start',
    },
    "& .CorrectAnswerContainer": {
        border: '1px solid #059669 !important',
        backgroundColor: "#D1FAE5 !important",
    },
    "& .HeadingTitle": {
        fontFamily: 'Urbanist',
        fontWeight: 600,
        fontSize: '25px',
        lineHeight: '30px'
    },
    "& .OutlinedButton": {
        padding: "10px 16px",
        backgroundColor: "#FFFFFF",
        borderColor: "#94A3B8",
        height: "58px",
        borderRadius: "4px",
        textTransform: "none",
        fontWeight: 500,
        "&:hover": {
            backgroundColor: "#FFFFFF",
            borderColor: "#94A3B8",
        },
        "& .MuiTypography-root": {
            fontWeight: 700,
            fontFamily: "Urbanist",
            fontSize: "16px",
            lineHeight: "28px",
            color: "#044352",
        }
    },
    "& .ContainedButton": {
        padding: "10px 16px",
        backgroundColor: "#044352",
        borderColor: "#94A3B8",
        height: "58px",
        borderRadius: "4px",
        textTransform: "none",
        fontWeight: 500,
        "&:hover": {
            backgroundColor: "#044352",
            borderColor: "#94A3B8",
        },
        "& .MuiTypography-root": {
            lineHeight: "28px",
            fontFamily: "Urbanist",
            fontSize: "16px",
            fontWeight: 700,
            color: "#FFFFFF",
        }
    },
    "& .BackArrowBox": {
        display: "flex", alignItems: "center",
        justifyContent: "center",
        width: "47px", height: "47px",
        marginRight: "10px",
        border: "1px solid #ECECEC", borderRadius: "50%",
        cursor: "pointer"
    },
    "& .userEnterInputField": {
        fontSie: '16px',
        fontWeight: 400,
        fontFamily: 'Urbanist',
        border: '1px solid lightgray',
        lineHight: "24px",
        textAlign: "left",
        borderRadius: '8px',
        height: '50px',
        padding: '12px 16px',
        width: '100%'
    },
    "& .userInputLabel": {
        fontSize: "14px",
        fontWeight: 700,
        fontFamily: "Urbanist",
        linHheight: "22px",
        color: "#344054",
        marginBottom: "2px"
    },
    "& .genderDropDown": {
        fontSie: '16px',
        fontWeight: 400,
        fontFamily: 'Urbanist',
        border: '1px solid lightgray',
        lineHight: "24px",
        borderRadius: '8px',
        height: '50px',
        padding: '12px 16px',
        width: '100%'
    },
})


// Customizable Area End