import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { SelectChangeEvent } from '@mui/material/Select';
import { getStorageData, setStorageData, removeStorageData } from "framework/src/Utilities";
import { i18n } from "i18next";


interface DeleteDictionary {
    message?: string;
    error?: string;
}
interface ListDictionaryResponse {
    message: string;
    download_url: string;
    error: string;
    competency_dictionaries: ListDictionaryData;
    meta: {
        total_pages: number;
        current_page: number;
        total_records: number;
    }
}

interface ListDictionaryData {
    data?: [
        {
            id: string;
            type: string;
            attributes: {
                id: string;
                competency_name: string;
                description: string;
                created_at: string;
                status: boolean;
                job_level: {
                    id: string;
                    name: string;
                },
                competency_type: {
                    id: string;
                    name: string;
                }
            }
        },
    ],
    meta?: {
        total_pages: number;
        current_page: number;
        total_records: number;
    }
}
// Customizable Area End

export const configJSON = require("./config");
export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    i18n?: i18n;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    selectedStatus: string;
    competencyStatus: Array<{
        name: string,
        value: string
    }>;
    anchorEl: HTMLElement | null;
    dictionaryData: ListDictionaryData;
    selectedLanguage: string;
    token: string;
    errorMsg: string;
    selectedRowId: string | null;
    openDelete: boolean;
    deleteRowId: string;
    deleteDictionary: DeleteDictionary;
    page: number;
    perPage: number;
    totalPage: number;
    loader: boolean;
    // Customizable Area End

}

interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class CompetencyDictionaryDashboardController extends BlockComponent<
    Props,
    S,
    SS
> {

    // Customizable Area Start
    listDictionaryApiCallId: string = "";
    deleteDicApiCallId: string = "";
    updateDicStatusApiCallId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.CountryCodeMessage)
        ];

        this.state = {
            selectedStatus: "",
            competencyStatus: [{
                name: this.TraslationFuncation("CompetencyDashboard.Active"),
                value: "Active"
            },
            {
                name: this.TraslationFuncation("CompetencyDashboard.Inactive"),
                value: "Inactive"
            }],
            anchorEl: null,
            dictionaryData: {},
            selectedLanguage: "",
            token: "",
            errorMsg: "",
            selectedRowId: null,
            openDelete: false,
            deleteRowId: "",
            deleteDictionary: {},
            page: 1,
            perPage: 10,
            totalPage: 0,
            loader: false
        };

        // Customizable Area End
        runEngine.attachBuildingBlock(this, this.subScribedMessages);
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (apiRequestCallId === this.listDictionaryApiCallId) {
                this.listDictionaryResponse(responseJson)
            }
            if (apiRequestCallId === this.deleteDicApiCallId) {
                this.deleteDicResponse(responseJson)
            }
            if (apiRequestCallId === this.updateDicStatusApiCallId) {
                this.updateStatusApiResponse(responseJson)
            }
        }
        // Customizable Area End
    }

    // Customizable Area Start
    async componentDidMount() {
        super.componentDidMount();
        const language = await getStorageData("language") || "English";
        this.setState({ selectedLanguage: language });

        const signInResponse = await getStorageData("signInResponse");
        const parsedSignInResponse = JSON.parse(signInResponse)
        this.setState({
            token: parsedSignInResponse.meta?.token,
        })
        this.listDictionaryApiCall();
    }

    updateStatusApiResponse = (responseJson: ListDictionaryResponse) => {
        if (responseJson && !responseJson.error) {
            this.listDictionaryApiCall();
        } else if (responseJson && responseJson.error) {
            this.setState({
                errorMsg: responseJson.error,
                loader: false
            });
        }
    }

    listDictionaryResponse = (responseJson: ListDictionaryResponse) => {
        if (responseJson && !responseJson.error) {
            if (responseJson.download_url) {
                this.setState({
                    loader: false
                })
                const link = document.createElement('a');
                link.href = responseJson.download_url;
                link.download = 'export.csv';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            } else {
                this.setState({
                    dictionaryData: responseJson.competency_dictionaries,
                    totalPage: responseJson.meta.total_pages,
                    loader: false
                });
            }
        }
        else if (responseJson && responseJson.error) {
            this.setState({
                errorMsg: responseJson.error,
                loader: false
            });
        }
    }

    deleteDicResponse = (responseJson: DeleteDictionary) => {
        if (responseJson && !responseJson.error) {
            this.setState({
                deleteDictionary: responseJson,
                openDelete: false
            }, () => {
                this.listDictionaryApiCall();
            });
        } else if (responseJson && responseJson.error) {
            this.setState({
                errorMsg: responseJson.error,
            });
        }
    }

    updateStatusApiCall = (id: string, status: boolean) => {
        const headers = {
            token: this.state.token,
            "Content-Type": "application/json"
        };

        const raw = JSON.stringify({
            "competency": {
                "status": status
            }
        })

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.updateDicStatusApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.dictionaryApiEndPoint}/${id}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            raw
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.putApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    listDictionaryApiCall = (exportAll?: boolean) => {
        this.setState({ loader: true })
        const headers = {
            "token": this.state.token
        };

        let queryParam = "";
        if (exportAll) {
            queryParam += `export=${exportAll}`
        }

        const getCompetencyMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.listDictionaryApiCallId = getCompetencyMsg.messageId;
        getCompetencyMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        getCompetencyMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.dictionaryApiEndPoint}?${queryParam}&page=${this.state.page}&per_page=${this.state.perPage}`
        );
        getCompetencyMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getApiMethodType
        );
        runEngine.sendMessage(getCompetencyMsg.id, getCompetencyMsg);
    }

    deleteDicApiCall = () => {
        const headers = {
            "token": this.state.token
        };
        const getCompetencyMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.deleteDicApiCallId = getCompetencyMsg.messageId;
        getCompetencyMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        getCompetencyMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.dictionaryApiEndPoint}/${this.state.deleteRowId}`
        );
        getCompetencyMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.deleteApiMethodType
        );
        runEngine.sendMessage(getCompetencyMsg.id, getCompetencyMsg);
    }

    handleExportAll = () => {
        this.listDictionaryApiCall(true);
    }

    handleMenuOpen = (event: React.MouseEvent<HTMLElement>, rowId: string) => {
        this.setState({
            anchorEl: event.currentTarget,
            selectedRowId: rowId
        });
    };

    handleMenuClose = () => {
        this.setState({
            anchorEl: null,
            selectedRowId: null
        });
    };

    handlePageChange = (newPage: number) => {
        this.setState({ page: newPage }, () => {
            this.listDictionaryApiCall();
        });
    };

    handleView = async (rowId: string) => {
        await setStorageData("dictionaryId", rowId)
        const navigateMsg = new Message(getName(MessageEnum.NavigationMessage));
        navigateMsg.addData(getName(MessageEnum.NavigationTargetMessage), "ViewDictionary");
        navigateMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(navigateMsg);
        this.handleMenuClose();
    };

    handleEdit = async (rowId: string) => {
        await setStorageData("dictionaryId", rowId)
        const navigateMsg = new Message(getName(MessageEnum.NavigationMessage));
        navigateMsg.addData(getName(MessageEnum.NavigationTargetMessage), "DictionaryCreation");
        navigateMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(navigateMsg);
        this.handleMenuClose();
    };

    TraslationFuncation = (key: string) => {
        if (this.props.i18n) {
            return this.props.i18n.t(key);
        } else{
            return key
        }
    }

    handleCompetencyStatus = (event: SelectChangeEvent<string>, id: string, status: boolean) => {
        this.updateStatusApiCall(id, event.target.value.toLowerCase() === "active");
    }

    openDeleteDialog = (rowId: string) => {
        this.setState({ openDelete: true, deleteRowId: rowId })
        this.handleMenuClose();
    }

    handleCloseDeleteDialog = () => {
        this.setState({ openDelete: false })
    }

    navigateToBack = () => {
        const navigateMsg = new Message(getName(MessageEnum.NavigationMessage));
        navigateMsg.addData(getName(MessageEnum.NavigationTargetMessage), "CompetencyDashboard");
        navigateMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(navigateMsg);
    }

    navigateToCreation = async () => {
        await removeStorageData("dictionaryId");
        const navigateMsg = new Message(getName(MessageEnum.NavigationMessage));
        navigateMsg.addData(getName(MessageEnum.NavigationTargetMessage), "DictionaryCreation");
        navigateMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(navigateMsg);
    }

    

    ConditionPass = (condition:boolean,TrueCondition:string,FalseCondition:string) => {
        if(condition){
            return TrueCondition
        }else{
            return FalseCondition
        }
    }

    // Customizable Area End
}