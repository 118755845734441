import React from "react";

// Customizable Area Start
import {
    Box,
    Grid,
    Typography,
    Button,
    CircularProgress,
    InputAdornment,
    IconButton,
    TextField,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Pagination,
    Modal
} from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { calendar, CheckIcon, UserIcon, emptyDashboardImg } from "./assets";
import { DashboardHeaderWeb as DashboardHeader } from "../../dashboard/src/DashboardHeader.web";

import Calendar from "react-calendar";
import { withTranslation } from "react-i18next";
import CriticalPositionDashbordController, {
    Props,
} from "./CriticalPositionDashbordController.web";
import { styled } from "@mui/system";
import CustomPopup from "../../../components/src/CustomSuccessModel.web";
import { setStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export default class AssessmentTestDashbord extends CriticalPositionDashbordController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start

        // Customizable Area End
    }

    // Customizable Area Start

    noDataScreen = (ButtonVisible: boolean) => {
        return (
            <AssessmentEmptyWrapper>
                <Grid container className="gridContainer">
                    <Grid item xs={12}>
                        <Box className="noDataImageBox">
                            <img src={emptyDashboardImg.default} />
                            <Typography className="noDataMainText">
                                Ohh No, No data found
                            </Typography>
                            <Typography className="noDataNextText">
                                It looks like theres nothing here yet.
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </AssessmentEmptyWrapper>
        )
    }

    TableDataScreen = () => {
        return (
            <Grid container justifyContent={"center"}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <HeaderContainer>
                        <Grid container spacing={2} alignItems={"center"}>
                            <Grid item xs={12} sm={12} md={4} lg={4}>
                                <Typography variant="h5" className="HeadingOne">
                                    Key Metrics for Critical Position
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} md={8} lg={8}>
                                <Grid container spacing={1} justifyContent="flex-end" display="flex">
                                    <Grid item xs={12} sm={6} md={3} lg={3}>
                                        <Box>
                                            <Button
                                                data-test-id="ExportAllButton"
                                                className="OutlinedButton"
                                                variant="outlined"
                                                fullWidth
                                            >
                                                <Typography className="ButtonText">
                                                    Export All
                                                </Typography>
                                            </Button>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={4} lg={4}>
                                        <CustomDatePicker sx={{ flex: 1 }}>
                                            <TextField
                                                fullWidth
                                                value={this.formatDateRange(this.state.selectedDate) || this.TraslationFuncation("CompetencyDashboard.SelectStartDateEndDate")}
                                                onClick={this.openCalendar}
                                                data-test-id="calendar_field"
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <IconButton onClick={this.openCalendar} />
                                                            <img src={calendar.default}
                                                                onClick={this.openCalendar}
                                                            />
                                                        </InputAdornment>
                                                    ),
                                                    inputProps: {
                                                        sx: {
                                                            color: this.handleCondition(this.state.selectedDate === null, '#334155', '#6C7B89'),
                                                            fontFamily: "Urbanist",
                                                            fontWeight: this.handleCondition(this.state.selectedDate === null, 700, 400),
                                                            fontSize: "16px",
                                                            paddingRight: '0px',
                                                            paddingLeft: "8px"
                                                        },
                                                    },
                                                    sx: { justifyContent: 'center' },
                                                }}
                                                InputLabelProps={{ sx: { textAlign: 'center', width: '100%' } }}
                                            />
                                            {this.state.calendarOpen && (
                                                <div className="reactCalendarBox">
                                                    <Calendar
                                                        selectRange
                                                        onChange={(e) => {
                                                            this.handleDateChange(e as [Date, Date]);
                                                        }}
                                                        data-test-id="calendar"
                                                        value={this.state.tempDate || this.state.selectedDate}
                                                    />
                                                    <div className="action-button-div">
                                                        <Button
                                                            className="action-btn cancel-btn"
                                                            onClick={this.handleCalendarCancelBtn}
                                                            data-test-id="cancel_calendar_btn"
                                                        >
                                                            {this.TraslationFuncation("CompetencyDashboard.Cancel")}
                                                        </Button>
                                                        <Button
                                                            data-test-id="calender_save_btn"
                                                            className={this.handleCondition(this.state.tempDate === null, `action-btn save-btn active`, `action-btn save-btn`) as string}
                                                            onClick={this.handleCalendarSaveBtn}
                                                        >
                                                            {this.TraslationFuncation("CompetencyDashboard.Apply")}
                                                        </Button>
                                                    </div>
                                                </div>
                                            )}
                                        </CustomDatePicker>
                                    </Grid>
                                </Grid>

                            </Grid>
                            <Grid item xs={12} sm={6} md={3} lg={3}>
                                <Box className="GreyContainer">
                                    <Box>
                                        <img src={UserIcon.default} className="GreyMainIcon" alt="" />
                                    </Box>
                                    <Box className="GreyTextContainer">
                                        <Box>
                                            <Typography className="GreyContainerheading">
                                                Total Assessments
                                            </Typography>
                                        </Box>
                                        <Box>
                                            <Typography className="GreyContainerheading">
                                                30
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>

                            <Grid item xs={12} sm={6} md={3} lg={3}>
                                <Box className="GreyContainerA">
                                    <Box>
                                        <img src={CheckIcon.default} className="GreyMainIconA" alt="" />
                                    </Box>
                                    <Box className="GreyTextContainerA">
                                        <Box>
                                            <Typography className="GreyContainerheadingA">
                                                Critical Rank A
                                            </Typography>
                                        </Box>
                                        <Box>
                                            <Typography className="GreyContainerheadingA">
                                                20
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>

                            <Grid item xs={12} sm={6} md={3} lg={3}>
                                <Box className="GreyContainerB">
                                    <Box>
                                        <img src={CheckIcon.default} className="GreyMainIconB" alt="" />
                                    </Box>
                                    <Box className="GreyTextContainerB">
                                        <Box>
                                            <Typography className="GreyContainerheadingB">
                                                Critical Rank B
                                            </Typography>
                                        </Box>
                                        <Box>
                                            <Typography className="GreyContainerheadingB">
                                                20
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>

                            <Grid item xs={12} sm={6} md={3} lg={3}>
                                <Box className="GreyContainerC">
                                    <Box>
                                        <img src={CheckIcon.default} className="GreyMainIconC" alt="" />
                                    </Box>
                                    <Box className="GreyTextContainerC">
                                        <Box>
                                            <Typography className="GreyContainerheadingC">
                                                Critical Rank C
                                            </Typography>
                                        </Box>
                                        <Box>
                                            <Typography className="GreyContainerheadingC">
                                                30
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>

                        </Grid>
                    </HeaderContainer>
                </Grid>
                <Grid style={{ marginTop: "20px" }} item xs={12} sm={12} md={12} lg={12}>
                    <HeaderContainer>
                        <Grid container spacing={2} alignItems={"center"}>
                            <Grid item xs={12} sm={12} md={4} lg={4}>
                                <Typography variant="h5" className="HeadingOne">
                                    Recent Results
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} md={8} lg={8}>
                                <Grid
                                    container
                                    spacing={1}
                                    alignItems="center"
                                    style={{ flexWrap: "nowrap" }}
                                >
                                    {/* Search Box */}
                                    <Grid item xs={12} sm={6} md={3} lg={3}>
                                        <TextField
                                            className="searchBox"
                                            data-test-id="searchTextBox"
                                            fullWidth
                                            value={this.state.searchText}
                                            placeholder={this.TraslationFuncation("CompetencyDashboard.Search")}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton data-test-id="search_icon">
                                                            <SearchIcon style={{ color: "#0F172A" }} />
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </Grid>

                                    {/* Critical Position Rank Filter */}
                                    <Grid item xs={12} sm={6} md={3} lg={3}>
                                        <Box>
                                            <Button
                                                data-test-id="FilterAssessmentDropDown"
                                                className="OutlinedButton"
                                                variant="outlined"
                                                fullWidth
                                                endIcon={<KeyboardArrowDownIcon style={{ color: "#0F172A" }} />}
                                            >
                                                <Typography className="ButtonText">
                                                    Critical Rank
                                                </Typography>
                                            </Button>
                                        </Box>
                                    </Grid>

                                    <Grid item xs={12} sm={6} md={3} lg={3}>
                                        <Box>
                                            <Button
                                                data-test-id="FilterAssessmentDropDown"
                                                className="OutlinedButton"
                                                variant="outlined"
                                                fullWidth
                                                endIcon={<KeyboardArrowDownIcon style={{ color: "#0F172A" }} />}
                                            >
                                                <Typography className="ButtonText">
                                                    Job Title
                                                </Typography>
                                            </Button>
                                        </Box>
                                    </Grid>


                                    <Grid item xs={12} sm={6} md={6} lg={6}>
                                        <Box>
                                            <Button
                                                data-test-id="ExportAllButton"
                                                className="OutlinedButton"
                                                variant="outlined"
                                                fullWidth
                                                onClick={()=>this.NaviGateToAnyPage("AdditionalParameter")}
                                            >
                                                <Typography className="ButtonText">
                                                    Add Additional Parameter
                                                </Typography>
                                            </Button>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <TableContainer sx={{ mt: 2 }}>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell className="TableTilteText" sx={{ paddingLeft: 0, textAlign: "center" }}>
                                                    <Box className="tableCellBox">Critical Position Rank</Box>
                                                </TableCell>
                                                <TableCell className="TableTilteText" sx={{ textAlign: "center" }}>
                                                    <Box className="tableCellBox">Job Title</Box>
                                                </TableCell>
                                                <TableCell className="TableTilteText" sx={{ textAlign: "center" }}>
                                                    <Box className="tableCellBox">Total Score</Box>
                                                </TableCell>
                                                <TableCell className="TableTilteText" sx={{ textAlign: "center" }}>
                                                    <Box className="tableCellBox">Strategic Impact</Box>
                                                </TableCell>
                                                <TableCell className="TableTilteText" sx={{ textAlign: "center" }}>
                                                    <Box className="tableCellBox">Financial Impact</Box>
                                                </TableCell>
                                                <TableCell className="TableTilteText" sx={{ textAlign: "center" }}>
                                                    <Box className="tableCellBox">Reputation</Box>
                                                </TableCell>
                                                <TableCell className="TableTilteText" sx={{ textAlign: "center" }}>
                                                    <Box className="tableCellBox">Scarcity</Box>
                                                </TableCell>
                                                {this.state.AllParameterNames.filter(
                                                    (param) => !["Financial Impact", "Reputation", "Scarcity", "Strategic Impact"].includes(param)
                                                ).map((paramName) => (
                                                    <TableCell key={paramName} className="TableTilteText" sx={{ textAlign: "center" }}>
                                                        <Box className="tableCellBox">{paramName}</Box>
                                                    </TableCell>
                                                ))}
                                                <TableCell data-test-id="delete_selected" className="TableTilteText actionStyle" sx={{ textAlign: "center" }}>
                                                    Action
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {this.state.CriticalTableData.map((row, index) => (
                                                <TableRow key={index}>

                                                    <TableCell className="TableTilteText" sx={{ paddingLeft: 0, textAlign: "center" }}>
                                                        <Box
                                                            sx={{
                                                                height: "100%",
                                                                display: "flex",
                                                                alignItems: "center",
                                                                justifyContent: "center",
                                                                borderLeft: `20px solid ${row.position_rank === "A"
                                                                    ? "#C8E6C9"
                                                                    : row.position_rank === "B"
                                                                        ? "#FFECB3"
                                                                        : row.position_rank === "C"
                                                                            ? "#FFCDD2"
                                                                            : "transparent"
                                                                    }`,
                                                                borderTopLeftRadius: "10px",
                                                                borderBottomLeftRadius: "10px",
                                                                flex: 1,
                                                                padding: "8px",
                                                            }}
                                                        >
                                                            {row.position_rank}
                                                        </Box>
                                                    </TableCell>


                                                    <TableCell className="TableTilteText" sx={{ textAlign: "center" }}>
                                                        <Box className="tableCellBox">{row.job_title}</Box>
                                                    </TableCell>
                                                    <TableCell className="TableTilteText" sx={{ textAlign: "center" }}>
                                                        <Box
                                                            className="tableCellBox"
                                                            sx={{
                                                                color: row.position_rank === "A"
                                                                    ? "#43A047"
                                                                    : row.position_rank === "B"
                                                                        ? "#FF9800"
                                                                        : row.position_rank === "C"
                                                                            ? "#D32F2F"
                                                                            : "inherit",
                                                                fontWeight: "bold",
                                                            }}
                                                        >
                                                            {row.total_score}
                                                        </Box>
                                                    </TableCell>
                                                    <TableCell className="TableTilteText" sx={{ textAlign: "center" }}>
                                                        <Box className="tableCellBox">{row.strategic_impact}</Box>
                                                    </TableCell>
                                                    <TableCell className="TableTilteText" sx={{ textAlign: "center" }}>
                                                        <Box className="tableCellBox">{row.financial_impact}</Box>
                                                    </TableCell>
                                                    <TableCell className="TableTilteText" sx={{ textAlign: "center" }}>
                                                        <Box className="tableCellBox">{row.reputation}</Box>
                                                    </TableCell>
                                                    <TableCell className="TableTilteText" sx={{ textAlign: "center" }}>
                                                        <Box className="tableCellBox">{row.scarcity}</Box>
                                                    </TableCell>
                                                    {this.state.AllParameterNames.filter(
                                                        (param) => !["Financial Impact", "Reputation", "Scarcity", "Strategic Impact"].includes(param)
                                                    ).map((paramName) => (
                                                        <TableCell key={paramName} className="TableTilteText" sx={{ textAlign: "center" }}>
                                                            <Box className="tableCellBox">{row[paramName] || "0/0"}</Box>
                                                        </TableCell>
                                                    ))}
                                                    <TableCell className="TableTilteText">
                                                        <Box className="tableCellBox" style={{ textAlign: "center" }}>
                                                            <IconButton
                                                                onClick={() =>
                                                                    this.setState({
                                                                        OpenIndex: this.state.OpenIndex === index ? -1 : index,
                                                                    })
                                                                }
                                                                data-test-id="DotsData"
                                                            >
                                                                <MoreVertIcon />
                                                            </IconButton>
                                                            {this.state.OpenIndex === index && (
                                                                <div
                                                                    className="PopUpMenu"
                                                                    style={{
                                                                        right: this.handleCondition(this.state.selectedLanguage.toLowerCase() === "english", "20px", "") as string,
                                                                        left: this.handleCondition(this.state.selectedLanguage.toLowerCase() === "english", "", "20px") as string,
                                                                    }}
                                                                >
                                                                    <Button
                                                                        className="MoreVertIconButton"
                                                                        onClick={() => {
                                                                            this.NaviGateToEditAndViewPage("ViewCriticalDashboard", row);
                                                                        }}
                                                                    >
                                                                        <Typography>View Details</Typography>
                                                                    </Button>
                                                                    <Button
                                                                        className="MoreVertIconButton"
                                                                        onClick={() => {
                                                                            this.NaviGateToEditAndViewPage("EditCriticalPosition", row);
                                                                        }}
                                                                    >
                                                                        <Typography>Edit details</Typography>
                                                                    </Button>
                                                                    <Button
                                                                        className="MoreVertIconButton"
                                                                        data-test-id="deleteAssessment"
                                                                    >
                                                                        <Typography className="DeleteColor">Delete details</Typography>
                                                                    </Button>
                                                                </div>
                                                            )}
                                                        </Box>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>

                                    <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2, width: "100%" }}>
                                        <Pagination
                                            color="primary"
                                            page={this.state.page}
                                            boundaryCount={1}
                                            siblingCount={1}
                                            data-test-id="pagination"
                                            onChange={this.PaginationFuncation}
                                            count={this.state.PaginationPageCount > 0 ? this.state.PaginationPageCount : Math.ceil(this.state.TotalRecord / this.state.perPage)}
                                            disabled={this.state.isLoading || this.state.TotalRecord === 0}
                                        />
                                    </Box>
                                </TableContainer>
                            </Grid>

                        </Grid>
                    </HeaderContainer>
                </Grid>
            </Grid>
        )
    }
    RenderAllScreens = () => {

        // return this.TableDataScreen()
        if (this.state.isLoading) {
            return <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80vh' }}>
                <CircularProgress style={{ color: "#044352" }} />
            </Box>
        } else {
            if (this.state.CriticalTableData.length !== 0) {
                return this.TableDataScreen()
            } else {
                return this.noDataScreen(true)
            }
        }

    }
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <DashboardHeader selectedMenu="Critical position" navigation={this.props.navigation} id={this.props.id}>
                <AssessmentTestDashbordStyle>
                    {this.RenderAllScreens()}
                </AssessmentTestDashbordStyle>
            </DashboardHeader>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
export const AssessmentTestDashbordWeb = withTranslation()(AssessmentTestDashbord)

export const AssessmentTestDashbordStyle = styled(Box)({
    display: "flex",
    justifyContent: "center",
    marginTop: "42px",
    padding: "0px 30px",
})


export const HeaderContainer = styled(Box)({
    boxShadow: "rgba(0, 0, 0, 0.1) 0px 1px 144px 0px",
    padding: "20px 20px",
    borderBottom: '1px solid #3333331A',
    "& .PopUpMenu": {
        position: 'absolute',
        backgroundColor: 'white',
        border: '1px solid #ccc',
        borderRadius: '4px',
        display: 'flex',
        flexDirection: 'column',
        zIndex: 1000,
    },
    "& .TableTilteText": {
        fontFamily: 'Urbanist',
        fontSize: '14px',
        fontWeight: 700
    },
    "& .HeadingOne": {
        fontFamily: 'Urbanist',
        fontSize: '20px',
        fontWeight: 700,
        lineHeight: '28px',
        textAlign: 'left',
    },
    "& .OutlinedButton": {
        backgroundColor: "#FFFFFF",
        borderColor: "#94A3B8",
        height: "58px",
        padding: "10px 16px",
        borderRadius: "4px",
        textTransform: "none",
        fontWeight: 500,
        "&:hover": {
            backgroundColor: "#FFFFFF",
            borderColor: "#94A3B8",
        },
        "& .MuiTypography-root": {
            fontFamily: "Urbanist",
            fontSize: "16px",
            fontWeight: 700,
            lineHeight: "28px",
            color: "#044352",
        }
    },
    "& .ContainedButton": {
        backgroundColor: "#044352",
        borderColor: "#94A3B8",
        height: "58px",
        padding: "10px 16px",
        borderRadius: "4px",
        textTransform: "none",
        fontWeight: 500,
        "&:hover": {
            backgroundColor: "#044352",
            borderColor: "#94A3B8",
        },
        "& .MuiTypography-root": {
            fontFamily: "Urbanist",
            fontSize: "16px",
            fontWeight: 700,
            lineHeight: "28px",
            color: "#FFFFFF",
        }
    },
    "& .GreyContainerA, & .GreyContainerB, & .GreyContainerC": {
        backgroundColor: "#F1F5F9",
        padding: "10px",
        borderRadius: "8px",
    },

    "& .GreyContainerheadingA, & .GreyContainerheadingB, & .GreyContainerheadingC": {
        fontFamily: 'Urbanist',
        fontSize: '14px',
        fontWeight: 700,
    },

    "& .GreyTextContainerA, & .GreyTextContainerB, & .GreyTextContainerC": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginTop: "10px",
    },

    "& .GreyMainIconA, & .GreyMainIconB, & .GreyMainIconC": {
        height: "30px"
    }
    ,
    "& .GreyContainer": {
        backgroundColor: "#F1F5F9",
        padding: "10px",
        borderRadius: "8px",
    },
    "& .GreyContainerheading": {
        fontFamily: 'Urbanist',
        fontSize: '14px',
        fontWeight: 700,
    },
    "& .GreyTextContainer": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginTop: "10px",
    },
    "& .GreyMainIcon": {
        height: "30px"
    },
    "& .DeleteColor": {
        color: "red !important",
    },
    "& .MoreVertIconButton": {
        fontFamily: "Urbanist",
        fontSize: "14px",
        justifyContent: "flex-start",
        fontWeight: 400,
        padding: '15px 30px 15px 30px',
        cursor: 'pointer',
        "&:hover": {
            backgroundColor: "#E2E8F0",
            borderRadius: "8px",
        },
        "& .MuiTypography-root": {
            fontFamily: 'Urbanist',
            fontWeight: 400,
            textAlign: "left",
            fontSize: '14px',
            lineHeight: '22px',
            color: "#0F172A",
            textTransform: "none",
        }
    }
})

export const CustomDatePicker = styled(Box)({
    borderRadius: '8px',
    position: 'relative' as 'relative',
    boxSizing: "border-box",
    padding: 0,
    border: "1px solid #94A3B8",
    "&:hover": {
        border: "1px solid #94A3B8"
    },
    "& .MuiInput-underline::after, .MuiInput-underline::before": {
        display: 'none'
    },
    "& .reactCalendarBox": {
        marginTop: "5px",
        boxShadow: "0px 8px 32px 0px #0000000F, 0px 4px 8px 0px #00000008",
        position: 'absolute',
        background: "white",
        top: '100%',
        left: -10,
        zIndex: 1000,
        direction: "ltr",
    },
    "& .reactCalendarBoxArabic": {
        marginTop: "5px",
        boxShadow: "0px 8px 32px 0px #0000000F, 0px 4px 8px 0px #00000008",
        position: 'absolute',
        background: "white",
        top: '100%',
        zIndex: 1000,
        direction: "ltr",
    },
    "& .react-calendar": {
        border: "none",
        fontFamily: "Urbanist",
    },
    "& .react-calendar__navigation button.react-calendar__navigation__label": {
        fontSize: '14px',
        fontFamily: "Urbanist",
        fontWeight: 700,
        color: "#0F172A",
    },
    "& .react-calendar__navigation__prev2-button, .react-calendar__navigation__next2-button": {
        display: "none",
    },
    "& .react-calendar__navigation button": {
        fontSize: '14px',
        fontFamily: "Urbanist",
        fontWeight: 700,
        color: "#94A3B8",
    },
    "& .react-calendar__tile.react-calendar__tile--now": {
        background: 'none',
        color: '#0F172A',
    },
    "& .react-calendar__month-view__weekdays__weekday": {
        textTransform: 'capitalize',
        fontSize: '14px',
        color: '#64748B',
        fontWeight: 400,
        textDecoration: 'none',
    },
    "& .react-calendar__month-view__weekdays__weekday abbr": {
        textDecoration: 'none',
    },
    "& .react-calendar__tile": {
        fontSize: '14px',
        fontWeight: 400,
        color: '#0F172A',
        width: '50px',
        height: '50px',
    },
    "& .react-calendar__tile.react-calendar__tile--range": {
        background: '#F1F5F9',
        color: '#044352',
        borderRadius: '0px',
    },
    "& .react-calendar__tile.react-calendar__tile--rangeStart": {
        borderTopLeftRadius: '50%',
        borderBottomLeftRadius: '50%',
        background: '#F1F5F9',
        color: '#044352',
    },
    "& .react-calendar__tile.react-calendar__tile--rangeEnd": {
        borderTopRightRadius: '50%',
        borderBottomRightRadius: '50%',
        background: '#F1F5F9',
        color: '#044352',
    },
    "& .react-calendar__tile.react-calendar__tile--active, .react-calendar__tile.react-calendar button:enabled": {
        background: '#F1F5F9',
        color: '#044352',
        fontSize: '14px',
        fontWeight: 700,
    },
    "& .react-calendar__tile.react-calendar__tile--active:hover, .react-calendar__tile.react-calendar button:enabled:hover": {
        background: '#F1F5F9',
        color: '#044352',
        pointerEvents: "none",
    },
    "& .react-calendar__tile.react-calendar__month-view__days__day--neighboringMonth": {
        color: '#94A3B8'
    },
    "& .action-button-div": {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '15px 15px',
        gap: '20px'
    },
    "& .action-button-div button": {
        width: '140px',
        height: '50px',
        borderRadius: '7.21px',
        fontFamily: "Urbanist",
        textTransform: 'capitalize',
        fontSize: '14.42px',
    },
    "& .action-button-div .cancel-btn": {
        background: '#F1F5F9',
        color: "#044352",
        boxShadow: "0px 3.6px 3.6px 0px #00000040"
    },
    "& .action-button-div .save-btn.active": {
        background: '#044352',
        color: '#FFFFFF'
    },
    "& .action-button-div .save-btn": {
        background: '#044352',
        color: '#FFFFFF'
    },
});

export const AssessmentEmptyWrapper = styled(Box)({
    width: "100%",
    paddingBottom: "30px",
    height: "calc(100vh - 180px)",
    "& .addNewUserButton": {
        padding: "8px 60px",
        marginTop: "24px",
        height: "58px",
        borderRadius: "4px",
        background: "#044352",
    },
    "& .noDataImageBox": {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
    },
    "& .noDataNextText": {
        color: "#8A96A8",
        width: "40%",
        fontWeight: 400,
        fontFamily: "Urbanist",
        fontSize: "18px",
        lineHeight: "26px",
        textAlign: "center"
    },
    "& .gridContainer": {
        height: "100%"
    },
    "& .addNewUserText": {
        fontWeight: 700,
        fontFamily: "Urbanist",
        fontSize: "16px",
        lineHeight: "24px",
        color: "#FFFFFF",
        textTransform: "none"
    },
    "& .noDataMainText": {
        color: "#000104",
        fontFamily: "Urbanist",
        fontWeight: 700,
        fontSize: "30px",
        lineHeight: "40px",
    },
})


// Customizable Area End