import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import {setStorageData , getStorageData, removeStorageData} from "framework/src/Utilities";
import { ChangeEvent} from 'react';
interface UserLoginDetails {
  email: string;
  password: string;
}

interface LoginErrorResponse {
  failed_login?: string;
}

interface SignInErrorResponse {
  errors: {
    wrong_password?: string;
    failed_login?: string;
  }[];
}

interface LoginErrors {
  email: string;
  password: string;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  i18n?:any
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  wrongCredentialError: SignInErrorResponse;
  loginErrors:LoginErrors;
  showPasswordField: boolean;
  currentAction: "Sign In" | "Send Link" | "Resend e-mail";
  errorsFromApi:LoginErrorResponse[];
  loginDetails: UserLoginDetails;
  password: string;
  isForgotPassword: boolean;
  email: string;
  selectedLanguageSignIn: string;
  enablePasswordField: boolean;
  checkedRememberMe: boolean;
  placeHolderEmail: string;
  placeHolderPassword: string;
  imgPasswordVisible: any;
  imgPasswordInVisible: any;
  labelHeader: string;
  btnTxtLogin: string;
  labelRememberMe: string;
  btnTxtSocialLogin: string;
  labelOr: string;
  showEmailScreen: boolean;
  showEmailSent:boolean;
  showPassword: boolean;
  errorMsg: string;
  forgotMessage: string;
  incorrectEmail: string;
  wrongCredential: string;
  signInSuccessResponse: {
    meta?: {
      token: string;
      refresh_token: string;
      id: string;
      profile_id: string;
    }
  }
  underReview:boolean;
  loginScreen:boolean;
  clickCount: number;
  firstClickTime: number;
  isButtonDisabled: boolean;
  IsLoading: boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmailAccountLoginController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  loginApiCallId: string = "";
  signApiCallId: string = "";
  apiEmailLoginCallId: string = "";
  validationApiCallId: string = "";
  emailReg: RegExp;
  labelTitle: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];

    this.state = {
      wrongCredentialError : {
        errors: [
          { wrong_password: "", failed_login: "" }
        ]
      }  ,
      showPasswordField: true,
      currentAction: "Sign In",
      isForgotPassword: false,
      errorsFromApi:[],
      errorMsg: " ",
      loginErrors: {
        email: '',
        password: '',
      },
      loginDetails: {
        email: '',
        password: '',
      },
      email: "",
      password: "",
      showEmailScreen:false,
      showEmailSent:false,
      selectedLanguageSignIn: "",
      enablePasswordField: true,
      checkedRememberMe: false,
      placeHolderEmail: configJSON.placeHolderEmail,
      placeHolderPassword: configJSON.placeHolderPassword,
      imgPasswordVisible: configJSON.imgPasswordVisible,
      imgPasswordInVisible: imgPasswordInVisible,
      labelHeader: configJSON.labelHeader,
      btnTxtLogin: configJSON.btnTxtLogin,
      labelRememberMe: configJSON.labelRememberMe,
      btnTxtSocialLogin: configJSON.btnTxtSocialLogin,
      labelOr: configJSON.labelOr,
      showPassword:false,
      forgotMessage: "",
      incorrectEmail: "",
      wrongCredential: "",
      signInSuccessResponse: {},
      underReview:false,
      loginScreen:true,
      clickCount: 0,
      firstClickTime: 0,
      isButtonDisabled: false,
      IsLoading: true
    };

    this.emailReg = new RegExp("");
    this.labelTitle = configJSON.labelTitle;
    this.handleChangeLogin = this.handleChangeLogin.bind(this);
    this.handleClickShowPassword = this.handleClickShowPassword.bind(this);
    this.handleButtonClick = this.handleButtonClick.bind(this);
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    this.callGetValidationApi();
    this.send(new Message(getName(MessageEnum.RequestUserCredentials)));
    // Customizable Area Start
    const storedClickCount = localStorage.getItem('clickCount');
  const storedFirstClickTime = localStorage.getItem('firstClickTime');

  if (storedClickCount && storedFirstClickTime) {
    this.setState({
      clickCount: parseInt(storedClickCount, 10),
      firstClickTime: parseInt(storedFirstClickTime, 10),
    });
  }
  const language = await getStorageData("language") || "English";
  this.setState({ selectedLanguageSignIn: language });
  const signIn = await getStorageData("NaviGateRoute");
  if (typeof signIn !== "string") {
    this.setState({IsLoading: false});
  }

  setTimeout(() => {
    if (typeof signIn === "string") {
        const navigateMsg = new Message(getName(MessageEnum.NavigationMessage));
        navigateMsg.addData(getName(MessageEnum.NavigationTargetMessage), signIn);
        navigateMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(navigateMsg);
        removeStorageData("NaviGateRoute");
      }
    }, 2000);
    // Customizable Area End
  }

  // Customizable Area Start
  handleChangeLogin = (event: ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = event.target;
    this.setState((prevState) => {
      const updatedErrors = {
        ...prevState.loginErrors,
        [name]: "",
      };
      const updatedWrongCredentialError = {
        ...prevState.wrongCredentialError,
        errors: prevState.wrongCredentialError.errors.filter(error =>
          (name === 'email' && !error.failed_login) ||
          (name === 'password' && !error.wrong_password)
        ),
      };

      return {
        loginDetails: {
          ...prevState.loginDetails,
          [name]: value,
        },
        loginErrors: updatedErrors,
        wrongCredentialError: updatedWrongCredentialError,
      };
    });
  };


  handleClickShowPassword() {
    this.setState((prevState) => ({ showPassword: !prevState.showPassword }));
  }

  handleNavigateLanding=()=>{
    this.props.navigation.navigate("LandingPage");
  }


  signInApiCall = () => {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
    };
    const httpBody: any = {
      "data": {
        "type": "email_account",
        "attributes":{...this.state.loginDetails, "language":this.state.selectedLanguageSignIn === "English" ? "en" : "ar",},
      }
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.signApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.loginUserUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  forgotPasswordApi = () => {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
    };
    const httpBody: any = {
        "email":this.state.loginDetails.email.trim(),
        "language":this.state.selectedLanguageSignIn === "English" ? "en" : "ar",
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.loginApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.forgotPasswordUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  toggleForgotPassword = (): void => {
    this.setState((prevState) => ({
      isForgotPassword: !prevState.isForgotPassword,
      loginDetails: {
        ...prevState.loginDetails,
        password: prevState.isForgotPassword ? '' : prevState.loginDetails.password,
      },
    }));
  }

  goTosignin = () => {
    this.setState({loginScreen:true , underReview:false})
  }

  handleForgotPasswordClick = () => {
    this.setState({ currentAction: "Send Link" });
  };

  validateLoginForm = (): boolean => {
    const { email, password } = this.state.loginDetails;
    let isValid = true;
    const errors: LoginErrors = {
      email: '',
      password: '',
    };

    if (!email.trim()) {
      errors.email = this.props.i18n?.t("register.EmailBlank");
      isValid = false;
    } else if (email.length < 4) {
      errors.email = configJSON.emailMessage;
      isValid = false;
    }

    if (!password.trim()) {
      errors.password = this.props.i18n?.t("register.PasswordBlank");
      isValid = false;
    }

    this.setState({ loginErrors: errors });
    return isValid;
  };

  handleButtonClick(event: any) {
    event.preventDefault();
    const { currentAction, loginDetails } = this.state;
    const email = loginDetails.email;
  
    if (currentAction === "Sign In") {
      if (this.validateLoginForm()) {
        this.signInApiCall();
      }
    } else if (currentAction === "Send Link" || currentAction === "Resend e-mail") {
      const currentTime = new Date().getTime();
      const savedData = localStorage.getItem(email);
  
      let emailData = {
        clickCount: 0,
        firstClickTime: currentTime
      };
  
      if (savedData) {
        emailData = JSON.parse(savedData);
      }
  
      const { firstClickTime } = emailData;
      const timeDifference = currentTime - firstClickTime;
      const thirtyMinutesInMs = 30 * 60 * 1000;
      this.timeSetup(emailData,timeDifference,thirtyMinutesInMs,currentTime)
      
    }
  }

  handleCondition = (condition:any , truePart:any , falsePart:any) => {
    return condition ? truePart : falsePart
  }
  
  timeSetup = (emailData:any,timeDifference:any,thirtyMinutesInMs:any,currentTime:any) => {
    const { loginDetails } = this.state;
    const email = loginDetails.email;

    if (emailData.clickCount === 0 || timeDifference >= thirtyMinutesInMs) {
      emailData.clickCount = 1;
      emailData.firstClickTime = currentTime;

      this.setState({
        errorMsg: "",
      });
      localStorage.setItem(email, JSON.stringify(emailData));
      this.forgotPasswordApi();
    } else if (emailData.clickCount >= 5 && timeDifference < thirtyMinutesInMs) {
      this.setState({
        errorMsg: this.props.i18n?.t("register.toManyAttempt"),
        isButtonDisabled: true,
      });

      setTimeout(() => {
        emailData.clickCount = 0;
        emailData.firstClickTime = 0;
        this.setState({
          isButtonDisabled: false,
          errorMsg: "",
        });
        localStorage.setItem(email, JSON.stringify(emailData));
      }, thirtyMinutesInMs);
    } else {
      emailData.clickCount += 1;

      this.setState({
        errorMsg: "",
      });
      localStorage.setItem(email, JSON.stringify(emailData));
      this.forgotPasswordApi();
    }
  }

  goToSignup = () =>{
    this.props.navigation.navigate("EmailAccountRegistrationWeb")
  }

  CustomCheckBoxProps = {
    onChangeValue: (value: boolean) => {
      this.setState({ checkedRememberMe: value });
      this.CustomCheckBoxProps.isChecked = value;
    },
    isChecked: false,
  };

    loginResponseMessage(responseJson:any) {
      if (responseJson && responseJson.message) {
        this.setState({
          forgotMessage: responseJson.message,
          currentAction: "Resend e-mail"
        });
      } else {
        if (responseJson && responseJson.errors) {
          this.setState({
            loginErrors: {
              email: responseJson.errors || null,
              password: "" 
            }
          });
        }
      } 
    }
  
    signResponseMessage(responseJson:any) {
      if(responseJson.approval_state === "pending" || responseJson.approval_state === "rejected"){
        this.setState({ underReview: true, loginScreen:false })
      }
      if (responseJson && responseJson.errors) {
        this.setState({ wrongCredentialError: responseJson });
      } else if (responseJson && !responseJson.errors) {
        if(responseJson.meta?.approval_state === "approved" && responseJson.meta?.user_role === "hr_admin"){
        
          this.setState({ signInSuccessResponse: responseJson , underReview:false, loginScreen:false, IsLoading: true },
           async () => {
            await setStorageData("signInResponse", JSON.stringify(this.state.signInSuccessResponse))
            await setStorageData("language", responseJson.meta?.language === "en" ? "English" : "عربي");
            await setStorageData("NaviGateRoute","Dashboard")
            window.location.reload()
            })
        }
        else if(responseJson.meta?.approval_state === "approved" && (responseJson.meta?.user_role === "candidate" || 
        responseJson.meta?.user_role === "employee" ||
        responseJson.meta?.user_role === "admin" || responseJson.meta?.user_role === "hr_sub_admin"
        )){
        
          this.setState({ signInSuccessResponse: responseJson , underReview:false, loginScreen:false, IsLoading: true },
           async () => {
            await setStorageData("signInResponse", JSON.stringify(this.state.signInSuccessResponse))
            await setStorageData("language", responseJson.meta?.language === "en" ? "English" : "عربي");
            await setStorageData("NaviGateRoute","CandidateDashboard")
            window.location.reload()
            })
        }
        else if(responseJson.meta?.approval_state === "pending" || responseJson.meta?.approval_state === "rejected"){
          this.setState({ underReview: true, loginScreen:false })
        }
      }
    }


  


  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start



    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );


      if (apiRequestCallId != null) {
        if(apiRequestCallId === this.loginApiCallId){
          this.loginResponseMessage(responseJson)
        }
        if(apiRequestCallId === this.signApiCallId){
          this.signResponseMessage(responseJson)
        }
      }
    }

    // Customizable Area End
  }

  sendLoginFailMessage() {
    const msg: Message = new Message(getName(MessageEnum.LoginFaliureMessage));
    this.send(msg);
  }

  sendLoginSuccessMessage() {
    const msg: Message = new Message(getName(MessageEnum.LoginSuccessMessage));

    msg.addData(getName(MessageEnum.LoginUserName), this.state.email);
    msg.addData(getName(MessageEnum.CountyCodeDataMessage), null);
    msg.addData(getName(MessageEnum.LoginPassword), this.state.password);
    msg.addData(
      getName(MessageEnum.LoginIsRememberMe),
      this.state.checkedRememberMe
    );

    this.send(msg);
  }

  saveLoggedInUserData(responseJson: any) {
    if (responseJson && responseJson.meta && responseJson.meta.token) {
      const msg: Message = new Message(getName(MessageEnum.SessionSaveMessage));

      msg.addData(
        getName(MessageEnum.SessionResponseData),
        JSON.stringify(responseJson)
      );
      msg.addData(
        getName(MessageEnum.SessionResponseToken),
        responseJson.meta.token
      );

      this.send(msg);
    }
  }

  openInfoPage() {
    // Merge Engine - Navigation - btnEmailLogIn - Start
    const msg: Message = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
    // Merge Engine - Navigation - btnEmailLogIn - End
  }

  goToForgotPassword() {
    // Merge Engine - Navigation - btnForgotPassword - Start
    const msg: Message = new Message(
      getName(MessageEnum.NavigationForgotPasswordMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.NavigationForgotPasswordPageInfo), "email");
    this.send(msg);
    // Merge Engine - Navigation - btnForgotPassword - End
  }

  goToSocialLogin() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationSocialLogInMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  doEmailLogIn(): boolean {
    if (
      this.state.email === null ||
      this.state.email.length === 0 ||
      !this.emailReg.test(this.state.email)
    ) {
      this.showAlert("Error", configJSON.errorEmailNotValid);
      return false;
    }

    if (this.state.password === null || this.state.password.length === 0) {
      this.showAlert("Error", configJSON.errorPasswordNotValid);
      return false;
    }

    const header = {
      "Content-Type": configJSON.loginApiContentType,
    };

    const attrs = {
      email: this.state.email,
      password: this.state.password,
    };

    const data = {
      type: "email_account",
      attributes: attrs,
    };

    const httpBody = {
      data: data,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiEmailLoginCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.loginAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  callGetValidationApi() {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.validationApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.urlGetValidations
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }
}
