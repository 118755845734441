import React from "react";
// Customizable Area Start
import {
    Box,
    Grid,
    styled,
    Typography,
    Card,
    CardContent,
    Divider,
    Input,
    InputAdornment,
    Button,
    Dialog,
    DialogTitle,
    IconButton,
    DialogContent,
    DialogActions,
    CircularProgress
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { DashboardHeaderWeb as DashboardHeader } from "../../dashboard/src/DashboardHeader.web";
import { backArrow, blankCheckbox, selectedCheckbox, successImg } from '../../assessmenttest/src/assets';
// Customizable Area End

import EditAseessmentQuestionsController, {
    Props
} from "../src/EditAseessmentQuestionsController";
import { withTranslation } from "react-i18next";

export default class EditAseessmentQuestions extends EditAseessmentQuestionsController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    updateQuestionSuccess = () => {
        return (
            <Dialog
                onClose={this.handleUpdateClose}
                open={this.state.updateDialog}
                PaperProps={{
                    sx: {
                        borderRadius: "8px 8px 32px 8px",
                        direction: this.state.selectedLanguage.toLowerCase() === "english" ? "ltr" : "rtl",
                        overflow: 'hidden',
                    }
                }}
            >
                <DialogTitle>
                    <DialogWrapper>
                        <Typography className="editDialogTitle">
                            Edit Question
                        </Typography>
                        <IconButton
                            data-test-id="close_update_dialog"
                            aria-label="close"
                            onClick={this.handleUpdateClose}
                            className="updateDialogClose"
                            sx={{
                                left: this.state.selectedLanguage.toLowerCase() === "english" ? "auto" : 30,
                                right: this.state.selectedLanguage.toLowerCase() === "english" ? 30 : "auto",
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </DialogWrapper>
                </DialogTitle>
                <Divider sx={{ color: "#E2E8F0" }} />
                <DialogContent>
                    <DialogWrapper>
                        <Box className="updateImageBox">
                            <img src={successImg.default}
                                alt="Success Image"
                                height={144}
                                width={144} />
                        </Box>
                    </DialogWrapper>
                    <Typography
                        sx={{
                            fontFamily: "Urbanist",
                            fontSize: "20px",
                            fontWeight: 700,
                            padding: {
                                xs: "14px 14px",
                                sm: "14px 64px"
                            },
                            lineHeight: "28px",
                            color: "#0F172A",
                            textAlign: "center"
                        }}>
                        Question is updated successfully.
                    </Typography>
                    <Typography
                        sx={{
                            padding: "0px 68px",
                            fontFamily: "Urbanist",
                            fontWeight: 400,
                            lineHeight: "26px",
                            color: "#0F172A",
                            fontSize: "18px",
                            textAlign: "center"
                        }}>
                        Your updated question will be added to Question Bank.
                    </Typography>

                </DialogContent>
                <Divider />
                <DialogActions>
                    <DialogWrapper>
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: {
                                    xs: "center",
                                    sm: "flex-end"
                                }
                            }}
                            className={`${this.state.selectedLanguage.toLowerCase() === "english" ?
                                "updateButtonsBox" : "updateButtonsArabic"}`}
                        >
                            <Button
                                color="primary"
                                variant="contained"
                                onClick={this.handleUpdateClose}
                                data-test-id="CancelButton"
                                className={`${this.state.selectedLanguage.toLowerCase() === "english" ? "cancelUpdateButton" : "cancelUpdateArabic"}`}
                            >
                                Cancel
                            </Button>
                            <Button
                                color="primary"
                                variant="contained"
                                onClick={this.editQuestionApiCall}
                                data-test-id="UpdateDialog"
                                className={`${this.state.selectedLanguage.toLowerCase() === "english" ? "updateButton" : "updateButtonArabic"}`}
                            >
                                Save changes
                            </Button>
                        </Box>
                    </DialogWrapper>
                </DialogActions>
            </Dialog>
        )
    }

    editQuestion = () => {
        return (
            <Wrapper>
                <Typography className="questionLabel">
                    Question
                </Typography>

                <Input
                    data-test-id="EditQuestion"
                    className="optionField"
                    disableUnderline
                    value={this.state.editQuestion}
                    onChange={this.questionChange}
                    autoComplete="off"
                />
            </Wrapper>
        )
    }

    editOptions = () => {
        return (
            <Wrapper>
                <Typography className="questionLabel">
                    Options
                </Typography>

                <Box sx={{ fontFamily: 'Urbanist' }}>
                    {this.state.editOptions.map((options, index) => {
                        return (
                            <Box
                                display="flex"
                                alignItems="center"
                                mb={1}
                                sx={{ fontFamily: 'Urbanist' }}
                            >
                                <Box style={{ width: '100%' }}>
                                    <Input
                                        startAdornment={
                                            <InputAdornment position="start">
                                                <img
                                                    src={options.correct ? selectedCheckbox.default : blankCheckbox.default}
                                                    alt="checkbox"
                                                    className="checkBoxStyle"
                                                />
                                            </InputAdornment>
                                        }
                                        data-test-id={"EditOption"}
                                        multiline
                                        className={options.correct ? "optionsStyleTrue" : "optionField"}
                                        disableUnderline
                                        value={options.option_text}
                                        onChange={(event) => this.handleOptionChange(event as React.ChangeEvent<HTMLInputElement>, options.id)}
                                        autoComplete="off"
                                    />
                                    {
                                        this.state.ErrorId.map((err) => {
                                            return (
                                                <Typography className="errorStyle">
                                                    {err.id === options.id && err.ErrorText}
                                                </Typography>
                                            )
                                        })
                                    }
                                </Box>
                            </Box>
                        )
                    })}
                </Box>
            </Wrapper>
        )
    }

    editQuestionButton = () => {
        return (
            <Wrapper>
                <Grid container>
                    <Grid item xs={12}>
                        <Button
                            className="editBtn"
                            data-test-id="EditButton"
                            onClick={this.handleEditQuestion}
                        >
                            <Typography className="editQueText">
                                Edit Question
                            </Typography>
                        </Button>
                    </Grid>
                </Grid>
            </Wrapper>
        )
    }

    questionListCard = () => {
        return (
            <Wrapper>
                <Box className="editQueMainBox">
                    <Grid container className="editQueContainer" justifyContent={"flex-start"}>
                        <Grid item xs={12} className="editQueCardItem">
                            <Card className="queCardDescBox">
                                <CardContent className="cardContentPadding">
                                    <Grid container>
                                        <Grid item xs={12} className="orgDetailPadding">
                                            <Typography className="cardTitle">
                                                Edit Question
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Divider />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2} className="contentPadding">
                                        <Grid item xs={12}>
                                            {this.editQuestion()}
                                        </Grid>
                                        <Grid item xs={12}>
                                            {this.editOptions()}
                                        </Grid>
                                        <Grid item xs={12}>
                                            {this.editQuestionButton()}
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </Box>
            </Wrapper>
        )
    }
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <DashboardHeader selectedMenu="Assessments" navigation={this.props.navigation} id={this.props.id}>
                <MainWrapper>
                    {this.state.IsLoading ? <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: "center", height: '80vh' }}>
                        <CircularProgress color="inherit" className="backdrop" />
                    </Box> :
                        <Grid container justifyContent={"center"}>
                            <Grid item xs={11}>
                                <Grid container justifyContent={"space-between"} alignItems={"center"} spacing={1}>
                                    <Grid item xs={11} sm={11} md={7}>
                                        <Grid container spacing={1} alignItems={"center"}>
                                            <Grid item xs={12}
                                                sx={{
                                                    display: "flex",
                                                    alignItems: "center"
                                                }}>
                                                <img src={backArrow.default}
                                                    data-test-id="back_to_dashboard"
                                                    alt="Back Arrow"
                                                    className="backArrowImg"
                                                    onClick={this.navigateToListing}
                                                />
                                                <Typography className="editTitle">
                                                    Edit Question
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={11}>
                                {this.questionListCard()}
                            </Grid>
                            {this.updateQuestionSuccess()}
                        </Grid>
                    }
                </MainWrapper>
            </DashboardHeader>

            // Customizable Area End
        );
    }
}

// Customizable Area Start

export const EditAseessmentQuestionsWeb = withTranslation()(EditAseessmentQuestions)

export const MainWrapper = styled(Box)({
    display: "flex",
    justifyContent: "center",
    width: "100%",
    marginTop: "42px",
    paddingBottom: "30px",
    "& .backdrop": {
        color: "#044352",
        backgroundColor: "#fff",
        height: "100vh",
    },
    "& .errorStyle": {
        fontFamily: "Urbanist",
        color: "#F87171",
        fontSize: "16px",
        fontWeight: 400,
        fontStyle: "italic",
        display: "flex",
        justifyContent: "flex-end"
    },
    "& .backArrowImg": {
        paddingRight: "12px"
    },
    "& .editTitle": {
        display: "inline",
        fontFamily: "Urbanist",
        fontSize: "25px",
        fontWeight: 600,
        marginLeft: "8px",
        color: "#000104"
    },
})
export const Wrapper = styled(Box)({
    width: "100%",
    "& .contentPadding": {
        padding: "30px 56px 30px 35px"
    },
    "& .editQueMainBox": {
        overflow: "hidden",
        width: "100%",
        display: "flex",
        justifyContent: "center",
    },
    "& .editBtn": {
        marginTop: "26px",
        borderRadius: "4px 0px 0px 0px",
        height: "65px",
        padding: "10px 16px 10px 16px",
        background: "#044352",
        width: "100%"
    },
    "& .cardTitle": {
        fontFamily: "Urbanist",
        fontSize: "20px",
        fontWeight: 600,
        lineHeight: "24px",
        color: "#0444352"
    },
    "& .editQueText": {
        fontWeight: 700,
        lineHeight: "24px",
        fontFamily: "Urbanist",
        fontFize: "16px",
        textTransform: "none",
        color: "#FFFFFF"
    },
    "& .checkBoxStyle": {
        marginRight: "10px",
        width: "20px",
        height: "20px",
    },
    "& .questionLabel": {
        fontFamily: "Urbanist",
        fontSize: "14px",
        fontWeight: 700,
        linHheight: "22px",
        color: "#344054",
        marginBottom: "10px"
    },
    "& .editQueContainer": {
        overflowX: "auto",
        scrollSnapType: "x mandatory",
        display: "flex",
        justifyContent: "flex-start",
        width: "100%",
        scrollBehavior: "smooth",
        padding: "0px 8px",
        scrollbarWidth: "none",
        "&::-webkit-scrollbar": {
            display: "none",
        },
    },
    "& .editOptionsStyle": {
        color: "#000000",
        width: "95%",
        fontSize: '14px',
        fontWeight: 400,
        fontFamily: 'Urbanist',
        cursor: "pointer",
        "&:hover": { backgroundColor: "transparent" },
        border: '1px solid #E2E8F0',
        height: '50px',
        lineHight: "24px",
        textAlign: "left",
        borderRadius: '8px',
        padding: '0px 16px',
        "& .MuiTypography-root": {
            fontWeight: 400,
            fontFamily: 'Urbanist',
            fontSize: "14px",
            linHheight: "22px",
        },
    },
    "& .optionField": {
        fontWeight: 600,
        lineHight: "24px",
        fontFamily: 'Urbanist',
        border: '1px solid #E2E8F0',
        fontSie: '16px',
        textAlign: "left",
        borderRadius: '8px',
        height: '66px',
        padding: '12px 16px',
        width: '100%'
    },
    "& .optionsStyleTrue": {
        fontWeight: 600,
        fontFamily: 'Urbanist',
        fontSie: '16px',
        textAlign: "left",
        borderRadius: '8px',
        padding: '12px 16px',
        width: '100%',
        border: "1px solid #059669",
        backgroundColor: "#D1FAE5",
        "&:hover": { backgroundColor: "#D1FAE5" },
        "& .MuiTypography-root": {
            fontWeight: 400,
            fontFamily: 'Urbanist',
            fontSize: "14px",
            linHheight: "22px",
        },
    },
    "& .orgDetailPadding": {
        padding: "30px 56px 30px 35px"
    },
    "& .cardContentPadding": {
        padding: "0px 0px 26px 0px"
    },
    "& .editQueCardItem": {
        flex: "0 0 auto",
        scrollSnapAlign: "center",
    },
    "& .queCardDescBox": {
        boxShadow: "rgba(0, 0, 0, 0.1) 0px 14px 144px 0px",
        width: "55vw",
        margin: "4vh 3vw",
        "@media(max-width:900px)": {
            width: "80vw"
        },
    },
})
export const DialogWrapper = styled(Box)({
    "& .updateButton": {
        opacity: "0px",
        fontFamily: "Urbanist",
        fontSize: "16px",
        fontWeight: 700,
        borderRadius: "8px",
        gap: "8px",
        height: "56px",
        padding: "16px 34px",
        marginLeft: "16px",
        background: "#044352",
        textTransform: "none",
        "&:hover": {
            backgroundColor: "#044352"
        },
    },
    "& .editDialogTitle": {
        lineHeight: "32px",
        color: "#1E1E1E",
        fontSize: "24px",
        fontFamily: "Urbanist",
        fontWeight: 700,
        margin: "8px 16px"
    },
    "& .cancelUpdateArabic": {
        padding: "16px 39.5px 16px 36.5px",
        background: "#F1F5F9",
        borderRadius: "8px",
        gap: "8px",
        fontFamily: "Urbanist",
        height: "56px",
        marginRight: "16px",
        textTransform: "none",
        opacity: "0px",
        fontSize: "16px",
        fontWeight: 700,
        width: "120px",
        color: "#000",
        "&:hover": {
            backgroundColor: "#F1F5F9"
        },
    },
    "& .updateDialogClose": {
        position: 'absolute',
        color: "#334155",
        top: 16
    },
    "& .updateButtonArabic": {
        height: "56px",
        gap: "8px",
        padding: "16px 39.5px 16px 36.5px",
        borderRadius: "8px",
        opacity: "0px",
        fontSize: "16px",
        marginRight: "16px",
        fontWeight: 700,
        fontFamily: "Urbanist",
        background: "#044352",
        textTransform: "none",
        width: "120px",
        "&:hover": {
            backgroundColor: "#044352"
        },
    },
    "& .cancelUpdateButton": {
        borderRadius: "8px",
        padding: "16px 34px",
        fontWeight: 700,
        marginLeft: "16px",
        gap: "8px",
        fontFamily: "Urbanist",
        fontSize: "16px",
        height: "56px",
        opacity: "0px",
        background: "#F1F5F9",
        textTransform: "none",
        color: "#000",
        "&:hover": {
            backgroundColor: "#F1F5F9"
        },
    },
    "& .updateButtonsBox": {
        margin: "16px 0px",
        paddingRight: "16px",
        display: "flex",
        justifyContent: "flex-end",
    },
    "& .updateButtonsArabic": {
        paddingLeft: "16px",
        margin: "16px 0px",
        display: "flex",
        justifyContent: "flex-end"
    },
    "& .updateImageBox": {
        display: "flex",
        justifyContent: "center",
        padding: "10px 0px",
    },
})
// Customizable Area End