import React from "react";

// Customizable Area Start
import {
    Box,
    Grid,
    Typography,
    Button,
    CircularProgress,
    IconButton,
    Pagination,
    Checkbox,
    Divider,
} from "@mui/material";
import { arrow_Left, ListIcon, UserSmallIcon } from "./assets";
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { DashboardHeaderWeb as DashboardHeader } from "../../dashboard/src/DashboardHeader.web";

import { withTranslation } from "react-i18next";
import ViewAssessmentController, {
    Props,
} from "./ViewAssessmentController";
import { styled } from "@mui/system";
import { HeaderContainer } from "./AssessmentTestDashbord.web";
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import { removeStorageData } from "../../../../packages/framework/src/Utilities";
// Customizable Area End

export default class ViewAssessment extends ViewAssessmentController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start





    RenderAllScreens = () => {
        if (this.state.isLoading) {
            return <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80vh' }}>
                <CircularProgress style={{ color: "#044352" }} />
            </Box>
        } else {
            return this.RenderTable()
        }

    }

    RenderTable = () => {
        return (
            <Grid container>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Box data-test-id="BackToJobDescription"
                        style={{
                            display: "flex", alignItems: "center", justifyContent: "flex-start",
                            marginBottom: "20px",
                            cursor: "pointer"
                        }}
                        onClick={() => {
                            this.NaviGateToAnyPage("AssessmentTestDashbord");
                            removeStorageData("assessmentId")
                        }}
                    >
                        <Box className="BackArrowBox">
                            <img src={arrow_Left.default}
                                alt="Back Arrow"
                            />
                        </Box>
                        <Typography className="HeadingTitle">Assessment Name</Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Grid container spacing={2} justifyContent={"flex-end"}>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                            <Button
                                data-test-id="ExportAllButton"
                                className="OutlinedButton"
                                variant="outlined"
                                fullWidth
                                onClick={() => {
                                    this.NaviGateToAnyPage("AssignAssessment")
                                }}
                            >
                                <Typography className="ButtonText">
                                    Assign
                                </Typography>
                            </Button>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                            <Button
                                data-test-id="ExportAllButton"
                                className="ContainedButton"
                                variant="outlined"
                                fullWidth
                                onClick={() => {
                                    this.NaviGateToAnyPage("EditAssessment")
                                }}
                            >
                                <Typography className="ButtonText">
                                    Edit Assessment
                                </Typography>
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} className="TableContainer" style={{ marginTop: "70px" }}>
                    <HeaderContainer>
                        <Box className="CardContainer">
                            <Typography className="SmallTitle">Assessment Name</Typography>
                            <Box>
                                <Divider style={{ color: "#E2E8F0", marginTop: "10px" }} />
                            </Box>
                            <Box>
                                <Box className="GreyColorContainer" >
                                    <Grid container spacing={2} justifyContent={"center"}>
                                        <Grid item xs={12} sm={12} md={10} lg={10}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12} sm={12} md={8} lg={8}>
                                                    <Grid container justifyContent={"center"} alignItems={"center"} spacing={6}>
                                                        <Grid item xs={12} sm={12} md={6} lg={6}>
                                                            <Box className="IconContainer" >
                                                                <Box>
                                                                    <img src={UserSmallIcon.default} alt="UserSmallIcon" />
                                                                </Box>
                                                                <Box>
                                                                    <Typography className="IconSmallTitle">
                                                                        Job Title
                                                                    </Typography>
                                                                    <Typography className="SmallIconTitle">
                                                                        {this.state.JobTitle}
                                                                    </Typography>
                                                                </Box>
                                                            </Box>
                                                        </Grid>
                                                        <Grid item xs={12} sm={12} md={6} lg={6}>
                                                            <Box className="IconContainer" >
                                                                <Box>
                                                                    <img src={ListIcon.default} alt="UserSmallIcon" />
                                                                </Box>
                                                                <Box>
                                                                    <Typography className="IconSmallTitle">
                                                                        Difficulty Level
                                                                    </Typography>
                                                                    <Typography className="SmallIconTitle">
                                                                        {this.state.DifficultyLevel.map((item, index) => item).join(', ')}
                                                                    </Typography>
                                                                </Box>
                                                            </Box>
                                                        </Grid>
                                                        <Grid item xs={12} sm={12} md={6} lg={6}>
                                                            <Box className="IconContainer" >
                                                                <Box>
                                                                    <img src={ListIcon.default} alt="UserSmallIcon" />
                                                                </Box>
                                                                <Box>
                                                                    <Typography className="IconSmallTitle">
                                                                        Focus Areas
                                                                    </Typography>
                                                                    <Typography className="SmallIconTitle">
                                                                        {this.state.FocusAreas.map((item, index) => item).join(', ')}
                                                                    </Typography>
                                                                </Box>
                                                            </Box>
                                                        </Grid>
                                                        <Grid item xs={12} sm={12} md={6} lg={6}>
                                                            <Box className="IconContainer" >
                                                                <Box>
                                                                    <img src={ListIcon.default} alt="UserSmallIcon" />
                                                                </Box>
                                                                <Box>
                                                                    <Typography className="IconSmallTitle">
                                                                        Question Count
                                                                    </Typography>
                                                                    <Typography className="SmallIconTitle">
                                                                        {this.state.QuestionCount}
                                                                    </Typography>
                                                                </Box>
                                                            </Box>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={4} lg={4}>
                                                    <Grid container>
                                                        <Box className="IconContainer CompetenciesContainer">
                                                            <Box>
                                                                <img src={ListIcon.default} alt="UserSmallIcon" />
                                                            </Box>
                                                            <Box>
                                                                <Typography className="IconSmallTitle">
                                                                    Competencies
                                                                </Typography>
                                                                {
                                                                    this.state.Competencies.map((item) => {
                                                                        return (
                                                                            <Typography className="SmallIconTitle">
                                                                                {item}
                                                                            </Typography>
                                                                        )
                                                                    })
                                                                }
                                                            </Box>
                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Box>
                                <Grid container spacing={2} justifyContent={"center"} style={{ marginTop: "10px" }}>
                                    <Grid item xs={12}>
                                        {Array.isArray(this.state.QuestionAnswerData) && this.PaginationData().map((item) => {
                                            let AnswerId: Array<number> = []
                                            let CorrectIds: Array<number> = []

                                            if (Array.isArray(item.Answers)) {
                                                AnswerId = item.Answers.filter((Answersitem) => Answersitem.isCorrect).map((Answersitem) => Answersitem.Id)
                                            }
                                            if (Array.isArray(item.CorrectChoices)) {
                                                CorrectIds = item.CorrectChoices.map((Correctitem) => Correctitem.Correctid)
                                            } else {
                                                CorrectIds = item.Answers.map((Answersitem) => Answersitem?.Id)
                                            }
                                            let isCorrect =AnswerId.every((item) => CorrectIds.includes(item))

                                            return (
                                                <Grid container justifyContent={"center"}>
                                                    <Grid item xs={12} sm={12} md={10} lg={10}>
                                                        <Grid container style={{ margin: "10px 0px" }}>
                                                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                                                <Typography className="QuestionTextTitle">
                                                                    {item.Index}.{item.QuestionText}
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                                                                    <Typography style={{
                                                                        fontFamily: 'Urbanist',
                                                                        fontWeight: 400,
                                                                        fontSize: '14px',
                                                                        marginRight: '10px',
                                                                        lineHeight: '22px'
                                                                    }}>
                                                                        {isCorrect ? "Correct Answers" : "Wrong Answers"}
                                                                    </Typography>
                                                                    {isCorrect ? <IconButton size="small" style={{ backgroundColor: "#D1FAE5", padding: "2px" }}>
                                                                        <CheckCircleOutlineOutlinedIcon style={{ color: "green" }} />
                                                                    </IconButton> :
                                                                        <IconButton size="small" style={{ backgroundColor: "#ff000029", padding: "2px" }}>
                                                                            <HighlightOffOutlinedIcon style={{ color: "red" }} />
                                                                        </IconButton>
                                                                    }
                                                                </div>
                                                            </Grid>
                                                            <Grid item xs={12} sm={12} md={12} lg={12} style={{ marginTop: "10px" }}>
                                                                {
                                                                    Array.isArray(item.Answers) && item.Answers.map((AnswerItem) => {
                                                                        return (
                                                                            <Box className={`QuetionAnswerContainer ${AnswerItem.isCorrect ? " CorrectAnswerContainer " : ""} `}>
                                                                                <Checkbox
                                                                                    indeterminate={AnswerItem.isCorrect}
                                                                                    checked={AnswerItem.isCorrect}
                                                                                    inputProps={{ 'aria-label': 'select all desserts' }}
                                                                                    indeterminateIcon={<CheckBoxIcon />}
                                                                                    style={{
                                                                                        paddingBottom: '0px',
                                                                                        paddingTop: '0px',
                                                                                    }}
                                                                                    data-test-id="select_all_checkbox"
                                                                                    sx={{
                                                                                        '&.Mui-checked': {
                                                                                            color: "#044352",
                                                                                        },
                                                                                    }}
                                                                                />
                                                                                <Typography className="AnswerText">
                                                                                    {AnswerItem.ChoiceText}
                                                                                </Typography>
                                                                            </Box>
                                                                        )
                                                                    })
                                                                }
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                                        <Divider style={{ color: "#E2E8F0", margin: "10px 0px" }} />
                                                    </Grid>
                                                </Grid>
                                            )
                                        })}
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <div style={{ display: "flex", justifyContent: "center" }}>
                                            <Pagination
                                                color="primary"
                                                count={Math.ceil(this.state.QuestionAnswerData.length / 5)}
                                                page={this.state.currentPage}
                                                style={{
                                                    direction: "ltr"
                                                }}
                                                data-test-id="pagination"
                                                onChange={(_e, index) => {
                                                    this.setState({
                                                        currentPage: index
                                                    })
                                                }}
                                            />
                                        </div>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                    </HeaderContainer>
                </Grid>
            </Grid>
        )
    }

    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <DashboardHeader selectedMenu="Assessments" navigation={this.props.navigation} id={this.props.id}>
                <ViewAssessmentStyle>
                    {this.RenderAllScreens()}
                </ViewAssessmentStyle>
            </DashboardHeader>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
export const ViewAssessmentWeb = withTranslation()(ViewAssessment)

export const ViewAssessmentStyle = styled(Box)({
    display: "flex",
    justifyContent: "center",
    marginTop: "42px",
    padding: "0px 30px",
    "& .CardContainer": {
        backgroundColor: "#FFFFFF",
        borderRadius: "8px",
        padding: "16px",

    },
    "& .GreyColorContainer": {
        backgroundColor: "#FCFCFD",
        margin: "26px 0",
        padding: "26px 0",
        border: '1px solid #ECECEC'
    },
    "& .SmallTitle": {
        fontFamily: 'Urbanist',
        fontWeight: 600,
        fontSize: '20px',
        lineHeight: '24px',
    },
    "& .IconSmallTitle": {
        fontFamily: 'Urbanist',
        fontWeight: 700,
        fontSize: '14px',
        lineHeight: '22px'
    },
    "& .SmallIconTitle": {
        fontFamily: 'Urbanist',
        fontWeight: 400,
        fontSize: '14px',
        color: "#0C2755B2"
    },
    "& .AnswerText": {
        fontFamily: 'Inter',
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '22px',
    },
    "& .IconContainer": {
        display: "flex",
        gap: "10px",
        "@media (max-width: 425px)": {
            marginLeft: "20px"
        },
    },
    "& .CompetenciesContainer": {
        "@media (max-width: 425px)": {
            paddingTop: "28px"
        },
    },
    "& .QuestionTextTitle": {
        fontFamily: 'Inter',
        fontWeight: '700',
        fontSize: '16px',
        lineHeight: '24px'
    },
    "& .QuetionAnswerContainer": {
        borderWidth: '1px',
        padding: '10px',
        borderRadius: '14px',
        border: '1px solid #E2E8F0',
        backgroundColor: "#F8FAFC",
        margin: '5px 0px',
        display: 'flex',
        alignItems: 'flex-start',
    },
    "& .CorrectAnswerContainer": {
        border: '1px solid #059669 !important',
        backgroundColor: "#D1FAE5 !important",
    },
    "& .HeadingTitle": {
        fontFamily: 'Urbanist',
        fontWeight: 600,
        fontSize: '25px',
        lineHeight: '30px'
    },
    "& .OutlinedButton": {
        padding: "10px 16px",
        backgroundColor: "#FFFFFF",
        borderColor: "#94A3B8",
        height: "58px",
        borderRadius: "4px",
        textTransform: "none",
        fontWeight: 500,
        "&:hover": {
            backgroundColor: "#FFFFFF",
            borderColor: "#94A3B8",
        },
        "& .MuiTypography-root": {
            fontWeight: 700,
            fontFamily: "Urbanist",
            fontSize: "16px",
            lineHeight: "28px",
            color: "#044352",
        }
    },
    "& .ContainedButton": {
        padding: "10px 16px",
        backgroundColor: "#044352",
        borderColor: "#94A3B8",
        height: "58px",
        borderRadius: "4px",
        textTransform: "none",
        fontWeight: 500,
        "&:hover": {
            backgroundColor: "#044352",
            borderColor: "#94A3B8",
        },
        "& .MuiTypography-root": {
            lineHeight: "28px",
            fontFamily: "Urbanist",
            fontSize: "16px",
            fontWeight: 700,
            color: "#FFFFFF",
        }
    },
    "& .BackArrowBox": {
        display: "flex", alignItems: "center",
        justifyContent: "center",
        width: "47px", height: "47px",
        marginRight: "10px",
        border: "1px solid #ECECEC", borderRadius: "50%",
        cursor: "pointer"
    },
    "& .userEnterInputField": {
        fontSie: '16px',
        fontWeight: 400,
        fontFamily: 'Urbanist',
        border: '1px solid lightgray',
        lineHight: "24px",
        textAlign: "left",
        borderRadius: '8px',
        height: '50px',
        padding: '12px 16px',
        width: '100%'
    },
    "& .userInputLabel": {
        fontSize: "14px",
        fontWeight: 700,
        fontFamily: "Urbanist",
        linHheight: "22px",
        color: "#344054",
        marginBottom: "2px"
    },
    "& .genderDropDown": {
        fontSie: '16px',
        fontWeight: 400,
        fontFamily: 'Urbanist',
        border: '1px solid lightgray',
        lineHight: "24px",
        borderRadius: '8px',
        height: '50px',
        padding: '12px 16px',
        width: '100%'
    },
})


// Customizable Area End