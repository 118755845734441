// Customizable Area Start
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";

import React, { ReactNode } from "react";
import { getStorageData } from "framework/src/Utilities";
import { i18n } from "i18next";

interface AssessmentResponse {
    message: string;
    assessment: {
        data: {
            id: string;
            type: string;
            attributes: {
                id: number;
                title: string;
                time_allocation: number;
                objective: string;
                number_of_questions: number;
                competencies: {
                    id: number;
                    job_title: string;
                    description: string;
                    competency_name: string;
                    job_description_id: number;
                    created_at: string;
                    updated_at: string;
                    competency_type_id: number;
                    status: boolean;
                    competency_dictionary_id: number;
                }[];
                status: string;
                creator: {
                    id: number;
                    name: string;
                };
                difficulty_levels: {
                    id: number;
                    name: string;
                }[];
                focus_areas: {
                    id: number;
                    name: string;
                }[];
                question_formats: {
                    id: number;
                    name: string;
                }[];
                job_description: {
                    id: number;
                    job_title: string;
                };
                questions: {
                    data: {
                        id: string;
                        type: string;
                        attributes: {
                            id: number;
                            text: string;
                            question_format_name: string;
                            question_bank_id: number;
                            assessment_id: number;
                            created_at: string;
                            job_description: {
                                id: number;
                                name: string;
                            };
                            competencies: [];
                            difficulty_level: {
                                id: number;
                                name: string;
                            };
                            answer: {
                                id: number;
                                answer_type: string;
                                choices: {
                                    id: number;
                                    option_text: string;
                                    correct: boolean;
                                }[];
                                correct_choices: {
                                    id: number;
                                    option_text: string;
                                    correct: boolean;
                                }[];
                                text_response: string;
                            };
                        };
                    };
                }[];
            };
        };
    };
}

// Customizable Area End

export const configJSON = require("./config");
export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    children?: ReactNode;
    selectedMenu?: string;
    i18n?: i18n;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    token: string;
    selectedLanguage: string;
    isLoading: boolean;
    QuestionAnswerData: Array<{
        Index: number;
        QuestionText: string;
        Answers: Array<{
            Id: number
            ChoiceText: string;
            isCorrect: boolean;
        }>;
        CorrectChoices: Array<{
            Correctid: number;
            CorrectText: string;
        }>;
    }>;
    currentPage: number;
    JobTitle: string,
    DifficultyLevel: Array<string>,
    FocusAreas: Array<string>,
    QuestionCount: string,
    Competencies: Array<string>

    // Customizable Area End

}

interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class ViewAssessmentController extends BlockComponent<
    Props,
    S,
    SS
> {

    // Customizable Area Start
    GetAssessmentData: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.CountryCodeMessage)
        ];

        this.state = {
            token: '',
            selectedLanguage: '',
            isLoading: false,
            QuestionAnswerData: [],
            currentPage: 1,
            JobTitle: "",
            DifficultyLevel: [],
            FocusAreas: [],
            QuestionCount: "",
            Competencies: []
        };

        // Customizable Area End
        runEngine.attachBuildingBlock(this, this.subScribedMessages);
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        runEngine.debugLog("Message Recived", message);
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            this.GetAssessmentApiResponse(apiRequestCallId, responseJson);
        }
        // Customizable Area End
    }

    // Customizable Area Start



    async componentDidMount() {
        const tokenMeta = await getStorageData('signInResponse', true);
        this.setState({
            token: tokenMeta?.meta?.token
        })
        const language = await getStorageData("language") || "English";
        this.setState({ selectedLanguage: language });
        this.GetAssessmentDataFuncation()
    }

    TraslationFuncation = (key: string) => {
        if (this.props.i18n) {
            return this.props.i18n.t(key);
        }
    }



    handleCondition = (condition: boolean, truePart: React.ReactNode, falsePart: React.ReactNode) => {
        return condition ? truePart : falsePart
    }

    GetAssessmentDataFuncation = async () => {
        const AssessmentId = await getStorageData("assessmentId") as string
        this.setState({ isLoading: true });
        const headers = {
            "token": this.state.token
        };
        const getAllUsersMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.GetAssessmentData = getAllUsersMsg.messageId;
        getAllUsersMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        getAllUsersMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.GetAllAssessment}/${AssessmentId}`
        );
        getAllUsersMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getAssessementMethod
        );
        runEngine.sendMessage(getAllUsersMsg.id, getAllUsersMsg);
    }

    GetAssessmentApiResponse = (ApiCallId: string, Response: AssessmentResponse) => {
        if (ApiCallId === this.GetAssessmentData) {
            const QuestionAnswerData = Response.assessment.data.attributes.questions.map((item, index) => {
                return {
                    Index: index + 1,
                    QuestionText: item.data.attributes.text,
                    Answers: item.data.attributes.answer.choices ? item.data.attributes.answer.choices?.map((item) => {
                        return {
                            Id: item.id,
                            ChoiceText: item.option_text,
                            isCorrect: item.correct
                        }
                    }) : [{
                        Id: item.data.attributes.answer.id,
                        ChoiceText: item.data.attributes.answer.text_response,
                        isCorrect: true
                    }],
                    CorrectChoices: item.data.attributes.answer.correct_choices?.map((CorrectItem) => {
                        return {
                            Correctid: CorrectItem.id,
                            CorrectText: CorrectItem.option_text,
                        }
                    }),
                }
            })
            if (QuestionAnswerData.length !== 0) {
                this.setState({
                    QuestionAnswerData: QuestionAnswerData,
                    JobTitle: Response.assessment.data.attributes.job_description.job_title,
                    DifficultyLevel: Response.assessment.data.attributes.difficulty_levels.map((item) => item.name),
                    FocusAreas: Response.assessment.data.attributes.focus_areas.map((item) => item.name),
                    QuestionCount: `${Response.assessment.data.attributes.number_of_questions}`,
                    Competencies: Response.assessment.data.attributes.competencies.map((item) => item.competency_name)
                })
            }
            this.setState({ isLoading: false });
        }
    }

    PaginationData = () => {
        const { currentPage } = this.state;

        const startIndex = (currentPage - 1) * 5;
        const currentData = this.state.QuestionAnswerData.slice(startIndex, startIndex + 5);
        return currentData
    }

    NaviGateToAnyPage = (PageRoute: string) => {
        const navigateMsg = new Message(getName(MessageEnum.NavigationMessage));
        navigateMsg.addData(getName(MessageEnum.NavigationTargetMessage), PageRoute);
        navigateMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(navigateMsg);
    }
    // Customizable Area End

}