import React from "react";

// Customizable Area Start
import {
    Box,
    Grid,
    Typography,
    Card,
    CardContent,
    Divider,
    Select,
    InputBase,
    MenuItem,
    Button,
    Modal,
    CircularProgress,
    LinearProgress,
    SelectChangeEvent,
    Input,
    InputAdornment,
    IconButton,
    styled
} from "@mui/material";
import { DashboardHeaderWeb as DashboardHeader } from "../../dashboard/src/DashboardHeader.web";
import {
    Formik,
    Form,
    ErrorMessage
} from "formik";
import {
    arrow_Left,
    progress,
    right
} from "./assets";
import AddIcon from '@mui/icons-material/Add'
import CustomPopup from "../../../components/src/CustomSuccessModel.web";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { withTranslation } from "react-i18next";
import { MainWrapper, Wrapper } from "./Cfchatgptintegration9.web"
import EditJobDescriptionController, {
    Props,
} from "./EditJobDescriptionController";
import { DeleteOutline } from "@mui/icons-material";
// Customizable Area End


export default class EditJobDescription extends EditJobDescriptionController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start

    progressDataScreen = () => {
        return (
            <Grid container justifyContent={"center"}
                style={{ direction: "ltr" }} >
                <Grid item xs={11}>
                    <Grid container
                        spacing={1} alignItems={"center"}>
                        <Grid item xs={1.5} sm={1} md={0.8} lg={0.5}  >
                            <Box data-test-id="BackToJobDescription" onClick={this.BackToJobDescription}
                                style={{
                                    width: "47px", height: "47px", border: "1px solid #ECECEC",
                                    borderRadius: "50%",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center"
                                }}
                            >
                                <img src={arrow_Left.default} alt="Back Arrow" />
                            </Box>
                        </Grid>
                        <Grid item xs={10.5} sm={11} md={11.2} lg={11.5}>
                            <Typography className="jobTitle">
                                {this.TraslationFuncation("JobDescriptionPage.GeneratingJobDescription")}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container className={"jobMainHeaderGrid"} spacing={1}>
                        <Grid item xs={12} sm={12} md={11.2} lg={11.5}>
                            <Wrapper>
                                <Box
                                    className="progressMainContainer">
                                    <Box
                                        className="progressContainer">
                                        <Box
                                            className="progressImage">
                                            <img src={progress.default} />
                                        </Box>
                                        <Box
                                            className="dotsProgress">
                                            {[0, 1, 2, 3, 4, 5, 6].map((obj, index) => <Box key={index} style={{
                                                width: "7.5px", height: "7.5px",
                                                borderRadius: "50%", backgroundColor: "#059669",
                                                transform: (index === this.state.active, "scale(1.5)", "scale(1)")
                                            }}></Box>)}
                                        </Box>
                                        <Box
                                            className="linearProgressBar">
                                            <LinearProgress className="linearProgress" value={this.state.progress} variant="determinate"
                                            />
                                        </Box>
                                        <Box className="ProgressTextContain">
                                            <Typography className="progressTextRunning">
                                                {this.state.progress >= 100 ? 100 : this.state.progress}{this.TraslationFuncation("JobDescriptionPage.OnePercentCompleteFinalisingJobDescription")}
                                            </Typography>
                                        </Box>
                                        <Box className="ProgressCreationContent newcalss">
                                            <Typography className="progressContent1 newcalss">

                                                {this.TraslationFuncation("JobDescriptionPage.HangTightJobDescriptionCreationInProgress")}

                                            </Typography>
                                            <Typography className="progressContent2 newcalss">

                                                {this.TraslationFuncation("JobDescriptionPage.WaitWeGenerateDescription")}

                                            </Typography>
                                        </Box>
                                    </Box>
                                </Box>
                            </Wrapper>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        )
    }

    loaderScreen = () => {
        return (
            <>
                <Box className="loader-outer"
                    sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "80vh" }}>
                    <Box className="loader-inner">
                        <CircularProgress />
                    </Box>
                </Box>
            </>
        )
    }

    RenderEditJobID = (_handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
        handleBlur: {
            (e: React.FocusEvent<React.FocusEventHandler<HTMLInputElement>>): void;
            <T = React.FocusEventHandler<HTMLInputElement>>(fieldOrEvent: T): T extends string ? (e: React.FocusEventHandler<HTMLInputElement>) => void : void;
        },
    ) => {
        return (<Grid item xs={12} sm={12} md={6} lg={6}>
            <Wrapper>
                <Box className="errorStyleMainbox">
                    <Typography className="cardInputLabel">
                        {this.TraslationFuncation("JobDescriptionPage.JobID")} <span>*</span>
                    </Typography>
                    <ErrorMessage name="EditJobId" component="div" className="errorStyle" />
                </Box>
                <input
                    className="dropDownStyle"
                    placeholder={this.TraslationFuncation("JobDescriptionPage.SpecifyYourTitle")}
                    data-test-id="JobId"
                    name="jobTitle"
                    disabled
                    onBlur={handleBlur}
                    value={this.state.EditJobId}
                />
            </Wrapper>
        </Grid>
        )
    }

    renderjobfamily = (selected: number) => {
        const selectedfamily = this.state.jobFamily?.
            find((option) => option.id === selected);
        return selectedfamily ? selectedfamily.name : (
            <div
                className="dropDownStyleplaceHolder">
                {this.TraslationFuncation("JobDescriptionPage.SpecifyYourJobFamily")}
            </div>
        );
    }
    RenderJobFamilyTextfiled = (
        handleChange: (e: SelectChangeEvent<string>) => void,

        handleBlur: {
            (e: React.FocusEvent<React.FocusEventHandler<HTMLInputElement>>): void;
            <T = React.FocusEventHandler<HTMLInputElement>>(fieldOrEvent: T): T extends string ? (e: React.FocusEventHandler<HTMLInputElement>) => void : void;
        },

        ValuesJobFamily: string
    ) => {
        return (
            <Grid xs={12} item md={6}>
                <Wrapper>
                    <Box className="errorStyleMainbox">
                        <Typography className="cardInputLabel">
                            {this.TraslationFuncation("JobDescriptionPage.JobFamily")} <span>*</span>
                        </Typography>
                        <ErrorMessage name="EditJobFamily" component="div" className="errorStyle" />
                    </Box>
                    <Select
                        data-test-id="EditsetJobFamily"
                        inputProps={{
                            icon: {
                                left: 0,
                            },
                        }}
                        className={`dropDownStyle`}
                        variant="outlined"
                        name="EditJobFamily"
                        onBlur={handleBlur}
                        value={ValuesJobFamily}
                        onChange={(e) => {
                            handleChange(e)
                            this.setState({
                                JobFamily: e.target.value
                            })
                        }}
                        displayEmpty
                        renderValue={
                            (selected) => this.renderjobfamily(Number(selected))}
                        IconComponent={KeyboardArrowRightIcon}
                        input={<InputBase />}
                    >
                        {this.state.jobFamily?.map((option) => (
                            <MenuItem
                                key={option.id}
                                value={`${option.id}`}>
                                {option.name}
                            </MenuItem>
                        ))}
                    </Select>
                </Wrapper>
            </Grid>
        )
    }

    RenderEditJobTitle = (handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
        handleBlur: {
            (e: React.FocusEvent<React.FocusEventHandler<HTMLInputElement>>): void;
            <T = React.FocusEventHandler<HTMLInputElement>>(fieldOrEvent: T): T extends string ? (e: React.FocusEventHandler<HTMLInputElement>) => void : void;
        },
        values: {
            EditJobTitle: string
        }
    ) => {
        return (<Grid item xs={12} sm={12} md={6} lg={6}>
            <Wrapper>
                <Box className="errorStyleMainbox">
                    <Typography className="cardInputLabel">
                        {this.TraslationFuncation("JobDescriptionPage.JobTitle")} <span>*</span>
                    </Typography>
                    <ErrorMessage name="EditJobTitle" component="div" className="errorStyle" />
                </Box>
                <input
                    className="dropDownStyle"
                    placeholder={this.TraslationFuncation("JobDescriptionPage.SpecifyYourTitle")}
                    data-test-id="EditJobTitle"
                    name="EditJobTitle"
                    onChange={(e) => {
                        handleChange(e)
                        this.setState({
                            EditJobTitle: e.target.value
                        })
                    }}
                    onBlur={handleBlur}
                    value={values.EditJobTitle}
                />
            </Wrapper>
        </Grid>
        )
    }

    RenderEditDepartmentTeam = (
        handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
        handleBlur: {
            (e: React.FocusEvent<React.FocusEventHandler<HTMLInputElement>>): void;
            <T = React.FocusEventHandler<HTMLInputElement>>(fieldOrEvent: T): T extends string ? (e: React.FocusEventHandler<HTMLInputElement>) => void : void;
        },
        values: {
            EditDepartment: string
        }
    ) => {
        return (
            <Grid item xs={12} md={6}>
                <Wrapper>
                    <Box className="errorStyleMainbox">
                        <Typography className="cardInputLabel">
                            {this.TraslationFuncation("JobDescriptionPage.DepartmentTeam")}  <span>*</span>
                        </Typography>
                        <ErrorMessage name="EditDepartment" component="div" className="errorStyle" />
                    </Box>
                    <input
                        className="dropDownStyle"
                        placeholder={this.TraslationFuncation("JobDescriptionPage.SpecifyYourDepartmentTeam")}
                        name="EditDepartment"
                        onChange={(e) => {
                            handleChange(e)
                            this.setState({
                                EditDepartment: e.target.value
                            })
                        }}
                        data-test-id="EditDepartmentTeam"
                        onBlur={handleBlur}
                        value={values.EditDepartment}
                    />
                </Wrapper>
            </Grid>
        )
    }

    RenderEditKeySkills = (
        handleBlur: {
            (e: React.FocusEvent<React.FocusEventHandler<HTMLInputElement>>): void;
            <T = React.FocusEventHandler<HTMLInputElement>>(fieldOrEvent: T): T extends string ? (e: React.FocusEventHandler<HTMLInputElement>) => void : void;
        },
        ValueskeySkills: string[],
        setFieldValue: (field: string, value: string | string[], shouldValidate?: boolean) => void
    ) => {
        let KeyName = "EditKeySkills"
        return (
            <Grid item xs={12} md={12}>
                <Wrapper>
                    <Box className="errorStyleMainbox">
                        <Typography className="cardInputLabel">
                            {this.TraslationFuncation("JobDescriptionPage.KeySkills")}  <span>*</span>
                        </Typography>
                        <ErrorMessage name={`${KeyName}.${0}`} component="div" className="errorStyle" />
                    </Box>
                    <Box>

                        <Grid container spacing={4}>
                            {ValueskeySkills.map((item, index) => {
                                const CanAdd = ValueskeySkills.length - 1 === index && ValueskeySkills[ValueskeySkills.length - 1] !== ""
                                return (
                                    <Grid item xs={12} md={12} style={{ position: "relative" }}>
                                        {index !== 0 &&
                                            <ErrorMessage name={`${KeyName}.${index}`} component="div" className="errorStyle CustomErrorMessage" />
                                        }
                                        <Input
                                            placeholder={""}
                                            minRows={3}
                                            data-test-id={`${KeyName}-${index}`}
                                            className="dropDownStyle"
                                            multiline
                                            value={item}
                                            name={`${KeyName}.${index}`}
                                            disableUnderline
                                            onBlur={handleBlur}
                                            onChange={(e) => {
                                                this.EditMultipleOnChange(e, setFieldValue, index, KeyName, ValueskeySkills)
                                            }}
                                            autoComplete="off"
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    {CanAdd ? <IconButton
                                                        data-test-id="AddIcon"
                                                        onClick={() => {
                                                            this.AddOneString(KeyName, ValueskeySkills, setFieldValue)
                                                        }}
                                                    >
                                                        <AddIcon />
                                                    </IconButton> :
                                                        <IconButton
                                                            data-test-id="RemoveIcon"
                                                            onClick={() => {
                                                                if (ValueskeySkills.length > 1) {
                                                                    this.RemoveOneString(KeyName, ValueskeySkills, index, setFieldValue)
                                                                }
                                                            }}
                                                        >
                                                            <DeleteOutline />
                                                        </IconButton>}
                                                </InputAdornment>
                                            }
                                        />
                                        <Typography className="companyCultureLimit">{item.length}/1000</Typography>
                                    </Grid>
                                )
                            })}
                        </Grid>

                    </Box>
                </Wrapper>
            </Grid>
        )
    }
    RenderEditKeyKeywords = (
        handleBlur: { (e: React.FocusEvent<React.FocusEventHandler<HTMLInputElement>>): void; <T = React.FocusEventHandler<HTMLInputElement>>(fieldOrEvent: T): T extends string ? (e: React.FocusEventHandler<HTMLInputElement>) => void : void; },
        ValuesOfStirngs: string[],
        setFieldValue: (field: string, value: string | string[], shouldValidate?: boolean) => void
    ) => {
        let KeyName = "EditKeyKeywords"
        return (
            <Grid item md={12} xs={12}>
                <Wrapper>
                    <Box className="errorStyleMainbox">
                        <Typography className="cardInputLabel">
                            {this.TraslationFuncation("JobDescriptionPage.KeyWords")}  <span>*</span>
                        </Typography>
                        <ErrorMessage
                            name={`${KeyName}.${0}`}
                            className="errorStyle"
                            component="div"
                        />
                    </Box>
                    <Box>
                        <Grid spacing={4} container>
                            {ValuesOfStirngs.map((item, index) => {
                                const CanAdd = ValuesOfStirngs.length - 1 === index && ValuesOfStirngs[ValuesOfStirngs.length - 1] !== ""
                                return (
                                    <Grid item xs={12} md={12} style={{ position: "relative" }}>
                                        {index !== 0 &&
                                            <ErrorMessage name={`${KeyName}.${index}`}
                                                component="div"
                                                className="errorStyle CustomErrorMessage" />
                                        }
                                        <Input
                                            minRows={3}
                                            data-test-id={`${KeyName}-${index}`}
                                            className="dropDownStyle"
                                            disableUnderline
                                            placeholder={""}
                                            multiline
                                            value={item}
                                            name={`${KeyName}.${index}`}
                                            onBlur={handleBlur}
                                            onChange={(e) => {
                                                this.EditMultipleOnChange(e, setFieldValue, index, `${KeyName}`, ValuesOfStirngs)
                                            }}
                                            autoComplete="off"
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    {CanAdd ?
                                                        <IconButton
                                                            data-test-id="AddIcon"
                                                            onClick={() => {
                                                                this.AddOneString(KeyName, ValuesOfStirngs, setFieldValue)
                                                            }}
                                                        >
                                                            <AddIcon />
                                                        </IconButton>
                                                        :
                                                        <IconButton
                                                            data-test-id="RemoveIcon"
                                                            onClick={() => {
                                                                if (ValuesOfStirngs.length > 1) {
                                                                    this.RemoveOneString(KeyName, ValuesOfStirngs, index, setFieldValue)
                                                                }
                                                            }}
                                                        >
                                                            <DeleteOutline />
                                                        </IconButton>}
                                                </InputAdornment>
                                            }
                                        />
                                        <Typography className="companyCultureLimit">{item.length}/1000</Typography>
                                    </Grid>
                                )
                            })}

                        </Grid>

                    </Box>
                </Wrapper>
            </Grid>
        )
    }

    RenderEditRequirements = (
        handleBlur: {
            (e: React.FocusEvent<React.FocusEventHandler<HTMLInputElement>>): void;
            <T = React.FocusEventHandler<HTMLInputElement>>(fieldOrEvent: T): T extends string ? (e: React.FocusEventHandler<HTMLInputElement>) => void : void;
        },
        ValuesOfStirngs: string[],
        setFieldValue: (field: string, value: string | string[], shouldValidate?: boolean) => void
    ) => {
        let KeyName = "EditJobRequirements"
        return (
            <Grid item xs={12} md={12}>
                <Wrapper>
                    <Box className="errorStyleMainbox">
                        <Typography className="cardInputLabel">
                            {this.TraslationFuncation("JobDescriptionPage.JobRequirements")}  <span>*</span>
                        </Typography>
                        <ErrorMessage
                            name={`${KeyName}.${0}`}
                            component="div"
                            className="errorStyle" />
                    </Box>
                    <Box>
                        <Grid container spacing={4}>
                            {ValuesOfStirngs.map((item, index) => {
                                const CanAdd = ValuesOfStirngs.length - 1 === index && ValuesOfStirngs[ValuesOfStirngs.length - 1] !== ""
                                return (
                                    <Grid item xs={12} md={12} style={{ position: "relative" }}>
                                        {index !== 0 &&
                                            <ErrorMessage
                                                name={`${KeyName}.${index}`}
                                                component="div"
                                                className="errorStyle CustomErrorMessage" />
                                        }
                                        <Input
                                            autoComplete="off"
                                            minRows={3}
                                            data-test-id={`${KeyName}-${index}`}
                                            className="dropDownStyle"
                                            disableUnderline
                                            placeholder={""}
                                            multiline
                                            value={item}
                                            name={`${KeyName}.${index}`}
                                            onBlur={handleBlur}
                                            onChange={(e) => {
                                                this.EditMultipleOnChange(e, setFieldValue, index, `${KeyName}`, ValuesOfStirngs)
                                            }}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    {CanAdd ? <IconButton
                                                        data-test-id="AddIcon"
                                                        onClick={() => {
                                                            this.AddOneString(KeyName, ValuesOfStirngs, setFieldValue)
                                                        }}
                                                    >
                                                        <AddIcon />
                                                    </IconButton> :
                                                        <IconButton
                                                            data-test-id="RemoveIcon"
                                                            onClick={() => {
                                                                if (ValuesOfStirngs.length > 1) {
                                                                    this.RemoveOneString(KeyName, ValuesOfStirngs, index, setFieldValue)
                                                                }
                                                            }}
                                                        >
                                                            <DeleteOutline />
                                                        </IconButton>}
                                                </InputAdornment>
                                            }
                                        />
                                        <Typography className="companyCultureLimit">{item.length}/1000</Typography>
                                    </Grid>
                                )
                            })}

                        </Grid>

                    </Box>
                </Wrapper>
            </Grid>
        )
    }

    RenderEditResponsibilities = (
        handleBlur: {
            (e: React.FocusEvent<React.FocusEventHandler<HTMLInputElement>>): void;
            <T = React.FocusEventHandler<HTMLInputElement>>(fieldOrEvent: T): T extends string ? (e: React.FocusEventHandler<HTMLInputElement>) => void : void;
        }, ValuesOfStirngs: string[], setFieldValue: (field: string, value: string | string[], shouldValidate?: boolean) => void
    ) => {
        let KeyName = "EditJobResponsibilities"
        return (
            <Grid item xs={12} md={12}>
                <Wrapper>
                    <Box className="errorStyleMainbox">
                        <Typography className="cardInputLabel">{this.TraslationFuncation("JobDescriptionPage.JobResponsibilities")}  <span>*</span></Typography>
                        <ErrorMessage
                            name={`${KeyName}.${0}`} component="div"
                            className="errorStyle" />
                    </Box>
                    <Box>
                        <Grid container spacing={4}>
                            {ValuesOfStirngs.map((item, index) => {
                                const CanAdd = ValuesOfStirngs.length - 1 === index && ValuesOfStirngs[ValuesOfStirngs.length - 1] !== ""
                                return (
                                    <Grid item xs={12} md={12} style={{ position: "relative" }}>
                                        {index !== 0 &&
                                            <ErrorMessage name={`${KeyName}.${index}`}
                                                component="div" className="errorStyle CustomErrorMessage" />
                                        }
                                        <Input
                                            data-test-id={`${KeyName}-${index}`}
                                            className="dropDownStyle"
                                            name={`${KeyName}.${index}`}
                                            onBlur={handleBlur}
                                            onChange={(e) => {
                                                this.EditMultipleOnChange(e, setFieldValue, index, `${KeyName}`, ValuesOfStirngs)
                                            }}
                                            minRows={3}
                                            autoComplete="off"
                                            disableUnderline
                                            placeholder={""}
                                            multiline
                                            value={item}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    {CanAdd ? <IconButton data-test-id="AddIcon"
                                                        onClick={() => {
                                                            this.AddOneString(KeyName, ValuesOfStirngs, setFieldValue)
                                                        }}
                                                    >
                                                        <AddIcon />
                                                    </IconButton> :
                                                        <IconButton data-test-id="RemoveIcon"
                                                            onClick={() => {
                                                                if (ValuesOfStirngs.length > 1) {
                                                                    this.RemoveOneString(KeyName, ValuesOfStirngs, index, setFieldValue)
                                                                }
                                                            }}
                                                        >
                                                            <DeleteOutline />
                                                        </IconButton>}
                                                </InputAdornment>
                                            }
                                        />
                                        <Typography className="companyCultureLimit">{item.length}/1000</Typography>
                                    </Grid>
                                )
                            })}

                        </Grid>

                    </Box>
                </Wrapper>
            </Grid>
        )
    }

    RenderEditJobSummary = (
        handleChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void,
        handleBlur: {
            (e: React.FocusEvent<React.FocusEventHandler<HTMLInputElement>>): void;
            <T = React.FocusEventHandler<HTMLInputElement>>(fieldOrEvent: T): T extends string ? (e: React.FocusEventHandler<HTMLInputElement>) => void : void;
        },
        values: {
            EditJobSummary: string
        }
    ) => {
        return (
            <Grid item xs={12}>
                <Wrapper>
                    <Box className="errorStyleMainbox">
                        <Typography className="cardInputLabel">
                            {this.TraslationFuncation("JobDescriptionPage.JobSummary")} <span>*</span>
                        </Typography>
                        <ErrorMessage
                            name="EditJobSummary"
                            component="div"
                            className="errorStyle" />
                    </Box>
                    <textarea
                        className={`cultureInputField`}
                        placeholder={this.TraslationFuncation("JobDescriptionPage.WriteAboutYourJob")}
                        value={values.EditJobSummary}
                        data-test-id="EditsetJobSummary"
                        name="EditJobSummary"
                        style={{
                            fontWeight: 700,
                            maxWidth: "100%",
                        }}
                        onBlur={handleBlur}
                        onChange={(e) => {
                            handleChange(e)
                            this.setState({
                                EditJobSummary: e.target.value
                            })
                        }}
                    />
                    <Typography className="companyCultureLimit">{values.EditJobSummary.length}/1000</Typography>
                </Wrapper>
            </Grid>
        )
    }

    FormAndLoader = () => {
        return this.state.loader ? this.loaderScreen() :
            <Grid container justifyContent={"center"}>
                <Grid item xs={11}>
                    <Grid container
                        spacing={1}
                        justifyContent={this.TrueOrFalseConditionPass(this.state.selectedLanguage.toLowerCase() === "english", "flex-start", "center")}
                        alignItems={"center"}
                        sx={{
                            direction: this.state.selectedLanguage.toLowerCase() === "english" ? "" : "rtl",
                        }}
                    >
                        <Grid item xs={1.5} sm={1} md={0.8} lg={0.5}>
                            <Box data-test-id="BackToJobDescription"
                                style={{
                                    width: "47px", height: "47px",
                                    border: "1px solid #ECECEC", borderRadius: "50%",
                                    display: "flex", alignItems: "center",
                                    justifyContent: "center", cursor: 'pointer',
                                }}
                                className={this.TrueOrFalseConditionPass(this.state.selectedLanguage.toLowerCase() === "english", " BackArrowForEnglish ", " BackArrowForArabic ")}
                                onClick={this.BackToJobDescription}
                            >
                                <img src={arrow_Left.default} alt="Back Arrow"
                                />
                            </Box>
                        </Grid>
                        <Grid item md={11.2} lg={6} xs={10.5} sm={11}>
                            <Typography className={this.ConditionForLanguage(" createUserTitle ", " createUserTitleAr ")}>
                                <Typography
                                    style={{ marginRight: this.TrueOrFalseConditionPass(this.state.selectedLanguage.toLowerCase() === "english", "  ", " 10px ") }} className="jobTitle">{this.state.TitleJobDescription}</Typography>
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid id="FormGridContainer" container spacing={1} className="FormGrid" justifyContent={this.TrueOrFalseConditionPass(this.state.selectedLanguage.toLowerCase() === "english", "flex-start", "flex-end")}>
                        <Formik
                            data-test-id="EditJobFormik"
                            initialValues={{
                                EditJobId: this.state.EditJobId,
                                EditJobFamily: this.state.EditJobFamily,
                                EditJobTitle: this.state.EditJobTitle,
                                EditDepartment: this.state.EditDepartment,

                                EditKeySkills: this.state.EditKeySkills,

                                EditKeyKeywords: this.state.EditKeyKeywords,
                                EditJobResponsibilities: this.state.EditJobResponsibilities,

                                EditJobRequirements: this.state.EditJobRequirements,

                                EditJobSummary: this.state.EditJobSummary,

                            }}
                            validationSchema={this.EditJobFormSchema}
                            enableReinitialize={this.state.FormEnableReinitialize}
                            onSubmit={(values) => {
                                this.EditJObDesFormApiCallFun(values);
                            }}
                        >
                            {({
                                handleSubmit, values, handleChange, handleBlur, setFieldValue,
                            }) => {
                                return (
                                    <Form onSubmit={handleSubmit} noValidate name="EditJobDetailForm" autoComplete="off"
                                    >
                                        <Grid item md={11.2} lg={11.5} xs={12} sm={12} className="jobDetailHeaderGrid">
                                            <Wrapper>
                                                <Box className="companyItemMainBox">
                                                    <Grid container className={"companyCardContainer" + " " + this.ConditionForLanguage("", "companyCardContainerArabic")} justifyContent={"flex-start"}>
                                                        <Grid xs={12} className="companyCardItem" item>
                                                            <Card className="companyCardDescBox">
                                                                <CardContent className="cardContentPadding">
                                                                    <Grid container>
                                                                        <Grid item xs={12} className="orgDetailPadding">
                                                                            <Typography className="JobDetailTitle">
                                                                                {this.TrueOrFalseConditionPass(this.state.RenderJobTitle === "", this.TraslationFuncation("JobDescriptionPage.JobDescription"), this.state.RenderJobTitle)}
                                                                            </Typography>
                                                                        </Grid>
                                                                        <Grid item xs={12}>
                                                                            <Divider
                                                                                sx={{
                                                                                    color: "#E2E8F0", width: 'calc(100% - 35px)',
                                                                                    marginRight: '35px',
                                                                                }}
                                                                            />

                                                                        </Grid>
                                                                    </Grid>
                                                                    <Grid container spacing={3.5} className="contentPadding">

                                                                        {this.RenderEditJobID(handleChange, handleBlur)}
                                                                        {this.RenderJobFamilyTextfiled(handleChange, handleBlur, values.EditJobFamily)}
                                                                        {this.RenderEditJobTitle(handleChange, handleBlur, values)}
                                                                        {this.RenderEditDepartmentTeam(handleChange, handleBlur, values)}
                                                                        {this.RenderEditKeySkills(handleBlur, values.EditKeySkills, setFieldValue)}
                                                                        {this.RenderEditKeyKeywords(handleBlur, values.EditKeyKeywords, setFieldValue)}
                                                                        {this.RenderEditRequirements(handleBlur, values.EditJobRequirements, setFieldValue)}
                                                                        {this.RenderEditResponsibilities(handleBlur, values.EditJobResponsibilities, setFieldValue)}
                                                                        {this.RenderEditJobSummary(handleChange, handleBlur, values)}


                                                                        <Grid item xs={12}>
                                                                            <Wrapper>
                                                                                <Grid container>
                                                                                    <Grid item xs={12}>
                                                                                        <Button
                                                                                            className="sumbmitBtn"
                                                                                            data-test-id="editJobDescription"
                                                                                            onClick={() => { handleSubmit() }}>
                                                                                            <Typography className="sumbmitText">
                                                                                                {this.state.FormSubmitButtonText}
                                                                                            </Typography>
                                                                                        </Button>
                                                                                    </Grid>
                                                                                </Grid>
                                                                            </Wrapper>
                                                                        </Grid>
                                                                    </Grid>
                                                                </CardContent>
                                                            </Card>
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                            </Wrapper>
                                        </Grid>
                                    </Form>
                                )
                            }}
                        </Formik>
                    </Grid>
                </Grid>
            </Grid>
    }

    SuccessModelForForm = () => {
        return (
            <Modal
                open={this.state.ProgressModel}
                data-test-id="successModel"
                onClose={this.CloseSuccessModel}>
                <CustomPopup
                    buttonText={this.TraslationFuncation("View")}
                    data-test-id="successModelCustomPopup"
                    headingPopup={this.state.SuccessModelTitleText}
                    titlePopup={this.state.jobdescResponse}
                    subTitlePopup={this.TraslationFuncation("JobDescriptionPage.JobDescriptionHasSavedInTheSystem")}
                    showCancelButton={false}
                    successIcon={right.default}
                    onOpen={() => { this.ViewJobDescription() }}
                    onClose={this.CloseSuccessModel}
                />
            </Modal>
        )
    }

    RenderAllScreens = () => {
        if (this.state.FormSubmit === false) {
            return this.FormAndLoader()
        } else if (this.state.FormSubmit && this.state.progressScreen) {
            return this.progressDataScreen()
        }
    }
    // Customizable Area End
    render() {
        return (
            // Customizable Area Start
            <DashboardHeader selectedMenu="Job descriptions" navigation={this.props.navigation} id={this.props.id}>
                <JobDescriptionWrapper>
                    <MainWrapper style={{ direction: this.state.selectedLanguage.toLowerCase() === "english" ? "ltr" : "rtl" }} >
                        {this.RenderAllScreens()}
                        {this.SuccessModelForForm()}
                    </MainWrapper>
                </JobDescriptionWrapper>
            </DashboardHeader>
            // Customizable Area End
        );
    }
}


// Customizable Area Start
export const EditJobDescriptionWeb = withTranslation()(EditJobDescription)

const JobDescriptionWrapper = styled(Box)({
    "& .CustomErrorMessage": {
        position: "absolute",
        right: 0,
        top: "5px"
    },
    "& .dropDownStyle": {
        height: "unset !important",
        minHeight: "66px !important",
    }
})

// Customizable Area End