import React from "react";
// Customizable Area Start
import {
    Box,
    Grid,
    styled,
    Typography,
    Button,
    Card,
    CardContent,
    Chip,
    IconButton,
    Dialog,
    DialogTitle,
    Divider,
    DialogContent,
    DialogActions,
    Modal
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { DashboardHeaderWeb as DashboardHeader } from "../../dashboard/src/DashboardHeader.web";
import { backArrow, blankCheckbox, selectedCheckbox, addQuestionImg, editQuestion, deleteQuestion, deleteQuestionBank, successImg } from './assets';
import { Questions } from "./ListingQuestionBankController";
import ListingQuestionBankController, {
    Props
} from "./ListingQuestionBankController";
import CustomPopup from "../../../../packages/components/src/CustomSuccessModel.web";
// Customizable Area End


export default class ListingQuestionBank extends ListingQuestionBankController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start

    SuccessModel = () => {
        return (
            <Modal
                open={this.state.SuccessModel}
                data-test-id="successModel"
                onClose={this.navigateToCreation}>
                <CustomPopup
                    buttonText={"Done"}
                    data-test-id="successModelCustomPopup"
                    headingPopup={"Question Bank Updated"}
                    titlePopup={"Questions Bank Updated Successfully"}
                    subTitlePopup={`Question Bank has been Updated successfully.`}
                    showCancelButton={false}
                    successIcon={successImg.default}
                    onOpen={this.navigateToCreation}
                    onClose={this.navigateToCreation}
                />
            </Modal>
        )
    }
    deleteQuestionSuccess = () => {
        return (
            <Dialog
                onClose={this.handleDeleteClose}
                open={this.state.deleteDialog}
                PaperProps={{
                    sx: {
                        borderRadius: "8px 8px 32px 8px",
                        direction: this.state.selectedLanguage.toLowerCase() === "english" ? "ltr" : "rtl",
                        overflow: 'hidden',
                    }
                }}
            >
                <DialogTitle>
                    <DialogWrapper sx={{ display: "flex", alignItems: "center", padding: "0" }}>
                        <Typography className="deleteQueDialogTitle">
                            Delete Question
                        </Typography>
                        <IconButton
                            data-test-id="close_delete_dialog"
                            aria-label="close"
                            onClick={this.handleDeleteClose}
                            className="deleteDialogClose"
                            sx={{
                                left: this.state.selectedLanguage.toLowerCase() === "english" ? "auto" : 30,
                                right: this.state.selectedLanguage.toLowerCase() === "english" ? 30 : "auto",
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </DialogWrapper>
                </DialogTitle>
                <Divider sx={{ color: "#E2E8F0" }} />
                <DialogContent>
                    <DialogWrapper>
                        <Box className="deleteImageBox">
                            <img src={deleteQuestionBank.default}
                                alt="Success Image"
                                height={144}
                                width={144} />
                        </Box>
                    </DialogWrapper>
                    <Typography
                        sx={{
                            fontFamily: "Urbanist",
                            fontSize: "20px",
                            fontWeight: 700,                            
                            padding: {
                                xs: "14px 14px",
                                sm: "14px 64px"
                            },
                            lineHeight: "28px",
                            color: "#0F172A",
                            textAlign: "center"
                        }}>
                        Are you sure you want to delete this Question?
                    </Typography>
                    <Typography
                        sx={{
                            padding: "0px 68px",
                            fontFamily: "Urbanist",
                            fontWeight: 400,
                            lineHeight: "26px",
                            color: "#0F172A",
                            fontSize: "18px",
                            textAlign: "center"
                        }}>
                        You won't be able to access it again.
                    </Typography>

                </DialogContent>
                <Divider />
                <DialogActions>
                    <DialogWrapper>
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: {
                                    xs: "center",
                                    sm: "flex-end"
                                }
                            }}
                            className={`${this.state.selectedLanguage.toLowerCase() === "english" ?
                                "deleteButtonsBox" : "deleteButtonsArabic"}`}
                        >
                            <Button
                                color="primary"
                                variant="contained"
                                onClick={this.handleDeleteClose}
                                data-test-id="cancel_delete_question"
                                className={`${this.state.selectedLanguage.toLowerCase() === "english" ? "cancelDeleteButton" : "cancelDeleteArabic"}`}
                            >
                                Cancel
                            </Button>
                            <Button
                                color="primary"
                                variant="contained"
                                onClick={() => this.deleteQuestionApiCall(this.state.rowId)}
                                data-test-id="delete_que_dialog"
                                className={`${this.state.selectedLanguage.toLowerCase() === "english" ? "listDeleteButton" : "listDeleteButtonArabic"}`}
                            >
                                Delete
                            </Button>
                        </Box>
                    </DialogWrapper>
                </DialogActions>
            </Dialog>
        )
    }

    addQuestionsBtn = () => {
        return (
            <Grid item xs={12} sm={7} md={12}>
                <Button
                    className="addQuestionBtn"
                    data-test-id="create_dictionary"
                    onClick={this.NaviGateToUploadQuetion}
                >
                    <Box className="addQuestionBox">
                        <img src={addQuestionImg.default} height="20px" width="20px" />
                        <Typography className="addQuestionText">
                            Add Questions
                        </Typography>
                    </Box>

                </Button>
            </Grid>
        )
    }

    questionList = (item: Questions, index: number) => {
        const data = Array.isArray(item.data) ? item.data[0] : item.data;
        const attributes = data?.attributes;
        const difficultyLevel = attributes?.difficulty_level?.name ? [attributes.difficulty_level.name] : [];

        let questionTypes: string[] = [];
        let questionFormats: string[] = [];
        let focusArea: string[] = [];

        if (this.state.questionBankData.data?.attributes) {
            questionTypes = this.state.questionBankData.data.attributes.selected_question_types?.map(item => item.name);
            questionFormats = this.state.questionBankData.data.attributes.selected_question_formats?.map(item => item.name);
            focusArea = this.state.questionBankData.data.attributes.selected_focus_areas?.map(item => item.name);
        }

        const combinedData = [
            ...difficultyLevel,
            ...questionTypes,
            ...questionFormats,
            ...focusArea
        ];
        return (
            <Wrapper sx={{ marginTop: 0 }}>
                {!this.state.previewClicked &&
                    <Grid container spacing={1.5} alignItems={"center"}>
                        <Grid item xs={12} sm={"auto"} className="filterEditRemove">
                            <img
                                src={blankCheckbox.default}
                                alt="checkbox"
                                className="checkBoxStyle"
                            />
                        </Grid>

                        <Grid item xs={12} sm={10.5} className="chipGridItem">
                            {combinedData.map((filter, index) => (
                                <Chip
                                    variant="outlined"
                                    size="small"
                                    label={filter}
                                    className={`filterChip ${index === 0 ? "filterChipSelected" : null}`}
                                />
                            ))}
                        </Grid>

                        <Grid item xs={12} sm={"auto"} className="filterEditRemove">
                            <img
                                src={editQuestion.default}
                                width={"24px"}
                                height={"24px"}
                                data-test-id="edit_question"
                                onClick={() => this.navigateToEdit(attributes.id)}
                                style={{ marginRight: "5px" }} />
                            <img
                                src={deleteQuestion.default}
                                data-test-id="delete_question"
                                onClick={()=>{this.handleDeleteRow(attributes.id)}}
                                width={"24px"}
                                height={"24px"} />
                        </Grid>
                    </Grid>
                }
                <Box sx={{
                    margin: {
                        xs: "24px 0px 24px 8px",
                        sm: "24px 0px 24px 16px",
                        md: "24px 0px 24px 40px"
                    }
                }}>
                    <Typography className="questionLabel">
                        {index}. {attributes.text}
                    </Typography>

                    <Box sx={{ fontFamily: "Urbanist" }}>
                        {attributes.question_format_name === "Scenario Based" ? (
                            <Box className="optionsStyle scenarioAns">
                                <Typography>
                                    {attributes.answer.text_response}
                                </Typography>
                            </Box>
                        ) : (
                            attributes?.answer?.choices?.map((options) => (
                                <Box
                                    key={options.option_text}
                                    display="flex"
                                    alignItems="center"
                                    mb={1}
                                    className={
                                        options.correct && !this.state.previewClicked
                                            ? "optionsStyleTrue"
                                            : "optionsStyle"
                                    }
                                    sx={{ fontFamily: "Urbanist" }}
                                    data-test-id="question_options"
                                >
                                    <img
                                        src={
                                            options.correct && !this.state.previewClicked
                                                ? selectedCheckbox.default
                                                : blankCheckbox.default
                                        }
                                        alt="checkbox"
                                        className="checkBoxStyle"
                                    />
                                    <Typography>{options.option_text}</Typography>
                                </Box>
                            ))
                        )}
                    </Box>
                </Box>

            </Wrapper>
        )
    }

    saveChangesButton = () => {
        return (
            <Wrapper sx={{ marginTop: "20px" }}>
                <Button
                    className="addQuestionBtn"
                    data-test-id="cancel_btn"
                    onClick={() => {
                        this.setState({
                            SuccessModel:true
                        })
                    }}
                >
                    <Typography className="addQuestionText">
                        Save Changes
                    </Typography>
                </Button>
            </Wrapper>
        )
    }

    cancelQuestionButton = () => {
        return (
            <Wrapper sx={{ marginTop: "20px" }}>
                <Button
                    className="addQuestionBtn"
                    data-test-id="cancelbtn"
                    onClick={()=>{
                        this.NaviGateToAnyPage("QuestionBankDashboard")
                    }}
                >
                    <Typography className="addQuestionText">
                        Cancel
                    </Typography>
                </Button>
            </Wrapper>
        )
    }

    previewButton = () => {
        return (
            <Wrapper sx={{ marginTop: "20px" }}>
                <Button
                    className="previewBtn"
                    data-test-id="preview_btn"
                    onClick={() => this.scrollToSection(this.previewRef)}
                >
                    <Typography className="previewBtnText">
                        Preview
                    </Typography>
                </Button>
            </Wrapper>
        )
    }

    questionListCard = () => {
        return (
            <Wrapper>
                {!this.state.loader &&
                    <Card sx={{ borderRadius: 2, boxShadow: "rgba(0, 0, 0, 0.1) 0px 14px 144px 0px" }}>
                        <CardContent sx={{ paddingLeft: "40px", paddingBottom: "0px !important" }}>
                            <Grid container spacing={1} className="contentPadding">
                                {this.state.questionBankData.data?.attributes.questions.map((item, index) => (
                                    <Grid item xs={12} key={index + 1}>
                                        {this.questionList(item, index + 1)}
                                    </Grid>
                                ))}
                                {!this.state.previewClicked &&
                                    <>
                                        <Grid item xs={12} md={4} >
                                            {this.saveChangesButton()}
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            {this.cancelQuestionButton()}
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            {this.previewButton()}
                                        </Grid>
                                    </>
                                }
                            </Grid>

                        </CardContent>
                    </Card>
                }
            </Wrapper>
        )
    }
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <DashboardHeader selectedMenu="Question banks" navigation={this.props.navigation} id={this.props.id}>
                <MainWrapper ref={this.previewRef}>
                    {this.SuccessModel()}
                    <Grid container justifyContent={"center"}>
                        <Grid item xs={11}>
                            <Grid container alignItems={"center"} justifyContent={"space-between"} spacing={1}>
                                <Grid item xs={11} sm={11} md={7}>
                                    <Grid container spacing={1} alignItems={"center"}>
                                        <Grid item xs={12} sx={{
                                            display: "flex",
                                            alignItems: "center"
                                        }}>
                                            <img src={backArrow.default}
                                                alt="Back Arrow"
                                                className="backArrowImg"
                                                onClick={this.state.previewClicked ?
                                                    this.navigateToListScreen : this.navigateToCreation}
                                                data-test-id="back_to_dashboard"
                                            />
                                            {!this.state.loader &&
                                                <Typography className="questionTitle">
                                                    {this.state.previewClicked ?
                                                        "Generated Questions" :
                                                        `${this.state.questionBankData.data?.attributes.questions.length} Generated Questions`
                                                    }
                                                </Typography>
                                            }
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item xs={11} sm={11} md={5} lg={2.5}
                                    sx={{
                                        display: "flex",
                                        justifyContent: {
                                            xs: "flex-start", md: "flex-end",
                                            lg: "flex-end", xl: "flex-end"
                                        },
                                        marginRight: "16px"
                                    }}
                                >
                                    {!this.state.previewClicked &&
                                        <Grid container spacing={2}>
                                            {this.addQuestionsBtn()}
                                        </Grid>
                                    }

                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={11}>{this.questionListCard()}</Grid>
                    </Grid>{this.deleteQuestionSuccess()}
                </MainWrapper>
            </DashboardHeader>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
export const MainWrapper = styled(Box)({
    display: "flex",
    justifyContent: "center",
    width: "100%",
    marginTop: "42px",
    paddingBottom: "30px",
    "& .backArrowImg": {
        paddingRight: "12px"
    },
    "& .questionTitle": {
        fontFamily: "Urbanist",
        display: "inline",
        fontSize: "25px",
        fontWeight: 600,
        color: "#000104",
        marginLeft: "8px",
    },
    "& .addQuestionBox": {
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    "& .addQuestionText": {
        fontWeight: 700,
        fontFamily: "Urbanist",
        color: "#044352",
        fontSize: "16px",
        lineHeight: "24px",
        textTransform: "none",
        marginLeft: "5px",
    },
    "& .addQuestionBtn": {
        width: "100%",
        height: "58px",
        borderRadius: "4px",
        border: "1px solid #044352",
        "&:hover": {
            background: "none"
        }
    },
    "& .previewBtn": {
        height: "58px",
        padding: "10px 16px 10px 16px",
        border: "1px solid #94A3B8",
        width: "100%",
        borderRadius: "4px",
        background: "#044352"
    },
    "& .previewBtnText": {
        lineHeight: "24px",
        fontFamily: "Urbanist",
        color: "#FFFFFF",
        fontWeight: 700,
        fontSize: "16px",
        textTransform: "none"
    },
})

export const DialogWrapper = styled(Box)({
    
    "& .listDeleteButton": {
        fontWeight: 700,
        borderRadius: "8px",
        gap: "8px",
        opacity: "0px",
        fontFamily: "Urbanist",
        fontSize: "16px",
        height: "56px",
        padding: "16px 34px",
        marginLeft: "16px",
        background: "#044352",
        textTransform: "none",
        "&:hover": {
            backgroundColor: "#044352"
        },
    },
    "& .deleteQueDialogTitle": {
        fontFamily: "Urbanist",
        color: "#1E1E1E",
        fontSize: "24px",
        lineHeight: "32px",   
        fontWeight: 700,
        margin: "8px 16px"
    },
    "& .listDeleteButtonArabic": {
        fontWeight: 700,
        height: "56px",
        gap: "8px",
        fontFamily: "Urbanist",
        background: "#044352",
        textTransform: "none",
        width: "120px",
        padding: "16px 39.5px 16px 36.5px",
        borderRadius: "8px", opacity: "0px",
        fontSize: "16px", marginRight: "16px",
        "&:hover": {
            backgroundColor: "#044352"
        },
    },
    
    "& .cancelDeleteArabic": {
        marginRight: "16px",
        gap: "8px",
        padding: "16px 39.5px 16px 36.5px",
        background: "#F1F5F9",
        borderRadius: "8px",
        height: "56px",
        fontFamily: "Urbanist",
        fontWeight: 700,
        width: "120px",
        textTransform: "none",
        opacity: "0px",
        fontSize: "16px",
        color: "#000",
        "&:hover": {
            backgroundColor: "#F1F5F9"
        },
    },
    "& .cancelDeleteButton": {
        padding: "16px 34px",
        height: "56px",
        opacity: "0px",
        fontWeight: 700,
        borderRadius: "8px",
        marginLeft: "16px",
        gap: "8px",
        fontFamily: "Urbanist",
        fontSize: "16px",
        background: "#F1F5F9",
        textTransform: "none",
        color: "#000",
        "&:hover": {
            backgroundColor: "#F1F5F9"
        },
    },
    "& .deleteButtonsBox": {
        display: "flex",
        justifyContent: "flex-end",
        margin: "16px 0px", paddingRight: "16px"
    },
    "& .deleteButtonsArabic": {
        margin: "16px 0px",
        paddingLeft: "16px",
        display: "flex",
        justifyContent: "flex-end"
    },
    "& .deleteDialogClose": {
        color: "#334155", position: 'absolute',
        top: 16
    },
    "& .deleteImageBox": {
        padding: "10px 0px",
        display: "flex",
        justifyContent: "center"        
    },
})

export const Wrapper = styled(Box)({
    width: "100%",
    marginTop: "54px",
    "& .questionLabel": {
        fontSize: "16px", color: "#0F172A", fontFamily: "Urbanist", fontWeight: 700,
        linHheight: "22px", marginBottom: "20px"
    },
    "& .contentPadding": {
        padding: "10px 30px 40px 0px"
    },
    "& .checkBoxStyle": {
        width: "20px", marginRight: "10px",
        height: "20px",
    },
    "& .chipGridItem": {
        alignItems: "center", display: "flex",
        flexWrap: "wrap"
    },
    "& .optionsStyle": {
        fontSize: '14px', fontWeight: 400, fontFamily: 'Urbanist',
        cursor: "pointer",color: "#000000",
        width: "93%",
        "&:hover": { backgroundColor: "transparent" },
        border: '1px solid #E2E8F0',
        minHeight: '50px', lineHight: "24px", textAlign: "left", borderRadius: '8px',
        padding: '0px 16px',
        "& .MuiTypography-root": {
            fontWeight: 400, fontFamily: 'Urbanist', fontSize: "14px",
            linHheight: "22px",
        },
    },
    "& .scenarioAns": {
        padding: "8px 16px"
    },
    "& .optionsStyleTrue": {
        color: "#000000",
        width: "93%",
        fontSize: '14px',
        fontWeight: 400,
        fontFamily: 'Urbanist',
        cursor: "pointer",
        border: "1px solid #059669",
        backgroundColor: "#D1FAE5",
        "&:hover": { backgroundColor: "#D1FAE5" },
        borderRadius: '8px',
        minHeight: '50px',
        lineHight: "24px",
        textAlign: "left",
        padding: '0px 16px',
        "& .MuiTypography-root": {
            linHheight: "22px",
            fontWeight: 400,
            fontFamily: 'Urbanist',
            fontSize: "14px",
        },
    },
    "& .filterChip": {
        fontSize: "14px",
        margin: "2px 2px 6px 2px",
        padding: "14px 4px",
        color: "#475569",
        fontFamily: "Urbanist",
        fontWeight: "400",
        lineHeight: "22px",
        border: "1px solid #E2E8F0",
        display: "flex"
    },
    "& .filterChipSelected": {
        color: "#DC2626",
        border: "1px solid #DC2626",
    },
    "& .filterEditRemove": {
        display: "flex",
        justifyContent: "center",
        "@media (max-width: 425px)": {
            justifyContent: "flex-start"
        },
    }
})

// Customizable Area End