import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { getStorageData, setStorageData } from "framework/src/Utilities";
import { SelectChangeEvent } from '@mui/material/Select';
interface JobDescriptionData {
    id: string;
    attributes: {
        id: number;
        generated_job_title: string;
    };
}

interface CompetencyDataApiResponse {
    message: string;
    competencies: {
        id: number;
        job_title: string;
        description: string;
        competency_name: string;
        job_description_id: number;
        created_at: string;
        updated_at: string;
        competency_type_id: number | null;
        status: boolean;
        competency_dictionary_id: number;
    }[];
}

// Customizable Area End

export const configJSON = require("./config");
export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    selectedLanguage: string;
    token: string;
    jobTitle: JobDescriptionData[];
    difficultyLevel: Array<{
        name: string;
        id: number;
    }>;
    questionList: Array<{
        id: string;
        name: string;
    }>;
    selectedQuestionBank: string;
    ErrorselectedQuestionBank: string;
    competencyData: string[];
    duplicateMultiSelect: {
        selectedDifficulty: string;
        selectedJobTitle: string;
        selectedCompetency: string;
        ErrorCompetency: string;
        ErrorDifficulty: string;
        ErrorJobTitle: string;
        multiQuestionText: string;
        QuestionErrorText: string;
        CompetenciesDropDownData: Array<{
            name: string;
            id: number;
        }>
        multiSelectOptions: {
            text: string;
            checked: boolean;
            ErrorMessage: string;
        }[];
    }[];
    duplicateScenario: {
        selectedDifficulty: string;
        selectedJobTitle: string;
        selectedCompetency: string;
        scenarioQueText: string;
        QuestionErrorText: string;
        scenarioAnswerText: string;
        ErrorMessage: string,
        ErrorCompetency: string;
        ErrorDifficulty: string;
        ErrorJobTitle: string;
        CompetenciesDropDownData: Array<{
            name: string;
            id: number;
        }>
    }[];
    duplicateShortAnswer: {
        selectedDifficulty: string;
        selectedJobTitle: string;
        selectedCompetency: string;
        scenarioQueText: string;
        scenarioAnswerText: string;
        ErrorMessage: string,
        ErrorCompetency: string,
        ErrorDifficulty: string,
        ErrorJobTitle: string,
        QuestionErrorText: string,
        CompetenciesDropDownData: Array<{
            name: string;
            id: number;
        }>
    }[];
    duplicateOpenEnded: {
        selectedDifficulty: string;
        selectedJobTitle: string;
        selectedCompetency: string;
        scenarioQueText: string;
        scenarioAnswerText: string;
        ErrorMessage: string;
        ErrorCompetency: string,
        ErrorDifficulty: string,
        ErrorJobTitle: string,
        QuestionErrorText: string,
        CompetenciesDropDownData: Array<{
            name: string;
            id: number;
        }>
    }[];
    duplicateRadio: {
        selectedDifficulty: string;
        selectedJobTitle: string;
        selectedCompetency: string;
        radioQueText: string;
        ErrorCompetency: string,
        ErrorDifficulty: string,
        ErrorJobTitle: string,
        QuestionErrorText: string,
        CompetenciesDropDownData: Array<{
            name: string;
            id: number;
        }>,
        radioOptions: {
            text: string;
            checked: boolean;
            ErrorMessage: string
        }[];
    }[];
    duplicateCheckboxes: {
        selectedDifficulty: string;
        selectedJobTitle: string;
        selectedCompetency: string;
        checkQuestionText: string;
        ErrorCompetency: string,
        ErrorDifficulty: string,
        ErrorJobTitle: string,
        QuestionErrorText: string,
        CompetenciesDropDownData: Array<{
            name: string;
            id: number;
        }>,
        checkboxesOptions: {
            text: string;
            checked: boolean;
            ErrorMessage: string
        }[];
    }[];
    selectedEssentials: string;
    selectedChoices: string[];
    SelectCompetenciesDropDownData: string,
    SelectIndexCompetenciesDropDownData: number;
    SuccessModel: boolean;
    IsItFromQuestionBankDashboard: boolean;
    KeyForApiCall: string;
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class UploadQuestionsController extends BlockComponent<
    Props,
    S,
    SS
> {

    // Customizable Area Start
    multiSelectQue = {
        selectedDifficulty: "",
        selectedJobTitle: "",
        selectedCompetency: "",
        multiQuestionText: "",
        ErrorCompetency: "",
        ErrorDifficulty: "",
        ErrorJobTitle: "",
        QuestionErrorText: "",
        CompetenciesDropDownData: []
    }
    scenarioBasedObj = {
        selectedDifficulty: "",
        selectedJobTitle: "",
        selectedCompetency: "",
        scenarioQueText: "",
        scenarioAnswerText: "",
        QuestionErrorText: "",
        ErrorMessage: "",
        ErrorCompetency: "",
        ErrorDifficulty: "",
        ErrorJobTitle: "",
        CompetenciesDropDownData: []
    }
    shortAnswerObj = {
        selectedDifficulty: "",
        selectedJobTitle: "",
        selectedCompetency: "",
        scenarioQueText: "",
        scenarioAnswerText: "",
        QuestionErrorText: "",
        ErrorMessage: "",
        ErrorCompetency: "",
        ErrorDifficulty: "",
        ErrorJobTitle: "",
        CompetenciesDropDownData: []
    }
    openEndedObj = {
        selectedDifficulty: "",
        selectedJobTitle: "",
        selectedCompetency: "",
        scenarioQueText: "",
        scenarioAnswerText: "",
        ErrorMessage: "",
        ErrorCompetency: "",
        ErrorDifficulty: "",
        QuestionErrorText: "",
        ErrorJobTitle: "",
        CompetenciesDropDownData: []
    }
    radioObj = {
        selectedDifficulty: "",
        selectedJobTitle: "",
        selectedCompetency: "",
        radioQueText: "",
        ErrorCompetency: "",
        ErrorDifficulty: "",
        ErrorJobTitle: "",
        QuestionErrorText: "",
        CompetenciesDropDownData: [],
    }
    checkboxesObj = {
        selectedDifficulty: "",
        selectedJobTitle: "",
        selectedCompetency: "",
        checkQuestionText: "",
        ErrorCompetency: "",
        ErrorDifficulty: "",
        ErrorJobTitle: "",
        QuestionErrorText: "",
        CompetenciesDropDownData: [],
    }

    DifficultyLevelsApiId: string = "";
    CompetencyTypeApiId: string = "";
    GetQuestionBanksListApiId: string = "";
    CreateUploadQueApiCallId: string = "";
    GetJobTitleApiCallId: string = "";

    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.CountryCodeMessage)
        ];

        this.state = {
            token: "",
            selectedLanguage: "",
            jobTitle: [
                {
                    id: "495",
                    attributes: {
                        id: 495,
                        generated_job_title: "Golang Developer",
                    },
                },
                {
                    id: "496",
                    attributes: {
                        id: 496,
                        generated_job_title: "React Developer",
                    },
                },
            ],
            difficultyLevel: [],
            questionList: [],
            selectedQuestionBank: "",
            ErrorselectedQuestionBank: "",
            competencyData: ["Core competency", "Basic competency", "Advance competency"],
            duplicateMultiSelect: [{ ...this.multiSelectQue, multiSelectOptions: [{ text: "", checked: false, ErrorMessage: "" }, { text: "", checked: false, ErrorMessage: "" }] }],
            duplicateScenario: [],
            duplicateShortAnswer: [],
            duplicateOpenEnded: [],
            duplicateRadio: [],
            duplicateCheckboxes: [],
            selectedEssentials: "text",
            selectedChoices: ["multipleSelection"],
            SelectCompetenciesDropDownData: "duplicateMultiSelect",
            SelectIndexCompetenciesDropDownData: 0,
            SuccessModel: false,
            IsItFromQuestionBankDashboard: true,
            KeyForApiCall: "question_bank_id"
        };

        // Customizable Area End
        runEngine.attachBuildingBlock(this, this.subScribedMessages);
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        runEngine.debugLog("Message Recived", message);
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            this.GetDifficultyLevelsApiResponse(apiRequestCallId, responseJson);
            this.CompetencyTypeApiResponse(apiRequestCallId, responseJson);
            this.QuestionBanksListResponse(apiRequestCallId, responseJson);
            this.CreateUploadApiResponse(apiRequestCallId, responseJson);
            this.GetJobTitleApiResponse(apiRequestCallId, responseJson);
        }
        // Customizable Area End
    }

    // Customizable Area Start
    async componentDidMount() {
        super.componentDidMount();

        const language = await getStorageData("language") || "English";
        this.setState({ selectedLanguage: language });
        const FromPage = await getStorageData("FromPageName")
        const ID = await getStorageData("AddQuestions")

        if (FromPage === "ViewGenerateAssessment") {
            this.setState({ KeyForApiCall: "assessment_id", IsItFromQuestionBankDashboard: false, selectedQuestionBank: ID })
        }
        if (FromPage === "ListingQuestionBank") {
            this.setState({ KeyForApiCall: "question_bank_id", IsItFromQuestionBankDashboard: false, selectedQuestionBank: ID })
        }
        if (FromPage === "QuestionBankDashboard") {
            this.setState({ KeyForApiCall: "question_bank_id", IsItFromQuestionBankDashboard: true })
        }
        const tokenMeta = await getStorageData('signInResponse', true);
        this.setState({
            token: tokenMeta?.meta?.token
        })
        this.GetJobTitle()
        this.DifficultyLevels()
        this.GetQuestionBanksApiCall()
    }

    handleEssentials = (menu: string) => {
        this.setState({ selectedEssentials: menu })
    }

    handlechoices = (menu: string) => {
        const selectedChoices = this.state.selectedChoices;
        if (!selectedChoices.includes(menu)) {
            selectedChoices.push(menu);
            this.setState({ selectedChoices })
        }

        if (menu === "multipleSelection" && this.state.duplicateMultiSelect.length === 0) {
            this.setState({
                duplicateMultiSelect: [{ ...this.multiSelectQue, multiSelectOptions: [{ text: "", ErrorMessage: "", checked: false }, { text: "", checked: false, ErrorMessage: "" }] }],
            })
        }

        if (menu === "checkboxes" && this.state.duplicateCheckboxes.length === 0) {
            this.setState({
                duplicateCheckboxes: [{ ...this.checkboxesObj, checkboxesOptions: [{ text: "", checked: false, ErrorMessage: "" }, { text: "", checked: false, ErrorMessage: "" }] }],
            })
        }
        if (menu === "radio" && this.state.duplicateRadio.length === 0) {
            this.setState({
                duplicateRadio: [{ ...this.radioObj, radioOptions: [{ text: "", checked: false, ErrorMessage: "" }, { text: "", checked: false, ErrorMessage: "" }] }],
            })
        }
        if (menu === "scenarioBased" && this.state.duplicateScenario.length === 0) {
            this.setState({
                duplicateScenario: [{ ...this.scenarioBasedObj }],
            })
        }
        if (menu === "shortAns" && this.state.duplicateShortAnswer.length === 0) {
            this.setState({
                duplicateShortAnswer: [{ ...this.scenarioBasedObj }],
            })
        }
        if (menu === "openEnded" && this.state.duplicateOpenEnded.length === 0) {
            this.setState({
                duplicateOpenEnded: [{ ...this.openEndedObj }],
            })
        }
    }

    handlequestionBank = (event: SelectChangeEvent<string>) => {
        this.setState({ selectedQuestionBank: event.target.value, ErrorselectedQuestionBank: "" });
    }

    handleDifficultyLevel = (event: SelectChangeEvent<string>, index: number) => {
        const duplicateMultiSelect = this.state.duplicateMultiSelect.map((value, position) => {
            if (position === index) {
                return {
                    ...this.state.duplicateMultiSelect[index],
                    ErrorDifficulty: "",
                    selectedDifficulty: event.target.value
                }
            }
            return value;
        });

        this.setState({ duplicateMultiSelect });
    }

    handleJobTitleChange = (event: SelectChangeEvent<string>, index: number) => {
        const duplicateMultiSelect = this.state.duplicateMultiSelect.map((value, position) => {
            if (position === index) {
                return {
                    ...this.state.duplicateMultiSelect[index],
                    ErrorJobTitle: "",
                    selectedJobTitle: event.target.value
                }
            }
            return value;
        });
        this.setState({ duplicateMultiSelect, SelectCompetenciesDropDownData: "duplicateMultiSelect", SelectIndexCompetenciesDropDownData: index }, () => {
            this.CompetencyTypeApicall(event.target.value)
        });
    }

    handleCompetencyType = (event: SelectChangeEvent<string>, index: number) => {
        const duplicateMultiSelect = this.state.duplicateMultiSelect.map((value, position) => {
            if (position === index) {
                return {
                    ...this.state.duplicateMultiSelect[index],
                    ErrorCompetency: "",
                    selectedCompetency: event.target.value
                }
            }
            return value;
        });
        this.setState({ duplicateMultiSelect });
    }

    handleMultiQuestionText = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number) => {
        const duplicateMultiSelect = this.state.duplicateMultiSelect.map((value, position) => {
            if (position === index) {
                return {
                    ...this.state.duplicateMultiSelect[index],
                    QuestionErrorText: "",
                    multiQuestionText: event.target.value
                }
            }
            return value;
        });
        this.setState({ duplicateMultiSelect });
    };

    handleCheckboxChange = (checked: boolean, index: number, optIndex: number) => {
        const duplicateMultiSelect = this.state.duplicateMultiSelect;

        duplicateMultiSelect[index].QuestionErrorText = ""

        duplicateMultiSelect[index].multiSelectOptions[optIndex].checked = checked;

        this.setState({ duplicateMultiSelect });
    };

    handleOptionChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number, optIndex: number,) => {
        const duplicateMultiSelect = this.state.duplicateMultiSelect;
        duplicateMultiSelect[index].multiSelectOptions[optIndex].text = event.target.value;
        duplicateMultiSelect[index].multiSelectOptions[optIndex].ErrorMessage = "";
        this.setState({ duplicateMultiSelect });
    };

    handleAddOtherOption = (index: number) => {
        const duplicateMultiSelect = this.state.duplicateMultiSelect;

        if (duplicateMultiSelect[index].multiSelectOptions.length < 5) {
            duplicateMultiSelect[index].multiSelectOptions.push({ text: '', checked: false, ErrorMessage: "" });
            this.setState({ duplicateMultiSelect });
        }
    }

    handleDuplicateMultiSelect = (index: number) => {
        const duplicateMultiSelect = this.state.duplicateMultiSelect;
        const multiSelectOptions = [...duplicateMultiSelect[index].multiSelectOptions].map(value =>
            ({ text: value.text, checked: value.checked, ErrorMessage: "" })
        )

        const addNewQuestion = {
            ...duplicateMultiSelect[index],
            multiSelectOptions
        };
        const updatedDuplicateMultiSelect = [...duplicateMultiSelect, addNewQuestion]
        this.setState({ duplicateMultiSelect: updatedDuplicateMultiSelect })
    }

    handleDeleteMultiSelect = (index: number) => {
        const duplicateMultiSelect = this.state.duplicateMultiSelect.filter((value, position) => position != index);
        this.setState({ duplicateMultiSelect }, () => {
            if (this.state.duplicateMultiSelect.length === 0) {
                this.setState({ selectedChoices: this.state.selectedChoices.filter((value) => value !== "multipleSelection") })
            }
        })
    }

    handleDifficultyLevelScenario = (event: SelectChangeEvent<string>, index: number) => {
        const duplicateScenario = this.state.duplicateScenario.map((value, position) => {
            if (position === index) {
                return {
                    ...this.state.duplicateScenario[index],
                    ErrorDifficulty: "",
                    selectedDifficulty: event.target.value
                }
            }
            return value;
        });

        this.setState({ duplicateScenario });
    }

    handleJobTitleScenario = (event: SelectChangeEvent<string>, index: number) => {
        const duplicateScenario = this.state.duplicateScenario.map((value, position) => {
            if (position === index) {
                return {
                    ...this.state.duplicateScenario[index],
                    ErrorJobTitle: "",
                    selectedJobTitle: event.target.value
                }
            }
            return value;
        });
        this.setState({ duplicateScenario, SelectCompetenciesDropDownData: "duplicateScenario", SelectIndexCompetenciesDropDownData: index }, () => {
            this.CompetencyTypeApicall(event.target.value)
        });
    }

    handleCompetencyScenario = (event: SelectChangeEvent<string>, index: number) => {
        const duplicateScenario = this.state.duplicateScenario.map((value, position) => {
            if (position === index) {
                return {
                    ...this.state.duplicateScenario[index],
                    ErrorCompetency: "",
                    selectedCompetency: event.target.value
                }
            }
            return value;
        });
        this.setState({ duplicateScenario });
    }

    handleScenarioQuestionText = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number) => {
        const duplicateScenario = this.state.duplicateScenario.map((value, position) => {
            if (position === index) {
                return {
                    ...this.state.duplicateScenario[index],
                    QuestionErrorText: "",
                    scenarioQueText: event.target.value
                }
            }
            return value;
        });
        this.setState({ duplicateScenario });
    };

    handlescenarioAnswer = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number) => {
        const duplicateScenario = this.state.duplicateScenario.map((value, position) => {
            if (position === index) {
                return {
                    ...this.state.duplicateScenario[index],
                    ErrorMessage: "",
                    scenarioAnswerText: event.target.value
                }
            }
            return value;
        });
        this.setState({ duplicateScenario });
    };

    handleDuplicateScenario = (index: number) => {
        const duplicateScenario = this.state.duplicateScenario;
        const addNewScenario = {
            ...duplicateScenario[index],
        };

        const updatedDuplicateMultiSelect = [...duplicateScenario, addNewScenario]
        this.setState({ duplicateScenario: updatedDuplicateMultiSelect })
    }

    handleDeleteScenario = (index: number) => {
        const duplicateScenario = this.state.duplicateScenario.filter((value, position) => position != index);
        this.setState({ duplicateScenario }, () => {
            if (this.state.duplicateScenario.length === 0) {
                this.setState({ selectedChoices: this.state.selectedChoices.filter((value) => value !== "scenarioBased") })
            }
        })
    }

    handleDifficultyLevelRadio = (event: SelectChangeEvent<string>, index: number) => {
        const duplicateRadio = this.state.duplicateRadio.map((value, position) => {
            if (position === index) {
                return {
                    ...this.state.duplicateRadio[index],
                    ErrorDifficulty: "",
                    selectedDifficulty: event.target.value
                }
            }
            return value;
        });

        this.setState({ duplicateRadio });
    }

    handleJobTitleRadio = (event: SelectChangeEvent<string>, index: number) => {
        const duplicateRadio = this.state.duplicateRadio.map((value, position) => {
            if (position === index) {
                return {
                    ...this.state.duplicateRadio[index],
                    ErrorJobTitle: "",
                    selectedJobTitle: event.target.value
                }
            }
            return value;
        });
        this.setState({ duplicateRadio, SelectCompetenciesDropDownData: "duplicateRadio", SelectIndexCompetenciesDropDownData: index }, () => {
            this.CompetencyTypeApicall(event.target.value)
        });
    }

    handleCompetencyRadio = (event: SelectChangeEvent<string>, index: number) => {
        const duplicateRadio = this.state.duplicateRadio.map((value, position) => {
            if (position === index) {
                return {
                    ...this.state.duplicateRadio[index],
                    ErrorCompetency: "",
                    selectedCompetency: event.target.value
                }
            }
            return value;
        });
        this.setState({ duplicateRadio });
    }

    handleRadioQuestionText = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number) => {
        const duplicateRadio = this.state.duplicateRadio.map((value, position) => {
            if (position === index) {
                return {
                    ...this.state.duplicateRadio[index],
                    QuestionErrorText: "",
                    radioQueText: event.target.value
                }
            }
            return value;
        });
        this.setState({ duplicateRadio });
    };

    handleRadioChange = (checked: boolean, index: number, optIndex: number) => {

        const duplicateRadio = this.state.duplicateRadio;

        duplicateRadio[index].radioOptions = duplicateRadio[index].radioOptions.map((value, position) => {
            duplicateRadio[index].QuestionErrorText = ""

            if (position === optIndex) {

                value.checked = true;

            } else {

                value.checked = false;

            }
            return value;
        });
        this.setState({ duplicateRadio });

    };

    handleRadioOptionChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number, optIndex: number,) => {
        const duplicateRadio = this.state.duplicateRadio;
        duplicateRadio[index].radioOptions[optIndex].text = event.target.value;
        duplicateRadio[index].radioOptions[optIndex].ErrorMessage = "";
        this.setState({ duplicateRadio });
    };

    handleDuplicateRadio = (index: number) => {
        const duplicateRadio = this.state.duplicateRadio;
        const radioOptions = [...duplicateRadio[index].radioOptions].map(value =>
            ({ text: value.text, checked: value.checked, ErrorMessage: "" })
        )

        const addNewQuestion = {
            ...duplicateRadio[index],
            radioOptions
        };
        const updatedDuplicateMultiSelect = [...duplicateRadio, addNewQuestion]
        this.setState({ duplicateRadio: updatedDuplicateMultiSelect })
    }

    handleDeleteRadio = (index: number) => {
        const duplicateRadio = this.state.duplicateRadio.filter((value, position) => position != index);
        this.setState({ duplicateRadio }, () => {
            if (this.state.duplicateRadio.length === 0) {
                this.setState({ selectedChoices: this.state.selectedChoices.filter((value) => value !== "radio") })
            }
        })
    }

    handleDifficultyLevelCheckboxes = (event: SelectChangeEvent<string>, index: number) => {
        const duplicateCheckboxes = this.state.duplicateCheckboxes.map((value, position) => {
            if (position === index) {
                return {
                    ...this.state.duplicateCheckboxes[index],
                    ErrorDifficulty: "",
                    selectedDifficulty: event.target.value
                }
            }
            return value;
        });

        this.setState({ duplicateCheckboxes });
    }

    handleJobTitleCheckboxes = (event: SelectChangeEvent<string>, index: number) => {
        const duplicateCheckboxes = this.state.duplicateCheckboxes.map((value, position) => {
            if (position === index) {
                return {
                    ...this.state.duplicateCheckboxes[index],
                    ErrorJobTitle: "",
                    selectedJobTitle: event.target.value
                }
            }
            return value;
        });
        this.setState({ duplicateCheckboxes, SelectCompetenciesDropDownData: "duplicateCheckboxes", SelectIndexCompetenciesDropDownData: index }, () => {
            this.CompetencyTypeApicall(event.target.value)
        });
    }

    handleCompetencyCheckboxes = (event: SelectChangeEvent<string>, index: number) => {
        const duplicateCheckboxes = this.state.duplicateCheckboxes.map((value, position) => {
            if (position === index) {
                return {
                    ...this.state.duplicateCheckboxes[index],
                    ErrorCompetency: "",
                    selectedCompetency: event.target.value
                }
            }
            return value;
        });
        this.setState({ duplicateCheckboxes });
    }

    handleCheckboxesQuestionText = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number) => {
        const duplicateCheckboxes = this.state.duplicateCheckboxes.map((value, position) => {
            if (position === index) {
                return {
                    ...this.state.duplicateCheckboxes[index],
                    QuestionErrorText: "",
                    checkQuestionText: event.target.value
                }
            }
            return value;
        });
        this.setState({ duplicateCheckboxes });
    };

    handleCheckboxTabChange = (checked: boolean, index: number, optIndex: number) => {
        const duplicateCheckboxes = this.state.duplicateCheckboxes;

        duplicateCheckboxes[index].QuestionErrorText = "";

        duplicateCheckboxes[index].checkboxesOptions[optIndex].checked = checked;

        this.setState({ duplicateCheckboxes });
    };


    handleOptionChangeCheckboxes = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number, optIndex: number,) => {
        const duplicateCheckboxes = this.state.duplicateCheckboxes;
        duplicateCheckboxes[index].checkboxesOptions[optIndex].text = event.target.value;
        duplicateCheckboxes[index].checkboxesOptions[optIndex].ErrorMessage = "";
        this.setState({ duplicateCheckboxes });
    };

    handleAddOptionCheckboxes = (index: number) => {
        const duplicateCheckboxes = this.state.duplicateCheckboxes;

        if (duplicateCheckboxes[index].checkboxesOptions.length < 5) {
            duplicateCheckboxes[index].checkboxesOptions.push({ text: '', checked: false, ErrorMessage: "" });
            this.setState({ duplicateCheckboxes });
        }
    }

    handleAddOtherOptionCheckboxes = (index: number) => {
        const duplicateCheckboxes = this.state.duplicateCheckboxes;

        if (duplicateCheckboxes[index].checkboxesOptions.length < 5) {
            duplicateCheckboxes[index].checkboxesOptions.push({ text: '', checked: false, ErrorMessage: "" });
            this.setState({ duplicateCheckboxes });
        }
    }

    handleDuplicateCheckboxes = (index: number) => {
        const duplicateCheckboxes = this.state.duplicateCheckboxes;
        const checkboxesOptions = [...duplicateCheckboxes[index].checkboxesOptions].map(value =>
            ({ text: value.text, checked: value.checked, ErrorMessage: "" })
        )

        const addNewQuestion = {
            ...duplicateCheckboxes[index],
            checkboxesOptions
        };
        const updatedDuplicateMultiSelect = [...duplicateCheckboxes, addNewQuestion]
        this.setState({ duplicateCheckboxes: updatedDuplicateMultiSelect })
    }

    handleDeleteCheckboxes = (index: number) => {
        const duplicateCheckboxes = this.state.duplicateCheckboxes.filter((value, position) => position != index);
        this.setState({ duplicateCheckboxes }, () => {
            if (this.state.duplicateCheckboxes.length === 0) {
                this.setState({ selectedChoices: this.state.selectedChoices.filter((value) => value !== "checkboxes") })
            }
        })
    }

    handleDifficultyLevelShortAns = (event: SelectChangeEvent<string>, index: number) => {
        const duplicateShortAnswer = this.state.duplicateShortAnswer.map((value, position) => {
            if (position === index) {
                return {
                    ...this.state.duplicateShortAnswer[index],
                    ErrorDifficulty: "",
                    selectedDifficulty: event.target.value
                }
            }
            return value;
        });

        this.setState({ duplicateShortAnswer });
    }

    handleJobTitleShortAns = (event: SelectChangeEvent<string>, index: number) => {
        const duplicateShortAnswer = this.state.duplicateShortAnswer.map((value, position) => {
            if (position === index) {
                return {
                    ...this.state.duplicateShortAnswer[index],
                    ErrorJobTitle: "",
                    selectedJobTitle: event.target.value
                }
            }
            return value;
        });
        this.setState({ duplicateShortAnswer, SelectCompetenciesDropDownData: "duplicateShortAnswer", SelectIndexCompetenciesDropDownData: index }, () => {
            this.CompetencyTypeApicall(event.target.value)
        });
    }

    handleCompetencyShortAns = (event: SelectChangeEvent<string>, index: number) => {
        const duplicateShortAnswer = this.state.duplicateShortAnswer.map((value, position) => {
            if (position === index) {
                return {
                    ...this.state.duplicateShortAnswer[index],
                    ErrorCompetency: "",
                    selectedCompetency: event.target.value
                }
            }
            return value;
        });
        this.setState({ duplicateShortAnswer });
    }

    handleShortAnsQuestionText = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number) => {
        const duplicateShortAnswer = this.state.duplicateShortAnswer.map((value, position) => {
            if (position === index) {
                return {
                    ...this.state.duplicateShortAnswer[index],
                    QuestionErrorText: "",
                    scenarioQueText: event.target.value
                }
            }
            return value;
        });
        this.setState({ duplicateShortAnswer });
    };

    handleShortAnswer = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number) => {
        const duplicateShortAnswer = this.state.duplicateShortAnswer.map((value, position) => {
            if (position === index) {
                return {
                    ...this.state.duplicateShortAnswer[index],
                    ErrorMessage: "",
                    scenarioAnswerText: event.target.value
                }
            }
            return value;
        });
        this.setState({ duplicateShortAnswer });
    };

    handleDuplicateShortAns = (index: number) => {
        const duplicateShortAnswer = this.state.duplicateShortAnswer;
        const addNewShortAns = {
            ...duplicateShortAnswer[index],
        };

        const updatedDuplicateShortAns = [...duplicateShortAnswer, addNewShortAns]
        this.setState({ duplicateShortAnswer: updatedDuplicateShortAns })
    }

    handleDeleteShortAns = (index: number) => {
        const duplicateShortAnswer = this.state.duplicateShortAnswer.filter((value, position) => position != index);
        this.setState({ duplicateShortAnswer }, () => {
            if (this.state.duplicateShortAnswer.length === 0) {
                this.setState({ selectedChoices: this.state.selectedChoices.filter((value) => value !== "shortAns") })
            }
        })
    }

    handleDifficultyLevelOpenEnded = (event: SelectChangeEvent<string>, index: number) => {
        const duplicateOpenEnded = this.state.duplicateOpenEnded.map((value, position) => {
            if (position === index) {
                return {
                    ...this.state.duplicateOpenEnded[index],
                    ErrorDifficulty: "",
                    selectedDifficulty: event.target.value
                }
            }
            return value;
        });

        this.setState({ duplicateOpenEnded });
    }

    handleJobTitleOpenEnded = (event: SelectChangeEvent<string>, index: number) => {
        const duplicateOpenEnded = this.state.duplicateOpenEnded.map((value, position) => {
            if (position === index) {
                return {
                    ...this.state.duplicateOpenEnded[index],
                    ErrorJobTitle: "",
                    selectedJobTitle: event.target.value
                }
            }
            return value;
        });
        this.setState({ duplicateOpenEnded, SelectCompetenciesDropDownData: "duplicateOpenEnded", SelectIndexCompetenciesDropDownData: index }, () => {
            this.CompetencyTypeApicall(event.target.value)
        });
    }

    handleCompetencyOpenEnded = (event: SelectChangeEvent<string>, index: number) => {
        const duplicateOpenEnded = this.state.duplicateOpenEnded.map((value, position) => {
            if (position === index) {
                return {
                    ...this.state.duplicateOpenEnded[index],
                    ErrorCompetency: "",
                    selectedCompetency: event.target.value
                }
            }
            return value;
        });
        this.setState({ duplicateOpenEnded });
    }

    handleOpenEndedQuestionText = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number) => {
        const duplicateOpenEnded = this.state.duplicateOpenEnded.map((value, position) => {
            if (position === index) {
                return {
                    ...this.state.duplicateOpenEnded[index],
                    QuestionErrorText: "",
                    scenarioQueText: event.target.value
                }
            }
            return value;
        });
        this.setState({ duplicateOpenEnded });
    };

    handleOpenEndedAnswer = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number) => {
        const duplicateOpenEnded = this.state.duplicateOpenEnded.map((value, position) => {
            if (position === index) {
                return {
                    ...this.state.duplicateOpenEnded[index],
                    ErrorMessage: "",
                    scenarioAnswerText: event.target.value
                }
            }
            return value;
        });
        this.setState({ duplicateOpenEnded });
    };

    handleDuplicateOpenEnded = (index: number) => {
        const duplicateOpenEnded = this.state.duplicateOpenEnded;
        const addNewOpenEnded = {
            ...duplicateOpenEnded[index],
        };

        const updatedDuplicateOpenEnded = [...duplicateOpenEnded, addNewOpenEnded]
        this.setState({ duplicateOpenEnded: updatedDuplicateOpenEnded })
    }

    handleDeleteOpenEnded = (index: number) => {
        const duplicateOpenEnded = this.state.duplicateOpenEnded.filter((value, position) => position != index);
        this.setState({ duplicateOpenEnded }, () => {
            if (this.state.duplicateOpenEnded.length === 0) {
                this.setState({ selectedChoices: this.state.selectedChoices.filter((value) => value !== "openEnded") })
            }
        })
    }

    backToDashBoard = () => {
        const navigateMsg = new Message(getName(MessageEnum.NavigationMessage));
        navigateMsg.addData(getName(MessageEnum.NavigationTargetMessage), "QuestionBankDashboard");
        navigateMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(navigateMsg);
    }

    handleAddQuestion = () => {
        const duplicateMultiSelect = this.state.duplicateMultiSelect;
        const updatedduplicateMultiSelect = [...duplicateMultiSelect, { ...this.multiSelectQue, multiSelectOptions: [{ text: "", checked: false, ErrorMessage: "" }, { text: "", checked: false, ErrorMessage: "" }] }]
        this.setState({
            duplicateMultiSelect: updatedduplicateMultiSelect
        })
    }

    handleAddQuestionScenario = () => {
        const duplicateScenario = this.state.duplicateScenario;
        const updatedduplicate = [...duplicateScenario, { ...this.scenarioBasedObj }]
        this.setState({
            duplicateScenario: updatedduplicate,
        })
    }

    handleAddQuestionCheckboxes = () => {
        const duplicateCheckboxes = this.state.duplicateCheckboxes;
        const updatedDuplicate = [...duplicateCheckboxes, { ...this.checkboxesObj, checkboxesOptions: [{ text: "", checked: false, ErrorMessage: "" }, { text: "", checked: false, ErrorMessage: "" }] }]
        this.setState({
            duplicateCheckboxes: updatedDuplicate
        })
    }

    handleAddQuestionShortAns = () => {
        const duplicateShortAnswer = this.state.duplicateShortAnswer;
        const updatedduplicate = [...duplicateShortAnswer, { ...this.shortAnswerObj }]
        this.setState({
            duplicateShortAnswer: updatedduplicate
        })
    }

    handleAddQuestionOpenEnded = () => {
        const duplicateOpenEnded = this.state.duplicateOpenEnded;
        const updatedduplicate = [...duplicateOpenEnded, { ...this.openEndedObj }]
        this.setState({
            duplicateOpenEnded: updatedduplicate
        })
    }

    handleAddQuestionRadio = () => {
        const duplicateRadio = this.state.duplicateRadio;
        const updatedduplicate = [...duplicateRadio, { ...this.radioObj, radioOptions: [{ text: "", checked: false, ErrorMessage: "" }, { text: "", checked: false, ErrorMessage: "" }] }]
        this.setState({
            duplicateRadio: updatedduplicate
        })
    }

    DifficultyLevels = () => {
        const headers = {
            "token": this.state.token
        };

        const getMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.DifficultyLevelsApiId = getMsg.messageId;

        getMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.GetDifficultyLevels}`
        );

        getMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        getMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "GET"
        );

        runEngine.sendMessage(getMsg.id, getMsg);
    }

    GetDifficultyLevelsApiResponse = (ApicallId: string, response: Array<
        {
            id: number,
            name: string,
        }
    >) => {
        if (ApicallId === this.DifficultyLevelsApiId) {
            const data = response.map((item) => {
                return {
                    id: item.id,
                    name: item.name
                }
            })
            this.setState({
                difficultyLevel: data
            })
        }
    }

    CompetencyTypeApicall = (Id: string) => {
        const headers = {
            "token": this.state.token
        };

        const getMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.CompetencyTypeApiId = getMsg.messageId;

        getMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.GetJobcompetenciesEndPoint}${Id}`
        );

        getMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        getMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "GET"
        );

        runEngine.sendMessage(getMsg.id, getMsg);
    }

    CompetencyTypeApiResponse = (ApicallId: string, response: CompetencyDataApiResponse) => {
        if (ApicallId === this.CompetencyTypeApiId) {

            if (this.state.SelectCompetenciesDropDownData === "duplicateMultiSelect") {
                this.duplicateMultiSelectCompetencyType(response)
            }
            if (this.state.SelectCompetenciesDropDownData === "duplicateScenario") {
                this.duplicateScenarioCompetencyType(response)
            }
            if (this.state.SelectCompetenciesDropDownData === "duplicateShortAnswer") {
                this.duplicateShortAnswerCompetencyType(response)
            }
            if (this.state.SelectCompetenciesDropDownData === "duplicateOpenEnded") {
                this.duplicateOpenEndedCompetencyType(response)
            }
            if (this.state.SelectCompetenciesDropDownData === "duplicateRadio") {
                this.duplicateRadioCompetencyType(response)
            }
            if (this.state.SelectCompetenciesDropDownData === "duplicateCheckboxes") {
                this.duplicateCheckboxesCompetencyType(response)
            }

        }
    }

    duplicateMultiSelectCompetencyType = (Response: CompetencyDataApiResponse) => {
        const Data = Array.isArray(Response.competencies) ? Response.competencies.map((item) => {
            return {
                id: Number(item.id),
                name: item.competency_name
            }
        }) : []

        let data = [...this.state.duplicateMultiSelect];
        data[Number(this.state.SelectIndexCompetenciesDropDownData)].CompetenciesDropDownData = Data;
        this.setState({
            duplicateMultiSelect: data
        })
    }
    duplicateScenarioCompetencyType = (Response: CompetencyDataApiResponse) => {
        const Data = Array.isArray(Response.competencies) ? Response.competencies.map((item) => {
            return {
                id: Number(item.id),
                name: item.competency_name
            }
        }) : []

        let data = [...this.state.duplicateScenario];
        data[Number(this.state.SelectIndexCompetenciesDropDownData)].CompetenciesDropDownData = Data;
        this.setState({
            duplicateScenario: data
        })
    }
    duplicateShortAnswerCompetencyType = (Response: CompetencyDataApiResponse) => {
        const Data = Array.isArray(Response.competencies) ? Response.competencies.map((item) => {
            return {
                id: Number(item.id),
                name: item.competency_name
            }
        }) : []

        let data = [...this.state.duplicateShortAnswer];
        data[Number(this.state.SelectIndexCompetenciesDropDownData)].CompetenciesDropDownData = Data;
        this.setState({
            duplicateShortAnswer: data
        })
    }

    duplicateOpenEndedCompetencyType = (Response: CompetencyDataApiResponse) => {
        const Data = Array.isArray(Response.competencies) ? Response.competencies.map((item) => {
            return {
                id: Number(item.id),
                name: item.competency_name
            }
        }) : []

        let data = [...this.state.duplicateOpenEnded];
        data[Number(this.state.SelectIndexCompetenciesDropDownData)].CompetenciesDropDownData = Data;
        this.setState({
            duplicateOpenEnded: data
        })
    }

    duplicateRadioCompetencyType = (Response: CompetencyDataApiResponse) => {
        const Data = Array.isArray(Response.competencies) ? Response.competencies.map((item) => {
            return {
                id: Number(item.id),
                name: item.competency_name
            }
        }) : []

        let data = [...this.state.duplicateRadio];
        data[Number(this.state.SelectIndexCompetenciesDropDownData)].CompetenciesDropDownData = Data;
        this.setState({
            duplicateRadio: data
        })
    }

    duplicateCheckboxesCompetencyType = (Response: CompetencyDataApiResponse) => {
        const Data = Array.isArray(Response.competencies) ? Response.competencies.map((item) => {
            return {
                id: Number(item.id),
                name: item.competency_name
            }
        }) : []

        let data = [...this.state.duplicateCheckboxes];
        data[Number(this.state.SelectIndexCompetenciesDropDownData)].CompetenciesDropDownData = Data;
        this.setState({
            duplicateCheckboxes: data
        })
    }

    CheckduplicateMultiSelect = () => {
        let duplicateMultiSelect = this.state.duplicateMultiSelect.map((item) => {
            if (item.multiQuestionText === "") {
                item.QuestionErrorText = "Required field"
            } else {
                if (item.multiSelectOptions.filter((data) => data.checked).length === 0) {
                    item.QuestionErrorText = "Select correct answer"
                }
            }
            if (item.selectedCompetency === "") {
                item.ErrorCompetency = "Required field"
            }
            if (item.selectedDifficulty === "") {
                item.ErrorDifficulty = "Required field"
            }
            if (item.selectedJobTitle === "") {
                item.ErrorJobTitle = "Required field"
            }
            const updatedItem = { ...item };
            if (updatedItem.multiSelectOptions.some((option) => option.text === "")) {
                updatedItem.multiSelectOptions = updatedItem.multiSelectOptions.map((option) => {
                    if (option.text === "") {
                        return { ...option, ErrorMessage: "Required field" };
                    }

                    return option;
                });
            }
            return updatedItem;
        });
        this.setState({
            duplicateMultiSelect: duplicateMultiSelect
        })

        return duplicateMultiSelect.every((item) =>
            item.multiSelectOptions.every((option) => option.text !== "") &&
            item.multiQuestionText !== "" &&
            item.selectedCompetency !== "" &&
            item.selectedDifficulty !== "" &&
            item.selectedJobTitle !== "" &&
            item.multiSelectOptions.some((data) => data.checked)
        )
    }

    CheckduplicateScenario = () => {
        let duplicateScenario = this.state.duplicateScenario.map((item) => {
            if (item.scenarioQueText === "") {
                item.QuestionErrorText = "Required field"
            }
            if (item.selectedCompetency === "") {
                item.ErrorCompetency = "Required field"
            }
            if (item.selectedDifficulty === "") {
                item.ErrorDifficulty = "Required field"
            }
            if (item.selectedJobTitle === "") {
                item.ErrorJobTitle = "Required field"
            }
            if (item.scenarioAnswerText === "") {
                return { ...item, ErrorMessage: "Required field" };
            }
            return item;
        });
        this.setState({
            duplicateScenario: duplicateScenario
        })
        return duplicateScenario.every((item) =>
            item.scenarioQueText !== "" &&
            item.selectedCompetency !== "" &&
            item.selectedDifficulty !== "" &&
            item.selectedJobTitle !== "" &&
            item.scenarioAnswerText !== ""
        );
    }

    CheckduplicateShortAnswer = () => {
        let duplicateShortAnswer = this.state.duplicateShortAnswer.map((item) => {
            if (item.scenarioQueText === "") {
                item.QuestionErrorText = "Required field"
            }
            if (item.selectedCompetency === "") {
                item.ErrorCompetency = "Required field"
            }
            if (item.selectedDifficulty === "") {
                item.ErrorDifficulty = "Required field"
            }
            if (item.selectedJobTitle === "") {
                item.ErrorJobTitle = "Required field"
            }
            if (item.scenarioAnswerText === "") {
                return { ...item, ErrorMessage: "Required field" };
            }
            return item;
        });
        this.setState({
            duplicateShortAnswer: duplicateShortAnswer
        })
        return duplicateShortAnswer.every((item) =>
            item.scenarioAnswerText !== "" &&
            item.scenarioQueText !== "" &&
            item.selectedCompetency !== "" &&
            item.selectedDifficulty !== "" &&
            item.selectedJobTitle !== ""
        );
    }

    CheckduplicateOpenEnded = () => {
        let duplicateOpenEnded = this.state.duplicateOpenEnded.map((item) => {
            if (item.scenarioQueText === "") {
                item.QuestionErrorText = "Required field"
            }
            if (item.selectedCompetency === "") {
                item.ErrorCompetency = "Required field"
            }
            if (item.selectedDifficulty === "") {
                item.ErrorDifficulty = "Required field"
            }
            if (item.selectedJobTitle === "") {
                item.ErrorJobTitle = "Required field"
            }
            if (item.scenarioAnswerText === "") {
                return { ...item, ErrorMessage: "Required field" };
            }
            return item;
        });
        this.setState({
            duplicateOpenEnded: duplicateOpenEnded
        })
        return duplicateOpenEnded.every((item) =>
            item.scenarioAnswerText !== "" &&
            item.scenarioQueText !== "" &&
            item.selectedCompetency !== "" &&
            item.selectedDifficulty !== "" &&
            item.selectedJobTitle !== ""
        );
    }

    CheckduplicateRadio = () => {
        let duplicateRadio = this.state.duplicateRadio.map((item) => {
            if (item.radioQueText === "") {
                item.QuestionErrorText = "Required field"
            } else {
                if (item.radioOptions.filter((data) => data.checked).length === 0) {
                    item.QuestionErrorText = "Select correct answer"
                }
            }
            if (item.selectedCompetency === "") {
                item.ErrorCompetency = "Required field"
            }
            if (item.selectedDifficulty === "") {
                item.ErrorDifficulty = "Required field"
            }
            if (item.selectedJobTitle === "") {
                item.ErrorJobTitle = "Required field"
            }
            const updatedItem = { ...item };
            if (updatedItem.radioOptions.some((option) => option.text === "")) {
                updatedItem.radioOptions = updatedItem.radioOptions.map((option) => {
                    if (option.text === "") {
                        return { ...option, ErrorMessage: "Required field" };
                    }
                    return option;
                });
            }
            return updatedItem;
        });
        this.setState({
            duplicateRadio: duplicateRadio
        })
        return duplicateRadio.every((item) =>
            item.radioOptions.every((option) => option.text !== "") &&
            item.radioOptions.some((data) => data.checked) &&
            item.radioQueText !== "" &&
            item.selectedCompetency !== "" &&
            item.selectedDifficulty !== "" &&
            item.selectedJobTitle !== ""
        );
    }

    CheckduplicateCheckboxes = () => {
        let duplicateCheckboxes = this.state.duplicateCheckboxes.map((item) => {
            if (item.checkQuestionText === "") {
                item.QuestionErrorText = "Required field"
            } else {
                if (item.checkboxesOptions.filter((data) => data.checked).length === 0) {
                    item.QuestionErrorText = "Select correct answer"
                }
            }
            if (item.selectedCompetency === "") {
                item.ErrorCompetency = "Required field"
            }
            if (item.selectedDifficulty === "") {
                item.ErrorDifficulty = "Required field"
            }
            if (item.selectedJobTitle === "") {
                item.ErrorJobTitle = "Required field"
            }
            const updatedItem = { ...item };
            if (updatedItem.checkboxesOptions.some((option) => option.text === "")) {
                updatedItem.checkboxesOptions = updatedItem.checkboxesOptions.map((option) => {
                    if (option.text === "") {
                        return { ...option, ErrorMessage: "Required field" };
                    }
                    return option;
                });
            }
            return updatedItem;
        });

        this.setState({
            duplicateCheckboxes,
        });

        return duplicateCheckboxes.every((item) =>
            item.checkboxesOptions.every((option) => option.text !== "") &&
            item.checkboxesOptions.some((data) => data.checked) &&
            item.checkQuestionText !== "" &&
            item.selectedCompetency !== "" &&
            item.selectedDifficulty !== "" &&
            item.selectedJobTitle !== ""
        );
    }

    SaveChanges = () => {
        let duplicateMultiSelect = this.CheckduplicateMultiSelect();
        let CheckduplicateScenario = this.CheckduplicateScenario();
        let duplicateShortAnswer = this.CheckduplicateShortAnswer();
        let duplicateOpenEnded = this.CheckduplicateOpenEnded();
        let duplicateRadio = this.CheckduplicateRadio();
        let duplicateCheckboxes = this.CheckduplicateCheckboxes();
        let selectedQuestionBank = this.state.IsItFromQuestionBankDashboard ? this.state.selectedQuestionBank !== "" : true
        if (!selectedQuestionBank) {
            this.setState({
                ErrorselectedQuestionBank: "Required field"
            })
        }

        if (duplicateMultiSelect && CheckduplicateScenario && duplicateShortAnswer && duplicateOpenEnded && duplicateRadio && duplicateCheckboxes && selectedQuestionBank) {
            this.CreateUploadQuetionApiCall()
        }


    }

    UploadMutliSelect = () => {

        const { duplicateMultiSelect, duplicateScenario, duplicateShortAnswer, duplicateOpenEnded, duplicateRadio, duplicateCheckboxes } = this.state

        let duplicateMultiSelectApiData = duplicateMultiSelect.map((item) => {
            return {
                question: {
                    text: item.multiQuestionText,
                    question_format_id: 1,
                    difficulty_level_id: item.selectedDifficulty,
                    job_description_id: item.selectedJobTitle,
                    competency_ids: item.selectedCompetency
                },
                [this.state.KeyForApiCall]: this.state.selectedQuestionBank,
                choices: item.multiSelectOptions.map((option) => {
                    return {
                        option_text: option.text,
                        correct: option.checked
                    }
                })
            }
        });
        let duplicateScenarioApiData = duplicateScenario.map((item) => {
            return {
                question: {
                    text: item.scenarioQueText,
                    question_format_id: 3,
                    difficulty_level_id: item.selectedDifficulty,
                    job_description_id: item.selectedJobTitle,
                    competency_ids: [item.selectedCompetency]
                },
                text_response: item.scenarioAnswerText,
                [this.state.KeyForApiCall]: this.state.selectedQuestionBank
            }
        })

        let duplicateShortAnswerApiData = duplicateShortAnswer.map((item) => {
            return {
                question: {
                    text: item.scenarioQueText,
                    question_format_id: 3,
                    difficulty_level_id: item.selectedDifficulty,
                    job_description_id: item.selectedJobTitle,
                    competency_ids: [item.selectedCompetency]
                },
                text_response: item.scenarioAnswerText,
                [this.state.KeyForApiCall]: this.state.selectedQuestionBank
            }
        })

        let duplicateOpenEndedApiData = duplicateOpenEnded.map((item) => {
            return {
                question: {
                    text: item.scenarioQueText,
                    question_format_id: 3,
                    difficulty_level_id: item.selectedDifficulty,
                    job_description_id: item.selectedJobTitle,
                    competency_ids: [item.selectedCompetency]
                },
                text_response: item.scenarioAnswerText,
                [this.state.KeyForApiCall]: this.state.selectedQuestionBank
            }
        })

        let duplicateRadioApiData = duplicateRadio.map((item) => {
            return {
                question: {
                    text: item.radioQueText,
                    question_format_id: 4,
                    difficulty_level_id: item.selectedDifficulty,
                    job_description_id: item.selectedJobTitle,
                    competency_ids: item.selectedCompetency
                },
                [this.state.KeyForApiCall]: this.state.selectedQuestionBank,
                choices: item.radioOptions.map((option) => {
                    return {
                        option_text: option.text,
                        correct: option.checked
                    }
                })
            }
        })

        let duplicateCheckboxesApiData = duplicateCheckboxes.map((item) => {
            return {
                question: {
                    text: item.checkQuestionText,
                    question_format_id: 1,
                    difficulty_level_id: item.selectedDifficulty,
                    job_description_id: item.selectedJobTitle,
                    competency_ids: item.selectedCompetency
                },
                choices: item.checkboxesOptions.map((option) => {
                    return {
                        option_text: option.text,
                        correct: option.checked
                    }
                }),
                [this.state.KeyForApiCall]: this.state.selectedQuestionBank
            }
        })


        return [...duplicateMultiSelectApiData, ...duplicateScenarioApiData, ...duplicateShortAnswerApiData, ...duplicateOpenEndedApiData, ...duplicateRadioApiData, ...duplicateCheckboxesApiData];
    }

    CreateUploadQuetionApiCall = async () => {
        const headers = {
            "token": this.state.token,
            "Content-Type": configJSON.validationApiContentType,
        };

        const httpBody = {
            questions: this.UploadMutliSelect()
        }
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.CreateUploadQueApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            "/bx_block_questionbank2/custom_questions"
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        )
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "POST"
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    CreateUploadApiResponse = async (ApiCallId: string, response: {
        message: string;
        created_questions: {
            data: {
                id: string;
                type: string;
                attributes: {
                    id: number;
                    text: string;
                    question_format_name: string;
                    question_bank_id: number;
                    assessment_id: number;
                    created_at: string;
                    job_description: {
                        id: number;
                        name: string;
                    };
                    competencies: [];
                    difficulty_level: {
                        id: number;
                        name: string;
                    };
                    answer: {
                        id: number;
                        answer_type: string;
                        choices: {
                            id: number;
                            option_text: string;
                            correct: boolean;
                        }[];
                        correct_choices: {
                            id: number;
                            option_text: string;
                            correct: boolean;
                        }[];
                    };
                };
            };
        }[];
        errors: [];
    }) => {
        if (this.CreateUploadQueApiCallId === ApiCallId) {
            const FromPage = await getStorageData("FromPageName")
            if (response.message === "Questions processed successfully") {
                this.setState({
                    SuccessModel: true
                })
                if (FromPage === "ViewGenerateAssessment") {
                    setStorageData("assessmentId", JSON.stringify(response.created_questions[0].data.attributes.assessment_id))
                } else {
                    setStorageData("questionBankId", JSON.stringify(response.created_questions[0].data.attributes.question_bank_id))
                }
            }
        }
    }

    GetQuestionBanksApiCall = () => {
        const headers = {
            "token": this.state.token
        };

        const getMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.GetQuestionBanksListApiId = getMsg.messageId;

        getMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `/bx_block_questionbank2/question_banks/`
        );

        getMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        getMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "GET"
        );

        runEngine.sendMessage(getMsg.id, getMsg);
    }


    QuestionBanksListResponse = (ApiCallId: string, response: {
        message: string;
        question_banks: {
            data: {
                id: string;
                type: string;
                attributes: {
                    question_bank_listing: {
                        job_id: number;
                        job_title: string;
                        competencies: string[];
                        question_count: number;
                    };
                };
            };
        }[];
        pagination: {
            current_page: number;
            total_pages: number;
            total_count: number;
            per_page: number;
            prev_page: number;
            next_page: number
        };
    }) => {
        if (ApiCallId === this.GetQuestionBanksListApiId) {
            let data = response.question_banks.map((item) => {
                return {
                    id: item.data.id,
                    name: item.data.attributes.question_bank_listing.job_title
                }
            })
            this.setState({
                questionList: data
            })
        }

    }

    GetJobTitle = () => {
        const headers = {
            "token": this.state.token
        };

        const GetJobTitleMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        GetJobTitleMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getApiMethodType
        );
        this.GetJobTitleApiCallId = GetJobTitleMsg.messageId;
        GetJobTitleMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.GetJobTitleEndPoint}`
        );
        GetJobTitleMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        runEngine.sendMessage(GetJobTitleMsg.id, GetJobTitleMsg);
    }

    GetJobTitleApiResponse = (ApiCallId: string, responseJson: {
        data: {
            id: string;
            type: "job_description";
            attributes: {
                id: number;
                created_at: string;
                generated_job_title: string;
                generated_department: string;
                generated_job_level: string;
                generated_job_experience: string;
                generated_job_summary: string;
                generated_responsibilities: string[];
                generated_requirements: string[];
                generated_key_skills: string[];
                generated_keywords: string[];
                job_level: {
                    id: number;
                    name: string;
                };
                job_family: {
                    id: number;
                    name: string;
                };
                job_experience: {
                    id: number;
                    name: string;
                };
                key_skills: {
                    id: number;
                    name: string;
                }[];
            };
        }[];
        meta: {
            total_pages: number;
            current_page: number;
            total_records: number;
            prev_page: number | null;
            next_page: number | null;
        };
    }) => {
        if (ApiCallId === this.GetJobTitleApiCallId) {
            if (responseJson && responseJson.data && responseJson.data.length > 0) {
                const jobTitle = responseJson.data?.map((item) => {
                    return {
                        id: item.id,
                        attributes: {
                            id: item.attributes.id,
                            generated_job_title: item.attributes.generated_job_title,
                        },
                    }
                })
                this.setState({
                    jobTitle
                })
            }
        }

    }

    CloseModelSuccessModel = async () => {
        this.setState({ SuccessModel: false })
        const FromPage = await getStorageData("FromPageName")
        let path = ""
        if (FromPage === "ViewGenerateAssessment") {
            path = FromPage
        } else {
            path = "ListingQuestionBank"
        }
        const navigateMsg = new Message(getName(MessageEnum.NavigationMessage));
        navigateMsg.addData(getName(MessageEnum.NavigationTargetMessage), path);
        navigateMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(navigateMsg);
    }

    CancelButton = async () => {
        const FromPage = await getStorageData("FromPageName") as string
        const navigateMsg = new Message(getName(MessageEnum.NavigationMessage));
        navigateMsg.addData(getName(MessageEnum.NavigationTargetMessage), FromPage);
        navigateMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(navigateMsg);
    }

    // Customizable Area End
}