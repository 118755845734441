import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { SelectChangeEvent } from '@mui/material/Select';
import { getStorageData, setStorageData } from "framework/src/Utilities";
import * as Yup from "yup";
import { i18n } from "i18next";
interface DifficultyLevel {
    id: string;
    name: string;
};
interface QuestionFormat {
    id: string;
    name: string;
};
interface FocusArea {
    id: string;
    name: string;
};

interface CompetenciesResponse {
    message: string;
    competencies: {
        id: number;
        job_title: string;
        description: string;
        competency_name: string;
        job_description_id: number;
        created_at: string;
        updated_at: string;
        competency_type_id: number | null;
        status: boolean;
        competency_dictionary_id: number;
    }[];
}

// Customizable Area End

export const configJSON = require("./config");
export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    i18n: i18n;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    selectedLanguage: string;
    token: string;
    errorMsg: string;
    assessmentTitle: string;
    selectedJobRole: string;
    jobRole: {
        id: string;
        name: string;
    }[];
    selectedCompetencies: string[];
    competencies: {
        id: string;
        name: string;
    }[];
    difficultyLevel: DifficultyLevel[];
    selectedDifficultyLevel: string[];
    assQuestionFormat: QuestionFormat[];
    selectedQuestionFormat: string[];
    assFocusArea: FocusArea[];
    selectedFocusArea: string[];
    assNoOfQuestion: string;
    noOfQueErrorMsg: string;
    timeAllocation: string;
    assessmentObjectives: string;
    IsLoading: boolean;
    ActiveNumber: number;
    ProgressValue: number;
    ProgressScreen: boolean;
    SuccessModel: boolean;
    TitleErrorMessage: string
    // Customizable Area End

}

interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class GenerateAssessmentController extends BlockComponent<
    Props,
    S,
    SS
> {

    // Customizable Area Start
    difficultyLevelApiCallId: string = "";
    questionFormatApiCallId: string = "";
    focusAreaApiCallId: string = "";
    GenerateAssessmentApiCallId: string = "";
    GetJobTitleApiCallId: string = "";
    GetCompetenciesApiCallId: string = "";

    IntervalGenrate: number | undefined;
    IntervalsGenrate: number | undefined;
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.CountryCodeMessage)
        ];

        this.state = {
            selectedLanguage: "",
            token: "",
            errorMsg: "",
            assessmentTitle: "",
            selectedJobRole: "",
            jobRole: [],
            selectedCompetencies: [],
            competencies: [],
            difficultyLevel: [],
            selectedDifficultyLevel: [],
            assQuestionFormat: [],
            selectedQuestionFormat: [],
            assFocusArea: [],
            selectedFocusArea: [],
            assNoOfQuestion: "",
            noOfQueErrorMsg: "",
            timeAllocation: "",
            assessmentObjectives: "",
            IsLoading: false,
            ActiveNumber: 0,
            ProgressValue: 0,
            ProgressScreen: false,
            SuccessModel: false,
            TitleErrorMessage: "",
        };

        // Customizable Area End
        runEngine.attachBuildingBlock(this, this.subScribedMessages);
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (apiRequestCallId === this.difficultyLevelApiCallId) {
                this.difficultyLeveApiResponse(responseJson)
            }
            if (apiRequestCallId === this.questionFormatApiCallId) {
                this.questionFormatApiResponse(responseJson)
            }
            if (apiRequestCallId === this.focusAreaApiCallId) {
                this.focusAreaApiResponse(responseJson)
            }
            this.GetJobTitleResponse(apiRequestCallId, responseJson)
            this.GetCompetenciesResponse(apiRequestCallId, responseJson)
            this.GetCreateAssessmentApiResponse(apiRequestCallId, responseJson)
        }
        // Customizable Area End
    }

    // Customizable Area Start
    async componentDidMount() {
        super.componentDidMount();
        const language = await getStorageData("language") || "English";
        this.setState({ selectedLanguage: language });

        const signInResponse = await getStorageData("signInResponse");
        const parsedSignInResponse = await JSON.parse(signInResponse)
        this.setState({ token: parsedSignInResponse.meta?.token });

        this.difficultyLevelApiCall();
        this.questionFormatApiCall();
        this.focusAreaApiCall();
        this.GetJobTitle()
    }

    difficultyLeveApiResponse = (responseJson: DifficultyLevel[]) => {
        if (responseJson) {
            this.setState({
                difficultyLevel: responseJson, IsLoading: false
            })
        }
    }

    questionFormatApiResponse = (responseJson: QuestionFormat[]) => {
        if (responseJson) {
            this.setState({
                assQuestionFormat: responseJson,
                IsLoading: false
            })
        }
    }

    focusAreaApiResponse = (responseJson: FocusArea[]) => {
        if (responseJson) {
            this.setState({
                assFocusArea: responseJson,
                IsLoading: false
            })
        }
    }

    difficultyLevelApiCall = () => {
        this.setState({ IsLoading: true })
        const headers = {
            "token": this.state.token
        };
        const questionMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.difficultyLevelApiCallId = questionMsg.messageId;
        questionMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        questionMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.difficultyLevelApiEndPoint}`
        );
        questionMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getApiMethodType
        );
        runEngine.sendMessage(questionMsg.id, questionMsg);
    }

    questionFormatApiCall = () => {
        this.setState({ IsLoading: true })
        const headers = {
            "token": this.state.token
        };
        const questionMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.questionFormatApiCallId = questionMsg.messageId;
        questionMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        questionMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.questionFormatApiEndPoint}`
        );
        questionMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getApiMethodType
        );
        runEngine.sendMessage(questionMsg.id, questionMsg);
    }

    focusAreaApiCall = () => {
        this.setState({ IsLoading: true })
        const headers = {
            "token": this.state.token
        };
        const questionMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.focusAreaApiCallId = questionMsg.messageId;
        questionMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        questionMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.focusAreaApiEndPoint}`
        );
        questionMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getApiMethodType
        );
        runEngine.sendMessage(questionMsg.id, questionMsg);
    }

    assDifficultyLevelChange = (id: string, setFieldValue: (field: string, value: string | number | number[] | string[], shouldValidate?: boolean) => void, KeyName: string) => {
        let selectedDifficultyLevel = this.state.selectedDifficultyLevel;
        if (selectedDifficultyLevel.includes(id)) {
            selectedDifficultyLevel = selectedDifficultyLevel.filter(value => value !== id)
        }
        else {
            selectedDifficultyLevel.push(id)
        }
        this.setState({ selectedDifficultyLevel });
        setFieldValue(KeyName, selectedDifficultyLevel);
    };

    assQuestionFormatChange = (id: string, setFieldValue: (field: string, value: string | number | number[] | string[], shouldValidate?: boolean) => void, KeyName: string) => {
        let selectedQuestionFormat = this.state.selectedQuestionFormat;
        if (selectedQuestionFormat.includes(id)) {
            selectedQuestionFormat = selectedQuestionFormat.filter(value => value !== id)
        }
        else {
            selectedQuestionFormat.push(id)
        }
        this.setState({ selectedQuestionFormat });
        setFieldValue(KeyName, selectedQuestionFormat);
    };

    assFocusAreaChange = (id: string, setFieldValue: (field: string, value: string | number | number[] | string[], shouldValidate?: boolean) => void, KeyName: string) => {
        let selectedFocusArea = this.state.selectedFocusArea;
        if (selectedFocusArea.includes(id)) {
            selectedFocusArea = selectedFocusArea.filter(value => value !== id)
        }
        else {
            selectedFocusArea.push(id)
        }
        this.setState({ selectedFocusArea });
        setFieldValue(KeyName, selectedFocusArea);
    };

    assessmentTitleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, setFieldValue: (field: string, value: string, shouldValidate?: boolean) => void, KeyName: string) => {
        this.setState({ assessmentTitle: event.target.value })
        setFieldValue(KeyName, event.target.value)
    }

    handleJobRoleChange = (event: SelectChangeEvent<string>, setFieldValue: (field: string, value: string | number | number[] | string[], shouldValidate?: boolean) => void, KeyName: string) => {
        this.setState({ selectedJobRole: event.target.value });
        setFieldValue(KeyName, event.target.value)
    }

    handleCompetenciesChange = (event: SelectChangeEvent<string[]>, setFieldValue: (field: string, value: string | number | number[] | string[], shouldValidate?: boolean) => void, KeyName: string) => {
        const { value } = event.target;
        this.setState({
            selectedCompetencies: typeof value === 'string' ? value.split(',') : value,
        });
        // setFieldValue(KeyName, typeof value === 'string' ? value.split(',') : value)
    };
    handleCompetencyCheckbox = (compId: string, setFieldValue: (field: string, value: string | number | number[] | string[], shouldValidate?: boolean) => void, KeyName: string) => {
        let selectedCompetencies = this.state.selectedCompetencies;
        if (selectedCompetencies.includes(compId)) {
            selectedCompetencies = selectedCompetencies.filter(value => value !== compId)
        }
        else {
            selectedCompetencies.push(compId)
        }
        this.setState({ selectedCompetencies })
        setFieldValue(KeyName, selectedCompetencies)
    }

    assNoOfQuestionChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, setFieldValue: (field: string, value: string, shouldValidate?: boolean) => void, KeyName: string) => {
        const value = event.target.value;
        const number = parseInt(value);
        const regex = /^\d{0,3}$/;
        if (regex.test(value)) {
            if (value === "" || (number <= 100)) {
                setFieldValue(KeyName, value)
                this.setState({
                    assNoOfQuestion: value,
                    noOfQueErrorMsg: "",
                });
            } else {
                this.setState({
                    noOfQueErrorMsg: this.TraslationFuncation("GenerateAssessment.NumberCannotBeGreaterThan100")
                });
            }
        }
    }

    timeAllocationChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, setFieldValue: (field: string, value: string | number | number[] | string[], shouldValidate?: boolean) => void, KeyName: string) => {
        this.setState({ timeAllocation: event.target.value })
        setFieldValue(KeyName, event.target.value)
    }

    handleAssessmentObjective = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, setFieldValue: (field: string, value: string | number | number[] | string[], shouldValidate?: boolean) => void, KeyName: string) => {
        this.setState({ assessmentObjectives: event.target.value.length > 5000 ? this.state.assessmentObjectives : event.target.value })
        setFieldValue(KeyName, event.target.value)
    }

    navigateToDashboard = async () => {
        const PathName = await getStorageData("GenerateAssessmentBack")
        const navigateMsg = new Message(getName(MessageEnum.NavigationMessage));
        navigateMsg.addData(getName(MessageEnum.NavigationTargetMessage), PathName);
        navigateMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(navigateMsg);
    }

    TraslationFuncation = (key: string) => {
        if (this.props.i18n) {
            return this.props.i18n.t(key);
        } else {
            return key
        }
    }

    AssessmentFormSchema = Yup.object().shape({
        AssessmentTitle: Yup.string().required(this.TraslationFuncation("RequiredField")),
        AssessmentJobRole: Yup.string().required(this.TraslationFuncation("RequiredField")),
        AssessmentCompetencies: Yup.array().required(this.TraslationFuncation("RequiredField")).min(1, this.TraslationFuncation("GenerateAssessment.AtLeastOneCompetencyIsRequired")),
        AssessmentDifficultyLevel: Yup.array().required(this.TraslationFuncation("RequiredField")).min(1, this.TraslationFuncation("GenerateAssessment.AtLeastOneDifficultyLevelIsRequired")),
        AssNoOfQuestion: Yup.number().required(this.TraslationFuncation("RequiredField")).positive(this.TraslationFuncation("GenerateAssessment.NumberOfQuestionsMustBePositive")).integer(this.TraslationFuncation("GenerateAssessment.NumberOfQuestionsMustBeAnInteger")),
        TimeAllocation: Yup.string().required(this.TraslationFuncation("RequiredField")),
        AssessmentQuestionFormat: Yup.array().required(this.TraslationFuncation("RequiredField")).min(1, this.TraslationFuncation("GenerateAssessment.AtLeastOneQuestionFormatIsRequired")),
        AssessmentFocusArea: Yup.array().required(this.TraslationFuncation("RequiredField")).min(1, this.TraslationFuncation("GenerateAssessment.AtLeastOneFocusAreaIsRequired")),
        ObjectivesOfAssessment: Yup.string().required(this.TraslationFuncation("RequiredField")).max(5000, this.TraslationFuncation("GenerateAssessment.AssessmentObjectiveMustBeLessThan5000Characters")),
    });

    GenerateAssessmentApiFuncation = (values: {
        AssessmentTitle: string;
        AssessmentJobRole: string;
        AssessmentCompetencies: string[];
        AssessmentDifficultyLevel: string[];
        AssNoOfQuestion: string;
        TimeAllocation: string;
        AssessmentQuestionFormat: string[];
        AssessmentFocusArea: string[];
        ObjectivesOfAssessment: string;
    }) => {
        this.setState({ ProgressScreen: true })
        const headers = {
            "token": this.state.token,
            "Content-Type": configJSON.exampleApiContentType,
        };
        const GenerateAssessment = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        const httpBody = {
            assessment: {
                title: values.AssessmentTitle,
                number_of_questions: Number(values.AssNoOfQuestion),
                time_allocation: Number(values.TimeAllocation),
                objective: values.ObjectivesOfAssessment,
                job_description_id: values.AssessmentJobRole,
                competency_ids: values.AssessmentCompetencies.map(Number),
                difficulty_level_ids: values.AssessmentDifficultyLevel,
                focus_area_ids: values.AssessmentFocusArea,
                question_format_ids: values.AssessmentQuestionFormat,
            }
        }

        this.GenerateAssessmentApiCallId = GenerateAssessment.messageId;
        GenerateAssessment.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        GenerateAssessment.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.GenerateAssessmentEndPoint}`
        );
        GenerateAssessment.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        )
        GenerateAssessment.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.postApiMethodType
        );
        runEngine.sendMessage(GenerateAssessment.id, GenerateAssessment);
    }

    GetCreateAssessmentApiResponse = (ApiCallId: string, response: {
        message: string;
        assessment: {
            data: {
                id: string;
                type: string;
                attributes: {
                    id: number;
                    title: string;
                    time_allocation: number;
                    objective: string;
                    number_of_questions: number;
                    competencies: {
                        id: number;
                        job_title: string;
                        description: string;
                        competency_name: string;
                        job_description_id: number;
                        created_at: string;
                        updated_at: string;
                        competency_type_id: any; // Can be more specific if the type is known
                        status: boolean;
                        competency_dictionary_id: number;
                    }[];
                    status: string; // Assuming status is a string based on the provided data
                    creator: { id: number; name: string; };
                    difficulty_levels: { id: number; name: string; }[];
                    focus_areas: { id: number; name: string; }[];
                    question_formats: { id: number; name: string; }[];
                    job_description: { id: number; job_title: string; };
                    questions: {
                        data: {
                            id: string;
                            type: string;
                            attributes: {
                                id: number;
                                question_format_name: string;
                                question_bank_id: string; // Can be more specific if the type is known
                                assessment_id: number;
                                created_at: string;
                                job_description: {
                                    id: string; // Can be more specific if the type is known
                                    name: string; // Can be more specific if the type is known
                                };
                                competencies: []; // Can be more specific if the structure is known
                                difficulty_level: {
                                    id: number;
                                    name: string;
                                };
                                text: string;
                                answer: {
                                    id: number;
                                    answer_type: string;
                                    choices: {
                                        id: number;
                                        option_text: string;
                                        correct: boolean;
                                    }[];
                                    correct_choices: {
                                        id: number;
                                        option_text: string;
                                        correct: boolean;
                                    }[];
                                };
                            };
                        }[];
                    }[];
                };
            };
        };
        errors: Array<string>
    }) => {
        if (ApiCallId === this.GenerateAssessmentApiCallId) {
            if (response.message === "assessment created successfully") {
                setStorageData("assessmentId", response.assessment.data.id)
                this.setState({
                    IsLoading: false,
                    ProgressScreen: false,
                    ProgressValue: 100,
                    SuccessModel: true,
                }, () => {
                    this.setState({
                        ProgressValue: 0,
                    })
                })
            } else {
                if (response.errors[0] === "Title has already been taken") {
                    this.setState({
                        TitleErrorMessage: this.TraslationFuncation("GenerateAssessment.TitleHasAlreadyBeenTaken"),
                    })
                }
                this.setState({
                    IsLoading: false,
                    ProgressScreen: false,
                    ProgressValue: 100,
                }, () => {
                    this.setState({
                        ProgressValue: 0,
                    })
                })
            }


        }
    }

    GetJobTitle = () => {
        this.setState({ IsLoading: true })
        const headers = {
            "token": this.state.token
        };

        const GetJobTitleMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        GetJobTitleMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getApiMethodType
        );
        this.GetJobTitleApiCallId = GetJobTitleMsg.messageId;
        GetJobTitleMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.GetJobTitleEndPoint}`
        );
        GetJobTitleMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        runEngine.sendMessage(GetJobTitleMsg.id, GetJobTitleMsg);
    }


    GetJobTitleResponse = (ApiCallId: string, response: {
        data: {
            id: string;
            type: string;
            attributes: {
                id: number;
                created_at: string;
                generated_job_title: string;
                generated_department: string;
                generated_job_level: string;
                generated_job_experience: string;
                generated_job_summary: string;
                generated_responsibilities: string[];
                generated_requirements: string[];
                generated_key_skills: string[];
                generated_keywords: string[];
                job_level: {
                    id: number;
                    name: string;
                };
                job_family: {
                    id: number;
                    name: string;
                };
                job_experience: {
                    id: number;
                    name: string;
                };
                key_skills: {
                    id: number;
                    name: string;
                }[];
            };
        }[];
        meta: {
            total_pages: number;
            current_page: number;
            total_records: number;
            prev_page: number | null;
            next_page: number | null;
        };
    }) => {
        if (this.GetJobTitleApiCallId === ApiCallId) {
            const JobTitle = response.data.map((item) => {
                return {
                    id: item.id,
                    name: item.attributes.generated_job_title,
                };
            });
            this.setState({
                jobRole: JobTitle,
                IsLoading: false
            })
        }

    }

    GetJobCompetencies = (Id: string) => {
        this.setState({ IsLoading: true })
        const headers = {
            "token": this.state.token
        };

        const GetJobTitleMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        GetJobTitleMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getApiMethodType
        );
        this.GetCompetenciesApiCallId = GetJobTitleMsg.messageId;
        GetJobTitleMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.GetJobcompetenciesEndPoint}${Id}`
        );
        GetJobTitleMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        runEngine.sendMessage(GetJobTitleMsg.id, GetJobTitleMsg);
    }

    GetCompetenciesResponse = (ApiCallId: string, Response: CompetenciesResponse) => {
        if (this.GetCompetenciesApiCallId === ApiCallId) {
            const Data = Response.competencies.map((item) => {
                return {
                    id: `${item.id}`,
                    name: item.competency_name
                }
            })
            this.setState({ competencies: Data, IsLoading: false })
        }

    }

    async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined) {
        if (prevState.ProgressScreen !== this.state.ProgressScreen) {
            if (this.state.ProgressScreen) {
                this.IntervalGenrate = setInterval(() => {
                    if (this.state.ActiveNumber === 100) {
                        this.setState({ ActiveNumber: 0 })
                    } else {
                        this.setState({ ActiveNumber: this.state.ActiveNumber + 1 })
                    }
                }, 300) as unknown as number


                this.IntervalsGenrate = setInterval(() => {
                    if (this.state.ProgressValue >= 90) {
                        clearInterval(this.IntervalGenrate);
                        clearInterval(this.IntervalsGenrate);
                    } else {
                        this.setState({ ProgressValue: this.state.ProgressValue + 1 })
                    }
                }, 100) as unknown as number
            }
        }
    }

    navigateToAnyPage = (PageName: string) => {
        const navigateMsg = new Message(getName(MessageEnum.NavigationMessage));
        navigateMsg.addData(getName(MessageEnum.NavigationTargetMessage), PageName);
        navigateMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(navigateMsg);
    }

    NaviGateToViewScreen = () => {
        this.navigateToAnyPage("ViewGenerateAssessment")
    }
    TrueOrFalseConditionPass = (Condition:boolean,TrueCondition:string,FalseCondition:string) =>{
        if (Condition) {
            return TrueCondition
        }else{
            return FalseCondition
        }
    }

    // Customizable Area End
}