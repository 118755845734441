import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { getStorageData } from "framework/src/Utilities";
interface QuestionBankData {
    data?: {
        id: string;
        type: string;
        attributes: {
            selected_question_types: string[];
            selected_question_formats: string[];
            selected_difficulty_levels: string[];
            selected_focus_areas: string[];
            questions: Questions[];
        }
    }
}
interface QuestionBankResponse {
    message: string;
    error: string;
    question_bank: QuestionBankData;
}

export interface Questions {
    data?: {
        id: string;
        type: string;
        attributes: {
            id: string;
            text: string;
            answer_type: string;
            question_bank_id: string;
            question_format_name: string;
            competencies: {
                id: number;
                name: string;
            }[]
            difficulty_level: {
                id: string;
                name: string;
            }
            job_description: {
                id: string;
                name: string;
            }
            answer: {
                id: string;
                answer_type: string;
                text_response: string;
                choices: {
                    id: string;
                    option_text: string;
                    correct: boolean;
                }[];
                correct_choices: {
                    id: string;
                    option_text: string;
                    correct: boolean;
                }[];
            };
        };
    };
}

interface Choices {
    id?: string;
    text?: string;
    answer_type?: string;
    question_bank_id?: string;
    answer?: {
        id: string;
        answer_type: string;
        choices: {
            id: string;
            option_text: string;
            correct: boolean;
        }[];
        correct_choices?: {
            id: string;
            option_text: string;
            correct: boolean;
        }[];
    };
}

// Customizable Area End

export const configJSON = require("./config");
export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    token: string;
    selectedLanguage: string;
    questionBankId: string;
    errorMsg: string;
    questionId: string;
    editQuestion: string;
    editOptions: {
        id: string;
        option_text: string;
        correct: boolean;
    }[];
    AnswerType: string;
    question_format_id: number,
    difficulty_level_id: number,
    job_description_id: number,
    competency_ids: Array<number>,
    QuetionType: string;
    questionBankData: Choices[];
    updateDialog: boolean;
    // Customizable Area End

}

interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class EditQuestionBankController extends BlockComponent<
    Props,
    S,
    SS
> {

    // Customizable Area Start
    listQuestionApiCallId: string = "";
    editQuestionApiCallId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.CountryCodeMessage)
        ];

        this.state = {
            token: "",
            selectedLanguage: "",
            errorMsg: "",
            questionBankId: "",
            questionId: "",
            editQuestion: "",
            questionBankData: [],
            editOptions: [],
            AnswerType: "",
            question_format_id: -1,
            difficulty_level_id: -1,
            job_description_id: -1,
            competency_ids: [],
            QuetionType: "",
            updateDialog: false
        };

        // Customizable Area End
        runEngine.attachBuildingBlock(this, this.subScribedMessages);
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (apiRequestCallId === this.listQuestionApiCallId) {
                this.listQuestionApiResponse(responseJson)
            }
            if (apiRequestCallId === this.editQuestionApiCallId) {
                this.editQuestionApiResponse(responseJson)
            }
        }
        // Customizable Area End
    }

    // Customizable Area Start
    async componentDidMount() {
        super.componentDidMount();
        const signInResponse = await getStorageData("signInResponse");
        const parsedSignInResponse = await JSON.parse(signInResponse)
        this.setState({ token: parsedSignInResponse.meta?.token })

        const questionId = await getStorageData("editQuestionId");
        const parsedQuestionId = await JSON.parse(questionId)
        this.setState({ questionId: parsedQuestionId });

        const questionBankId = await getStorageData("questionBankId");
        const parsedId = await JSON.parse(questionBankId)
        this.setState({ questionBankId: parsedId }, () => {
            this.listQuestionApiCall()
        });

        const language = await getStorageData("language") || "English";
        this.setState({ selectedLanguage: language });
    }

    listQuestionApiResponse = (responseJson: QuestionBankResponse) => {
        if (responseJson && !responseJson.error) {
            const questionResponse = responseJson.question_bank.data?.attributes.questions || [];
            const matchingQuestions = questionResponse && questionResponse.filter(
                (options) => options.data?.id == this.state.questionId);
                
            let AnswerData = matchingQuestions[0]?.data?.attributes?.question_format_name === "Multiple Choice" ? matchingQuestions[0].data?.attributes?.answer.choices :
                [{
                    id: `${matchingQuestions[0]?.data?.attributes.answer.id}`,
                    option_text: `${matchingQuestions[0]?.data?.attributes?.answer?.text_response}`,
                    correct: true,
                }]
            this.setState({
                QuetionType: `${matchingQuestions[0]?.data?.attributes?.question_format_name}`,
                editOptions: AnswerData || [],
                AnswerType: `${matchingQuestions[0]?.data?.attributes?.question_format_name}`,
                question_format_id: 1,
                difficulty_level_id: Number(matchingQuestions[0]?.data?.attributes?.difficulty_level?.id),
                job_description_id: Number(matchingQuestions[0]?.data?.attributes?.job_description?.id),
            })

            let competenciesId = matchingQuestions[0]?.data?.attributes?.competencies?.map((item: { id: number }) => item?.id);
            if (Array.isArray(competenciesId)) {
                this.setState({
                    competency_ids: competenciesId,
                })
            }

            if (matchingQuestions.length) {
                const currentQuestion = matchingQuestions[0]?.data?.attributes;
                this.setState({
                    questionBankData: currentQuestion ? [currentQuestion] : []
                }, () => {
                    const plainText = this.state.questionBankData?.[0]?.text?.replace(/^Question:\s*/, "") || "";
                    this.setState({ editQuestion: plainText })
                })
            }

        } else {
            this.setState({
                errorMsg: responseJson.error
            });
        }
    }

    editQuestionApiResponse = (responseJson: QuestionBankResponse) => {
        if (responseJson && !responseJson.error) {
            this.navigateToListing();
        } else {
            this.setState({
                errorMsg: responseJson.error
            });
        }
    }

    listQuestionApiCall = () => {
        const headers = {
            "token": this.state.token
        };
        const getQuestionListMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.listQuestionApiCallId = getQuestionListMsg.messageId;
        getQuestionListMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        getQuestionListMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.questionListApiEndPoint}/${this.state.questionBankId}`
        );
        getQuestionListMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getApiMethodType
        );
        runEngine.sendMessage(getQuestionListMsg.id, getQuestionListMsg);
    }

    editQuestionApiCall = () => {
        const headers = {
            "token": this.state.token,
            "Content-Type": "application/json"
        };


        const MultipleChoice = {
            questions: [
                {
                    question: {
                        text: this.state.editQuestion,
                        question_format_id: this.state.question_format_id,
                        difficulty_level_id: this.state.difficulty_level_id,
                        job_description_id: this.state.job_description_id,
                        competency_ids: this.state.competency_ids
                    },
                    choices: this.state.editOptions.map(option => ({
                        option_text: option.option_text,
                        correct: option.correct
                    }))
                }
            ]
        }

        const ScenarioBasedBody = {
            custom_question: {
                questions: [
                    {
                        question: {
                            text: this.state.editQuestion
                        },
                        text_response: this.state.editOptions[0]?.option_text
                    }
                ]
            }
        }

        const rawBody = this.state.AnswerType === "Multiple Choice" ? MultipleChoice : ScenarioBasedBody

        const questionMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.editQuestionApiCallId = questionMsg.messageId;
        questionMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        questionMsg.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(rawBody)
        );
        questionMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.editQuestionApiEndPoint}/${this.state.questionId}`
        );
        questionMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.putApiMethodType
        );
        runEngine.sendMessage(questionMsg.id, questionMsg);
    }

    questionChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({ editQuestion: event.target.value });
    }

    handleOptionChange = (event: React.ChangeEvent<HTMLInputElement>, id: string): void => {
        const updatedOptions = this.state.editOptions?.map(option => {
            if (option.id === id) {
                return {
                    ...option,
                    option_text: event.target.value
                };
            }
            return option;
        });

        this.setState({
            editOptions: updatedOptions
        });
    };

    handleUpdateClose = () => {
        this.setState({ updateDialog: false })
    }

    handleEditQuestion = () => {
        this.setState({ updateDialog: true })
    }

    navigateToListing = () => {
        const navigateMsg = new Message(getName(MessageEnum.NavigationMessage));
        navigateMsg.addData(getName(MessageEnum.NavigationTargetMessage), "ListingQuestionBank");
        navigateMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(navigateMsg);
    }
    // Customizable Area End
}