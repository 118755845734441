import React from "react";
// Customizable Area Start
import {
    Box,
    Grid,
    styled,
    Typography,
    Button,
    Divider,
    Input,
    TextField,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { camera, viewB, viewBLeft, successImg, companyImage, linkedinImage, twitterImage, boxImage, removeImg, uploadImg } from "./assets";
import { DashboardHeaderWeb as DashboardHeader } from "../../dashboard/src/DashboardHeader.web";
export const configJSON = require("./config");
// Customizable Area End

import EditUserProfileController, {
    Props
} from "./EditUserProfileController";
import { withTranslation } from "react-i18next";

export default class EditUserProfile extends EditUserProfileController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    editProfileDetail = () => {
        return (
            <Wrapper>
                <Grid container className="profileDetailContainer">
                    <Grid item xs={12} className={"profilePhotoBox "+this.TrueOrFalseCondition(this.state.IsItEnglish," BoderRadiusForEnglish "," BoderRadiusForArabic ")}>
                        <img src={viewBLeft.default} className="profilePhotoLeft" />
                        <img src={viewB.default} className="profilePhotoRight" height="100%" />
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container>
                            <Grid item xs={3} md={2} xl={1.5}>
                                <Box
                                    className="profileImage"
                                    sx={{ backgroundColor: this.state.referenceImage ? "none" : "#111" }}>
                                    <Box data-test-id="upload_photo_dropdown" onClick={this.uploadImageDropdown}>
                                        <Box className="profileImageContainer">
                                            <img
                                                className={this.state.referenceImage ? "profilePhoto" : ""}
                                                alt="Profile Image"
                                                src={this.state.referenceImage || camera.default}
                                            />
                                        </Box>
                                        <Box className="editPhotoText editPhotoTextPosition">
                                            {!this.state.referenceImage && this.TraslationFuncation("UserProfile.EditPhoto")}
                                        </Box>

                                    </Box>
                                    {this.state.uploadImgDropdown && (
                                        <Box className={" imgDropdownMenu "+ this.TrueOrFalseCondition(this.state.IsItEnglish," imgDropdownMenuForEnglish "," imgDropdownMenuForArabic ")}>
                                            <Button component="label" data-test-id="upload_photo" className="dropdownItem">
                                                <Box className="dropdownItemUpload">
                                                    <img src={uploadImg.default} alt="Upload Image" className="viewProfileImgStyle" />
                                                    {this.TraslationFuncation("UserProfile.UploadPhoto")}
                                                    <input
                                                        data-test-id="upload_referenceImage"
                                                        type="file"
                                                        name="image"
                                                        accept="image/png, image/jpeg"
                                                        onChange={(event) => {
                                                            this.handleImageChange(event);
                                                        }}
                                                    />
                                                </Box>
                                            </Button>
                                            <Typography onClick={this.removeProfilePhoto} data-test-id="remove_photo" className="dropdownItem">
                                                <img src={removeImg.default} alt="Remove Image" className="viewProfileImgStyle" />
                                                {this.TraslationFuncation("UserProfile.RemovePhoto")}
                                            </Typography>
                                        </Box>
                                    )}
                                    {this.state.referenceImageError &&
                                        <Box className={"profilePhotoError"+this.TrueOrFalseCondition(this.state.IsItEnglish," profilePhotoErrorEnglish "," profilePhotoErrorArabic ")}>* {this.state.referenceImageError}</Box>
                                    }
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12}>
                        <Grid container spacing={2} justifyContent="center">
                            <Grid item xs={11}>
                                <Typography className="personalInfoText">
                                    {this.TraslationFuncation("UserProfile.PersonalInformation")}
                                </Typography>
                            </Grid>
                            <Grid item xs={11} sm={5.5}>
                                <Grid container spacing={2} className="userDetailBox">
                                    <Grid item xs={11.5}>
                                        <Typography className="editUserInputTitle">
                                            {this.TraslationFuncation("UserProfile.Name")}
                                            <span>*</span></Typography>
                                        <Input
                                            data-test-id="name"
                                            className="enterInputField"
                                            value={this.state.name}
                                            disableUnderline
                                            placeholder={
                                                this.TraslationFuncation("UserProfile.Name")}
                                            onChange={(event) => this.nameChange(event)}
                                            autoComplete="off"
                                        />
                                        <Typography className="errorStyle">
                                            {this.state.saveProfileClicked && !this.state.name &&
                                                this.TraslationFuncation("UserProfile.ThisFieldIsRequired")}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={11.5}>
                                        <Typography className="editUserInputTitle">{this.TraslationFuncation("UserProfile.EmailAddress")}<span>*</span></Typography>
                                        <Input
                                            data-test-id="email_address"
                                            className="enterInputField"
                                            value={this.state.emailAddress}
                                            disableUnderline
                                            placeholder={
                                                this.TraslationFuncation("UserProfile.EmailAddress")
                                            }
                                            onChange={(event) => this.emailAddressChange(event)}
                                            autoComplete="off"
                                            onBlur={this.validationEmail}
                                        />
                                        <Typography className="errorStyle">
                                            {this.state.emailError?.length > 0 && this.state.emailError}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item xs={11} sm={5.5}>
                                <Grid container spacing={2} className="userDetailBox">
                                    <Grid item xs={11.5}>
                                        <Typography className="editUserInputTitle">
                                            {this.TraslationFuncation("UserProfile.DateOfBirth")}<span>*</span></Typography>
                                        <TextField
                                            type="date"
                                            inputRef={this.dateInputRef}
                                            value={this.state.birthDate}
                                            onChange={this.birthDateChange}
                                            data-test-id="date_of_birth"
                                            className="birthDateField"
                                            InputLabelProps={{
                                                shrink: true,
                                                sx: { color: this.state.birthDate ? 'lightgray' : '#aaa' },
                                            }}
                                            placeholder={this.TraslationFuncation("UserProfile.DateOfBirth")}
                                            autoComplete="off"
                                            inputProps={{
                                                max: this.getMaxDateOfBirthDate()
                                            }}
                                            sx={{
                                                '& .MuiInputBase-input': {
                                                    border: 'none',
                                                    fontFamily: 'Urbanist',
                                                    fontSize: '16px',
                                                    fontWeight: 400,
                                                    padding: '12px 16px',
                                                    color: "rgba(15, 23, 42, 1)"
                                                },
                                                '& .MuiOutlinedInput-root': {
                                                    '& fieldset': {
                                                        border: 'none',
                                                    },
                                                    '&:hover fieldset': {
                                                        border: 'none',
                                                    },
                                                },
                                            }}

                                        />
                                        <Typography className="errorStyle">
                                            {this.state.saveProfileClicked && !this.state.birthDate && this.TraslationFuncation("UserProfile.ThisFieldIsRequired")}
                                            {this.state.saveProfileClicked && this.state.errorDateOfBirth?.length > 0 &&
                                                this.TraslationFuncation("UserProfile.DateOfBirthShouldNotBeLessThen18Years")
                                            }
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={11.5}>
                                        <Typography className="editUserInputTitle">
                                            {this.TraslationFuncation("UserProfile.CompanyName")}
                                            <span>*</span></Typography>
                                        <Input
                                            data-test-id="company_name"
                                            className="enterInputField"
                                            value={this.state.companyName}
                                            disableUnderline
                                            placeholder={this.TraslationFuncation("UserProfile.CompanyName")}
                                            onChange={(event) => this.companyNameChange(event)}
                                            autoComplete="off"
                                        />
                                        <Typography className="errorStyle">
                                            {this.state.saveProfileClicked && !this.state.companyName &&
                                                this.TraslationFuncation("UserProfile.ThisFieldIsRequired")}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Wrapper>
        )
    }

    editCompanyLink = () => {
        const title = this.state.selectedLanguage === "English" ? "Website" : "الاسم الأول";

        return (
            <Wrapper>
                <Grid container className="socialGridContainer" alignItems={"center"}>
                    <Grid item xs={3} sm={2} md={1.5} lg={1} className="socialImageGrid">
                        <img src={companyImage.default} height="48px" width="48px" />
                    </Grid>
                    <Grid item xs={2} sm={1.5} md={1} lg={0.6}>
                        <Typography className="socialTitle">{title}</Typography>
                    </Grid>
                    <Grid item xs={7} sm={8} md={9.5}>
                        <Typography className="socialDesc">
                            <Input
                                data-test-id="website"
                                className="enterInputField"
                                value={this.state.website}
                                disableUnderline
                                placeholder={title}
                                onChange={(event) => this.websiteChange(event)}
                                autoComplete="off"
                            />
                            {this.state.websiteError && (this.state.website || "").length > 0 && (
                                <Typography className="errorStyle">
                                    {this.TraslationFuncation("UserProfile.PleaseEnterAValidHTTPSUrl")}
                                </Typography>
                            )}
                        </Typography>
                    </Grid>
                </Grid>
                <Divider />
            </Wrapper>
        )
    }

    editLinkedinLink = () => {
        const title = this.state.selectedLanguage === "English" ? "Linkedin" : "الاسم الأول";

        return (
            <Wrapper>
                <Grid container className="socialGridContainer" alignItems={"center"}>
                    <Grid item xs={3} sm={2} md={1.5} lg={1} className="socialImageGrid">
                        <img src={linkedinImage.default} height="48px" width="48px" />
                    </Grid>
                    <Grid item xs={2} sm={1.5} md={1} lg={0.6}>
                        <Typography className="socialTitle">{title}</Typography>
                    </Grid>
                    <Grid item xs={7} sm={8} md={9.5}>
                        <Typography className="socialDesc">
                            <Input
                                data-test-id="linkedin"
                                className="enterInputField"
                                value={this.state.linkedin}
                                disableUnderline
                                placeholder={title}
                                onChange={(event) => this.linkedinChange(event)}
                                autoComplete="off"
                            />
                            {this.state.linkedInError && (this.state.linkedin || "").length > 0 && (
                                <Typography className="errorStyle">
                                    {
                                        this.TraslationFuncation("UserProfile.PleaseEnteraValidLinkedinUrl")
                                    }</Typography>
                            )}
                        </Typography>
                    </Grid>
                </Grid>
                <Divider />
            </Wrapper>
        )
    }

    editTwitterLink = () => {
        const title = this.state.selectedLanguage === "English" ? "Twitter" : "الاسم الأول";

        return (
            <Wrapper>
                <Grid container className="socialGridContainer" alignItems={"center"}>
                    <Grid item xs={3} sm={2} md={1.5} lg={1} className="socialImageGrid">
                        <img src={twitterImage.default} height="48px" width="48px" />
                    </Grid>
                    <Grid item xs={2} sm={1.5} md={1} lg={0.6}>
                        <Typography className="socialTitle">{title}</Typography>
                    </Grid>
                    <Grid item xs={7} sm={8} md={9.5}>
                        <Typography className="socialDesc">
                            <Input
                                data-test-id="twitter"
                                className="enterInputField"
                                value={this.state.twitter}
                                disableUnderline
                                placeholder={title}
                                onChange={(event) => this.twitterChange(event)}
                                autoComplete="off"
                            />
                            {this.state.twitterError && (this.state.twitter || "").length > 0 && (
                                <Typography className="errorStyle">
                                    {this.TraslationFuncation("UserProfile.PleaseEnterAValidTwitterUrl")}
                                </Typography>
                            )}
                        </Typography>
                    </Grid>
                </Grid>
                <Divider />
            </Wrapper>
        )
    }

    successUpdateProfileDialog = () => {
        return (
            <Dialog
                open={this.state.openSuccess}
                onClose={this.handleCloseSuccess}
                PaperProps={{
                    sx: {
                        direction: this.state.selectedLanguage.toLowerCase() === "english" ? "ltr" : "rtl",
                        overflow: 'hidden',
                        borderRadius: "8px 8px 32px 8px"
                    }
                }}
            >
                <DialogTitle>
                    <Wrapper>
                        <Typography className="successDialogTitle">
                            {this.TraslationFuncation("UserProfile.ProfileUpdated")}
                        </Typography>
                        <IconButton
                            data-test-id="closeSuccess"
                            aria-label="close"
                            onClick={this.handleCloseSuccess}
                            className="successDialogClose"
                            sx={{
                                right: this.state.selectedLanguage.toLowerCase() === "english" ? 30 : "auto",
                                left: this.state.selectedLanguage.toLowerCase() === "english" ? "auto" : 30
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Wrapper>
                </DialogTitle>
                <Divider sx={{ color: "#E2E8F0" }} />
                <DialogContent>
                    <Wrapper>
                        <Box className="successImageBox">
                            <img src={successImg.default} alt="Success Image" height={144} width={144} />
                        </Box>
                    </Wrapper>
                    <Typography
                        sx={{
                            fontFamily: "Urbanist",
                            padding: "16px 70px",
                            fontSize: "20px",
                            fontWeight: 700,
                            lineHeight: "28px",
                            color: "#0F172A"
                        }}>
                        {this.TraslationFuncation("UserProfile.YourProfileHasBeenSuccessfullyUpdated")}
                    </Typography>
                </DialogContent>
                <Divider />
                <DialogActions>
                    <Wrapper>
                        <Box className={`${this.state.selectedLanguage.toLowerCase() === "english" ? "successActionButtonsBox" : "successActionButtonsBoxArabic"}`}>
                            <Button
                                onClick={this.handleBtnDone}
                                variant="contained"
                                color="primary"
                                data-test-id="done_Success"
                                className={`${this.state.selectedLanguage.toLowerCase() === "english" ? "successAgreeButton" : "successAgreeButtonArabic"}`}
                            >
                                {this.TraslationFuncation("UserProfile.Done")}
                            </Button>
                        </Box>
                    </Wrapper>
                </DialogActions>
            </Dialog>
        )
    }
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <DashboardHeader navigation={this.props.navigation} id={this.props.id}>
                <MainWrapper style={{
                    direction: this.state.IsItEnglish ? "ltr" : "rtl",
                }} >
                    <Grid container justifyContent={"center"}>
                        <Grid item xs={11}>
                            <Box className="editProfileMainBox">
                                <Button
                                    className="editProfileButton"
                                    data-test-id="edit_profile_button"
                                    onClick={this.saveProfile}>
                                    <Typography className="editProfileText">
                                        {this.TraslationFuncation("UserProfile.SaveProfile")}
                                    </Typography>
                                </Button>
                            </Box>
                        </Grid>
                        <Grid item xs={10} className="profileDetailmargin">
                            {this.editProfileDetail()}
                        </Grid>
                        <Grid item xs={10} className="socialDetailmargin">
                            {this.editCompanyLink()}
                        </Grid>
                        <Grid item xs={10}>
                            {this.editLinkedinLink()}
                        </Grid>
                        <Grid item xs={10}>
                            {this.editTwitterLink()}
                        </Grid>
                        {this.successUpdateProfileDialog()}
                    </Grid>
                </MainWrapper>
            </DashboardHeader>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
export const EditUserProfileWeb = withTranslation()(EditUserProfile);
export const Wrapper = styled(Box)({
    width: "100%",
    "& .profilePhotoRight": {
        right: "15vw",
        position: "absolute"
    },
    "& .profilePhotoLeft": {
        top: "50%",
        position: "absolute"
    },
    "& .successDialogTitle": {
        fontFamily: "Urbanist",
        fontWeight: 700,
        fontSize: "24px",
        lineHeight: "32px",
        margin: "8px 16px",
        color: "#1E1E1E"
    },
    "& .successDialogClose": {
        position: 'absolute',
        top: 16,
        color: "#334155"
    },
    "& .successImageBox": {
        display: "flex",
        justifyContent: "center",
        padding: "10px 0px"
    },
    "& .profilePhotoBox": {
        height: "90px",
        borderRadius: "4px 4px 32px 4px",
        position: "relative",
        backgroundImage: `url(${boxImage.default})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        width: '100%',
        "@media(max-width:750px)": {
            height: "70px",
        },
        "@media(max-width:500px)": {
            height: "55px",
        }
    },
    "& .BoderRadiusForEnglish": {
        borderRadius: "4px 4px 32px 4px",
    },
    "& .BoderRadiusForArabic": {
        borderRadius: "4px 4px 4px 32px",
    },
    "& .successAgreeButton": {
        fontSize: "16px",
        fontWeight: 700,
        width: "120px",
        height: "56px",
        fontFamily: "Urbanist",
        padding: "16px 39.5px 16px 36.5px",
        gap: "8px",
        borderRadius: "8px",
        opacity: "0px",
        marginLeft: "16px",
        background: "#044352",
        textTransform: "none",
        "&:hover": {
            backgroundColor: "#044352"
        },
    },
    "& .successAgreeButtonArabic": {
        fontSize: "16px",
        fontWeight: 700,
        width: "120px",
        height: "56px",
        fontFamily: "Urbanist",
        padding: "16px 39.5px 16px 36.5px",
        gap: "8px",
        borderRadius: "8px",
        opacity: "0px",
        marginRight: "16px",
        background: "#044352",
        textTransform: "none",
        "&:hover": {
            backgroundColor: "#044352"
        },
    },
    "& .successActionButtonsBox": {
        paddingRight: "16px",
        margin: "16px 0px",
        display: "flex",
        justifyContent: "flex-end"
    },
    "& .successActionButtonsBoxArabic": {
        paddingLeft: "16px",
        margin: "16px 0px",
        display: "flex",
        justifyContent: "flex-end"
    },
    "& .profilePhoto": {
        maxWidth: "150px",
        maxHeight: "150px",
        minWidth: "80px",
        minHeight: "80px",
        height: "10vw",
        width: "10vw",
        borderRadius: "50%",
        paddingTop: "8px"
    },
    "& .profileImage": {
        marginLeft: "13%",
        maxWidth: "150px",
        maxHeight: "150px",
        minWidth: "80px",
        minHeight: "80px",
        height: "10vw",
        width: "10vw",
        background: "#111",
        borderRadius: "50%",
        opacity: "0px",
        zIndex: 10,
        position: "relative",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: "white",
        fontSize: "14px",
        fontWeight: "700",
        lineHeight: "22px",
        marginTop: "-50px",
        border: "4px solid white",
        "@media(max-width:750px)": {
            marginTop: "-40px",
        },
        "@media(max-width:500px)": {
            marginTop: "-30px",
        }
    },
    "& .profileDetailContainer": {
        backgroundColor: "#E2E8F0",
        borderRadius: "8px",
        paddingBottom: "80px",
    },
    "& .profileImageContainer": {
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    "& .socialGridContainer": {
        backgroundColor: "E2E8F0",
        padding: "16px 16px 16px 0px"
    },
    "& .socialImageGrid": {
        display: "flex",
        justifyContent: "center"
    },
    "& .socialImageGridMargin": {
        display: "flex",
        justifyContent: "center",
        marginTop: "10px"
    },
    "& .socialTitle": {
        fontFamily: "Urbanist",
        fontSize: "14px",
        fontWeight: 400,
        lineWeight: "22px",
        color: "#64748B",
        display: "inline-block",
    },
    "& .editPhotoText": {
        fontFamily: "Inter !important",
        fontSize: "12px",
        fontWeight: 700,
        lineHeight: "22px",
        marginTop: "6px",
        textAlign: "center"
    },
    "& .editPhotoTextPosition": {
        position: "relative"
    },
    "& .birthDateField": {
        border: "1px solid rgba(203, 213, 225, 1)",
        background: "white",
        borderRadius: '8px',
        height: '48px',
        width: '100%',
    },
    "& .socialDesc": {
        paddingLeft: "10px",
        fontFamily: "Urbanist",
        fontSize: "18px",
        fontWeight: 700,
        lineWeight: "26px",
        color: "#334155",
        wordBreak: "break-word",
        display: "inline-block",
        width: "50%",
        "@media(max-width:900px)": {
            paddingLeft: "0px",
            width: "60%",
        },
        "@media(max-width:600px)": {
            paddingLeft: "0px",
            width: "70%",
        },
    },
    "& .enterInputField": {
        fontFamily: 'Urbanist',
        border: "1px solid rgba(203, 213, 225, 1)",
        fontSie: '16px',
        background: "white",
        fontWeight: 400,
        lineHight: "24px",
        borderRadius: '8px',
        height: '50px',
        padding: '12px 16px',
        width: '100%',
        color: "rgba(15, 23, 42, 1)"
    },
    "& .personalInfoText": {
        marginTop: "22px",
        fontFamily: "Urbanist",
        fontSize: "18px",
        fontWeight: 700,
        lineHeight: "26px",
        color: "rgba(100, 116, 139, 1)"
    },
    "& .editUserInputTitle": {
        fontFamily: "Urbanist",
        paddingBottom: "2px",
        fontSize: "14px",
        fontWeight: 700,
        lineHeight: "22px",
        color: "rgba(100, 116, 139, 1)"
    },
    "& .imgDropdownMenuForEnglish":{
        right: "-170px",
        top: "75px",
    },
    "& .imgDropdownMenuForArabic":{
        right: "150px",
        top: "75px",
    },
    "& .imgDropdownMenu": {
        position: "absolute",
        display: "flex",
        flexDirection: "column",
        background: "white",
        border: "1px solid #ccc",
        borderRadius: "8px",
        zIndex: 1000,
        maxWidth: "192px",
        width: "120%",
        minWidth: "152px",
        "@media(max-width:1300px)": {
            right: "-150px",
            top: "70px",
            minWidth: "156px",
        },
        "@media(max-width:1200px)": {
            right: "-145px",
            top: "60px",
            minWidth: "156px",
        },
        "@media(max-width:600px)": {
            right: "-140px",
            top: "60px",
            minWidth: "156px",
        },
    },
    "& .profilePhotoErrorEnglish":{
        right: "-312px",
    },
    "& .profilePhotoErrorArabic":{
        right: "170px",
    },
    "& .profilePhotoError": {
        color: "#F30202",
        fontSize: "12px",
        fontStyle: "italic",
        fontWeight: "400",
        lineheight: "24px",
        top: "76px",
        position: "absolute",
        marginTop: "4px",
        display: "flex",
        flexDirection: "column",
        maxWidth: "300px",
        width: "200%",
        "@media(max-width:1300px)": {
            right: "-280px",
            top: "50px",
        },
        "@media(max-width:1200px)": {
            right: "-250px",
            top: "50px",
        },
        "@media(max-width:1050px)": {
            right: "-230px",
            top: "50px",
        },
        "@media(max-width:950px)": {
            right: "-200px",
            top: "50px",
        },
        "@media(max-width:850px)": {
            right: "-170px",
            top: "50px",
        }
    },
    "& .dropdownItemUpload": {
        display: "flex",
        alignItems: "center",
        "@media(min-width:1500px)": {
            marginLeft: "-28px",
        },
    },
    "& .dropdownItem": {
        display: "flex",
        justifyContent: "flex-start",
        padding: "4px 16px",
        cursor: "pointer",
        fontFamily: "Urbanist",
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "22px",
        color: "#0F172A",
        borderRadius: "8px",
        margin: "4px",
        textTransform: "none",
        "&:hover": {
            backgroundColor: "#E2E8F0"
        },
    },
    "& .viewProfileImgStyle": {
        height: "18px",
        width: "18px",
        margin: "2px 5px"
    },
    "& .errorStyle": {
        color: "#F30202",
        fontSize: "0.85rem",
        marginBottom: "0.5rem",
    },
})
export const MainWrapper = styled(Box)({
    display: "flex",
    justifyContent: "center",
    width: "100%",
    marginTop: "31px",
    paddingBottom: "30px",
    "& .socialDetailmargin": {
        marginTop: "38px"
    },
    "& .profileDetailmargin": {
        marginTop: "40px"
    },
    "& .editProfileMainBox": {
        display: "flex",
        justifyContent: "flex-end"
    },
    "& .editProfileButton": {
        height: "58px",
        borderRadius: "4px",
        opacity: "0px",
        width: "40%",
        background: "var(--Brand, #044352)",
        maxWidth: "218px",
        "&:hover": {
            backgroundColor: "#044352",
        },
    },
    "& .editProfileText": {
        fontWeight: 700,
        fontFamily: "Urbanist",
        textAlign: "center",
        fontSize: "16px",
        lineHeight: "24px",
        color: "#FFFFFF",
        textTransform: "none"
    },
})
// Customizable Area End