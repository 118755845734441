    // Customizable Area Start
    import { Message } from "framework/src/Message";
    import { BlockComponent } from "framework/src/BlockComponent";
    import { runEngine } from "framework/src/RunEngine";
    import MessageEnum, {
        getName
    } from "framework/src/Messages/MessageEnum";

    import React, { ReactNode } from "react";
    import { getStorageData, removeStorageData } from "framework/src/Utilities";
    import * as Yup from "yup";
    import { ShowCompanyData } from "./Cfchatgptintegration9Controller";
    import { i18n } from "i18next";


    

    interface Skill {
        id: string; // Note: id is a string in the JSON
        type: string;
        attributes: SkillAttributes;
    }

    interface SkillAttributes {
        id: number;
        skill_name: string;
    }

    interface SkillData {
        data: Skill[];
    }

    interface KeySkillsData {
        data: KeySkill[];
    }

    

    interface JobFamily {
        id: number;
        name: string;
    }

    interface JobLevel {
        id: number;
        name: string;
    }

    interface JobExperience {
        id: number;
        name: string;
    }

    interface ChatGPTResponse {
        job_experience: string; position: string;
        department: string; job_level: string;
        job_summary: string; key_skills: string[];
        keywords: string[]; key_responsibilities: string[];
    }

    interface JobDescriptionAttributes {
        id: number; created_at: string;
        job_title: string; job_experience: JobExperience;
        department: string; job_summary: string;
        chatgpt_response: ChatGPTResponse;
        key_words: string[]; key_skills: KeySkillsData;
        job_level: JobLevel; job_family: JobFamily;
    }

    interface JobDescriptionData {
        id: string; type: string;
        attributes: JobDescriptionAttributes;
    }

    

    interface KeySkill {
        id: string; type: string;
        attributes: KeySkillAttributes;
    }
    interface KeySkillAttributes {
        id: number; skill_name: string;
    }

    interface KeySkillsData {
        data: KeySkill[];
    }

    interface JobLevel {
        id: number; name: string;
    }

    interface JobFamily {
        id: number; name: string;
    }

    interface JobExperience {
        id: number; name: string;
    }

    interface ChatGPTResponse {
        job_summary: string;
        job_level: string;
        job_experience: string; key_responsibilities: string[];
        position: string;
        department: string; key_skills: string[];
        keywords: string[];
    }

    interface JobDescriptionAttributes {
        department: string; job_summary: string;
        id: number; created_at: string;
        job_title: string; job_family: JobFamily;
        key_words: string[]; key_skills: KeySkillsData;
        job_level: JobLevel;
        job_experience: JobExperience;
        chatgpt_response: ChatGPTResponse;
    }

    interface JobDescriptionData {
        id: string;
        type: string; attributes: JobDescriptionAttributes;
    }

    interface JobDescriptionFormApiResponse {
        data: JobDescriptionData;
        meta: { message: string; };
    }

    interface UpdatedJobDescription {
        data: {
            id: string;
            type: string;
            attributes: UpdatedJobDescriptionAttributes;
        };
    }

    interface UpdatedJobDescriptionAttributes {
        id: number;
        created_at: string;
        generated_job_title: string; generated_department: string;
        generated_job_level: string; generated_job_experience: string;
        generated_job_summary: string;
        generated_responsibilities: string[]; generated_requirements: string[];
        generated_key_skills: string[]; generated_keywords: string[];
        job_level: UpdatedJobDescriptionLevel; job_family: UpdatedJobDescriptionFamily;
        job_experience: UpdatedJobDescriptionExperience; key_skills: UpdatedJobDescriptionKeySkill[];
    }

    interface UpdatedJobDescriptionKeySkill {
        id: number;
        name: string;
    }
    interface UpdatedJobDescriptionExperience {
        id: number;
        name: string;
    }

    

    interface UpdatedJobDescriptionFamily {
        id: number;
        name: string;
    }

    interface UpdatedJobDescriptionLevel {
        id: number;
        name: string;
    }

    


    // Customizable Area End

    export const configJSON = require("./config");
    export interface Props {
        navigation: any;
        id: string;
        // Customizable Area Start
        children?: ReactNode;
        selectedMenu?: string;
        i18n?: i18n;
        // Customizable Area End
    }

    interface S {
        // Customizable Area Start
        token: string;
        profileId: string;
        errorMsg: string;
        selectedLanguage: string;
        FormEnableReinitialize: boolean;
        JobTitle: string,
        RenderJobTitle: string,
        DepartmentTeam: string,
        JobLevel: string,
        JobLevelName: string,
        JobExperience: string,
        JobExperienceName: string,
        JobFamily: string,
        JobSummary: string,
        KeySkills: Array<string>,
        KeyWords: Array<string>,
        jobLevel: { id: number, name: string }[];
        jdstyle: { id: number, name: string }[];
        jobFamily: { id: number, name: string }[];
        keySkills: { id: number, name: string }[];
        keyWords: { keywords: string }[];
        loader: boolean;
        TitleJobDescription: string;
        FormSubmitButtonText: string;
        active: number;
        progressScreen: boolean;
        progress: number;
        ProgressModel: boolean,
        FormSubmit: boolean,
        jobdescResponse: string;
        SuccessModelTitleText: string;
        IsItEditForm: boolean;

        EditJobId: string;
        EditJobFamily: string;
        EditJobTitle: string;
        EditDepartment: string;
        EditKeySkills: string[];
        EditKeyKeywords: string[];
        EditJobResponsibilities: string[];
        EditJobRequirements: string[];
        EditJobSummary: string;
        // Customizable Area End

    }

    interface SS {
        // Customizable Area Start
        id: any;
        // Customizable Area End
    }

    export default class EditJobDescriptionController extends BlockComponent<
        Props,
        S,
        SS
    > {

        // Customizable Area Start
        mediaQueryList: MediaQueryList | null = null;
        getJobDescLevelApiCallId: string = "";
        getJobDescExpApiCallId: string = "";
        getJobDescKeySkillApiCallId: string = "";
        getJobDescKeyWordApiCallId: string = "";
        getJobDescJobFamilyApiCallId: string = "";
        getCompanyApiCallId: string = "";
        EditJobDescApiCallId: string = "";
        GetJobDescrptionDataApiCallId: string = "";

        intervalEdit: number | undefined;
        intervalsEdit: number | undefined;
        // Customizable Area End

        constructor(props: Props) {
            super(props);
            this.receive = this.receive.bind(this);
            // Customizable Area Start
            this.subScribedMessages = [
                getName(MessageEnum.RestAPIResponceMessage),
                getName(MessageEnum.SessionResponseMessage),
                getName(MessageEnum.CountryCodeMessage)
            ];

            this.state = {
                token: "",
                profileId: "",
                errorMsg: "",
                selectedLanguage: "English",
                JobTitle: "",
                RenderJobTitle: "",
                DepartmentTeam: "",
                FormEnableReinitialize: false,
                JobLevel: "",
                JobLevelName: "",
                JobExperience: "",
                JobExperienceName: "",
                JobFamily: "",
                JobSummary: "",
                KeySkills: [],
                KeyWords: [],
                jobLevel: [],
                jdstyle: [],
                jobFamily: [],
                keySkills: [],
                keyWords: [],
                loader: false,
                TitleJobDescription: "",
                FormSubmitButtonText: "",
                active: 0,
                progressScreen: false,
                progress: 0,
                ProgressModel: false,
                FormSubmit: false,
                jobdescResponse: "",
                SuccessModelTitleText: "",
                IsItEditForm: false,

                EditJobId: "",
                EditJobFamily: "",
                EditJobTitle: "",
                EditDepartment: "",
                EditKeySkills: [],
                EditKeyKeywords: [],
                EditJobResponsibilities: [],
                EditJobRequirements: [],
                EditJobSummary: "",
            };

            // Customizable Area End
            runEngine.attachBuildingBlock(this, this.subScribedMessages);
        }

        async receive(from: string, message: Message) {
            // Customizable Area Start
            runEngine.debugLog("Message Recived", message);
            if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
                const apiRequestCallId = message.getData(
                    getName(MessageEnum.RestAPIResponceDataMessage)
                );

                const responseJson = message.getData(
                    getName(MessageEnum.RestAPIResponceSuccessMessage)
                );
                // Get the data for the response
                this.SetJobDescriptionApiResponse(apiRequestCallId, responseJson);

                this.companyApiResponse(apiRequestCallId, responseJson);
                this.JobLevelApiCall(apiRequestCallId, responseJson);
                this.KeyWordApiCallResponseSet(apiRequestCallId, responseJson);
                this.JobFamilyGetApiCallResponseSet(apiRequestCallId, responseJson);
                this.JobExprinceApiCallResponseSet(apiRequestCallId, responseJson);
                this.KeySkillsApiCallResponseSet(apiRequestCallId, responseJson);
                this.FormApiCallSetResponse(apiRequestCallId, responseJson);
            }
            // Customizable Area End
        }

        // Customizable Area Start

        KeyWordApiCallResponseSet = (ApiCallId: string, response: {
            keywords: string[];
        }) => {
            if (ApiCallId === this.getJobDescKeyWordApiCallId) {
                const keyWordsData = response?.keywords?.map((data: any) => ({
                    keywords: data,
                }));
                this.setState({ keyWords: keyWordsData });
            }
        }

        

        JobLevelApiCall = (apiRequestCallId: string, responseJson: Array<{
            id: number;
            name: string;
            created_at: string;
            updated_at: string;
        }>) => {
            if (apiRequestCallId === this.getJobDescLevelApiCallId) {
                this.setState({ jobLevel: responseJson })
            }
        }

        JobFamilyGetApiCallResponseSet = (apiRequestCallId: string, responseJson: Array<{
            id: number;
            name: string;
            created_at: string;
            updated_at: string;
        }>) => {
            if (apiRequestCallId === this.getJobDescJobFamilyApiCallId) {
                this.setState({ jobFamily: responseJson })
            }
        }

        

        KeySkillsApiCallResponseSet = (ApiCallId: string, response: SkillData) => {
            if (ApiCallId === this.getJobDescKeySkillApiCallId) {
                const keySkillData = response.data.map((data) => ({
                    id: data.attributes.id,
                    name: data.attributes.skill_name,
                }));
                this.setState({ keySkills: keySkillData });
            }
        }

        JobExprinceApiCallResponseSet = (apiRequestCallId: string, responseJson: Array<{
            id: number;
            name: string;
            created_at: string;
            updated_at: string;
        }>) => {
            if (apiRequestCallId === this.getJobDescExpApiCallId) {
                this.setState({ jdstyle: responseJson })
            }
        }

        async componentDidMount() {
            const tokenMeta = await getStorageData('signInResponse', true);
            this.setState({
                token: tokenMeta?.meta?.token
            })
            const language = await getStorageData("language") || "English";

            this.setState({ selectedLanguage: language });
            this.jobDescriptionJobFamilyGetCall()
            this.jobDescriptionKeySkillGetCall()
            this.GetCompanyApiCall();
            this.jobDescriptionGetCall()
            this.jobDescriptionExpGetCall()
            this.jobDescriptionKeyWordGetCall()

            this.GetJobDescriptionDataApiCallFuncation()
        }

        TraslationFuncation = (key: string) => {
            if (this.props.i18n) {
                return this.props.i18n.t(key);
            } else {
                return key
            }
        }

        ConditionForLanguage = (TrueCondition: string, FalseCondition: string) => {
            if (this.state.selectedLanguage.toLowerCase() === "english") {
                return TrueCondition
            } else {
                return FalseCondition
            }
        }

        NavigateToAnyPage = (pageName: string) => {
            const navigateMsg = new Message(getName(MessageEnum.NavigationMessage));
            navigateMsg.addData(getName(MessageEnum.NavigationTargetMessage), pageName);
            navigateMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
            this.send(navigateMsg);
        }

        renderjobLevel = (selected: number) => {
            const selectedjobLevel = this.state.jobLevel?.find((option) => option.id === selected);
            return selectedjobLevel ? selectedjobLevel.name : (
                <div className="dropDownStyleplaceHolder"
                >  {this.TraslationFuncation("JobDescriptionPage.SpecifyYourTitle")}
                </div>
            );
        }

        
        

        GetCompanyApiCall = () => {
            const headers = {
                "token": this.state.token
            };

            const GetCompanyApigetMsg = new Message(
                getName(MessageEnum.RestAPIRequestMessage)
            );

            this.getCompanyApiCallId = GetCompanyApigetMsg.messageId;

            GetCompanyApigetMsg.addData(
                getName(MessageEnum.RestAPIResponceEndPointMessage),
                `${configJSON.showCompanyApiEndPoint}`
            );

            GetCompanyApigetMsg.addData(
                getName(MessageEnum.RestAPIRequestHeaderMessage),
                JSON.stringify(headers)
            );

            GetCompanyApigetMsg.addData(
                getName(MessageEnum.RestAPIRequestMethodMessage),
                "GET"
            );

            runEngine.sendMessage(GetCompanyApigetMsg.id, GetCompanyApigetMsg);
        }

        jobDescriptionExpGetCall = () => {

            const headers = {
                "token": this.state.token,
                "Content-Type": configJSON.validationApiContentType,
            };
            const ExportrequestMessageExp = new Message(
                getName(MessageEnum.RestAPIRequestMessage)
            );
            this.getJobDescExpApiCallId = ExportrequestMessageExp.messageId;
            ExportrequestMessageExp.addData(
                getName(MessageEnum.RestAPIResponceEndPointMessage),
                `${configJSON.getJobDescExpApiEndPoint}`
            );
            ExportrequestMessageExp.addData(
                getName(MessageEnum.RestAPIRequestHeaderMessage),
                JSON.stringify(headers)
            );
            ExportrequestMessageExp.addData(
                getName(MessageEnum.RestAPIRequestMethodMessage),
                configJSON.validationApiMethodType
            );
            runEngine.sendMessage(ExportrequestMessageExp.id, ExportrequestMessageExp);
        }

        jobDescriptionGetCall = () => {

            const headers = {
                "token": this.state.token,
                "Content-Type": configJSON.validationApiContentType,
            };
            const JobDescLevelrequestMessage = new Message(
                getName(MessageEnum.RestAPIRequestMessage)
            );
            this.getJobDescLevelApiCallId = JobDescLevelrequestMessage.messageId;
            JobDescLevelrequestMessage.addData(
                getName(MessageEnum.RestAPIResponceEndPointMessage),
                configJSON.getJobDescLevelApiEndPoint
            );
            JobDescLevelrequestMessage.addData(
                getName(MessageEnum.RestAPIRequestHeaderMessage),
                JSON.stringify(headers)
            );
            JobDescLevelrequestMessage.addData(
                getName(MessageEnum.RestAPIRequestMethodMessage),
                configJSON.validationApiMethodType
            );
            runEngine.sendMessage(JobDescLevelrequestMessage.id, JobDescLevelrequestMessage);
        }

        jobDescriptionKeySkillGetCall = () => {

            const headers = {
                "token": this.state.token,
                "Content-Type": configJSON.validationApiContentType,
            };
            const KeySklisRequestMessage = new Message(
                getName(MessageEnum.RestAPIRequestMessage)
            );
            this.getJobDescKeySkillApiCallId = KeySklisRequestMessage.messageId;
            KeySklisRequestMessage.addData(
                getName(MessageEnum.RestAPIResponceEndPointMessage),
                `${configJSON.getJobDescKeySkillApiEndPoint}`
            );
            KeySklisRequestMessage.addData(
                getName(MessageEnum.RestAPIRequestHeaderMessage),
                JSON.stringify(headers)
            );
            KeySklisRequestMessage.addData(
                getName(MessageEnum.RestAPIRequestMethodMessage),
                configJSON.validationApiMethodType
            );
            runEngine.sendMessage(KeySklisRequestMessage.id, KeySklisRequestMessage);
        }

        jobDescriptionKeyWordGetCall = () => {

            const headers = {
                "token": this.state.token,
                "Content-Type": configJSON.validationApiContentType,
            };
            const KeyWordsrequestMessage = new Message(
                getName(MessageEnum.RestAPIRequestMessage)
            );
            this.getJobDescKeyWordApiCallId = KeyWordsrequestMessage.messageId;
            KeyWordsrequestMessage.addData(
                getName(MessageEnum.RestAPIResponceEndPointMessage),
                `${configJSON.getJobDescKeyWordApiEndPoint}`
            );
            KeyWordsrequestMessage.addData(
                getName(MessageEnum.RestAPIRequestHeaderMessage),
                JSON.stringify(headers)
            );
            KeyWordsrequestMessage.addData(
                getName(MessageEnum.RestAPIRequestMethodMessage),
                configJSON.validationApiMethodType
            );
            runEngine.sendMessage(KeyWordsrequestMessage.id, KeyWordsrequestMessage);
        }

        

        

        companyApiResponse = (ApiCallId: string, responseJson: ShowCompanyData) => {
            if (ApiCallId === this.getCompanyApiCallId) {
                if (responseJson.message === "No associated company found for the current user") {
                    this.NavigateToAnyPage("cfchatgptintegration9")
                }
            }
        }

        jobDescriptionJobFamilyGetCall = () => {

            const headers = {
                "token": this.state.token,
                "Content-Type": configJSON.validationApiContentType,
            };
            const JobFamilyrequestMessage = new Message(
                getName(MessageEnum.RestAPIRequestMessage)
            );
            this.getJobDescJobFamilyApiCallId = JobFamilyrequestMessage.messageId;
            JobFamilyrequestMessage.addData(
                getName(MessageEnum.RestAPIResponceEndPointMessage),
                `${configJSON.getJobDescJobFamilyAPiEndPoint}`
            );
            JobFamilyrequestMessage.addData(
                getName(MessageEnum.RestAPIRequestHeaderMessage),
                JSON.stringify(headers)
            );
            JobFamilyrequestMessage.addData(
                getName(MessageEnum.RestAPIRequestMethodMessage),
                configJSON.validationApiMethodType
            );
            runEngine.sendMessage(JobFamilyrequestMessage.id, JobFamilyrequestMessage);
        }

        GetJobDescriptionDataApiCallFuncation = async () => {
                this.setState({
                    loader: true,
                    TitleJobDescription: this.TraslationFuncation("JobDescriptionPage.EditJobDescriptionSubText"),
                    FormSubmitButtonText: this.TraslationFuncation("JobDescriptionPage.UpdateJobDescription"),
                    FormSubmit: false,
                })
                this.GetJobDescrptionApiCallFuncation()
        }

        GetJobDescrptionApiCallFuncation = async () => {

            const headers = {
                "token": this.state.token,
                "Content-Type": configJSON.validationApiContentType,
            };
            const requestMessage = new Message(
                getName(MessageEnum.RestAPIRequestMessage)
            );
            this.GetJobDescrptionDataApiCallId = requestMessage.messageId;
            const ViewJobId = await getStorageData('ViewJobId')
            requestMessage.addData(
                getName(MessageEnum.RestAPIResponceEndPointMessage),
                `${configJSON.getJobDescDetailsAPiEndPoint}/${ViewJobId}`
            );
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestHeaderMessage),
                JSON.stringify(headers)
            );
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestMethodMessage),
                configJSON.validationApiMethodType
            );
            runEngine.sendMessage(requestMessage.id, requestMessage);
        }

        BackToJobDescription = () => {
            this.NavigateToAnyPage("Cfchatgptintegration9")
            removeStorageData("ViewJobId");
        }

        SetJobDescriptionApiResponse = (ApiCallId: string, responseJson: UpdatedJobDescription) => {
            if (ApiCallId === this.GetJobDescrptionDataApiCallId) {
                this.setState({
                    EditJobId: responseJson.data.id,
                    JobTitle: responseJson.data.attributes.generated_job_title,
                    RenderJobTitle: responseJson.data.attributes.generated_job_title,
                    DepartmentTeam: responseJson.data.attributes.generated_department,
                    JobLevel: responseJson.data.attributes.job_level.id.toString(),
                    JobLevelName: responseJson.data.attributes.job_level.name,
                    JobExperience: responseJson.data.attributes.job_experience.id.toString(),
                    JobExperienceName: responseJson.data.attributes.job_experience.name,
                    JobFamily: responseJson.data.attributes.job_family.id.toString(),
                    JobSummary: responseJson.data.attributes.generated_job_summary,
                    EditJobSummary: responseJson.data.attributes.generated_job_summary,
                    KeySkills: responseJson.data.attributes.key_skills.map((item) => `${item.id}`),
                    KeyWords: responseJson.data.attributes.generated_keywords,
                    loader: false,
                    IsItEditForm: true,
                    EditJobFamily: responseJson.data.attributes.job_family.id.toString(),
                    EditJobTitle: responseJson.data.attributes.generated_job_title,
                    EditDepartment: responseJson.data.attributes.generated_department,

                    EditKeySkills: responseJson.data.attributes.generated_key_skills,

                    EditKeyKeywords: responseJson.data.attributes.generated_keywords,
                    EditJobResponsibilities: responseJson.data.attributes.generated_responsibilities,

                    EditJobRequirements: responseJson.data.attributes.generated_requirements,
                })
                this.FormEnableReinitializeFuncation();
            }
        }

        FormEnableReinitializeFuncation = () => {
            this.setState({
                FormEnableReinitialize: true,
            }, () => {
                this.setState({
                    FormEnableReinitialize: false,
                })
            })
        }

        

        EditJObDesFormApiCallFun = async (data: {
            EditJobId: string;
            EditJobFamily: string;
            EditJobTitle: string;
            EditDepartment: string;
            EditKeySkills: string[];
            EditKeyKeywords: string[];
            EditJobResponsibilities: string[];
            EditJobRequirements: string[];
            EditJobSummary: string;
        }) => {
            this.setState({
                FormSubmit: true,
                progressScreen: true,
            })
            const ViewJobId = await getStorageData('ViewJobId')
            
            let Body = {
                job_description: {
                    job_family_id: data.EditJobFamily,
                    job_experience_id: this.state.JobExperience,
                    job_level_id: this.state.JobLevel,
                    key_skill_ids: this.state.KeySkills,
                    generated_job_title: data.EditJobTitle,
                    generated_department: data.EditDepartment,
                    generated_job_level: this.state.JobLevelName,
                    generated_job_experience: this.state.JobExperienceName,
                    generated_job_summary: data.EditJobSummary,
                    generated_responsibilities: data.EditJobResponsibilities,
                    generated_requirements: data.EditJobRequirements,
                    generated_key_skills: data.EditKeySkills,
                    generated_keywords: data.EditKeyKeywords
                }
            }

            const headers = {
                "token": this.state.token,
                "Content-Type": configJSON.validationApiContentType,
            };
            const requestMessage = new Message(
                getName(MessageEnum.RestAPIRequestMessage)
            );
            this.EditJobDescApiCallId = requestMessage.messageId;
            requestMessage.addData(
                getName(MessageEnum.RestAPIResponceEndPointMessage),
                `${configJSON.createJobDescApiEndPoint}/${ViewJobId}`
            );
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestHeaderMessage),
                JSON.stringify(headers)
            );
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                JSON.stringify(Body)
            )
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestMethodMessage),
                configJSON.PUT
            );
            runEngine.sendMessage(requestMessage.id, requestMessage);
        }
        

        FormApiCallSetResponse = (ApiCallId: string, _responseJson: JobDescriptionFormApiResponse) => {
            if (ApiCallId === this.EditJobDescApiCallId) {
                this.setState({
                    ProgressModel: true,
                    SuccessModelTitleText: this.TraslationFuncation("JobDescriptionPage.JobDescriptionUpdate"),
                    progress: 100
                })
            }
        }

        

        async componentWillUnmount() {
            if (this.intervalEdit) {
                clearInterval(this.intervalEdit);
                this.intervalEdit = undefined;
            }
            if (this.intervalsEdit) {
                clearInterval(this.intervalsEdit);
                this.intervalsEdit = undefined;
            }
        }

        async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined) {
            if (prevState.progressScreen !== this.state.progressScreen) {
                if (this.state.progressScreen) {
                    this.intervalEdit = setInterval(() => {
                        if (this.state.active === 6) {
                            this.setState({ active: 0 })
                        } else
                            this.setState({ active: this.state.active + 1 })
                    }, 300) as unknown as number


                    this.intervalsEdit = setInterval(() => {
                        if (this.state.progress >= 80) {
                            clearInterval(this.intervalEdit);
                            clearInterval(this.intervalsEdit);
                            this.setState({ progress: 100 });
                            this.setState({ ProgressModel: true });
                        }
                        this.setState({ progress: this.state.progress + 1 })
                    }, 100) as unknown as number
                }
            }
        }

        ViewJobDescription = () => {
            this.NavigateToAnyPage("ViewJobDescription")
        }

        CloseSuccessModel = () => {
            removeStorageData("ViewJobId");
            this.NavigateToAnyPage("Cfchatgptintegration9")
            this.setState({
                ProgressModel: false,
                progress: 0,
                loader: true,
            })
        }

        



        TrueOrFalseConditionPass = (Condition: boolean, TrueCondition: string, FalseCondition: string) => {
            if (Condition) {
                return TrueCondition
            } else {
                return FalseCondition
            }
        }

        EditJobFormSchema = () => {
            return Yup.object().shape({
                EditJobId: Yup.string().required(this.TraslationFuncation("RequiredField")),
                EditJobFamily: Yup.string().required(this.TraslationFuncation("RequiredField")),
                EditJobTitle: Yup.string().required(this.TraslationFuncation("RequiredField")),
                EditDepartment: Yup.string().required(this.TraslationFuncation("RequiredField")),

                EditKeySkills: Yup.array().min(1, this.TraslationFuncation("JobDescriptionPage.AtLeastOne")).of(Yup.string().required(this.TraslationFuncation("RequiredField")).max(1000, this.TraslationFuncation("JobDescriptionPage.Maximum1000Characters"))),

                EditKeyKeywords: Yup.array().min(1, this.TraslationFuncation("JobDescriptionPage.AtLeastOne")).of(Yup.string().required(this.TraslationFuncation("RequiredField")).max(1000, this.TraslationFuncation("JobDescriptionPage.Maximum1000Characters"))),
                EditJobResponsibilities: Yup.array().min(1, this.TraslationFuncation("JobDescriptionPage.AtLeastOne")).of(Yup.string().required(this.TraslationFuncation("RequiredField")).max(1000, this.TraslationFuncation("JobDescriptionPage.Maximum1000Characters"))),

                EditJobRequirements: Yup.array().min(1, this.TraslationFuncation("JobDescriptionPage.AtLeastOne")).of(Yup.string().required(this.TraslationFuncation("RequiredField")).max(1000, this.TraslationFuncation("JobDescriptionPage.Maximum1000Characters"))),

                EditJobSummary: Yup.string().required(this.TraslationFuncation("RequiredField")),
            });
        }

        EditMultipleOnChange = (
            e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
            setFieldValue: (field: string, value: string, shouldValidate?: boolean) => void,
            index: number,
            KeyName: string,
            CurrentValue: string[]
        ) => {
            setFieldValue(`${KeyName}.${index}`, e.target.value)
            let data = [...CurrentValue]
            data[index] = e.target.value
            this.setState((prevState) => ({
                ...prevState,
                [KeyName]: data
            }))
        }

        AddOneString = (KeyName: string, CurrentValue: string[], setFieldValue: (field: string, value: string | string[], shouldValidate?: boolean) => void) => {
            let data = [...CurrentValue]
            data.push("")
            this.setState((prevState) => ({
                ...prevState,
                [KeyName]: data
            }))
            if (setFieldValue) {
                setFieldValue(`${KeyName}`, data)
            }
        }

        RemoveOneString = (KeyName: string, CurrentValue: string[], index: number, setFieldValue: (field: string, value: string | string[], shouldValidate?: boolean) => void) => {
            let data = [...CurrentValue]
            data.splice(index, 1)
            this.setState((prevState) => ({
                ...prevState,
                [KeyName]: data
            }))
            if (setFieldValue) {
                setFieldValue(`${KeyName}`, data)
            }
        }

        // Customizable Area End

    }