// Customizable Area Start
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";

import React, { ReactNode } from "react";
import { i18n } from "i18next";
import { setStorageData, getStorageData } from "../../../../packages/framework/src/Utilities";


interface Assessment {
    id: string;
    type: string;
    attributes: AssessmentAttributes;
}

interface AssessmentAttributes {
    id: number;
    title: string;
    time_allocation: number;
    objective: string;
    number_of_questions: number;
    competencies: Competency[];
    creator: Creator;
    difficulty_levels: DifficultyLevel[];
    focus_areas: FocusArea[];
    question_formats: QuestionFormat[];
    job_description: JobDescription;
    questions: Question[];
    status: string;
}

interface Competency {
    id: number;
    job_title: string;
    description: string;
    competency_name: string;
    job_description_id: number | null;
    created_at: string;
    updated_at: string;
    competency_type_id: number;
    status: boolean;
    competency_dictionary_id: number | null;
}

interface Creator {
    id: number;
    name: string;
}

interface DifficultyLevel {
    id: number;
    name: string;
}

interface FocusArea {
    id: number;
    name: string;
}

interface QuestionFormat {
    id: number;
    name: string;
}

interface JobDescription {
    id: number;
    job_title: string;
}

interface Question {
    data: QuestionData;
}

interface QuestionData {
    id: string;
    type: string;
    attributes: QuestionAttributes;
}

interface QuestionAttributes {
    id: number;
    text: string;
    question_format_name: string;
    question_bank_id: number | null;
    assessment_id: number;
    created_at: string;
    job_description: JobDescription | null;
    competency: Competency | null;
    difficulty_level: DifficultyLevel;
    answer: Answer;
}

interface Answer {
    id: number;
    answer_type: string;
    choices?: Choice[];
    correct_choices?: Choice[];
    text_response?: string;
}

interface Choice {
    id: number;
    option_text: string;
    correct: boolean;
}

// Main data structure
interface AssessmentDataResponse {
    data: Assessment[];
    meta: {
        pagination: {
            current_page: number,
            next_page: number,
            per_page: number,
            prev_page: number,
            total_count: number,
            total_pages: number
        },
        pending_assessments_count: number;
        total_assessments_count: number;
    },
    download_url: string;
}
// Customizable Area End

export const configJSON = require("./config");
export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    children?: ReactNode;
    selectedMenu?: string;
    i18n?: i18n;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    token: string;
    selectedLanguage: string;
    calendarOpen: boolean,
    selectedDate: null | [Date, Date];
    tempDate: null | [Date, Date];
    AssessmentsTableData: Array<{
        ID: string;
        AssessmentTitle: string;
        JobRole: string;
        Competency: string;
        DifficultyLevels: string;
        QuestionsCount: number;
        FocusAreas: string;
        Status: string;
    }>;
    TotalAssessments: number;
    isLoading: boolean;
    searchText: string;
    anchorRef: HTMLElement | null;
    OpenIndex: number;
    PendingAssessments: number;
    page: number;
    perPage: number;
    PaginationPageCount: number;
    TotalRecord: number;
    CurrentFilterStatus: string;
    FilterAssessmentDropDown: boolean;
    status: string;
    DeleteQuestionModel: boolean;
    AssessmentId: string
    // Customizable Area End

}

interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class AssessmentTestDashbordController extends BlockComponent<
    Props,
    S,
    SS
> {

    // Customizable Area Start
    GetAllAssessementApiCallId: string = "";
    DeleteQuestionApiId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.CountryCodeMessage)
        ];

        this.state = {
            token: '',
            selectedLanguage: '',
            calendarOpen: false,
            selectedDate: null,
            tempDate: null,
            AssessmentsTableData: [],
            TotalAssessments: 0,
            isLoading: false,
            anchorRef: null,
            OpenIndex: -1,
            PendingAssessments: 0,
            searchText: '',
            page: 1,
            perPage: 10,
            PaginationPageCount: 0,
            TotalRecord: 0,
            FilterAssessmentDropDown: false,
            CurrentFilterStatus: "All Assessments",
            status: "",
            DeleteQuestionModel: false,
            AssessmentId: "",
        };

        // Customizable Area End
        runEngine.attachBuildingBlock(this, this.subScribedMessages);
    } 
    async receive(from: string, message: Message) {
        // Customizable Area Start
        runEngine.debugLog("Message Recived", message);
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            this.GetJobDescrptionApiResponse(apiRequestCallId, responseJson);
            this.DeleteQuestionApiResponse(apiRequestCallId, responseJson);
            // Get the data for the response

        }
        // Customizable Area End
    }

    // Customizable Area Start



    async componentDidMount() {
        const tokenMeta = await getStorageData('signInResponse', true);
        this.setState({
            token: tokenMeta?.meta?.token
        })
        const language = await getStorageData("language") || "English";
        this.setState({ selectedLanguage: language });
        this.AllSearchAndFilterApiCall(false)
    }

    NavigateToAnyPage = (pageName: string) => {
        const navigateMsg = new Message(getName(MessageEnum.NavigationMessage));
        navigateMsg.addData(getName(MessageEnum.NavigationTargetMessage), pageName);
        navigateMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(navigateMsg);
    }

    TraslationFuncation = (key: string) => {
        if (this.props.i18n) {
            return this.props.i18n.t(key);
        } else {
            return key
        }
    }



    handleCondition = (condition: boolean, truePart: React.ReactNode, falsePart: React.ReactNode) => {
        return condition ? truePart : falsePart
    }

    formatDateRange = (dateRange: [Date, Date] | null): string => {
        if (Array.isArray(dateRange) && dateRange.length === 2) {
            const [startDate, endDate] = dateRange;
            return `${startDate.toLocaleDateString()} - ${endDate.toLocaleDateString()}`;
        }
        return this.TraslationFuncation("CompetencyDashboard.SelectStartDateEndDate");
    };

    GetJobDescrptionApiResponse = (APiCallId: string, responseJson: AssessmentDataResponse) => {
        if (APiCallId === this.GetAllAssessementApiCallId) {
            if (responseJson.download_url) {
                this.setState({
                    isLoading: false
                })
                const link = document.createElement('a');
                link.href = responseJson.download_url;
                link.download = 'export.csv';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            } else {
                this.ListDataSet(responseJson)
            }
        }
    }

    ListDataSet = (responseJson: AssessmentDataResponse) => {
        if (responseJson.data.length > 0) {
            const AssessmentsTableData = responseJson.data.map((item, index) => {
                return {
                    ID: `${item.attributes.id}`,
                    AssessmentTitle: item.attributes.title,
                    JobRole: item.attributes.job_description.job_title,
                    Competency: item.attributes.competencies.map((competency) => {
                        return competency.competency_name
                    }).join(", "),
                    DifficultyLevels: item.attributes.difficulty_levels.map((difficulty) => {
                        return difficulty.name
                    }).join(", "),
                    QuestionsCount: item.attributes.number_of_questions,
                    FocusAreas: item.attributes.focus_areas.map((focus) => {
                        return focus.name
                    }).join(", "),
                    Status: item.attributes.status,
                }
            })
            this.setState({
                AssessmentsTableData: AssessmentsTableData,
                isLoading: false,
                TotalAssessments: responseJson.meta.total_assessments_count,
                PendingAssessments: responseJson.meta.pending_assessments_count,
                TotalRecord: responseJson.meta.pagination.total_count,
            })
        } else {
            this.setState({
                isLoading: false,
                status: "",
                AssessmentsTableData: [],
                FilterAssessmentDropDown: false,
                CurrentFilterStatus: "All Assessments",
            })
        }
    }

    NaviGateToAnyPage = (PageRoute: string) => {
        const navigateMsg = new Message(getName(MessageEnum.NavigationMessage));
        navigateMsg.addData(getName(MessageEnum.NavigationTargetMessage), PageRoute);
        navigateMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(navigateMsg);
    }

    NaviGateToEditAndViewPage = (PageName: string, item: { ID: string }) => {
        this.NaviGateToAnyPage(PageName)
        setStorageData("assessmentId", item.ID)
    }

    handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>): void => {
        if (event.key === 'Enter') {
            this.AllSearchAndFilterApiCall()
        }
    };

    formatToYYYYMMDD = (date: Date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    AllSearchAndFilterApiCall = (exportAll?: boolean) => {
        this.setState({ isLoading: true })
        const headers = {
            "token": this.state.token
        };

        let queryParam = "";

        if (this.state.searchText) {
            queryParam += `query=${this.state.searchText}&`
        }

        if (this.state.selectedDate) {
            queryParam += `start_date=${this.formatToYYYYMMDD(this.state.selectedDate[0])}&`;
            queryParam += `end_date=${this.formatToYYYYMMDD(this.state.selectedDate[1])}&`;
        }
        if (exportAll) {
            queryParam += `export=${exportAll}&`
        }
        if (this.state.status) {
            queryParam += `status=${this.state.status}&`
        }

        const competencyMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.GetAllAssessementApiCallId = competencyMsg.messageId;
        competencyMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        competencyMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.GetAllAssessment}?${queryParam}&page=${this.state.page}&per_page=${this.state.perPage}`
        );
        competencyMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "GET"
        );
        runEngine.sendMessage(competencyMsg.id, competencyMsg);
    }

    openCalendar = () => {
        this.setState((prevState) => ({ calendarOpen: !prevState.calendarOpen }));
    };

    handleDateChange = (newDate: [Date, Date]) => {
        if (newDate) {
            this.setState({ tempDate: newDate });
        }
    };

    handleCalendarCancelBtn = () => {
        this.setState({ tempDate: null, selectedDate: null, calendarOpen: false, page: 1 },
            () => { this.AllSearchAndFilterApiCall() }
        );
    };

    handleCalendarSaveBtn = () => {
        if (this.state.tempDate) {
            this.setState({
                selectedDate: this.state.tempDate,
                tempDate: null,
                calendarOpen: false,
                page: 1
            }, () => { this.AllSearchAndFilterApiCall() });
        }
    };

    PaginationFuncation = (_event: React.ChangeEvent<unknown>, page: number) => {
        this.setState({
            page: page
        }, () => {
            this.AllSearchAndFilterApiCall()
        })
    }

    RenderAllTabs = () => {
        let data = [
            {
                value: "",
                name: "All Assessments",
            },
            {
                value: "created",
                name: "Created Assessments",
            },
            {
                value: "pending",
                name: "Pending Assessments",
            }
        ]
        return data
    }

    SetFilterApiCall = (value: {
        value: string;
        name: string
    }) => {
        this.setState({
            status: value.value,
            FilterAssessmentDropDown: false,
            CurrentFilterStatus: value.name,
        }, () => {
            this.AllSearchAndFilterApiCall()
        })
    }

    CloseDeleteModel = () => {
        this.setState({ DeleteQuestionModel: false, AssessmentId: "" })
    }

    DeleteQuestionApiCall = async () => {
        this.setState({ isLoading: true });
        const headers = {
            "token": this.state.token
        };
        const DeleteAssessment = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.DeleteQuestionApiId = DeleteAssessment.messageId;
        DeleteAssessment.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        DeleteAssessment.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            "/bx_block_assessmenttest/assessments/" + this.state.AssessmentId
        );
        DeleteAssessment.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "DELETE"
        );
        runEngine.sendMessage(DeleteAssessment.id, DeleteAssessment);
    }

    DeleteQuestionApiResponse = (ApiCallId: string, Response: { message: string, error:string }) => {
        if (ApiCallId === this.DeleteQuestionApiId) {
            if (Response.message === "Assessment deleted successfully") {
                this.setState({ isLoading: false });
                this.setState({ DeleteQuestionModel: false, AssessmentId: "" });
                this.AllSearchAndFilterApiCall();
            } else {
                this.setState({ isLoading: false,DeleteQuestionModel: false, AssessmentId: "" });
            }
        }
    }

    // Customizable Area End

}