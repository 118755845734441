import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import React, { ReactNode } from "react";
import { getStorageData, removeStorageData, setStorageData } from "framework/src/Utilities";

interface UserProfile {
  error?: string;
  errors: Array<{
    token: string;
  }>,
  data?: {
    id: string;
    type: string;
    attributes: {
      id: string;
      company_name: string;
      approval_state: string,
      "website": string;
      "linkedin": string;
      "twitter": string;
      "country": string;
      "account_id": number;
      "photo": string;
      "full_name": string;
      "date_of_birth": string;
      "email": string;
    }
  }
}

interface LanguageChange {
  message: string;
  error: string;
  language: string;
}
// Customizable Area End

export const configJSON = require("./config");
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  children?: ReactNode;
  SearchFuncation?: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  CallSearchFuncation?: () => void;
  selectedMenu?: string;
  IsMenuHidden?: boolean;
  i18n?: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  profileId: string;
  errorMsg: string;
  userProfileData: UserProfile;
  sideMenuVisible: boolean;
  isMdUp: boolean;
  languageDropdown: boolean;
  profileDropdown: boolean;
  selectedLanguage: string;
  Role: string;
  SeachText: string;
  Isloading: boolean
  // Customizable Area End

}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class DashboardHeaderController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  mediaQueryList: MediaQueryList | null = null;
  showProfileApiCallId: string = "";
  ChangeLandApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];

    this.state = {
      token: "",
      profileId: "",
      errorMsg: "",
      userProfileData: {} as UserProfile,
      sideMenuVisible: false,
      isMdUp: false,
      languageDropdown: false,
      profileDropdown: false,
      selectedLanguage: "English",
      Role: "",
      SeachText: "",
      Isloading: false
    };

    // Customizable Area End
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId === this.showProfileApiCallId) {
        this.handleProfileApiResponse(responseJson)
      }
      if (apiRequestCallId === this.ChangeLandApiCallId) {
        this.handleLangChangeApiResponse(responseJson)
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();

    const language = await getStorageData("language") || "English";
    this.setState({ selectedLanguage: language });

    this.mediaQueryList = window.matchMedia('(min-width:900px)');

    this.handleMediaQueryChange(this.mediaQueryList);
    if (this.mediaQueryList.addEventListener) {
      this.mediaQueryList.addEventListener('change', this.handleMediaQueryChange);
    }
    if (this.props.IsMenuHidden){
      this.toggleSideMenu()
    }

    const signInResponse = await getStorageData("signInResponse");
    const parsedSignInResponse = JSON.parse(signInResponse)
    this.setState({
      token: parsedSignInResponse.meta?.token,
      profileId: parsedSignInResponse.meta?.profile_id,
      Role: parsedSignInResponse.meta.user_role
    })
    this.GetProfileApiCall()
  }

  async componentWillUnmount() {
    if (this.mediaQueryList?.removeEventListener) {
      this.mediaQueryList.removeEventListener('change', this.handleMediaQueryChange);
    }
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): void {
    if(this.props.IsMenuHidden !== prevProps.IsMenuHidden) {
      this.toggleSideMenu()
    }
  }

  handleProfileApiResponse = async (responseJson: UserProfile) => {
    if (responseJson && !responseJson.error) {
      if (responseJson.data?.attributes.approval_state !== "approved") {
        this.setState({
          Isloading:true
        })
        setTimeout(() => {
          Object.keys(localStorage).map((item) => item).forEach((key)=>{
            if (key !=="i18nextLng" && key !== "language") {
              removeStorageData(key)
            }
          })
        }, 100);
        setTimeout(() => {
          window.location.reload()
        }, 100);
      }
      this.setState({ userProfileData: responseJson, Isloading: false })
    } else if (responseJson && responseJson.error) {
      this.setState({
        errorMsg: responseJson.error
      });
    }
    if (Array.isArray(responseJson.errors)) {
      if (responseJson.errors[0].token === "Token has Expired") {
        this.NavigateToAnyPage("EmailAccountLoginBlock")
        removeStorageData("signInResponse")
      }
    }
  }

  handleMediaQueryChange = (event: MediaQueryList | MediaQueryListEvent) => {
    this.setState({ isMdUp: event.matches }, () => {
      if (!this.state.isMdUp) {
        this.setState({ sideMenuVisible: false });
      } else {
        this.setState({ sideMenuVisible: true });
      }
    });
  };

  toggleSideMenu = () => {
    this.setState({ sideMenuVisible: !this.state.sideMenuVisible })
  }

  toggleLanguageDropdown = () => {
    this.setState((prevState) => ({
      languageDropdown: !prevState.languageDropdown,
    }));
  };

  ChangeLang = (language: string) => {
    const selectedLanguage = language || this.state.selectedLanguage;

    const headers = {
      "token": this.state.token
    };
    this.setState({
      Isloading: true
    })
    const getUserStatusMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.ChangeLandApiCallId = getUserStatusMsg.messageId;
    getUserStatusMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getUserStatusMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.langChangeApiEndPoint}?language=${selectedLanguage === "English" ? "en" : "ar"}`
    );
    getUserStatusMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.patchApiMethodType
    );
    runEngine.sendMessage(getUserStatusMsg.id, getUserStatusMsg);
  }

  handleLangChangeApiResponse = (responseJson: LanguageChange) => {
    if (responseJson && !responseJson.error) {
      this.setState({
        Isloading: false
      })
      setStorageData("language", responseJson.language === "en" ? "English" : "عربي");
      window.location.reload();
    } else if (responseJson && responseJson.error) {
      this.setState({
        errorMsg: responseJson.error,
        Isloading: false
      });
    }
  }
  closeDropdown = () => {
    this.setState({ languageDropdown: false });
  };

  toggleProfileDropdown = () => {
    this.setState((prevState) => ({
      profileDropdown: !prevState.profileDropdown,
    }));
  };

  closeProfileDropdown = () => {
    this.setState({ profileDropdown: false });
  };

  languageChange = (language: string) => {
    this.ChangeLang(language)
  };

  navigateToUserProfile = () => {
    const navigateMsg = new Message(getName(MessageEnum.NavigationMessage));
    navigateMsg.addData(getName(MessageEnum.NavigationTargetMessage), "UserProfileBasic");
    navigateMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(navigateMsg);
  }

  navigateToSetting = () => {
    const navigateMsg = new Message(getName(MessageEnum.NavigationMessage));
    navigateMsg.addData(getName(MessageEnum.NavigationTargetMessage), "Settings2");
    navigateMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(navigateMsg);
  }

  NavigateToAnyPage = (pageName: string) => {
    const navigateMsg = new Message(getName(MessageEnum.NavigationMessage));
    navigateMsg.addData(getName(MessageEnum.NavigationTargetMessage), pageName);
    navigateMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(navigateMsg);
  }

  IsHrAdmin = () => {
    return this.state.Role === "hr_admin"
  }

  GetProfileApiCall = () => {
    const headersForApi = {
      "Content-Type": "application/json",
      "token": this.state.token
    };
    const MsgData = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.showProfileApiCallId = MsgData.messageId;
    MsgData.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.showProfileApiEndPoint}/${this.state.profileId}`
    );
    MsgData.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headersForApi)
    );
    MsgData.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );
    runEngine.sendMessage(MsgData.id, MsgData);
  }

  // Customizable Area End

}