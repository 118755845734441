import React from "react";
// Customizable Area Start
import {
    Box,
    Grid,
    styled,
    Typography,
    Card,
    CardContent,
    Button,
    Input,
    Dialog,
    DialogTitle,
    Divider,
    DialogContent,
    DialogActions,
    IconButton
} from "@mui/material";
import { DashboardHeaderWeb as DashboardHeader } from "../../dashboard/src/DashboardHeader.web";
import CloseIcon from '@mui/icons-material/Close';
import { backArrow, addOtherImg, successImg } from "./assets";
// Customizable Area End

import EditCriticalPositionController, {
    Props,
} from "./EditCriticalPositionController";

export default class EditCriticalPosition extends EditCriticalPositionController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    successUpdateDialog = () => {
        return (
            <Dialog
                open={this.state.openSuccess}
                onClose={this.handleCloseSuccess}
                PaperProps={{
                    sx: {
                        direction: this.state.selectedLanguage.toLowerCase() === "english" ? "ltr" : "rtl",
                        borderRadius: "8px 8px 32px 8px",
                        overflow: 'hidden',
                    }
                }}
            >
                <DialogTitle>
                    <Wrapper>
                        <Typography className="successUupdateDialogTitle">
                            Critical Position Updated
                        </Typography>
                        <IconButton
                            aria-label="close"
                            data-test-id="close_success"
                            onClick={this.handleCloseSuccess}
                            className="successDialogClose"
                            sx={{
                                right: this.state.selectedLanguage.toLowerCase() === "english" ? 30 : "auto",
                                left: this.state.selectedLanguage.toLowerCase() === "english" ? "auto" : 30
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Wrapper>
                </DialogTitle>
                <Divider sx={{ color: "#E2E8F0" }} />
                <DialogContent>
                    <Wrapper>
                        <Box className="successImageBox">
                            <img src={successImg.default} alt="Success Image" height={144} width={144} />
                        </Box>
                    </Wrapper>
                    <Typography
                        sx={{
                            fontSize: "20px",
                            fontWeight: 700,
                            fontFamily: "Urbanist",
                            padding: "14px 34px",
                            lineHeight: "28px",
                            color: "#0F172A",
                            textAlign: "center"
                        }}>
                        The Critical Position Updated Successfully!
                    </Typography>
                    <Typography
                        sx={{
                            fontWeight: 400,
                            lineHeight: "26px",
                            fontFamily: "Urbanist",
                            padding: "0px 40px",
                            fontSize: "18px",
                            color: "#0F172A",
                            textAlign: "center"
                        }}>
                        The critical position has been updated successfully. You can View and Edit it further.
                    </Typography>

                </DialogContent>
                <Divider />
                <DialogActions>
                    <Wrapper>
                        <Box className={`${this.state.selectedLanguage.toLowerCase() === "english" ? "viewDetailBox" : "viewDetailBoxArabic"}`}>
                            <Button
                                color="primary"
                                variant="contained"
                                data-test-id="navigate_to_view"
                                onClick={this.navigateToViewUserPosition}
                                className={`${this.state.selectedLanguage.toLowerCase() === "english" ? "viewButton" : "viewButtonArabic"}`}
                            >
                                View
                            </Button>
                        </Box>
                    </Wrapper>
                </DialogActions>
            </Dialog>
        )
    }

    JobTitleRender = () => {
        return (
            <Wrapper>
                <Grid container>
                    <Grid item xs={1.5} sm={1} md={0.8} lg={0.7}>
                        <img src={backArrow.default}
                            alt="Back Arrow"
                            className={`${this.state.selectedLanguage.toLowerCase() === "english" ? "mainArrowrrowImg" : "mainBackArrowImgAr"}`}
                            data-test-id="back_arrow"
                            onClick={this.backToDashBoard}
                        />
                    </Grid>
                    <Grid item xs={10} sm={11} md={11.2} lg={11.3}>
                        <Grid container>
                            <Grid item xs={12}>
                                <Typography
                                    className={`${this.state.selectedLanguage.toLowerCase() === "english" ? "jobTitle" : "jobTitleAr"}`}
                                >
                                    Jobtitle
                                </Typography>
                            </Grid>
                            <Grid item xs={12} style={{ display: "flex", justifyContent: "space-between", flexWrap: "wrap", width: "100%" }}>
                                <Grid container spacing={2} justifyContent={"space-between"}>
                                    <Grid item xs={12} sm={8}>
                                        <Box className="jobtitleDropDown">
                                            <Typography className="jobTitleTypo">
                                                {this.state.jobTitle}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <Box className="saveChangesMainBox">
                                            <Button className="saveChangesButton" data-test-id="save_changes" onClick={this.editCriticalPositionApiCall}>
                                                <Typography className="saveChangesText">
                                                    Save Changes
                                                </Typography>
                                            </Button>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sx={{ margin: "24px 0px 0px 12px" }}>
                                <Button className="rankButton">
                                    <Typography className="rankText">
                                        Critical Rank
                                        <span style={{ color: "#059669", marginLeft: "5px" }}>{this.state.rank}</span>
                                        <span style={{ color: "#059669", marginLeft: "36px" }}>{this.state.totalScore}</span>
                                    </Typography>
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Wrapper>
        )
    }

    editPositionHeader = () => {
        return (
            <Wrapper>
                <Card sx={{ borderRadius: 2, boxShadow: "rgba(0, 0, 0, 0.1) 0px 14px 144px 0px" }}>
                    <CardContent>
                        <Grid container alignItems="center" justifyContent="space-between">
                            <Grid item xs={12}>
                                {this.JobTitleRender()}
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Wrapper>
        )
    }

    startagicParameter = (type: string, index: number) => {
        return (
            <Wrapper>
                <Box className="strategicMainBox">

                    <Grid container>
                        <Grid item xs={12}>
                            <Typography className="strategicTypo">
                                {index + 1}. Parameter Name*
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Input
                                data-test-id={`strategic-param-name-${type}`}
                                className="subParamInputField"
                                disableUnderline
                                value={this.state.strategicParams[type]?.name || type}
                                onChange={(e) => this.handleParameterNameChange(type, e.target.value)}
                                autoComplete="off"
                            />
                        </Grid>
                        {this.state.duplicateStrategicParam[type].map((item, index) => {
                            return (
                                <Grid item xs={4} key={index} style={{ padding: "0px 8px" }}>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <Typography className="subparamTypo">
                                                Sub-parameters {index + 1}
                                            </Typography>
                                            <Input
                                                data-test-id={`strategic-sub_param-${type}-${index}`}
                                                className="subParamInputField"
                                                disableUnderline
                                                placeholder="Strategic Impact"
                                                value={this.state.duplicateStrategicParam[type][index]?.strategicSubParam}
                                                onChange={(e) => this.handleInputChange(type, index, "strategicSubParam", e.target.value)}
                                                autoComplete="off"
                                            />
                                        </Grid>

                                        <Grid item xs={12}>
                                            <Typography className="subparamTypo">
                                                Score Out Of
                                            </Typography>
                                            <Input
                                                data-test-id={`strategic-score-outof-${type}-${index}`}
                                                className="subParamInputField"
                                                disableUnderline
                                                placeholder="Enter the score value"
                                                value={this.state.duplicateStrategicParam[type][index]?.strategicScoreOutOf}
                                                onChange={(e) => this.handleInputChange(type, index, "strategicScoreOutOf", e.target.value)}
                                                autoComplete="off"
                                            />
                                        </Grid>

                                        <Grid item xs={12}>
                                            <Typography className="subparamTypo">
                                                Score Generated
                                            </Typography>
                                            <Input
                                                data-test-id={`strategic-score-generated-${type}-${index}`}
                                                className="subParamInputField"
                                                disableUnderline
                                                placeholder="Strategic score generated"
                                                value={this.state.duplicateStrategicParam[type][index]?.strategicScoreGenerated}
                                                onChange={(e) => this.handleInputChange(type, index, "strategicScoreGenerated", e.target.value)}
                                                autoComplete="off"
                                            />
                                        </Grid>
                                        {this.state.duplicateStrategicParam[type][index]?.error && (
                                            <Typography className="error-message" sx={{ color: "red", fontSize: "12px" }}>
                                                {this.state.duplicateStrategicParam[type][index].error}
                                            </Typography>
                                        )}
                                    </Grid>
                                </Grid>);
                        })}

                        <Grid item xs={12}>
                            <Box sx={{ marginTop: "32px" }}>
                                <Button
                                    data-test-id={`add-sub-param-btn-${type}`}
                                    onClick={() => this.addStrategicSubParam(type)}
                                >
                                    <img src={addOtherImg.default} />
                                    <Typography className="addOtherBtn">
                                        Add Sub-parameter
                                    </Typography>
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Wrapper>
        )
    }

    stratagicImpactHeader = () => {
        return (
            <Wrapper sx={{ marginTop: "30px", backgroundColor: "white" }}>
                {Object.keys(this.state.duplicateStrategicParam).map((type, index) => {
                    return (
                        <Card sx={{ borderRadius: 2 }} key={index}>
                            <CardContent>
                                <Grid container alignItems="center" justifyContent="space-between">

                                    <Grid item xs={12} key={index}>
                                        {this.startagicParameter(type, index)}
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>)
                })}
            </Wrapper>
        )
    }
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <DashboardHeader selectedMenu="Critical position" navigation={this.props.navigation} id={this.props.id}>
                <MainWrapper>
                    <Grid container justifyContent={"center"} spacing={3}>
                        <Grid item xs={11}>
                            {this.editPositionHeader()}
                        </Grid>
                        <Grid item xs={11}>
                            {this.stratagicImpactHeader()}
                        </Grid>
                    </Grid>
                    {this.successUpdateDialog()}
                </MainWrapper>
            </DashboardHeader>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
export const MainWrapper = styled(Box)({
    marginTop: "42px",
    paddingBottom: "30px",
    display: "flex",
    justifyContent: "center",
    width: "100%",
})
export const Wrapper = styled(Box)({
    width: "100%",
    "& .cardDetailPadding": {
        padding: "30px 56px 30px 35px"
    },
    "& .mainArrowrrowImg": {
        paddingRight: "20px"
    },
    "& .mainBackArrowImgAr": {
        transform: "rotate(180deg)"
    },
    "& .viewButtonArabic": {
        ontSize: "16px",
        fontWeight: 700,
        width: "120px",
        height: "56px",
        gap: "8px",
        borderRadius: "8px",
        opacity: "0px",
        ffontFamily: "Urbanist",
        padding: "16px 39.5px 16px 36.5px",
        marginRight: "16px",
        background: "#044352",
        textTransform: "none",
        "&:hover": {
            backgroundColor: "#044352"
        },
    },
    "& .successImageBox": {
        display: "flex",        
        justifyContent: "center",
        padding: "16px 0px"
    },
    "& .viewButton": {
        height: "56px",
        fontWeight: 700,
        width: "30%",        
        fontFamily: "Urbanist",
        fontSize: "16px",
        padding: "16px 24px",
        gap: "8px",
        borderRadius: "8px",        
        marginLeft: "16px",
        background: "#044352",
        textTransform: "none",
        opacity: "0px",
        "&:hover": {
            backgroundColor: "#044352"
        },
    },
    "& .successUupdateDialogTitle": {
        lineHeight: "32px",
        color: "#1E1E1E",
        fontSize: "24px",        
        fontFamily: "Urbanist",
        fontWeight: 700,
        margin: "8px 16px"
    },
    "& .strategicTypo": {
        color: "#344054",
        fontFamily: "Urbanist",
        fontWeight: 700,
        fontSize: "14px",
        lineHeight: "22px",
        marginBottom: "13px"
    },
    "& .viewDetailBox": {
        paddingRight: "16px",
        margin: "16px 0px",        
        display: "flex",
        justifyContent: "flex-end"
    },    
    "& .successDialogClose": {
        position: 'absolute',
        top: 16,
        color: "#334155"
    },
    "& .viewDetailBoxArabic": {        
        justifyContent: "flex-end",
        display: "flex",
        paddingLeft: "16px",
        margin: "16px 0px"
    },
    "& .subparamTypo": {
        color: "#344054",
        fontFamily: "Urbanist",
        fontWeight: 700,
        fontSize: "14px",
        lineHeight: "22px",
        marginTop: "20px"
    },
    "& .subParamInputField": {
        lineHight: "24px",
        textAlign: "left",
        borderRadius: '8px',
        backgroundColor: "#FFFFFF",
        height: '50px',
        fontSie: '16px',
        fontWeight: 400,
        fontFamily: 'Urbanist',
        border: '1px solid lightgray',
        padding: '12px 16px',
        width: '100%',
        marginTop: "12px"
    },
    "& .parameterBox": {
        backgroundColor: "#FFFFFF",
        borderRadius: "8px",
        border: "1px solid #CBD5E1",
        padding: "20px 12px"
    },
    "& .parameterName": {
        color: "#0F172A",
        fontFamily: "Urbanist",
        fontWeight: 700,
        fontSize: "20px",
        lineHeight: "28px"
    },
    "& .strategicMainBox": {
        border: "2px solid #ECECEC",
        borderRadius: "5px",
        padding: "24px 22px",
        backgroundColor: "#ECECEC"
    },
    "& .jobTitleAr": {
        fontFamily: "Urbanist",
        lineHeight: "30px",
        color: "#000104",
        fontSize: "16px",
        fontWeight: 700,
        marginRight: "12px",
        "@media (max-width: 360px)": {
            marginRight: "30px",
        }
    },
    "& .jobTitle": {
        fontFamily: "Urbanist",
        fontSize: "16px",
        fontWeight: 700,
        marginLeft: "12px",
        lineHeight: "30px",
        color: "#000104",
        "@media (max-width: 360px)": {
            marginLeft: "30px",
        }
    },
    "& .dropDownplaceHolder": {
        fontSize: 16,
        color: "#DCDCDC",
        fontWeight: 400
    } as React.CSSProperties,
    "& .cardContentPadding": {
        padding: "0px 0px 42px 0px"
    },
    "& .contentPadding": {
        padding: "30px 56px 0px 35px"
    },
    "& .jobtitleDropDown": {
        fontFamily: 'Urbanist',
        border: '1px solid lightgray',
        fontSie: '16px',
        fontWeight: 400,
        lineHight: "24px",
        borderRadius: '8px',
        padding: '12px 16px',
        width: '80%',
        margin: "12px 0px 10px 12px",
    },
    "& .jobTitleTypo": {
        fontFamily: "Urbanist",
        fontWeight: 400,
        fontSize: "16px",
        lineHeight: "24px",
        color: "#94A3B8"
    },
    "& .saveChangesMainBox": {
        display: "flex",
        justifyContent: "flex-end",
        padding: "0px 12px 0px 12px",
        width: "90%",
        "@media(max-width:600px)": {
            justifyContent: "flex-start",
        }
    },
    "& .addOtherBtn": {
        lineHeight: "22px",
        textTransform: "none",
        fontFamily: "Urbanist",
        fontSize: "16px",
        fontWeight: 700,
        color: "#044352",
        paddingLeft: "12px",
    },
    "& .saveChangesButton": {
        height: "58px",
        padding: "10px 32px",
        borderRadius: "4px",
        border: "1px solid #94A3B8",
        background: "#044352",
    },
    "& .saveChangesText": {
        fontFamily: "Urbanist",
        fontSize: "16px",
        fontWeight: 700,
        lineHeight: "24px",
        color: "#FFFFFF",
        textTransform: "none"
    },
    "& .rankButton": {
        height: "52px",
        borderRadius: "8px",
        padding: "16px 10px",
        backgroundColor: "#F1F5F9",
    },
    "& .rankText": {
        fontFamily: "Urbanist",
        fontWeight: 700,
        fontSize: "16px",
        lineHeight: "24px",
        color: "#0F172A",
        padding: "0px 10px",
        textTransform: "none"
    }
})
// Customizable Area End