import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { SelectChangeEvent } from '@mui/material/Select';
import { i18n } from "i18next";
import { setStorageData, getStorageData } from "framework/src/Utilities";

interface CompetencyResponse {
    message: string;
    error: string;
    competencies: Competencies;
}

interface Competencies {
    data?: {
        id: string;
        type: string;
        attributes: {
            id: string;
            competency_type_id: number;
            competency_type: string;
            competency_name: string;
        };
    }[];
}
interface QuestionBankResponse {
    message: string;
    error: string;
    question_bank: QuestionBankData;
}
interface QuestionBankData {
    data?: {
        id: string;
        type: string;
    }
}

interface JobDescription {
    data: {
      id: string;
      type: "job_description";
      attributes: {
        id: number;
        created_at: string;
        generated_job_title: string;
        generated_department: string;
        generated_job_level: string;
        generated_job_experience: string;
        generated_job_summary: string;
        generated_responsibilities: string[];
        generated_requirements: string[];
        generated_key_skills: string[];
        generated_keywords: string[];
        job_level: {
          id: number;
          name: string;
        };
        job_family: {
          id: number;
          name: string;
        };
        job_experience: {
          id: number;
          name: string;
        };
        key_skills: {
          id: number;
          name: string;
        }[];
      };
    }[];
    meta: {
      total_pages: number;
      current_page: number;
      total_records: number;
      prev_page: number | null;
      next_page: number | null;
    };
  }

  interface CompetenciesResponse {
    message: string;
    competencies: {
      id: number;
      job_title: string;
      description: string;
      competency_name: string;
      job_description_id: number;
      created_at: string;
      updated_at: string;
      competency_type_id: number | null;
      status: boolean;
      competency_dictionary_id: number;
    }[];
  }
  
  

// Customizable Area End

export const configJSON = require("./config");
export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    i18n?: i18n;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    token: string;
    errorMsg: string;
    competencies: Competencies;
    selectedCompetencies: string[];
    generateBtnClicked: boolean;
    assessmentObjectives: string;
    questionType: {
        id: string,
        name: string
    }[];
    selectedQuestionType: string[];
    questionFormat: {
        id: string,
        name: string
    }[];
    selectedQuestionFormat: string[];
    difficultyLevel: {
        id: string,
        name: string
    }[];
    selectedDifficultyLevel: string[];
    focusAreas: {
        id: string,
        name: string
    }[];
    selectedFocusArea: string[];
    noOfQuestions: string;
    exportProgress: number;
    active: number;
    progrssbarScreen: boolean;
    openSuccessDialog: boolean;
    selectedLanguage: string;
    noOfQueErrorMsg: string;
    questionBankData: QuestionBankData;
    createQuestionMessage: string;
    JobTitleDropDown: Array<string>,
    JobTitleDropDownData: {
        id: string;
        name: string;
    }[],
    NewCompetenciesDropDownData:{
        id: string;
        competency_name: string;
    }[]
    // Customizable Area End

}

interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class QuestionBankCreationController extends BlockComponent<
    Props,
    S,
    SS
> {

    // Customizable Area Start
    interval: number | undefined;
    getCompetencyApiCallId: string = "";
    createQuestionsApiCallId: string = "";
    GetJobTitleApiCallId: string = "";
    GetCompetenciesApiCallId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.CountryCodeMessage)
        ];

        this.state = {
            token: "",
            errorMsg: "",
            competencies: {},
            selectedCompetencies: [],
            generateBtnClicked: false,
            assessmentObjectives: "",
            questionType: [{ id: "1", name: this.TraslationFuncation("questionBankCreationPage.Technical")
            }, { id: "2", name: this.TraslationFuncation("questionBankCreationPage.Functional") }],
            selectedQuestionType: [],
            questionFormat: [{ id: "1", name: this.TraslationFuncation("questionBankCreationPage.multipleChoice") }, { id: "3", name: this.TraslationFuncation("questionBankCreationPage.ScenarioBased") }, { id: "2", name: this.TraslationFuncation("questionBankCreationPage.Mixed") }],
            selectedQuestionFormat: [],
            difficultyLevel: [{ id: "1", name: this.TraslationFuncation("questionBankCreationPage.Basic") }, { id: "2", name: this.TraslationFuncation("questionBankCreationPage.Intermediate") }, { id: "3", name: this.TraslationFuncation("questionBankCreationPage.Advanced") }],
            selectedDifficultyLevel: [],
            focusAreas: [{ id: "1", name: this.TraslationFuncation("questionBankCreationPage.KeyCompetencies") }, { id: "2", name: this.TraslationFuncation("questionBankCreationPage.CriticalSkills") }],
            selectedFocusArea: [],
            noOfQuestions: "",
            exportProgress: 0,
            active: 0,
            progrssbarScreen: false,
            openSuccessDialog: false,
            selectedLanguage: "",
            noOfQueErrorMsg: "",
            questionBankData: {},
            createQuestionMessage: "",
            JobTitleDropDownData: [],
            JobTitleDropDown: [],
            NewCompetenciesDropDownData:[]
        };

        // Customizable Area End
        runEngine.attachBuildingBlock(this, this.subScribedMessages);
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (apiRequestCallId === this.getCompetencyApiCallId) {
                this.competenciesApiResponse(responseJson)
            }
            if (apiRequestCallId === this.createQuestionsApiCallId) {
                this.createQuestionsApiResponse(responseJson)
            }
            this.GetJobTitleApiResponse(apiRequestCallId, responseJson)
            this.GetCompetenciesResponse(apiRequestCallId, responseJson)
        }
        // Customizable Area End
    }

    // Customizable Area Start
    async componentDidMount() {
        super.componentDidMount();

        const language = await getStorageData("language") || "English";
        this.setState({ selectedLanguage: language });

        const signInResponse = await getStorageData("signInResponse");
        const parsedSignInResponse = await JSON.parse(signInResponse)
        this.setState({ token: parsedSignInResponse.meta?.token })

        this.getCompetenciesApiCall();
        this.GetJobTitle();
        this.updateTranslations();
    }
    updateTranslations = () => {
        this.setState({
            questionType: [
                { id: "1", name: this.TraslationFuncation("questionBankCreationPage.Technical") },
                { id: "2", name: this.TraslationFuncation("questionBankCreationPage.Functional") }
            ],
            questionFormat: [
                { id: "1", name: this.TraslationFuncation("questionBankCreationPage.multipleChoice") },
                { id: "3", name: this.TraslationFuncation("questionBankCreationPage.ScenarioBased") },
                { id: "2", name: this.TraslationFuncation("questionBankCreationPage.Mixed") }
            ],
            difficultyLevel: [
                { id: "1", name: this.TraslationFuncation("questionBankCreationPage.Basic") },
                { id: "2", name: this.TraslationFuncation("questionBankCreationPage.Intermediate") },
                { id: "3", name: this.TraslationFuncation("questionBankCreationPage.Advanced") }
            ],
            focusAreas: [
                { id: "1", name: this.TraslationFuncation("questionBankCreationPage.KeyCompetencies") },
                { id: "2", name: this.TraslationFuncation("questionBankCreationPage.CriticalSkills") }
            ]
        });
    };
    
    GetJobTitleApiResponse = (ApiCallId:string, responseJson: JobDescription) => {
        if (ApiCallId === this.GetJobTitleApiCallId) {
            if (responseJson && responseJson.data && responseJson.data.length > 0) {
                const jobTitle = responseJson.data?.map((item) => {
                    return {
                        id: item.id,
                        name: item.attributes.generated_job_title
                    }
                })
                this.setState({ JobTitleDropDownData: jobTitle })
            }
        }

    }

    GetCompetenciesResponse = (ApiCallId:string, Response:CompetenciesResponse) => {
        if (this.GetCompetenciesApiCallId === ApiCallId) {
            const Data = Response.competencies.map((item) => {
                return {
                    id: `${item.id}`,
                    competency_name: item.competency_name
                }
            })
            this.setState({ NewCompetenciesDropDownData: Data })
        }

    }

    async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined) {
        if (prevState.progrssbarScreen !== this.state.progrssbarScreen) {
            if (this.state.progrssbarScreen) {
                this.interval = setInterval(() => {
                    if (this.state.active === 6) {
                        this.setState({ active: 0 })
                    } else {
                        this.setState({ active: this.state.active + 1 })
                    }
                }, 300) as unknown as number
            }
        }
    }

    competenciesApiResponse = (responseJson: CompetencyResponse) => {
        if (responseJson && !responseJson.error) {
            this.setState({ competencies: responseJson.competencies })
        } else if (responseJson && responseJson.error) {
            this.setState({
                errorMsg: responseJson.error
            });
        }
    }

    createQuestionsApiResponse = (responseJson: QuestionBankResponse) => {
        if (responseJson && !responseJson.error) {
            this.setState({
                questionBankData: responseJson?.question_bank              
            }, async () => {
                await setStorageData("questionBankId", JSON.stringify(this.state.questionBankData?.data?.id))
            })
            clearInterval(this.interval);

            this.setState({
                exportProgress: 100,
            }, () => {
                this.setState({
                    openSuccessDialog: true,
                })
            })
        } else {
            clearInterval(this.interval);

            this.setState({
                exportProgress: 100,
            }, () => {
                this.setState({
                    openSuccessDialog: true,
                })
            })
        }
    }

    createQuestionBanksApiCall = () => {
        const headers = {
            token: this.state.token,
            "Content-Type": "application/json"
        };

        const rawBody = JSON.stringify({
            "question_bank": {
                "job_description_id": this.state.JobTitleDropDown[0],
                "question_type_ids": this.state.selectedQuestionType.map((id) => parseInt(id)),
                "question_format_ids": this.state.selectedQuestionFormat.map((id) => parseInt(id)),
                "difficulty_level_ids": this.state.selectedDifficultyLevel.map((id) => parseInt(id)),
                "focus_area_ids": this.state.selectedFocusArea.map((id) => parseInt(id)),
                "number_of_questions": parseInt(this.state.noOfQuestions),
                "assessment_objective": this.state.assessmentObjectives,
                "competency_ids": this.state.selectedCompetencies
            }
        });


        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.createQuestionsApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.createQuestionsApiEndPoint}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            rawBody
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.postApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    getCompetenciesApiCall = () => {
        const headers = {
            "token": this.state.token
        };

        const competencyMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        competencyMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getApiMethodType
        );
        this.getCompetencyApiCallId = competencyMsg.messageId;
        competencyMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.getCompetencyApiEndPoint}`
        );
        competencyMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        runEngine.sendMessage(competencyMsg.id, competencyMsg);
    }
    TraslationFuncation = (key: string) => {
            if (this.props.i18n) {
                return this.props.i18n.t(key);
            }else{
                return key
            }
        }
    GetJobTitle = () => {
        const headers = {
            "token": this.state.token
        };

        const GetJobTitleMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        GetJobTitleMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getApiMethodType
        );
        this.GetJobTitleApiCallId = GetJobTitleMsg.messageId;
        GetJobTitleMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.GetJobTitleEndPoint}`
        );
        GetJobTitleMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        runEngine.sendMessage(GetJobTitleMsg.id, GetJobTitleMsg);
    }

    GetJobCompetencies = (Id:string) => {
        const headers = {
            "token": this.state.token
        };

        const GetJobTitleMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        GetJobTitleMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getApiMethodType
        );
        this.GetCompetenciesApiCallId = GetJobTitleMsg.messageId;
        GetJobTitleMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.GetJobcompetenciesEndPoint}${Id}`
        );
        GetJobTitleMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        runEngine.sendMessage(GetJobTitleMsg.id, GetJobTitleMsg);
    }


    incrementProgress = () => {
        const progressInterval = setInterval(() => {
            if (this.state.exportProgress >= 90) {
                clearInterval(progressInterval);
            }
            this.setState({ exportProgress: Math.min(this.state.exportProgress + 5, 90) });
        }, 200);
    }

    handleCompetenciesChange = (event: SelectChangeEvent<string[]>) => {
        const { value } = event.target;
        this.setState({
            selectedCompetencies: typeof value === 'string' ? value.split(',') : value,
        });
    };

    handleCheckbox = (compId: string) => {
        let selectedCompetencies = this.state.selectedCompetencies;
        if (selectedCompetencies.includes(compId)) {
            selectedCompetencies = selectedCompetencies.filter(value => value !== compId)
        }
        else {
            selectedCompetencies.push(compId)
        }
        this.setState({ selectedCompetencies })
    }

    handleJobTitleCheckbox = (compId: string) => {
        this.setState({ JobTitleDropDown: [compId] })
    }

    handleAssessmentObjective = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({ assessmentObjectives: event.target.value.length > 5000 ? this.state.assessmentObjectives : event.target.value })
    }

    handleQuestionTypeChange = (id: string) => {
        let selectedQuestionType = this.state.selectedQuestionType;
        if (selectedQuestionType.includes(id)) {
            selectedQuestionType = selectedQuestionType.filter(value => value !== id)
        }
        else {
            selectedQuestionType.push(id)
        }
        this.setState({ selectedQuestionType });
    };

    difficultyLevelChange = (id: string) => {
        let selectedDifficultyLevel = this.state.selectedDifficultyLevel;
        if (selectedDifficultyLevel.includes(id)) {
            selectedDifficultyLevel = selectedDifficultyLevel.filter(value => value !== id)
        }
        else {
            selectedDifficultyLevel.push(id)
        }
        this.setState({ selectedDifficultyLevel });
    };

    focusAreaChange = (id: string) => {
        let selectedFocusArea = this.state.selectedFocusArea;
        if (selectedFocusArea.includes(id)) {
            selectedFocusArea = selectedFocusArea.filter(value => value !== id)
        }
        else {
            selectedFocusArea.push(id)
        }
        this.setState({ selectedFocusArea });
    };

    handleQuestionFormatChange = (id: string) => {
        let selectedQuestionFormat = this.state.selectedQuestionFormat;
        if (selectedQuestionFormat.includes(id)) {
            selectedQuestionFormat = selectedQuestionFormat.filter(value => value !== id)
        }
        else {
            selectedQuestionFormat.push(id)
        }
        this.setState({ selectedQuestionFormat });
    };

    noOfQuestionChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const value = event.target.value;
        const number = parseInt(value);
        const regex = /^\d{0,3}$/;
        if (regex.test(value)) {
            if (value === "" || (number <= 100)) {
                this.setState({
                    noOfQuestions: value,
                    noOfQueErrorMsg: "",
                });
            } else {
                this.setState({
                    noOfQueErrorMsg: "Number cannot be greater than 100"
                });
            }
        }
    }

    handleGenerateQuestion = () => {
        this.setState({ generateBtnClicked: true  })
        let JobTitleId = !this.state.JobTitleDropDown.length;
        let errorInCompetencies = !this.state.selectedCompetencies.length;
        let errorInQuestionType = !this.state.selectedQuestionType.length;
        let errorInQuestionFormat = !this.state.selectedQuestionFormat.length;
        let errorInNoOfInQuestions = !this.state.noOfQuestions;
        let errorInDifficultyLevel = !this.state.selectedDifficultyLevel.length;
        let errorInFocusArea = !this.state.selectedFocusArea.length;
        let errorInassessmentObjective = !this.state.assessmentObjectives;
        if (errorInCompetencies ||
            errorInQuestionType ||
            errorInQuestionFormat ||
            errorInNoOfInQuestions ||
            errorInDifficultyLevel ||
            errorInFocusArea ||
            errorInassessmentObjective ||
            JobTitleId
        ) {
            return
        } else {
            this.setState({ progrssbarScreen: true, exportProgress: 0 }, () => {
                setTimeout(() => {
                    this.createQuestionBanksApiCall();
                }, 3000);
                this.incrementProgress();
            });
        }
    }

    navigateToQuesCreation = () => {
        this.setState({ progrssbarScreen: !this.state.progrssbarScreen, openSuccessDialog: false })
    }

    analyzingText = (): string => {
        if (this.state.exportProgress > 30 && this.state.exportProgress < 60) {
            return "Selecting relevant question types...";
        } else if (this.state.exportProgress >= 60) {
            return "Generating questions with varied difficulty levels...";
        } else {
            return "Analyzing competencies...";
        }
    };

    closeSuccessDialog = () => {
        this.setState({ openSuccessDialog: false })
    }

    navigateToDashboard = () => {
        const navigateMsg = new Message(getName(MessageEnum.NavigationMessage));
        navigateMsg.addData(getName(MessageEnum.NavigationTargetMessage), "QuestionBankDashboard");
        navigateMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(navigateMsg);
    }

    navigateToListQuestion = () => {
        const navigateMsg = new Message(getName(MessageEnum.NavigationMessage));
        navigateMsg.addData(getName(MessageEnum.NavigationTargetMessage), "ListingQuestionBank");
        navigateMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(navigateMsg);
    }

    questionTitle = () => {
        if (this.state.progrssbarScreen) {
            return this.TraslationFuncation("questionBankCreationPage.generatedQuestions");
        } else {
            return this.TraslationFuncation("questionBankCreationPage.createQuestionBank");
        }
    };

    ConditionForLanguage = (TrueCondition: string, FalseCondition: string) => {
        if (this.state.selectedLanguage.toLowerCase() === "english") {
            return TrueCondition
        } else {
            return FalseCondition
        }
    }
    TrueOrFalseConditionPass = (Condition:boolean,TrueCondition:string,FalseCondition:string) =>{
        if (Condition) {
            return TrueCondition
        }else{
            return FalseCondition
        }
    }

    handleCondition = (condition: boolean, truePart: string, falsePart: string) => {
        return condition ? truePart : falsePart
    }
    // Customizable Area End
}