import React from "react";
// Customizable Area Start
import {
    Box,
    Grid,
    styled,
    Typography,
    Card,
    Divider,
    CardContent,
    Select,
    InputBase,
    MenuItem,
    IconButton,
    TextField,
    InputAdornment,
    Button,
    Radio,
    Modal
} from "@mui/material";
import { DashboardHeaderWeb as DashboardHeader } from "../../dashboard/src/DashboardHeader.web";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { backArrow, titleImg, textImg, shortAnswer, duplicateImg, deleteQuestion, openEnded, multipleSelection, addOtherImg, checkbox, selectedCheckbox, scenarioBased, yesNo, dragIconButton, markRightImg, successImg } from "./assets";
import CustomPopup from "../../../../packages/components/src/CustomSuccessModel.web";
// Customizable Area End

import UploadQuestionsController, {
    Props
} from "./UploadQuestionsController";

export default class UploadQuestions extends UploadQuestionsController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    essentials = () => {
        return (
            <Wrapper>
                <Box>
                    <Grid container justifyContent={"center"} alignItems={"center"} spacing={1}>
                        <Grid item xs={10}>
                            <Typography className="essentialText">
                                ESSENTIALS
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>

            </Wrapper>
        )
    }

    titleMenu = () => {
        return (
            <Wrapper
                data-test-id="title_tab"
                onClick={() => this.handleEssentials("title")}
                sx={{ backgroundColor: this.state.selectedEssentials === "title" ? "F1F5F9" : "" }}
            >
                <Box>
                    <Grid container justifyContent={"center"} alignItems={"center"} spacing={1}>
                        <Grid item xs={2} className="headerSubGrid" >
                            <img
                                src={titleImg.default}
                                height="24px"
                                width="24px"
                            />
                        </Grid>
                        <Grid item xs={10}>
                            <Typography className='titleText'>
                                Title
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>
            </Wrapper>
        )
    }

    textMenu = () => {
        return (
            <Wrapper
                data-test-id="text_tab"
                onClick={() => this.handleEssentials("text")}
                sx={{ backgroundColor: this.state.selectedEssentials === "text" ? "F1F5F9" : "" }}
            >
                <Box>
                    <Grid container justifyContent={"center"} alignItems={"center"} spacing={1}>
                        <Grid item xs={2} className="headerSubGrid">
                            <img
                                src={textImg.default}
                                height="24px"
                                width="24px"
                            />
                        </Grid>
                        <Grid item xs={10}>
                            <Typography className='titleText'>
                                Text
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>
            </Wrapper>
        )
    }

    choises = () => {
        return (
            <Wrapper sx={{ marginTop: "16px" }}>
                <Box>
                    <Grid container justifyContent={"center"} alignItems={"center"} spacing={1}>
                        <Grid item xs={10}>
                            <Typography className="essentialText">
                                CHOISES
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>

            </Wrapper>
        )
    }

    shortAnswer = () => {
        return (
            <Wrapper
                data-test-id="short_ans"
                onClick={() => this.handlechoices("shortAns")}
                sx={{ backgroundColor: this.state.selectedChoices.includes("shortAns") ? "F1F5F9" : "" }}
            >
                <Box>
                    <Grid container justifyContent={"center"} alignItems={"center"} spacing={1}>
                        <Grid item xs={2} className="headerSubGrid">
                            <img
                                src={shortAnswer.default}
                                height="24px"
                                width="24px"
                            />
                        </Grid>
                        <Grid item xs={10}>
                            <Typography className='titleText'>
                                Short Answer
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>
            </Wrapper>
        )
    }

    openEnded = () => {
        return (
            <Wrapper
                onClick={() => this.handlechoices("openEnded")}
                data-test-id="open_ended_tab"
                sx={{ backgroundColor: this.state.selectedChoices.includes("openEnded") ? "F1F5F9" : "" }}
            >
                <Box>
                    <Grid container justifyContent={"center"} alignItems={"center"} spacing={1}>
                        <Grid item xs={2} className="headerSubGrid">
                            <img
                                src={openEnded.default}
                                height="24px"
                                width="24px"
                            />
                        </Grid>
                        <Grid item xs={10}>
                            <Typography className='titleText'>
                                Open Ended
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>
            </Wrapper>
        )
    }

    multipleSelection = () => {
        return (
            <Wrapper
                data-test-id="multi_select_tab"
                onClick={() => this.handlechoices("multipleSelection")}
                sx={{ backgroundColor: this.state.selectedChoices.includes("multipleSelection") ? "F1F5F9" : "" }}
            >
                <Box>
                    <Grid container justifyContent={"center"} alignItems={"center"} spacing={1}>
                        <Grid item xs={2} className="headerSubGrid">
                            <img
                                src={multipleSelection.default}
                                height="24px"
                                width="24px"
                            />
                        </Grid>
                        <Grid item xs={10}>
                            <Typography className='titleText'>
                                Multiple Selection
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>
            </Wrapper>
        )
    }

    checkboxes = () => {
        return (
            <Wrapper
                data-test-id="checkboxes_tab"
                onClick={() => this.handlechoices("checkboxes")}
                sx={{ backgroundColor: this.state.selectedChoices.includes("checkboxes") ? "F1F5F9" : "" }}
            >
                <Box>
                    <Grid container justifyContent={"center"} alignItems={"center"} spacing={1}>
                        <Grid item xs={2} className="headerSubGrid">
                            <img
                                src={checkbox.default}
                                height="24px"
                                width="24px"
                            />
                        </Grid>
                        <Grid item xs={10}>
                            <Typography className='titleText'>
                                Checkboxes
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>
            </Wrapper>
        )
    }

    scenarioBased = () => {
        return (
            <Wrapper
                data-test-id="scenario_based_tab"
                onClick={() => this.handlechoices("scenarioBased")}
                sx={{ backgroundColor: this.state.selectedChoices.includes("scenarioBased") ? "F1F5F9" : "" }}
            >
                <Box>
                    <Grid container justifyContent={"center"} alignItems={"center"} spacing={1}>
                        <Grid item xs={2} className="headerSubGrid">
                            <img
                                src={scenarioBased.default}
                                height="24px"
                                width="24px"
                            />
                        </Grid>
                        <Grid item xs={10}>
                            <Typography className='titleText'>
                                Scenario-Based
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>
            </Wrapper>
        )
    }

    yesNo = () => {
        return (
            <Wrapper
                data-test-id="radio_tab"
                onClick={() => this.handlechoices("radio")}
                sx={{ backgroundColor: this.state.selectedChoices.includes("radio") ? "F1F5F9" : "" }}
            >
                <Box>
                    <Grid container justifyContent={"center"} alignItems={"center"} spacing={1}>
                        <Grid item xs={2} className="headerSubGrid">
                            <img
                                src={yesNo.default}
                                height="24px"
                                width="24px"
                            />
                        </Grid>
                        <Grid item xs={10}>
                            <Typography className='titleText'>
                                Yes/No
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>
            </Wrapper>
        )
    }

    QuestionBankList = () => {
        return (
            <Wrapper className="jobTitleWrapper">
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <Typography className="jobLabel">
                        Question Bank List
                    </Typography>
                    <Box style={{ display: "flex", justifyContent: "flex-end" }}>
                        <span className="errorStyle">
                            {this.state.ErrorselectedQuestionBank}
                        </span>
                    </Box>
                </div>
                <Select
                    data-test-id="questionBank_id"
                    className="dropDownClass"
                    inputProps={{
                        icon: {
                            left: 0,
                        },
                    }}
                    displayEmpty
                    variant="outlined"
                    value={this.state.selectedQuestionBank}
                    renderValue={
                        this.state.selectedQuestionBank
                            ? undefined
                            : () => (
                                <div className="dropDownStyleplaceHolder">
                                    Select Job Title
                                </div>
                            )
                    }
                    IconComponent={KeyboardArrowRightIcon}
                    onChange={this.handlequestionBank}
                    input={<InputBase />}
                >
                    {this.state.questionList.map((option) => (
                        <MenuItem key={option.id} value={option.id}
                            sx={{
                                fontFamily: "Urbanist",
                                fontSize: "16px",
                                fontWeight: 400,
                                margin: "4px"
                            }}>
                            {option.name}
                        </MenuItem>
                    ))}
                </Select>
            </Wrapper>
        )
    }

    difficultyLevel = (index: number) => {
        return (
            <Wrapper>
                <Box style={{ position: "relative" }} >

                    {
                        this.state.duplicateMultiSelect[index].ErrorDifficulty !== "" &&
                        <Box style={{ display: "flex", justifyContent: "flex-end", position: "absolute", right: "0px", top: "-30px" }}>
                            <span className="errorStyle">
                                {this.state.duplicateMultiSelect[index].ErrorDifficulty}
                            </span>
                        </Box>
                    }
                    <Select
                        data-test-id="difficulty_level"
                        className="threeDropDown"
                        inputProps={{
                            icon: {
                                left: 0,
                            },
                        }}
                        displayEmpty
                        variant="outlined"
                        value={this.state.duplicateMultiSelect[index].selectedDifficulty}
                        renderValue={
                            this.state.duplicateMultiSelect[index].selectedDifficulty
                                ? undefined
                                : () => (
                                    <div>
                                        Difficulty Level
                                    </div>
                                )
                        }
                        IconComponent={KeyboardArrowDownIcon}
                        onChange={(event) => this.handleDifficultyLevel(event, index)}
                        input={<InputBase />}
                    >
                        {this.state.difficultyLevel.map((option) => (
                            <MenuItem key={option.id} value={option.id}
                                sx={{
                                    fontFamily: "Urbanist",
                                    fontSize: "16px",
                                    fontWeight: 400,
                                    margin: "4px"
                                }}>
                                {option.name}
                            </MenuItem>
                        ))}
                    </Select>
                </Box>
            </Wrapper>
        )
    }

    difficultyLevelScenario = (index: number) => {
        return (
            <Wrapper>
                <Box style={{ position: "relative" }} >
                    {
                        this.state.duplicateScenario[index].ErrorDifficulty !== "" &&
                        <Box style={{ display: "flex", justifyContent: "flex-end", position: "absolute", right: "0px", top: "-30px" }}>
                            <span className="errorStyle">
                                {this.state.duplicateScenario[index].ErrorDifficulty}
                            </span>
                        </Box>
                    }
                    <Select
                        data-test-id="difficulty_level_scenario"
                        className="threeDropDown"
                        inputProps={{
                            icon: {
                                left: 0,
                            },
                        }}
                        displayEmpty
                        variant="outlined"
                        value={this.state.duplicateScenario[index].selectedDifficulty}
                        renderValue={
                            this.state.duplicateScenario[index].selectedDifficulty
                                ? undefined
                                : () => (
                                    <div>
                                        Difficulty Level
                                    </div>
                                )
                        }
                        IconComponent={KeyboardArrowDownIcon}
                        onChange={(event) => this.handleDifficultyLevelScenario(event, index)}
                        input={<InputBase />}
                    >
                        {this.state.difficultyLevel.map((option) => (
                            <MenuItem key={option.id} value={option.id}
                                sx={{
                                    fontFamily: "Urbanist",
                                    fontSize: "16px",
                                    fontWeight: 400,
                                    margin: "4px"
                                }}>
                                {option.name}
                            </MenuItem>
                        ))}
                    </Select>
                </Box>
            </Wrapper>
        )
    }

    difficultyLevelRadio = (index: number) => {
        return (
            <Wrapper>
                <Box style={{ position: "relative" }}>
                    {
                        this.state.duplicateRadio[index].ErrorDifficulty !== "" &&
                        <Box style={{ display: "flex", justifyContent: "flex-end", position: "absolute", right: "0px", top: "-30px" }}>
                            <span className="errorStyle">
                                {this.state.duplicateRadio[index].ErrorDifficulty}
                            </span>
                        </Box>
                    }
                    <Select
                        data-test-id="difficulty_level_radio"
                        className="threeDropDown"
                        inputProps={{
                            icon: {
                                left: 0,
                            },
                        }}
                        displayEmpty
                        variant="outlined"
                        value={this.state.duplicateRadio[index].selectedDifficulty}
                        renderValue={
                            this.state.duplicateRadio[index].selectedDifficulty
                                ? undefined
                                : () => (
                                    <div>
                                        Difficulty Level
                                    </div>
                                )
                        }
                        IconComponent={KeyboardArrowDownIcon}
                        onChange={(event) => this.handleDifficultyLevelRadio(event, index)}
                        input={<InputBase />}
                    >
                        {this.state.difficultyLevel.map((option) => (
                            <MenuItem key={option.id} value={option.id}
                                sx={{
                                    fontFamily: "Urbanist",
                                    fontSize: "16px",
                                    fontWeight: 400,
                                    margin: "4px"
                                }}>
                                {option.name}
                            </MenuItem>
                        ))}
                    </Select>
                </Box>
            </Wrapper>
        )
    }

    difficultyLevelCheckboxes = (index: number) => {
        return (
            <Wrapper>
                <Box style={{ position: "relative" }}>
                    {
                        this.state.duplicateCheckboxes[index].ErrorDifficulty !== "" &&
                        <Box style={{ display: "flex", justifyContent: "flex-end", position: "absolute", right: "0px", top: "-30px" }}>
                            <span className="errorStyle">
                                {this.state.duplicateCheckboxes[index].ErrorDifficulty}
                            </span>
                        </Box>
                    }
                    <Select
                        data-test-id="difficulty_level_checkboxes"
                        inputProps={{
                            icon: {
                                left: 0,
                            },
                        }}
                        displayEmpty
                        className="threeDropDown"
                        variant="outlined"
                        value={this.state.duplicateCheckboxes[index].selectedDifficulty}
                        renderValue={
                            this.state.duplicateCheckboxes[index].selectedDifficulty
                                ? undefined
                                : () => (
                                    <div>
                                        Difficulty Level
                                    </div>
                                )
                        }
                        IconComponent={KeyboardArrowDownIcon}
                        onChange={(event) => this.handleDifficultyLevelCheckboxes(event, index)}
                        input={<InputBase />}
                    >
                        {this.state.difficultyLevel.map((option) => (
                            <MenuItem key={option.id} value={option.id}
                                sx={{
                                    fontSize: "16px",
                                    fontFamily: "Urbanist",
                                    fontWeight: 400,
                                    margin: "4px"
                                }}>
                                {option.name}
                            </MenuItem>
                        ))}
                    </Select>
                </Box>
            </Wrapper>
        )
    }

    difficultyLevelShortAns = (index: number) => {
        return (
            <Wrapper>
                <Box style={{ position: "relative" }}>
                    {
                        this.state.duplicateShortAnswer[index].ErrorDifficulty !== "" &&
                        <Box style={{ display: "flex", justifyContent: "flex-end", position: "absolute", right: "0px", top: "-30px" }}>
                            <span className="errorStyle">
                                {this.state.duplicateShortAnswer[index].ErrorDifficulty}
                            </span>
                        </Box>
                    }
                    <Select
                        className="threeDropDown"
                        inputProps={{
                            icon: {
                                left: 0,
                            },
                        }}
                        displayEmpty
                        variant="outlined"
                        data-test-id="difficulty_level_short_ans"
                        value={this.state.duplicateShortAnswer[index].selectedDifficulty}
                        renderValue={
                            this.state.duplicateShortAnswer[index].selectedDifficulty
                                ? undefined
                                : () => (
                                    <div>
                                        Difficulty Level
                                    </div>
                                )
                        }
                        IconComponent={KeyboardArrowDownIcon}
                        onChange={(event) => this.handleDifficultyLevelShortAns(event, index)}
                        input={<InputBase />}
                    >
                        {this.state.difficultyLevel.map((option) => (
                            <MenuItem key={option.name} value={option.name}
                                sx={{
                                    fontFamily: "Urbanist",
                                    fontWeight: 400,
                                    margin: "4px",
                                    fontSize: "16px"
                                }}>
                                {option.name}
                            </MenuItem>
                        ))}
                    </Select>
                </Box>
            </Wrapper>
        )
    }

    difficultyLevelOpenEnded = (index: number) => {
        return (
            <Wrapper>
                <Box style={{ position: "relative" }}>
                    {
                        this.state.duplicateOpenEnded[index].ErrorDifficulty !== "" &&
                        <Box style={{ display: "flex", justifyContent: "flex-end", position: "absolute", right: "0px", top: "-30px" }}>
                            <span className="errorStyle">
                                {this.state.duplicateOpenEnded[index].ErrorDifficulty}
                            </span>
                        </Box>
                    }
                    <Select
                        inputProps={{
                            icon: {
                                left: 0,
                            },
                        }}
                        className="threeDropDown"
                        displayEmpty
                        variant="outlined"
                        data-test-id="difficulty_level_open_ended"
                        value={this.state.duplicateOpenEnded[index].selectedDifficulty}
                        renderValue={
                            this.state.duplicateOpenEnded[index].selectedDifficulty
                                ? undefined
                                : () => (
                                    <div>
                                        Difficulty Level
                                    </div>
                                )
                        }
                        IconComponent={KeyboardArrowDownIcon}
                        onChange={(event) => this.handleDifficultyLevelOpenEnded(event, index)}
                        input={<InputBase />}
                    >
                        {this.state.difficultyLevel.map((option) => (
                            <MenuItem key={option.id} value={option.id}
                                sx={{
                                    margin: "4px",
                                    fontFamily: "Urbanist",
                                    fontWeight: 400,
                                    fontSize: "16px"
                                }}>
                                {option.name}
                            </MenuItem>
                        ))}
                    </Select>
                </Box>
            </Wrapper>
        )
    }

    jobTitle = (index: number) => {
        return (
            <Wrapper>
                <Box style={{ position: "relative" }}>
                    {
                        this.state.duplicateMultiSelect[index].ErrorJobTitle !== "" &&
                        <Box style={{ display: "flex", justifyContent: "flex-end", position: "absolute", right: "0px", top: "-30px" }}>
                            <span className="errorStyle">
                                {this.state.duplicateMultiSelect[index].ErrorJobTitle}
                            </span>
                        </Box>
                    }
                    <Select
                        data-test-id="job_title"
                        className="threeDropDown"
                        inputProps={{
                            icon: {
                                left: 0,
                            },
                        }}
                        displayEmpty
                        variant="outlined"
                        value={this.state.duplicateMultiSelect[index].selectedJobTitle}
                        renderValue={
                            this.state.duplicateMultiSelect[index].selectedJobTitle
                                ? undefined
                                : () => (
                                    <div>
                                        Job Title
                                    </div>
                                )
                        }
                        IconComponent={KeyboardArrowDownIcon}
                        onChange={(event) => this.handleJobTitleChange(event, index)}
                        input={<InputBase />}
                    >
                        {this.state.jobTitle.map((option) => (
                            <MenuItem key={option.id} value={option.id}
                                sx={{
                                    fontFamily: "Urbanist",
                                    fontSize: "16px",
                                    fontWeight: 400,
                                    margin: "4px"
                                }}>
                                {option.attributes.generated_job_title}
                            </MenuItem>
                        ))}
                    </Select>
                </Box>
            </Wrapper>
        )
    }

    jobTitleScenario = (index: number) => {
        return (
            <Wrapper>
                <Box style={{ position: "relative" }}>
                    {
                        this.state.duplicateScenario[index].ErrorJobTitle !== "" &&
                        <Box style={{ display: "flex", justifyContent: "flex-end", position: "absolute", right: "0px", top: "-30px" }}>
                            <span className="errorStyle">
                                {this.state.duplicateScenario[index].ErrorJobTitle}
                            </span>
                        </Box>
                    }
                    <Select
                        data-test-id="job_title_scenario"
                        className="threeDropDown"
                        inputProps={{
                            icon: {
                                left: 0,
                            },
                        }}
                        displayEmpty
                        variant="outlined"
                        value={this.state.duplicateScenario[index].selectedJobTitle}
                        renderValue={
                            this.state.duplicateScenario[index].selectedJobTitle
                                ? undefined
                                : () => (
                                    <div>
                                        Job Title
                                    </div>
                                )
                        }
                        IconComponent={KeyboardArrowDownIcon}
                        onChange={(event) => this.handleJobTitleScenario(event, index)}
                        input={<InputBase />}
                    >
                        {this.state.jobTitle.map((option) => (
                            <MenuItem key={option.id} value={option.id}
                                sx={{
                                    fontFamily: "Urbanist",
                                    fontSize: "16px",
                                    fontWeight: 400,
                                    margin: "4px"
                                }}>
                                {option.attributes.generated_job_title}
                            </MenuItem>
                        ))}
                    </Select>
                </Box>
            </Wrapper>
        )
    }

    jobTitleRadio = (index: number) => {
        return (
            <Wrapper>
                <Box style={{ position: "relative" }}>
                    {
                        this.state.duplicateRadio[index].ErrorJobTitle !== "" &&
                        <Box style={{ display: "flex", justifyContent: "flex-end", position: "absolute", right: "0px", top: "-30px" }}>
                            <span className="errorStyle">
                                {this.state.duplicateRadio[index].ErrorJobTitle}
                            </span>
                        </Box>
                    }
                    <Select
                        data-test-id="job_title_radio"
                        inputProps={{
                            icon: {
                                left: 0,
                            },
                        }}
                        className="threeDropDown"
                        displayEmpty
                        variant="outlined"
                        value={this.state.duplicateRadio[index].selectedJobTitle}
                        renderValue={
                            this.state.duplicateRadio[index].selectedJobTitle
                                ? undefined
                                : () => (
                                    <div>
                                        Job Title
                                    </div>
                                )
                        }
                        IconComponent={KeyboardArrowDownIcon}
                        onChange={(event) => this.handleJobTitleRadio(event, index)}
                        input={<InputBase />}
                    >
                        {this.state.jobTitle.map((option) => (
                            <MenuItem key={option.id} value={option.id}
                                sx={{
                                    fontFamily: "Urbanist",
                                    fontSize: "16px",
                                    fontWeight: 400,
                                    margin: "4px"
                                }}>
                                {option.attributes.generated_job_title}
                            </MenuItem>
                        ))}
                    </Select>
                </Box>
            </Wrapper>
        )
    }

    jobTitleCheckboxes = (index: number) => {


        return (
            <Wrapper>
                <Box style={{ position: "relative" }}>
                    {
                        this.state.duplicateCheckboxes[index].ErrorJobTitle !== "" &&
                        <Box style={{ display: "flex", justifyContent: "flex-end", position: "absolute", right: "0px", top: "-30px" }}>
                            <span className="errorStyle">
                                {this.state.duplicateCheckboxes[index].ErrorJobTitle}
                            </span>
                        </Box>
                    }
                    <Select
                        data-test-id="job_title_checkboxes"
                        className="threeDropDown"
                        displayEmpty
                        variant="outlined"
                        inputProps={{
                            icon: {
                                left: 0,
                            },
                        }}
                        value={this.state.duplicateCheckboxes[index].selectedJobTitle}
                        renderValue={
                            this.state.duplicateCheckboxes[index].selectedJobTitle
                                ? undefined
                                : () => (
                                    <div>
                                        Job Title
                                    </div>
                                )
                        }
                        IconComponent={KeyboardArrowDownIcon}
                        onChange={(event) => this.handleJobTitleCheckboxes(event, index)}
                        input={<InputBase />}
                    >
                        {this.state.jobTitle.map((option) => (
                            <MenuItem key={option.id} value={option.id}
                                sx={{
                                    margin: "4px",
                                    fontFamily: "Urbanist",
                                    fontSize: "16px",
                                    fontWeight: 400
                                }}>
                                {option.attributes.generated_job_title}
                            </MenuItem>
                        ))}
                    </Select>
                </Box>
            </Wrapper>
        )
    }

    jobTitleShortAns = (index: number) => {
        return (
            <Wrapper>
                <Box style={{ position: "relative" }}>
                    {
                        this.state.duplicateShortAnswer[index].ErrorJobTitle !== "" &&
                        <Box style={{ display: "flex", justifyContent: "flex-end", position: "absolute", right: "0px", top: "-30px" }}>
                            <span className="errorStyle">
                                {this.state.duplicateShortAnswer[index].ErrorJobTitle}
                            </span>
                        </Box>
                    }
                    <Select
                        className="threeDropDown"
                        data-test-id="job_title_short_ans"
                        inputProps={{
                            icon: {
                                left: 0,
                            },
                        }}
                        displayEmpty
                        variant="outlined"
                        value={this.state.duplicateShortAnswer[index].selectedJobTitle}
                        renderValue={
                            this.state.duplicateShortAnswer[index].selectedJobTitle
                                ? undefined
                                : () => (
                                    <div> Job Title </div>
                                )
                        }
                        IconComponent={KeyboardArrowDownIcon}
                        onChange={(event) => this.handleJobTitleShortAns(event, index)}
                        input={<InputBase />}
                    >
                        {this.state.jobTitle.map((option) => (
                            <MenuItem key={option.id} value={option.id}
                                sx={{
                                    fontFamily: "Urbanist",
                                    fontSize: "16px",
                                    fontWeight: 400,
                                    margin: "4px"
                                }}>
                                {option.attributes.generated_job_title}
                            </MenuItem>
                        ))}
                    </Select>
                </Box>
            </Wrapper>
        )
    }

    jobTitleOpenEnded = (index: number) => {
        return (
            <Wrapper>
                <Box style={{ position: "relative" }}>
                    {
                        this.state.duplicateOpenEnded[index].ErrorJobTitle !== "" &&
                        <Box style={{ display: "flex", justifyContent: "flex-end", position: "absolute", right: "0px", top: "-30px" }}>
                            <span className="errorStyle">
                                {this.state.duplicateOpenEnded[index].ErrorJobTitle}
                            </span>
                        </Box>
                    }
                    <Select
                        className="threeDropDown"
                        data-test-id="job_title_open_ended"
                        inputProps={{
                            icon: {
                                left: 0,
                            },
                        }}
                        variant="outlined"
                        displayEmpty
                        value={this.state.duplicateOpenEnded[index].selectedJobTitle}
                        renderValue={
                            this.state.duplicateOpenEnded[index].selectedJobTitle
                                ? undefined
                                : () => (
                                    <div> Job Title </div>
                                )
                        }
                        IconComponent={KeyboardArrowDownIcon}
                        onChange={(event) => this.handleJobTitleOpenEnded(event, index)}
                        input={<InputBase />}
                    >
                        {this.state.jobTitle.map((option) => (
                            <MenuItem key={option.id} value={option.id}
                                sx={{
                                    fontFamily: "Urbanist",
                                    fontSize: "16px",
                                    fontWeight: 400,
                                    margin: "4px"
                                }}>
                                {option.attributes.generated_job_title}
                            </MenuItem>
                        ))}
                    </Select>
                </Box>
            </Wrapper>
        )
    }

    competencyType = (index: number) => {
        return (
            <Wrapper>
                <Box style={{ position: "relative" }}>
                    {
                        this.state.duplicateMultiSelect[index].ErrorCompetency !== "" &&
                        <Box style={{ display: "flex", justifyContent: "flex-end", position: "absolute", right: "0px", top: "-30px" }}>
                            <span className="errorStyle">
                                {this.state.duplicateMultiSelect[index].ErrorCompetency}
                            </span>
                        </Box>
                    }
                    <Select
                        data-test-id="competency_type"
                        className="threeDropDown"
                        inputProps={{
                            icon: {
                                left: 0,
                            },
                        }}
                        displayEmpty
                        variant="outlined"
                        value={this.state.duplicateMultiSelect[index].selectedCompetency}
                        renderValue={
                            this.state.duplicateMultiSelect[index].selectedCompetency
                                ? undefined
                                : () => (
                                    <div>
                                        Competency Type
                                    </div>
                                )
                        }
                        IconComponent={KeyboardArrowDownIcon}
                        onChange={(event) => this.handleCompetencyType(event, index)}
                        input={<InputBase />}
                    >
                        {this.state.duplicateMultiSelect[index].CompetenciesDropDownData.map((option) => (
                            <MenuItem key={option.id} value={option.id}
                                sx={{
                                    fontFamily: "Urbanist",
                                    fontSize: "16px",
                                    fontWeight: 400,
                                    margin: "4px"
                                }}>
                                {option.name}
                            </MenuItem>
                        ))}
                    </Select>
                </Box>
            </Wrapper>
        )
    }

    competencyTypeScenario = (index: number) => {
        return (
            <Wrapper>
                <Box style={{ position: "relative" }}>
                    {
                        this.state.duplicateScenario[index].ErrorCompetency !== "" &&
                        <Box style={{ display: "flex", justifyContent: "flex-end", position: "absolute", right: "0px", top: "-30px" }}>
                            <span className="errorStyle">
                                {this.state.duplicateScenario[index].ErrorCompetency}
                            </span>
                        </Box>
                    }
                    <Select
                        data-test-id="competency_type_scenario"
                        className="threeDropDown"
                        inputProps={{
                            icon: {
                                left: 0,
                            },
                        }}
                        displayEmpty
                        variant="outlined"
                        value={this.state.duplicateScenario[index].selectedCompetency}
                        renderValue={
                            this.state.duplicateScenario[index].selectedCompetency
                                ? undefined
                                : () => (
                                    <div>
                                        Competency Type
                                    </div>
                                )
                        }
                        IconComponent={KeyboardArrowDownIcon}
                        onChange={(event) => this.handleCompetencyScenario(event, index)}
                        input={<InputBase />}
                    >
                        {this.state.duplicateScenario[index].CompetenciesDropDownData.map((option) => (
                            <MenuItem key={option.id} value={option.id}
                                sx={{
                                    fontFamily: "Urbanist",
                                    fontSize: "16px",
                                    fontWeight: 400,
                                    margin: "4px"
                                }}>
                                {option.name}
                            </MenuItem>
                        ))}
                    </Select>
                </Box>
            </Wrapper>
        )
    }

    competencyTypeRadio = (index: number) => {
        return (
            <Wrapper>
                <Box style={{ position: "relative" }}>
                    {
                        this.state.duplicateRadio[index].ErrorCompetency !== "" &&
                        <Box style={{ display: "flex", justifyContent: "flex-end", position: "absolute", right: "0px", top: "-30px" }}>
                            <span className="errorStyle">
                                {this.state.duplicateRadio[index].ErrorCompetency}
                            </span>
                        </Box>
                    }
                    <Select
                        className="threeDropDown"
                        data-test-id="competency_type_radio"
                        inputProps={{
                            icon: {
                                left: 0,
                            },
                        }}
                        displayEmpty
                        variant="outlined"
                        value={this.state.duplicateRadio[index].selectedCompetency}
                        renderValue={
                            this.state.duplicateRadio[index].selectedCompetency
                                ? undefined
                                : () => (
                                    <div>
                                        Competency Type
                                    </div>
                                )
                        }
                        IconComponent={KeyboardArrowDownIcon}
                        onChange={(event) => this.handleCompetencyRadio(event, index)}
                        input={<InputBase />}
                    >
                        {this.state.duplicateRadio[index].CompetenciesDropDownData.map((option) => (
                            <MenuItem key={option.id} value={option.id}
                                sx={{
                                    fontFamily: "Urbanist",
                                    fontSize: "16px",
                                    fontWeight: 400,
                                    margin: "4px"
                                }}>
                                {option.name}
                            </MenuItem>
                        ))}
                    </Select>
                </Box>
            </Wrapper>
        )
    }

    competencyCheckboxes = (index: number) => {
        return (
            <Wrapper>
                <Box style={{ position: "relative" }}>
                    {
                        this.state.duplicateCheckboxes[index].ErrorCompetency !== "" &&
                        <Box style={{ display: "flex", justifyContent: "flex-end", position: "absolute", right: "0px", top: "-30px" }}>
                            <span className="errorStyle">
                                {this.state.duplicateCheckboxes[index].ErrorCompetency}
                            </span>
                        </Box>
                    }
                    <Select
                        displayEmpty
                        data-test-id="competency_type_checkboxes"
                        className="threeDropDown"
                        inputProps={{
                            icon: {
                                left: 0,
                            },
                        }}
                        variant="outlined"
                        value={this.state.duplicateCheckboxes[index].selectedCompetency}
                        renderValue={
                            this.state.duplicateCheckboxes[index].selectedCompetency
                                ? undefined
                                : () => (
                                    <div>
                                        Competency Type
                                    </div>
                                )
                        }
                        IconComponent={KeyboardArrowDownIcon}
                        onChange={(event) => this.handleCompetencyCheckboxes(event, index)}
                        input={<InputBase />}
                    >
                        {this.state.duplicateCheckboxes[index].CompetenciesDropDownData.map((option) => (
                            <MenuItem key={option.id} value={option.id}
                                sx={{
                                    fontWeight: 400,
                                    fontFamily: "Urbanist",
                                    fontSize: "16px",
                                    margin: "4px"
                                }}>
                                {option.name}
                            </MenuItem>
                        ))}
                    </Select>
                </Box>
            </Wrapper>
        )
    }

    competencyShortAns = (index: number) => {
        return (
            <Wrapper>
                <Box style={{ position: "relative" }}>
                    {
                        this.state.duplicateShortAnswer[index].ErrorCompetency !== "" &&
                        <Box style={{ display: "flex", justifyContent: "flex-end", position: "absolute", right: "0px", top: "-30px" }}>
                            <span className="errorStyle">
                                {this.state.duplicateShortAnswer[index].ErrorCompetency}
                            </span>
                        </Box>
                    }
                    <Select
                        displayEmpty
                        data-test-id="competency_type_short_ans"
                        className="threeDropDown"
                        inputProps={{
                            icon: {
                                left: 0,
                            },
                        }}
                        variant="outlined"
                        value={this.state.duplicateShortAnswer[index].selectedCompetency}
                        renderValue={
                            this.state.duplicateShortAnswer[index].selectedCompetency
                                ? undefined
                                : () => (
                                    <div>
                                        Competency Type
                                    </div>
                                )
                        }
                        IconComponent={KeyboardArrowDownIcon}
                        onChange={(event) => this.handleCompetencyShortAns(event, index)}
                        input={<InputBase />}
                    >
                        {this.state.duplicateShortAnswer[index].CompetenciesDropDownData.map((option) => (
                            <MenuItem key={option.id} value={option.id}
                                sx={{
                                    fontFamily: "Urbanist",
                                    fontSize: "16px",
                                    fontWeight: 400,
                                    margin: "4px"
                                }}>
                                {option.name}
                            </MenuItem>
                        ))}
                    </Select>
                </Box>
            </Wrapper>
        )
    }

    competencyOpenEnded = (index: number) => {
        return (
            <Wrapper>
                <Box style={{ position: "relative" }}>
                    {
                        this.state.duplicateOpenEnded[index].ErrorCompetency !== "" &&
                        <Box style={{ display: "flex", justifyContent: "flex-end", position: "absolute", right: "0px", top: "-30px" }}>
                            <span className="errorStyle">
                                {this.state.duplicateOpenEnded[index].ErrorCompetency}
                            </span>
                        </Box>
                    }
                    <Select
                        displayEmpty
                        className="threeDropDown"
                        data-test-id="competency_type_open_ended"
                        inputProps={{
                            icon: {
                                left: 0,
                            },
                        }}
                        value={this.state.duplicateOpenEnded[index].selectedCompetency}
                        variant="outlined"
                        renderValue={
                            this.state.duplicateOpenEnded[index].selectedCompetency
                                ? undefined
                                : () => (
                                    <div>
                                        Competency Type
                                    </div>
                                )
                        }
                        IconComponent={KeyboardArrowDownIcon}
                        onChange={(event) => this.handleCompetencyOpenEnded(event, index)}
                        input={<InputBase />}
                    >
                        {this.state.duplicateOpenEnded[index].CompetenciesDropDownData.map((option) => (
                            <MenuItem key={option.id} value={option.id}
                                sx={{
                                    fontFamily: "Urbanist",
                                    fontSize: "16px",
                                    fontWeight: 400,
                                    margin: "4px"
                                }}>
                                {option.name}
                            </MenuItem>
                        ))}
                    </Select>
                </Box>
            </Wrapper>
        )
    }

    SuccessModel = () => {
        return (
            <Modal
                open={this.state.SuccessModel}
                data-test-id="successModel"
                onClose={this.CloseModelSuccessModel}>
                <CustomPopup
                    buttonText={"Done"}
                    data-test-id="successModelCustomPopup"
                    headingPopup={"Upload Questions"}
                    titlePopup={"Upload Questions Manually"}
                    subTitlePopup={`Questions uploaded manually successfully.`}
                    showCancelButton={false}
                    successIcon={successImg.default}
                    onOpen={this.CloseModelSuccessModel}
                    onClose={this.CloseModelSuccessModel}
                />
            </Modal>
        )
    }

    newQuestion = (index: number) => {
        return (
            <Grid container spacing={"4"} sx={{ textAlign: "center" }}>
                <Grid item xs={12}>
                    <img
                        src={dragIconButton.default}
                        height="32px"
                        width="32px"
                    />
                </Grid>
                <Grid item xs={12}>
                    <Grid container className="dropDownMoreMainGrid">
                        <Grid item xs={11}>
                            <Grid container>
                                <Grid item xs={12} md={4}>
                                    {this.difficultyLevel(index)}
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    {this.jobTitle(index)}
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    {this.competencyType(index)}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={1}>
                            <IconButton data-test-id="more_icon">
                                <MoreVertIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        )
    }

    newQuestionScenario = (index: number) => {
        return (
            <Grid container spacing={"4"} sx={{ textAlign: "center" }}>
                <Grid item xs={12}>
                    <img
                        src={dragIconButton.default}
                        height="32px"
                        width="32px"
                    />
                </Grid>
                <Grid item xs={12}>
                    <Grid container className="dropDownMoreMainGrid">
                        <Grid item xs={11}>
                            <Grid container>
                                <Grid item xs={12} md={4}>
                                    {this.difficultyLevelScenario(index)}
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    {this.jobTitleScenario(index)}
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    {this.competencyTypeScenario(index)}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={1}>
                            <IconButton data-test-id="more_icon">
                                <MoreVertIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        )
    }

    newQuestionRadio = (index: number) => {
        return (
            <Grid container spacing={"4"} sx={{ textAlign: "center" }}>
                <Grid item xs={12}>
                    <img
                        height="32px"
                        src={dragIconButton.default}
                        width="32px"
                    />
                </Grid>
                <Grid item xs={12}>
                    <Grid container className="dropDownMoreMainGrid">
                        <Grid item xs={11}>
                            <Grid container>
                                <Grid item xs={12} md={4}>
                                    {this.difficultyLevelRadio(index)}
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    {this.jobTitleRadio(index)}
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    {this.competencyTypeRadio(index)}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={1}>
                            <IconButton data-test-id="more_icon">
                                <MoreVertIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        )
    }

    newQuestionCheckboxes = (index: number) => {
        return (
            <Grid container spacing={"4"} sx={{ textAlign: "center" }}>
                <Grid item xs={12}>
                    <img
                        width="32px"
                        src={dragIconButton.default}
                        height="32px"

                    />
                </Grid>
                <Grid item xs={12}>
                    <Grid container className="dropDownMoreMainGrid">
                        <Grid item xs={11}>
                            <Grid container>
                                <Grid item xs={12} md={4}>
                                    {this.difficultyLevelCheckboxes(index)}
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    {this.jobTitleCheckboxes(index)}
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    {this.competencyCheckboxes(index)}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={1}>
                            <IconButton data-test-id="more_icon">
                                <MoreVertIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        )
    }

    newQuestionShortAns = (index: number) => {
        return (
            <Grid container spacing={"4"} sx={{ textAlign: "center" }}>
                <Grid item xs={12}>
                    <img
                        src={dragIconButton.default}
                        width="32px"
                        height="32px"
                    />
                </Grid>
                <Grid item xs={12}>
                    <Grid container className="dropDownMoreMainGrid">
                        <Grid item xs={11}>
                            <Grid container>
                                <Grid item xs={12} md={4}>
                                    {this.difficultyLevelShortAns(index)}
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    {this.jobTitleShortAns(index)}
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    {this.competencyShortAns(index)}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={1}>
                            <IconButton data-test-id="more_icon">
                                <MoreVertIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        )
    }

    newQuestionOpenEnded = (index: number) => {
        return (
            <Grid container spacing={"4"} sx={{ textAlign: "center" }}>
                <Grid item xs={12}>
                    <img
                        width="32px"
                        height="32px"
                        src={dragIconButton.default}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Grid container className="dropDownMoreMainGrid">
                        <Grid item xs={11}>
                            <Grid container>
                                <Grid item xs={12} md={4}>
                                    {this.difficultyLevelOpenEnded(index)}
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    {this.jobTitleOpenEnded(index)}
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    {this.competencyOpenEnded(index)}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={1}>
                            <IconButton data-test-id="more_icon">
                                <MoreVertIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        )
    }

    multySelectFormat = (index: number) => {
        const isAnyChecked = this.state.duplicateMultiSelect[index].multiSelectOptions.filter(option => option.checked).length > 0;
        return (
            <Wrapper className="multiSelectWrapper">
                <Box>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                        <Typography className="questionLabel">
                            {index + 1}. Question
                        </Typography>
                        {
                            this.state.duplicateMultiSelect[index].QuestionErrorText !== "" &&
                            <Box>
                                <span className="errorStyle">
                                    {this.state.duplicateMultiSelect[index].QuestionErrorText}
                                </span>
                            </Box>
                        }
                    </div>
                    <TextField
                        fullWidth
                        data-test-id="multi_select_question"
                        variant="outlined"
                        className="queTextboxStyle"
                        sx={{
                            mb: 2,
                            '& input': {
                                color: '#0F172A',
                                fontSize: '16px',
                                fontFamily: 'Urbanist',
                                fontWeight: this.state.selectedEssentials === "title" ? 700 : 400
                            },
                        }}
                        placeholder="Type Question here"
                        value={this.state.duplicateMultiSelect[index].multiQuestionText}
                        onChange={(event) => this.handleMultiQuestionText(event, index)}
                    />

                    <Typography className="questionLabel">
                        Answer
                    </Typography>

                    {this.state.duplicateMultiSelect[index].multiSelectOptions.map((option, optIndex) => (
                        <Box key={optIndex} sx={{ mb: 2, position: 'relative' }}>
                            {
                                option.ErrorMessage !== "" &&
                                <Box style={{ display: "flex", justifyContent: "flex-end" }}>
                                    <span className="errorStyle">
                                        {option.ErrorMessage}
                                    </span>
                                </Box>
                            }
                            <TextField
                                fullWidth
                                variant="outlined"
                                data-test-id="muti_select_options"
                                className="queTextboxStyle"
                                placeholder={`Type Option ${optIndex + 1}`}
                                value={option.text}
                                onChange={(event) => this.handleOptionChange(event, index, optIndex)}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <img
                                                src={option.checked ? selectedCheckbox.default : checkbox.default}
                                                height="24px"
                                                width="24px"
                                                data-test-id="muti_select_options_checkbox"
                                                onClick={() => this.handleCheckboxChange(!option.checked, index, optIndex)}
                                                className="checkBoxStyle"
                                            />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Box>
                    ))}

                    {this.state.duplicateMultiSelect[index].multiSelectOptions.length < 5 && <Button
                        data-test-id="add_other_btn"
                        onClick={() => this.handleAddOtherOption(index)}
                        sx={{
                            "&:hover": {
                                backgroundColor: "white"
                            }
                        }}
                    >
                        <img src={addOtherImg.default} />
                        <Typography className="addOtherBtn">
                            Add Other
                        </Typography>
                    </Button>}

                    <Grid container spacing={2} justifyContent={"flex-end"} sx={{ mt: 2 }}>
                        <Grid item xs={12} md={2.5} className="bottomOptions">
                            <img src={markRightImg.default} height="24px" width="24px" />
                            <Typography className="addOtherBtn">
                                {isAnyChecked ? "Marked" : "Mark right answer"}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={2.5} data-test-id="duplicate_btn" className="bottomOptions" onClick={() => this.handleDuplicateMultiSelect(index)}>
                            <img src={duplicateImg.default} height="24px" width="24px" />
                            <Typography className="addOtherBtn">
                                Duplicate
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={2.5} data-test-id="delete_btn" className="bottomOptions" onClick={() => this.handleDeleteMultiSelect(index)}>
                            <img src={deleteQuestion.default} height="24px" width="24px" />
                            <Typography className="addOtherBtn">
                                Delete
                            </Typography>
                        </Grid>

                    </Grid>
                </Box>
            </Wrapper>
        )
    }

    scenarioFormat = (index: number) => {
        return (
            <Wrapper className="multiSelectWrapper">
                <Box>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <Typography className="questionLabel">
                            {index + 1}. Question
                        </Typography>
                        {
                            this.state.duplicateScenario[index].QuestionErrorText !== "" &&
                            <Box>
                                <span className="errorStyle">
                                    {this.state.duplicateScenario[index].QuestionErrorText}
                                </span>
                            </Box>
                        }
                    </div>
                    <TextField
                        fullWidth
                        data-test-id="scenario_question"
                        variant="outlined"
                        className="queTextboxStyle"
                        sx={{
                            mb: 2,
                            '& input': {
                                color: '#0F172A',
                                fontSize: '16px',
                                fontFamily: 'Urbanist',
                                fontWeight: this.state.selectedEssentials === "title" ? 700 : 400
                            },
                        }}
                        placeholder="Type Question here"
                        value={this.state.duplicateScenario[index].scenarioQueText}
                        onChange={(event) => this.handleScenarioQuestionText(event, index)}
                    />

                    <Typography className="questionLabel">
                        Answer
                    </Typography>
                    {
                        this.state.duplicateScenario[index].ErrorMessage !== "" &&
                        <Box style={{ display: "flex", justifyContent: "flex-end" }}>
                            <span className="errorStyle">
                                {this.state.duplicateScenario[index].ErrorMessage}
                            </span>
                        </Box>
                    }
                    <textarea
                        data-test-id="scenario_answer"
                        className="answerInputField"
                        placeholder="Optional"
                        value={this.state.duplicateScenario[index].scenarioAnswerText}
                        onChange={(event) => this.handlescenarioAnswer(event, index)}
                    />

                    <Grid container spacing={2} justifyContent={"flex-end"} sx={{ mt: 2 }}>
                        <Grid item xs={12} md={2.5} data-test-id="duplicate_btn_scenario" className="bottomOptions" onClick={() => this.handleDuplicateScenario(index)}>
                            <img src={duplicateImg.default} height="24px" width="24px" />
                            <Typography className="addOtherBtn">
                                Duplicate
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={2.5} data-test-id="delete_btn_scenario" className="bottomOptions" onClick={() => this.handleDeleteScenario(index)}>
                            <img src={deleteQuestion.default} height="24px" width="24px" />
                            <Typography className="addOtherBtn">
                                Delete
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>
            </Wrapper>
        )
    }

    radioFormat = (index: number) => {
        return (
            <Wrapper className="multiSelectWrapper">
                <Box>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <Typography className="questionLabel">
                            {index + 1}. Question
                        </Typography>
                        {
                            this.state.duplicateRadio[index].QuestionErrorText !== "" &&
                            <Box>
                                <span className="errorStyle">
                                    {this.state.duplicateRadio[index].QuestionErrorText}
                                </span>
                            </Box>
                        }
                    </div>
                    <TextField
                        fullWidth
                        data-test-id="radio_question"
                        variant="outlined"
                        className="queTextboxStyle"
                        sx={{
                            mb: 2,
                            '& input': {
                                color: '#0F172A',
                                fontFamily: 'Urbanist',
                                fontSize: '16px',
                                fontWeight: this.state.selectedEssentials === "title" ? 700 : 400
                            },
                        }}
                        placeholder="Type Question here"
                        value={this.state.duplicateRadio[index].radioQueText}
                        onChange={(event) => this.handleRadioQuestionText(event, index)}
                    />

                    <Typography className="questionLabel">
                        Answer
                    </Typography>

                    {this.state.duplicateRadio[index].radioOptions.map((option, optIndex) => (
                        <Box key={optIndex} sx={{ mb: 2 }}>
                            {
                                option.ErrorMessage !== "" &&
                                <Box style={{ display: "flex", justifyContent: "flex-end" }}>
                                    <span className="errorStyle">
                                        {option.ErrorMessage}
                                    </span>
                                </Box>
                            }
                            <TextField
                                fullWidth
                                variant="outlined"
                                data-test-id="radio_options"
                                className="queTextboxStyle"
                                placeholder={`Type Option ${optIndex + 1}`}
                                value={option.text}
                                onChange={(event) => this.handleRadioOptionChange(event, index, optIndex)}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Radio
                                                checked={option.checked}
                                                onChange={() => this.handleRadioChange(!option.checked, index, optIndex)}
                                                data-test-id="muti_select_options_radio"
                                                className="checkBoxStyle"
                                            />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Box>
                    ))}

                    <Grid container spacing={2} justifyContent={"flex-end"} sx={{ mt: 2 }}>
                        <Grid item xs={12} md={2.5} data-test-id="duplicate_btn_radio" className="bottomOptions" onClick={() => this.handleDuplicateRadio(index)}>
                            <img src={duplicateImg.default} height="24px" width="24px" />
                            <Typography className="addOtherBtn">
                                Duplicate
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={2.5} data-test-id="delete_btn_radio" className="bottomOptions" onClick={() => this.handleDeleteRadio(index)}>
                            <img src={deleteQuestion.default} height="24px" width="24px" />
                            <Typography className="addOtherBtn">
                                Delete
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>
            </Wrapper>
        )
    }

    checkboxesFormat = (index: number) => {
        const isChecked = this.state.duplicateCheckboxes[index].checkboxesOptions.filter(option => option.checked).length > 0;
        return (
            <Wrapper className="multiSelectWrapper">
                <Box>
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <Typography className="questionLabel">
                            {index + 1}. Question
                        </Typography>
                        {
                            this.state.duplicateCheckboxes[index].QuestionErrorText !== "" &&
                            <Box>
                                <span className="errorStyle">
                                    {this.state.duplicateCheckboxes[index].QuestionErrorText}
                                </span>
                            </Box>
                        }
                    </div>
                    <TextField
                        fullWidth
                        data-test-id="checkboxes_question"
                        className="queTextboxStyle"
                        variant="outlined"
                        sx={{
                            mb: 2,
                            '& input': {
                                fontSize: '16px',
                                color: '#0F172A',
                                fontFamily: 'Urbanist',
                                fontWeight: this.state.selectedEssentials === "title" ? 700 : 400
                            },
                        }}
                        placeholder="Type Question here"
                        value={this.state.duplicateCheckboxes[index].checkQuestionText}
                        onChange={(event) => this.handleCheckboxesQuestionText(event, index)}
                    />

                    <Typography className="questionLabel">
                        Answer
                    </Typography>

                    {this.state.duplicateCheckboxes[index].checkboxesOptions.map((option, optIndex) => (
                        <Box key={optIndex} sx={{ mb: 2 }}>
                            {
                                option.ErrorMessage !== "" &&
                                <Box style={{ display: "flex", justifyContent: "flex-end" }}>
                                    <span className="errorStyle">
                                        {option.ErrorMessage}
                                    </span>
                                </Box>
                            }
                            <TextField
                                fullWidth
                                data-test-id="checkboxes_options"
                                className="queTextboxStyle"
                                variant="outlined"
                                placeholder={`Type Option ${optIndex + 1}`}
                                value={option.text}
                                onChange={(event) => this.handleOptionChangeCheckboxes(event, index, optIndex)}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <img
                                                height="24px"
                                                width="24px"
                                                src={option.checked ? selectedCheckbox.default : checkbox.default}
                                                data-test-id="checkboxes_options_checkbox"
                                                onClick={() => this.handleCheckboxTabChange(!option.checked, index, optIndex)}
                                                className="checkBoxStyle"
                                            />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Box>
                    ))}

                    {this.state.duplicateCheckboxes[index].checkboxesOptions.length < 5 && <Button
                        onClick={() => this.handleAddOtherOptionCheckboxes(index)}
                        data-test-id="add_other_btn_checkboxes"
                        sx={{
                            "&:hover": {
                                backgroundColor: "white"
                            }
                        }}
                    >
                        <img src={addOtherImg.default} />
                        <Typography className="addOtherBtn">
                            Add Other
                        </Typography>
                    </Button>}

                    <Grid container spacing={2} justifyContent={"flex-end"} sx={{ mt: 2 }}>
                        <Grid item xs={12} md={2.5} className="bottomOptions">
                            <img src={markRightImg.default} height="24px" width="24px" />
                            <Typography className="addOtherBtn">
                                {isChecked ? "Marked" : "Mark right answer"}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={2.5} data-test-id="duplicate_btn_checkboxes" className="bottomOptions" onClick={() => this.handleDuplicateCheckboxes(index)}>
                            <img src={duplicateImg.default} height="24px" width="24px" />
                            <Typography className="addOtherBtn">
                                Duplicate
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={2.5} data-test-id="delete_btn_checkboxes" className="bottomOptions" onClick={() => this.handleDeleteCheckboxes(index)}>
                            <img src={deleteQuestion.default} height="24px" width="24px" />
                            <Typography className="addOtherBtn">
                                Delete
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>
            </Wrapper>
        )
    }

    shortAnsFormat = (index: number) => {
        return (
            <Wrapper className="multiSelectWrapper">
                <Box>
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <Typography className="questionLabel">
                            {index + 1}. Question
                        </Typography>
                        {
                            this.state.duplicateShortAnswer[index].QuestionErrorText !== "" &&
                            <Box>
                                <span className="errorStyle">
                                    {this.state.duplicateShortAnswer[index].QuestionErrorText}
                                </span>
                            </Box>
                        }
                    </div>
                    <TextField
                        fullWidth
                        data-test-id="short_ans_question"
                        className="queTextboxStyle"
                        variant="outlined"
                        sx={{
                            mb: 2,
                            '& input': {
                                fontWeight: this.state.selectedEssentials === "title" ? 700 : 400,
                                color: '#0F172A',
                                fontSize: '16px',
                                fontFamily: 'Urbanist'
                            },
                        }}
                        placeholder="Type Question here"
                        value={this.state.duplicateShortAnswer[index].scenarioQueText}
                        onChange={(event) => this.handleShortAnsQuestionText(event, index)}
                    />

                    <Typography className="questionLabel">
                        Answer
                    </Typography>
                    {
                        this.state.duplicateShortAnswer[index].ErrorMessage !== "" &&
                        <Box style={{ display: "flex", justifyContent: "flex-end" }}>
                            <span className="errorStyle">
                                {this.state.duplicateShortAnswer[index].ErrorMessage}
                            </span>
                        </Box>
                    }
                    <textarea
                        data-test-id="short_answer"
                        className="answerInputField"
                        placeholder="Optional"
                        value={this.state.duplicateShortAnswer[index].scenarioAnswerText}
                        onChange={(event) => this.handleShortAnswer(event, index)}
                    />

                    <Grid container spacing={2} justifyContent={"flex-end"} sx={{ mt: 2 }}>
                        <Grid item xs={12} md={2.5} data-test-id="duplicate_btn_short_ans" className="bottomOptions" onClick={() => this.handleDuplicateShortAns(index)}>
                            <img src={duplicateImg.default} height="24px" width="24px" />
                            <Typography className="addOtherBtn">
                                Duplicate
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={2.5} data-test-id="delete_btn_short_ans" className="bottomOptions" onClick={() => this.handleDeleteShortAns(index)}>
                            <img src={deleteQuestion.default} height="24px" width="24px" />
                            <Typography className="addOtherBtn">
                                Delete
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>
            </Wrapper>
        )
    }

    openEndedFormat = (index: number) => {
        return (
            <Wrapper className="multiSelectWrapper">
                <Box>
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <Typography className="questionLabel">
                            {index + 1}. Question
                        </Typography>
                        {
                            this.state.duplicateOpenEnded[index].QuestionErrorText !== "" &&
                            <Box>
                                <span className="errorStyle">
                                    {this.state.duplicateOpenEnded[index].QuestionErrorText}
                                </span>
                            </Box>
                        }
                    </div>
                    <TextField
                        variant="outlined"
                        fullWidth
                        data-test-id="open_ended_question"
                        className="queTextboxStyle"
                        sx={{
                            mb: 2,
                            '& input': {
                                fontWeight: this.state.selectedEssentials === "title" ? 700 : 400,
                                fontSize: '16px',
                                fontFamily: 'Urbanist',
                                color: '#0F172A'
                            },
                        }}
                        placeholder="Type Question here"
                        value={this.state.duplicateOpenEnded[index].scenarioQueText}
                        onChange={(event) => this.handleOpenEndedQuestionText(event, index)}
                    />

                    <Typography className="questionLabel">
                        Answer
                    </Typography>
                    {
                        this.state.duplicateOpenEnded[index].ErrorMessage !== "" &&
                        <Box style={{ display: "flex", justifyContent: "flex-end" }}>
                            <span className="errorStyle">
                                {this.state.duplicateOpenEnded[index].ErrorMessage}
                            </span>
                        </Box>
                    }
                    <textarea
                        data-test-id="open_ended_answer"
                        className="answerInputField"
                        placeholder="Optional"
                        value={this.state.duplicateOpenEnded[index].scenarioAnswerText}
                        onChange={(event) => this.handleOpenEndedAnswer(event, index)}
                    />

                    <Grid container spacing={2} justifyContent={"flex-end"} sx={{ mt: 2 }}>
                        <Grid item xs={12} md={2.5} data-test-id="duplicate_btn_open_ended" className="bottomOptions" onClick={() => this.handleDuplicateOpenEnded(index)}>
                            <img src={duplicateImg.default} height="24px" width="24px" />
                            <Typography className="addOtherBtn">
                                Duplicate
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={2.5} data-test-id="delete_btn_open_ended" className="bottomOptions" onClick={() => this.handleDeleteOpenEnded(index)}>
                            <img src={deleteQuestion.default} height="24px" width="24px" />
                            <Typography className="addOtherBtn">
                                Delete
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>
            </Wrapper>
        )
    }



    multipleChoiseTab = () => {
        return (
            <>
                {this.state.duplicateMultiSelect.map((value, index) => {
                    return (
                        <Card className="queContentCard">
                            <CardContent>
                                <Grid container>

                                    <Grid item xs={12}>
                                        {this.newQuestion(index)}
                                    </Grid>
                                    <Grid item xs={12}>
                                        {this.multySelectFormat(index)}
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    )
                })}
            </>
        )
    }

    scenarioBasedTab = () => {
        return (
            <>
                {this.state.duplicateScenario.map((value, index) => {
                    return (
                        <Card className="queContentCard">
                            <CardContent>
                                <Grid container>

                                    <Grid item xs={12}>
                                        {this.newQuestionScenario(index)}
                                    </Grid>
                                    <Grid item xs={12}>
                                        {this.scenarioFormat(index)}
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    )
                })}
            </>
        )
    }

    radioTab = () => {
        return (
            <>
                {this.state.duplicateRadio.map((value, index) => {
                    return (
                        <Card className="queContentCard">
                            <CardContent>
                                <Grid container>

                                    <Grid item xs={12}>
                                        {this.newQuestionRadio(index)}
                                    </Grid>
                                    <Grid item xs={12}>
                                        {this.radioFormat(index)}
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    )
                })}
            </>
        )
    }

    checkboxesTab = () => {
        return (
            <>
                {this.state.duplicateCheckboxes.map((value, index) => {
                    return (
                        <Card className="queContentCard">
                            <CardContent>
                                <Grid container>

                                    <Grid item xs={12}>
                                        {this.newQuestionCheckboxes(index)}
                                    </Grid>
                                    <Grid item xs={12}>
                                        {this.checkboxesFormat(index)}
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    )
                })}
            </>
        )
    }

    shortAnsTab = () => {
        return (
            <>
                {this.state.duplicateShortAnswer.map((value, index) => {
                    return (
                        <Card className="queContentCard">
                            <CardContent>
                                <Grid container>

                                    <Grid item xs={12}>
                                        {this.newQuestionShortAns(index)}
                                    </Grid>
                                    <Grid item xs={12}>
                                        {this.shortAnsFormat(index)}
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    )
                })}
            </>
        )
    }

    openEndedTab = () => {
        return (
            <>
                {this.state.duplicateOpenEnded.map((value, index) => {
                    return (
                        <Card className="queContentCard">
                            <CardContent>
                                <Grid container>

                                    <Grid item xs={12}>
                                        {this.newQuestionOpenEnded(index)}
                                    </Grid>
                                    <Grid item xs={12}>
                                        {this.openEndedFormat(index)}
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    )
                })}
            </>
        )
    }

    addQuestionMutiSelect = () => {
        return (
            <Wrapper>
                <Button
                    data-test-id="add_question_btn"
                    onClick={() => this.handleAddQuestion()}
                    className="addQuestion"
                >
                    <img src={addOtherImg.default} />
                    <Typography className="addOtherBtn">
                        Add Question
                    </Typography>
                </Button>
            </Wrapper>
        )
    }

    addQuestionScenrio = () => {
        return (
            <Wrapper>
                <Button
                    onClick={() => this.handleAddQuestionScenario()}
                    className="addQuestion"
                    data-test-id="add_question_scenario"
                >
                    <img src={addOtherImg.default} />
                    <Typography className="addOtherBtn">
                        Add Question
                    </Typography>
                </Button>
            </Wrapper>
        )
    }

    addQuestionCheckboxes = () => {
        return (
            <Wrapper>
                <Button
                    onClick={() => this.handleAddQuestionCheckboxes()}
                    className="addQuestion"
                    data-test-id="add_question_checkboxes"
                >
                    <img src={addOtherImg.default} />
                    <Typography className="addOtherBtn">
                        Add Question
                    </Typography>
                </Button>
            </Wrapper>
        )
    }

    addQuestionShortAns = () => {
        return (
            <Wrapper>
                <Button
                    onClick={() => this.handleAddQuestionShortAns()}
                    className="addQuestion"
                    data-test-id="add_question_short_ans"
                >
                    <img src={addOtherImg.default} />
                    <Typography className="addOtherBtn">
                        Add Question
                    </Typography>
                </Button>
            </Wrapper>
        )
    }

    addQuestionOpenEnded = () => {
        return (
            <Wrapper>
                <Button
                    onClick={() => this.handleAddQuestionOpenEnded()}
                    className="addQuestion"
                    data-test-id="add_question_open_ended"
                >
                    <img src={addOtherImg.default} />
                    <Typography className="addOtherBtn">
                        Add Question
                    </Typography>
                </Button>
            </Wrapper>
        )
    }

    addQuestionRadio = () => {
        return (
            <Wrapper>
                <Button
                    onClick={() => this.handleAddQuestionRadio()}
                    className="addQuestion"
                    data-test-id="add_question_radio"
                >
                    <img src={addOtherImg.default} />
                    <Typography className="addOtherBtn">
                        Add Question
                    </Typography>
                </Button>
            </Wrapper>
        )
    }

    questionCard = () => {
        return (
            <Wrapper>
                <Card className="mainCard">
                    <CardContent>
                        <Card className="titleCard">
                            <CardContent>
                                <Grid container spacing={"4"}>
                                    <Grid item xs={12}>
                                        <Typography className="assessmentTitle">Assessment Title</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography className="assessmentDescription">Description</Typography>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>

                        <Grid container>
                            <Grid item xs={12}>
                                {this.state.IsItFromQuestionBankDashboard && this.QuestionBankList()}
                            </Grid>
                        </Grid>
                        {this.state.selectedChoices.includes("multipleSelection") && this.multipleChoiseTab()}
                        {this.state.selectedChoices.includes("multipleSelection") && this.addQuestionMutiSelect()}

                        {this.state.selectedChoices.includes("scenarioBased") && this.scenarioBasedTab()}
                        {this.state.selectedChoices.includes("scenarioBased") && this.addQuestionScenrio()}

                        {this.state.selectedChoices.includes("checkboxes") && this.checkboxesTab()}
                        {this.state.selectedChoices.includes("checkboxes") && this.addQuestionCheckboxes()}

                        {this.state.selectedChoices.includes("shortAns") && this.shortAnsTab()}
                        {this.state.selectedChoices.includes("shortAns") && this.addQuestionShortAns()}

                        {this.state.selectedChoices.includes("openEnded") && this.openEndedTab()}
                        {this.state.selectedChoices.includes("openEnded") && this.addQuestionOpenEnded()}

                        {this.state.selectedChoices.includes("radio") && this.radioTab()}
                        {this.state.selectedChoices.includes("radio") && this.addQuestionRadio()}
                        <Grid container spacing={2} justifyContent={"space-between"}>
                            <Grid item xs={6}>
                                <Button variant="outlined"
                                    fullWidth data-test-id="cancel_btn" className="OutlinedButton"
                                    onClick={this.CancelButton}
                                >
                                    <Typography className="addQuestionText">
                                        Cancel
                                    </Typography>
                                </Button>
                            </Grid>
                            <Grid item xs={6}>
                                <Button data-test-id="SaveChange" onClick={this.SaveChanges} variant="outlined"
                                    fullWidth className="OutlinedButton">
                                    <Typography className="addQuestionText">
                                        Upload questions
                                    </Typography>
                                </Button>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Wrapper>
        )
    }

    sideNavigationMenu = () => {
        return (
            <NavigationWrapper sx={{ marginLeft: 0, paddingLeft: 0 }}>
                <Card className="navigationCard">
                    <Grid item xs={12}>
                        {this.essentials()}
                    </Grid>
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>
                    <Grid item xs={12}>
                        {this.titleMenu()}
                    </Grid>
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>
                    <Grid item xs={12}>
                        {this.textMenu()}
                    </Grid>
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>
                    <Grid item xs={12}>
                        {this.choises()}
                    </Grid>
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>
                    <Grid item xs={12}>
                        {this.shortAnswer()}
                    </Grid>
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>
                    <Grid item xs={12}>
                        {this.openEnded()}
                    </Grid>
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>
                    <Grid item xs={12}>
                        {this.multipleSelection()}
                    </Grid>
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>
                    <Grid item xs={12}>
                        {this.checkboxes()}
                    </Grid>
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>
                    <Grid item xs={12}>
                        {this.scenarioBased()}
                    </Grid>
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>
                    <Grid item xs={12}>
                        {this.yesNo()}
                    </Grid>
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>
                </Card>
            </NavigationWrapper>
        )
    }
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <DashboardHeader selectedMenu="Question banks" navigation={this.props.navigation} id={this.props.id}>
                <MainWrapper>
                    {this.SuccessModel()}
                    <Grid container justifyContent={"center"}>
                        <Grid item xs={11}>
                            <Grid container
                                spacing={1}
                                alignItems={"center"}
                                sx={{
                                    direction: this.state.selectedLanguage.toLowerCase() === "english" ? "" : "rtl",
                                    width: "84%"
                                }}
                            >
                                <Grid item xs={1.5} sm={1} md={0.8} lg={0.5}>
                                    <img src={backArrow.default}
                                        alt="Back Arrow"
                                        className={`${this.state.selectedLanguage.toLowerCase() === "english" ? "backArrowrrowImg" : "backArrowImgAr"}`}
                                        data-test-id="back_arrow"
                                        onClick={this.backToDashBoard}
                                    />
                                </Grid>
                                <Grid item xs={10.5} sm={11} md={11.2} lg={6}>
                                    <Typography
                                        className={`${this.state.selectedLanguage.toLowerCase() === "english" ? "uploadTitle" : "uploadTitleAr"}`}
                                    >
                                        Upload Questions Manually
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </MainWrapper>
                <SideWrapper>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={12} md={3}>
                            {this.sideNavigationMenu()}
                        </Grid>
                        <Grid item xs={12} sm={12} md={6.8}>
                            {this.questionCard()}
                        </Grid>
                    </Grid>
                </SideWrapper>
            </DashboardHeader>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
export const NavigationWrapper = styled(Box)({
    padding: "24px 24px 24px 0px",
    "& .navigationCard": {
        boxShadow: "rgba(0, 0, 0, 0.1) 0px 14px 144px 0px",
        padding: "56px 0px 80px 0px"
    },
    "& .essentialText": {
        fontFamily: "Urbanist",
        fontSize: "14px",
        fontWeight: 700,
        lineHeight: "22px",
        color: "#64748B"
    }
})
export const SideWrapper = styled(Box)({
    width: "100vw",
    marginTop: "20px",
})
export const Wrapper = styled(Box)({
    padding: "22px 12px 22px 0px",
    cursor: "pointer",
    position: "relative",
    "& .errorStyle": {
        color: "#F87171",
        fontFamily: "Urbanist",
        fontSize: "16px",
        fontWeight: 400,
        fontStyle: "italic",
        margin: "10px 0px"
    },
    "& .editBtn": {
        borderRadius: "4px 0px 0px 0px",
        height: "58px",
        padding: "10px 16px 10px 16px",
        background: "#044352",
        width: "100%"
    },
    "& .addQuestionText": {
        fontWeight: 700,
        fontFamily: "Urbanist",
        color: "#fff",
        fontSize: "16px",
        lineHeight: "24px",
        textTransform: "none",
        marginLeft: "5px",
    },
    "& .OutlinedButton": {
        padding: "10px 16px",
        backgroundColor: "#FFFFFF",
        borderColor: "#94A3B8",
        height: "58px",
        borderRadius: "4px",
        textTransform: "none",
        fontWeight: 500,
        "&:hover": {
            backgroundColor: "#FFFFFF",
            borderColor: "#94A3B8",
        },
        "& .MuiTypography-root": {
            fontWeight: 700,
            fontFamily: "Urbanist",
            fontSize: "16px",
            lineHeight: "28px",
            color: "#044352",
        }
    },
    "& .questionLabel": {
        color: "#0F172A",
        fontFamily: "Urbanist",
        fontSize: "16px",
        fontWeight: 700,
        linHheight: "22px",
        marginBottom: "10px"
    },
    "& .addQuestion": {
        margin: "0px 0px 10px 10px",
        border: "1px solid #E2E8F0",
        backgroundColor: "#F1F5F9"
    },
    "& .answerInputField": {
        fontFamily: 'Urbanist',
        border: '1px solid #CBD5E1',
        fontWeight: 400,
        boxShadow: "0px 1px 2px 0px #1018280D",
        fontSie: "16px",
        lineHight: "24px",
        borderRadius: "8px",
        height: "98px",
        padding: "12px 16px",
        width: '100%',
        color: "#475569",
        "&:focus": {
            borderColor: "#CBD5E1",
            outline: "none",
        }
    },
    "& .bottomOptions": {
        display: "flex",
        justifyContent: "flex-end"
    },
    "& .addOtherBtn": {
        fontFamily: "Urbanist",
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "24px",
        textTransform: "none",
        color: "#334155",
        marginLeft: "8px"
    },
    "& .checkBoxStyle": {
        cursor: 'pointer',
        marginRight: '8px'
    },
    "& .multiSelectWrapper": {
        padding: "0px 0px 0px 4px"
    },
    "& .queTextboxStyle": {
        color: "#0F172A",
        fontFamily: "Urbanist",
        fontSize: "16px",
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: '#CBD5E1',
                borderRadius: '8px',
            },
            '&:hover fieldset': {
                borderColor: '#CBD5E1',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#CBD5E1',
            },
        },
    },
    "& .jobTitleWrapper": {
        padding: "22px 2px 28px 2px"
    },
    "& .MuiSelect-icon": {
        transform: 'none !important',
    },
    "& .jobLabel": {
        fontSize: "16px",
        fontFamily: "Urbanist",
        fontWeight: 700,
        lineHeight: "22px",
        color: "#475569",
        marginBottom: "3px"
    },
    "& .dropDownStyleplaceHolder": {
        color: "#DCDCDC",
        fontSize: 16,
        fontWeight: 400
    } as React.CSSProperties,

    "& .mainCard": {
        borderRadius: 2,
        boxShadow: "rgba(0, 0, 0, 0.1) 0px 14px 144px 0px"
    },
    "& .titleCard": {
        marginBottom: "24px",
        padding: "24px 0px",
        borderRadius: "16px",
        background: "#F1F5F9"
    },
    "& .queContentCard": {
        borderRadius: "16px",
        borderLeft: "4px solid #94A3B8",
        marginBottom: "8px"
    },
    "& .errorClass": {
        border: '1px solid #F87171 !important',
    },
    "& .dropDownMoreMainGrid": {
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    "& .dropDownClass": {
        border: '1px solid #CBD5E1',
        fontSie: '16px',
        backgroundColor: 'white',
        height: '66px',
        borderRadius: '8px',
        fontWeight: 700,
        lineHight: "24px",
        fontFamily: 'Urbanist',
        padding: '10px 12px',
        width: '100%',
        color: "#475569"
    },
    "& .threeDropDown": {
        border: '1px solid #94A3B8',
        fontSie: '16px',
        backgroundColor: '#F1F5F9',
        height: '66px',
        borderRadius: '4px',
        fontWeight: 700,
        lineHight: "24px",
        fontFamily: 'Urbanist',
        padding: '10px 12px',
        width: '100%',
        color: "#475569"
    },
    "& .selectedAction": {
        padding: "0px 6px 10px 0px"
    },
    "& .notSelectedTab": {
        padding: "0px 6px 10px 0px"
    },
    "& .headerSubGrid": {
        display: "flex",
        justifyContent: "end"
    },
    "& .titleText": {
        fontSize: "16px",
        fontFamily: "Urbanist",
        fontWeight: 400,
        lineHeight: "24px",
        color: "#0F172A",
        marginTop: "2px"
    },
    "& .assessmentTitle": {
        fontFamily: "Urbanist",
        fontSize: "24px",
        fontWeight: 700,
        lineHeight: "32px",
        textAlign: "center",
    },
    "& .assessmentDescription": {
        fontFamily: "Urbanist",
        fontSize: "18px",
        fontWeight: 400,
        lineHeight: "26px",
        textAlign: "center",
    }
})
export const MainWrapper = styled(Box)({
    display: "flex",
    justifyContent: "center",
    width: "100%",
    marginTop: "42px",
    paddingBottom: "30px",
    "& .backArrowrrowImg": {
        paddingRight: "12px"
    },
    "& .uploadTitleAr": {
        fontFamily: "Urbanist",
        fontSize: "25px",
        fontWeight: 600,
        lineHeight: "30px",
        color: "#000104",
        marginRight: "16px"
    },
    "& .backArrowImgAr": {
        transform: "rotate(180deg)"
    },
    "& .uploadTitle": {
        fontFamily: "Urbanist",
        fontSize: "25px",
        lineHeight: "30px",
        color: "#000104",
        fontWeight: 600,
        marginLeft: "16px"
    },
    "& .arabicDirection": {
        direction: "rtl",
    }
})
// Customizable Area End