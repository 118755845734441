import React from "react";
// Customizable Area Start
import {
    Box,
    Grid,
    Typography,
    Button,
    Card,
    CardContent,
    Modal,
    Select,
    InputBase,
    MenuItem
} from "@mui/material";
import { DashboardHeaderWeb as DashboardHeader } from "../../dashboard/src/DashboardHeader.web";
import { backArrow, blankCheckbox, selectedCheckbox, addQuestionImg, right } from './assets';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

import QuestionsAddController, {
    Props
} from "./QuestionsAddController";
import { withTranslation } from "react-i18next";
import CustomPopup from "../../../../packages/components/src/CustomSuccessModel.web";
import { Wrapper, MainWrapper } from "./ViewGenerateAssessment.web";
import NoDataScreen from "../../../../packages/components/src/NoDataScreen";
// Customizable Area End

export default class QuestionsAdd extends QuestionsAddController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start

    SuccessModel = () => {
        return (
            <Modal
                open={this.state.SuccessModel}
                data-test-id="successModel"
                onClose={this.CloseModelSuccessModel}>
                <CustomPopup
                    buttonText={this.TraslationFuncation("ViewGenerateAssessment.Done")}
                    data-test-id="successMopModel"
                    headingPopup={"Questions added"}
                    titlePopup={"Questions Added Successfully!"}
                    subTitlePopup={"The selected Questions are added to Assessment & saved successfully."}
                    showCancelButton={false}
                    successIcon={right.default}
                    onOpen={this.CloseModelSuccessModel}
                    onClose={this.CloseModelSuccessModel}
                />
            </Modal>
        )
    }


    addQuestionsBtn = () => {
        return (
            <Grid item xs={12} sm={7} md={12}>
                <Button
                    className="addQuestionBtn"
                    data-test-id="AddInAssessment"
                    disabled={this.state.SelectedQuestionId.length == 0}
                    onClick={() => {
                        this.AddInAssessnent()
                    }}
                >
                    <Box className="addQuestionBox">
                        <img src={addQuestionImg.default} height="20px" width="20px" />
                        <Typography className="addQuestionText">
                            Add To Assessment
                        </Typography>
                    </Box>

                </Button>
            </Grid>
        )
    }

    questionList = (item: {
        Id: string;
        Index: number;
        QuestionFormatName: string,
        QuestionText: string;
        TagText: Array<{
            id: string;
            name: string;
        }>
        Answers: Array<{
            Id: number
            ChoiceText: string;
            isCorrect: boolean;
        }>;
        CorrectChoices: Array<{
            Correctid: number;
            CorrectText: string;
        }>;
    }, index: number) => {

        return (
            <Wrapper sx={{ marginTop: 0 }}>
                {!this.state.previewClicked &&
                    <Grid container spacing={1.5} alignItems={"center"}>
                        <Grid item xs={12} sm={"auto"} className="filterEditRemove">
                            <img
                                src={this.state.SelectedQuestionId.includes(Number(item.Id)) ? selectedCheckbox.default : blankCheckbox.default}
                                alt="checkbox"
                                onClick={() => {
                                    this.setState({
                                        SelectedQuestionId: this.state.SelectedQuestionId.includes(Number(item.Id)) ? this.state.SelectedQuestionId.filter((id: number) => id !== Number(item.Id)) : [...this.state.SelectedQuestionId, Number(item.Id)]
                                    })
                                }}
                                data-test-id={"SelectedQuestionIdCheck"}
                                className="checkBoxStyle"
                            />
                        </Grid>
                    </Grid>
                }
                <Box sx={{ margin: { xs: "24px 0px 24px 8px", sm: "24px 0px 24px 16px", md: "24px 0px 24px 40px" }}}>
                    <Typography 
                     className="questionLabel">
                        {index}. {item.QuestionText}
                    </Typography>

                    <Box 
                        sx={{ fontFamily: "Urbanist" }}>
                        {item.QuestionFormatName === "Scenario Based" ? (
                            <Box 
                                className="optionsStyle scenarioAns">
                                <Typography>
                                    {item.QuestionText}
                                </Typography>
                            </Box>
                        ) : (
                            item.Answers?.map((options, index) => (
                                <Box
                                    key={options.ChoiceText + index}
                                    display="flex"
                                    className={
                                        options.isCorrect && !this.state.previewClicked
                                            ? "optionsStyleTrue"
                                            : "optionsStyle"
                                    }
                                    sx={{ fontFamily: "Urbanist" }}
                                    data-test-id="question_options"
                                    alignItems="center"
                                    mb={1}
                                >
                                    <img src={options.isCorrect && !this.state.previewClicked ? selectedCheckbox.default: blankCheckbox.default} alt="checkbox" className="checkBoxStyle"
                                    />
                                    <Typography style={{
                                        marginRight: this.TrueOrFalseConditionPass(this.state.selectedLanguage.toLowerCase() === "english", "  ", " 10px ")
                                    }} >{options.ChoiceText}</Typography>
                                </Box>
                            ))
                        )}
                    </Box>
                </Box>

            </Wrapper>
        )
    }

    questionListCard = () => {
        return (
            <Wrapper>

                <Card sx={{ borderRadius: 2, boxShadow: "rgba(0, 0, 0, 0.1) 0px 14px 144px 0px" }}>
                    {this.SuccessModel()}
                    <CardContent sx={{ paddingLeft: "40px", paddingBottom: "0px !important" }}>
                        <Grid container spacing={1} className="contentPadding">
                            {Array.isArray(this.state.QuestionAnswerData) && this.state.QuestionAnswerData.length !== 0 ? this.state.QuestionAnswerData.map((item, index) => (
                                <Grid item xs={12} key={item.Id + index + 1}>
                                    {this.questionList(item, index + 1)}
                                </Grid>
                            )) : <NoDataScreen />}
                        </Grid>

                    </CardContent>
                </Card>
            </Wrapper>
        )
    }
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <DashboardHeader navigation={this.props.navigation} id={this.props.id} selectedMenu="Question banks">
                <MainWrapper style={{ direction: this.state.selectedLanguage.toLowerCase() === "english" ? "ltr" : "rtl" }} ref={this.previewRef} >
                    <Grid justifyContent={"center"} container>
                        <Grid item xs={11}>
                            <Grid container alignItems={"center"} justifyContent={"space-between"} spacing={1}>
                                <Grid item xs={11} sm={11} md={7}>
                                    <Grid container spacing={1} alignItems={"center"}>
                                        <Grid item xs={12} sx={{ display: "flex", alignItems: "center" }}>
                                            <img src={backArrow.default}
                                                onClick={this.navigateToCreation}
                                                alt="Back Arrow"
                                                className={"backArrowImg" + " " + this.TrueOrFalseConditionPass(this.state.selectedLanguage.toLowerCase() === "english", " BackArrowForEnglish ", " BackArrowForArabic ")}
                                                data-test-id="back_to_dashboard"
                                            />
                                            <Typography className="questionTitle">
                                                Select Questions
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item xs={11} sm={11} md={5} lg={2.5}
                                    sx={{
                                        display: "flex",
                                        justifyContent: {
                                            xs: "flex-start",
                                            md: "flex-end",
                                            lg: "flex-end",
                                            xl: "flex-end"
                                        },
                                        marginRight: "16px"
                                    }}
                                >
                                    <Grid container spacing={2}>
                                        {this.addQuestionsBtn()}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={11}>
                            <Wrapper className="jobTitleWrapper">
                                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                    <Typography className="jobLabel">
                                        Question Bank
                                    </Typography>
                                </div>
                                <Select
                                    data-test-id="questionBank_id"
                                    className="dropDownClass"
                                    inputProps={{
                                        icon: {
                                            left: 0,
                                        },
                                    }}
                                    displayEmpty
                                    variant="outlined"
                                    value={this.state.selectedQuestionBank}
                                    renderValue={
                                        this.state.selectedQuestionBank
                                            ? undefined
                                            : () => (
                                                <div className="dropDownStyleplaceHolder">
                                                    Select Job Title
                                                </div>
                                            )
                                    }
                                    IconComponent={KeyboardArrowRightIcon}
                                    onChange={(e) => {
                                        this.QuestionListApiCall(e.target.value);
                                        this.setState({
                                            selectedQuestionBank: e.target.value,
                                        })
                                    }}
                                    input={<InputBase />}
                                >
                                    {this.state.questionList.map((option) => (
                                        <MenuItem key={option.id} value={option.id}
                                            sx={{
                                                fontFamily: "Urbanist",
                                                fontSize: "16px",
                                                fontWeight: 400,
                                                margin: "4px"
                                            }}>
                                            {option.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </Wrapper>
                        </Grid>
                        <Grid item xs={11}>
                            {this.questionListCard()}
                        </Grid>
                    </Grid>
                </MainWrapper>
            </DashboardHeader>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
export const QuestionsAddWeb = withTranslation()(QuestionsAdd);





// Customizable Area End