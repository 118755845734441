// Customizable Area Start
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";

import React, { ReactNode } from "react";

interface AssessmentResponse {
    message: string;
    assessment: {
        data: {
            id: string;
            type: string;
            attributes: {
                id: number;
                title: string;
            };
        };
    };
}

// Customizable Area End

export const configJSON = require("./config");
export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    children?: ReactNode;
    selectedMenu?: string;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    token: string;
    selectedLanguage: string;
    criticalId: string;
    isLoading: boolean;
    QuestionAnswerData: Array<{
        Index: number;
        QuestionText: string;
        Answers: Array<{
            Id: number;
            ChoiceText: string;
            isCorrect: boolean;
        }>;
        CorrectChoices: Array<{
            Correctid: number;
            CorrectText: string;
        }>;
    }>;
    currentPage: number;
    JobTitle: string;
    DifficultyLevel: string[];
    FocusAreas: string[];
    QuestionCount: string;
    totalScore: string;
    rank: string;
    criticalParameters: Array<any>;
    cpParameters: Array<any>;
    Competencies: Array<{
        name: string;
        scoreRatio: string;
        subParameters: Array<{
            subName: string;
            subScoreGenerated: number;
            subScoreOutOf: number;
        }>;
    }>;

    // Customizable Area End

}

interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class ViewCriticalDashboardController extends BlockComponent<
    Props,
    S,
    SS
> {

    // Customizable Area Start
    GetAssessmentData: string = "";
    GetCriticalDashboardApiCallId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.CountryCodeMessage)
        ];

        this.state = {
            token: '',
            selectedLanguage: '',
            criticalId: '',
            isLoading: false,
            QuestionAnswerData: [],
            currentPage: 1,
            JobTitle: "",
            DifficultyLevel: [],
            FocusAreas: [],
            QuestionCount: "",
            Competencies: [],
            totalScore: "",
            rank: "",
            criticalParameters: [],
            cpParameters: []
        };

        // Customizable Area End
        runEngine.attachBuildingBlock(this, this.subScribedMessages);
    }

    async componentDidMount() {
        const tokenMeta = await getStorageData('signInResponse', true);
        this.setState({
            token: tokenMeta?.meta?.token
        });
        const criticalId = await getStorageData('criticalID');
        this.setState({criticalId})
        this.AllCriticalPostionApi();
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        runEngine.debugLog("Message Recived", message);
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (responseJson) {
                this.GetCriticalPositionApiResponseOne(apiRequestCallId, responseJson);
            }
        }
        // Customizable Area End
    }

    // Customizable Area Start
    GetCriticalPositionApiResponseOne = (apiCallId: string, responseJson: any) => {
        if (apiCallId === this.GetCriticalDashboardApiCallId) {
    
            if (responseJson?.critical_position?.data?.attributes) {
                const criticalData = responseJson.critical_position.data.attributes;
    
                // ✅ Corrected totalScore calculation
                let rawScore = parseFloat(criticalData.total_score) || 0;
                let formattedTotalScore = rawScore >= 100 ? `${Math.floor(rawScore - 100)}/100` : `${Math.floor(rawScore)}/100`;
    
                this.setState({
                    JobTitle: criticalData.job_title || "",
                    totalScore: formattedTotalScore, // ✅ Updated totalScore correctly
                    rank: criticalData.rank || "",
                    criticalParameters: criticalData.critical_parameters || [],
                    cpParameters: criticalData.cp_parameters || []
                }); // Debugging log
            }
        }
    };
    

    AllCriticalPostionApi = async () => {
        this.setState({ isLoading: true });
        const AssessmentId = await getStorageData("criticalID") as string
        const headers = { token: this.state.token };
        const competencyMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.GetCriticalDashboardApiCallId = competencyMsg.messageId;

        competencyMsg.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));


        competencyMsg.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `${configJSON.criticalEndPoint}/${AssessmentId}`);

        competencyMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");

        runEngine.sendMessage(competencyMsg.id, competencyMsg);
    };


    NaviGateToAnyPage = (PageRoute: string) => {
        const navigateMsg = new Message(getName(MessageEnum.NavigationMessage));
        navigateMsg.addData(getName(MessageEnum.NavigationTargetMessage), PageRoute);
        navigateMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(navigateMsg);
    }
    navigateToEditPosition = async () => {
        await setStorageData("criticalID", this.state.criticalId)
        const navigateMsg = new Message(getName(MessageEnum.NavigationMessage));
        navigateMsg.addData(getName(MessageEnum.NavigationTargetMessage), "EditCriticalPosition");
        navigateMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(navigateMsg);
    }
    // Customizable Area End
}