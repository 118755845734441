import React from "react";

import {
    Backdrop,
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    CircularProgress,
    // Customizable Area Start
    Grid,
    IconButton,
    LinearProgress,
    styled,
    Typography,
    // Customizable Area End
} from "@mui/material";

// Customizable Area Start
import SentimentSatisfiedRoundedIcon from '@mui/icons-material/SentimentSatisfiedRounded';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined';
import CandidateDashboardController, {
    Props,
} from "./CandidateDashboardController";
import DashboardHeader from "./DashboardHeader.web";
import { check, donut_large, list } from "./assets";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import NoDataScreen from "../../../../packages/components/src/NoDataScreen";
// Customizable Area End


export default class CandidateDashboard extends CandidateDashboardController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start

    AssessmentDataListRender = () => {
        return (
            <Box style={{ padding: "40px 50px", }}>
                <Grid container spacing={2} alignItems={"center"} justifyContent={"space-between"}>
                    <Grid item xs={12} md={8}>
                        <Grid container className="GreyBox" alignItems={"center"} justifyContent={"space-between"}>
                            <Grid item xs={12} md={8}>
                                <Box className="WhiteBox">
                                    <div>
                                        <Typography variant="h6" style={{ fontWeight: "bold", color: "#044352" }}>
                                            Welcome Back, {this.state.CandidateName} 🤩
                                        </Typography>
                                        <Typography variant="h6" style={{
                                            fontFamily: 'Urbanist',
                                            fontWeight: 400,
                                            fontSize: '14px',
                                            color: "#044352"
                                        }}>
                                            Keep up the great work. We are sure that you can reach 100% soon.
                                        </Typography>
                                    </div>

                                </Box>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Box className="WhiteBox">
                                    <div style={{ width: "100%" }}>
                                        <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                                            <SentimentSatisfiedRoundedIcon />
                                            <ArrowForwardIcon />
                                        </div>
                                        <div>
                                            <Typography variant="h6" style={{ fontWeight: "bold" }}>
                                                {this.state.TotalPercentageScore}%
                                            </Typography>
                                        </div>
                                        <div>
                                            <Typography variant="h6" className="AverageScore">
                                                Average Score
                                            </Typography>
                                        </div>
                                    </div>
                                </Box>
                            </Grid>

                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Box className="GreyBox" style={{ padding: "10px 10px" }}>
                            <div style={{
                                display: "flex",
                                gap: "10px",
                                alignItems: "center",
                                width: "100%"
                            }}>
                                <CheckCircleOutlineIcon style={{ color: "#044352" }} />
                                <Typography className="PercentageTitle" variant="h6">
                                    {this.state.CompletionPercentage}% Assessment Path Complete!
                                </Typography>
                            </div>
                            <div style={{ marginTop: "10px" }}>
                                <LinearProgress sx={{
                                    height: "10px",
                                    borderRadius: "5px",
                                    backgroundColor: "#fff"
                                }} variant="determinate" value={this.state.CompletionPercentage} />
                            </div>
                            <div>
                                <Typography variant="h6" className="PercentageDesText">
                                    Please hurry and complete your remaining assessments. Let’s get this done!
                                </Typography>
                            </div>
                        </Box>
                    </Grid>
                </Grid>
                <Grid container style={{ marginTop: "15px", marginBottom: "15px" }} spacing={2} alignItems={"center"}>
                    <Grid item xs={12} md={10}>
                        <Box>
                            <Typography variant="h6" className="YourAssessmentText">
                                Your Assessments
                            </Typography>
                            <Typography variant="h6" className="YourAssessmentTextDes">
                                {this.state.AssessmentList.length} Assessments Assigned, {this.state.CompletedAssessmentCount} Completed Assessments, {this.state.CreatedAssessmentCount} New Assessment.
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <Box style={{ position: "relative", zIndex: 1000 }}>
                            <div style={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>
                                <Button data-test-id="PopUpButton" onClick={this.HandelPopUpOpenAndClose} endIcon={<KeyboardArrowDownIcon style={{ transform: this.handleCondition(this.state.IsPopUpOpen, "rotate(0deg)", 'rotate(180deg)'), color: "#0F172A" }} />} variant="contained" className="NewAssessmentButton">
                                    {this.state.ActiveTab}
                                </Button>
                            </div>
                            {
                                this.state.IsPopUpOpen &&
                                <div className="PopUp"
                                    style={{
                                        right: this.handleCondition(this.state.selectedLanguage.toLowerCase() === "english", "20px", ""),
                                        left: this.handleCondition(this.state.selectedLanguage.toLowerCase() === "english", "", "20px"),
                                    }}>
                                    {
                                        this.RenderPopList().map((item, index) => {
                                            return (
                                                <div
                                                    key={item.name}
                                                    data-test-id={`StatusButton`}
                                                    onClick={() => {
                                                        this.HandelStatus(item.name, item.value)
                                                    }}
                                                    className="PopUpButton"
                                                    style={{
                                                        backgroundColor: this.handleCondition(this.state.ActiveTab === item.name, '#E2E8F0', 'transparent'),
                                                    }}
                                                >
                                                    {item.name}
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            }
                        </Box>
                    </Grid>
                </Grid>
                <Grid container style={{ marginTop: "15px", marginBottom: "15px" }} spacing={2} alignItems={"stretch"}>
                    {this.state.AssessmentList.map((item, index) => {
                        let Labelbutton = ""
                        let StartButton = ""
                        let NeedStartText = ""
                        let LabelButtonClass = ""
                        let StartButtonClassName = ""
                        if (item.status === "created") {
                            Labelbutton = "New"
                            LabelButtonClass = "NewButtonClass"
                            StartButton = "Start"
                            StartButtonClassName = "StartButton"
                            NeedStartText = "Need To Start"
                        } else {
                            LabelButtonClass = "CompletedButtonClass"
                            StartButtonClassName = "CompletedButton"
                            Labelbutton = "Complated"
                            StartButton = "Complated"
                            NeedStartText = ""
                        }
                        return (
                            <Grid item xs={12} md={4} key={item.AssessmentId + item.CandidateId}>
                                <Card className={"AssessmentCard"}>
                                    <CardContent>
                                        <Grid container spacing={2} justifyContent={"space-between"} alignItems={"center"}>
                                            <Grid item xs={2}>
                                                <IconButton style={{ backgroundColor: "#E2E8F0" }}>
                                                    <img src={donut_large.default} alt="" style={{ height: "20px", width: "20px" }} />
                                                </IconButton>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <Button fullWidth variant="outlined" className={LabelButtonClass}>
                                                    {Labelbutton}
                                                </Button>
                                            </Grid>
                                        </Grid>
                                        <Box style={{ marginTop: "10px" }}>
                                            <Typography variant="h6" className="AssessmentTitle">
                                                {item.AssessmentTitle}
                                            </Typography>
                                        </Box>
                                        <Box style={{ marginTop: "10px", minHeight: "30px" }}>
                                            <Typography variant="h6" className="AssessmentDescription">
                                                {item.objective}
                                            </Typography>
                                        </Box>
                                        <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", marginTop: "10px" }}>
                                            <TimerOutlinedIcon />

                                            <Typography variant="h6" className="AssessmentTime">
                                                Begin the Assessment
                                            </Typography>
                                        </div>
                                        <Grid container justifyContent={"space-between"} spacing={2} className="ListAndTimerIconContainer">
                                            <Grid item xs={12} md={12}>
                                                <div className="IconContainer">
                                                    <div>
                                                        <IconButton style={{ backgroundColor: "#E2E8F0" }}>
                                                            <img src={list.default} alt="" style={{ height: "30px", width: "30px" }} />
                                                        </IconButton>
                                                    </div>
                                                    <div className="TextConatiner">
                                                        <Typography variant="h6" className="SimpleText">
                                                            Attended
                                                        </Typography>
                                                        <Typography variant="h6" className="SimpleText">
                                                            <span style={{ fontWeight: 700 }}>{item.TotalQuestions - item.UnattemptedQuestions}/{item.TotalQuestions}</span>  questions
                                                        </Typography>
                                                    </div>
                                                </div>
                                            </Grid>
                                        </Grid>
                                        <div className="ProgressAndTextContainer">
                                            <div className="PercentageAndTextContainer">
                                                <div style={{ display: "flex" }}>
                                                    <img src={check.default} alt="" />
                                                    <Typography className="BoldText" style={{ marginLeft: "4px" }}>
                                                        {item.PercentageScore} &#37;
                                                    </Typography>
                                                </div>
                                                <Typography className="BoldText">
                                                    {NeedStartText}
                                                </Typography>
                                            </div>
                                            <LinearProgress sx={{
                                                height: "8px",
                                                borderRadius: "5px",
                                                backgroundColor: "#fff"
                                            }} variant="determinate" value={item.PercentageScore} />
                                        </div>
                                    </CardContent>
                                    <CardActions>
                                        <Button data-test-id={"StartButton" + index} disabled={StartButton === "Complated"} fullWidth variant="contained" onClick={() => {
                                            this.StartTest(item)
                                        }} className={StartButtonClassName}>
                                            {StartButton}
                                        </Button>
                                    </CardActions>
                                </Card>
                            </Grid>
                        )
                    })
                    }

                </Grid>
            </Box>
        )
    }

    RenderNodataScreen = () => {
        return (
            <Box style={{ marginTop: "10px" }}>
                <NoDataScreen />
            </Box>
        )
    }

    RenderData = () => {
        if (this.state.IsLoading) {
            return (
                <Backdrop className={"backdrop"} open={this.state.IsLoading}>
                    <CircularProgress style={{ color: "#044352" }} />
                </Backdrop>
            )
        } else if (this.state.AssessmentList.length === 0) {
            return (
                this.RenderNodataScreen()
            )
        } else if (this.state.AssessmentList.length > 0) {
            return (
                this.AssessmentDataListRender()
            )
        }
    }

    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <MainWrapper>
                <DashboardHeader data-test-id="DashboardHeader" selectedMenu="Dashboard" SearchFuncation={(e) => {
                    this.setState({
                        SeachText: e.target.value
                    })
                }} CallSearchFuncation={() => {
                    this.GetAllCandidate()
                }} navigation={this.props.navigation} id={this.props.id}>
                    {this.RenderData()}
                </DashboardHeader>
            </MainWrapper >
            // Customizable Area End
        );
    }
}

// Customizable Area Start
export const MainWrapper = styled(Box)({
    "& .MuiLinearProgress-bar": {
        backgroundColor: "#22C55E",
        borderRadius: "5px"
    },
    "& .backdrop": {
        color: '#044352',
        backgroundColor: "#fff",
    },
    "& .GreyBox": {
        backgroundColor: "#E2E8F0",
        padding: "10px 10px",
        boxShadow: "0px 8px 32px 0px #0000000F",
        borderRadius: '6px',
        minHeight: "120px",
        height: "100%",
    },
    "& .WhiteBox": {
        padding: "20px",
        backgroundColor: "#FFFFFF",
        borderRadius: "10px",
        margin: "0px 10px",
        display: "flex",
        alignItems: "center",
        minHeight: "70px",
        height: "100%",
    },
    "& .AverageScore": {
        fontFamily: 'Urbanist',
        fontWeight: 400,
        fontSize: '14px'
    },
    "& .PercentageTitle": {
        fontFamily: 'Urbanist',
        fontWeight: 700,
        fontSize: '20px'
    },
    "& .PercentageDesText": {
        fontFamily: 'Urbanist',
        fontWeight: 400,
        fontSize: '14px'
    },
    "& .YourAssessmentText": {
        fontFamily: 'Urbanist',
        fontWeight: 600,
        fontSize: '25px',
    },
    "& .YourAssessmentTextDes": {
        fontFamily: 'Urbanist',
        fontWeight: 400,
        fontSize: '16px',
        color: "#8A96A8",
    },
    "& .SmallIconAndStatusContainer": {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "10px 0px",
    },
    "& .AssessmentTitle": {
        fontFamily: 'Urbanist',
        fontWeight: 700,
        fontSize: '24px',
        color: "#0F172A"
    },
    "& .AssessmentDescription": {
        fontFamily: 'Urbanist',
        fontWeight: 400,
        fontSize: '12px',
        color: "#0F172A"
    },
    "& .AssessmentTime": {
        fontFamily: 'Urbanist',
        fontWeight: 700,
        fontSize: '12px',
        color: "#0F172A",
        marginLeft: "5px"
    },
    "& .ListAndTimerIconContainer": {
        marginTop: "10px",
        "& .IconContainer": {
            display: "flex",
            alignItems: "center",
            "& .TextConatiner": {
                marginLeft: "10px"
            }
        },
        "& .SimpleText": {
            fontFamily: 'Urbanist',
            fontWeight: 400,
            fontSize: '14px',
        },
    },
    "& .ProgressAndTextContainer": {
        marginTop: "20px",
        "& .PercentageAndTextContainer": {
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "5px",
            "& .BoldText": {
                fontFamily: 'Urbanist',
                fontWeight: 700,
                fontSize: '12px',
            }
        }
    },
    "& .StartButton": {
        backgroundColor: "#044352", fontFamily: 'Urbanist',
        fontWeight: 500,
        fontSize: '16px',
        textTransform: "capitalize",
        "&:hover": {
            backgroundColor: "#044352",
        },
    },
    "& .CompletedButton": {
        backgroundColor: "#fff",
        border: "1px solid #CBD5E1",
        fontFamily: 'Urbanist',
        fontWeight: 500,
        fontSize: '16px',
        color: "#475569",
        textTransform: "capitalize",
        "&:hover": {
            backgroundColor: "#CBD5E1",
        },
    },
    "& .NewAssessmentButton": {
        backgroundColor: "#F1F5F9",
        color: "#64748B",
        minWidth: "100px",
        borderRadius: "50px",
        display: "flex",
        justifyContent: "space-between",
        textTransform: "capitalize",
        height: "40px",
        "&:hover": {
            backgroundColor: "#FFFFFF",
            borderColor: "#94A3B8",
        },
    },
    "& .PopUp": {
        position: 'absolute',
        backgroundColor: 'white',
        border: '1px solid #ccc',
        borderRadius: '4px',
        zIndex: 1000,
        width: "150px",
    },
    "& .PopUpButton": {
        color: "#0F172A",
        fontFamily: "Urbanist",
        fontSize: "14px",
        fontWeight: 400,
        padding: '15px 30px 15px 30px',
        cursor: 'pointer',
    },
    "& .NewButtonClass": {
        color: "#DC2626", borderColor: "#FEE2E2"
    },
    "& .CompletedButtonClass": {
        color: "#059669", borderColor: "#D1FAE5  "
    }
})
// Customizable Area End