import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { getStorageData } from "framework/src/Utilities";

interface CriticalSubParameter {
    id: string;
    strategicSubParam: string;
    strategicScoreOutOf: string;
    strategicScoreGenerated: string;
    error?: string;
}

interface CriticalParameter {
    name: string;
    subParameters: CriticalSubParameter[];
}

interface CriticalAPIResponse {
    message: string;
    error: string;
    critical_position: {
        data: {
            id: string;
            type: string;
            attributes: {
                created_at: string;
                job_description_id: number;
                total_score: string;
                rank: string;
                job_title: string;
                critical_parameters: { name: string; score_ratio: string }[];
                cp_parameters: {
                    data: {
                        id: string;
                        type: string;
                        attributes: {
                            name: string;
                            max_score: string;
                            calculated_score: string;
                            score_ratio: string;
                            critical_sub_parameters: {
                                data: {
                                    id: string;
                                    type: string;
                                    attributes: {
                                        name: string;
                                        score_out_of: string;
                                        score_generated: string;
                                    };
                                };
                            }[];
                        };
                    };
                }[];
            };
        };
    };
}

// Customizable Area End

export const configJSON = require("./config");
export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    token: string;
    errorMsg: string;
    selectedLanguage: string;
    selectedJobTitle: string;
    criticalId: string;
    jobTitle: string;
    rank: string;
    totalScore: string;
    openSuccess: boolean;
    strategicParams: {
        [key: string]: { 
            id:string; 
            name?: string;
        }
    },
    duplicateStrategicParam: {
        [key: string]: {
            id:string;
            strategicSubParam: string;
            strategicScoreOutOf: string;
            strategicScoreGenerated: string;
            error?: string;
        }[]
    };
    // Customizable Area End

}

interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class EditCriticalPositionController extends BlockComponent<
    Props,
    S,
    SS
> {

    // Customizable Area Start
    duplicateStrategicObj!: {
        id: "",
        strategicSubParam: "",
        strategicScoreOutOf: "",
        strategicScoreGenerated: "",
        error?: ""
    }

    getCriticalApiCallId: string = "";
    editCriticalApiCallId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.CountryCodeMessage)
        ];

        this.state = {
            token: "",
            errorMsg: "",
            selectedLanguage: "",
            selectedJobTitle: "",
            criticalId: "",
            jobTitle: "",
            rank: "",
            totalScore: "",
            openSuccess: false,
            duplicateStrategicParam: {},
            strategicParams: {}
        };

        // Customizable Area End
        runEngine.attachBuildingBlock(this, this.subScribedMessages);
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        runEngine.debugLog("Message Recived", message);
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (apiRequestCallId === this.getCriticalApiCallId) {
                this.getCriticalApiResponse(responseJson)
            }
            if (apiRequestCallId === this.editCriticalApiCallId) {
                this.editCriticalApiResponse(responseJson)
            }
        }
        // Customizable Area End
    }

    // Customizable Area Start
    async componentDidMount() {
        super.componentDidMount();

        const language = await getStorageData("language") || "English";
        this.setState({ selectedLanguage: language });

        const signInResponse = await getStorageData("signInResponse");
        const parsedSignInResponse = JSON.parse(signInResponse)
        this.setState({
            token: parsedSignInResponse.meta?.token,
        })

        const criticalId = await getStorageData("criticalID");
        this.setState({ criticalId }, () => {
            this.getCriticalPositionApiCall()
        })
    }

    editCriticalApiResponse = (responseJson: CriticalAPIResponse) => {
        if (responseJson && !responseJson.error) {
            this.setState({ openSuccess: true})
        }
    };

    getCriticalApiResponse = (responseJson: CriticalAPIResponse) => {
        if (responseJson && !responseJson.error) {
            this.setState({
                jobTitle: responseJson.critical_position.data.attributes.job_title,
                rank: responseJson.critical_position.data.attributes.rank,
                totalScore: responseJson.critical_position.data.attributes.total_score
            })
    
            const stratagicIds: {[key: string] : { id: string }} = {};
            responseJson.critical_position.data.attributes.cp_parameters.forEach(data =>{
                stratagicIds[data.data.attributes.name] = {id : data.data.id};
            })

            this.setState({ strategicParams: stratagicIds})            

            const duplicateStrategicParam: Record<string, CriticalSubParameter[]> = {};
            responseJson.critical_position.data.attributes.cp_parameters.forEach(param =>{
                const paramName = param.data.attributes.name
                const subParams = param.data.attributes.critical_sub_parameters.map(subParam => ({
                    id: subParam.data.id,
                    strategicSubParam: subParam.data.attributes.name,
                    strategicScoreOutOf: subParam.data.attributes.score_out_of,
                    strategicScoreGenerated: subParam.data.attributes.score_generated
                }))

                duplicateStrategicParam[paramName] = subParams;
            })
            this.setState({ duplicateStrategicParam })
        } else if (responseJson && responseJson.error) {
            this.setState({
                errorMsg: responseJson.error
            });
        }
    }

    getCriticalPositionApiCall = () => {
        const headers = {
            "Content-Type": "application/json",
            "token": this.state.token
        };
        const getViewMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getCriticalApiCallId = getViewMsg.messageId;
        getViewMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        getViewMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.criticalEndPoint}/${this.state.criticalId}`
        );
        getViewMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getApiMethodType
        );
        runEngine.sendMessage(getViewMsg.id, getViewMsg);
    }


    editCriticalPositionApiCall = () => {
        const headers = {
            "token": this.state.token,
            "Content-Type": "application/json"
        };

        const rowBody = {
            critical_position: {
                critical_parameters_attributes: Object.keys(this.state.duplicateStrategicParam).map(paramName => {
                    const paramData = this.state.strategicParams[paramName];
                    return {
                        id: paramData ? paramData.id : null,
                        name: paramData?.name || paramName,
                        critical_sub_parameters_attributes: this.state.duplicateStrategicParam[paramName].map(subParam => ({
                            ...(subParam.id ? { id: subParam.id } : {}),
                            name: subParam.strategicSubParam,
                            score_out_of: subParam.strategicScoreOutOf,
                            score_generated: subParam.strategicScoreGenerated
                        }))
                    };
                })
            }
        };
       
        const getMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.editCriticalApiCallId= getMsg.messageId;
        getMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        getMsg.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(rowBody)
        );
        getMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.criticalEndPoint}/${this.state.criticalId}`
        );
        getMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.putApiMethodType
        );
        runEngine.sendMessage(getMsg.id, getMsg);
    }

    handleCloseSuccess = () => {
        this.setState({ openSuccess: false })
    }

    handleParameterNameChange = (paramType: string, newName: string) => {
        this.setState(prevState => ({
            strategicParams: {
                ...prevState.strategicParams,
                [paramType]: {
                    ...prevState.strategicParams[paramType],
                    name: newName 
                }
            }
        }));
    };
    
    handleInputChange = (
        type: string,
        index: number,
        field: "strategicSubParam" | "strategicScoreOutOf" | "strategicScoreGenerated",
        value: string
    ) => {
        this.setState(prevState => {
            const updatedParams = { ...prevState.duplicateStrategicParam };
    
            if (!updatedParams[type]) {
                updatedParams[type] = [];
            }
    
            if (!updatedParams[type][index]) {
                updatedParams[type][index] = {
                    id: "",
                    strategicSubParam: "",
                    strategicScoreOutOf: "",
                    strategicScoreGenerated: "",
                    error: ""
                };
            }
    
            updatedParams[type][index][field] = value;
    
            if (
                field === "strategicScoreGenerated" &&
                parseFloat(value) > parseFloat(updatedParams[type][index]?.strategicScoreOutOf || "0")
            ) {
                updatedParams[type][index].error = "Score Generated cannot be greater than Score Out Of";
            } else {
                updatedParams[type][index].error = "";
            }
    
            return { duplicateStrategicParam: updatedParams };
        });
    };  
    
    addStrategicSubParam = (type: string) => {
        const duplicateStrategicParam = this.state.duplicateStrategicParam[type];
        const updatedDuplicate = {
            ...this.state.duplicateStrategicParam,
            [type]: [
                ...duplicateStrategicParam,
                this.duplicateStrategicObj
            ]
        }

        this.setState({
            duplicateStrategicParam: updatedDuplicate,
        })
    }
    
    backToDashBoard = () => {
        const navigateMsg = new Message(getName(MessageEnum.NavigationMessage));
        navigateMsg.addData(getName(MessageEnum.NavigationTargetMessage), "CriticalDashboardView");
        navigateMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(navigateMsg);
    }
    navigateToViewUserPosition = () => {
        const navigateMsg = new Message(getName(MessageEnum.NavigationMessage));
        navigateMsg.addData(getName(MessageEnum.NavigationTargetMessage), "ViewCriticalDashboard");
        navigateMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(navigateMsg);
    }
    // Customizable Area End
}