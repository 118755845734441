// Customizable Area Start
import React from "react";
import {
    Box,
    Grid,
    Typography,
    Button,
    CircularProgress,
    InputAdornment,
    IconButton,
    TextField,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Pagination
} from "@mui/material";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { arrow_Left, calendar, CheckIcon, UserIcon } from "./assets";
import Calendar from "react-calendar";
import { DashboardHeaderWeb as DashboardHeader } from "../../dashboard/src/DashboardHeader.web";
import { withTranslation } from "react-i18next";
import ViewScoreCardController, {
    Props,
} from "./ViewScoreCardController";
import { styled } from "@mui/system";
import { CustomDatePicker, HeaderContainer } from "./AssessmentTestDashbord.web";
import NoDataScreen from "../../../../packages/components/src/NoDataScreen";
import moment from "moment";
// Customizable Area End

export default class ViewScoreCard extends ViewScoreCardController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start

    TableDataScreen = () => {
        return (
            <Grid container justifyContent={"center"}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <HeaderContainer>
                        <Grid container spacing={2} alignItems={"center"}>
                            <Grid item xs={12} sm={12} md={4} lg={4}>
                                <Box data-test-id="BackToJobDescription"
                                    style={{
                                        display: "flex", alignItems: "center", justifyContent: "flex-start",
                                        marginBottom: "20px",
                                        cursor: "pointer",
                                    }}
                                    onClick={() => {
                                        this.NaviGateToAnyPage("AssessmentTestDashbord");
                                    }}
                                >
                                    <Box className="BackArrowBox">
                                        <img src={arrow_Left.default}
                                            alt="Back Arrow"
                                        />
                                    </Box>
                                    <Typography className="HeadingTitle">View Score Card</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={12} md={8} lg={8}>
                                <Grid container spacing={1} justifyContent={"flex-end"}>
                                    <Grid item xs={12} sm={12} md={4} lg={4}>
                                        <Button
                                            data-test-id="ExportAllButton"
                                            className="OutlinedButton"
                                            variant="outlined"
                                            fullWidth
                                            onClick={() => {
                                                this.listAllUsersApiCall(true)
                                            }}
                                        >
                                            <Typography className="ButtonText">
                                                Export All
                                            </Typography>
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={4} lg={4} style={{ position: "relative" }}>
                                        <CustomDatePicker sx={{ flex: 1 }}>
                                            <TextField
                                                fullWidth
                                                onClick={this.openCalendar}
                                                data-test-id="calendar_field"
                                                value={this.formatDateRange(this.state.selectedDate) || this.TraslationFuncation("CompetencyDashboard.SelectStartDateEndDate")}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <IconButton onClick={this.openCalendar}>
                                                                <img src={calendar.default} onClick={this.openCalendar} />
                                                            </IconButton>
                                                        </InputAdornment>
                                                    ),
                                                    inputProps: {
                                                        sx: {
                                                            color: this.handleCondition(this.state.selectedDate === null, '#334155', '#6C7B89'),
                                                            fontWeight: this.handleCondition(this.state.selectedDate === null, 700, 400),
                                                            fontSize: "16px",
                                                            paddingRight: '0px',
                                                            fontFamily: "Urbanist",
                                                            paddingLeft: "8px"
                                                        },
                                                    },
                                                    sx: { justifyContent: 'center', },
                                                }}
                                                InputLabelProps={{ sx: { textAlign: 'center', width: '100%' }, }}
                                            />
                                            {this.state.calendarOpen && (
                                                <div
                                                    className={`${this.handleCondition(this.state.selectedLanguage.toLowerCase() === "english", " reactCalendarBox ", " reactCalendarBoxArabic ")}`}
                                                >
                                                    <Calendar
                                                        selectRange
                                                        data-test-id="calendar"
                                                        onChange={(e) => {
                                                            this.handleDateChange(e as [Date, Date]);
                                                        }}
                                                        value={this.state.tempDate || this.state.selectedDate}
                                                    />
                                                    <div className="action-button-div">
                                                        <Button
                                                            className="action-btn cancel-btn"
                                                            data-test-id="cancel_calendar_btn"
                                                            onClick={this.handleCalendarCancelBtn}
                                                        >
                                                            {this.TraslationFuncation("CompetencyDashboard.Cancel")}
                                                        </Button>
                                                        <Button
                                                            onClick={this.handleCalendarSaveBtn}
                                                            data-test-id="calender_save_btn"
                                                            className={this.handleCondition(this.state.tempDate === null, `action-btn save-btn active`, `action-btn save-btn`) as string}
                                                        >
                                                            {this.TraslationFuncation("CompetencyDashboard.Apply")}
                                                        </Button>
                                                    </div>
                                                </div>
                                            )}
                                        </CustomDatePicker>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} lg={4}>
                                <Box className="GreyContainer">
                                    <Box>
                                        <img
                                            src={UserIcon.default}
                                            className="GreyMainIcon"
                                            alt=""
                                        />
                                    </Box>
                                    <Box className="GreyTextContainer">
                                        <Box>
                                            <Typography className="GreyContainerheading">
                                                Total Assessments
                                            </Typography>
                                        </Box>
                                        <Box>
                                            <Typography className="GreyContainerheading">
                                                {this.state.TotalRecord}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} lg={4}>
                                <Box className="GreyContainer">
                                    <Box>
                                        <img src={CheckIcon.default}
                                            className="GreyMainIcon"
                                            alt="" />
                                    </Box>
                                    <Box className="GreyTextContainer">
                                        <Box>
                                            <Typography className="GreyContainerheading">
                                                Pending Assessments
                                            </Typography>
                                        </Box>
                                        <Box>
                                            <Typography className="GreyContainerheading">
                                                {this.state.PendingAssessments}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} lg={4}>
                                <Box className="GreyContainer">
                                    <Box>
                                        <img className="GreyMainIcon" alt="" src={CheckIcon.default} />
                                    </Box>
                                    <Box className="GreyTextContainer">
                                        <Box>
                                            <Typography className="GreyContainerheading">
                                                Average Score
                                            </Typography>
                                        </Box>
                                        <Box>
                                            <Typography className="GreyContainerheading">
                                                {this.state.OverallAveragePercentage}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </HeaderContainer>
                </Grid>
                <Grid style={{ marginTop: "20px" }} item xs={12} sm={12} md={12} lg={12}>
                    <HeaderContainer>
                        <Grid container spacing={2} alignItems={"center"}>
                            <Grid item xs={12} sm={12} md={4} lg={4}>
                                <Typography variant="h5" className="HeadingOne">
                                    Assessments
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} md={8} lg={8}>
                                <Grid container spacing={1} justifyContent={"flex-end"}>
                                    <Grid item xs={12} sm={12} md={4} lg={4}>
                                        <Box>
                                            <Button
                                                data-test-id="AllAssessments"
                                                className="OutlinedButton"
                                                variant="outlined"
                                                fullWidth
                                                endIcon={<KeyboardArrowDownIcon style={{ color: "#0F172A" }} />}
                                            >
                                                <Typography className="ButtonText">
                                                    All Assessments
                                                </Typography>
                                            </Button>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <TableContainer sx={{ mt: 2 }}>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell className="TableTilteText" align="center" sx={{ paddingLeft: 0 }}>
                                                    <Box className="tableCellBox">
                                                        Candidate Name
                                                    </Box>
                                                </TableCell>
                                                <TableCell className="TableTilteText" align="center">
                                                    <Box className="tableCellBox">
                                                        Candidate ID
                                                    </Box>
                                                </TableCell>
                                                <TableCell className="TableTilteText" align="center">
                                                    <Box className="tableCellBox">
                                                        Job Title
                                                    </Box>
                                                </TableCell>
                                                <TableCell className="TableTilteText" align="center">
                                                    <Box className="tableCellBox">
                                                        Assessment Name
                                                    </Box>
                                                </TableCell>
                                                <TableCell className="TableTilteText" align="center">
                                                    Assessment Description
                                                </TableCell>
                                                <TableCell className="TableTilteText" align="center">
                                                    Competency Type
                                                </TableCell>
                                                <TableCell className="TableTilteText" align="center">
                                                    Total Questions
                                                </TableCell>
                                                <TableCell
                                                    data-test-id="delete_selected"
                                                    className="TableTilteText" align="center">
                                                    Score
                                                </TableCell>
                                                <TableCell
                                                    data-test-id="delete_selected"
                                                    className="TableTilteText" align="center">
                                                    Assessment Date
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                this.state.ViewScoreCardListData.length > 0 &&
                                                this.state.ViewScoreCardListData.map((item, index) => {
                                                    let BoxData = this.BoxCondition(item.PercentageScore)
                                                    return (
                                                        <TableRow>
                                                            <TableCell className="TableTilteText" sx={{ paddingLeft: 0 }}>
                                                                <Box className="tableCellBox" style={{ textAlign: "center" }}>
                                                                    {item.FirstName}
                                                                </Box>
                                                            </TableCell>
                                                            <TableCell className="TableTilteText" sx={{ paddingLeft: 0 }}>
                                                                <Box className="tableCellBox" style={{ textAlign: "center" }}>
                                                                    {item.CandidateId}
                                                                </Box>
                                                            </TableCell>
                                                            <TableCell className="TableTilteText" sx={{ paddingLeft: 0 }}>
                                                                <Box className="tableCellBox" style={{ textAlign: "center" }}>
                                                                    {item.JobTitle}
                                                                </Box>
                                                            </TableCell>
                                                            <TableCell className="TableTilteText" sx={{ paddingLeft: 0 }}>
                                                                <Box className="tableCellBox" style={{ textAlign: "center" }}>
                                                                    {item.AssessmentTitle}
                                                                </Box>
                                                            </TableCell>
                                                            <TableCell className="TableTilteText" sx={{ paddingLeft: 0 }}>
                                                                <Box className="tableCellBox" style={{ textAlign: "center" }}>
                                                                    {item.Objective}
                                                                </Box>
                                                            </TableCell>
                                                            <TableCell className="TableTilteText" sx={{ paddingLeft: 0 }} style={{ textAlign: "center" }}>
                                                                <Box className="tableCellBox">
                                                                    {Array.isArray(item.CompetencyName) ? item.CompetencyName.join(" ,") : item.CompetencyName}
                                                                </Box>
                                                            </TableCell>
                                                            <TableCell className="TableTilteText" sx={{ paddingLeft: 0 }} style={{ textAlign: "center" }}>
                                                                <Box className="tableCellBox">
                                                                    {item.TotalQuestions}
                                                                </Box>
                                                            </TableCell>
                                                            <TableCell className="TableTilteText" sx={{ paddingLeft: 0 }}>
                                                                <Box className="tableCellBox" style={{ textAlign: "center", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                                    {
                                                                        Array(4).fill(null).map((_, index) => {
                                                                            return (
                                                                                <Box className="tableCellBox" style={{
                                                                                    width: '11px',
                                                                                    height: '8',
                                                                                    marginRight: '2px',
                                                                                    marginLeft: '2px',
                                                                                    backgroundColor: index <= BoxData.Box ? BoxData.Color : "#F8FAFC",
                                                                                }} />
                                                                            )
                                                                        })
                                                                    }

                                                                </Box>
                                                            </TableCell>
                                                            <TableCell className="TableTilteText" sx={{ paddingLeft: 0 }}>
                                                                <Box className="tableCellBox" style={{ textAlign: "center" }}>
                                                                    {moment(item.AssessmentCompleteDate).format('DD/MM/YYYY')}
                                                                </Box>
                                                            </TableCell>
                                                        </TableRow>
                                                    )
                                                })
                                            }
                                        </TableBody>
                                    </Table>
                                    <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2, width: "100%" }}>
                                        <Pagination
                                            color="primary"
                                            boundaryCount={1}
                                            siblingCount={0}
                                            // page={this.state.PaginationPageCount}
                                            style={{
                                                direction: "ltr"
                                            }}
                                            data-test-id="pagination"
                                        // onChange={this.PaginationFuncation}
                                        // count={Math.ceil(this.state.TotalRecord / this.state.PerPageDataRender)}
                                        />
                                    </Box>
                                </TableContainer>
                            </Grid>
                        </Grid>
                    </HeaderContainer>
                </Grid>
            </Grid>
        )
    }

    RenderAllScreens = () => {
        if (this.state.isLoading) {
            return <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80vh' }}>
                <CircularProgress style={{ color: "#044352" }} />
            </Box>
        } else {
            if (this.state.ViewScoreCardListData.length > 0) {
                return this.RenderTable()
            } else {
                return <NoDataScreen CustomJsk={
                    <Box style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                        <Typography className="noDataNextText">
                            It looks like there’s nothing here yet. Add your <b>Assessments</b> for greater efficiency and growth.
                        </Typography>
                    </Box>
                } />
            }
        }

    }

    RenderTable = () => {
        return (
            <Grid container>
                {this.TableDataScreen()}
            </Grid>
        )
    }

    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <DashboardHeader selectedMenu="Assessments" navigation={this.props.navigation} id={this.props.id}>
                <ViewScoreCardStyle>
                    {this.RenderAllScreens()}
                </ViewScoreCardStyle>
            </DashboardHeader>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
export const ViewScoreCardWeb = withTranslation()(ViewScoreCard)

export const ViewScoreCardStyle = styled(Box)({
    display: "flex",
    justifyContent: "center",
    marginTop: "42px",
    padding: "0px 30px",
    "& .HeadingTitle": {
        fontFamily: 'Urbanist',
        fontSize: '25px',
        fontWeight: 600,
        lineHeight: '30px'
    },
    "& .SelectedButton": {
        paddingLeft: 0,
        fontSize: "18px",
        fontWeight: 700,
        lineHeight: "26.46px",
        color: "#64748B",
        textTransform: "none",
        padding: "8px",
        borderRadius: "4px",
        border: "1px solid #64748B",
        background: "#F1F5F9",
    },
    "& .UnSelectedButton": {
        fontSize: "18px",
        fontWeight: 700,
        lineHeight: "26.46px",
        color: "#64748B",
        textTransform: "none",
        padding: "8px",
        paddingLeft: 0,
        "&:hover": {
            background: "none"
        }
    },
    "& .AssignUsersBtn": {
        padding: "8px 60px",
        height: "58px",
        borderRadius: "4px",
        background: "#044352",
    },
    "& .BackArrowBox": {
        display: "flex", alignItems: "center", justifyContent: "center",
        width: "47px", height: "47px", marginRight: "10px", border: "1px solid #ECECEC", borderRadius: "50%",
        cursor: "pointer"
    }
})

// Customizable Area End