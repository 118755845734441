// Customizable Area Start
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";

import React, { ReactNode } from "react";
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
import { i18n } from "i18next";

interface AssessmentResponse {
    message: string;
    assessment: {
        data: {
            id: string;
            type: string;
            attributes: {
                time_allocation: number;
                id: number;
                title: string;
                objective: string;
                number_of_questions: number;
                competencies: {
                    description: string;
                    id: number;
                    job_title: string;
                    created_at: string;
                    competency_name: string;
                    job_description_id: number;
                    status: boolean;
                    updated_at: string;
                    competency_type_id: number;
                    competency_dictionary_id: number;
                }[];
                status: string;
                creator: {
                    id: number; name: string;
                };
                difficulty_levels: {
                    id: number; name: string;
                }[];
                focus_areas: {
                    id: number; name: string;
                }[];
                question_formats: {
                    id: number;
                    name: string;
                }[];
                job_description: {
                    id: number; job_title: string;
                };
                questions: {
                    data: {
                        id: string;
                        type: string;
                        attributes: {
                            question_bank_id: number;
                            assessment_id: number;
                            id: number;
                            text: string;
                            question_format_name: string;
                            created_at: string;
                            job_description: {
                                id: number; name: string;
                            };
                            competencies: [
                                {
                                    id: number; competency_name: string;
                                }
                            ];
                            difficulty_level: {
                                id: number;
                                name: string;
                            };
                            answer: {
                                id: number;
                                answer_type: string;
                                choices: {
                                    id: number; option_text: string; correct: boolean;
                                }[];
                                correct_choices: {
                                    id: number; option_text: string; correct: boolean;
                                }[];
                                text_response: string;
                            };
                        };
                    };
                }[];
            };
        };
    };
}

// Customizable Area End

export const configJSON = require("./config");
export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    selectedMenu?: string;
    children?: ReactNode;
    i18n?: i18n;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    token: string;
    isLoading: boolean;
    selectedLanguage: string;
    QuestionAnswerData: {
        question: {
            id: string;
            text: string;
            question_format_id: string;
            difficulty_level_id: string;
            job_description_id: string;
            competency_ids: string[];
        };
        choices: {
            option_text: string;
            correct: boolean;
        }[];
    }[];
    AssessmentName: string;
    QuestionCount: string,
    currentPage: number;
    JobTitle: string,
    DifficultyLevel: Array<string>,
    FocusAreas: string[],
    Competencies: Array<string>,
    QuestionFormats: Array<string>,
    EditFormReinitialize: boolean,
    QuestionApiId: string,
    DeleteQuestionModel: boolean,
    SuccessModel: boolean,

    // Customizable Area End

}

interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class EditAssessmentCotroller extends BlockComponent<
    Props,
    S,
    SS
> {

    // Customizable Area Start
    GetAssessmentData: string = "";
    DeleteQuestionApiId: string = "";
    SaveChangesApiId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.CountryCodeMessage)
        ];

        this.state = {
            token: '',
            selectedLanguage: '',
            isLoading: false,
            QuestionAnswerData: [],
            currentPage: 1,
            FocusAreas: [],
            QuestionCount: "",
            AssessmentName: "",
            JobTitle: "",
            DifficultyLevel: [],
            Competencies: [],
            QuestionFormats: [],
            EditFormReinitialize: false,
            QuestionApiId: "",
            DeleteQuestionModel: false,
            SuccessModel: false,
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this, this.subScribedMessages);
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        runEngine.debugLog("Message Recived", message);
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const ApiCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            const ApiResponse = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            this.GetAssessmentApiResponse(ApiCallId, ApiResponse);
            this.DeleteQuestionApiResponse(ApiCallId, ApiResponse);
            this.SaveChangeApiCallResponse(ApiCallId, ApiResponse);
        }
        // Customizable Area End
    }

    // Customizable Area Start



    async componentDidMount() {
        const token = await getStorageData('signInResponse', true);
        this.setState({
            token: token?.meta?.token
        })
        const language = await getStorageData("language") || "English";
        this.setState({ selectedLanguage: language });
        this.GetAssessmentDataFuncation()
    }





    GetAssessmentDataFuncation = async () => {
        const AssessmentId = await getStorageData("assessmentId") as string
        this.setState({ isLoading: true });
        const headers = {
            "token": this.state.token
        };
        const GetAssessment = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.GetAssessmentData = GetAssessment.messageId;
        GetAssessment.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        GetAssessment.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.GetAllAssessment}/${AssessmentId}`
        );
        GetAssessment.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getAssessementMethod
        );
        runEngine.sendMessage(GetAssessment.id, GetAssessment);
    }

    handleCondition = (condition: boolean, truePart: React.ReactNode, falsePart: React.ReactNode) => {
        return condition ? truePart : falsePart
    }

    GetAssessmentApiResponse = (ApiCallId: string, Response: AssessmentResponse) => {
        if (ApiCallId === this.GetAssessmentData) {
            const QuestionAnswerData = Response.assessment.data.attributes.questions.map((item) => {
                return {
                    question: {
                        id: `${item.data.attributes.id}`,
                        text: item.data.attributes.text,
                        question_format_id: item.data.attributes.question_format_name,
                        difficulty_level_id: `${item.data.attributes.difficulty_level.id}`,
                        job_description_id: `${item.data.attributes.job_description.id}`,
                        competency_ids: item.data.attributes.competencies.map((i) => `${i.id}`),
                    },
                    choices: item.data.attributes.answer.answer_type === "Multiple Choice" ? item.data.attributes.answer.choices?.map((item) => {
                        return {
                            option_text: item.option_text,
                            correct: item.correct
                        }
                    }) : [{
                            option_text: item.data.attributes.answer.text_response,
                            correct: true
                        }]
                }
            })
            if (QuestionAnswerData.length !== 0) {
                this.setState({
                    QuestionAnswerData: QuestionAnswerData,
                    AssessmentName: Response.assessment.data.attributes.title,
                    JobTitle: Response.assessment.data.attributes.job_description.job_title,
                    DifficultyLevel: Response.assessment.data.attributes.difficulty_levels.map((i) => i.name),
                    FocusAreas: Response.assessment.data.attributes.focus_areas.map((i) => i.name),
                    QuestionCount: `${Response.assessment.data.attributes.number_of_questions}`,
                    Competencies: Response.assessment.data.attributes.competencies.map((item) => item.competency_name),
                    QuestionFormats: Response.assessment.data.attributes.question_formats.map((item) => item.name),
                })
            }
            this.setState({ isLoading: false });
        }
    }

    NaviGateToAnyPage = (PageRoute: string) => {
        const navigateMsg = new Message(getName(MessageEnum.NavigationMessage));
        navigateMsg.addData(getName(MessageEnum.NavigationTargetMessage), PageRoute);
        navigateMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(navigateMsg);
    }

    navigateToEdit = async (id: string) => {
        await setStorageData("editQuestionId", JSON.stringify(id))
        setStorageData("NaviGateFrom", "EditAssessment")
        const navigateMsg = new Message(getName(MessageEnum.NavigationMessage)); navigateMsg.addData(getName(MessageEnum.NavigationTargetMessage), "EditAseessmentQuestions"); navigateMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props); this.send(navigateMsg);
    }

    TraslationFuncation = (key: string) => {
        if (this.props.i18n) {
            return this.props.i18n.t(key)
        }
        else {
            return key
        }
    }

    DeleteQuestionApiCall = async () => {
        const AssessmentId = await getStorageData("assessmentId") as string
        this.setState({ isLoading: true });
        const headers = {
            "token": this.state.token
        };
        const DeleteAssessment = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.DeleteQuestionApiId = DeleteAssessment.messageId;
        DeleteAssessment.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        DeleteAssessment.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.getDeleteAssessmentQuestionUrl(AssessmentId, this.state.QuestionApiId)}`
        );
        DeleteAssessment.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.DeleteMethod
        );
        runEngine.sendMessage(DeleteAssessment.id, DeleteAssessment);
    }

    DeleteQuestionApiResponse = (ApiCallId: string, Response: { message: string }) => {
        if (ApiCallId === this.DeleteQuestionApiId) {
            if (Response.message === "Question deleted successfully") {
                this.setState({ isLoading: false });
                this.setState({ DeleteQuestionModel: false, QuestionApiId: "" });
                this.GetAssessmentDataFuncation();
            } else {
                this.setState({ isLoading: false });
            }
        }
    }

    CloseDeleteModel = () => {
        this.setState({ DeleteQuestionModel: false, QuestionApiId: "" })
    }

    CloseModelSuccessModel = () => {
        this.setState({ SuccessModel: false })
        this.NaviGateToAnyPage("AssessmentTestDashbord")
    }

    SaveChangesApiCall = async () => {
        const AssessmentId = await getStorageData("assessmentId") as string
        this.setState({ isLoading: true });
        const headers = {
            "token": this.state.token
        };
        const httpBody = {
            "status": "created"
        }
        const Assessment_Save = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.SaveChangesApiId = Assessment_Save.messageId;
        Assessment_Save.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        Assessment_Save.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.StatusChangeEndPoint}/${AssessmentId}`
        );
        Assessment_Save.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        )
        Assessment_Save.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.PUT
        );
        runEngine.sendMessage(Assessment_Save.id, Assessment_Save);

    }

    SaveChangeApiCallResponse = (ApiCallId: string, Response: { message: string }) => {
        if (this.SaveChangesApiId === ApiCallId) {
            if (Response.message === "Assessment update successfully") {
                this.setState({ isLoading: false });
                this.setState({ SuccessModel: true })
            } else {
                this.setState({ isLoading: false });
                
            }
        }
    }

    // Customizable Area End

}