import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { getStorageData, setStorageData } from "framework/src/Utilities";

interface DeleteQuestionBankResp {
    error: string;
    message: string;
}
interface AllQuestionListResponse {
    message: string;
    download_url: string;
    error: string;
    question_banks: {
      data: {
        id: string;
        type: string;
        attributes: {
          question_bank_listing: {
            job_id: number;
            job_title: string;
            competencies: string[];
            question_count: number;
          };
        };
      };
    }[];
    pagination: {
      current_page: number;
      total_pages: number;
      total_count: number;
      per_page: string;
      prev_page: number | null;
      next_page: number | null;
    };
}
interface QuestionData {
    data?: {
        id: string;
        type: string;
        attributes: {
            competencies_and_question_counts: {
                job_title: string;
                competencies: string;
                total_questions_count: string;
            }[];
        }
    };
}
// Customizable Area End

export const configJSON = require("./config");
export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    selectedTab: string;
    anchorEl: HTMLElement | null;
    viewMoreAnchorEl: HTMLElement | null;
    selectedRowId: string | null;
    selectedViewRowId: string | null;
    selectedLanguage: string;
    token: string;
    errorMsg: string;
    questionListData:  {
      data: {
        id: string;
        type: string;
        attributes: {
          question_bank_listing: {
            job_id: number;
            job_title: string;
            competencies: string[];
            question_count: number;
          };
        };
      };
    }[];
    loader: boolean;
    opendialog: boolean;
    deleteRowId: string;
    page: number;
    perPage: number;
    totalPage: number;
    // Customizable Area End

}

interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class QuestionBankDashboardController extends BlockComponent<
    Props,
    S,
    SS
> {

    // Customizable Area Start
    listQuestionApiCallId: string = "";
    deleteQuestionBankCallId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.CountryCodeMessage)
        ];

        this.state = {
            selectedTab: "",
            anchorEl: null,
            viewMoreAnchorEl: null,
            selectedRowId: null,
            selectedViewRowId: null,
            selectedLanguage: "",
            token: "",
            errorMsg: "",
            questionListData: [],
            loader: false,
            opendialog: false,
            deleteRowId: "",
            page: 1,
            perPage: 10,
            totalPage: 0
        };

        // Customizable Area End
        runEngine.attachBuildingBlock(this, this.subScribedMessages);
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (apiRequestCallId === this.listQuestionApiCallId) {
                this.listQuestionApiResponse(responseJson)
            }
            if (apiRequestCallId === this.deleteQuestionBankCallId) {
                this.deleteQuestionApiResponse(responseJson)
            }
        }
        // Customizable Area End
    }

    // Customizable Area Start
    async componentDidMount() {
        super.componentDidMount();

        const language = await getStorageData("language") || "English";
        this.setState({ selectedLanguage: language });

        const signInResponse = await getStorageData("signInResponse");
        const parsedSignInResponse = await JSON.parse(signInResponse)
        this.setState({ token: parsedSignInResponse.meta?.token })

        this.QuestionListApiCall();
    }

    listQuestionApiResponse = (responseJson: AllQuestionListResponse) => {
        if (responseJson && !responseJson.error) {
            if (responseJson.download_url) {
                this.setState({
                    loader: false
                })
                const link = document.createElement('a');
                link.href = responseJson.download_url;
                link.download = 'export.csv';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            } else {
                this.setState({
                    questionListData: responseJson.question_banks ? responseJson.question_banks : [],
                    totalPage: responseJson?.pagination.total_pages,
                    loader: false
                })
            }
        } else {
            this.setState({
                errorMsg: responseJson.error,
                loader: false
            });
        }
    }

    deleteQuestionApiResponse = (responseJson: DeleteQuestionBankResp) => {
        if (responseJson && !responseJson.error) {
            this.setState({ deleteRowId: "", opendialog: false }, () => {
                this.QuestionListApiCall();
            })

        } else {
            this.setState({
                errorMsg: responseJson.error
            });
        }
    }

    handlePageChange = (newPage: number) => {
        this.setState({ page: newPage }, () => {
            this.QuestionListApiCall();
        });
    };

    handleExportAll = () => {
        this.QuestionListApiCall(true);
    }

    QuestionListApiCall = (exportAll?: boolean) => {
        this.setState({ loader: true })
        const headers = {
            "token": this.state.token
        };

        let queryParam = "";
        if (exportAll) {
            queryParam += `export=${exportAll}&`
        }
        const getQuestionListMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.listQuestionApiCallId = getQuestionListMsg.messageId;
        getQuestionListMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        getQuestionListMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getApiMethodType
        );
        getQuestionListMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.questionListApiEndPoint}?${queryParam}&page=${this.state.page}&per_page=${this.state.perPage}`
        );

        runEngine.sendMessage(getQuestionListMsg.id, getQuestionListMsg);
    }

    deleteQuestionBankApiCall = (id: string) => {
        const headers = {
            "token": this.state.token
        };
        const getQuestionListMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.deleteQuestionBankCallId = getQuestionListMsg.messageId;
        getQuestionListMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        getQuestionListMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.deleteApiMethodType
        );
        getQuestionListMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.deleteQuestionBankApiEndPoint}/${id}`
        );

        runEngine.sendMessage(getQuestionListMsg.id, getQuestionListMsg);
    }

    handleTabSelction = (tabText: string) => {
        this.setState({ selectedTab: tabText }, () => {
            let route = "";
            if (this.state.selectedTab === "CreateNewQuestion") {
                route = "QuestionBankCreation";
            }
            if (this.state.selectedTab === "UploadQuestionsManually") {
                route = "UploadQuestions";
                setStorageData("AddQuestions", "")
                setStorageData("FromPageName", "QuestionBankDashboard");
            }
            if (this.state.selectedTab === "GenerateAssessment") {
                route = "GenerateAssessment";
            }
            if (tabText === "ManageQuestions") {
                this.setState({
                    page: 1,
                    perPage: 6,
                },()=>{
                    this.QuestionListApiCall();
                })
            }
            const navigateMsg = new Message(getName(MessageEnum.NavigationMessage));
            navigateMsg.addData(getName(MessageEnum.NavigationTargetMessage), route);
            navigateMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
            this.send(navigateMsg);
        })
    }

    handleMenuOpen = (event: React.MouseEvent<HTMLElement>, rowId: string) => {
        this.setState({
            anchorEl: event.currentTarget,
            selectedRowId: rowId
        });
    };

    handleViewMoreMenuOpen = (event: React.MouseEvent<HTMLElement>, rowId: string) => {
        this.setState({
            viewMoreAnchorEl: event.currentTarget,
            selectedViewRowId: rowId
        });
    };

    handleViewMoreMenuClose = () => {
        this.setState({
            viewMoreAnchorEl: null,
            selectedViewRowId: null
        });
    };

    handleMenuClose = () => {
        this.setState({
            anchorEl: null,
            selectedRowId: null
        });
    };

    handleCloseDelete = () => {
        this.setState({ opendialog: false })
    }

    handleDelete = (rowId: string) => {
        this.setState({ opendialog: true, deleteRowId: rowId }, 
            ()=>{
                this.handleMenuClose();
            }
        )
    }

    handleView = async (id: string) => {
        await setStorageData("questionBankId", JSON.stringify(id));

        const navigateMsg = new Message(getName(MessageEnum.NavigationMessage));
        navigateMsg.addData(getName(MessageEnum.NavigationTargetMessage), "ListingQuestionBank");
        navigateMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(navigateMsg);

        this.handleMenuClose();
    }

    // Customizable Area End
}