import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
import React, { RefObject, ChangeEvent } from "react";
import { i18n } from "i18next";

// Customizable Area End

export const configJSON = require("./config");
export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    i18n: i18n;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    selectedLanguage: string;
    token: string;
    loader: boolean;
    deleteDialog: boolean;
    rowId: string;
    questionFormatName: string;
    QuestionAnswerData: Array<{
        Index: number;
        QuestionsId: number;
        Id: number;
        QuestionFormatName: string;
        QuestionText: string;
        AnswerText: string;
        Answers: {
            Id: number;
            ChoiceText: string;
        }[];
    }>;
    QuestionTypes: Array<{
        id: string,
        name: string
    }>;
    DifficultyLevels: Array<{
        id: string,
        name: string
    }>;
    FocusAreas: Array<{
        id: string,
        name: string
    }>;
    SuccessModel: boolean;
    AddQuestionsPopUp: boolean;
    currentPage: number;
    visibleCount: number,
    itemsPerPage: number,
    IsMenuHide: boolean;
    checked: boolean;
    StartAssessmentPopUp: boolean;
    AssessmentStart: boolean;
    SelectedAnswer: Array<number>;
    TotalQuestions: number;
    // Customizable Area End

}

interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class InstructionsController extends BlockComponent<
    Props,
    S,
    SS
> {

    // Customizable Area Start
    getQuestionListApiCallId: string = "";
    SaveChangesApiId: string = "";
    GetAllQuetions: string = "";
    previewRef: RefObject<HTMLElement> = React.createRef<HTMLElement>();
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.CountryCodeMessage)
        ];

        this.state = {
            selectedLanguage: "",
            token: "",
            loader: false,
            deleteDialog: false,
            rowId: "",
            questionFormatName: "",
            QuestionAnswerData: [],
            QuestionTypes: [],
            DifficultyLevels: [],
            FocusAreas: [],
            SuccessModel: false,
            AddQuestionsPopUp: false,
            currentPage: 0,
            visibleCount: 5,
            itemsPerPage: 5,
            IsMenuHide: false,
            checked: false,
            StartAssessmentPopUp: false,
            AssessmentStart: false,
            SelectedAnswer: [],
            TotalQuestions: 0,
        };

        // Customizable Area End
        runEngine.attachBuildingBlock(this, this.subScribedMessages);
    }


    // Customizable Area Start
    async componentDidMount() {
        super.componentDidMount();

        const signInResponse = await getStorageData("signInResponse");
        const parsedSignInResponse = await JSON.parse(signInResponse)
        this.setState({ token: parsedSignInResponse.meta?.token })



        const language = await getStorageData("language") || "English";
        const TestData = await getStorageData("TestData") as string;
        const parsedTestData = await JSON.parse(TestData)

        this.setState({ selectedLanguage: language, TotalQuestions: parsedTestData.TotalQuestions });
        
    }

    NaviGateToAnyScreen = (PathName: string) => {
        const navigateMsg = new Message(getName(MessageEnum.NavigationMessage));
        navigateMsg.addData(getName(MessageEnum.NavigationTargetMessage), PathName);
        navigateMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(navigateMsg);
    }

    CloseStartPopUp = () => {
        this.setState({
            StartAssessmentPopUp: false
        })
    }

    StartAssessment = () => {
        this.setState({
            StartAssessmentPopUp: false,
            IsMenuHide: true,
            AssessmentStart: true,
        })
        this.enterFullScreen()
        this.NaviGateToAnyScreen("CandidateTest")
    }

    enterFullScreen = () => {
        const elem: HTMLElement = document.documentElement;
        if (elem.requestFullscreen) {
            elem.requestFullscreen();
        } else if (document.fullscreenElement === null && document.exitFullscreen) {
            document.exitFullscreen();
        }
    };

    handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>): void => {
        this.setState({ checked: event.target.checked });
    };

    OpenAssessment = () => {
        this.setState({
            StartAssessmentPopUp: true,
        })
    }

    // Customizable Area End
}